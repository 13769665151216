import { generateTechSupportDataObservable, getRecentTechSupportRequestsObservable } from "./services";
import { catchError, mergeMap } from "rxjs/operators";
import { parseError } from "ui-lib";
import { of, concat } from "rxjs";
import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";

export const GENERATE_TECH_SUPPORT_DATA = "GENERATE_TECH_SUPPORT_DATA";
export const GENERATE_TECH_SUPPORT_DATA_SUCCESS = "GENERATE_TECH_SUPPORT_DATA_SUCCESS";
export const GET_RECENT_TECH_SUPPORT_REQUESTS = "GET_RECENT_TECH_SUPPORT_REQUESTS";
export const GET_RECENT_TECH_SUPPORT_REQUESTS_SUCCESS = "GET_RECENT_TECH_SUPPORT_REQUESTS_SUCCESS";
export const SERVER_ERROR = "SERVER_ERROR";
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const generateTechSupportData = caseId => ({
	type: GENERATE_TECH_SUPPORT_DATA,
	caseId
});

export const getRecentTechSupportRequests = () => ({
	type: GET_RECENT_TECH_SUPPORT_REQUESTS
});

export const startLoading = () => ({
	type: START_LOADING
});

export const finishLoading = () => ({
	type: FINISH_LOADING
});

export const generateTechSupportDataEpic = action$ => {
	return action$.pipe(
		ofType(GENERATE_TECH_SUPPORT_DATA),
		mergeMap(action => {
			return concat(
				ajax(generateTechSupportDataObservable(action.caseId)).pipe(
					mergeMap(response =>
						of(
							{
								type: GENERATE_TECH_SUPPORT_DATA_SUCCESS,
								generatedTechSupportData: response
							},
							getRecentTechSupportRequests(),
							finishLoading()
						)
					),
					catchError(error => {
						return of({
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: true
						});
					})
				)
			);
		})
	);
};

export const getRecentTechSupportRequestsEpic = action$ => {
	return action$.pipe(
		ofType(GET_RECENT_TECH_SUPPORT_REQUESTS),
		mergeMap(action => {
			return concat(
				ajax(getRecentTechSupportRequestsObservable()).pipe(
					mergeMap(response => of({ type: GET_RECENT_TECH_SUPPORT_REQUESTS_SUCCESS, recentTechSupportRequests: response }, finishLoading())),
					catchError(error => {
						return of({
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: true
						});
					})
				)
			);
		})
	);
};
