import { ajax as Ajax } from 'rxjs/ajax'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { getAuthHeaders, getCustomServiceURL, getLoginServiceURL, MOCK_SERVICES } from 'ui-lib'
import Constants from '../../utils/Constants'
import Util from '../../utils/Util'
import { getStoreState } from 'ui-lib'
import _ from 'lodash'

const relogin = () => {
	let loginUrl = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL']
	//tenant from redux store
	let store = getStoreState()
	let tenantId = _.get(store, 'main.tenant.id', null)
	if (tenantId) {
		loginUrl += `?tenantId=${tenantId}`
	}
	window.location = loginUrl;
}

const ajax = (obj) => {
	return Ajax(obj).pipe(map(r => {
		// on redirection response is null & req url != resp url
		if (r.xhr.status === 403) {
			return relogin();
		}
		return r
	}), catchError(e => {
		if (e.xhr.status === 403) {
			return relogin()
		} else {
			return throwError(e)
		}
	}))
}

export function getSchema() {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/schema/merged')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getLogs(store, pageLimit) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/job')
	const [vendor, type] = store.logType.split('.')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			logtype: store.logType,
			limit: pageLimit,
			startTime: store.timeRange[0].valueOf(),
			endTime: store.timeRange[1].valueOf(),
			defaultPageSize: Constants.DEFAULT_PAGE_SIZE,
			sortField: store.schema[vendor].content[type].defaultSort,
			order: store.sortOrder,
			timeRangeField: store.schema[vendor].content[type].defaultTimeField,
			query: Util.convertQueryTimeStamp(store.query, store.schema[vendor].content[type].fields),
			logtenant: store.tenantId
		}
	})
}

export function getLogsComposite(store, logType, filters) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/composite')
	const [vendor, type] = logType.split('.')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			logtype: logType,
			limit: store.pageLimit,
			startTime: store.timeRange[0].valueOf(),
			endTime: store.timeRange[1].valueOf(),
			defaultPageSize: Constants.DEFAULT_PAGE_SIZE,
			sortField: store.schema[vendor].content[type].defaultSort,
			order: 'DESC',
			timeRangeField: store.schema[vendor].content[type].defaultTimeField,
			query: Util.convertQueryTimeStamp(store.query + filters, store.schema[vendor].content[type].fields),
			logtenant: store.tenantId
		}
	})
}

export function getLogsCount(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/count')
	const [vendor, type] = store.logType.split('.')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			logtype: store.logType,
			limit: store.pageLimit,
			startTime: store.timeRange[0].valueOf(),
			endTime: store.timeRange[1].valueOf(),
			defaultPageSize: Constants.DEFAULT_PAGE_SIZE,
			sortField: store.schema[vendor].content[type].defaultSort,
			order: store.sortOrder,
			timeRangeField: store.schema[vendor].content[type].defaultTimeField,
			query: Util.convertQueryTimeStamp(store.query, store.schema[vendor].content[type].fields),
			logtenant: store.tenantId
		}
	})
}

export function pollJob(store, currentJobId) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/polljob')
	SERVICE_URL = `${SERVICE_URL}?jobId=${currentJobId}&pageNumber=${store.pageNumber}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	})
}

export function getUserContext({ username }) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/context/user')
	SERVICE_URL = `${SERVICE_URL}?username=${username}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	})
}

export function initExport(store, params) {
	const { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/export')
	const [vendor, type] = store.logType.split('.')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			aggregates: '',
			startTime: store.timeRange[0].valueOf(),
			endTime: store.timeRange[1].valueOf(),
			filename: params.filename,
			filetype: params.filetype,
			limit: params.limit,
			logtenant: store.tenantId,
			logtype: store.logType,
			sortField: store.sortField || store.schema[vendor].content[type].defaultTimeField,
			order: store.sortOrder,
			query: Util.convertQueryTimeStamp(store.query, store.schema[vendor].content[type].fields),
			selectors: '',
			timeRangeField: store.schema[vendor].content[type].defaultTimeField
		}
	})
}

export function cancelExport(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/export')
	SERVICE_URL = `${SERVICE_URL}/${store.exportJobId}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getExportStatus(exportJobId) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/export')
	SERVICE_URL = `${SERVICE_URL}/${exportJobId}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getExportHistory() {
	const { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/export')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function downloadExport(params) {
	console.log(params)
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/export/file')
	SERVICE_URL = `${SERVICE_URL}/${params.fileId}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'blob',
		url: SERVICE_URL,
		headers: {
			...getAuthHeaders()
		}
	})
}
