import React from "react";
import PropTypes from "prop-types";
import { Input, Label, FormGroup } from "reactstrap";
import './CheckboxWidget.scss';
export class CheckboxWidget extends React.Component{

    render() {
        const {
            id,
            value,
            required,
            disabled,
            readonly,
            label,
            autoFocus,
            onChange,
            error,
            showErrors
        } = this.props;

        let errorClassName = error && !disabled && showErrors ? 'checkbox-error' : '';
        return (
            <FormGroup check disabled={disabled || readonly}>
                <Label check>
                    <Input
                        type="checkbox"
                        id={id}
                        checked={typeof value === "undefined" ? false : value}
                        required={required}
                        disabled={disabled || readonly}
                        autoFocus={autoFocus}
                        onChange={event => onChange(event.target.checked)}
                        className={errorClassName}
                        invalid = {showErrors && !!error}
                    />{' '}{label}
                </Label>
            </FormGroup>
        );
    }
}

CheckboxWidget.defaultProps = {
    autoFocus: false,
    disabled: false,
    readonly: false,
    required: false,
    label:""
};

if (process.env.NODE_ENV !== "production") {
    CheckboxWidget.propTypes = {
        id: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string,PropTypes.bool]),
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        label:PropTypes.string,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        error:PropTypes.string,
        showErrors:PropTypes.bool,
    };
}