import TPLBaseViewer from '../../template/TPLBaseViewer';
import { ViewerManager, Pan } from 'ui-lib';
import getTPLRecordViewer from '../../template/TPLRecordViewer';

const _T = str => str;
const clientLessAppGroupFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            disableOnEditRecord: true
        }
    },
    {
        name: '$.members',
        uiHint: {
            fieldLabel: _T('Applications')
        }
    },
    {
        name: '$.members.member.*',
        uiHint: {
            builder: 'CompletionBuilder',
            completionConfig: {
                actions: [
                    {
                        text: 'Add Clientless App',
                        viewerName: 'ClientLessAppRecordViewer',
                        rbaPath: 'network.global-protect.clientless-apps',
                        viewerProps: {
                            title: _T('ClientLess App'),
                            windowSize: 'lg'
                        }
                    }
                ],
            }
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            '$.members'
        ],
        uiHint: {
            labelWidthSize: 5
        }
    }
];

const clientLessAppGroupColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.global-protect.clientless-app-group.entry'
}];

const viewConfig = {
    serviceName : 'Network/GlobalProtect/ClientlessAppGroups',
    reduxId : 'clientLessAppGroup',
    header : 'ClientLess App Group',
    columns : clientLessAppGroupColumns,
    fields : clientLessAppGroupFields,
    rbaPath: 'network.global-protect.clientless-app-groups',
    recordConfigPath : '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.global-protect.clientless-app-group.entry',
    className: 'ClientLessAppGroup'
}

export default class ClientLessAppGroup extends TPLBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getTPLRecordViewer);