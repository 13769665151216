/* eslint-disable array-callback-return */
import { jsonPath, Pan } from 'ui-lib';
import _ from "lodash";


const _T = str => str;

class AppContainer {
    constructor(name) {
        this.name = name;
        this.count = 0;
        this.children = [];
        this.category = '&nbsp;';
        this.subcategory = '&nbsp;';
        this.technology = '&nbsp;';
        this.risk = '0';
        this.iconClass = 'x-pan-application';
        this.tagged = "false";
        this['tag-info'] = undefined;
        this.tagcount = undefined;

        this.hasTaggedChildInCurrentLoc = false;
    }

    hasTaggedChildInScope() {
        return this.hasTaggedChildInCurrentLoc;
    }

    hasTagDisabledOverride() {
        return jsonPath(this, "$.tag-info.disable-override") === 'yes';
    }
};

let allAppContainers = [];
let allApps = [];

// TODO:
let __dynamic_saas_risks = [];

class AppDataProxy {

    //////////////////// private functions ////////////////////
    updateTagInfo(app, name, taggedAppCache, tagMap) {
        // Set app tagged state for every app.
        if (Pan.isDefined(taggedAppCache) && Pan.isDefined(taggedAppCache[name])) {
            app.tagged = "true";
            let ts = jsonPath(taggedAppCache[name], "$.tag.member");
            if (ts && ts.length > 0) {
                let tgs = [];
                ts[0].map((t) => {
                    var t1 = tagMap[t];
                    tgs.push({
                        'name': t,
                        'color': t1.color
                    });
                });
                app['tag-info'] = tgs;
                app['tagcount'] = taggedAppCache[name]['tag'].member.length >= 1 ? "<img src='/images/treemenu/objects/tags.png'/>" : '';
                // not display tag-count if tag is 'empty'
                if (taggedAppCache[name]['tag'].member.length === 1 &&
                    taggedAppCache[name]['tag'].member.indexOf('empty') >= 0) {
                    app['tagcount'] = _T('Remove Tag Inheritance');
                }
            }
        }
        else {
            app.tagged = "false";
            app['tag-info'] = app['tagcount'] = undefined;
        }
    }

    allowTag(name, isContainer, currentLoc) {
        return isContainer ? this.allowTagContainer(name, currentLoc) : this.allowTagApp(name, currentLoc);
    }

    allowTagOverride(name, isContainer, currentLoc) {
        var container;
        var containerLoc;
        if (isContainer) {
            container = allAppContainers[name];
            containerLoc = jsonPath(container, "$.tag-info.@loc");
            // the parent container has disable-override set
            if (containerLoc && currentLoc !== containerLoc) {
                if (container.hasTagDisabledOverride()) {
                    console.log('Disallow tagging app as disable-override is set in container @' + containerLoc);
                    return false;
                }
                if (container.hasTaggedChildInScope()) {
                    return false;
                }
                return true;
            }
            // not parent DG
            return false;
        }
        else {
            var app = allApps[name];
            var appLoc = jsonPath(app, "$.tag-info.@loc");
            var containerName = app.container;
            if (containerName) {
                container = allAppContainers[containerName];
                containerLoc = jsonPath(container, "$.tag-info.@loc");
                if (containerLoc === currentLoc) {
                    console.log('Disallow overriding app, container is tagged in current scope');
                    return false;
                }
            }
            if (appLoc === currentLoc) {
                // should not use override in same scope, use Tag instead
                return false;
            }
            else if (jsonPath(app, "$.tag-info.disable-override") === 'yes') {
                console.log('Disallow overriding app, disable-override is set in app @' + appLoc);
                return false;
            }
            return true;
        }
    }

    allowTagContainer(containerName, currentLoc) {
        var container = allAppContainers[containerName];
        var containerLoc = jsonPath(container, "$.tag-info.@loc");
        // the parent container has disable-override set
        if (containerLoc && currentLoc !== containerLoc) {
            if (container.hasTagDisabledOverride()) {
                return false;
            }
            // we also disable tag button to force use override button
            console.log('Container is tagged @' + containerLoc + ' should use override app');
            return false;
        }
        // allow taggin container if there is NO tagged child in current scope
        return !container.hasTaggedChildInScope();
    }

    allowTagApp(appName, currentLoc) {
        var app = allApps[appName];
        var appLoc = jsonPath(app, "$.tag-info.@loc");
        var containerName = app.container;
        if (containerName) {
            var container = allAppContainers[containerName];
            var containerLoc = jsonPath(container, "$.tag-info.@loc");
            if (container.hasTagDisabledOverride()) {
                console.log('Disallow tagging app as disable-override is set in container @' + containerLoc);
                return false;
            }
            // if the container location is tagged in current scope
            // we don't allow tagging child app
            if (containerLoc === currentLoc) {
                console.log('Disallow tagging app, container is tagged in current scope');
                return false;
            }
        }
        if (appLoc && currentLoc !== appLoc) {
            if (jsonPath(app, "$.tag-info.disable-override") === 'yes') {
                console.log('Disallow tagging app, disable-override is set in app @' + appLoc);
                return false;
            }
            // we also disable tag button to force use override button
            console.log('App is tagged @' + appLoc + ' should use override app');
            return false;
        }
        return true;
    }

    /*
     * load all apps from Pan.direct
     */
    loadData(data) {
        const updataApp = (app, data) => {
            var list_of_lines_of_ports = null;
            // app path = vsys || shared || panorama || predefined
            app.path = path;
            app.title = app.name || app['@name'];
            app.name = app.title;
            if (path === 'vsys') {
                app.location = app['@loc'];
                app.xpathId = 'vsys';
                app.vsysName = app['@loc'];
                if (!app['@overrides']) {
                    app['overrides'] = '';
                }
                else {
                    app['overrides'] = app['@overrides'];
                }
            }

            // Set app disabled state for every app.
            if (data['disabledApps'] && data['disabledApps'].indexOf(app.title) !== -1)
                app.disabled = "true";
            else
                app.disabled = "false";

            // Set app tagged state for every app.
            this.updateTagInfo(app, app.title, data['tagApps'], tagMap);

            if (/panorama/i.exec(app.path)) {
                app.cssClass = "x-pan-panorama-row x-grid3-row";
            }
            else {
                app.cssClass = "";
            }

            // replace " with &quot;
            app.description = app.description && app.description.replace ? app.description.replace(/"/g, '&quot;') : "";

            app.selectable = false;
            // defaul configuredRisk to be risk, if user override this risk, this will be used for display in ListView
            app.configuredRisk = app.risk ? app.risk : "";
            if (path !== 'predefined') {
                // Assuming its a custom app.

                // If its a panorama pushed app. Dont set selectable.
                if (path !== 'panorama') {
                    app.selectable = true;
                }
                app.customapp = true;
            }
            else {
                // Its a predefined app or content-preview app.
                app.selectable = app.selectable || (app['can-disable'] === "yes") ? true : false;
                app.customapp = false;
                var overrides = jsonPath(data, "$.override.override.application.entry.*.@name");
                if (overrides) {
                    var pos = overrides.indexOf(app.name);
                    if (pos >= 0) {
                        app.configuredRisk = jsonPath(data, "$.override.override.application.entry." + pos + ".risk", app.risk);
                    }
                }
            }
            // list_of_lines_of_ports looks like ["tcp/10-12, 200", "tcp/2020"]
            if (path === 'customApps' && Pan.isDefined(app['default']) && Pan.isDefined(app['default']['port'])) {
                list_of_lines_of_ports = app['default']['port']['member'];
            }
            else {
                list_of_lines_of_ports = app.ports;
            }
            var app_ports = [];
            if (!Pan.isEmpty(list_of_lines_of_ports)) {
                Pan.each(list_of_lines_of_ports, function (line_of_ports) {
                    // line_of_port will look like "tcp/10-12, 200"
                    if (Pan.isEmpty(line_of_ports)) {
                        return;
                    }
                    var ports = [];
                    if (line_of_ports.indexOf(",") !== -1) {
                        ports = line_of_ports.split(",");
                    }
                    else {
                        ports = [line_of_ports];
                    }

                    //ports look like ["tcp/10-12", "200"]
                    Pan.each(ports, function (port, index) {
                        //port may look like "tcp/10-12"
                        if (port.indexOf("/") !== -1) {
                            var parts = port.split("/");
                            ports[index] = parts[1];
                            ports.push(parts[0]);
                        }
                    });

                    Pan.each(ports, function (port) {
                        if (app_ports.indexOf(port) === -1) {
                            app_ports.push(port);
                        }
                    });
                });
                app_ports.sort();
                if (path === 'customApps' && Pan.isDefined(app['default']) && Pan.isDefined(app['default']['port'])) {
                    app.xml_port_value = app['default']['port']['member'];
                }
                else {
                    app.xml_port_value = app.ports;
                }
                // app.ports = app_ports;
            }

            // create container-app
            this.updateContainer(result, app, data['taggedApps']);
            // update all counters
            this.updateFilterCounts(result, app);
        };

        var result = this.initResult([]);
        var tagMap = this.buildTagMap(data.tags || []);
        for (var path in data) {
            if (data.hasOwnProperty(path)) {
                if (path !== "predefined" && path !== "customApps" && path !== "disabledApps" && path !== "taggedApps")
                    continue;
                var apps = Pan.isArray(data[path]) ? data[path] : data[path]["entry"];
                if (!apps) {
                    apps = [];
                }
                apps = apps.sort(function (o1, o2) {
                    var o1Name = o1.name || o1['@name'] || o1;
                    var o2Name = o2.name || o2['@name'] || o2;
                    return o1Name.localeCompare(o2Name);
                });

                Pan.each(apps, app => updataApp(app, data), this);
                result.data = result.data.concat(apps);
                result.tagMap = tagMap;
            }
        }
        // build associative array of all app/containers to be used in
        // allowTag and allowTagOverride
        allAppContainers = [];
        Pan.each(result.appcontainer, function (ac) {
            var name = ac.name || ac['@name'];
            allAppContainers[name] = ac;
        }, this);
        allApps = [];
        Pan.each(result.data, function (app) {
            var name = app.name || app['@name'];
            allApps[name] = app;
        }, this);

        return result;
    }

    /*
     * build Tag Map from result
     */
    buildTagMap(tags) {
        let tagColorMap = {};
        tags.forEach(function (tag) {
            tagColorMap[tag['@name']] = tag;
        });
        return tagColorMap;
    }

    /*
     * update apps before display them to app browser
     * update tree icons, app container title
     */
    updateDisplayList(all, result) {
        // add container-app to display list
        Pan.each(result.appcontainer, function (ac) {
            var name = ac.name || ac['@name'];
            var n = this.findByName(all.appcontainer, name);
            var oac = all.appcontainer[n];
            var first = ac.children[0];
            var last = ac.children[ac.count - 1];
            var index = this.findByName(result.data, first.name);
            if (index !== -1) {
                var group = [ac];
                for (var i = 0; i < ac.children.length; i++) {
                    var child = ac.children[i];
                    child.treeClass = 'x-tree-elbow';
                    // eslint-disable-next-line no-loop-func
                    _.remove(result.data, n => n.name === child.name);
                    group.push(child);
                }
                result.data.splice.apply(result.data, [index, 0].concat(group));
            }
            // set icon and tree class
            last.treeClass = 'x-tree-elbow-end';

            if (ac.count === oac.count) {
                ac.title = ac.name || ac['@name'];
            }
            else {
                ac.title = _T(`${ac.name || ac['@name']} (${ac.count} out of ${oac.count} shown)`);
            }
        }, this);
    }

    /*
     * apply app filters, rebuild filter components
     * rebuild apps list
     */
    loadFilteredData(allresult, filters) {
        // apply all filters except the last one
        // update all counter, and save the last filter's count
        // apply the last filter
        // copy the saved counter back the the return result
        var result = this.initResult([]);

        if (filters.length > 1) {
            var prevfilters = filters.slice(0, filters.length - 1);
            Pan.each(allresult.data, function (app) {
                if (this.applyFilters(app, prevfilters)) {
                    this.updateContainer(result, app, allresult['taggedApps']);
                    this.updateFilterCounts(result, app);
                    result.data.push(app);
                }
            }, this);
        }
        else {
            result = Pan.applyIf({ data: allresult.data.slice(0) }, allresult);
        }

        var lastFilter = filters[filters.length - 1];
        if (lastFilter && lastFilter.data.length !== 0) {
            var newdata = result.data;
            var savedtype = Pan.apply({}, result[lastFilter.type]);
            result = this.initResult([]);
            Pan.each(newdata, function (app) {
                if (this.applyFilters(app, lastFilter)) {
                    this.updateContainer(result, app, allresult['taggedApps']);
                    this.updateFilterCounts(result, app);
                    result.data.push(app);
                }
            }, this);
            result[lastFilter.type] = savedtype;
        }
        return result;
    }

    /*
     * map characteristic to display title
     */
    characteristicMap() {
        var legacyCharacteristic = {
            'evasive': _T('Evasive'),
            'excessive-bandwidth-use': _T('Excessive Bandwidth'),
            'used-by-malware': _T('Used by Malware'),
            'transfers-files': _T('Transfers Files'),
            'has-known-vulnerabilities': _T('Vulnerability'),
            'tunnels-other-apps': _T('Tunnels Other Apps'),
            'prone-to-misuse': _T('Prone to Misuse'),
            'pervasive': _T('Widely used'),
            'is-saas': _T('SaaS'),
            'new-appid': _T('New App-ID'),
            'no-certifications': _T('No Certifications')
        };
        // get them from cache
        var dynamicSaaSRisks = __dynamic_saas_risks;
        if (dynamicSaaSRisks) {
            for (var i = 0; i < dynamicSaaSRisks.length; i++) {
                legacyCharacteristic[dynamicSaaSRisks[i].name] = dynamicSaaSRisks[i].display;
            }
        }
        return legacyCharacteristic;
    }

    toFilters(obj, image, characteristic, filter) {
        var map = {};
        if (characteristic) {
            map = this.characteristicMap();
        }

        var ret = [];
        for (var type in obj) {
            if (obj.hasOwnProperty(type)) {
                if (type !== '') {
                    var selected = filter.indexOf(type) !== -1;
                    ret.push({
                        name: type,
                        title: characteristic ? map[type] : type,
                        count: obj[type],
                        image: image,
                        selected: selected
                    });
                }
            }
        }
        // find  selected filter with applications matched (not in "ret" array) 
        // and push them to "ret" with zero-count and selected=true
        _.forEach(filter, function (value) {
            var matchRet = _.find(ret, { 'name': value });
            if (_.isNil(matchRet)) {
                ret.push({
                    name: value,
                    title: characteristic ? map[value] : value,
                    count: 0,
                    image: image,
                    selected: true
                });
            }
        });
        return ret;
    }

    /*
     * load all app filters from Pan.direct
     */
    loadAppFilters(data) {
        const updateFilter = (filter, result) => {
            var item = {};
            item.name = filter.name;
            item.path = path;
            item.category = this.convertToText(filter.category);
            item.subcategory = this.convertToText(filter.subcategory);
            item.risk = this.convertToText(filter.risk);
            item.technology = this.convertToText(filter.technology);
            item.characteristic = this.convertCharacteristicToText(filter);
            result.push(item);
        };

        var result = [];
        for (var path in data) {
            if (data.hasOwnProperty(path)) {
                var appfilters = data[path].result || [];
                Pan.each(appfilters, (filter) => updateFilter(filter, result), this);
            }
        }
        return result;
    }

    /*
     * load all app groups from Pan.direct
     */
    loadAppGroups(data, fgs) {
        const updateGroup = (gr, result) => {
            var item = {};
            item.name = gr.name;
            item.path = path;
            item.members = 0;
            var applications = [];
            var filters = [];
            var groups = [];
            this.eachNumberedProperty(gr, function (ref) {
                if (fgs.groups.indexOf(ref) !== -1)
                    groups.push(ref);
                else if (fgs.filters.indexOf(ref) !== -1)
                    filters.push(ref);
                else
                    applications.push(ref);
                item.members++;
            });
            item.applications = applications.join('|');
            item.filters = filters.join('|');
            item.groups = groups.join('|');
            result.push(item);
        };

        var result = [];
        for (var i = 0; i < data.length; i++) {
            var path = this.convertIndexToPath(i);
            var appgroups = data[i].result.application_group || [];
            Pan.each(appgroups, (gr) => updateGroup(gr, result), this);
        }
        return result;
    }

    /*
     * load all app filters/groups from Pan.direct
     */
    loadCompletions(completions) {
        var result = [];
        if (completions) {
            Pan.each(completions, function (item) {
                result.push(item.value);
            }, this);
        }
        return result;
    }

    /*
     * load all filter and group names
     */
    loadFilterAndGroupNames(fdata, gdata) {
        var result = { filters: [], groups: [] };
        var i;
        for (i = 0; i < fdata.length; i++) {
            var filters = fdata[i];
            if (filters && filters.completions) {
                result.filters = result.filters.concat(this.loadCompletions(filters.completions));
            }
        }
        for (i = 0; i < gdata.length; i++) {
            var groups = gdata[i];
            if (groups && groups.completions) {
                result.groups = result.groups.concat(this.loadCompletions(groups.completions));
            }
        }
        return result;
    }

    eachNumberedProperty(obj, func) {
        var keys = [];
        for (var n in obj) {
            if (obj.hasOwnProperty(n)) {
                if (typeof n == "number" || !isNaN(parseInt(n, 10))) {
                    keys.push(n);
                }
            }
        }
        keys.sort(function (x, y) {
            var a = parseInt(x, 10), b = parseInt(y, 10);
            return a > b ? 1 : a < b ? -1 : 0;
        });
        for (var i = 0; i < keys.length; i++) {
            // eslint-disable-next-line no-useless-call
            var rc = func.call(null, obj[keys[i]], keys[i], keys.length);
            if (rc === false) {
                break;
            }
        }
    }

    initResult(data) {
        return {
            data: data,
            appcontainer: [],
            category: {},
            subcategory: {},
            technology: {},
            risk: {},
            characteristic: {}
        };
    }

    isContainerTagged(app) {
        var containerName = app.container;
        var container;
        if (containerName) {
            container = allAppContainers[containerName];
        }
        return container && container.tagged === "true";
    }

    updateContainer(result, app, taggedAppCache) {
        // reset tree class
        app.treeClass = '';
        app.iconClass = app.selectable ? 'x-pan-customapp' : 'x-pan-application';

        var container = app.container;
        if (!Pan.isEmpty(container)) {
            var n = this.findByName(result.appcontainer, container);
            var appcontainer = {};
            if (n === -1) {
                appcontainer = new AppContainer(container);
                this.updateTagInfo(appcontainer, container, taggedAppCache);
                result.appcontainer.push(appcontainer);
            }
            else {
                appcontainer = result.appcontainer[n];
            }
            // mark container to have tagged child in currently selected location
            // TODO:
            //appcontainer.hasTaggedChildInCurrentLoc = appcontainer.hasTaggedChildInCurrentLocv || (Pan.global.getLocVal() == jsonPath(app, "$.tag-info.@loc"));
            appcontainer.hasTaggedChildInCurrentLoc = appcontainer.hasTaggedChildInCurrentLocv || false;
            // this is an app-function
            app.iconClass = 'x-pan-appfunction';
            appcontainer.children.push(app);
            appcontainer.count = appcontainer.count + 1;
        }
    }

    updateFilterCounts(result, app) {
        this.increaseCounter(result.category, app.category);
        this.increaseCounter(result.subcategory, app.subcategory);
        this.increaseCounter(result.technology, app.technology);
        this.increaseCounter(result.risk, app.configuredRisk);
        this.updateCharacteristicCounters(result.characteristic, app);
    }

    updateCharacteristicCounters(type, app) {
        if (app['evasive'] === 'yes') this.increaseCounter(type, "evasive");
        if (app['excessive-bandwidth-use'] === 'yes') this.increaseCounter(type, "excessive-bandwidth-use");
        if (app['used-by-malware'] === 'yes') this.increaseCounter(type, "used-by-malware");
        if (app['transfers-files'] === 'yes') this.increaseCounter(type, "transfers-files");
        if (app['has-known-vulnerabilities'] === 'yes') this.increaseCounter(type, "has-known-vulnerabilities");
        if (app['tunnels-other-apps'] === 'yes') this.increaseCounter(type, "tunnels-other-apps");
        if (app['prone-to-misuse'] === 'yes') this.increaseCounter(type, "prone-to-misuse");
        if (app['pervasive'] === 'yes') this.increaseCounter(type, "pervasive");
        if (app['is-saas'] === 'yes') this.increaseCounter(type, "is-saas");
        if (app['new-appid'] === 'yes') this.increaseCounter(type, "new-appid");
        if (app['no-certifications'] === 'yes') this.increaseCounter(type, "no-certifications");
        // var dynamicSaaSRisks = Pan.appframework.PanPredefinedCache.appCache.getSaaSRiskAttributes();
        // TODO:
        var dynamicSaaSRisks = __dynamic_saas_risks;
        if (dynamicSaaSRisks) {
            for (var i = 0; i < dynamicSaaSRisks.length; i++) {
                var name = dynamicSaaSRisks[i].name;
                if (app[name] === 'yes') this.increaseCounter(type, name);
            }
        }
    }

    characteristicMember(data) {
        var member = [];
        if (data['evasive'] === 'yes') member.push("evasive");
        if (data['excessive-bandwidth-use'] === 'yes') member.push("excessive-bandwidth-use");
        if (data['used-by-malware'] === 'yes') member.push("used-by-malware");
        if (data['transfers-files'] === 'yes') member.push("transfers-files");
        if (data['has-known-vulnerabilities'] === 'yes') member.push("has-known-vulnerabilities");
        if (data['tunnels-other-apps'] === 'yes') member.push("tunnels-other-apps");
        if (data['prone-to-misuse'] === 'yes') member.push("prone-to-misuse");
        if (data['pervasive'] === 'yes') member.push("pervasive");
        if (data['is-saas'] === 'yes') member.push("is-saas");
        if (data['new-appid'] === 'yes') member.push("new-appid");
        if (data['no-certifications'] === 'yes') member.push("no-certifications");
        var dynamicSaaSRisks = __dynamic_saas_risks;
        if (dynamicSaaSRisks) {
            for (var i = 0; i < dynamicSaaSRisks.length; i++) {
                var name = dynamicSaaSRisks[i].name;
                if (data[name] === 'yes') member.push(name);
                delete data[name];
            }
        }

        delete data['evasive'];
        delete data['excessive-bandwidth-use'];
        delete data['used-by-malware'];
        delete data['transfers-files'];
        delete data['has-known-vulnerabilities'];
        delete data['tunnels-other-apps'];
        delete data['prone-to-misuse'];
        delete data['pervasive'];
        delete data['is-saas'];
        delete data['new-appid'];
        delete data['no-certifications'];

        return member;
    }

    findByName(data, name) {
        for (var i = 0; i < data.length; i++) {
            if (name === data[i].name || name === data[i]["@name"])
                return i;
        }
        return -1;
    }

    increaseCounter(type, val) {
        var count = type[val] || 0;
        ++count;
        type[val] = count;
    }

    applyFilters(app, filters) {
        if (Pan.isArray(filters)) {
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i];
                if (this.applyFilter(app, filter) === false) {
                    return false;
                }
            }
            return true;
        }

        return this.applyFilter(app, filters);
    }

    /*
     * @param: filter = {type: 'category', data: ['string']}
     */
    applyFilter(app, filter) {
        var i;
        if (filter.type === 'category') {
            return filter.data.indexOf(app.category) !== -1;
        }
        if (filter.type === 'subcategory') {
            return filter.data.indexOf(app.subcategory) !== -1;
        }
        if (filter.type === 'technology') {
            return filter.data.indexOf(app.technology) !== -1;
        }
        if (filter.type === 'risk') {
            return filter.data.indexOf(app.configuredRisk) !== -1;
        }
        if (filter.type === 'characteristic') {
            var ret = true;
            for (i = 0; i < filter.data.length; i++) {
                var c = filter.data[i];
                ret = ret && (app[c] === 'yes');
            }
            return ret;
        }
        if (filter.type === 'search') {
            if (!Pan.isDefined(app["depends-on"])) {
                app["depends-on"] = [];
            }
            if (!Pan.isDefined(app.ports)) {
                app.ports = [];
            }
            var search = String(app.name + app.container + app.description + app["depends-on"].join(",") + app.ports.join(","));
            if (search.indexOf(filter.data) !== -1) {
                return true;
            }
            else {
                if (!Pan.isEmpty(app.ports) && !isNaN(filter.data)) {
                    var len;
                    for (i = 0, len = app.ports.length; i < len; ++i) {
                        var port = app.ports[i];
                        if (port.indexOf("-") !== -1) {
                            var parts = port.split("-");
                            if (!isNaN(parts[0]) && !isNaN(parts[1])) {
                                parts[0] = parseInt(parts[0], 10);
                                parts[1] = parseInt(parts[1], 10);
                                parts.sort();
                                var port_filter = parseInt(filter.data, 10);
                                if (parts[0] <= port_filter && port_filter <= parts[1]) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            }
        }
        if (filter.type === 'customapp') {
            if (filter.data)
                return app.customapp;
            return true;
        }
        if (filter.type === 'disabledapp') {
            if (filter.data)
                return (app.disabled === "true") ? true : false;
            return true;
        }
        if (filter.type === 'taggedapp') {
            if (filter.data)
                return (app.tagged === "true" || this.isContainerTagged(app)) ? true : false;
            return true;
        }
        return true;
    }

    convertToText(types) {
        var ret = '';
        if (types && types.length > 0) {
            ret = types.join('|');
        }
        return ret;
    }

    convertCharacteristicToText(filter) {
        var list = [];
        if (filter['evasive'] === 'yes') list.push("evasive");
        if (filter['excessive-bandwidth-use'] === 'yes') list.push("excessive-bandwidth-use");
        if (filter['used-by-malware'] === 'yes') list.push("used-by-malware");
        if (filter['transfers-files'] === 'yes') list.push("transfers-files");
        if (filter['has-known-vulnerabilities'] === 'yes') list.push("has-known-vulnerabilities");
        if (filter['tunnels-other-apps'] === 'yes') list.push("tunnels-other-apps");
        if (filter['prone-to-misuse'] === 'yes') list.push("prone-to-misuse");
        if (filter['pervasive'] === 'yes') list.push("pervasive");
        if (filter['is-saas'] === 'yes') list.push("is-saas");
        if (filter['new-appid'] === 'yes') list.push("new-appid");
        if (filter['no-certifications'] === 'yes') list.push("no-certifications");

        var dynamicSaaSRisks = __dynamic_saas_risks;
        if (dynamicSaaSRisks) {
            for (var i = 0; i < dynamicSaaSRisks.length; i++) {
                var name = dynamicSaaSRisks[i].name;
                if (filter[name] === 'yes') list.push(name);
            }
        }

        return this.convertToText(list);
    }

    convertIndexToPath(index) {
        var cms = ['shared', 'predefined'];
        return cms[index];
    }
}


const instance = new AppDataProxy();
Object.freeze(instance);

export default instance;