import React, { Component } from 'react';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Risk from './Risk';

import './FilterInput.scss';

export default class FilterInput extends Component {

    toggle = (item) => {
        let { items } = this.props;
        const name = item.name;
        let filter = [];
        for (let i = 0; i < items.length; i++) {
            let current = items[i];
            if (current.name == name) {
                current.selected = !current.selected;
            }
            if (current.selected) {
                filter.push(current.name);
            }
        }
        // nodify the parent
        let { type, onFilterChange } = this.props;
        if (onFilterChange) {
            onFilterChange(type, filter);
        }
    }

    renderTextItem = (item) => {
        let { name, title, count, selected } = item;
        const filterSelected = selected ? 'filter-selected' : '';

        return (
            <ListGroupItem
                key={name}
                className="justify-content-between"
                action
                tag="a"
                onClick={() => this.toggle(item)}
            >
                <div>
                    <span className={`filter-count ${filterSelected}`}>{count}</span>
                    <span className={`${filterSelected}`}>{title}</span>
                </div>
            </ListGroupItem>
        );
    }

    renderSeverityItem = (item) => {
        let { name, title, count, selected } = item;
        const filterSelected = selected ? 'filter-selected' : '';

        return (
            <ListGroupItem
                key={name}
                className="justify-content-between"
                action
                tag="a"
                onClick={() => this.toggle(item)}
            >
                <div>
                    <span className={`filter-count ${filterSelected}`}>{count}</span>
                    <Risk title={title} />
                </div>
            </ListGroupItem>
        );
    }

    renderItem = (item) => {
        let { image } = item;
        if (image) {
            return this.renderSeverityItem(item);
        } else {
            return this.renderTextItem(item);
        }
    }

    render() {
        let { items, title } = this.props;
        return (
            <Card>
                <CardHeader>{title}</CardHeader>
                <CardBody style={{ overflow: 'auto' }}>
                    <ListGroup>
                        {
                            items.map((item) => {
                                return this.renderItem(item);
                            })
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        );
    }
}