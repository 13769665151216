import React, { Component } from "react";
import { getPathValue, HLGridHeader, TextareaWidget, capitalizeLetter, connect, FormFeedbackWidget, UPDATE_MODAL, updateModal, hideModal, SHOW_MODAL, JobManager } from "ui-lib";
import { DG_TPL_MAP } from 'service-lib';
import CommitDetails from "./CommitDetails";
import AdminSelection from "./AdminSelection";
import { fetchCommitData, doCommit, doCommitAndPush, doPush, doRevert } from "../main/actions";
import "./CommitManager.scss";

export const COMMIT_PARTIAL = "operation-type-partial";
export const COMMIT_FULL = "operation-type-all";

class CommitManager extends Component {
	constructor(props) {
		super(props);
		this.getAdminSelectionComponent = this.getAdminSelectionComponent.bind(this);
		this.onAdminSelectionChange = this.onAdminSelectionChange.bind(this);
		this.onCommit = this.onCommit.bind(this);
		this.onCommitAndPush = this.onCommitAndPush.bind(this);
		this.onPush = this.onPush.bind(this);
		this.onRevert = this.onRevert.bind(this);
		this.loadCommitData = this.loadCommitData.bind(this);
		this.getTitle = this.getTitle.bind(this);
		this.getPartialParams = this.getPartialParams.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.togglePushScopeSelectAll = this.togglePushScopeSelectAll.bind(this);
		this.togglePushScopeRowSelection = this.togglePushScopeRowSelection.bind(this);
		this.toggleCommitScopeSelectAll = this.toggleCommitScopeSelectAll.bind(this);
		this.toggleCommitScopeRowSelection = this.toggleCommitScopeRowSelection.bind(this);
		this.updateSelected = this.updateSelectedPushScope.bind(this);
		this.updateSelectedScope = this.updateSelectedCommitScope.bind(this);
		this.getLoggedInUser = this.getLoggedInUser.bind(this);
		this.getCommitScope = this.getCommitScope.bind(this);
		this.buildTemplateMap = this.buildTemplateMap.bind(this);
		// this.getPushScope  =this.getPushScope.bind(this);
		this.state = {
			commitScope: COMMIT_FULL,
			isPartialCommit: false,
			allowedDG: CommitManager.getAllowedDG(props),
			allowedTPL: CommitManager.getAllowedTPL(props),
			type: props.type,
			description: "",
			selectedCommitScope: {
				dgs: [],
				templates: [],
				templateStacks: [],
				workflowConfigurations: []
			},
			selectedPushScope: {
				dgs: [],
				templateStacks: []
			},
			pushScope: [],
			isOperationAllowed: props.type === "revert" ? true : false,
			isChangingScope: false,
			scopeData: [],
			isLoading: false,
			isDescriptionValid: true,
			actions: {
				commitandpush: {
					title: "Commit & Push",
					action: this.onCommitAndPush,
					message: "Doing a commit will overwrite the running configuration with the commit scope and will push the configuration out to the cloud infrastructure."
				},
				commit: {
					title: "Commit",
					action: this.onCommit,
					message: "Doing a commit will overwrite the running configuration with the commit scope."
				},
				push: {
					title: "Push",
					action: this.onPush,
					message: "This operation will push the configuration out to the cloud infrastructure."
				},
				revert: {
					title: "Revert",
					action: this.onRevert
				}
			},
			templateMap: this.buildTemplateMap()
		};
	}

	componentDidMount() {
		this.loadCommitData(this.getCommitScope());
	}

	static processCommitData(responseData, preState) {
		let commitScopeData = getPathValue(responseData, "result.result.summary") || {};
		let gridData = [];
		let { templateMap } = preState;
		Object.keys(commitScopeData).forEach(function (item) {
			if (commitScopeData[item].member && commitScopeData[item].member.length > 0) {
				commitScopeData[item].member.forEach(function (i) {
					let iName = i;
					if (item === "template") {
						iName = templateMap[i] || capitalizeLetter(iName);
					}
					gridData.push({
						"@name": iName,
						type: item
					});
				});
			}
		});
		return gridData;
	}

	static getPushScope(prevState) {
		let pushScope = [];
		let { type, scopeData, allowedDG } = prevState;
		(type === "push" || scopeData.length > 0) &&
			allowedDG.forEach(function (item) {
				pushScope.push({
					"@name": item.label,
					value: item.value,
					type: "device-group"
				});
			});
		return pushScope;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let { isOperationAllowed, type, isPartialCommit } = prevState;
		let responseData = nextProps.main["commitScope"] || {};
		let commitBegins = nextProps.main["commitBegins"] || false;
		let gridData = CommitManager.processCommitData(responseData, prevState);
		if (type === "commit" && gridData.length > 0) {
			isOperationAllowed = true;
		}
		if (type === "commit" && gridData.length === 0) {
			isOperationAllowed = false;
		}
		if (isPartialCommit) {
			isOperationAllowed = false;
		}
		let allowedDG = CommitManager.getAllowedDG(nextProps);
		let pushScope = CommitManager.getPushScope(prevState);
		return {
			scopeData: gridData,
			isLoading: nextProps.main.loadingCommitData || false,
			isOperationAllowed: isOperationAllowed && !commitBegins,
			allowedDG,
			pushScope
		}
	}

	buildTemplateMap() {
		let map = {};
		DG_TPL_MAP.forEach(element => {
			map[element.tpl] = element.dg;
		});
		return map;
	}

	loadCommitData(scope) {
		let { isOperationAllowed, templateMap } = this.state;
		let _actions = this.state.actions[this.state.type];
		let actions = [
			{
				text: _actions.title,
				color: "primary",
				action: _actions.action,
				disabled: !isOperationAllowed
			},
			{
				text: "Close",
				color: "secondary",
				action: () => {
					hideModal("CommitManagerModal");
				}
			}
		];
		if (this.state.type !== "revert" && this.state.type !== "push") {
			this.props.dispatch(
				fetchCommitData({
					scope,
					type: UPDATE_MODAL,
					postAction: data => {
						let responseData = data.response || {};
						let gridData = CommitManager.processCommitData(responseData, this.state);
						if (this.state.type === "commit" && gridData.length > 0) {
							isOperationAllowed = true;
						}
						if (this.state.type === "commit" && gridData.length === 0) {
							isOperationAllowed = false;
						}
						if (this.state.isPartialCommit) {
							isOperationAllowed = false;
						}
						let result = {};
						result.id = this.props.modalId;
						result.props = {
							actions: [
								{
									text: _actions.title,
									color: "primary",
									action: _actions.action,
									disabled: !isOperationAllowed
								},
								{
									text: "Close",
									color: "secondary",
									action: () => {
										hideModal("CommitManagerModal");
									}
								}
							]
						};
						return result;
					}
				})
			);
		} else {
			updateModal(this.props.modalId, {
				actions: actions
			});
		}
	}

	getCommitScope() {
		return this.state.commitScope === COMMIT_FULL ? "all" : "admin";
	}

	getLoggedInUser() {
		return this.props.main["loggedInUser"] && this.props.main["loggedInUser"].userName ? this.props.main["loggedInUser"].userName : "";
	}

	static getAllowedTPL(currentProps) {
		let tmpls = [];
		currentProps.main["allowedDG"].forEach(function (item) {
			if (item.label.toLowerCase() !== "common") {
				tmpls.push(item);
			}
		});
		return tmpls;
	}

	static isValidLicenseInfo(currentProps) {
		let licenseInfo = currentProps.main["licenseInfo"] || {};
		let licenses = [];
		currentProps.main["allowedDG"].forEach(function (item) {
			if (item.label.toLowerCase() !== "prisma access common" && licenseInfo[item.licenseKey]) {
				licenses.push(item);
			}
		});
		return licenses.length > 0;
	}

	static getAllowedDG(currentProps) {
		let dgs = [];
		let licenseInfo = currentProps.main["licenseInfo"] || {};
		const isValidLicense = CommitManager.isValidLicenseInfo(currentProps);
		currentProps.main["allowedDG"].forEach(function (item) {
			if (item.label.toLowerCase() !== "prisma access common" && isValidLicense && (!item.licenseKey || licenseInfo[item.licenseKey])) {
				dgs.push(item);
			}
		});
		return dgs;
	}

	getTitle() {
		switch (this.state.type) {
			case "commitandpush":
				return "Commit & Push";
			case "push":
				return "Push";
			case "revert":
				return "Revert";
			default:
				return "Commit";
		}
	}

	getPartialParams() {
		let params = {
			description: this.state.description,
			partialParams: {}
		};
		const { selectedCommitScope, commitScope } = this.state;
		if (commitScope === COMMIT_PARTIAL) {
			if (selectedCommitScope.dgs.length > 0) {
				params.partialParams["deviceGroups"] = selectedCommitScope.dgs;
			}
			if (selectedCommitScope.templates.length > 0) {
				params.partialParams["template"] = selectedCommitScope.templates;
			}
			if (selectedCommitScope.templateStacks.length > 0) {
				params.partialParams["templateStacks"] = selectedCommitScope.templateStacks;
			}
			if (selectedCommitScope.workflowConfigurations.length > 0) {
				params.partialParams["workflowConfigurations"] = selectedCommitScope.workflowConfigurations;
			}
			params.partialParams["admin"] = [this.getLoggedInUser()];
		}
		return params;
	}

	onCommitAndPush() {
		const { selectedCommitScope, selectedPushScope, commitScope } = this.state;
		let commitPushPayload = { commit: {}, push: {} };
		if (commitScope === COMMIT_PARTIAL) {
			commitPushPayload.commit.admin = [this.getLoggedInUser()];
		}
		if (this.state.description) {
			commitPushPayload.description = this.state.description;
		}
		if (selectedCommitScope.dgs.length > 0) {
			commitPushPayload.commit.deviceGroups = selectedCommitScope.dgs;
		}
		if (selectedCommitScope.templates.length > 0) {
			commitPushPayload.commit.templates = selectedCommitScope.templates;
		}
		if (selectedCommitScope.workflowConfigurations.length > 0) {
			commitPushPayload.commit.workflowConfigurations = selectedCommitScope.workflowConfigurations;
		}
		if (selectedPushScope.dgs.length > 0) {
			commitPushPayload.push.deviceGroups = selectedPushScope.dgs;
		}
		if (selectedPushScope.templateStacks.length > 0) {
			commitPushPayload.push.templateStacks = selectedPushScope.templateStacks;
		}
		this.props.dispatch(doCommitAndPush(this.createPayLoad(commitPushPayload)));
	}

	onPush() {
		let d = [];
		const { description, selectedPushScope } = this.state;
		selectedPushScope.dgs.forEach(function (item) {
			let tmp = "";
			DG_TPL_MAP.forEach(function (i) {
				if (i.dg === item) {
					tmp = i.stack;
				}
			});
			d.push({
				description,
				deviceGroup: item,
				template: tmp
			});
		});
		this.updateModalAction(false);
		this.props.dispatch(doPush(this.createPayLoad(d)));
	}

	onRevert() {
		this.props.dispatch(doRevert({}));
	}

	createPayLoad(params) {
		return {
			params,
			modalId: this.props.modalId,
			type: SHOW_MODAL,
			postAction: response => {
				let result = {};
				result.modal = {
					id: "JobManagerModal",
					title: "Jobs",
					size: "xl",
					type: "Info",
					toggle: () => {
						hideModal("JobManagerModal");
					},
					modalBody: {
						component: JobManager,
						props: {}
					},
					actions: [
						{
							text: "Close",
							color: "secondary",
							action: () => {
								hideModal("JobManagerModal");
							}
						}
					]
				};
				return result;
			}
		};
	}

	onCommit() {
		let params = this.getPartialParams();
		this.props.dispatch(doCommit(this.createPayLoad(params)));
	}

	onDescriptionChange(value) {
		const regex = /^([ 0-9a-zA-Z:#!%./_)(,'-])+$/;
		let isDescriptionValid = true;
		if (value) {
			isDescriptionValid = !!value.match(regex);
		}

		this.setState({
			description: value,
			isDescriptionValid
		});
	}

	onAdminSelectionChange(newCommitScope) {
		let { commitScope } = this.state;
		if (commitScope !== newCommitScope) {
			let scope = newCommitScope === COMMIT_FULL ? "all" : this.getLoggedInUser() || "all";
			let isOperationAllowed = false;
			this.setState(
				{
					commitScope: newCommitScope,
					isPartialCommit: newCommitScope === COMMIT_PARTIAL,
					selectedCommitScope: {
						dgs: [],
						templates: [],
						templateStacks: [],
						workflowConfigurations: []
					},
					selectedPushScope: {
						dgs: [],
						templateStacks: []
					},
					pushScope: [],
					isOperationAllowed: isOperationAllowed,
					isValidPushScope: false,
					isValidCommitScope: false
				},
				() => {
					this.updateModalAction(isOperationAllowed);
					this.loadCommitData(scope);
				}
			);

		}
	}

	updateSelectedPushScope(newSelected) {
		let dgs = [];
		let ts = [];
		Object.keys(newSelected).forEach(function (key) {
			let item = newSelected[key];
			dgs.push(item["@name"]);
			DG_TPL_MAP.forEach(function (i) {
				if (i.dg === item["@name"]) {
					ts.push(i.stack);
				}
			});
		});
		let isValidPushScope = dgs.length > 0;
		let { isValidCommitScope, type, commitScope } = this.state;
		let isOperationAllowed = type === "push" ? isValidPushScope : (commitScope === COMMIT_FULL ? isValidPushScope : isValidPushScope && isValidCommitScope);
		this.setState(
			{
				selectedPushScope: {
					dgs: dgs,
					templateStacks: ts
				},
				isValidPushScope,
				isOperationAllowed: isOperationAllowed
			},
			() => {
				this.updateModalAction(isOperationAllowed);
			}
		);
	}

	updateSelectedCommitScope(newSelected) {
		let dgs = [];
		let tpls = [];
		let wfc = [];
		Object.keys(newSelected).forEach(function (key) {
			let item = newSelected[key];
			switch (item["type"]) {
				case "device-group":
					dgs.push(item["@name"]);
					break;
				case "template":
					tpls.push(item["@name"]);
					break;
				case "workflow-configuration":
					wfc.push(item["@name"]);
					break;
				default:
					break;
			}
		});
		let isValidCommitScope = dgs.length > 0 || wfc.length > 0 || tpls.length > 0;
		let { isValidPushScope, type, commitScope } = this.state;
		let isOperationAllowed = type === "commit" ? isValidCommitScope : (commitScope === COMMIT_FULL ? isValidCommitScope : isValidPushScope && isValidCommitScope);
		this.setState(
			{
				selectedCommitScope: {
					dgs: dgs,
					templates: tpls,
					templateStacks: [],
					workflowConfigurations: wfc
				},
				isValidCommitScope,
				isOperationAllowed: isOperationAllowed
			},
			() => {
				this.updateModalAction(isOperationAllowed);
			}
		);
	}

	togglePushScopeSelectAll(newSelected) {
		this.updateSelectedPushScope(newSelected);
	}

	togglePushScopeRowSelection(newSelected) {
		this.updateSelectedPushScope(newSelected);
	}

	toggleCommitScopeSelectAll(newSelected) {
		this.updateSelectedCommitScope(newSelected);
	}

	toggleCommitScopeRowSelection(newSelected) {
		this.updateSelectedCommitScope(newSelected);
	}

	getAdminSelectionComponent() {
		let option = [
			{
				label: "All Administrators",
				value: COMMIT_FULL
			}
		];
		if (this.getLoggedInUser()) {
			option.push({ label: this.getLoggedInUser(), value: COMMIT_PARTIAL });
		}
		return <AdminSelection value={this.state.commitScope} options={option} onChange={this.onAdminSelectionChange} />;
	}

	updateModalAction(allowed) {
		let _actions = this.state.actions[this.state.type];
		updateModal(this.props.modalId, {
			actions: [
				{
					text: _actions.title,
					color: "primary",
					action: _actions.action,
					disabled: !allowed
				},
				{
					text: "Close",
					color: "secondary",
					action: () => {
						hideModal("CommitManagerModal");
					}
				}
			]
		});
	}

	render() {
		let actions = this.state.actions[this.state.type];
		return (
			<div>
				{this.state.type !== "push" && this.state.type !== "revert" && (
					<HLGridHeader key="adminSelection" header={" "}>
						<div>{this.getAdminSelectionComponent()}</div>
					</HLGridHeader>
				)}
				{this.state.type !== "push" && this.state.type !== "revert" && this.state.isPartialCommit && (
					<div className="scope">
						<h2>Commit Scope</h2>
						<CommitDetails
							toggleRowSelection={this.toggleCommitScopeRowSelection}
							toggleSelectAll={this.toggleCommitScopeSelectAll}
							data={this.state.scopeData}
							columns={[
								{
									headerName: "Scope",
									valueGetter: params => {
										return capitalizeLetter(params.data["@name"]);
									}
								},
								{
									headerName: "Type",
									valueGetter: params => {
										return capitalizeLetter(params.data["type"]);
									}
								}
							]}
							isPartialCommit={true}
							noDataText="No configuration to commit"
							loading={this.state.isLoading}
						/>
					</div>
				)}
				{this.state.type !== "push" && this.state.type !== "revert" && !this.state.isPartialCommit && (
					<div className="scope">
						<h2>Commit Scope</h2>
						<CommitDetails data={this.state.scopeData} columns={[
							{
								headerName: "Scope",
								valueGetter: params => {
									return capitalizeLetter(params.data["@name"]);
								}
							},
							{
								headerName: "Type",
								valueGetter: params => {
									return capitalizeLetter(params.data["type"]);
								}
							}
						]} isPartialCommit={false} noDataText="No configuration to commit" loading={this.state.isLoading} />
					</div>
				)}
				{this.state.type !== "commit" && this.state.type !== "revert" && (
					<div className="scope">
						<h2>Push Scope</h2>
						<CommitDetails
							toggleRowSelection={this.togglePushScopeRowSelection}
							toggleSelectAll={this.togglePushScopeSelectAll}
							data={this.state.pushScope}
							columns={[
								{
									headerName: "Scope",
									valueGetter: params => {
										return capitalizeLetter(params.data["@name"]);
									}
								},
								{
									headerName: "Type",
									valueGetter: params => {
										return capitalizeLetter(params.data["type"]);
									}
								}
							]}
							isPartialCommit={true}
							noDataText="No configuration to push"
							loading={this.state.isLoading}
						/>
					</div>
				)}
				{this.state.type === "revert" && <div className="commit-confirm">Are you sure you want to revert to the running config?</div>}
				{this.state.type !== "revert" && (
					<div className="commit-desc">
						<TextareaWidget
							id="commitDescription"
							showErrors={true}
							error={!this.state.isDescriptionValid}
							onChange={this.onDescriptionChange}
							value={this.state.description}
							placeholder="Enter Description"
							maxLength = {512}
						/>
						{!this.state.isDescriptionValid && <FormFeedbackWidget target={"commitDescription"} feedback={"Special characters such as & and % are not allowed"} />}
					</div>
				)}
				{this.state.type !== "revert" && <div style={{ paddingTop: 10 }}>{actions.message}</div>}
				{/* </ModalWindowWidget> */}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { commit: state.commit, main: state.main };
};

export default connect(
	mapStateToProps,
	null,
	null
)(CommitManager);
