import { getPathValue, jsonPath } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const fileBlockingObjectFields = [
	{
		type: "string",
		name: "name",
		mapping: "@name",
		uiHint: {
			fieldLabel: _T("Name")
		}
	},
	{
		name: "$.description",
		uiHint: {
			fieldLabel: _T("Description")
		}
	}, {
		name: "$.rules.entry.*.@name",
		uiHint: {
			fieldLabel: _T("Rule Name"),
			columnConfig: {
				type: 'clickableColumn'
			}
		}
	}, {
		name: "$.rules.entry.*.file-type.member",
		defaultValue: ['any'],
		uiHint: {
			fieldLabel: _T("File Types"),
		}
	}, {
		name: "$.rules.entry.*.file-type.member.*",
		defaultValue: 'any'
	}, {
		name: "$.rules.entry.*.application.member.*",
		defaultValue: 'any'
	}, {
		name: "$.rules.entry.*.application.member",
		defaultValue: ['any'],
		uiHint: {
			fieldLabel: _T("Applications"),
		}
	}, {
		name: "$.rules.entry",
		uiHint: {
			editorType: "record-form",
			dialogSize: "md",
			useColumns: [
				"$.rules.entry.*.@name",
				"$.rules.entry.*.application",
				"$.rules.entry.*.file-type",
				"$.rules.entry.*.direction",
				"$.rules.entry.*.action"
			]
		}
	},
	{
		name: "$.rules.entry.*.action",
		uiHint: {
			columnConfig: {
				id: "rules-action",
				valueGetter: params => {
					return jsonPath(params.data, "action") || "alert";
				}
			}
		}
	},
	{
		name: "$",
		childrenNames: ["name", "$.description", "$.rules"]
	}
];

const fileBlockingObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
	minWidth: 100
}, {
	headerName: 'Location',
	field: '@loc',
	minWidth: 200
}, {
	headerName: 'Rule Name',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['@name']);
	},
	minWidth: 300
}, {
	headerName: 'Applications',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['application']['member'].join(', '));
	},
	minWidth: 150
}, {
	headerName: 'File Types',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['file-type']['member'].join(', '));
	},
	minWidth: 800
}, {
	headerName: 'Direction',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['direction']);
	},
	minWidth: 100
}, {
	headerName: 'Action',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['action'] || "alert");
	},
	minWidth: 100
}];
export default class FileBlocking extends DGBaseViewer {

	serviceName = 'Objects/FileBlockingSecurityProfiles';
	reduxId = 'fileBlockingObjects';
	header = 'File Blocking';
	columns = fileBlockingObjectColumns;
	fields = fileBlockingObjectFields;
	recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.file-blocking.entry';
	rbaPath = 'objects.security-profiles.file-blocking';
}
