import {
	Pan,
	getRedirectURL,
	getAuthHeaders,
	MOCK_SERVICES,
	MOCK_BASE_URL,
	VSYS_SHARED,
	CONFIG_BASE_URI,
	AUTO_COMPLETE_URI,
	EXTENSION_BASE_URL
} from "ui-lib";

export const LOCATION = "device-group";
export const GPCS_COMMON_DG = "Prisma Access Common";
export const SERVICE_CONNECTION_DG = "Service Connections";
export const REMOTE_NETWORKS_DG = "Remote Networks";
export const MOBILE_USERS_DG = "Mobile Users";

export const GPCS_COMMON_TPL = "Prisma Access Common Template";
export const SERVICE_CONNECTION_TPL = "Service Conn Template";
export const SERVICE_CONNECTION_TPL_STACK = "Service Conn Template Stack";
export const REMOTE_NETWORKS_TPL = "Remote Networks Template";
export const REMOTE_NETWORKS_TPL_STACK = "Remote Networks Template Stack";
export const MOBILE_USERS_TPL = "Mobile Users Template";
export const MOBILE_USERS_TPL_STACK = "Mobile Users Template Stack";
export const MOBILE_USERS_WORKFLOW_CONFIGURATION = "mobile-users";

export const DG_TPL_MAP = [
	{
		dg: MOBILE_USERS_DG,
		tpl: MOBILE_USERS_TPL,
		stack: MOBILE_USERS_TPL_STACK
	},
	{
		dg: SERVICE_CONNECTION_DG,
		tpl: SERVICE_CONNECTION_TPL,
		stack: SERVICE_CONNECTION_TPL_STACK
	},
	{
		dg: REMOTE_NETWORKS_DG,
		tpl: REMOTE_NETWORKS_TPL,
		stack: REMOTE_NETWORKS_TPL_STACK
	},
	{
		dg: GPCS_COMMON_DG,
		tpl: GPCS_COMMON_TPL
	}
];

// service functions
export function getServiceURLS(serviceName, location) {
	let SERVICE_BASE_URL = getRedirectURL();
	let urlParams = "";
	let serviceUrl = {};
	// let SERVICE_BASE_URL = 'https://api.dev2.adelphi.us.paloaltonetworks.com:15982';
	//construct location url params
	//possible locations dg, tpl, extension
	if (location && location.dg) {
		let dgName = location.dg.name;
		let locationUrlParam = `location=device-group`;
		let dgUrlParam = `device-group=${dgName}`;
		urlParams = `?${locationUrlParam}&${dgUrlParam}`;
		serviceUrl.SERVICE_URL = MOCK_SERVICES ? `${MOCK_BASE_URL}/api/${serviceName}` : `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}${urlParams}`;
		serviceUrl.RENAME_ACTION_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/rename${urlParams}&action=rename`;
		serviceUrl.CLONE_ACTION_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/multiclone${urlParams}`;
		serviceUrl.DISABLE_RULES_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/Policies/disableRules${urlParams}`;
		serviceUrl.MOVE_RULES_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/move${urlParams}`;
		serviceUrl.AUTO_COMPLETION_URL = `${SERVICE_BASE_URL}${AUTO_COMPLETE_URI}${urlParams}`;
	} else if (location && location.tpl) {
		let tplname = location.tpl.name;
		let vsys = location.tpl.vsys;

		let locationUrlParam;
		if (serviceName.startsWith("Network/")) {
			locationUrlParam = vsys === "shared" ? `location=shared` : vsys && vsys.startsWith("vsys") ? `location=vsys&vsys=${vsys}` : `location=template-network`;
		} else {
			locationUrlParam = `location=${vsys === VSYS_SHARED ? vsys : "template"}`;
		}

		let tplUrlParam = `template=${tplname}`;
		urlParams = `?${locationUrlParam}&${tplUrlParam}`;
		serviceUrl.SERVICE_URL = MOCK_SERVICES ? `${MOCK_BASE_URL}/api/${serviceName}` : `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}${urlParams}`;
		serviceUrl.RENAME_ACTION_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/rename${urlParams}&action=rename`;
		serviceUrl.CLONE_ACTION_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/multiclone${urlParams}`;
		serviceUrl.AUTO_COMPLETION_URL = `${SERVICE_BASE_URL}${AUTO_COMPLETE_URI}${urlParams}`;
	} else if (location && location.extension) {
		let extensionName = location.extension.gpcs.name;
		serviceUrl.SERVICE_URL = MOCK_SERVICES ? `${MOCK_BASE_URL}/api/${serviceName}` : `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/${serviceName}`;
		serviceUrl.RENAME_ACTION_URL = `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/${serviceName}/rename`;
		serviceUrl.CLONE_ACTION_URL = `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/${serviceName}/clone`;
		serviceUrl.AUTO_COMPLETION_URL = `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/complete`;
	} else {
		//no location. Set the serviceName as url
		serviceUrl.SERVICE_URL = MOCK_SERVICES ? `${MOCK_BASE_URL}/${serviceName}` : `${SERVICE_BASE_URL}/${serviceName}`;
	}

	return serviceUrl;
}

export function getRecordsActionObservable(serviceName, location) {
	let { SERVICE_URL } = getServiceURLS(serviceName, location);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getRecordActionObservable(serviceName, recordId, location) {
	let { SERVICE_URL } = getServiceURLS(serviceName, location);

	if (recordId) {
		SERVICE_URL = `${SERVICE_URL}${SERVICE_URL.indexOf("?") === -1 ? "?" : "&"}name=${recordId}`;
	}

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function addSimpleRecordActionObservable(record, serviceName, location) {
	let { SERVICE_URL } = getServiceURLS(serviceName, location);

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(record),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function addRecordActionObservable(record, serviceName, location) {
	let rec = {
		entry: {
			...record
		}
	};
	let { SERVICE_URL } = getServiceURLS(serviceName, location);

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(rec),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function editRecordActionObservable(record, serviceName, location) {
	let { SERVICE_URL } = getServiceURLS(serviceName, location);
	let oldName = record.old["@name"];
	let url_base = SERVICE_URL;
	let service_url = `${url_base}${url_base.indexOf("?") === -1 ? "?" : "&"}name=${oldName}`;
	let editRecord = {
		entry: {
			...record.new,
			"@name": oldName
		}
	};
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "PUT",
		responseType: "json",
		url: service_url,
		body: JSON.stringify(editRecord),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function renameRecordActionObservable(record, serviceName, location) {
	let { RENAME_ACTION_URL } = getServiceURLS(serviceName, location);

	let oldName = record.old["@name"];
	let newName = record.new["@name"];
	let url_base = RENAME_ACTION_URL;
	let service_url = `${url_base}${url_base.indexOf("?") === -1 ? "?" : "&"}name=${oldName}&newname=${newName}`;
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: service_url,
		body: JSON.stringify({}),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function deleteRecordsActionObservable(records, serviceName, location) {
	let { SERVICE_URL } = getServiceURLS(serviceName, location);
	let names = [];
	for (let key in records) {
		let record = records[key];
		names.push(record["@name"] || record);
	}
	let url_base = SERVICE_URL;
	let service_url = url_base + `${url_base.indexOf("?") === -1 ? "?" : "&"}name=${names.join(",")}`;

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "DELETE",
		responseType: "json",
		url: service_url,
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function cloneRecordsActionObservable(records, serviceName, location) {
	let { CLONE_ACTION_URL } = getServiceURLS(serviceName, location);
	let cloneObj = {};
	let service_url = CLONE_ACTION_URL;

	if (location && location.dg) {
		cloneObj = {
			destination: {},
			source: {}
		};
		if (location.dg && location.dg.name) {
			cloneObj.destination = {
				location: "device-group",
				name: location.dg.name
			};
		} else if (location.shared) {
			cloneObj.destination = {
				location: "shared"
			};
		} else {
			//no matching destination location
		}

		let dgnames = {},
			shared = [],
			predefined = [];
		for (let key in records) {
			let record = records[key];
			let loc = record["@loc"];
			let recName = record["@name"] || record;
			if (loc === "predefined") {
				predefined.push(recName);
			} else if (loc === "shared") {
				shared.push(recName);
			} else {
				dgnames[loc] = dgnames[loc] || [];
				dgnames[loc].push(recName);
			}
		}
		if (!Pan.isEmpty(dgnames)) {
			let sourceDgs = [];
			for (let dg in dgnames) {
				sourceDgs.push({
					members: dgnames[dg],
					name: dg
				});
			}
			cloneObj.source.deviceGroup = sourceDgs;
		}

		if (!Pan.isEmpty(shared)) {
			cloneObj.source.shared = shared;
		}

		if (!Pan.isEmpty(predefined)) {
			cloneObj.source.predefined = predefined;
		}
	} else if (location && location.extension) {
		let names = [];
		for (let key in records) {
			let record = records[key];
			names.push(record["@name"] || record);
		}
		service_url = `${CLONE_ACTION_URL}${CLONE_ACTION_URL.indexOf("?") === -1 ? "?" : "&"}name=${names.join(",")}`;
	}

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: service_url,
		body: JSON.stringify(cloneObj),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}
