import { gpcsItemsReducer } from './reducer';
import {
    getGlobalMapRegionInfoEpic,
    fetchMobileUsersFQDNEpic,
    fetchGpcsCommonInfraEpic,
    fetchAPIKeyEpic,
    fetchGpcsItemEpic,
    pollingMobileUserStatusEpic,
    addPushGpcsItemEpic,
    editPushGpcsItemEpic,
    doMUCommitAndPushEpic,
    fetchJobDetailsEpic,
    markCertAsForwardTrustEpic,
    markCertAsForwardUntrustEpic,
    getNotificationEpic,
    postNotificationEpic,
    getNetworkDetailDataEpic,
    getRemoteNetworkDetailsEpic,
    getServiceConnectionNetworkDetailsEpic,
    fetchSSLDecryptionEpic,
    getMobileUsersNetworkDetailsEpic,
    fetchGPCSStatusEpic,
    generationAPIKeyEpic,
    getAPIKeyEpic,
    getBGPStatusEpic,
    getMobileUserProvisioningStatusEpic,
    getPollingMobileUserProvisioningStatusEpic,
    checkMobileUserPortalFqdnEpic,
    generateMUDefaultConfigCertsEpic
} from './actions';

const storeInfo = {
    reducers: {
        gpcs: gpcsItemsReducer
    },
    epics: [
        getGlobalMapRegionInfoEpic,
        fetchMobileUsersFQDNEpic,
        fetchGpcsCommonInfraEpic,
        fetchAPIKeyEpic,
        fetchGpcsItemEpic,
        pollingMobileUserStatusEpic,
        addPushGpcsItemEpic,
        editPushGpcsItemEpic,
        doMUCommitAndPushEpic,
        fetchJobDetailsEpic,
        markCertAsForwardTrustEpic,
        markCertAsForwardUntrustEpic,
        getNotificationEpic,
        postNotificationEpic,
        getNetworkDetailDataEpic,
        getRemoteNetworkDetailsEpic,
        getServiceConnectionNetworkDetailsEpic,
        fetchSSLDecryptionEpic,
        getMobileUsersNetworkDetailsEpic,
        fetchGPCSStatusEpic,
        generationAPIKeyEpic,
        getAPIKeyEpic,
        getBGPStatusEpic,
        getMobileUserProvisioningStatusEpic,
        getPollingMobileUserProvisioningStatusEpic,
        checkMobileUserPortalFqdnEpic,
        generateMUDefaultConfigCertsEpic
    ]
};

export default storeInfo;
