import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classnames from 'classnames';

export class ButtonWidget extends Component {
    render() {
        return (
            <React.Fragment>
                <Button
                    key={this.props.text}
                    color={this.props.color || "primary"}
                    size="sm"
                    onClick={e => {
                        this.props.onClick();
                        e.target.blur();
                    }}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    className={classnames(this.props.className,'glyphicon glyphicon-th-list')}
                >
                    {this.props.text}
                </Button>
            </React.Fragment>
        );
    }
}

ButtonWidget.defaultProps = {
    text: 'button',
};

if (process.env.NODE_ENV !== 'production') {
    ButtonWidget.propTypes = {
        disabled: PropTypes.bool,
        text: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        style:PropTypes.object
    };
}
