/*
 * Copyright(c) 2015, Palo Alto Networks, inc.
 * Created by hwong on 3/11/2015.
 */
import Pan from './Pan';
import { jsonPath } from '../../utils/json';


export default class PanDecode {

    // will return string ['abc']['def']
    static panPathString(o, e) {
        var result = jsonPath(o, e, { resultType: "PATH" });
        if (result) {
            result = result[0].split("$");
            if (result && result.length > 1) {
                return result[1];
            } else {
                result = false;
            }
        }
        return result;
    }

    // will return array of [ "'abc'", "'def'"] or [ "abc", "def" ] depending on the removeQuotes flag
    static panPathArray(o, e, removeQuotes) {
        var result = jsonPath(o, e, { resultType: "PATH" });
        if (result) {
            result = result[0].split('][');
            if (result && result.length > 0) {
                result[0] = result[0].slice(2);
                var last = result[result.length - 1];
                result[result.length - 1] = last.slice(0, last.length - 1);
                if (removeQuotes) {
                    for (var i = 0; i < result.length; i++) {
                        result[i] = result[i].replace(/^[']|[']$/g, "");
                    }
                }
            } else {
                result = false;
            }
        }
        return result;
    }

    eq(a, b) {
        return a == b;
    }

    //A zero value indicates that a == b.
    //A value greater than zero indicates that a > b; And a value less than zero indicates the opposite.
    compare(a, b) {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return +1;
        }
        return 0;
    }

    static arraySubtract(a, b, compare) {
        if (!compare) {
            compare = this.compare;
        }
        a = a.slice(0).sort();
        for (var i = 0; i < b.length; i++) {
            for (var j = 0; j < a.length; j++) {
                var result = compare(a[j], b[i]);
                if (result == 0) {
                    a.splice(j, 1);
                }
                if (result >= 0) {
                    break;
                }
            }
        }
        return a;
    }

    static deleteAllChildren(o) {
        for (var m in o) {
            if (o.hasOwnProperty(m)) {
                delete o[m];
            }
        }
    }

    static createMutator(expr) {
        if (expr) {
            // Remove [' and '] from mapping expressions
            expr = String(expr).replace(/['"]*[\]][\[]['"]*|['"]*[\]][.]*|[.]*[\[]['"]*/g, ".");
            expr = expr.replace(/^[.]*|[.]*$/g, "");
        }
        var parts = String(expr).split(".");
        if (parts.length > 0) {
            return function (obj, value, recreate, startPathIndex, merge) {
                var i = (Pan.isNumber(startPathIndex) ? startPathIndex : 0);

                // recreate means create an entirely new object, regardless of what was there in the old obj
                if (recreate) {
                    if (obj[parts[i]]) {
                        delete obj[parts[i]];
                    }
                }
                for (; i < parts.length - 1; i++) {
                    if (value !== undefined) {
                        obj = obj[parts[i]] = obj[parts[i]] || {};
                    } else {
                        if (obj[parts[i]]) {
                            obj = obj[parts[i]];
                        } else {
                            break;
                        }
                    }
                }
                if (value === undefined) {
                    if (i == parts.length - 1) {
                        delete obj[parts[i]];
                    }
                } else {
                    if (merge) {
                        Pan.apply(obj, value);
                    } else {
                        obj[parts[i]] = value;
                    }
                }
                return obj;
            };
        }
        return function (obj, value/*, recreate*/) {
            if (value === undefined) {
                if (obj[expr]) {
                    delete obj[expr];
                }
                return obj;
            }
            obj[expr] = value;
            return obj;
        };
    }

    static setValue(object, expr, value, recreate, startPathIndex) {
        return this.createMutator(expr)(object, value, recreate, startPathIndex);
    }

    static createAccessor(expr) {
        if (expr) {
            // Remove [' and '] from mapping expressions
            expr = String(expr).replace(/['"]*[\]][\[]['"]*|['"]*[\]][.]*|[.]*[\[]['"]*/g, ".");
            expr = expr.replace(/^[.]*|[.]*$/g, "");
        }
        var parts = String(expr).split(".");
        if (parts.length > 0) {
            return function (o, startPathIndex) {
                if (o) {
                    for (var i = startPathIndex || 0; i < parts.length; i++) {
                        o = o[parts[i]];
                        if (o !== 0 && !o) {
                            return undefined;
                        }
                    }
                }
                return o;
            };
        } else {
            return function (o) {
                if (o) {
                    return o[expr];
                } else {
                    return o;
                }
            };
        }
    }

    static callChain(field, callChain, args) {
        var log = PanLogging.getLogger('base:PanDecode');
        var parts = [];
        if (callChain.match(/\.*\)/)) {
            args = {
                multiOperands: []
            };
            var funcSplitParts = callChain.split(/[()]/g);
            if (funcSplitParts.length % 2 === 1) { // if even number, there is an unclosed ()
                for (var j = 0; j < funcSplitParts.length;) {
                    var subparts = funcSplitParts[j++].split(".");
                    for (var k = 0; k < subparts.length; k++) {
                        if (subparts[k] !== "") {
                            parts[parts.length] = subparts[k];
                        }
                    }
                    if (j < funcSplitParts.length) {
                        var argparts = funcSplitParts[j++].split(",");
                        args.multiOperands.push(argparts);
                        for (var l = 0; l < argparts.length; l++) {
                            if (argparts[l] === "") {
                                argparts[l] = undefined;
                            } else {
                                try {
                                    argparts[l] = Pan.decode(argparts[l]);
                                } catch (ex) {
                                    log.error("Unable to evaluate " + callChain);
                                    return;
                                }
                            }
                        }
                    }
                }
            }

            //return new Function('obj', 'try { return obj.' + callChain + ';} catch(ex) { return undefined; }')(json);
            /*
             try {
             return eval("json." + callChain);
             } catch (ex) {
             base.log("Unable to evaluate " + callChain);
             return
             }
             */
        } else {
            parts = String(callChain).split(".");
        }

        // set up arguments for function calls
        if (!Pan.isDefined(args)) {
            args = [];
        } else if (Pan.isArray(args.multiOperands)) {
            args = args.multiOperands;
        } else {
            args = [args];
        }
        var argsIndex = 0;

        var json = field;
        if (parts.length > 0) {
            for (var i = 0; json && i < parts.length; i++) {
                var scope = json;
                json = json[parts[i]]; // both object and array will use dot notation as part of the field
                while (Pan.isFunction(json)) {
                    var a = [];
                    if (argsIndex < args.length) {
                        a = args[argsIndex++];
                    }
                    json = json.apply(scope, a);
                }
            }
        }
        return json;
    }
}
