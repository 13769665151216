export const FETCH_SCHEMA_REQUEST = 'FETCH_SCHEMA_REQUEST'
export const FETCH_SCHEMA_SUCCESS = 'FETCH_SCHEMA_SUCCESS'
export const FETCH_SCHEMA_FAILURE = 'FETCH_SCHEMA_FAILURE'

export const FETCH_LOGS_REQUEST = 'FETCH_LOGS_REQUEST'
export const SET_LOGS_REQUEST_ID = 'SET_LOGS_REQUEST_ID'
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS'
export const FETCH_LOGS_FAILURE = 'FETCH_LOGS_FAILURE'

export const FETCH_LOGS_COUNT_REQUEST = 'FETCH_LOGS_COUNT_REQUEST'
export const SET_LOGS_COUNT_REQUEST_ID = 'SET_LOGS_COUNT_REQUEST_ID'
export const FETCH_LOGS_COUNT_SUCCESS = 'FETCH_LOGS_COUNT_SUCCESS'
export const FETCH_LOGS_COUNT_FAILURE = 'FETCH_LOGS_COUNT_FAILURE'

export const FETCH_RELATED_LOGS_REQUEST = 'FETCH_RELATED_LOGS_REQUEST'
export const SET_RELATED_LOGS_REQUEST_ID = 'SET_RELATED_LOGS_REQUEST_ID'
export const FETCH_RELATED_LOGS_SUCCESS = 'FETCH_RELATED_LOGS_SUCCESS'
export const FETCH_RELATED_LOGS_FAILURE = 'FETCH_RELATED_LOGS_FAILURE'

export const FETCH_USRCTX_REQUEST = 'FETCH_USRCTX_REQUEST'
export const FETCH_USRCTX_SUCCESS = 'FETCH_USRCTX_SUCCESS'
export const FETCH_USRCTX_FAILURE = 'FETCH_USRCTX_FAILURE'

export const INIT_EXPORT_REQUEST = 'INIT_EXPORT_REQUEST'
export const SET_EXPORT_REQUEST_ID = 'SET_EXPORT_REQUEST_ID'
export const INIT_EXPORT_SUCCESS = 'INIT_EXPORT_SUCCESS'
export const INIT_EXPORT_FAILURE = 'INIT_EXPORT_FAILURE'

export const CANCEL_EXPORT_REQUEST = 'CANCEL_EXPORT_REQUEST'
export const CANCEL_EXPORT_SUCCESS = 'CANCEL_EXPORT_SUCCESS'
export const REMOVE_CURRENT_EXPORT_JOBID = 'REMOVE_CURRENT_EXPORT_JOBID'
export const CANCEL_EXPORT_FAILURE = 'CANCEL_EXPORT_FAILURE'

export const FETCH_EXPORT_STATUS_REQUEST = 'FETCH_EXPORT_STATUS_REQUEST'
export const FETCH_EXPORT_STATUS_SUCCESS = 'FETCH_EXPORT_STATUS_SUCCESS'
export const FETCH_EXPORT_STATUS_FAILURE = 'FETCH_EXPORT_STATUS_FAILURE'

export const FETCH_EXPORT_HISTORY_REQUEST = 'FETCH_EXPORT_HISTORY_REQUEST'
export const FETCH_EXPORT_HISTORY_SUCCESS = 'FETCH_EXPORT_HISTORY_SUCCESS'
export const FETCH_EXPORT_HISTORY_FAILURE = 'FETCH_EXPORT_HISTORY_FAILURE'

export const FETCH_EXPORT_FILE_REQUEST = 'FETCH_EXPORT_FILE_REQUEST'
export const FETCH_EXPORT_FILE_SUCCESS = 'FETCH_EXPORT_FILE_SUCCESS'
export const FETCH_EXPORT_FILE_FAILURE = 'FETCH_EXPORT_FILE_FAILURE'

export const POLL_JOB_REQUEST = 'POLL_JOB_REQUEST'
export const POLL_JOB_FAILURE = 'POLL_JOB_FAILURE'

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST'
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER'
export const UPDATE_PAGE_LIMIT = 'UPDATE_PAGE_LIMIT'

export const UPDATE_QUERY = 'UPDATE_QUERY'
export const UPDATE_LOG_TYPE = 'UPDATE_LOG_TYPE'
export const UPDATE_TENANT_ID = 'UPDATE_TENANT_ID'
export const UPDATE_TIME_RANGE = 'UPDATE_TIME_RANGE'
export const UPDATE_TIME_PRESET = 'UPDATE_TIME_PRESET'
export const UPDATE_SORT_ORDER = 'UPDATE_SORT_ORDER'
