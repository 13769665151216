import { getAuthHeaders, getCustomServiceURL, getAdminServiceURL, MOCK_SERVICES } from 'ui-lib';

export function fetchRemoteNetworksStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/getFWaaSOverviewStat`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
    };
}

export function fetchMobileUsersStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/getGPaaSOverviewStat`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function fetchMobileUsersCurrentUsersObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/remoteActiveUsersCount`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function fetchMobileUsersCurrentUsersDetailObservable(queryString = '') {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/getGPaaSActiveUsers`
	);
	if(queryString !== ''){
		SERVICE_URL += '&query_str='+queryString+'';
	}
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
			headers: getAuthHeaders()
	};
}

export function fetchMobileUsersTotalUsersObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/remote90DaysUsersCount`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
			headers: getAuthHeaders()
	};
}

export function fetchMobileUsersTotalUsersDetailObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/getGPaaSLast90DaysUniqueUsers`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function fetchServiceConnectionStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/getServiceConnectionOverviewStat`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getGlobalMapContinentInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/getGlobalMapContinentInfo`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getDetailInfoObservable(header) {
	let param = '';
	if (header === 'Remote Networks') {
		param = 'getFWaaSRegionalStat';
	} else if (header === 'Mobile Users') {
		param = 'getGPaaSRegionalStat';
	} else {
		param = 'getServiceConnectionRegionalStat'
	}
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/status/${param}`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getDirctorySyncObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/directory/v1/status`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getSecondLevelDetailInfoObservable(header, locations) {
	let param = '';
	if (header === 'Remote Networks') {
		param = 'remoteNetworkRegionDetails';
	} else if (header === 'Mobile Users') {
		param = 'mobileUserRegionDetails';
	} else {
		param = 'serviceConnectionRegionDetails'
	}
	let locationStr = locations.reduce((currStr, location) => currStr += '"' + location + '",', '');
	locationStr = locationStr.substring(0, locationStr.length-2) + '"';
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/${param}?regions=[${locationStr}]`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getSecondLevelTableDetailInfoObservable(header, locations) {
	let param = '';
	if (header === 'Remote Networks') {
		param = 'remoteNetworkRegionStatistics';
	} else if (header === 'Service Connections') {
		param = 'serviceConnectionRegionStatistics';
	} 
	let locationStr = locations.reduce((currStr, location) => currStr += '"' + location + '",', '');
	locationStr = locationStr.substring(0, locationStr.length-2) + '"';
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/${param}?regions=[${locationStr}]`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getLicenseInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/license`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getTokenInfoObservable() {
	let { LOGIN_SERVICE_URL } = getAdminServiceURL(
		`api/ui/tokens`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: LOGIN_SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getApplicationsInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/contentservice/application`
	);

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}

export function getQoStatisticsObservable(site) {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/qosStatistics?site=${site}`
	);
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
        headers: getAuthHeaders()
	};
}