import React, {Component} from 'react';

export default class LoadingOverlay extends Component {
    render() {
        return (
            <p
              className="ag-overlay-loading-center"
              style={{ height: '9%'}}
            >
              {this.props.loadingMessage}
            </p>
        );
    }
}