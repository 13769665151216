import React, { Component } from 'react';
import { MapWidget } from '../../widgets';
import { FormContext } from "../../FormContext";
import { showModal, hideModal } from '../../../services/actions';
import { getStore, getStoreState } from '../../../utils/storeRegistry';
import { jsonPath, getPathValue } from '../../../utils/json';
import _ from 'lodash';
import { Button } from "reactstrap";
import Pan from '../../schema/Pan';
import './MapPageBuilder.scss';
import { JobManager } from '../../../../containers/jobManager';

export class MapPageBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markArea: [],
            mapData: [],
            hostName: "",
            provisioningStatus: 'in-progress'
        };
        this.toggleJobDetails = this.toggleJobDetails.bind(this);
    }

    populateFormData() {
        let { formData } = this.context;
        let defaultRegion = jsonPath(formData, 'stage1.deployment.region.entry.*') || [];
        let mapData = defaultRegion.reduce((acc, item) => {
            if (item.locations.member.length > 0 && Pan.isObject(item.locations.member[0])) {
                return acc.concat(item.locations.member.map((obj) => obj.value));
            }
            return acc.concat(item.locations.member);
        },
            []
        );
        const mapInfo = getPathValue(getStoreState(), 'gpcs.globalMapRegionInfo') || [];
        const markArea = mapInfo.filter(item => mapData.indexOf(item.name) >= 0).map((item) => {
            return {
                name: item['name'],
                coordinates: [item['@longitude'], item['@latitude']]
            }
        });
        let hostName = formData['@name'];
        this.setState({
            hostName,
            markArea,
            mapData
        })
    }

    componentDidMount() {
        getStore().dispatch({ type: 'POLLING_MU_PROVISIONING_STATUS' });
        if (getPathValue(getStoreState(), 'gpcs.muProvisionStatus') !== 'init') {
            this.setState({
                provisioningStatus: getPathValue(getStoreState(), 'gpcs.muProvisionStatus')
            });
        }
        this.populateFormData();
    }

    componentWillUnmount() {
        getStore().dispatch({
            type: 'STOP_POLLING_MU_PROVISION_STATUS',
            muProvisionStatus: this.state.provisioningStatus
        });
    }

    static getDerivedStateFromProps() {
        const _reduxState = getStoreState();
        const jobDetails = getPathValue(_reduxState, 'gpcs.jobDetails');
        const provisioningStatus = getPathValue(_reduxState, 'gpcs.muProvisionStatus') === 'init' ? 'in-progress' : getPathValue(_reduxState, 'gpcs.muProvisionStatus');
        if (jobDetails) {
            return {
                jobDetails,
                provisioningStatus
            };
        }

        return {
            provisioningStatus
        };
    }

    toggleJobDetails() {
        let modal = {
            id: "JobDetailFromMaplModal",
            title: "Job Details",
            size: "xl",
            type: "Info",
            toggle: () => {
                hideModal("JobDetailFromMaplModal");
            },
            modalBody: {
                component: JobManager,
                props: {}
            },
            actions: [{
                text: "Close",
                color: "secondary",
                action: () => {
                    hideModal("JobDetailFromMaplModal");
                }
            }]
        };
        showModal(modal);
    }

    defaultFillColor = geography => {
        const middle_east = [
            'Bahrain',
            'Cyprus',
            'Egypt',
            'Iran',
            'Iraq',
            'Israel',
            'Jordan',
            'Kuwait',
            'Lebanon',
            'Oman',
            'Qatar',
            'Saudi Arabia',
            'Syria',
            'Turkey',
            'United Arab Emirates',
            'Yemen',
        ];
        if (this.state.selectArea === '') {
            return '#d9d9d9';
        }
        if (this.state.selectArea === 'ANZ' && geography.properties.continent === 'Oceania') {
            return '#979797';
        }
        if (this.state.selectArea === 'Asia' && geography.properties.continent === 'Asia' && geography.properties.name !== 'Japan') {
            return '#979797';
        }
        if (this.state.selectArea === 'Japan' && geography.properties.name === 'Japan') {
            return '#979797';
        }
        if (this.state.selectArea === 'Middle East' && middle_east.indexOf(geography.properties.name) !== -1) {
            return '#979797';
        }
        if (this.state.selectArea === geography.properties.continent) {
            return '#979797';
        }
        return '#d9d9d9';
    };

    render() {
        return (
            <div className="out-container">
                <div className="map-top-container">
                    <div className="init-title">Initial Setup</div>
                    <span>This setup usually takes between 7 to 15 minutes. In the meantime, learn more about <a href="https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-cloud-managed-admin.html">Prisma Access</a>.</span>
                </div>
                <div className="map-middle-container">
                    <div className="job-details">
                        <span><strong>Job Details: </strong></span>
                        <a onClick={this.toggleJobDetails}>View commit status</a>
                    </div>
                    <div className="hostname">
                        <span><strong>Your Hostname: </strong></span>
                        <span className='status'>{this.state.hostName}</span>
                    </div>
                    <div className="map-bottom-btn-group mapbtn">
                        <Button
                            type="button"
                            color="primary"
                            onClick={(e) => {
                                this.props.actionMap.jumpToStep('$.stage_1', '$.stage1_step3');
                            }}
                            disabled={this.state.provisioningStatus !== 'success'}
                        >
                            Ready to Go
                        </Button>
                    </div>
                </div>
                <div className="regionMap" id="regionMap">
                    <MapWidget
                        defaultFillColor={this.defaultFillColor}
                        markType="pollingStatus"
                        markArea={this.state.markArea}
                        data={this.state.mapData}
                    />
                    <div className="center" id="center">
                        <div className="message">
                            {this.state.provisioningStatus === 'in-progress' &&
                                <div className="status">
                                    Provisioning <span className="in-progress">In Progress</span>
                                </div>
                            }
                            {this.state.provisioningStatus === 'success' &&
                                <div className="status">
                                    Provisioning <span className="success">successfully</span> completed
                                </div>
                            }
                            {this.state.provisioningStatus === 'failure' &&
                                <div className="status">
                                    Provisioning <span className="error">failed</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MapPageBuilder.contextType = FormContext;