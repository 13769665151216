import React from 'react';
import {
    FETCH_REMOTE_NETWORKS_STATUS_SUCCESS,
    FETCH_MOBILE_USERS_STATUS_SUCCESS,
    FETCH_SERVICE_CONNECTION_STATUS_SUCCESS,
    FETCH_CURRENT_USERS_DETAILS_INFO_SUCCESS,
    FETCH_MAP_DETAIL_INFO_SUCCESS,
    FETCH_MAP_DETAIL_INFO_COMBINE_SUCCESS,
    CLEAR_MAP_DETAIL_INFO_SUCCESS,
    FETCH_SECOND_LEVEL_MAP_DETAIL_INFO_SUCCESS,
    FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO_SUCCESS,
    CLEAR_TABLE_DETAIL_INFO,
    START_DATA_LOADING,
    FINISH_DATA_LOADING
} from "./actions";

const ACTION_HANDLERS = {
    [FETCH_REMOTE_NETWORKS_STATUS_SUCCESS]: (state, { remoteNetwork }) => {
        return {
            ...state,
            remoteNetwork
        };
    },
    [FETCH_MOBILE_USERS_STATUS_SUCCESS]: (state, { mobileUser }) => {
        return {
            ...state,
            mobileUser
        };
    },
    [FETCH_SERVICE_CONNECTION_STATUS_SUCCESS]: (state, { serviceConnection }) => {
        return {
            ...state,
            serviceConnection
        };
    },
    [FETCH_MAP_DETAIL_INFO_SUCCESS]: (state, { mapData, mapType }) => {
        return {
            ...state,
            mapData,
            mapType
        };
    },
    [FETCH_MAP_DETAIL_INFO_COMBINE_SUCCESS]: (state, { mapData }) => {
        return {
            ...state,
            mapData
        };
    },
    [FETCH_CURRENT_USERS_DETAILS_INFO_SUCCESS]: (state, { currentUsers }) => {
        return {
            ...state,
            currentUsers
        };
    },    
    [FETCH_SECOND_LEVEL_MAP_DETAIL_INFO_SUCCESS]: (state, { info }) => {
        return {
            ...state,
            info
        };
    },
    [FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO_SUCCESS]: (state, { info }) => {
        return {
            ...state,
            statInfo: info
        };
    },
    [CLEAR_MAP_DETAIL_INFO_SUCCESS]: (state, { mapData }) => {
        return {
            ...state,
            mapData
        };
    },
    [CLEAR_TABLE_DETAIL_INFO]: (state) => {
        return {
            ...state,
            info:{}
        };
    },
    [START_DATA_LOADING]: (state, { location }) => {
		return {
			...state,
            loadingLocation: location, 
            loadingState: true
		};
    },
    [FINISH_DATA_LOADING]: (state, { location }) => {
        return {
			...state,
            loadingLocation: location, 
            loadingState: false
		};
	},
};

const initialState = {};

export const dashboardReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};