import React, { Component } from "react";
import GridToolbar from "./GridToolbar";
import Pan from "../../schema/Pan";
import "./GridWidget.scss";
import PropTypes from "prop-types";
import { PanCellRenderer } from "./Renderers";
import { clickableColumn, nameColumn, checkboxColumn, dragDropCheckboxColumn } from "../../../../core/utils/CommonColumnConfig";
import { INJECTED_REC_ID } from "../util";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import _ from "lodash";
import LoadingOverlay from "./LoadingOverlay.js";
import { EditorGridCellBuilder } from "../../builders/EditorGridCellBuilder";

const DEFAULT_PAGE_SIZE = 500;
const GRID_ROW_HEIGHT = 28;
const TOOLBAR_POSITION = "top";
const _T = str => str;

const stringToHash = (string) =>  { 
                  
	let hash = 0; 
		
	if (!string || string.length == 0) return hash; 
		
	for (let i = 0; i < string.length; i++) { 
			const char = string.charCodeAt(i); 
			hash = ((hash << 5) - hash) + char; 
			hash = hash & hash; 
	} 
		
	return hash; 
} 

export class GridWidget extends Component {
	constructor(props) {
		super(props);
		// let pageCount = this.getPageCount(props);
		let columns = [...props.columns];
		if (props.checkboxSelection !== false && columns.length > 0) {
			columns.splice(0, 0, props.allowDrag ? dragDropCheckboxColumn : checkboxColumn);
		}

		if (props.allowDrag && columns.length > 0) {
			Pan.apply(columns[0], {
				rowDrag:function(params) {
					return props.allowDrag(params)
				}
			});
        }
        
		this.state = {
			gridData: props.gridData || [],
			columns,
			selected: {},
			expanded: {},
			hash: null
		};
		this.hoverNode = null;
		this.selectedElements = [];
		this.onExportCSV = this.onExportCSV.bind(this);
	}

	// editColumn = null;
	// __editColumn = null;

	static getDerivedStateFromProps(nextProps, _) {
		return {
			gridData: nextProps.gridData || []
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps.columns, this.props.columns)){
			let {columns} = this.props;
			if (this.props.checkboxSelection !== false && columns.length > 0) {
				columns.splice(0, 0, this.props.allowDrag ? dragDropCheckboxColumn : checkboxColumn);
			}
			this.setState({
				columns: this.props.columns
			});
		}		
		if (this.gridApi) {
			this.gridApi.setQuickFilter(this.props.filterText);
			if (this.props.loading === true ) {
				this.gridApi.showLoadingOverlay();
			} else {
				//onRowDataUpdated need this commented out

				// if (this.state.hash !== stringToHash(JSON.stringify(this.props.gridData))) {
				// 	this.setState({
				// 		hash: stringToHash(JSON.stringify(this.props.gridData))
				// 	})
					this.gridApi.setRowData(this.props.gridData);
				// }
				this.gridApi.hideOverlay();
				// this.gridApi.resetRowHeights();
				this.props.scrollIndex && this.gridApi.ensureIndexVisible(this.props.scrollIndex,'middle');
			}
			this.gridApi.sizeColumnsToFit();
		}
	}

	getPageCount(props) {
		let totalCount = props.totalCount || (props.gridData ? props.gridData.length : 0);
		let pageSize = props.pageSize || DEFAULT_PAGE_SIZE;
		let pageCount = Math.ceil(totalCount / pageSize);
		return pageCount;
	}

	setEditingRowColumn = (rowIndex, colKey) => {
		setTimeout(() => {
			this.gridApi &&
				this.gridApi.startEditingCell({
					rowIndex,
					colKey
				});
		}, 500);
	};

	onExpandedChange = (newExpanded, index, event) => {
		if (newExpanded[index[0]] === false) {
			newExpanded = {};
		} else {
			Object.keys(newExpanded).map(k => {
				newExpanded[k] = parseInt(k) === index[0] ? {} : false;
			});
		}
		this.setState({ expanded: newExpanded });
	};

	getIdColumn = () => {
		return this.props.idColumn || "@name";
	}

	changePage = page => {
		this.gridApi.paginationGoToPage(page - 1);
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		// setGridData
		this.setState({ gridData: this.props.gridData });
		this.columnsResize(params, true);
	};

	columnsResize = (params, onReady) => {
		if (this.props.columnsResizeFn) {
			this.props.columnsResizeFn(params);
		} else {
			if (this.props.suppressColsSizeToFit === true) {//this condition does not work with flex columns
				if (onReady) {
					this.allColumnIds = [];
					this.gridColumnApi.getAllColumns().forEach(function(column) {
						this.allColumnIds.push(column.colId);
					}, this);
				}
				const skipHeader = this.props.skipHeaderOnAutoSize;
				this.gridColumnApi.autoSizeColumns(this.allColumnIds, skipHeader);
			} else {// resize columns to fit in the grid
				this.gridApi.sizeColumnsToFit();
				if (onReady) {
					window.onresize = () => {
						this.gridApi.sizeColumnsToFit();
					}
				}
			}
		} 
	}

	onSelectionChanged = event => {
		this.setState({
			selected: this.getSelectedMap()
		}, () => {
			this.props.toggleRowSelection && this.props.toggleRowSelection(this.state.selected)
		});
	};

	getSelectedMap = () => {
		let selected = this.getSelected();
		let selectedMap = selected.reduce((result, curr) => {
			let itemId = curr[this.getIdColumn()] || curr[INJECTED_REC_ID];
			result[itemId] = curr;
			return result;
		}, {});
		return selectedMap;
	};

	getSelected = () => {
		return this.gridApi ? this.gridApi.getSelectedRows() : [];
	};

	onFirstDataRendered = params => {
		this.columnsResize(params);
	};

	getRowNodeId = data => {
		return data[INJECTED_REC_ID] || data[this.getIdColumn()];
	};

	getMaxLength = (params, cols) => {
		const { data } = params;
		let maxLen = 1;
		cols = this.getAllColumns(cols);
		cols.forEach(item => {
			if (Pan.isDefined(item.valueGetter)) {
				let value = item.valueGetter({ data });
				if (_.isArray(value)) {
					value = _.flattenDeep(value);
					maxLen = Math.max(maxLen, value.length);
				}
			} else {
				let field = _.get(data, item.field);
				if (Pan.isDefined(field) && field.hasOwnProperty("member")) {
					// This solves if the data is manipulated using cellRendererParams function
					field = _.get(data, item.field + ".member");
				}
				if (_.isArray(field)) {
					maxLen = Math.max(maxLen, field.length);
				}
			}
		});
		return maxLen;
	};

	getAllColumns = (cols) => {
		let columns = [];
		cols.forEach(col => {
			if (Pan.isDefined(col.children)) {
				columns.push(this.getAllColumns(col.children));
			} else {
				columns.push(col);
			}
		});
		return _.flattenDeep(columns);
	}

	/***
	 * Grid contraints
	 * 1. data should be in this json format:
	 * {
	 *      result: {
	 *          result: {
	 *              entry: [ all grid records],
	 *              totalCount: 100
	 *          }
	 *      }
	 * }
	 * 2. dafault page size is set if not mentioned
	 */
	getSelectedNodes(api){
		const nodesArray=api.getSelectedNodes();
		return nodesArray.map(node=>{
			return node.childIndex;
		})
	}

	getElementsToMove(selectedNodes, currentIndex, newIndex) {
		const currInSelect = _.includes(selectedNodes, currentIndex);
		const destInSelect = _.includes(selectedNodes, newIndex);

		if ((currInSelect && destInSelect) || currentIndex === newIndex) {
			return []
		}
		if (currInSelect && !destInSelect) {
			return selectedNodes
		}
		return [currentIndex]

	}

	getselectedElements(e, currentIndex, newIndex) {
		const selectedNodes = this.getSelectedNodes(e.api);
		return this.getElementsToMove(selectedNodes, currentIndex, newIndex);
	}

	onRowDragEnd = (e) => {
		this.selectedElements = [];
		const currentIndex = e.node.childIndex;
		const newIndex = e.overIndex;
		this.setHoverNode(e.api, null);
		const { onMoveDrag, gridData, allowDrag } = this.props;
		if (!allowDrag(e.overNode)) {
			return;
		}
		const elements = this.getselectedElements(e, currentIndex, newIndex);
		if (elements.length > 0) {
			elements.sort();
			const selectedData = elements.map(index => {
				return gridData[index]
			})
			if (gridData[newIndex] && onMoveDrag) {
				const moveTop = this.shouldMoveUp(elements,newIndex);
				onMoveDrag(selectedData, newIndex, gridData, elements, e.api, moveTop);
			}
		}
	}

	onRowDragMove = (e) => {
		const currentIndex = e.node.childIndex;
		const newIndex = e.overIndex;
		this.selectedElements = this.getselectedElements(e, currentIndex, newIndex).sort();
		this.setHoverNode(e.api, e.overNode);
	}

	onRowDragLeave(e) {
		this.setHoverNode(e.api, null);	
	}

	shouldMoveUp(selectedElements, newIndex) {
		const firstIndex = _.first(selectedElements.sort());
		return newIndex < firstIndex ? true : false;
	}

	shouldHoverTop(allowDrop, shoudHoverBottom) {
		return function (params) {
			if (allowDrop && allowDrop(params, this.hoverNode)) {
				let moveTop = this.shouldMoveUp(this.selectedElements, params.rowIndex);
				return shoudHoverBottom ? !moveTop : moveTop;
			}
		}
	}

	setHoverNode(api, overNode) {
		let newHoverNode;
		if (overNode) {
		  newHoverNode = overNode;
		} else {
		  newHoverNode = null;
		}
		let alreadySelected = this.hoverNode === newHoverNode;
		if (alreadySelected) {
		  return;
		}
		let rowsToRefresh = [];
		if (this.hoverNode) {
		  rowsToRefresh.push(this.hoverNode);
		}
		if (newHoverNode) {
		  rowsToRefresh.push(newHoverNode); 
		}
		this.hoverNode = newHoverNode;
		this.refreshRows(api, rowsToRefresh);
	}

	refreshRows(api, rowsToRefresh) {
		let params = {
		  rowNodes: rowsToRefresh,
		  force: true
		};
		api.refreshCells(params);
	}

	onExportCSV() {
		const { exportFileParams } = this.props;
		this.gridApi.exportDataAsCsv(exportFileParams);
	}

	render() {
		let pageSize = this.props.pageSize || DEFAULT_PAGE_SIZE;
		let gridActions = this.props.gridActions || [];
		let newGridActions = [];
        let {allowDrop} = this.props;
		if (gridActions) {
			gridActions.forEach(action => {
				if (action.avail) {
					action.disabled = action.avail(this.state.selected, this.props.gridData, this.getIdColumn());
				}
				newGridActions.push(action);
			});
		}
		let rowClassRules = { ...this.props.rowClassRules, 'disabled-row': "data['disabled'] === 'yes'" };
		let toolbarPosition = this.props.toolbarPosition || TOOLBAR_POSITION;
		let showPaginationTop = toolbarPosition === "top";
		let showPaginationBottom = toolbarPosition !== "top";
		let { showPaging = true, showGridToolBar = true, editable = false, sortable = false, enableRangeSelection = false, suppressRowClickSelection = false } = this.props;

		let { errors, showErrors, disabled, className, minRows, frameworkComponents = {} } = this.props;
		className = className || '';
		className = this.props.allowDrag ? className + ' pan-allow-drag' : className;
		let gridClassName = showErrors && errors && !disabled ? "is-invalid " : "";
		let noDataText = this.props.noDataText ? this.props.noDataText : "This table will populate as you add items";
		let overlayNoRowsTemplate = `<span>${noDataText}</span>`;
		let dataLength = (this.props.gridData && this.props.gridData.length) || 0;
		dataLength = Math.min(dataLength, pageSize);
		const pagingBarHeight = showGridToolBar && showPaginationTop ? 36 : 0;
		let gridHeaderRowCount = 1
		if (this.gridColumnApi && this.gridColumnApi.columnController) {
			gridHeaderRowCount = this.gridColumnApi.columnController.gridHeaderRowCount;
		}
		let gridHeight = minRows && Math.max(minRows * GRID_ROW_HEIGHT + gridHeaderRowCount * 32 + pagingBarHeight, 130)+'px' || ''; //add table header + paging bar height
		let style = { height: `${gridHeight}`, ...this.props.style};
		let context = this.props.agContext || {};
		let agGridProps = this.props.agGridProps || {};

		let GridToolBarComp = (
			<GridToolbar
				{...this.props}
				gridActions={newGridActions}
				selected={this.state.selected}
				changePage={this.props.changePage || this.changePage}
				showPaging={showPaging}
				showFilter={this.props.showFilter}
				showExportCsvBtn={this.props.showExportCsvBtn}
				onExportCSV={this.onExportCSV}
				pages={this.props.pages || this.getPageCount(this.props)}
			/>
		);

		return (
			<React.Fragment>
				<div className={"pan-ag-grid " + (className  || "")} style={style}>
					{showGridToolBar && showPaginationTop && GridToolBarComp}
					<div className={"ag-theme-balham " + (gridClassName || "")} style={{ height: showGridToolBar && showPaginationTop ? "calc(100% - 34px)" : "100%" }}>
						<AgGridReact
              reactNext={true}
							columnDefs={this.state.columns}
							rowSelection={this.props.rowSelection || "multiple"} // default row selection is multiple
							defaultColDef={{
								// default column props
								sortable: sortable,
								resizable: true,
								filter: true,
								minWidth: 75,
								editable: editable,
								autoHeight: true,
								cellRenderer: "panCellRenderer",
								cellClassRules: {
									"hover-over-bottom": this.shouldHoverTop(allowDrop, true).bind(this),
									"hover-over-top": this.shouldHoverTop(allowDrop, false).bind(this)
								}
							}}
							frameworkComponents={{
								editorGridCellBuilder: EditorGridCellBuilder,
								panCellRenderer: PanCellRenderer,
								loadingOverlay: LoadingOverlay,
								...frameworkComponents
							}}
							loadingOverlayComponent="loadingOverlay"
							loadingOverlayComponentParams={{
								loadingMessage: _T("Loading data...")
							}}
							columnTypes={{
								clickableColumn: clickableColumn,
								nameColumn: nameColumn
							}}
							context={context}
							
							getRowNodeId={this.getRowNodeId}
							rowClassRules={rowClassRules}
							suppressPaginationPanel={true}
							pagination={true}
							paginationPageSize={pageSize}
							onGridReady={this.onGridReady}
							onSelectionChanged={this.onSelectionChanged}
							// editType='fullRow'
							suppressRowClickSelection={suppressRowClickSelection}
							enableRangeSelection={enableRangeSelection}
							suppressCellSelection={true}
							suppressContextMenu={true}
							enableCellTextSelection={true}
							// singleClickEdit={true}
							deltaRowDataMode={true}
							onFirstDataRendered={this.onFirstDataRendered}
							overlayNoRowsTemplate={overlayNoRowsTemplate}
							suppressRowDrag={false}
							animateRows={true}
							onRowDragEnd={this.onRowDragEnd}
							onRowDragMove={this.onRowDragMove}
							getRowHeight={
								params => {
								if(this.props.getRowHeight) {
									return this.props.getRowHeight(params);
								}
								//TODO: remove this fix, once ag-grid enterprise supports array rendering in columns.
								const len = this.getMaxLength(params, this.state.columns);
								return len * 26;
							}}
							{...agGridProps} // should be at the bottom to override anything above
						/>
						{showGridToolBar && showPaginationBottom && GridToolBarComp}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

GridWidget.defaultProps = {
	columns: [],
};

if (process.env.NODE_ENV !== "production") {
	GridWidget.propTypes = {
		gridData: PropTypes.array,
		columns: PropTypes.array.isRequired,
		pageSize: PropTypes.number,
		minRows: PropTypes.number,
		gridActions: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string,
				color: PropTypes.oneOf(["primary", "secondary", "success", "link"]),
				callback: PropTypes.func.isRequired
			})
		),
		className: PropTypes.string,
		toolbarPosition: PropTypes.string,
		showPaging: PropTypes.bool,
		showFilter: PropTypes.bool,
		filterText: PropTypes.string,
		style: PropTypes.object,
		errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		showErrors: PropTypes.bool,
		disabled: PropTypes.bool,
		getTbodyProps: PropTypes.func,
		getTableProps: PropTypes.func,
		defaultSorted: PropTypes.array,
		checkboxSelection: PropTypes.bool,
		totalCount: PropTypes.number,
		toggleRowSelection: PropTypes.func,
		toggleSelectAll: PropTypes.func,
		idColumn: PropTypes.string,
		showGridToolBar: PropTypes.bool,
		SubComponent: PropTypes.func,
		noDataText: PropTypes.string
	};
}
