import { BaseBuilder } from "./BaseBuilder";
import Pan from "../../schema/Pan";

export class BaseFieldBuilder extends BaseBuilder {
    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(nextProps, _) {
        const { field } = nextProps;
        let uiHint = field ? field.uiHint : null;
        if (uiHint && uiHint.disabled === true) {
            return {
                disableField: true
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, _) {
        if (!prevProps.disabled && this.props.disabled) {
            //change in props to enable this field. Trigger validations
            this.executeValidationsAtRender(this.props);
        } else if (!this.props.disabled && prevProps.disabled) {
            //change in props to disable this field. Delete validation errors
            this.removeErrors();
        }
    }

    componentDidMount() {
        this.executeValidationsAtRender(this.props);
        super.componentDidMount();
    }

    executeValidationsAtRender(props) {
        const value = this.state.value || this.getRecordValue();
        let { disabled } = props;
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        if (!error && !disabled) {
            //RUN VALIDATION
            this.runFieldValidationAtRender(value);
        }
    }

    componentWillUnmount() {
        //remove erros if any set for this 
        if (!this.props.isEditorGridColumnEditor) {
            //for editor grid record, dont remove error on unmount.
            //it is hadled in delete record or set record value
            this.removeErrors();
        }
    }

}