import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';
import { Button } from "reactstrap";
import _ from 'lodash';
import { FormContext, FormConsumer } from "../../FormContext";
import './Wizard.scss';
import Pan from "../../schema/Pan";
import { isAllFieldsValid } from '../util';
import { showModal, hideModal } from "../../../services/actions";

export class Wizard extends Component {
    constructor(props) {
        super(props);

        const { steps } = props;
        const currentStepName = steps[props.startAtStep].stepName;
        this.state = {
            currentStep: this.props.startAtStep,
            currentStepName,
            navState: this.getNavStates(this.props.startAtStep, this.props.steps.length)
        };

        this.hidden = {
            display: 'none'
        };

        // if user did not give a custom nextTextOnFinalActionStep, the nextButtonText becomes the default
        this.nextTextOnFinalActionStep = (this.props.nextTextOnFinalActionStep) ? this.props.nextTextOnFinalActionStep : this.props.nextButtonText;
        this.applyValidationFlagsToSteps();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stepIndex = nextProps.steps.findIndex((step) => {
            return step.stepName === prevState.currentStepName;
        })
        stepIndex = stepIndex == -1 ? 0 : stepIndex;
        if (prevState.currentStep !== stepIndex) {
            return { currentStep: stepIndex };
        }
        return null;
    }

    // extend the "steps" array with flags to indicate if they have been validated
    applyValidationFlagsToSteps() {
        this.props.steps.map((i, idx) => {
            if (this.props.dontValidate) {
                i.validated = true;
            } else {
                // check if isValidated was exposed in the step, if yes then set initial state as not validated (false) or vice versa
                // if HOCValidation is used for the step then mark it as "requires to be validated. i.e. false"
                i.validated = (
                    typeof i.component.type === 'undefined' ||
                    (typeof i.component.type.prototype.isValidated === 'undefined' && !this.isStepAtIndexHOCValidationBased(idx))
                ) ? true : false;
            }
            return i;
        });
    }

    // update the header nav states via classes so they can be styled via css
    getNavStates(indx, length) {
        const styles = [];
        for (let i = 0; i < length; i++) {
            if (i < indx) {
                styles.push('done');
            } else if (i === indx) {
                styles.push('doing');
            } else {
                styles.push('todo');
            }
        }
        return { current: indx, styles };
    }

    getPrevNextBtnLayout(currentStep) {
        // first set default values
        let showPreviousBtn = true;
        let nextStepText = this.props.nextButtonText;

        // first step hide previous btn
        if (currentStep === 0) {
            showPreviousBtn = false;
        }

        // last step change next btn text if supplied as props
        if (currentStep === this.props.steps.length - 1) {
            nextStepText = this.props.nextTextOnFinalActionStep || nextStepText;
            showPreviousBtn = this.props.prevBtnOnLastStep === false ? false : true;
        }

        return {
            showPreviousBtn,
            nextStepText
        };
    }

    // which step are we in?
    checkNavState(nextStep) {
        if (this.props.onStepChange) {
            this.props.onStepChange(nextStep);
        }
    }

    // set the nav state
    setNavState(next) {
        this.setState({ navState: this.getNavStates(next, this.props.steps.length) });
        this.setState({ hideProgressTracker: this.props.steps[next] && this.props.steps[next].field && this.props.steps[next].field.uiHint.optional });
        if (next < this.props.steps.length) {
            this.setState({ 
                currentStep: next,
                currentStepName: this.props.steps[next].stepName
            });
        }

        this.checkNavState(next);
    }

    // handles keydown on enter being pressed in any Child component input area. 
    // in this case it goes to the next (ignore textareas as they should allow line breaks)
    handleKeyDown(evt) {
        if (evt.which === 13) {
            if (!this.props.preventEnterSubmission && evt.target.type !== 'textarea') {
                this.next();
            } else if (evt.target.type !== 'textarea') {
                evt.preventDefault();
            }
        }
    }

    // this utility method lets Child components invoke a direct jump to another step
    jumpToStep(evt) {
        if (typeof evt.target === 'undefined') {
            // a child step wants to invoke a jump between steps. 
            // in this case 'evt' is the numeric step number or the step field name and not the JS event
            if (typeof evt === 'string') {
                const { steps } = this.props;
                let stepIndex = steps.findIndex((s) => {
                    return s.stepName === evt;
                });
                stepIndex = stepIndex !== -1 ? stepIndex : 0;
                this.setNavState(stepIndex);
            } else {
                this.setNavState(evt);
            }
        } else { // the main navigation step ui is invoking a jump between steps
            // if stepsNavigation is turned off or user clicked on existing step again (on step 2 and clicked on 2 again) then ignore
            if (!this.props.stepsNavigation || evt.target.parentElement.value === this.state.currentStep) {
                evt.preventDefault();
                evt.stopPropagation();
                return;
            }

            // evt is a react event so we need to persist it as we deal with aync promises which nullifies these events 
            // (https://facebook.github.io/react/docs/events.html#event-pooling)
            evt.persist();

            const movingBack = evt.target.parentElement.value < this.state.currentStep; // are we trying to move back or front?
            let passThroughStepsNotValid = false; // if we are jumping forward, only allow that if inbetween steps are all validated. This flag informs the logic...
            let proceed = false; // flag on if we should move on

            this.abstractStepMoveAllowedToPromise(movingBack)
                .then((valid = true) => {
                    // validation was a success (promise or sync validation). In it was a Promise's resolve()
                    // ... then proceed will be undefined, so make it true. Or else 'proceed' will carry the true/false value from sync
                    proceed = valid;

                    if (!movingBack) {
                        this.updateStepValidationFlag(proceed);
                    }

                    if (proceed) {
                        if (!movingBack) {
                            // looks like we are moving forward, 'reduce' a new array of step>validated values we need to check and
                            // ... 'some' that to get a decision on if we should allow moving forward
                            passThroughStepsNotValid = this.props.steps
                                .reduce((a, c, i) => {
                                    if (i >= this.state.currentStep && i < evt.target.parentElement.value) {
                                        a.push(c.validated);
                                    }
                                    return a;
                                }, [])
                                .some((c) => {
                                    return c === false;
                                });
                        }
                    }
                })
                .catch(() => {
                    // Promise based validation was a fail (i.e reject())
                    if (!movingBack) {
                        this.updateStepValidationFlag(false);
                    }
                })
                .then(() => {
                    if (evt.target.parentElement.tagName !== 'LI') {
                        return;
                    }
                    // this is like finally(), executes if error no no error
                    if (proceed && !passThroughStepsNotValid) {
                        if (evt.target.parentElement.value === (this.props.steps.length - 1)
                            && this.state.currentStep === (this.props.steps.length - 1)) {
                            this.setNavState(this.props.steps.length);
                        } else {
                            this.setNavState(evt.target.parentElement.value);
                        }
                    }
                })
                .catch(e => {
                    if (e) {
                        // see note below called "CatchRethrowing"
                        // ... plus the finally then() above is what throws the JS Error so we need to catch that here specifically
                        setTimeout(() => { throw e; });
                    }
                });
        }
    }

    // move next via next button
    next() {
        this.abstractStepMoveAllowedToPromise()
            .then((proceed = true) => {
                // validation was a success (promise or sync validation). In it was a Promise's resolve() then proceed will be undefined,
                // ... so make it true. Or else 'proceed' will carry the true/false value from sync validation
                this.updateStepValidationFlag(proceed);

                if (proceed) {
                    this.setNavState(this.state.currentStep + 1);
                }
            })
            .catch((e) => {
                if (e) {
                    // CatchRethrowing: as we wrap StepMoveAllowed() to resolve as a Promise, the then() is invoked and the next React Component is loaded.
                    // ... during the render, if there are JS errors thrown (e.g. ReferenceError) it gets swallowed by the Promise library and comes in here (catch)
                    // ... so we need to rethrow it outside the execution stack so it behaves like a notmal JS error (i.e. halts and prints to console)
                    //
                    setTimeout(() => { throw e; });
                }

                // Promise based validation was a fail (i.e reject())
                this.updateStepValidationFlag(false);
            });
    }

    // move behind via previous button
    previous() {
        if (this.state.currentStep > 0) {
            this.setNavState(this.state.currentStep - 1);
        }
    }

    // update step's validation flag
    updateStepValidationFlag(val = true) {
        this.props.steps[this.state.currentStep].validated = val; // note: if a step component returns 'underfined' then treat as "true".
    }

    // are we allowed to move forward? via the next button or via jumpToStep?
    stepMoveAllowed(skipValidationExecution = false) {
        let proceed = false;

        if (this.props.dontValidate) {
            proceed = true;
        } else {
            if (skipValidationExecution) {
                // we are moving backwards in steps, in this case dont validate as it means the user is not commiting to "save"
                proceed = true;
            } else if (this.isStepAtIndexHOCValidationBased(this.state.currentStep)) {
                // the user is using a higer order component (HOC) for validation (e.g react-validation-mixin), this wraps the Wizard steps as a HOC,
                // so use hocValidationAppliedTo to determine if this step needs the aync validation as per react-validation-mixin interface
                proceed = this.refs.activeComponent.refs.component.isValidated();
            } else {
                // user is moving forward in steps, invoke validation as its available
                proceed = this.props.isValidated(this.props.steps[this.state.currentStep].field);
            }
        }

        return proceed;
    }

    isStepAtIndexHOCValidationBased(stepIndex) {
        return (this.props.hocValidationAppliedTo.length > 0 && this.props.hocValidationAppliedTo.indexOf(stepIndex) > -1);
    }

    // a validation method is each step can be sync or async (Promise based), this utility abstracts 
    // the wrapper stepMoveAllowed to be Promise driven regardless of validation return type
    abstractStepMoveAllowedToPromise(movingBack) {
        return Promise.resolve(this.stepMoveAllowed(movingBack));
    }

    validateToNextStep = (next) => {
        this.abstractStepMoveAllowedToPromise()
            .then((proceed = true) => {
                // validation was a success (promise or sync validation). In it was a Promise's resolve() then proceed will be undefined,
                // ... so make it true. Or else 'proceed' will carry the true/false value from sync validation
                this.updateStepValidationFlag(proceed);

                if (proceed) {
                    next();
                }
            })
            .catch((e) => {
                if (e) {
                    // CatchRethrowing: as we wrap StepMoveAllowed() to resolve as a Promise, the then() is invoked and the next React Component is loaded.
                    // ... during the render, if there are JS errors thrown (e.g. ReferenceError) it gets swallowed by the Promise library and comes in here (catch)
                    // ... so we need to rethrow it outside the execution stack so it behaves like a notmal JS error (i.e. halts and prints to console)
                    //
                    setTimeout(() => { throw e; });
                }

                // Promise based validation was a fail (i.e reject())
                this.updateStepValidationFlag(false);
            });
    }

    // get the classmame of steps
    getClassName(className, i) {
        let liClassName = `${className}-${this.state.navState.styles[i]}`;

        // if step ui based navigation is disabled, then dont highlight step
        if (!this.props.stepsNavigation) {
            liClassName += ' no-hl';
        }

        return liClassName;
    }

    // render the steps as stepsNavigation
    renderSteps() {
        return this.props.steps.map((s, i) => (
            <li className={this.getClassName('progtrckr', i)} onClick={(evt) => { this.jumpToStep(evt); }} key={i} value={i}>
                <em>{i + 1}</em>
                {s.name && <span>{this.props.steps[i].name}</span>}
            </li>
        ));
    }

    renderButtons() {
        const { props } = this;
        const { nextStepText, showPreviousBtn } = this.getPrevNextBtnLayout(this.state.currentStep);
        let { steps, showOverview, showBack, showCommitAndPush, showNext, showSave } = props;
        showBack = this.state.hideProgressTracker == true ? false : showPreviousBtn;
        let { buttonsAvail } = steps[this.state.currentStep].field.uiHint;
        if (buttonsAvail) {
            for (var p in buttonsAvail) {
                let avail = Pan.isFunction(buttonsAvail[p]) ? buttonsAvail[p]() : buttonsAvail[p];
                switch (p) {
                    case 'back':
                        showBack = avail;
                        break;
                    case 'next':
                        showNext = avail;
                        break;
                    case 'overview':
                        showOverview = avail;
                        break;
                    case 'save':
                        showSave = avail;
                        break;
                    case 'commitandpush':
                        showCommitAndPush = avail;
                        break;
                }
            }
        }
        if (showOverview || showBack || showCommitAndPush || showNext || showSave) {
            return <div className="form-footer-actions d-flex justify-content-between bg-white btnContainer">
                <div>
                    <Button
                        color="secondary"
                        style={showOverview ? {} : this.hidden}
                        onClick={e => {
                            e.preventDefault();
                            this.props.fromEditToSummary ? this.props.fromEditToSummary() : null;
                        }}>
                        Overview
                    </Button>
                </div>
                <div>
                    <Button
                        type="button" color="secondary"
                        style={showBack ? {} : this.hidden}
                        className={props.backButtonCls}
                        onClick={() => { this.previous(); }}
                        id="prev-button">
                        {this.props.backButtonText}
                    </Button>
                    <Button
                        type="button" color="primary"
                        style={showNext ? {} : this.hidden}
                        className={props.nextButtonCls}
                        disabled={isAllFieldsValid(this.props.errors)}
                        onClick={(e) => {
                            let { jumpToSummaryFromStep } = this.props;
                            const currentState = this.state.currentStep + 1;
                            jumpToSummaryFromStep = jumpToSummaryFromStep || [];
                            if (Pan.isNumber(jumpToSummaryFromStep)) {
                                jumpToSummaryFromStep = [jumpToSummaryFromStep];
                            }
                            if (jumpToSummaryFromStep.indexOf(currentState) !== -1) {
                                this.validateToNextStep(() => {
                                    if (this.props.onNext) {
                                        this.props.onNext(this.state.currentStep, this.context, this.props.onGoToSummary);
                                    } else {
                                        this.props.onGoToSummary(e);
                                    }
                                });
                            } else if (this.props.jumpToMapPage && this.props.onGoToMapPage) {
                                this.props.onGoToMapPage(e);
                            } else if (this.state.currentStep === (this.props.steps.length - 1) && this.props.goToSummary) {
                                this.validateToNextStep(() => this.props.onGoToSummary(e));
                            } else if (this.state.currentStep === (this.props.steps.length - 1)) {
                                this.props.onSubmit(e);
                            } else {
                                if (this.props.onNext) {
                                    this.props.onNext(this.state.currentStep, this.context, this.next.bind(this));
                                } else {
                                    this.next();
                                }
                            }
                        }}
                        id="next-button">
                        {nextStepText}
                    </Button>
                    <Button
                        type="button" color="primary"
                        style={showSave ? {} : this.hidden}
                        className={props.saveButtonCls}
                        disabled={isAllFieldsValid(this.props.errors)}
                        onClick={(e) => {
                            this.validateToNextStep(() => {
                                this.props.onSubmit(e);
                                this.props.onGoToSummary(e);
                            });
                        }}
                        id="save-button">
                        {this.props.saveButtonText}
                    </Button>
                    <Button
                        type="button" color="primary"
                        style={showCommitAndPush ? {} : this.hidden}
                        className={props.commitAndPushButtonCls}
                        disabled={isAllFieldsValid(this.props.errors)}
                        onClick={(e) => {
                            let actionButtons = [];
                            actionButtons.push({
                                text: "No",
                                color: "secondary",
                                action: () => {
                                    hideModal("CommitAndPushConfirmation");
                                }
                            });
                            actionButtons.push({
                                text: "Yes",
                                color: "primary",
                                action: (evt) => {
                                    hideModal("CommitAndPushConfirmation");
                                    if (props.customValidationToSpecialSubmit) {
                                        props.customValidationToSpecialSubmit(
                                            this.state.currentStep,
                                            this.context.formData,
                                            () => {
                                                this.props.onSpecialSubmit(e);
                                            }
                                        );
                                    } else {
                                        this.props.onSpecialSubmit(e);
                                    }
                                    this.next();
                                }
                            });
                            showModal({
                                id: "CommitAndPushConfirmation",
                                open: true,
                                size: "md",
                                toggle: () => {
                                    hideModal("CommitAndPushConfirmation");
                                },
                                title: 'Commit and Push',
                                message: 'Doing a commit and Push will overwrite the running configuration with all the changes and pushes only the Mobile users changes to Prisma Access. Are you sure you want to commit and push now?',
                                actions: actionButtons
                            });
                        }}
                        id="commit-and-push-button">
                        {this.props.commitAndPushButtonText}
                    </Button>
                </div>
            </div>
        } else {
            return null;
        }
    }

    // main render of Wizard container
    renderWizard = () => {
        // clone the step component dynamically and tag it as activeComponent so we can validate it on next. also bind the jumpToStep piping method
        const cloneExtensions = {
            jumpToStep: (t) => {
                this.jumpToStep(t);
            },
            next: this.next.bind(this)
        };

        const componentPointer = this.props.steps[this.state.currentStep].component;
        const showWideForm = this.props.steps[this.state.currentStep].showWideForm;

        // can only update refs if its a regular React component (not a pure component), so lets check that
        if (componentPointer instanceof Component || (componentPointer.type && componentPointer.type.prototype instanceof Component)) {
            // unit test deteceted that instanceof Component can be in either of these locations so test both (not sure why this is the case)
            cloneExtensions.ref = 'activeComponent';
        }

        const compToRender = React.cloneElement(componentPointer, cloneExtensions);

        return (
            <div className={`multi-step d-flex flex-column hlgrid-form ${showWideForm ? 'hlgrid-form-wide' : ''}`} onKeyDown={(evt) => { this.handleKeyDown(evt); }}>
                {this.props.showSteps && this.state.hideProgressTracker !== true ? <ol className="progtrckr">{this.renderSteps()}</ol> : <span></span>}
                {compToRender}
                {this.renderButtons()}
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderWizard()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

Wizard.contextType = FormContext;

Wizard.defaultProps = {
    showSteps: true,
    stepsNavigation: true,
    prevBtnOnLastStep: true,
    dontValidate: false,
    preventEnterSubmission: false,
    startAtStep: 0,
    showOverview: true,
    showNext: true,
    showSave: false,
    showCommitAndPush: false,
    showBack: true,
    nextButtonText: 'Next',
    nextButtonCls: 'btn btn-prev btn-primary pull-right',
    backButtonText: 'Previous',
    backButtonCls: 'btn btn-next btn-primary pull-left',
    saveButtonText: 'Save',
    saveButtonCls: 'btn btn-prev btn-primary pull-right',
    commitAndPushButtonText: 'Commit And Push',
    commitAndPushButtonCls: 'btn btn-prev btn-primary pull-right',
    hocValidationAppliedTo: []
};

Wizard.propTypes = {
    /**
     * steps for the wizard
     */
    steps: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]).isRequired,
        component: PropTypes.element.isRequired
    })).isRequired,
    /**
     * Step number to start the wizard at
     */
    startAtStep: PropTypes.number,
    /**
     * value of "next" text on final step
     */
    nextTextOnFinalActionStep:PropTypes.string,
    nextButtonText:PropTypes.string,
    dontValidate:PropTypes.bool,
    /**
     * false value will hide prev button on last step
     */
    prevBtnOnLastStep: PropTypes.bool,
    /**
     * callback function when step changes in the wizard
     */
    onStepChange: PropTypes.func,
    showSteps: PropTypes.bool,
    stepsNavigation: PropTypes.bool,
    showOverview: PropTypes.bool,
    showNext: PropTypes.bool,
    showSave: PropTypes.bool,
    showCommitAndPush: PropTypes.bool,
    showBack: PropTypes.bool,
    preventEnterSubmission: PropTypes.bool,
    stepsNavigation: PropTypes.bool,
    isValidated:PropTypes.func,
    hocValidationAppliedTo: PropTypes.array,
    /**
     * shows the total steps and current step at the top
     */
    showSteps: PropTypes.bool,
    showNavigation: PropTypes.bool,
    onCancel:PropTypes.func,
    hideEditToSummary:PropTypes.bool,
    fromEditToSummary:PropTypes.func,
    nextButtonCls: PropTypes.string,
    backButtonCls: PropTypes.string,
    backButtonText: PropTypes.string,
    jumpToSummaryFromStep:PropTypes.array,
    onGoToSummary:PropTypes.func,
    onGoToMapPage:PropTypes.func,
    goToSummary:PropTypes.bool,
    onSubmit:PropTypes.func,
    jumpToMapPage:PropTypes.bool
};
