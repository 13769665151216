import React, { Component } from "react";
import { Pan, getStore } from "ui-lib";
import { getEffectiveServiceMap, getHLViewerWithActions } from "service-lib";
import DGSelection from "./DGSelection";
import {
	deleteApplicationTag,
} from './actions';

export default class DGBaseViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			DGHLViewerWithActions: null
		};
	}

	componentDidMount() {
		const serviceMap = getEffectiveServiceMap(this.serviceName, this.serviceMap);
		this.setState({
			DGHLViewerWithActions: getHLViewerWithActions(
				this.serviceName,
				this.reduxId,
				this.hlComponent,
				this.headerComponent,
				serviceMap,
				this.resultPath,
				'dg',
				this.extraInfoActions
			),
			reduxId: this.reduxId,
			reduxStateId: {
				reduxId: this.reduxId,
				locationPath: 'dg'
			},
			serviceMap: serviceMap
		});
	}

	onDeleteApplicationTag(records) {
		getStore().dispatch(
			deleteApplicationTag(records, this.state.serviceMap, 'dg', this.props.configLocation),
		);
	}

	getConfigLocationWidget() {
		return (
			<DGSelection value={this.props.configLocation && this.props.configLocation.dg ? this.props.configLocation.dg.name : ""} />
		)
	}

	getGridActions() {
		let gridActions = this.props.gridActions || [];
		return [...gridActions];
	}

	render() {
		let DGHLViewerWithActions = this.state.DGHLViewerWithActions;
		return (
			DGHLViewerWithActions && (
				<DGHLViewerWithActions
					{...this.props}
					header={this.props.header || this.header}
					columns={this.columns}
					widerForm={this.widerForm}
					simpleColumns={this.simpleColumns}
					inputFields={this.fields}
					recordConfigPath={this.recordConfigPath}
					mode={this.props.mode || "grid"}
					displayMode={this.props.displayMode || "default"}
					editorMode={this.props.editorMode || "default"}
					rbaPath={this.rbaPath}
					resultPath={this.resultPath}
					showEnableAction={this.showEnableAction}
					showDisableAction={this.showDisableAction}
					showDefaultAddAction={this.showDefaultAddAction}
					showDefaultDeleteAction={this.showDefaultDeleteAction}
					showDefaultCloneAction={this.showDefaultCloneAction}
					checkboxSelection={this.checkboxSelection}
					onMoveDrag={this.onMoveDrag && this.onMoveDrag.bind(this)}
					gridOverride={this.gridOverride}
					gridActions={this.getGridActions()}
					onDeleteApplicationTag={this.onDeleteApplicationTag.bind(this)}
					locationWidget={this.getConfigLocationWidget()}
					minRows={this.props.minRows || this.minRows}
					suppressColsSizeToFit={Pan.isDefined(this.props.suppressColsSizeToFit) ? this.props.suppressColsSizeToFit : this.suppressColsSizeToFit}
					allowDrop = {this.allowDrop}
					allowDrag = {this.allowDrag}
					agGridProps={this.agGridProps}
					initActions={this.initActions}
				/>
			)
		);
	}
}
