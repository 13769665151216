import * as React from "react";
import Tooltip from "./components/Tooltip";
import warning from "warning";
import "./PopoverWidget.scss";

export class PopoverWidget extends React.Component {
	static defaultProps = {
		placement: "top",
		transitionName: "zoom-big",
		trigger: "hover",
		mouseEnterDelay: 0.1,
		mouseLeaveDelay: 0.1,
		overlayStyle: {}
	};

	getPopupDomNode() {
		return this.tooltip.getPopupDomNode();
	}

	getOverlay(prefixCls) {
		const { title, content } = this.props;
		warning(!("overlay" in this.props), "Popover " + "`overlay` is removed, please use `content`");
		return (
			<div>
				{title && <div className={`${prefixCls}-title`}>{title}</div>}
				<div className={`${prefixCls}-inner-content`}>{content}</div>
			</div>
		);
	}

	saveTooltip = node => {
		this.tooltip = node;
	};

	render() {
		const { prefixCls: customizePrefixCls, ...props } = this.props;
		delete props.title;
		const prefixCls = customizePrefixCls ? `${customizePrefixCls} pan-popover` : "pan-popover";
		return <Tooltip {...props} prefixCls={prefixCls} ref={this.saveTooltip} overlay={this.getOverlay(prefixCls)} />;
	}
}
