import React from "react";
import { Pan, VSYS_SHARED, showModal, hideModal, getPathValue, getStoreState,isUserRolePemission } from 'ui-lib';
import { SERVICE_CONNECTION_TPL } from 'service-lib';
import GPCSWorkflowBaseViewer from '../main/GPCSWorkflowBaseViewer';
import { gpcsCommonFields, validateID, displayValue } from '../remoteNetworks/GPCSCommonFields';
import gpcsCommonColumnConfig from '../remoteNetworks/GPCSCommonColumns';
import unionBy from 'lodash/unionBy';
import { fetchGPCSStatus, getServiceConnectionNetworksDetails } from '../main/actions';
import ServiceConnectionAddChecking from './ServiceConnectionAddChecking';
import BGPStatus from "../main/BGPStatus";

const _T = str => str;
const DEFAULT_FREE_SC_COUNT = 3;

export const serviceConnectionFields = unionBy([{
    name: '$',
    childrenNames: [
        '$.stage_1',
        '$.stage_2',
        '$.stage_3',
        '$.stage_summary'
    ],
    uiHint: {
        labelWidthSize: 3
    }
}, {
    // must defined name field, it used for nameProprityId
    type: 'string',
    name: 'name',
    mapping: '@name',
    uiHint: {
        fieldLabel: _T('Site Name'),
        disableOnEditRecord: true,
        autoFocus: true
    }
}, {
    name: '$.stage1.product',
    uiHint: {
        fieldLabel: _T('Site IPSec Device'),
        useValueAsDisplay: true
    }
}, {
    name: '$.stage_1',
    recordConfigPath: '$.stage1',
    childrenNames: [
        // 'get_started',
        '$.stage1_step1',
        '$.stage1_step2',
        '$.stage1_step3',
        '$.stage1_step4',
        '$.stage1_step5'
    ],
    uiHint: {
        jumpToSummaryFromStep: [3, 4, 5],
        stageTitle: 'Primary Tunnel',
        stageNumber: '1',
        stageCollapseTitle: 'Primary Tunnel',
        onNext: (currentStep, formData, formDataBackup, isNewRecord, _next) => {
            let region1 = formData && formData.stage1 ? formData.stage1.region : null;
            let region2 = formDataBackup && formDataBackup.stage1 ? formDataBackup.stage1.region : null;

            let peerAddress = formData && formData.stage1 ? formData.stage1['peer-address'] : null;
            let peerId = formData && formData.stage1 && formData.stage1['authentication'] && formData.stage1['authentication']['pre-shared-key'] ? formData.stage1['authentication']['pre-shared-key']['peer-id'] : null;

            if (currentStep === 0 && !isNewRecord && region1 !== region2) {
                let modal = {
                    id: "ServiceConnectionRegionChangeModal",
                    title: _T('Region Change Warning'),
                    type: 'Warning',
                    message: _T('A region change may require Prisma Access to redeploy the current setup and cause downtime at the remote network. With the new setup your service IP address will change. To resume connectivity, you might need to reconfigure the IPSec VPN tunnel between the corporate networks or the remote networks, and the cloud service.'),
                    toggle: () => {
                        hideModal("ServiceConnectionRegionChangeModal");
                    },
                    actions: [{
                        text: 'Yes',
                        action: () => {
                            _next && _next();
                            hideModal("ServiceConnectionRegionChangeModal");
                        }
                    }, {
                        text: 'No',
                        action: () => {
                            hideModal("ServiceConnectionRegionChangeModal");
                        }
                    }]
                };
                showModal(modal);
            } else if(currentStep === 1 && peerAddress && peerAddress.dynamic) { //ADI-2009
                if( _.isEmpty(peerId) || _.isEmpty(peerId.type) || _.isEmpty(peerId.type)){
                    let modal = {
                        id:"requireHQDCIkeIdModal",
                        title:_T('Validation Error'),
                        type:'Warning',
                        message:_T('HQ/DC IKE ID is required when using Dynamic IP Address.'),
                        toggle: () => {
                            hideModal("requireHQDCIkeIdModal");
                        },
                        actions:[{
                            text:'OK',
                            action:()=>{
                                hideModal("requireHQDCIkeIdModal");
                            }
                        }]
                    };
                    showModal(modal);
                }
                else{
                    _next && _next();
                }
            } else {
                _next && _next();
            }
        }
    }
}, {
    name: '$.stage1_step1',
    childrenNames: [
        'name',
        '$.stage1.region',
        '$.stage1.product'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: _T('Tunnel Information'),
        stepTitle: _T('Tunnel Information'),
        fieldDescription: _T('Name your HQ or data center site and select the IPSec device you are using to connect the site to Prisma Access.')
    }
}, {
    name: '$.stage1_step2',
    childrenNames: [
        '$.stage1.version',
        '$.stage1.authentication',
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: _T('IPSec Peer Authentication'),
        stepTitle: 'IPSec Peer Authentication',
        fieldDescription: _T('To set up a VPN tunnel between your HQ or data center and Prisma Access, specify a pre-shared key and IKE ID for each end of the tunnel.')
    }
}, {
    name: '$.stage1_step3',
    childrenNames: [
        '$.stage1.proxy-id.entry-separator',
        '$.stage1.proxy-id.entry',
        '$.stage1.tunnel-monitor-separator',
        '$.stage1.tunnel-monitor.destination-ip',
        '$.stage1.tunnel-monitor.proxy-id',
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: _T('Tunnel Settings'),
        buttonsAvail: {
            next: false,
            back: true,
            save: isUserRolePemission
        },
        stepTitle: 'Tunnel Settings',
        fieldDescription: _T('Specify an IP address on your HQ or data center network to use to monitor the tunnel. Also, for policy-based VPN devices only, specify the Proxy IDs that match your policies.')
    }
}, {
    name: '$.stage1.proxy-id.entry-separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('Proxy-IDs')
    }
},
{
    name: '$.stage1.tunnel-monitor-separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('Tunnel Monitoring IP')
    }
},
{
    name: '$.stage1_step4',
    childrenNames: [
        '$.stage1.ike-crypto-profiles.name',
        '$.stage1.ike-crypto-profiles.choice'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        optional: true,
        fieldLabel: '',
        buttonsAvail: {
            next: false,
            back: false,
            save: isUserRolePemission
        },
        stepTitle: _T('IKE Crypto Settings') + ' (Optional)',
        fieldDescription: _T('Based on the IPSec device type you selected, Prisma Access provides a recommended set of ciphers and a key lifetime for the IKE Phase 1 key exchange process between your corporate IPSec device and the Prisma Access node. You can use the recommended settings, or customize the settings as needed for your environment.')
    }
}, {
    name: '$.stage1_step5',
    childrenNames: [
        '$.stage1.ipsec-crypto-profiles.name',
        '$.stage1.ipsec-crypto-profiles.choice'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        optional: true,
        fieldLabel: '',
        buttonsAvail: {
            next: false,
            save: isUserRolePemission
        },
        stepTitle: _T('IPSec Crypto Settings') + ' (Optional)',
        fieldDescription: _T('Based on the IPSec device type you selected, Prisma Access provides a recommended set of IPSec protocol and  key lifetime settings to use to secure data within the IPSec tunnel between your corporate device and the Prisma Access node in IKE Phase 2 for the Security Association (SA). You can use the recommended settings, or customize the settings as needed for your environment.')
    }
}, {
    name: '$.stage_2',
    recordConfigPath: '$.stage2',
    childrenNames: [
        '$.stage2_step1',
        '$.stage2_step2',
        '$.stage2_step3',
        '$.stage2_step4'
    ],
    uiHint: {
        jumpToSummaryFromStep: [2, 3, 4],
        stageTitle: _T('Secondary tunnel'),
        stageNumber: '2',
        stageCollapseTitle: _T('Secondary Tunnel')
    }
}, {
    name: '$.stage3.routing.subnets.member',
    uiHint: {
        fieldLabel: _T('Subnets'),
    }
},
{
    name: '$.stage_3',
    recordConfigPath: '$.stage3',
    childrenNames: [
        '$.stage3_step1'
    ],
    uiHint: {

        stageTitle: 'Routing and QoS',
        stageNumber: '3',
        stageCollapseTitle: ''
    }
},
{
    name: '$.stage3_step1',
    childrenNames: [
        '$.stage3.routing',
        '$.stage3.qos-profile-separator',
        '$.stage3.qos-profile'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: 'Step 1',
        stepTitle: 'Routing and QoS',
        buttonsAvail: {
            next: false,
            back: true,
            save: isUserRolePemission
        },
        fieldDescription: _T('Enable routing—static routes and/or BGP—to the subnet or individual IP addresses at the HQ or data center site. Optionally, configure QoS to prioritize business-critical traffic or traffic that requires low latency.')
    }
},
{
    name: '$.stage3.qos-profile-separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('QoS')
    }
},
{
    name: '$.stage3.qos-profile',
    uiHint: {
        builder: 'CompletionBuilder',
        completionConfig: {
            actions: [
                {
                    text: 'Add QoS Profile',
                    rbaPath: 'network.network-profiles.qos-profile',
                    viewerName: 'QoSProfileRecordViewer',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('QoS Profile'),
                        configLocation: {
                            tpl: {
                                name: SERVICE_CONNECTION_TPL
                            }
                        }
                    }

                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'QoSProfile',
            viewerProps: {
                displayCancel: true,
                title: 'QoS Profile',
                configLocation: {
                    tpl: {
                        name: SERVICE_CONNECTION_TPL
                    }
                }
            },
            actionText: 'Manage QoS Profiles',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {

    name: '$.stage1.authentication.pre-shared-key',
    childrenNames: [
        '$.stage1.authentication.pre-shared-key.key',
        '$.stage1.peer-address.choice',
        '$.stage1.authentication.pre-shared-key.local-id',
        '$.stage1.authentication.pre-shared-key.peer-id'
    ],
    uiHint: {
        fieldLabel: _T('Pre-Shared Key')
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.key',
    uiHint: {
        fieldLabel: _T('Pre-Shared Key'),
        hideLabelInSummary: true,
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.peer-id.type',
    uiHint: {
        fieldLabel: _T('Identification Type'),
        hideLabelInSummary: true,
        getDisplayValue: function (formData) {
            let val = getPathValue(formData, '$.stage1.authentication.pre-shared-key.peer-id.type');
            return val ? displayValue(val) : '';
        },
        association: {
            // fields: ['$.stage1.authentication.pre-shared-key.peer-id.type'],
            runValidation: function (values, formData, field, key) {
                const idVal = getPathValue(formData, '$.stage1.authentication.pre-shared-key.peer-id.id');
                return { '$.stage1.authentication.pre-shared-key.peer-id.id': idVal };
            }
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.peer-id',
    uiHint: {
        fieldLabel: _T('HQ/DC IKE ID'),
        customValidation: function(value, field, formData) {
            return validateID(value, field, formData, '$.stage1.authentication.pre-shared-key.peer-id.type');
        },
        association: {
            fields: ['$.stage1.peer-address.choice'],
            updateFormData: function (values, formData, filters, key) {
                const dynamic = getPathValue(formData, 'stage1.peer-address.dynamic');
                    if (!getPathValue(formData, 'stage1.authentication.pre-shared-key.peer-id.type')) {
                        return { '$.stage1.authentication.pre-shared-key.peer-id': dynamic ? {'type': ''}  : false};
                    }
                    return {};
            },
        }        
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.peer-id.id',
    uiHint: {
        fieldLabel: _T('Identifier'),
        displayLabel: _T('HQ/DC IKE ID'),
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage1.authentication.pre-shared-key.peer-id.type');
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.local-id.type',
    uiHint: {
        fieldLabel: _T('Identification Type'),
        hideLabelInSummary: true,
        association: {
            // fields: ['$.stage1.authentication.pre-shared-key.local-id.type'],
            runValidation: function (values, formData, field, key) {
                const idVal = getPathValue(formData, '$.stage1.authentication.pre-shared-key.local-id.id');
                return { '$.stage1.authentication.pre-shared-key.local-id.id': idVal };
            }
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.local-id',
    uiHint: {
        fieldLabel: _T('Prisma Access IKE ID')
    }
}, {

    name: '$.stage1.authentication.pre-shared-key.local-id.id',
    uiHint: {
        fieldLabel: _T('Identifier'),
        displayLabel: 'Prisma Access IKE ID',
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage1.authentication.pre-shared-key.local-id.type');
        }
    }
}, {
    name: '$.stage1.authentication.certificate.local-id',
    uiHint: {
        fieldLabel: _T('Prisma Access IKE ID')
    }
}, {

    name: '$.stage2.authentication.pre-shared-key',
    childrenNames: ['$.stage2.authentication.pre-shared-key.key',
        '$.stage2.peer-address.choice',
        '$.stage2.authentication.pre-shared-key.local-id',
        '$.stage2.authentication.pre-shared-key.peer-id'],

    uiHint: {
        fieldLabel: _T('Pre-Shared Key')
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.key',
    uiHint: {
        fieldLabel: _T('Pre-Shared Key'),
        hideLabelInSummary: true,
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.peer-id.type',
    uiHint: {
        fieldLabel: _T('Identification Type'),
        hideLabelInSummary: true,
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.peer-id.id',
    uiHint: {
        fieldLabel: _T('Identifier'),
        displayLabel: 'HQ/DC IKE ID',
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage2.authentication.pre-shared-key.peer-id.type');
        }
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.local-id.type',
    uiHint: {
        hideLabelInSummary: true,
        fieldLabel: _T('Identification Type')
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.local-id.id',
    uiHint: {
        displayLabel: 'Prisma Access IKE ID',
        fieldLabel: _T('Identifier'),
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage2.authentication.pre-shared-key.local-id.type');
        }
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.peer-id',
    uiHint: {
        fieldLabel: _T('HQ/DC IKE ID'),
        association: {
            fields: ['$.stage2.peer-address.choice'],
            updateFormData: function (values, formData, filters, key) {
                const dynamic = getPathValue(formData, 'stage2.peer-address.dynamic');
                if (!getPathValue(formData, 'stage2.authentication.pre-shared-key.local-id.type')) {
                    return { '$.stage2.authentication.pre-shared-key.local-id': dynamic ? {'type': ''}  : false};
                }
                return {};
            },
        }
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.local-id',
    uiHint: {
        fieldLabel: _T('Prisma Access IKE ID')
    }
}, {
    name: '$.stage1.peer-address.choice',
    uiHint: {
        fieldLabel: _T('Peer IP Address Type')
    }
}, {
    name: '$.stage2.peer-address.choice',
    uiHint: {
        fieldLabel: _T('Peer IP Address Type')
    }
}, {
    name: '$.stage1.authentication.certificate.local-certificate.name',
    uiHint: {
        fieldLabel: _T('Local Certificate'),
        completionConfig: {
            actions: [
                {
                    text: 'Import',
                    viewerName: 'CertificateImport',
                    rbaPath: 'device.certificate-management.certificates',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('Import Certificate'),
                        configLocation: {
                            tpl: {
                                name: SERVICE_CONNECTION_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'LocalCertificate',
            viewerProps: {
                displayCancel: true,
                title: 'Local Certificate',
                configLocation: {
                    tpl: {
                        name: SERVICE_CONNECTION_TPL,
                        vsys: VSYS_SHARED
                    }
                }
            },
            actionText: 'Manage Local Certificate',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage1.authentication.certificate.certificate-profile',
    uiHint: {
        builder: 'CompletionBuilder',
        completionConfig: {
            actions: [
                {
                    text: 'Add Certificate Profile',
                    rbaPath: 'device.certificate-management.certificate-profile',
                    viewerName: 'CertificateProfileRecordViewer',
                    viewerProps: {
                        windowSize: 'xl',
                        title: _T('Certificate Profile'),
                        configLocation: {
                            tpl: {
                                name: SERVICE_CONNECTION_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'CertificateProfile',
            viewerProps: {
                displayCancel: true,
                title: 'Certificate Profile',
                configLocation: {
                    tpl: {
                        name: SERVICE_CONNECTION_TPL
                    }
                }
            },
            actionText: 'Manage Certificate Profiles',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage2.authentication.certificate.local-certificate.name',
    uiHint: {
        fieldLabel: _T('Local Certificate'),
        completionConfig: {
            actions: [
                {
                    text: 'Import',
                    viewerName: 'CertificateImport',
                    rbaPath: 'device.certificate-management.certificates',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('Import Certificate'),
                        configLocation: {
                            tpl: {
                                name: SERVICE_CONNECTION_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'LocalCertificate',
            viewerProps: {
                displayCancel: true,
                title: 'Local Certificate',
                configLocation: {
                    tpl: {
                        name: SERVICE_CONNECTION_TPL
                    }
                }
            },
            actionText: 'Manage Local Certificate',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage2.authentication.certificate.certificate-profile',
    uiHint: {
        builder: 'CompletionBuilder',
        completionConfig: {
            actions: [
                {
                    text: 'Add Certificate Profile',
                    rbaPath: 'device.certificate-management.certificate-profile',
                    viewerName: 'CertificateProfileRecordViewer',
                    viewerProps: {
                        windowSize: 'xl',
                        title: _T('Certificate Profile'),
                        configLocation: {
                            tpl: {
                                name: SERVICE_CONNECTION_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'CertificateProfile',
            viewerProps: {
                formSize: 'xl',
                displayCancel: true,
                title: 'Certificate Profile',
                configLocation: {
                    tpl: {
                        name: SERVICE_CONNECTION_TPL
                    }
                }
            },
            actionText: 'Manage Certificate Profiles',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}], gpcsCommonFields);

export const serviceConnectionColumns = [
    {
        headerName: 'Site',
        children: [
            Pan.apply({}, {}, gpcsCommonColumnConfig.name),
            {
                headerName: _T("Subnets"),
                field: 'stage3.routing.subnets',
                minWidth: 200
            },
            {
                headerName: _T("EBGP ROUTER & AS"),
                field: 'ebgp_router_as',
                cellRendererParams: {
                    renderCell: params => {
                        let value = '';
                        if (getStoreState().gpcs.serviceConnectionNetworkDetails && getStoreState().gpcs.serviceConnectionNetworkDetails !== 'error') {
                            const val = findColumnData(params.data, getStoreState().gpcs.serviceConnectionNetworkDetails, '@name', 'Branch AS and Router');
                            value = Pan.isArray(val) && val.length > 0 && val[0]['AS'] ? val[0]['AS'] : [];
                        }
                        return (
                            <React.Fragment>
                                <div>{value}</div>
                                {value &&
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a onClick={() => {
                                        let modal = {
                                            id: "BGPStatus",
                                            title: "BGP Status",
                                            size: "xl",
                                            type: "Info",
                                            toggle: () => {
                                                hideModal("BGPStatus");
                                            },
                                            modalBody: {
                                                component: BGPStatus,
                                                props: {
                                                    site: getPathValue(params, 'data.@name')
                                                }
                                            },
                                            actions: [
                                                {
                                                    text: "Close",
                                                    color: "secondary",
                                                    action: () => {
                                                        hideModal("BGPStatus");
                                                    }
                                                }
                                            ]
                                        };
                                        showModal(modal);
                                    }}>
                                        Show BGP Status
                                </a>
                                }
                            </React.Fragment>
                        )
                    }
                },
                minWidth: 200
            }
        ]
    },
    {
        headerName: _T('Prisma Access'),
        children: [
            Pan.apply({}, {}, gpcsCommonColumnConfig.region),
            {
                headerName: _T('Service IP'),
                field: 'service_ip',
                cellRendererParams: {
                    renderCell: params => {
                        let val = '';
                        if (getStoreState().gpcs.serviceConnectionNetworkDetails && getStoreState().gpcs.serviceConnectionNetworkDetails !== 'error') {
                            val = findColumnData(params.data, getStoreState().gpcs.serviceConnectionNetworkDetails, '@name', 'Service IP Address');
                        }
                        return <div>{val}</div>;
                    }
                },
                minWidth: 125
            },
            {
                headerName: _T('EBGP Router'),
                field: 'ebgp_router',
                cellRendererParams: {
                    renderCell: params => {
                        let val = '';
                        if (getStoreState().gpcs.serviceConnectionNetworkDetails && getStoreState().gpcs.serviceConnectionNetworkDetails !== 'error') {
                            val = findColumnData(params.data, getStoreState().gpcs.serviceConnectionNetworkDetails, '@name', 'EBGP Router');
                        }
                        return <div>{val}</div>;
                    }
                },
                minWidth: 150
            },
        ]
    }, {
        headerName: _T('Status'),
        children: [
            {
                headerName: _T('Primary Tunnel'),
                valueGetter: params => {
                    const val = getPathValue(params.data, 'stage1.product')
                    return (val) ? _T('Configured') : ''
                },
                minWidth: 150
            },
            {
                headerName: _T('Secondary Tunnel'),
                valueGetter: params => {
                    const val = getPathValue(params.data, 'stage2') || {};
                    return Pan.isEmpty(val) ? '' : _T('Configured');
                },
                minWidth: 150
            },
            {
                headerName: _T("User-ID Agent ID"),
                field: 'user_id_agent',
                cellRendererParams: {
                    renderCell: params => {
                        let val = '';
                        if (getStoreState().gpcs.serviceConnectionNetworkDetails && getStoreState().gpcs.serviceConnectionNetworkDetails !== 'error') {
                            val = findColumnData(params.data, getStoreState().gpcs.serviceConnectionNetworkDetails, '@name', 'User-ID Agent Address');
                        }
                        return <div>{val}</div>;
                    }
                },
                minWidth: 100
            },
            {
                headerName: _T('Config Status'),
                field: 'config_status',
                cellRendererParams: {
                    renderCell: params => {
                        let val = '';
                        if (getStoreState().gpcs.gpcs_status && getStoreState().gpcs.gpcs_status !== 'error') {
                            const gpcs_status = getStoreState().gpcs.gpcs_status;
                            const matched = gpcs_status ? gpcs_status.find(entry => entry['Remote Peer'] === params.data['@name']) : undefined;
                            val = matched && matched['Config Status'] ? matched['Config Status'].label : '';
                        }
                        return <div>{val}</div>;
                    }
                },
                minWidth: 150
            }
        ]
    }
];

const findColumnData = (columnData, responseData, matchProperty, valueProperty) => {
    const matched = responseData ? responseData.find(entry => entry.name === columnData[matchProperty]) : undefined;
    return matched ? matched[valueProperty] : '';
};

const serviceConnectionsSimpleColumns = [{
    headerName: 'Name',
    field: '@name'
},
{
    headerName: _T('Tunnel Status'),
    valueGetter: params => {
        if (getStoreState().gpcs.service_connection_status && getStoreState().gpcs.service_connection_status !== 'error') {
            return getStoreState().gpcs.service_connection_status.status;
        }
        return '';
    }
}];

function prepareAddRecord(addClosure) {
    if (this.props.gpcs.serviceConnection) {
        let count = getPathValue(getStoreState().gpcs.serviceConnection, 'result.entry').length;
        if (Number(count) >= DEFAULT_FREE_SC_COUNT) {
            let modal = {
                id: "ServiceConnectionPrepareAddRecordModal",
                title: _T('Service Setup'),
                type: 'Warning',
                style: {
                    width: '500px'
                },
                modalBody: {
                    component: ServiceConnectionAddChecking
                },
                toggle: () => {
                    hideModal("ServiceConnectionPrepareAddRecordModal");
                },
                actions: [{
                    text: 'Yes',
                    disabled: true,
                    action: () => {
                        addClosure && addClosure();
                        hideModal("ServiceConnectionPrepareAddRecordModal");
                    }
                }, {
                    text: 'No',
                    action: () => {
                        hideModal("ServiceConnectionPrepareAddRecordModal");
                    }
                }]
            };
            showModal(modal);
        } else {
            addClosure();
        }
    } else {
        addClosure();
    }
};


export default class ServiceConnections extends GPCSWorkflowBaseViewer {
    simpleColumns = serviceConnectionsSimpleColumns;
    serviceName = 'workflow/serviceConnection';
    reduxId = 'serviceConnection';
    header = 'Service Connections';
    columns = serviceConnectionColumns;
    fields = serviceConnectionFields;
    rbaPermission=isUserRolePemission() ? 'enable':'disable';
    recordConfigPath = '$.config.devices.entry.plugins.cloud_services.adelphi_gpcs_workflow.service-connection.onboarding.entry';
    suppressColsSizeToFit = false;
    initActions = [fetchGPCSStatus, getServiceConnectionNetworksDetails];
    prepareAddRecord = prepareAddRecord;
    refreshColumns = ['ebgp_router_as', 'service_ip', 'ebgp_router', 'user_id_agent', 'config_status']
}
