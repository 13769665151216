import Pan from "../autorender/schema/Pan";
const _T = str => str;

export const validationRulesMap = {
    noAllowBlank: (value, field) => {
        if (Pan.isEmpty(value))
            return 'Value is required';
    },
    isValidPattern: function (str) {
        if (str.length < 7) {
            return 'The length of the pattern must be at least 7 characters';
        };
        return null;
    },
    objectName: function (str, field) {
        if (str && !str.match(/^[0-9a-zA-Z]{1}([0-9a-zA-Z_-]|[ ]|[.])*$/)) {
            return "A valid object name must start with an alphanumeric character and can contain zero or more alphanumeric characters, underscore '_', hyphen '-', dot '.' or spaces.";
        }
        return validationRulesMap.isString(str, field);
    },
    isEmail: function (str) {
        if (str && !str.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return 'Invalid Email Address';
        };
        return null;
    },
    isHexadecimal: function (str) {
        if (str) {
            var hexValue = parseInt(str, 16);
            if (hexValue.toString(16) === str.toLowerCase())
                return null;
        };
        return 'Invalid Key ID (must be a hexadecimal value)';
    },
    isString: function (str, field) {
        let length = 0;
        if (str) {
            length = str.length;
        }
        let minLength = field && field.uiHint && field.uiHint.minLength;
        minLength = minLength || Math.min;
        let maxLength = field && field.uiHint && field.uiHint.maxLength;
        maxLength = maxLength || Math.max;
        if (length < minLength)
            return "The minimum length is " + minLength + " characters.";
        if (length > maxLength)
            return "The maximum length is " + maxLength + " characters.";
        return null;
    },
    isNumber: function (str, field) {
        return validationRulesMap.rangedInt(str, field);
    },
    validNumber: function (str, min, max) {
        var n = Number(str);
        if (isNaN(n)) {
            return false;
        }
        // check to make sure that there is only digits
        if (str.match(/^([0-9])+$/) == null) {
            return false;
        }
        if (min != undefined && n < min) {
            return false;
        }
        if (max != undefined && n > max) {
            return false;
        }
        return true;
    },
    validNumberRangeList: function (str, min, max) {
        var tokensArray = str.split(",");
        for (var i = 0; i < tokensArray.length; i++) {
            if (tokensArray[i].charAt(tokensArray[i].length - 1) == '-') {
                // cannot end in -
                return false;
            }
            if (tokensArray[i].charAt(0) == '-') {
                // if the first char is - then it is a negative number, consider it as a number
                // instead of a range.
                if (!validationRulesMap.validNumber(tokensArray[i], min, max)) {
                    return false;
                }
            } else {
                var numbersArray = tokensArray[i].split("-");
                for (var j = 0; j < numbersArray.length; j++) {
                    if (!validationRulesMap.validNumber(numbersArray[j], min, max)) {
                        return false;
                    }
                }
                if (numbersArray.length == 2) {
                    var num1 = numbersArray[0] - 0;
                    var num2 = numbersArray[1] - 0;
                    if (num1 > num2) {
                        return false;
                    }
                }
            }
        }
        return true;
    },
    rangeList: function (v, field) {
        let min = field && field.uiHint && field.uiHint.minValue || 0;
        let max = field && field.uiHint && field.uiHint.maxValue || 65535;
        if (!Pan.isEmpty(v) && !validationRulesMap.validNumberRangeList(v, min, max)) {
            return "Invalid range";
        }
        return null;
    },
    rangedInt: function (str, field) {
        var n = Number(str);
        if (isNaN(n))
            return "Invalid value";
        // check to make sure that there is only digits
        // Do not need to check it anymore
        // if (!Pan.isEmpty(str) && str.match(/^([0-9])+$/) == null) {
        //     return "Invalid value";
        // }
        let min = field && field.uiHint && field.uiHint.minValue;
        let max = field && field.uiHint && field.uiHint.maxValue;
        var matches = str.match(/^[0-9]+$/);
        if (!matches) {
            return "Value must only contain integers";
        }
        if (min != undefined && n < min)
            return "Value is less than minimum";
        if (max != undefined && n > max)
            return "Value is greater than maximum";
        return null;
    },
    isIpV4Address: function (str) {
        var i;
        var matches = str ? str.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)(\/[0-9]{1,3})?$/) : null;
        if (!matches) return "Invalid IP address";
        for (i = 1; i <= 4; i++) {
            var n = parseInt(matches[i], 10);
            if (isNaN(n)) return "Invalid IP address";
            if (!(n >= 0 && n <= 255)) return "Invalid IP address";
        }
        matches.shift();            // drop the complete string match
        for (i = 0; i < matches.length; i++)
            matches[i] = parseInt(matches[i], 10);
        return { ip: validationRulesMap.octectsToLong.apply(null, matches), octects: matches };
    },
    isIpV4AddressMask: function (str, requireMask) {
        /* accept both 1.1.1.1 and 1.1.1.1/30 */
        var matches = str ?  str.match(/^([^\/]+)\/([^\/]+)$/) : null;
        if (!requireMask && !matches) return validationRulesMap.isIpV4Address(str);
        if (matches) {
            var n = parseInt(matches[2], 10);
            return (n >= 0 && n <= 32 && validationRulesMap.isIpV4Address(matches[1]));
        }
        return validationRulesMap.isIpV4Address(str);
    },
    ipAndIntegerSubnetMaskV4orV6: function (v, requireMask) {
        return validationRulesMap.isIpAddressMask(v, requireMask);
    },
    octectsToLong: function (a, b, c, d) {
        // mozilla return negative number for 192 << 24
        // while 7f << 24 is positive
        //return (a << 24) + (b << 16) + (c << 8) + d;
        return a * (1 << 24) + b * (1 << 16) + c * (1 << 8) + d;
    },
    isIpV4Netmask: function (str) {
        var cidr;
        var result = validationRulesMap.isIpV4Address(str);
        if (result && result.ip) {
            if (!validationRulesMap.isIpV4NetmaskLongValue(result.ip)) return false;
            var bit = 0x80000000;       // #b10000000000000000000000000000000
            cidr = 0;
            while (bit & result.ip) {
                cidr++;
                bit >>>= 1;
            }
            return { mask: result.ip, cidr: cidr };
        } else {
            cidr = parseInt(str, 10);
            if (isNaN(cidr)) return "Invalid IP address";
            if (cidr < 0 || cidr > 32) return "Invalid IP address";
            var mask = 0xffffffff;
            var bits = cidr;
            while (bits > 0) {
                mask >>>= 1;
                bits--;
            }
            return { mask: ~mask, cidr: cidr };
        }
    },
    isIpV6Address: function (str) {
        // Original
        return !!str.match(/^((([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}:[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){5}:([0-9A-Fa-f]{1,4}:)?[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){4}:([0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){3}:([0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){2}:([0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(([0-9A-Fa-f]{1,4}:){0,5}:((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(::([0-9A-Fa-f]{1,4}:){0,5}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|([0-9A-Fa-f]{1,4}::([0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})|(::([0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){1,7}:))$/);
        //return !!str.match(/^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/);
    },
    isIpV6Netmask: function (str) {
        return validationRulesMap.inRange(str, 3, 128);
    },
    isIpV6AddressMask: function (str, requireMask) {
        //default unicast route address
        if (str === "::/0")
            return true;
        var arr = str.split("/");
        if (!requireMask && arr.length == 1) {
            return validationRulesMap.isIpV6Address(arr[0]);
        } else if (arr.length == 2) {
            return validationRulesMap.isIpV6Netmask(arr[1]) ||
                validationRulesMap.isIpV6Address(arr[0]);
        }
        return '';
    },
    isIpAddress: function (str) {
        const ipV4 = validationRulesMap.isIpV4Address(str);
        if ((ipV4 && ipV4.ip) || validationRulesMap.isIpV6Address(str)) {
            return '';
        }
        return "Invalid IP address";
    },
    inRange: function (str, min, max) {
        var n = Number(str);
        if (isNaN(n))
            return false;
        // check to make sure that there is only digits
        if (str.match(/^([0-9])+$/) == null) {
            return false;
        }
        if (min != undefined && n < min)
            return false;
        return !(max != undefined && n > max);

    },
    ipAndIntegerSubnetMaskV4: function (v) {
        let ipv4 = validationRulesMap.isIpV4AddressMask(v);
        if ((ipv4 && ipv4['ip'])) {
            return '';
        }
        return 'Invalid IP address';
    },
    ipAndIntegerSubnetMaskV6: function (v) {
        let ipv6 = validationRulesMap.isIpV6AddressMask(v);
        if (ipv6) {
            return '';
        }
        return 'Invalid IP address';
    },
    isIpAddressMask: function (str, requireMask) {
        if (!Pan.isEmpty(str)) {
            let ipv4 = validationRulesMap.isIpV4AddressMask(str, requireMask);
            let ipv6 = validationRulesMap.isIpV6AddressMask(str, requireMask);
            if ((ipv4 && ipv4['ip']) || ipv6) {
                return '';
            }
        }
        return 'Invalid IP address';
    },
    ipRange: function (v) {
        var arr = v.split ? v.split("-") : "";
        if (arr.length != 2)
            return "Invalid IP address range";
        if (validationRulesMap.isIpAddressMask(arr[0]) || validationRulesMap.isIpAddressMask(arr[1])) {
            return "Invalid IP address range";
        }
    },

    multiVtype: function (value, field) {
        let vtypeText = null;
        if (field && field.multitypes) {
            var enumValues = field.multitypes['enum'];
            if (enumValues) {
                for (var j = 0; j < enumValues.length; j++) {
                    if (enumValues[j][0] === value) {
                        return null;
                    }
                }
            }
            let strVal = field.multitypes['string'];
            if (strVal) {
                if (strVal.regex) {
                    if (value.match(strVal.regex)) {
                        // found match for user-provided regex
                        vtypeText = undefined;
                        return null;
                    } else {
                        if (field.uiHint.regexText) {
                            vtypeText = field.uiHint.regexText;
                        } else {
                            vtypeText =
                                'Value does not conform to the validation pattern';
                        }
                        return vtypeText;
                    }
                } else {
                    return null;
                }
            }
        }

        var multiValidationInfo = field.uiHint.multiValidationInfo;
        for (var i = 0; i < multiValidationInfo.length; i++) {
            var validationInfo = multiValidationInfo[i];
            let newVtypeText = undefined;
            var vtype = validationInfo.vtype;
            if (vtype) {
                var compVtypeText = validationRulesMap[vtype](value, field, validationInfo);
                if (compVtypeText) {
                    if (compVtypeText['ip']) {
                        vtypeText = undefined;
                    } else if (compVtypeText != vtypeText) {
                        newVtypeText = compVtypeText;
                    } else {
                        newVtypeText = vtypeText;
                    }
                    if (!vtypeText) {
                        // record the first error
                        vtypeText = newVtypeText;
                    } else {
                        vtypeText += ", " + _T("or ") + newVtypeText;
                    }
                }
                else {
                    // found a type without vtype, consider it pass
                    vtypeText = undefined;
                    break;
                }
            } else if (validationInfo.regex) {
                if (field.uiHint.regex) {
                    if (value.match(field.uiHint.regex)) {
                        // found match for user-provided regex
                        vtypeText = undefined;
                        break;
                    } else {
                        if (field.uiHint.regexText) {
                            vtypeText = field.uiHint.regexText;
                        } else {
                            vtypeText = "Value does not conform to the validation pattern";
                        }
                    }
                }
                else if (!validationInfo.regex.test(value)) {
                    if (field.uiHint.regexText) {
                        vtypeText = field.uiHint.regexText;
                    } else if (validationInfo.helpstring) {
                        vtypeText = validationInfo.helpstring;
                    } else {
                        vtypeText = "Value does not conform to the validation pattern: " + validationInfo.regex.source;
                    }
                } else {
                    // found a string with regex but meets the criteria
                    vtypeText = undefined;
                    break;
                }
            } else {
                // found a type without vtype, consider it pass
                vtypeText = undefined;
                break;
            }
        }
        return vtypeText;
    },

    regEx: function (value, field) {
        let vtypeText = null;
        if (field && field.uiHint && field.uiHint.regex) {
            if (field.uiHint.regex.test(value)) {
                // found match for user-provided regex
                vtypeText = undefined;
            } else {
                if (field.uiHint.regexText) {
                    vtypeText = field.uiHint.regexText;
                } else {
                    vtypeText = "Value does not conform to the validation pattern: " + field.uiHint.regex.source;
                }
            }
        }
        return vtypeText;
    },

}