import { ajax } from "rxjs/ajax";
import { store } from "../../store";
import _ from 'lodash';
import { parseError, SERVER_ERROR, getPathValue, getCustomServiceURL, setPathValue, Pan, getAuthHeaders, isDirectorySyncServiceActivated } from "ui-lib";

const _T = str => str;

export const commonFields = [
    {
        // must defined name field, it used for nameProprityId
        type: 'string',
        name: '$.@name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    },
    {
        name: 'uuid',
        mapping: '@uuid'
    },
    {
        name: '$.description',
        uiHint: {
            fieldLabel: _T('Description'),
            builder: 'TextareaBuilder'
        }
    },
    {
        name: '$.from.member',
        dataMap: (formData, value)  => {
            let validFromZones = value && value.filter(fromZone => fromZone === 'trust' || fromZone === 'clientless-vpn');
            setPathValue(formData, '$.from.member', validFromZones);
        },
        uiHint: {
            fieldLabel: _T('Zone'),
            listOptions: ['trust', 'clientless-vpn'],
            builder: 'ListGroupBuilder',
            getDisplayStr: function(val) {
                if (val === 'clientless-vpn') {
                    return _T('Clientless VPN');
                } 
                return null;
            }
        }
    },
    {
        name: '$.from.member.*',
        defaultValue: 'trust',
        uiHint: {
            minChars: 1,
            alwaysReload: true,
            remoteFilter: true,
            fieldLabel: _T('Source Zone')
        }
    },
    {
        name: '$.to.member',
        dataMap: (formData, value)  => {
            let validFromZones = value && value.filter(fromZone => fromZone === 'trust' || fromZone === 'untrust');
            setPathValue(formData, '$.to.member', validFromZones);
        },
        uiHint: {
            fieldLabel: _T('Zone'),
            listOptions: ['trust', 'untrust'],
            builder: 'ListGroupBuilder'
        }
    },
    {
        name: '$.to.member.*',
        defaultValue: 'trust',
        uiHint: {
            minChars: 1,
            alwaysReload: true,
            remoteFilter: true,
            fieldLabel: _T('Destination Zone')
        }
    },
    {
        name: '$.source.member',
        uiHint: {
            hideLabel: true
        }
    },
    {
        name: '$.source.member.*',
        uiHint: {
            alwaysReload: true,
            fieldLabel: _T('Source Address'),
            forceSelection: false,
            minChars: 1,
            remoteFilter: true,
            remoteFilterHelpString: true,
            groupOrder: ['address', 'address-group', 'external-list', 'region'],
            completionConfig: {
				actions: [
					{
						text: "Add Address",
						viewerName: 'AddressRecordViewer',
						rbaPath: "objects.addresses",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Address')
						} 
					},
					{
						text: "Add Address Group",
						viewerName: 'AddressGroupsRecordViewer',
						rbaPath: "objects.address-groups",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Address Group')
						} 
					}
				]
			}
        }
    },
    {
        name: '$.negate-source',
        uiHint: {
            hideLabel: true,
            boxLabel: _T('Negate')
        }
    },
    {
        name: 'source-with-negate',
        childrenNames: [
            '$.source.member',
            '$.negate-source',
        ],
        uiHint: {
            builder: 'RowBuilder',
            layout: [
                { '$.source.member': 'col-10', '$.negate-source': 'col-2' }
            ],
            fieldLabel: _T("Source Address")
        }
    },
    {
        name: '$.destination.member',
        uiHint: {
            hideLabel: true,
            fieldLabel: _T("Destination")
        }
    },
    {
        name: '$.destination.member.*',
        uiHint: {
            fieldLabel: _T('Destination Address'),
            forceSelection: false,
            remoteFilter: true,
            minChars: 1,
            alwaysReload: true,
            remoteFilterHelpString: true,
            groupOrder: ['address', 'address-group', 'external-list', 'region'],
            completionConfig: {
				actions: [
					{
						text: "Add Address",
						viewerName: 'AddressRecordViewer',
						rbaPath: "objects.addresses",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Address')
						} 
					},
					{
						text: "Add Address Group",
						viewerName: 'AddressGroupsRecordViewer',
						rbaPath: "objects.address-groups",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Address Group')
						} 
					}
				]
			}
        }
    },
    {
        name: '$.negate-destination',
        uiHint: {
            hideLabel: true,
            boxLabel: _T('Negate')
        }
    },
    {
        name: 'destination-with-negate',
        childrenNames: [
            '$.destination.member',
            '$.negate-destination'
        ],
        uiHint: {
            builder: 'RowBuilder',
            layout: [
                { '$.destination.member': 'col-10', '$.negate-destination': 'col-2' }
            ],
            fieldLabel: _T("Destination Address")
        }
    },
    {
        name: '$.source-user.member',
        uiHint: {
            fieldLabel: _T("Users"),
            allowBlank: false,
            gridActionsAvail: {
                isAddDisabled: () => {
                    return !isDirectorySyncServiceActivated();
                }
            },
            customValidation: function(value) {
                const isSelectChosen = Pan.isArray(value) ? true : false;
                let errMsg = '';
                if(isSelectChosen && !isDirectorySyncServiceActivated()) {
                    errMsg = _T("You have not activated the Directory Sync Service for this tenant");
                }
                return errMsg;
            }
        }
    },
    {
        name: '$.source-user.member.*',
        looseMembership: false,
        type: null,
        uiHint: {
            fieldLabel: _T('Source User'),
            allowBlank: false,
            forceSelection: false,
            remoteFilter: true,
            minChars: 1,
            alwaysReload: true,
            query:'remote',
            columnConfig: {
                cellRendererParams: {
                    renderCell: params => {
                        let value = params.value || "";
                        return getUserDisplayLabel(value);
                    }
                }
            },
            completionConfig: {
                CustomCompletion: (searchQuery) => {
                    let { SERVICE_URL } = getCustomServiceURL("api/directory/v1/groupsAndUsers");

                    let directoryGroupsAndUsersRequest = {
						withCredentials: true,
						method: "POST",
						responseType: "json",
                        url: SERVICE_URL,
                        body: {},
                        headers:  {
                            "Content-Type": "application/json",
                            ...getAuthHeaders()
                        }
                    };

                    const {
                        main: { directoryDomains = [] }
                    } = store.getState();

                    const directoryUsersPromise = new Promise((resolve, reject) => {
                        const domainMapping = getDomainNameMapping(directoryDomains);
                        let requestBody = {};
                        const domains = getDomains(domainMapping);
                        if (!Pan.isEmpty(searchQuery)) {
                            requestBody.filter = searchQuery;
                        }
                        if(!Pan.isEmpty(domains)) {
                            requestBody.domains = domains;
                        }
                        directoryGroupsAndUsersRequest.body = JSON.stringify(requestBody);
						ajax(directoryGroupsAndUsersRequest).toPromise()
							.then(usersResponse => {
								const users = getFormattedUsers(usersResponse);
								users.forEach(user => {
                                    user.label = domainNameRenderer(user.value, domainMapping, user);
                                    if(user.type === 'users') {
                                        user.value = user.label;
                                        user.label = user.label.toLowerCase();
                                    }
                                });
								resolve(users);
							})
							.catch(err => {
								store.dispatch({
									type: SERVER_ERROR,
									errorMessage: parseError(err)
								});
								reject(new Error("Error in Auto Completion: " + err));
							});
					});
                    return directoryUsersPromise;
                }
            }
        }
    },
    {
        name: '$.category.member',
        uiHint: {
            fieldLabel: _T('URL Category')
        }
    },
    {
        name: '$.category.member.*',
        uiHint: {
            fieldLabel: _T('URL Category'),
            remoteSort: false,
            completionConfig: {
				actions: [
					{
						text: "Add URL Category",
						viewerName: 'URLCategoryRecordViewer',
						rbaPath: "objects.custom-objects.url-category",
						viewerProps: {
							windowSize: 'lg',
							title: _T('URL Category')
						} 
                    }
                ]
            }
        }
    },
    {
        name: '$.application.member',
        uiHint: {
            fieldLabel: _T("Applications")
        }
    },
    {
        name: '$.application.member.*',
        uiHint: {
            fieldLabel: _T('Applications'),
            completionConfig: {
				actions: [
					{
						text: "Add Application Group",
						viewerName: 'ApplicationGroupsRecordViewer',
						rbaPath: "objects.application-groups",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Application Group')
						} 
                    },
                    {
						text: "Add Application Filter",
						viewerName: 'ApplicationFiltersRecordViewer',
						rbaPath: "objects.application-filters",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Application Filter')
						} 
					}
                ]
            }
        }
    },
    {
        name: '$.service.member',
        uiHint: {
            fieldLabel: _T("Services")
        }
    },
    {
        name: '$.service.member.*',
        uiHint: {
            minChars: 1,
            alwaysReload: true,
            remoteFilter: true,
            completionConfig: {
				actions: [
					{
						text: "Add Service",
						viewerName: 'ServicesRecordViewer',
						rbaPath: "objects.services",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Service')
						} 
					},
					{
						text: "Add Service Group",
						viewerName: 'ServiceGroupsRecordViewer',
						rbaPath: "objects.service-groups",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Service Group')
						} 
					}
				]
			}
        }
    },
    {
        name: '$.hip-profiles.member',
        uiHint: {
            fieldLabel: _T("HIP Profiles")
        }
    },
    {
        name: '$.to-interface',
        uiHint: {
            fieldLabel: _T('Destination Interface')
        }
    },
    {
        name: '$.general-box',
        childrenNames: [
            '$.@name',
            '$.description',
            '$.tag'
        ],
        uiHint: {
            fieldLabel: _T('General')
        }
    },
    {
        name: '$.source-with-user-box',
        childrenNames: [
            '$.from.member',
            'source-with-negate',
            '$.source-user.member'
        ],
        uiHint: {
            layout: [
                {'$.from.member': 'col-10'},
                {'source-with-negate': 'col-12'},
                {'$.source-user.member': 'col-10'},
            ],
            fieldLabel: _T('Source')
        }
    },
    {
        name: '$.source-box',
        childrenNames: [
            '$.from.member',
            'source-with-negate'
        ],
        uiHint: {
            layout: [
                {'$.from.member': 'col-10'},
                {'source-with-negate': 'col-12'}
            ],
            fieldLabel: _T('Source')
        }
    },
    {
        name: '$.destination-box',
        childrenNames: [
            '$.to.member',
            'destination-with-negate'
        ],
        uiHint: {
            layout: [
                { '$.to.member': 'col-10'}, 
                {'destination-with-negate': 'col-12' }
            ],
            fieldLabel: _T('Destination')
        }
    }, 
    {
        name: '$.service-category-box',
        childrenNames: [
            '$.service.member',
            '$.category.member'
        ],
        uiHint: {
            layout: [
                { '$.service.member': 'col-10'}, 
                {'$.category.member': 'col-10' }
            ],
            fieldLabel: _T('Service/URL Category')
        }
    },
    {
        name: '$.app-box',
        childrenNames: [
            '$.application.member'
        ],
        uiHint: {
            layout: [
                { '$.application.member': 'col-10' }
            ],
            fieldLabel: _T('Application')
        }
    },
    {
        name: '$.app-hip-box',
        childrenNames: [
            '$.application.member',
            '$.hip-profiles.member'
        ],
        uiHint: {
            layout: [
                {'$.application.member': 'col-10'}, 
                {'$.hip-profiles.member': 'col-10'}
            ],
            fieldLabel: _T('Application/HIP Profile')
        }
    },
    {
        name: '$.action-box',
        childrenNames: [
            '$.action'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('Actions')
        }
    },
    // {
    //     name: '$.schedule-box',
    //     childrenNames: [
    //         '$.schedule'
    //     ],
    //     uiHint: {
    //         builder: 'FieldSetBuilder',
    //         fieldLabel: _T('Schedule Setting')
    //     }
    // },
    {
        name: '$.log-setting',
        uiHint: {
            fieldRequired: true,
            fieldRequiredValue: 'Cortex Data Lake',
            fieldLabel: _T('Log Forwarding')
        }
    },
    {
        name: '$.from.choice',
        uiHint: {
            fieldLabel: _T('Type'),
            largeSelectionCount: 1
        }
    },
    {
        name: '$.from.zone.member',
        uiHint: {
            fieldLabel: _T("Zones")
        }
    },
    {
        name: '$.from.interface.member',
        uiHint: {
            hideLabel: true,
            hasGridFilter: false,
            builder: 'EditorGridBuilder',
            height: 183
        }
    },
    {
        name: '$.to.choice',
        uiHint: {
            fieldLabel: _T('Type'),
            largeSelectionCount: 1
        }
    },
    {
        name: '$.to.zone.member',
        uiHint: {
            hideLabel: true,
            height: 183
        }
    },
    {
        name: '$.to.interface.member',
        uiHint: {
            hideLabel: true,
            builder: 'EditorGridBuilder',
            height: 183
        }
    },
    {
        name: '$.active-active-device-binding',
        pruneIfNotInSchema: true,
        uiHint: {
            fieldLabel: _T('Active/Active HA Binding')
        }
    },
    {
        name: '$.HA-box',
        childrenNames: [
            '$.active-active-device-binding'
        ],
        uiHint: {
            fieldLabel: _T('Active/Active HA Binding')
        }
    },
    {
        name: '$.action.forward.nexthop.choice',
        uiHint: {
            singleLineLayout: false
        }
    }
];

const getFormattedUsers = usersResponse => {
	let result = getPathValue(usersResponse, "response.result");
	let users = [];
	if (Pan.isArray(result)) {
        users = result.map(item => {
            return item.objects;
        })
        users = _.flatten(users);
        users = users.map(item => {
            return { value: item.dn, sAMAccountName: item.sAMAccountName, type: Pan.isDefined(item.type) ? item.type : '' };
        });
    }
    return users;
};

const getDomainNameMapping = (directoryDomains) => {
    let domains = {};
    if(Pan.isArray(directoryDomains)) {
        directoryDomains.forEach(item => {
            let dn = item.dn.replace(/\s/g, '');
            domains[dn] = {netbios:item.netbios, vsys: item.vsys, dns: item.dns};
        });
    }
    return domains;
}

const getDomains = (domainMapping) => {
    return Object.keys(domainMapping).map(item => domainMapping[item].dns);
}

const userDomainNameRenderer = (value, userGroupObject) => {
    return userGroupObject['sAMAccountName'] ? userGroupObject['sAMAccountName'] : '';
}

const groupDomainNameRenderer = (value) => {
    let input = value.toLowerCase();
    let cn = '';
    let test = input.match(/cn=(.*?)\,( )*(cn|o|ou|l|st|dc|dn|c)=/i);
    if(Pan.isArray(test) && test.length > 0) {
        cn = test[1];
    } else {
        input.replace(/cn=[^,]*/, function(a) {
            if (a && !cn) {
                cn = a.replace('cn=', '');
            }
            return '';
        });
    }
}

const domainNameRenderer = (value, domainMapping, userGroupObject) => {
    let user = value;
    if (Pan.isEmpty(value)) {
        return '';
    }
    if (!Pan.isEmpty(domainMapping)) {
        let input =  value.toLowerCase();
        let dc = '';
        input.replace(/dc=.*/,  function(matched) {
            dc = matched;
            return '';
        });
        if(!Pan.isEmpty(dc)) {
            let mapping = domainMapping[dc];
            if(mapping) {
                let cn = '';
                if(Pan.isDefined(userGroupObject) && !Pan.isEmpty(userGroupObject.type) && userGroupObject.type === 'users') {
                    cn = userDomainNameRenderer(value, userGroupObject);
                } else {
                    cn = groupDomainNameRenderer(value);
                }
                if (!cn) {
                    cn = input.replace(/([^=]*=)/, "").replace(/,.*/, "");
                }
                if (cn) {
                    user = mapping.netbios + '\\' + cn.replace(/\\/g, "");
                }
            }
        }
    }
    return user;
}

export const getUserDisplayLabel = (value) => {
    const {
		main: { directoryDomains = [] }
	} = store.getState();
	const domainMapping = getDomainNameMapping(directoryDomains);
	return domainNameRenderer(value, domainMapping).toLowerCase();
}
