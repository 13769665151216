import React from "react";
import PropTypes from "prop-types";
import Pan from "../../schema/Pan";
import { FormContext, FormConsumer } from "../../FormContext";
import { FormGroup, Label, Row, Col } from "reactstrap";
import { capitalizeLetter } from "../../schema/utils";
import './FakeSchemaContainerBuilder.scss';
import _ from 'lodash';
import { BaseFieldBuilder } from "../BaseBuilder";

export class FakeSchemaContainerBuilder extends BaseFieldBuilder {
    static NAME = 'FakeSchemaContainerBuilder';
    constructor(props) {
        super(props);
        this.state = {
            value: false
        }
        this.setRecordValue = super.setRecordValue.bind(this);
    }

    renderValue(vals) {
        if (Pan.isArray(vals)) {
            return vals.reduce((acc, cur) => {
                return acc + ', ' + cur;
            });
        }
        return vals;
    }

    renderLabel(label) {
        return <Label className='col-form-label'>{capitalizeLetter(label)}</Label>;
    }

    renderChildren() {
        const { field } = this.props;
        const dataSource = field.uiHint.fakeSchemaDataSource;
        const dataMapping = field.uiHint.dataMapping;
        const fakeDataHelpStringKey = _.get(this.context.formData, dataSource.substring(2));
        const encoderSelectedData = this.context.fields.filter(field => field.name === dataSource)[0].uiHint.helpStrings[fakeDataHelpStringKey];
        if (!encoderSelectedData) {
            return;
        }
        const decoderSelectedData = JSON.parse(decodeURIComponent(encoderSelectedData));
        const dataObject = decoderSelectedData[dataMapping];

        return (
            <FormGroup className='fake-schema-form' test_id={field.attrPath}>
                {
                    Object.keys(dataObject).map((key) => {
                        return (
                            <Row key={key}>
                                <Col sm="5" className="col-form-label">{key}</Col>
                                <Col sm="7">{this.renderValue(dataObject[key])}</Col>
                            </Row>
                        );
                    })
                }
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderChildren()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

FakeSchemaContainerBuilder.contextType = FormContext;

FakeSchemaContainerBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    FakeSchemaContainerBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        field: PropTypes.object,
        id: PropTypes.string,
        className: PropTypes.string
    };
}