import React, { Component } from "react";
import { showModal, hideModal } from "../../core/services/actions";
import { FilterWidget } from '../../core/autorender/widgets/FilterWidget';
import { GridWidget } from '../../core/autorender/widgets/GridWidget';
import { JsonViewer } from '../../core/autorender/widgets/JsonViewer';

import "./ErrorManager.scss";

const PAGE_SIZE = 25;

export default class ErrorManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterText: ''
        };
    }

    onFilter = (filterText) => {
        this.setState({
            filterText: filterText
        });
    }

    showErrorDetails = (error) => {
        let modal = {
            id: "ErrorDetailModal",
            open: true,
            title: "Error Details",
            size: "lg",
            type: "Info",
            toggle: () => {
                hideModal("ErrorDetailModal");
            },
            modalBody: {
                component: JsonViewer,
                props: {
                    src: error.original
                }
            },
            actions: [
                {
                    text: "Close",
                    color: "secondary",
                    action: () => {
                        hideModal("ErrorDetailModal");
                    }
                }
            ]
        };
        showModal(modal);
    }

    render() {
        const commonColumns = [
            {
                headerName: "Error Message",
                field: "msg",
                cellRendererParams: {
                    renderCell: params => {
                        return (
                            <div className='clearfix'>
                                <span className='float-left' style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{params.value}</span>
                                <a href="#" className='float-right' onClick={() => this.showErrorDetails(params)}>
                                    <span style={{ width: 20 }} className='text-center fa fa-info-circle' />
                                </a>
                            </div>
                        );
                    }
                }
            },
            {
                headerName: "Time",
                field: "timestamp",
                width: 120
            },
            {
                headerName: "Error Code",
                field: "errorCode",
                width: 175
            }
        ];

        const { data } = this.props;
        const header = "Error";
        return (
            <div className="h-100">
                <div className="pb-2">
                    <FilterWidget id="filterInput" objectName={header} onFilter={this.onFilter} />
                </div>
                <GridWidget
                    gridData={data}
                    gridActions={[]}
                    columns={commonColumns}
                    checkboxSelection={false}
                    pageSize={PAGE_SIZE}
                    noDataText={'No Errors'}
                    filterText={this.state.filterText}
                    showFilter={false}
                    showPaging={true}
                    minRows={0}
                    style={{ height: "550px" }}
                />
            </div>
        );
    }
}
