import React from "react";
import { GridWidget } from "ui-lib";

export default function CommitDetails(props) {
    return (
        <div>
            <GridWidget
                columns={props.columns}
                gridData={props.data}
                toggleRowSelection={props.toggleRowSelection}
                toggleSelectAll={props.toggleSelectAll}
                checkboxSelection={props.isPartialCommit}
                showPaging={props.data.length ? true : false}
                showGridToolBar={props.data.length ? true : false}
                noDataText={props.loading ? '' : props.noDataText}
                loading={props.loading}
                minRows={props.data.length || 3}
            />
        </div>
    );
}
