import React, { Component } from 'react';
import getTPLRecordViewer from '../../template/TPLRecordViewer';

const _T = str => str;

const certificateImportFields = [
    {
        type: 'string',
        name: 'name',
        mapping: 'name',
        uiHint: {
            fieldLabel : _T( 'Certificate Name'),
            vtype: 'objectName'
        }
    },
    {
        name: '$.certificateFile',
        uiHint: {
            builder: 'FileReaderBuilder',
            fieldLabel: _T('Certificate File')
        }
    },
    {
        name: '$.keyFile',
        uiHint: {
            builder: 'FileReaderBuilder',
            fieldLabel: _T('Key File'),
            association: {
                fields: ['$.format'],
                availHide: function (values, formData, filters, key) {
                    return formData && formData.format === 'pkcs12';
                }
            }
        }
    },
    {
        name: '$.passphrase',
        uiHint: {
            builder: 'PasswordBuilder',
            fieldLabel: _T('Passphrase'),
            allowBlank: true
        }
    }, 
    {
        name: '$.format',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            '$.certificateFile',
            '$.format',
            '$.keyFile',
            '$.passphrase'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            labelWidthSize: 5
        }
    }
];

window.__pan_schema.config.injected.certificate = {
    "@attr": {
        "node-type": "array",
    },
    "entry": {
        "@attr": {
            "node-type": "sequence"
        },
        "name": {
            "@attr": {
                "node-type": "attr-req",
                "type": "string",
                "minlen": "6",
                "maxlen": "64"
            }
        },
        "certificateFile": {
            "@attr": {
                "node-type": "element",
                "type": "string"
            }
        },
        "keyFile": {
            "@attr": {
                "node-type": "element",
                "optional": "yes",
                "type": "string"
            }
        },
        "passphrase": {
            "@attr": {
                "node-type": "element",
                "optional": "yes",
                "type": "string"
            }
        },
        "format": {
            "@attr": {
                "node-type": "element",
                type: "enum",
                default: "pem",
                "help-string": "certificate format",
                enum: [
                    {
                        value: "pkcs12",
                        "help-string": "Encrypted Private Key and Certificate (PKCS12)"
                    },
                    {
                        value: "pem",
                        "help-string": "Base64 Encoded Certificate (PEM)"
                    }
                ]
            }
        }
    }
};

export default class CertificateImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CertificateImportViewer: null
        };
    }
    
    componentDidMount() {
        this.setState({
            CertificateImportViewer: getTPLRecordViewer(
                '',
                'Device/CertificateManagement/ImportCertificate',
                '',
                'ImportCertificate',
            )
        });
    }

    render() {
        let CertificateImportViewer = this.state.CertificateImportViewer;
        return (
            CertificateImportViewer && 
            <CertificateImportViewer 
                {...this.props}
                fetchRecord = {false}
                rbaPath='device.certificate-management.certificates'
                panSchema = {window.__pan_schema}
                recordConfigPath = '$.config.injected.certificate.entry'
                inputFields = {certificateImportFields}
                header = 'Import Certificate'
                fetchAfterSuccess = {false}
            />
        );
    }
}