import React, { Component } from 'react';
import { DropdownWidget, connect, getStore } from 'ui-lib';
import { DG_TPL_MAP } from "service-lib";

import {
	changeConfigLocation
} from '../main/actions';

class DGSelection extends Component {

	onChangeLocation = (locationValue) => {
		DG_TPL_MAP.forEach(element => {
			if (element.dg === locationValue) {
				let location = {};
				location.dg = { name: element.dg };
				location.tpl = { name: element.tpl };
				getStore().dispatch(changeConfigLocation(location));
				return;
			}
		});
	}

    render() {
        return (
            <div className="location-dropdown">
                <DropdownWidget
                    value={this.props.value}
                    options={this.props.options}
                    onChange={this.onChangeLocation}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { options: state.main.allowedDG };
};

export default connect(mapStateToProps, null, null)(DGSelection);