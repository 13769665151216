import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './DropdownWidget.scss';

export class DropdownWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            options: props.options || [],
        };
        if (props.loadOptions) {
            // load the options from the promise
            this.getOptions(props);
        }
    }

    static getDerivedStateFromProps(nextProps, _) {
        if (nextProps.options) {
            return {
                value: nextProps.value,
                options: nextProps.options
            };
        }
        return null;
    }

    componentDidUpdate() {
        if (this.props.loadOptions) {
            // load the options from the promise
            this.getOptions(this.props);
        }
    }

    getOptions(props) {
        // only for async - local query. Need to cache otions
        props.loadOptions().then(
            response => {
                this.setState({
                    value: props.value,
                    options: response,
                });
            },
            error => { },
        );
    }

    click = (value) => {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { options = [], value } = this.state;
        return (
            <UncontrolledDropdown>
                <DropdownToggle caret>
                    {value}
                </DropdownToggle>
                <DropdownMenu>
                    {options.map(({ label, value, dropdownDisplayLevel }) => {
                        const { value: currentSelection } = this.props;
                        return (
                            <DropdownItem
                                key={value}
                                className={`${
                                        dropdownDisplayLevel ? `level-${dropdownDisplayLevel}`: ''
                                    } ${
                                        value === currentSelection ? 'selected': ''
                                    }`}
                                onClick={() => this.click(value)}
                            >
                                {label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

DropdownWidget.defaultProps = {
};

if (process.env.NODE_ENV !== 'production') {
    DropdownWidget.propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value:PropTypes.string,
            label:PropTypes.string
        })),
        value: PropTypes.any,
        loadOptions:PropTypes.func,
        onChange: PropTypes.func,
    };
}
