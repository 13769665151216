import * as React from "react";
import RcTooltip from "rc-tooltip";
import classNames from "classnames";
import getPlacements from "./util";

class Tooltip extends React.Component {
	static defaultProps = {
		placement: "top",
		transitionName: "zoom-big-fast",
		mouseEnterDelay: 0.1,
		mouseLeaveDelay: 0.1,
		arrowPointAtCenter: false,
		autoAdjustOverflow: true
	};

	static getDerivedStateFromProps(nextProps) {
		if ("visible" in nextProps) {
			return { visible: nextProps.visible };
		}
		return null;
	}

	constructor(props) {
		super(props);

		this.state = {
			visible: !!props.visible || !!props.defaultVisible
		};
	}

	onVisibleChange = visible => {
		const { onVisibleChange } = this.props;
		if (!("visible" in this.props)) {
			this.setState({ visible: this.isNoTitle() ? false : visible });
		}
		if (onVisibleChange && !this.isNoTitle()) {
			onVisibleChange(visible);
		}
	};

	getPopupDomNode() {
		return this.tooltip.getPopupDomNode();
	}

	getPlacements() {
		const { builtinPlacements, arrowPointAtCenter, autoAdjustOverflow } = this.props;
		return (
			builtinPlacements ||
			getPlacements({
				arrowPointAtCenter,
				verticalArrowShift: 8,
				autoAdjustOverflow
			})
		);
	}

	saveTooltip = node => {
		this.tooltip = node;
	};

	// Set animation point dynamically
	onPopupAlign = (domNode, align) => {
		const placements = this.getPlacements();
		// Current position returned
		const placement = Object.keys(placements).filter(key => placements[key].points[0] === align.points[0] && placements[key].points[1] === align.points[1])[0];
		if (!placement) {
			return;
		}
		// Set animation point based on current position
		const rect = domNode.getBoundingClientRect();
		const transformOrigin = {
			top: "50%",
			left: "50%"
		};
		if (placement.indexOf("top") >= 0 || placement.indexOf("Bottom") >= 0) {
			transformOrigin.top = `${rect.height - align.offset[1]}px`;
		} else if (placement.indexOf("Top") >= 0 || placement.indexOf("bottom") >= 0) {
			transformOrigin.top = `${-align.offset[1]}px`;
		}
		if (placement.indexOf("left") >= 0 || placement.indexOf("Right") >= 0) {
			transformOrigin.left = `${rect.width - align.offset[0]}px`;
		} else if (placement.indexOf("right") >= 0 || placement.indexOf("Left") >= 0) {
			transformOrigin.left = `${-align.offset[0]}px`;
		}
		domNode.style.transformOrigin = `${transformOrigin.left} ${transformOrigin.top}`;
	};

	isNoTitle() {
		const { title, overlay } = this.props;
		return !title && !overlay; // overlay for old version compatibility
	}

	render() {
		const { props, state } = this;
		const { title, overlay, openClassName, getPopupContainer, getTooltipContainer } = props;
		const children = props.children;
		const prefixCls = props.prefixCls ? props.prefixCls : "pan-po-tooltip";
		let { visible } = state;
		// Hide tooltip when there is no title
		if (!("visible" in props) && this.isNoTitle()) {
			visible = false;
		}

		const child = React.isValidElement(children) ? children : <span>{children}</span>;

		const childProps = child.props;
		const childCls = classNames(childProps.className, {
			[openClassName || `${prefixCls}-open`]: true
		});

		return (
			<RcTooltip
				{...this.props}
				prefixCls={prefixCls}
				getTooltipContainer={getPopupContainer || getTooltipContainer || (() => document.body)}
				ref={this.saveTooltip}
				builtinPlacements={this.getPlacements()}
				overlay={overlay || title || ""}
				visible={visible}
				onVisibleChange={this.onVisibleChange}
				onPopupAlign={this.onPopupAlign}
			>
				{visible ? React.cloneElement(child, { className: childCls }) : child}
			</RcTooltip>
		);
	}
}

export default Tooltip;
