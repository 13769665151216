import { ViewerManager, Pan } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

const _T = str => str;

const serviceFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    },
    {
        name: '$.protocol.tcp.source-port',
        uiHint: {
            showHelpString: _T("Port can be a single port #, range (1-65535), or comma separated (80, 8080, 443)")
        }
    },
    {
        name: '$.protocol.tcp.port',
        uiHint: {
            fieldLabel: _T('Destination Port')
        }
    },
    {
        name: '$.protocol.tcp.override',
        uiHint: {
            fieldLabel: _T('Session Timeout')
        }
    },
    {
        name: '$.protocol.tcp.override.yes',
        childrenNames: [
            '$.protocol.tcp.override.yes.timeout',
            '$.protocol.tcp.override.yes.halfclose-timeout',
            '$.protocol.tcp.override.yes.timewait-timeout'
        ],
        uiHint: {
            fieldLabel: _T('Override'),
            // layout: [{
            //     '$.protocol.tcp.override.yes.timeout': "col-6",
            //     '$.protocol.tcp.override.yes.halfclose-timeout': "col-6"
            // }, {
            //     '$.protocol.tcp.override.yes.timewait-timeout': "col-12"
            // }]
        }
    },
    {
        name: '$.protocol.tcp.override.yes.timeout',
        uiHint: {
            fieldLabel: _T('TCP Timeout')
        }
    },
    {
        name: '$.protocol.tcp.override.yes.halfclose-timeout',
        uiHint: {
            fieldLabel: _T('TCP Half Closed')
        }
    },
    {
        name: '$.protocol.tcp.override.yes.timewait-timeout',
        uiHint: {
            fieldLabel: _T('TCP Wait Time')
        }
    },
    {
        name: '$.protocol.tcp.override.no',
        uiHint: {
            fieldLabel: _T('Inherit from application')
        }
    },
    {
        name: '$.protocol.tcp',
        childrenNames: [
            '$.protocol.tcp.port',
            '$.protocol.tcp.source-port',
            '$.protocol.tcp.override'
        ],
        uiHint: {
            fieldLabel: _T('TCP')
        }
    },
    {
        name: '$.protocol.udp.port',
        uiHint: {
            fieldLabel: _T('Destination Port')
        }
    },
    {
        name: '$.protocol.udp.source-port',
        uiHint: {
            fieldLabel: _T('Source Port'),
            showHelpString: _T("Port can be a single port #, range (1-65535), or comma separated (80, 8080, 443)")
        }
    },
    {
        name: '$.protocol.udp.override',
        uiHint: {
            fieldLabel: _T('Session Timeout')
        }
    },
    {
        name: '$.protocol.udp.override.yes',
        uiHint: {
            fieldLabel: _T('Override'),
            columnCount: 3
        }
    },
    {
        name: '$.protocol.udp.override.yes.timeout',
        uiHint: {
            fieldLabel: _T('UDP Timeout')
        }
    },
    {
        name: '$.protocol.udp.override.no',
        uiHint: {
            fieldLabel: _T('Inherit from application')
        }
    },
    {
        name: '$.protocol.udp',
        childrenNames: [
            '$.protocol.udp.port',
            '$.protocol.udp.source-port',
            '$.protocol.udp.override'
        ],
        uiHint: {
            fieldLabel: _T('UDP')
        }
    },
    {
        name: '$.protocol.sctp',
        uiHint: {
            fieldLabel: _T('SCTP')
        }
    },
    {
        name: '$.protocol.sctp.port',
        uiHint: {
            fieldLabel: _T('Destination Port')
        }
    },
    {
        name: '$.protocol.sctp.source-port',
        uiHint: {
            showHelpString: _T("Port can be a single port #, range (1-65535), or comma separated (80, 8080, 443)")
        }
    },
    {
        name: '$.protocol',
        uiHint: {
            largeSelectionCount: 4
        }
    },
    {
        name: 'port',
        attrPath: "$.tcpOrUDPPort",
        mapping: 'protocol',
        type: 'rangelistspec',
        uiHint: {
            fieldLabel: _T('Destination Port')
        }
    },
    {
        name: 'source-port',
        attrPath: "$.tcpOrUDPSourcePort",
        mapping: 'protocol',
        type: 'rangelistspec',
        uiHint: {
            fieldLabel: _T('Source Port')
        }
    },
    {
        name: '$',
        childrenNames: [
            "name",
            "location",
            "$.description",
            "$.protocol",
            "$.tag"
        ]
    }
];


const serviceObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    minWidth: 100
}, {
    headerName: 'Description',
    field: 'description',
    minWidth: 150
}, {
    headerName: 'Location',
    field: '@loc',
    minWidth: 100
}, {
    headerName: 'Protocol',
    valueGetter: params => {
        let protocol = params.data.protocol;
        let pt = protocol ? (protocol.tcp ? 'TCP' :
            protocol.udp ? 'UDP' :
                protocol.sctp ? 'SCTP' : '') : '';
        return pt;
    },
    minWidth: 100
}, {
    headerName: 'Destination Port',
    valueGetter: params => {
        let protocol = params.data.protocol;
        let pt = protocol ? (protocol.tcp ? 'tcp' :
            protocol.udp ? 'udp' :
                protocol.sctp ? 'sctp' : '') : '';
        let port = params.data.protocol[pt] && params.data.protocol[pt].port ? params.data.protocol[pt].port : '';
        return port;
    },
    minWidth: 100
}, {
    headerName: 'Tags',
    field: 'tag.member',
    minWidth: 80
}, {
    headerName: 'Source Port',
    valueGetter: params => {
        let protocol = params.data.protocol;
        let pt = protocol ? (protocol.tcp ? 'tcp' :
            protocol.udp ? 'udp' :
                protocol.sctp ? 'sctp' : '') : '';
        let port = params.data.protocol[pt] && params.data.protocol[pt]['source-port'] ? params.data.protocol[pt]['source-port'] : '';
        return port;
    },
    minWidth: 50
}];

const viewConfig = {
    serviceName: 'Objects/Services',
    reduxId: 'serviceObjects',
    header: 'Services',
    columns: serviceObjectColumns,
    fields: serviceFields,
    recordConfigPath: '$.config.devices.entry.device-group.entry.service.entry',
    rbaPath: 'objects.services',
    className: 'Services'
}

export default class Services extends DGBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);
