import { Pan } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import './SecurityPolicies.scss';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str;

export const securityPolicyFields = unionBy([{
    // Option configuration
    name: 'options',
    childrenNames: [
        '$.log-setting-box',
        '$.others-box'
    ]
}, {
    name: '$.from.member.*',
    defaultValue: 'trust'
},{
    name: '$.to.member',
    uiHint: {
        fieldLabel: _T('Zone')
    }
}, {
    name: '$.to.member.*',
    defaultValue: 'trust'
}, {
    // Make Action option be First Letter Capitalized
    name: '$.action',
    uiHint: {
        useHelpStringAsDisplay: true
    }
}, {
    name: '$.icmp-unreachable',
    uiHint: {
        fieldLabel: _T('Send ICMP Unreachable'),
        association: {
            fields: ['$.action'],
            updateFormData: function (values, formData, filters, key) {
                if (values && values[0] && (values[0] === 'allow' || values[0] === 'deny')) {
                    return { '$.icmp-unreachable': false };
                }
            }, 
            availDisable: function (values, formData, filters, key) {
                return (values && values[0] && (values[0] === 'allow' || values[0] === 'deny'));
            }, 
        }
    }
}, {
    name: '$.action-box',
    childrenNames: [
        '$.action',
        '$.icmp-unreachable'
    ],
    uiHint: {
        builder: 'FieldSetBuilder',
        fieldLabel: _T('Action Setting')
    }
}, {
    name: '$.log-setting-box',
    childrenNames: [
        '$.log-start',
        '$.log-end',
        '$.log-setting'
    ],
    uiHint: {
        builder: 'FieldSetBuilder',
        fieldLabel: _T('Log Setting')
    }
}, {
    name: '$.source-with-user-hip-box',
    childrenNames: [
        '$.from.member',
        'source-with-negate',
        '$.source-user.member',
        '$.hip-profiles.member'
    ],
    uiHint: {
        layout: [
            {'$.from.member': 'col-10'},
            {'source-with-negate': 'col-12'},
            {'$.source-user.member': 'col-10'},
            {'$.hip-profiles.member': 'col-10'}
        ],
        fieldLabel: _T('Source')
    }
},  {
    name: '$.others-box',
    childrenNames: [
        // '$.schedule',
        '$.qos.marking',
        '$.option.disable-server-response-inspection'
    ],
    uiHint: {
        builder: 'FieldSetBuilder',
        fieldLabel: _T('Other Settings')
    }
}, {
    name: '$.option.disable-server-response-inspection',
    uiHint: {
        fieldLabel: _T('Disable Server Response Inspection')
    }
}, {
    name: '$.log-start',
    uiHint: {
        fieldLabel: _T('Log at Session Start')
    }
}, {
    name: '$.log-end',
    uiHint: {
        fieldLabel: _T('Log at Session End')
    }
}, {
    name: '$.qos.marking',
    uiHint: {
        fieldLabel: _T('QoS Marking'),
        useHelpStringAsDisplay: true,
        largeSelectionCount: 1,
        singleLineLayout: false
    }
}, {
    name: '$.qos.marking.ip-precedence',
    uiHint: {
        helpstring: "IP Precedence",
        setValueToDisplay: false
    }
}, {
    name: '$.qos.marking.ip-dscp',
    uiHint: {
        showHelpString: "IP DSCP",
        setValueToDisplay: false
    }
}, {
    // Create a new rule
    name: '$.tabs-box',
    childrenNames: [
        '$.source-with-user-hip-box',
        '$.destination-box',
        '$.app-box',
        '$.service-category-box',
        '$.profile-option-box'
    ],
    uiHint: {
        builder: 'TabBuilder',
        deferredRender: false
    }
}, {
    // Create a new rule
    name: '$',
    childrenNames: [
        '$.general-box',
        '$.tabs-box'
    ]
}, {
    name: '$.profile-setting-box',
    childrenNames: [
        '$.profile-setting'
    ],
    uiHint: {
        builder: 'FieldSetBuilder',
        fieldLabel: _T('Profile Setting')
    }
}, {
    name: '$.profile-setting.profiles',
    childrenNames: [
        '$.profile-setting.profiles.virus.member',
        '$.profile-setting.profiles.vulnerability.member',
        '$.profile-setting.profiles.spyware.member',
        '$.profile-setting.profiles.url-filtering.member',
        '$.profile-setting.profiles.file-blocking.member',
        '$.profile-setting.profiles.wildfire-analysis.member',
        //'$.profile-setting.profiles.gtp.member',
        // '$.profile-setting.profiles.sctp.member'
    ]
}, {
    name: '$.profile-setting.profiles.url-filtering.member',
    uiHint: {
        fieldLabel: _T('URL Filtering')
    }
}, {
    name: '$.profile-setting.profiles.file-blocking.member',
    uiHint: {
        fieldLabel: _T('File Blocking')
    }
}, {
    name: '$.profile-setting.profiles.virus.member',
    uiHint: {
        fieldLabel: _T('Antivirus')
    }
}, {
    name: '$.profile-setting.profiles.spyware.member',
    uiHint: {
        fieldLabel: _T('Anti-Spyware')
    }
}, {
    name: '$.profile-setting.profiles.vulnerability.member',
    uiHint: {
        fieldLabel: _T('Vulnerability Protection')
    }
}, {
    name: '$.profile-setting.profiles.wildfire-analysis.member',
    uiHint: {
        fieldLabel: _T('WildFire Analysis')
    }
}, {
    name: '$.profile-setting.profiles.sctp.member',
    uiHint: {
        fieldLabel: _T('SCTP Protection')
    }
}, {
    name: '$.profile-setting.group.member',
    uiHint: {
        fieldLabel: _T('Group Profile'),
        allowBlank: false
    }
}, {
    name: '$.profile-setting.group.member.*',
    defaultValue: 'best-practice',
    uiHint: {
        allowBlank: false
    }
}, {
    name: '$.profile-setting',
    uiHint: {
        fieldLabel: _T('Profile Type'),
        fieldRequiredValue: 'group',
        fieldRequired: true,
        largeSelectionCount: 1
    }
}, {
    name: '$.action-profile-box',
    childrenNames: [
        '$.action-box',
        '$.profile-setting-box'
    ]
}, {
    name: '$.profile-option-box',
    childrenNames: [
        '$.action-profile-box',
        'options'
    ],
    uiHint: {
        layout: [
            {'$.action-profile-box': 'col-6', 'options': 'col-6'}
        ],
        fieldLabel: _T('Actions'),
        labelWidthSize: 4
    }
}, {
    name: '$.negate-source',
    uiHint: {
        hideLabel: true,
        boxLabel: _T('Negate')
    }
}, {
    name: '$.negate-destination',
    uiHint: {
        hideLabel: true,
        boxLabel: _T('Negate')
    }
}, {
    name: '$.general-box',
    childrenNames: [
        '$.@name',
        '$.description',
        '$.tag'
    ],
    uiHint: {
        fieldLabel: _T('General')
    }
}], commonFields);


export const securityPolicyColumns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    {
        headerName: 'Source',
        children: [
            Pan.apply({}, {}, columnConfig.from),
            Pan.apply({}, {}, columnConfig.source_negate),
            Pan.apply({}, {}, columnConfig.source_user),
            Pan.apply({}, {}, columnConfig.hip_profile),
        ]
    },
    {
        headerName: 'Destination',
        children: [
            Pan.apply({}, {}, columnConfig.to),
            Pan.apply({}, {}, columnConfig.destination_negate),
        ]
    },
    Pan.apply({}, {}, columnConfig.application),
    Pan.apply({}, {}, columnConfig.service),
    Pan.apply({}, {}, columnConfig.category),
    Pan.apply({}, {}, columnConfig.action),
    Pan.apply({}, {}, columnConfig.profile_setting),
    Pan.apply({}, {}, columnConfig.options),
    Pan.apply({}, {}, columnConfig.description)
];

export default class SecurityPolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/SecurityPreRules';
    reduxId = 'securityPreRules';
    header = 'Security Pre Rules';
    columns = securityPolicyColumns;
    fields = securityPolicyFields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.security.rules.entry';
    rbaPath = 'policies.security-rulebase';
    allowDrop = this.allowDrop;
    allowDrag = this.allowDrag;
}
