/*!
 * PAN Management UI
 * Copyright(c) Palo Alto Networks, Inc.
 */

import Pan from './Pan';
import { jsonPath } from "../../utils/json";

const types = {
    auto: {
        type: 'auto',
        uiHint: {
            builder: function (config) {
                var builder = 'TextfieldBuilder';
                if (config.autoComplete) {
                    builder = 'CompletionBuilder';
                }
                return builder;
            }
        }
    },
    bool: {
        type: 'bool',
        uiHint: {
            builder: 'CheckboxBuilder'
        }
    },
    reverseBool: {
        type: 'reverseBool',
        uiHint: {
            builder: 'CheckboxBuilder',
            type: 'reverse'
        }
    },
    date: {
        type: 'date',
        uiHint: {
            builder: 'DatefieldBuilder'
        }
    },
    float: {
        type: 'float',
        uiHint: {
            builder: 'NumberfieldBuilder'
        },
        defaults: {
            max: 1000000,
            min: 0
        }
    },
    int: {
        type: 'int',
        uiHint: {
            builder: 'NumberfieldBuilder',
            allowDecimals: false
        }
    },
    string: {
        type: 'string',
        uiHint: {
            builder: function (config) {
                var builder = 'TextfieldBuilder'
                if (config.autoComplete) {
                    builder = 'CompletionBuilder';;
                }
                return builder;
            },
            vtype: 'isString'
        }
    },
    array: {
        type: 'array',
        uiHint: {
            builder: function (config) {
                var builder = 'EditorGridBuilder';
                if (config.maxCount === "1" && config.attrName !== 'entry' ) {
                    builder = 'CompletionBuilder';
                }
                return builder;
            }
        }
    },
    union: {
        type: 'union',
        uiHint: {
            builder: 'RadioCardBuilder'
        }
    },
    sequence: {
        type: 'sequence',
        uiHint: {
            builder: function (config) {
                var builder = 'ContainerBuilder';
                if (config.allowBlank || (config.uiHint && config.uiHint.allowBlank)) {
                    if (config.childrenNames && config.childrenNames.length > 0) {
                        // use field set to enable/disable children content if optional field
                        builder = 'FieldSetBuilder';
                    } else {
                        // empty field set does not make sense for sequence with no child.  Use sequencecheckbox instead.
                        //builder = "SequenceCheckboxBuilder";
                        builder = "ContainerBuilder";
                    }
                }
                return builder;
            }
        }
    },
    choice: {
        type: 'choice',
        uiHint: {
            builder: 'RadioCardBuilder'
        }
    },
    enum: {
        type: 'enum',
        uiHint: {
            builder: function (config) {
                let builder = 'CompletionBuilder';
                let optionsEnum = config && config.uiHint ? config.uiHint.enum : null;
                if (optionsEnum.length === 1) {
                    builder = 'CheckboxBuilder';
                }
                return builder;
            },
            mode: 'local'
        }
    },
    multiple: {
        type: 'multiple',
        uiHint: {
            builder: function (config) {
                let builder = 'CompletionBuilder';
                if (config.multitypes && !jsonPath(config.multitypes, "$.string.complete-handler")) {
                    if (!config.multitypes['enum'] && !config.autoComplete) {
                        builder = 'TextfieldBuilder';
                    }
                }
                return builder;
            }
        }
    },
    password: {
        type: 'password',
        uiHint: {
            builder: 'PasswordBuilder'
        }
    },
    // definitions of "like" types
    rangedint: {
        type: "rangedint",
        uiHint: {
            builder: 'NumberfieldBuilder',
            allowDecimals: false,
            vtype: 'rangedInt'
        },
        defaults: {
            max: 65535,
            min: 1
        }
    },
    ipspec: {
        type: 'ipspec',
        uiHint: {
            builder: 'TextfieldBuilder',
            vtype: function(field) {
                var multicastOnly = field['multicast-only'] === 'yes' || field['multicast-only'] === true;
                var unicastWithMask = field['unicast-with-mask'];
                var ipv4Only = field['ipv4-only'] === 'yes' || field['ipv4-only'] === true;
                var ipv6Only = field['ipv6-only'] === 'yes' || field['ipv6-only'] === true;

                var vtype = 'ipAndIntegerSubnetMaskV4orV6';
                if (multicastOnly && ipv4Only) {
                    vtype = 'multicast';
                }
                else if (ipv4Only) {
                    vtype = 'ipAndIntegerSubnetMaskV4';
                }
                if (unicastWithMask) {
                    vtype = 'ipAndIntegerSubnetMaskV4orV6SubnetMaskRequired';
                }
                if (ipv6Only) {
                    vtype = 'ipAndIntegerSubnetMaskV6';
                }
                return vtype;
            }
        }
    },
    iprangespec: {
        type: 'iprangespec',
        uiHint: {
            builder: 'TextfieldBuilder',
            vtype: 'ipRange',
            maxLength: 91   //ipV6 max length can be 45. So 2 Ipv6 = 45 + 45 + 1('-' seperator)
        }
    },
    mac: {
        type: 'mac',
        uiHint: {
            builder: 'TextfieldBuilder',
            vtype: 'macAddress'
        }
    },
    rangelistspec: {
        type: 'rangelistspec',
        uiHint: {
            builder: 'TextfieldBuilder',
            vtype: 'rangeList',
            pattern: '[.0-9,\-]'
        }
    },
    tag: {
        type: 'tag',
        uiHint: {
            builder: 'TagBuilder'
        }
    },
    adminrole: {
        type: 'adminrole',
        uiHint: {
            builder: 'AdminroleBuilder'
        }
    }
};

export default class PanType {

    static get(type) {
        return types[type];
    }

    /**
     * Get type info.
     * @param type
     * @param {string} [attrName] Optional. It's the name of the node of the specified type.
     * @returns {object} Type info.
     */
    static getTypeInfo(type, attrName) {
        var result = PanType.get(type);
        if (Pan.isFunction(result)) {
            result = result(attrName);
        }

        return result;
    }

}
