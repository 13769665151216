import React from 'react';
import { ViewerManager } from '../../widgets';
import { FormContext } from '../../FormContext';
import Pan from "../../schema/Pan";
import {  Col, FormGroup, Label } from "reactstrap";
import { getStoreState } from '../../../utils/storeRegistry';
import { getPathValue } from '../../../utils/json';
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";
import './ViewerDisplayBuilder.scss';
import { BaseBuilder } from '../BaseBuilder/BaseBuilder';
import PropTypes from 'prop-types';

export class ViewerDisplayBuilder extends BaseBuilder {
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.state = {
            hideField: false,
            errors: ''
        }
    }

    afterUpdate() {
        let { field, addError, removeError } = this.props;
        if (field && field.uiHint && Pan.isFunction(field.uiHint.customValidation)) {
            let fieldErrors = field.uiHint.customValidation();
            if (fieldErrors !== this.state.errors) {
                let { errors } = this.context;
                let recPath = field.attrPath || field.name;
                if (Pan.isEmpty(fieldErrors) && errors[recPath]) {
                    removeError(recPath);
                } else if (!Pan.isEmpty(fieldErrors) && !errors[recPath]) {
                    addError({ [recPath]: fieldErrors });
                }
                this.setState({
                    errors: fieldErrors
                });
            }
        }
    }
    
    render() {
        let { field, containerLabelWidthSize } = this.props;
        if (this.props.hidden || this.state.hideField) {
            return (
                <div />
            );
        }
        let uiHint = field ? field.uiHint : null;
        let viewerName = uiHint ? uiHint.viewerName : null;
        let viewerProps = uiHint ? uiHint.viewerProps : null;
        let fieldLabel = uiHint.fieldLabel;
        let errors = this.state.errors;
        let valid = errors ? 'is-invalid' : '';
        if (fieldLabel === field.uiHint.fieldLabelAutoGen) {
            fieldLabel = '';
        }
        let labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
        let labelClassName = labelWidthSize ? 'col-sm-' + labelWidthSize :
            containerLabelWidthSize ? 'col-sm-' + containerLabelWidthSize : '';
        let Viewer = viewerName ? ViewerManager.getView(viewerName) : null;
        let store = getStoreState();
        let configLocation = this.props.configLocation ? this.props.configLocation :
            store && store.main && store.main.configLocation ? store.main.configLocation : {};

        return (
            Viewer && (
                <FormGroup className={"d-flex flex-row viewer-display"}>
                    {fieldLabel &&
                        <Label className={"flex-grow-0 flex-shrink-0 col-form-label " + labelClassName}>
                            {fieldLabel}
                        </Label>
                    }
                    <Col id={this.id} className={`${valid}`}>
                        <Viewer configLocation={configLocation} afterUpdate={this.afterUpdate.bind(this)} {...viewerProps} />
                        {errors && <FormFeedbackWidget target={this.id} placement={'bottom'} feedback={errors} />}
                    </Col>
                </FormGroup>
            )
        );
    }
}

ViewerDisplayBuilder.contextType = FormContext;

ViewerDisplayBuilder.defaultProps = {};

if (process.env.NODE_ENV !== 'production') {
    ViewerDisplayBuilder.propTypes = {
        hidden: PropTypes.bool,
        configLocation: PropTypes.object,
        /**
         * uiHint properties
         * *viewerName* - Name of the viewer as registered in the ViewerManager
         * *viewerProps* - props to be passed in the ViewerManager
         * if fieldLabel==fieldLabelAutoGen, fieldLabel will be blank
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
         */
        field: PropTypes.shape({
            defaultValue: PropTypes.string,
            uiHint: PropTypes.shape({
                viewerName: PropTypes.string,
                viewerProps: PropTypes.object,
                fieldLabel: PropTypes.string,
                fieldLabelAutoGen: PropTypes.string,
                labelWidthSize: PropTypes.string,
                allowBlank: PropTypes.bool,
                vtype: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
                customValidation: PropTypes.func,
            })
        }),
        onChange: PropTypes.func,
        addError: PropTypes.func,
        cellInfo: PropTypes.object,
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                find: PropTypes.string,
                replace: PropTypes.string,
                append: PropTypes.string,
            }),
        ),
        removeError: PropTypes.func.isRequired,
    };
}