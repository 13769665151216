import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { filter, get, each } from 'lodash';
import './RouteViewer.scss';
import Pan from "../../core/autorender/schema/Pan";
import {
    AppSidebar,
    AppSidebarNav,
} from '@coreui/react';
import { getRBAPermission, isPermissionDisabled } from '../../core/autorender/widgets/util';

export default class RouteViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
    }

    componentDidMount() {
        document.querySelector('#pan-nav').addEventListener('cortex-left-bar', (e) => {
            const { leftbar } = e.detail;
            this.setState({
                isOpen: leftbar
            });
        });
    }

    getRBAFromRoute(routeUrl) {
        let rba = routeUrl ? routeUrl.replace(/\//g, '.') : "";
        rba = rba && rba.startsWith('.') ? rba.slice(1) : rba;
        return rba;
    }

    execAvailNavigation(navigation) {
        let availNavigation = [];
        navigation.forEach(nav => {
            if (nav && (nav.component || nav.title) && (nav.url || nav.rbaPath) && this.isValidRoute(nav)) {
                availNavigation.push(nav)
            } else if (nav && nav.items) {
                let navItems = this.execAvailNavigation(nav.items);
                if (navItems && navItems.length > 0) {
                    nav.items = navItems;
                    availNavigation.push(nav);
                }
            } else if (nav && nav.children) {
                let navChildren = this.execAvailNavigation(nav.children);
                if (navChildren && navChildren.length > 0) {
                    nav.children = navChildren;
                    availNavigation.push(nav)
                }
            }
        });
        return availNavigation;
    }

    isValidRoute = (nav) => {
        let rba = this.getRBAFromRoute(nav.rbaPath || nav.url);
        return ((nav.noRBACheck || (!isPermissionDisabled(getRBAPermission(rba)))) && (!nav.avail || nav.avail(this.props.configLocation, this.props.licenseInfo)));
    }

    getFirstAvailDefaultRouteURL(navItems) {
        let firstAvailDefaultRouteURL = "/";
        each(navItems, (nav) => {
            if (firstAvailDefaultRouteURL === "/") {
                if ( nav.url && this.isValidRoute(nav) ) {
                    firstAvailDefaultRouteURL = nav.url;
                    return false;
                }
                else if (nav.children)  {
                    firstAvailDefaultRouteURL = this.getFirstAvailDefaultRouteURL(nav.children);
                }
            } else {
                return false;
            }
        });
        return firstAvailDefaultRouteURL;
    }

    updateDefaultRoute(navItem, parent) {
        let { defaultRoutes } = this.props;
        // if route is invalid, use suggested route from getNextRoute
        if(!this.isValidRoute(navItem) && parent) {
            const navParent = filter(this.props.navigation, (np) => { return np.name === parent; });
            const navItems =  get(navParent, "0.items");
            let firstAvailDefaultRouteURL = this.getFirstAvailDefaultRouteURL(navItems);
            defaultRoutes[parent] = firstAvailDefaultRouteURL;
        }
        return defaultRoutes;      
    }

    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    renderRouteComponent = (navItem, parent) => {
        if (navItem.name === 'dashboard') {
            // for dashboard no sidebar. Need suspense
            return (
                <Route
                    key={navItem.url}
                    exact={navItem.exact}
                    path={navItem.url}
                    render={(props) => {
                        return (
                            <Container fluid>
                                <Suspense fallback={this.loading()}>
                                    <navItem.component {...props} {...this.props} />
                                </Suspense>
                            </Container>
                        );
                    }}
                />
            );
        }

        let defaultRoutes = this.updateDefaultRoute(navItem, parent);

        // all the leaf nodes with component will create a route
        return (
            <Route
                key={navItem.url}
                exact={navItem.exact}
                path={navItem.url}
                render={(props) => {
                    if (this.isValidRoute(navItem)) {
                        return (<navItem.component {...props} {...this.props} />);
                    }
                    // no permission for this route. redirect to paret defaultRoute
                    return (<Redirect to={parent && defaultRoutes[parent] ? defaultRoutes[parent] : '/'} />);
                }}
            />
        );
    }

    renderRouteItems = (navItem, parent) => {
        let { defaultRoutes } = this.props;

        // object, policy, configure
        let availNavigation = Pan.clone(navItem);
        availNavigation.items = this.execAvailNavigation(availNavigation.items);
        return (
            <Route
                key={navItem.url}
                path={navItem.url}
                render={props => {
                    // navItem with items will display sidebar and main panel
                    return (
                        <React.Fragment>
                            <AppSidebar className='left-nav' display="lg">
                                <Suspense>
                                    <AppSidebarNav navConfig={availNavigation} {...props} />
                                </Suspense>
                            </AppSidebar>
                            <main className="main" id="main">
                                <Container fluid>
                                    <Suspense fallback={this.loading()}>
                                        <Switch>
                                            {this.renderNavigation(navItem.items, navItem.name)}
                                            <Redirect to={defaultRoutes[navItem.name] ? defaultRoutes[navItem.name] : '/'} />
                                        </Switch>
                                    </Suspense>
                                </Container>
                            </main>
                        </React.Fragment>
                    );
                }}
            />
        );
    }

    renderNavigation = (nav, parent) => {
        return nav.map((navItem, i) => {
            if (navItem && navItem.component) {
                return this.renderRouteComponent(navItem, parent);
            } else if (navItem && navItem.items) {
                return this.renderRouteItems(navItem, parent);
            } else if (navItem && navItem.children) {
                // nested child navigation - we will display as collapsible side section
                return this.renderNavigation(navItem.children, navItem.name);
            }
        });
    }

    render() {
        let NavItems = this.renderNavigation(this.props.navigation);
        return (
            <div className="app">
                {this.props.appBar}
                <div id="mainframe" className={this.state.isOpen ? "app-body" : "app-body reset-body"}>
                    <Switch>
                        {NavItems}
                        <Redirect from="/" to="/dashboard" />
                    </Switch>
                </div>
            </div>
        );
    }
}