import _, { get } from 'lodash';
import DGBaseViewer from '../deviceGroup/DGBaseViewer';
import { fetchDirectoryDomains } from "../main/actions";
import { isDirectorySyncServiceActivated } from "ui-lib";
import { isPermissionEnabled, getRBAPermission, getSelectedAndIndices, getStore, getStoreState } from 'ui-lib';
import {
    enablePolicies,
    disablePolicies,
    movePolicies,
    moveTopPolicies,
    moveBottomPolicies
} from '../deviceGroup/actions';

export default class PoliciesBaseViewer extends DGBaseViewer {

    constructor(props) {
        super(props);
        this.showEnableAction = true;
        this.showDisableAction = true;
        this.isMoveable = true;
        this.initActions = this.getInitActions();

        // this.agGridProps = {
        //     onRowDataUpdated: function(params) {
        //         let gridApi = params.api;
        //         gridApi.forEachNode( function(rowNode, index) {
        //             rowNode.ruleNumber = index + 1;
        //         });
        //         let refreshParams = {
        //             columns: ['ruleNumber'],
        //             force: true
        //         }
        //         gridApi.refreshCells(refreshParams);
                
        //     }
        // };
    }
    
    getInitActions() {
		let initActions = [];
		if(isDirectorySyncServiceActivated()){
			initActions.push(fetchDirectoryDomains)
		}
		return initActions;
	}

    allowDrag = (params) => {
        return _.get(params,"data.@loc")===_.get(getStoreState(),"main.configLocation.dg.name");
    }

    allowDrop = (params, targetNode) => {
        return params.node === targetNode && _.get(params,"data.@loc")===_.get(getStoreState(),"main.configLocation.dg.name");
    }

    onEnablePolicies(records) {
        getStore().dispatch(
            enablePolicies(records, this.state.serviceMap, this.state.reduxStateId, this.props.configLocation),
        );
    }

    onDisablePolicies(records) {
        getStore().dispatch(
            disablePolicies(records, this.state.serviceMap, this.state.reduxStateId, this.props.configLocation),
        );
    }

    bulkMovePolicies(selectedValues, movePosition, dstRuleName) {
        const RuleNameArray=selectedValues.map(item=>{
            return item['@name'];
        })
            switch (movePosition) {
                case 'top':
                    RuleNameArray.forEach(selRuleName=>{
                        this.onMoveTopPolicies(
                            selRuleName,
                            this.props.recordConfigPath,
                        );
                    })
                    break;
                case 'bottom':
                    RuleNameArray.forEach(selRuleName=>{
                        this.onMoveBottomPolicies(
                            selRuleName,
                            this.props.recordConfigPath,
                        );
                })
                    break;
                case 'after':
                    this.onMovePolicies(
                        RuleNameArray,
                        dstRuleName,
                        'after'
                    );
                    break;
                case 'before':
                    this.onMovePolicies(
                        RuleNameArray,
                        dstRuleName,
                        'before'
                    );
                    break;
                default:
                    break;
            }
        // };
    }

    onMoveDrag(selectedValues, dstIndex, gridData , elements, api, moveTop){
        const moveDirection = moveTop?'before':'after';
        this.bulkMovePolicies(selectedValues, moveDirection, gridData[dstIndex]["@name"]);
    }

    onMovePolicies(selectedRule, dstRule, direction) {
        getStore().dispatch(
            movePolicies(selectedRule, dstRule, this.state.serviceMap, this.state.reduxStateId, this.props.configLocation, direction),
        );
    }
    onMoveTopPolicies(selectedRule) {
        getStore().dispatch(
            moveTopPolicies(selectedRule, this.state.serviceMap, this.state.reduxStateId, this.props.configLocation),
        );
    }
    onMoveBottomPolicies(selectedRule) {
        getStore().dispatch(
            moveBottomPolicies(selectedRule, this.state.serviceMap, this.state.reduxStateId, this.props.configLocation),
        );
    }

    getGridActions() {
        let gridActions = this.props.gridActions || [];
        gridActions = [...gridActions];
        const rbaPermission = getRBAPermission(this.rbaPath || '');
        let permissionEnabled = isPermissionEnabled(rbaPermission);
        var gridActionsAvail = get(this, "gridOverride.gridActionsAvail");

        if (this.showEnableAction === true && permissionEnabled) {
            gridActions.push({
                text: 'Enable',
                avail: get(gridActionsAvail, "isGridActionDisabled", this.isGridActionDisabled),
                callback: (selected) => {
                    this.onEnablePolicies(selected, this.recordConfigPath)
                }
            });
        }

        if (this.showDisableAction === true && permissionEnabled) {
            gridActions.push({
                text: 'Disable',
                avail: get(gridActionsAvail, "isGridActionDisabled", this.isGridActionDisabled),
                callback: (selected) => {
                    this.onDisablePolicies(selected, this.recordConfigPath)
                }
            });
        }

        if (this.isMoveable === true && permissionEnabled) {
            gridActions.push(
                {
                    text: 'Move Up',
                    avail: get(gridActionsAvail, "isGridMoveUpActionDisabled", this.isGridMoveUpActionDisabled),
                    callback: (selected, gridData) => {
                        let selObj = getSelectedAndIndices(selected, gridData);
                        let beforeIndex = selObj.min >= 1 ? selObj.min - 1 : 0;
                        this.bulkMovePolicies(selObj.selectedValues, 'before', gridData[beforeIndex]['@name']);
                    },
                },
                {
                    text: 'Move Down',
                    avail: get(gridActionsAvail, "isGridMoveDownActionDisabled", this.isGridMoveDownActionDisabled),
                    callback: (selected, gridData) => {
                        let selObj = getSelectedAndIndices(selected, gridData);
                        let afterIndex = selObj.max < gridData.length - 1 ? selObj.max + 1 : gridData.length - 1;
                        this.bulkMovePolicies(selObj.selectedValues, 'after', gridData[afterIndex]['@name']);
                    },
                },
                {
                    text: 'Move Top',
                    avail: get(gridActionsAvail, "isGridMoveUpActionDisabled", this.isGridMoveUpActionDisabled),
                    callback: (selected, gridData) => {
                        let selObj = getSelectedAndIndices(selected, gridData);
                        this.bulkMovePolicies(selObj.selectedValues.reverse(), 'top');
                    },
                },
                {
                    text: 'Move Bottom',
                    avail: get(gridActionsAvail, "isGridMoveDownActionDisabled", this.isGridMoveDownActionDisabled),
                    callback: (selected, gridData) => {
                        let selObj = getSelectedAndIndices(selected, gridData);
                        this.bulkMovePolicies(selObj.selectedValues, 'bottom');
                    },
                }
            );
        }
        return gridActions;
    }
}
