import {
    finishGridLoading,
    startGridLoading,
    fetchConfigItems
} from 'service-lib';
import {
    parseError,
    SERVER_ERROR
} from 'ui-lib';
import {
    disablePoliciesActionObservable,
    movePoliciesActionObservable,
    deleteApplicationTagActionObservable
} from './services';
import { ajax } from 'rxjs/ajax';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, concat } from 'rxjs';
export const ENABLE_POLICIES = 'ENABLE_POLICIES';
export const DISABLE_POLICIES = 'DISABLE_POLICIES';
export const MOVE_POLICIES = 'MOVE_POLICIES';
export const MOVE_TOP_POLICIES = 'MOVE_TOP_POLICIES';
export const MOVE_BOTTOM_POLICIES = 'MOVE_BOTTOM_POLICIES';
export const DELETE_APPLICATION_TAG = 'DELETE_APPLICATION_TAG';
export const FILTER_DG_ITEMS = 'FILTER_DG_ITEMS';


export const enablePolicies = (dgItems, serviceMap, reduxStateId, location) => ({
    type: ENABLE_POLICIES,
    dgItems,
    serviceMap,
    reduxStateId,
    location
});

export const disablePolicies = (dgItems, serviceMap, reduxStateId, location) => ({
    type: DISABLE_POLICIES,
    dgItems,
    serviceMap,
    reduxStateId,
    location
});

export const movePolicies = (selectedRule, afterRule, serviceMap, reduxStateId, location, direction) => ({
    type: MOVE_POLICIES,
    selectedRule,
    afterRule,
    serviceMap,
    reduxStateId,
    location,
    direction
});

export const moveTopPolicies = (selectedRule, serviceMap, reduxStateId, location) => ({
    type: MOVE_TOP_POLICIES,
    selectedRule,
    serviceMap,
    reduxStateId,
    location
});

export const moveBottomPolicies = (selectedRule, serviceMap, reduxStateId, location) => ({
    type: MOVE_BOTTOM_POLICIES,
    selectedRule,
    serviceMap,
    reduxStateId,
    location
});

export const deleteApplicationTag = (dgItems, serviceMap, reduxStateId, location) => ({
    type: DELETE_APPLICATION_TAG,
    dgItems,
    serviceMap: serviceMap,
    reduxStateId,
    location
});

export const enablePoliciesEpic = action$ =>
    action$.pipe(
        ofType(ENABLE_POLICIES),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId)),
                ajax(disablePoliciesActionObservable(
                    action.dgItems,
                    action.serviceMap.GET,
                    action.location,
                    false
                )).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        }, finishGridLoading(action.reduxStateId));
                    }),
                )
            )
        }
        ),
    );

export const disablePoliciesEpic = action$ =>
    action$.pipe(
        ofType(DISABLE_POLICIES),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId)),
                ajax(disablePoliciesActionObservable(
                    action.dgItems,
                    action.serviceMap.GET,
                    action.location,
                    true
                )).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        }, finishGridLoading(action.reduxStateId));
                    }),
                )
            )
        }
        ),
    );
    
export const movePoliciesEpic = action$ => action$.pipe(
        ofType(MOVE_POLICIES),
        mergeMap(action => {
            if(action.selectedRule.length>0){
                let selectedRuleCopy = action.selectedRule;
                return concat(
                    of(startGridLoading(action.reduxStateId)),
                    ajax(movePoliciesActionObservable(
                        action.selectedRule[0],
                        action.afterRule,
                        action.serviceMap.GET,
                        action.location,
                        action.direction)
                    ).pipe(
                        ...action.selectedRule.slice(1).map((selRuleName, i)=>{
                        return concatMap((response) =>  {  
                                if(response.status===200){
                                    return ajax(movePoliciesActionObservable(
                                    selRuleName,
                                    selectedRuleCopy[i],
                                    action.serviceMap.GET,
                                    action.location,
                                    'after'
                                    ))
                                }
                            })
                        })
                    ,
                        mergeMap((response) => of(
                            finishGridLoading(action.reduxStateId),
                            fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                        )),
                        catchError(error => {
                            return of({
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId,
                            }, 
                            finishGridLoading(action.reduxStateId),
                            fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                            );
                        }),
                    )
                )
            }
        }
        
        ),
    );

export const moveTopPoliciesEpic = action$ =>
    action$.pipe(
        ofType(MOVE_TOP_POLICIES),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId)),
                ajax(movePoliciesActionObservable(
                    action.selectedRule,
                    '',
                    action.serviceMap.GET,
                    action.location,
                    'top'
                )).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        }, finishGridLoading(action.reduxStateId));
                    }),
                )
            )
        }
        ),
    );

export const moveBottomPoliciesEpic = action$ =>
    action$.pipe(
        ofType(MOVE_BOTTOM_POLICIES),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId)),
                ajax(movePoliciesActionObservable(
                    action.selectedRule,
                    '',
                    action.serviceMap.GET,
                    action.location,
                    'bottom'
                )).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        }, finishGridLoading(action.reduxStateId));
                    }),
                )
            )
        }
        ),
    );

export const deleteApplicationTagEpic = action$ =>
    action$.pipe(
        ofType(DELETE_APPLICATION_TAG),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId)),
                ajax(deleteApplicationTagActionObservable(
                    action.dgItems,
                    'Objects/ApplicationTags',
                    action.location,
                )).pipe(
                    mergeMap((response) => of(
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        }, finishGridLoading(action.reduxStateId));
                    }),
                )
            )
        }),
    );
