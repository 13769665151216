import mainReducer from "./reducer";
// import { jobsReducer } from '../jobManager/reducer';
import { dgItemsReducer } from "../deviceGroup/reducer";
import { tplItemsReducer } from "../template/reducer";
import { dashboardReducer } from "../dashboard/reducer";
import { visibilityReducer } from "../explore/logViewer_v2/store/reducer";
import { enablePoliciesEpic, disablePoliciesEpic, movePoliciesEpic, moveTopPoliciesEpic, moveBottomPoliciesEpic, deleteApplicationTagEpic } from "../deviceGroup/actions";

import {
	fetchSchemaEpic,
	fetchLogsEpic,
	fetchLogsCountEpic,
	fetchRelatedLogsEpic,
	pollJobEpic,
	updatePageEpic,
	fetchUserContextEpic,
	initExportEpic,
	checkExportStatusEpic,
	cancelExportEpic,
	fetchExportHistoryEpic,
	fetchExportFileEpic
} from "../explore/logViewer_v2/store/epics";
import {
	isUserLoggedInEpic,
	getLoggedInUserEpic,
	fetchCommitEpic,
	doCommitEpic,
	doCommitAndPushEpic,
	doPushEpic,
	doRevertEpic,
	fetchLicenseInfoEpic,
	fetchTokenInfoEpic,
	fetchDirectoryDomainsEpic,
	fetchAplicationsInfoEpic
} from "./actions";
import {
	addConfigItemsEpic,
	editConfigItemsEpic,
	fetchConfigItemsEpic,
	renameConfigItemsEpic,
	deleteConfigItemsEpic,
	cloneConfigItemsEpic,
	getURLFilteringCategoriesEpic,
	getPoliciesAddressesEpic,
	getPoliciesApplicationEpic,
	getPoliciesServicesEpic
} from "service-lib";
// import {
// 	fetchJobsEpic,
// 	fetchJobDetailsEpic
// } from '../jobManager/actions';
import {
	fetchRemoteNetworksStatusEpic,
	fetchMobileUsersStatusEpic,
	fetchServiceConnectionStatusEpic,
	getDetailInfoEpic,
	getDetailInfoCombineEpic,
	clearDetailInfoEpic,
	getSecondLevelDetailInfoEpic,
	getSecondLevelTableDetailInfoEpic,
	fetchCurrentUsersDetailsInfoEpic
} from "../dashboard/actions";
import { generateTechSupportDataEpic, getRecentTechSupportRequestsEpic } from "../TechSupport/actions";
import { techSupportReducer } from "../TechSupport/reducer";

const adelphiStoreInfo = {
	reducers: {
		main: mainReducer,
		dg: dgItemsReducer,
		// jobs: jobsReducer,
		tpl: tplItemsReducer,
		dashboard: dashboardReducer,
		visibility: visibilityReducer,
		techSupport: techSupportReducer
	},
	epics: [
		enablePoliciesEpic,
		disablePoliciesEpic,
		movePoliciesEpic,
		moveTopPoliciesEpic,
		moveBottomPoliciesEpic,
		deleteApplicationTagEpic,
		isUserLoggedInEpic,
		getLoggedInUserEpic,
		fetchCommitEpic,
		doCommitEpic,
		doCommitAndPushEpic,
		doPushEpic,
		doRevertEpic,
		// fetchJobsEpic,
		// fetchJobDetailsEpic,
		addConfigItemsEpic,
		editConfigItemsEpic,
		fetchConfigItemsEpic,
		renameConfigItemsEpic,
		deleteConfigItemsEpic,
		cloneConfigItemsEpic,
		fetchRemoteNetworksStatusEpic,
		fetchMobileUsersStatusEpic,
		fetchServiceConnectionStatusEpic,
		getDetailInfoEpic,
		getDetailInfoCombineEpic,
		clearDetailInfoEpic,
		getSecondLevelDetailInfoEpic,
		getSecondLevelTableDetailInfoEpic,
		fetchCurrentUsersDetailsInfoEpic,
		fetchLicenseInfoEpic,
		fetchTokenInfoEpic,
		fetchAplicationsInfoEpic,
		fetchSchemaEpic,
		fetchLogsEpic,
		fetchLogsCountEpic,
		fetchRelatedLogsEpic,
		pollJobEpic,
		updatePageEpic,
		fetchUserContextEpic,
		initExportEpic,
		cancelExportEpic,
		checkExportStatusEpic,
		fetchExportHistoryEpic,
		fetchExportFileEpic,
		getURLFilteringCategoriesEpic,
		getPoliciesAddressesEpic,
		getPoliciesApplicationEpic,
		getPoliciesServicesEpic,
		generateTechSupportDataEpic,
		getRecentTechSupportRequestsEpic,
		fetchDirectoryDomainsEpic
	]
};

export { adelphiStoreInfo };
