import React, { Component } from 'react'
import { createPortal } from 'react-dom';

export default class CellComponentPortal extends Component {

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.builder = React.createRef();
    }

    componentWillMount() {
        if (!this.props.dontHandleMouseClik) {
            document.addEventListener('mousedown', this.handleClick);
        }
    }

    componentWillUnmount() {
        if (!this.props.dontHandleMouseClik) {
            document.removeEventListener('mousedown', this.handleClick);
        }
    }

    // onClick = () => {
    //     if (this.editor.current.contains(e.target)) {
    //         console.log('dont destroy');
    //     } else {
    //             setTimeout(() => {
    //                 this.props.api.stopEditing();
    //             }, 100 );
    //     }
    //     if (this.props.onClick) {
    //         this.props.onClick();
    //     } 
    // }

    handleClick = (e) => {
        if (this.editor.current.contains(e.target)) {
            return;
        }
        setTimeout(() => {
            this.props.api.stopEditing();
        }, 100 );
    }

    focus = () => {
        if (this.builder && this.builder.current.focus) {
            this.builder.current.focus();
        }
    }

    getCellComponent() {
        let Builder = this.props.Builder;
        const style = this.props.offset ? {
            top: this.props.offset.top,
            left: this.props.offset.left,
            width: this.props.offset.width
          } : {
              display: 'none'
          };
        return (
            <div 
                style={style}
                ref={this.editor}
                className={'cell-editor ' + (this.props.showEditor ? 'editor-cell-show' : 'editor-cell-hide')}>
                <Builder 
                    ref={this.builder}
                    {...this.props} />
            </div>
        )
    }

    render() {
        return createPortal(this.getCellComponent(), document.body);
    }


}
