import * as React from 'react';
import RcCalendar from 'rc-calendar';
import MonthCalendar from 'rc-calendar/lib/MonthCalendar';
import createPicker from './components/createPicker';
import wrapPicker from './components/wrapPicker';
import RangePicker from './components/RangePicker';
import WeekPicker from './components/WeekPicker';
import './DatePickerWidget.scss'

export const DatePickerWidget = wrapPicker(createPicker(RcCalendar), 'date')

const MonthPicker = wrapPicker(createPicker(MonthCalendar), 'month');

Object.assign(DatePickerWidget, {
    RangePicker: wrapPicker(RangePicker, 'date'),
    MonthPicker,
    WeekPicker: wrapPicker(WeekPicker, 'week'),
});
