import React, { Component } from 'react';
import getTPLRecordViewer from '../../template/TPLRecordViewer';

const _T = str => str;

const samlIdpImportFields = [
    {
        type: 'string',
        name: 'name',
        mapping: 'name',
        uiHint: {
            fieldLabel : _T( 'Name'),
            vtype: 'objectName'
        }
    },
    {
        name: '$.contentIdPMetadata',
        uiHint: {
            builder: 'FileReaderBuilder',
            fieldLabel: _T('SAML Metadata')
        }
    },
    {
        name: '$.profileName',
        uiHint: {
            fieldLabel: _T('Profile Name'),
            association: {
                fields: ['$.profileName'],
                updateFormData: function (values, formData, filters, key) {
                    if (values && values[0]) {
                        return { '$.name': values[0] };
                    }
                },
            }
        }
    },
    {
        name: '$.validateIdPCertificate',
        defaultValue: 'no',
        uiHint: {
            allowBlank: false, //ADI-1501 - Hide and set to no
            hidden: true,
            fieldLabel: _T('Validate Identity Provider Certificate')
        }
    }, {
        name: '$.maxClockSkew',
        uiHint: {
            fieldLabel: _T('Max Clock Skew (seconds)')
        }
    }, {
        name: '$',
        childrenNames: [
            '$.profileName',
            '$.contentIdPMetadata',
            '$.validateIdPCertificate',
            '$.maxClockSkew'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            labelWidthSize: 5
        }
    }
];

window.__pan_schema.config.injected.samlimport = {
    "@attr": {
        "node-type": "array",
    },
    "entry": {
        "@attr": {
            "node-type": "sequence"
        },
        "name": {
            "@attr": {
                "node-type": "attr-req",
                "type": "string",
                "minlen": "6",
                "maxlen": "64"
            }
        },
        "validateIdPCertificate": {
            "@attr": {
                "node-type": "element",
                "type": "bool",
                "optional": "yes"
            }
        },
        "validateMetadataSignature": {
            "@attr": {
                "node-type": "element",
                "type": "bool",
                "optional": "yes"
            }
        },
        "contentIdPMetadata": {
            "@attr": {
                "node-type": "element",
                "type": "string"
            }
        },
        "profileName": {
            "@attr": {
                "node-type": "element",
                "type": "string"
            }
        },
        "maxClockSkew": {
            "@attr": {
                "node-type": "element",
                "type": "string",
                "optional": "yes",
                "default": 60
            }
        },
        "adminUseOnly": {
            "@attr": {
                "node-type": "element",
                "type": "bool",
                "optional": "yes"
            }
        }
    }
};

export default class ImportSAMLIDP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SAMLIDPImportViewer: null
        };
    }
    
    componentDidMount() {
        this.setState({
            SAMLIDPImportViewer: getTPLRecordViewer(
                '',
                'Device/SAMLIdP/Import',
                '',
                'ImportSAMLIDP',
            )
        });
    }

    render() {
        let SAMLIDPImportViewer = this.state.SAMLIDPImportViewer;
        return (
            SAMLIDPImportViewer && 
            <SAMLIDPImportViewer 
                {...this.props}
                fetchRecord = {false}
                rbaPath = "device.server-profile.saml_idp"
                panSchema = {window.__pan_schema}
                recordConfigPath = '$.config.injected.samlimport.entry'
                inputFields = {samlIdpImportFields}
                header = 'Import SAML IDP Metadata'
                fetchAfterSuccess = {false}
            />
        );
    }
}