import React from "react";
import PropTypes from "prop-types";
import { Input, Col } from "reactstrap";
import './TextareaWidget.scss';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 * Component is described here.
 * @visibleName TextArea
 */
export function TextareaWidget(props) {
    const {
        id,
        options,
        placeholder,
        value,
        required,
        disabled,
        readonly,
        autoFocus,
        onChange,
        onBlur,
        onFocus,
        className,
        showErrors,
        maxLength,
        error
    } = props;

    const _onChange = ({ target: { value } }) => {
        return onChange(value === "" ? options.emptyValue : value);
    };
    return (
        <React.Fragment>
            <Col>
                <Input
                    id={id}
                    type="textarea"
                    value={typeof value === "undefined" ? "" : value}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    maxLength={maxLength}
                    readOnly={readonly}
                    autoFocus={autoFocus}
                    rows={options.rows}
                    className={className}
                    onBlur={onBlur && (event => onBlur(id, event.target.value))}
                    onFocus={onFocus && (event => onFocus(id, event.target.value))}
                    onChange={_onChange}
                    invalid={showErrors && !!error}
                />
            </Col>
        </React.Fragment>
    );
}

TextareaWidget.defaultProps = {
    autoFocus: false,
    options: {},
};

if (process.env.NODE_ENV !== 'production') {
    TextareaWidget.propTypes = {
        /**
         * HTML ID for associated input
         */
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
            emptyValue: PropTypes.string,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        error: PropTypes.string,
        className: PropTypes.string,
        showErrors: PropTypes.bool,
    };
}
