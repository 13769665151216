import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const regionsObjectFields = [
	{
		type: 'string',
		name: 'name',
		mapping: '@name',
		uiHint: {
			fieldLabel: _T('Name'),
			autoFocus: true
		}
	},
	{
		name: '$.geo-location',
		childrenNames: ['$.geo-location.latitude', '$.geo-location.longitude'],
		uiHint: {
			fieldLabel: _T('Geo Location'),
			builder: 'FieldSetBuilder'
		}
	},
	{
		name: '$',
		childrenNames: [
			'name',
			'$.geo-location',
			'$.address'
		]
	}
];

const regionsObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
	minWidth: 50
}, {
	headerName: 'Location',
	field: '@loc',
	minWidth: 200
}, {
	headerName: _T('IP'),
	valueGetter: params => {
		return params.data['address'] && params.data['address']['member'] ? params.data['address']['member'] : '';
	},
	minWidth: 100
}, {
	headerName: _T('Latitude'),
	field: 'geo-location.latitude',
	minWidth: 100
}, {
	headerName: _T('Longitude'),
	field: 'geo-location.longitude',
	minWidth: 100
}];

export default class Regions extends DGBaseViewer {

	serviceName = 'Objects/Regions';
	reduxId = 'regionsObjects';
	header = 'Regions';
	columns = regionsObjectColumns;
	fields = regionsObjectFields;
	recordConfigPath = '$.config.devices.entry.device-group.entry.region.entry';
	rbaPath = 'objects.regions';
}
