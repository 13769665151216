import React, { Component } from "react";
import { GridWidget } from "../GridWidget/GridWidget";
import { ajax } from "rxjs/ajax";
import { getCustomServiceURL, MOCK_SERVICES } from "../../../services/CommonServices";
import { getAuthHeaders } from "../../../../auth/lib";

export class ExploreGridWidget extends Component {
	state = {
		movingColumn: null,
	};

	setPreferences = (presentation) => {
		let { SERVICE_URL } = getCustomServiceURL("api/visibility/v1/preferences");
		ajax({
			withCredentials: MOCK_SERVICES ? false : true,
			method: "POST",
			responseType: "json",
			url: SERVICE_URL,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				...getAuthHeaders(),
			},
			body: {
				presentation,
			},
		}).subscribe((res) => {
			if (!res.response.ok) {
				alert("Error Saving Preference. Try Again.");
			}
		});
	};

	onColumnResized = (e) => {
		if (_.get(e, "column.colDef.field") === "details") return;
		const { column, finished } = e;
		if (column && finished) {
			const presentation = {};
			_.set(presentation, `${this.props.agGridProps.logType}.fields[0].name`, column.colDef.field);
			_.set(presentation, `${this.props.agGridProps.logType}.fields[0].width`, column.actualWidth);

			this.setPreferences(presentation);
		}
	};

	onColumnPinned = (e) => {
		const { column, pinned } = e;

		const presentation = {};
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].name`, column.colDef.field);
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].pinned`, pinned);

		this.setPreferences(presentation);
	};

	onDragStarted = (e) => {
		const columns = e.api.columnController.gridColumns;
		const movingColumn = columns.find((col) => col.moving);
		if (movingColumn) {
			this.setState({ movingColumn });
		}
	};

	onDragStopped = (e) => {
		if (this.state.movingColumn) {
			const columns = e.api.columnController.gridColumns;
			const movColStopIndex = columns.findIndex((col) => col.colDef.field === this.state.movingColumn.colDef.field);

			const presentation = {};
			_.set(presentation, `${this.props.agGridProps.logType}.field.name`, this.state.movingColumn.colDef.field);
			// If column is moved to the beginning of all, use moveBefore, otherwise use moveAfter
			if (movColStopIndex === 1) {
				_.set(presentation, `${this.props.agGridProps.logType}.moveBefore.name`, columns[movColStopIndex + 1].colDef.field);
			} else {
				_.set(presentation, `${this.props.agGridProps.logType}.moveAfter.name`, columns[movColStopIndex - 1].colDef.field);
			}
			this.setPreferences(presentation);

			this.setState({ movingColumn: null });
		}
	};

	onColumnVisible = (e) => {
		const { visible, column } = e;

		const presentation = {};
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].name`, column.colDef.field);
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].hidden`, !visible);

		this.setPreferences(presentation);
	};

	render() {
		return (
			<GridWidget
				{...this.props}
				agGridProps={{
					onColumnResized: this.onColumnResized,
					onColumnPinned: this.onColumnPinned,
					onDragStarted: this.onDragStarted,
					onDragStopped: this.onDragStopped,
					onColumnVisible: this.onColumnVisible,
					...this.props.agGridProps,
				}}
			/>
		);
	}
}
