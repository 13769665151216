import TPLBaseViewer from '../../template/TPLBaseViewer';


const localCertificateFields = [];

const localCertificateColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.certificate.entry'
}];

export default class LocalCertificate extends TPLBaseViewer {
    
    serviceName = 'Device/Certificates';
    reduxId = 'localCertificate';
    header = 'Local Certificate';
    widerForm = true;
    columns = localCertificateColumns;
    fields = localCertificateFields;
    showDefaultAddAction=false;
    recordConfigPath = '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.certificate.entry';
    rbaPath='device.certificate-management.certificates';
}