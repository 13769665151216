import React, { Component } from "react";
import PropTypes from "prop-types";
import Pan from "../../schema/Pan";
import { FormContext, FormConsumer } from "../../FormContext";
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";
import { FormGroup, Col, FormText } from "reactstrap";
import { getFieldLabelComp, getFieldWidthClass, getFieldHelpString, emptyTextFormat } from "../../schema/utils";
import classnames from "classnames";
import './FileReaderBuilder.scss';
import _ from 'lodash';
import { BaseFieldBuilder } from "../BaseBuilder";
import { FileTextReaderWidget } from "../../widgets/FileTextReaderWidget/FileTextReaderWidget";

export class FileReaderBuilder extends BaseFieldBuilder {
    static NAME = 'FileReaderBuilder';
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.state = {
            value: false
        }
        this.setRecordValue = super.setRecordValue.bind(this);
    }

    renderFileReader() {
        let {
            name,
            className,
            field,
            hideLabel,
            containerLabelWidthSize,
            onBlur,
            onFocus,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            autoFocus
        } = this.props;

        const value = this.getRecordValue();
        let errors = this.getErrors();
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let helpString = getFieldHelpString(field, this.context.formData);
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let fieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        // let min = field.uiHint.minValue;
        // let max = field.uiHint.maxValue;
        let placeHolder = emptyTextFormat(field);
        return (
            <React.Fragment>
                {!this.state.hideField &&
                    <FormGroup className={classnames(className, `d-flex flex-row ${required}`)} test_id={field.attrPath}>
                        {!hideLabel && LabelComp}
                        <Col >
                            <div className={classnames(fieldClassName, 'p-0')}>
                                <FileTextReaderWidget
                                    id={this.id}
                                    name={name}
                                    value={value}
                                    onChange={this.setRecordValue}
                                    placeholder={placeHolder}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    disabled={disabled || this.state.disableField}
                                    error={error}
                                    autoFocus={autoFocus}
                                    showErrors={showErrors} />
                            </div>
                            {helpString && <FormText>{helpString}</FormText>}
                            {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                        </Col>
                    </FormGroup>
                }
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderFileReader()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

FileReaderBuilder.contextType = FormContext;

FileReaderBuilder.defaultProps = {
    disabled: false,
};

if (process.env.NODE_ENV !== "production") {
    FileReaderBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        hideLabel: PropTypes.bool,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onChange: PropTypes.func,
        containerLabelWidthSize: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        isEditorGridColumnEditor: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        autoFocus: PropTypes.func,
        cellInfo: PropTypes.object,
        /**
         * *defaultValue* - sets the default value of the field.
         * UiHint properties :
         * *allowBlank* - enable/disable errors if field is blank
         * *fieldWidthSize* - The size of the field - ranges from 1 to 12.
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *showHelpString* - if true,will show custom help string/message
         * *helpstring* - set the help string/mesage         
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
		 */
        field: PropTypes.shape({
            defaultValue: PropTypes.string,
            uiHint: PropTypes.shape({
                allowBlank: PropTypes.bool,
                labelWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                fieldWidthSize: PropTypes.string,
                showHelpString: PropTypes.bool,
                helpString: PropTypes.string,
                vtype: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            })
        })
    };
}