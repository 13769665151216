import {
    getRecordsActionObservable,
    getRecordActionObservable,
    addRecordActionObservable,
    editRecordActionObservable,
    renameRecordActionObservable,
    deleteRecordsActionObservable,
    cloneRecordsActionObservable,
} from './services';
import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";
import { of, concat, from } from "rxjs";
import { ajax } from "rxjs/ajax";
import { parseError, SERVER_ERROR, SHOW_MODAL, UPDATE_MODAL, getGenericAutoComplete } from "ui-lib";

export const START_GRID_LOADING = "START_GRID_LOADING";
export const FINISH_GRID_LOADING = "FINISH_GRID_LOADING";
export const START_FORM_LOADING = "START_FORM_LOADING";
export const FINISH_FORM_LOADING = "FINISH_FORM_LOADING";
export const LOAD_CONFIG_ITEMS = 'LOAD_CONFIG_ITEMS';
export const FETCH_CONFIG_ITEMS = 'FETCH_CONFIG_ITEMS';
export const FETCH_CONFIG_ITEM = 'FETCH_CONFIG_ITEM';
export const ADD_CONFIG_ITEM = 'ADD_CONFIG_ITEM';
export const EDIT_CONFIG_ITEM = 'EDIT_CONFIG_ITEM';
export const RENAME_CONFIG_ITEM = 'RENAME_CONFIG_ITEM';
export const DELETE_CONFIG_ITEM = 'DELETE_CONFIG_ITEM';
export const CLONE_CONFIG_ITEM = 'CLONE_CONFIG_ITEM';
export const SHOW_FORM = "SHOW_FORM";
export const HIDE_FORM = "HIDE_FORM";
export const GRID_LOADING = "GRID_LOADING";
export const FORM_LOADING = "FORM_LOADING";
export const CHANGE_CONFIG_LOCATION = "CHANGE_CONFIG_LOCATION";
export const START_WORKFLOW_LOADING = 'START_WORKFLOW_LOADING';
export const FINISH_WORKFLOW_LOADING = 'FINISH_WORKFLOW_LOADING';
export const SHOW_WORKFLOW = 'SHOW_WORKFLOW';
export const HIDE_WORKFLOW = 'HIDE_WORKFLOW';
export const WORKFLOW_LOADING = 'WORKFLOW_LOADING';
export const LOAD_EXTRA_INFO = 'LOAD_EXTRA_INFO';
export const GET_URL_FILTERING_CATEGORIES = 'GET_URL_FILTERING_CATEGORIES';
export const GET_POLICIES_ADDRESSES = 'GET_POLICIES_ADDRESSES';
export const GET_POLICIES_APPLICATIONS = 'GET_POLICIES_APPLICATIONS';
export const GET_POLICIES_SERVICES = 'GET_POLICIES_SERVICES';
export const PERSIST_FILTER_TEXT = 'PERSIST_FILTER_TEXT';
export const SAVE_CONFIG_SUCCESS = 'SAVE_CONFIG_SUCCESS';

const emptyAction = { type: "" };

export const startGridLoading = (reduxStateId, locationPath) => ({
    type: START_GRID_LOADING,
    reduxStateId,
    locationPath
});

export const finishGridLoading = (reduxStateId, locationPath) => ({
    type: FINISH_GRID_LOADING,
    reduxStateId,
    locationPath
});

export const showForm = (reduxStateId, locationPath) => ({
    type: SHOW_FORM,
    reduxStateId,
    locationPath
});

export const hideForm = (reduxStateId, locationPath) => ({
    type: HIDE_FORM,
    reduxStateId,
    locationPath
});

export const startFormLoading = (reduxStateId, locationPath) => ({
    type: START_FORM_LOADING,
    reduxStateId,
    locationPath
});

export const finishFormLoading = (reduxStateId, locationPath) => ({
    type: FINISH_FORM_LOADING,
    reduxStateId,
    locationPath
});

export const saveConfigSuccess = (reduxStateId, success) => ({
    type: SAVE_CONFIG_SUCCESS,
    reduxStateId,
    success
});

export const changeConfigLocation = locationValue => ({
    type: CHANGE_CONFIG_LOCATION,
    locationValue
});

export const loadInReduxConfigItems = (configItems, reduxStateId, successCallback) => ({
    type: LOAD_CONFIG_ITEMS,
    configItems,
    reduxStateId,
    successCallback
});

export const fetchConfigItems = (serviceMap, reduxStateId, location, successCallback) => ({
    type: FETCH_CONFIG_ITEMS,
    serviceName: serviceMap.GET,
    reduxStateId,
    location,
    successCallback
});

export const fetchConfigItem = (serviceMap, recordId, reduxStateId, location) => ({
    type: FETCH_CONFIG_ITEM,
    serviceName: serviceMap.GET,
    recordId,
    reduxStateId,
    location
});

export const addConfigItem = (configItem, serviceMap, reduxStateId, location, fetchConfigItems = true) => ({
    type: ADD_CONFIG_ITEM,
    configItem,
    serviceMap,
    reduxStateId,
    location,
    fetchConfigItems
});

export const editConfigItem = (
    newItem,
    oldItem,
    serviceMap,
    reduxStateId,
    location
) => ({
    type: EDIT_CONFIG_ITEM,
    configItem: { new: newItem, old: oldItem },
    serviceMap,
    reduxStateId,
    location
});

export const renameConfigItem = (configItem, serviceMap, reduxStateId, location) => ({
    type: RENAME_CONFIG_ITEM,
    configItem,
    serviceMap,
    reduxStateId,
    location
});

export const deleteConfigItem = (configItems, serviceMap, reduxStateId, location) => ({
    type: DELETE_CONFIG_ITEM,
    configItems,
    serviceMap,
    reduxStateId,
    location
});

export const cloneConfigItem = (configItems, serviceMap, reduxStateId, location) => ({
    type: CLONE_CONFIG_ITEM,
    configItems,
    serviceMap,
    reduxStateId,
    location
});

export const showWorkflow = reduxStateId => ({
    type: SHOW_WORKFLOW,
    reduxStateId,
});

export const hideWorkflow = reduxStateId => ({
    type: HIDE_WORKFLOW,
    reduxStateId,
});

export const startWorkflowLoading = reduxStateId => ({
    type: START_WORKFLOW_LOADING,
    reduxStateId,
});

export const finishWorkflowLoading = reduxStateId => ({
    type: FINISH_WORKFLOW_LOADING,
    reduxStateId,
});

export const loadExtraInfo = (reduxStateId, extraInfo) => ({
    type: "LOAD_EXTRA_INFO",
    reduxStateId,
    extraInfo
});

export const getURLFilteringCategories = (reduxStateId, configLocation) => ({
    type: GET_URL_FILTERING_CATEGORIES,
    reduxStateId,
    configLocation
});

export const getPoliciesAddresses = (reduxStateId, configLocation) => ({
    type: GET_POLICIES_ADDRESSES,
    reduxStateId,
    configLocation
});

export const getPoliciesApplications = (reduxStateId, configLocation) => ({
    type: GET_POLICIES_APPLICATIONS,
    reduxStateId,
    configLocation,
});

export const getPoliciesServices = (reduxStateId, configLocation) => ({
    type: GET_POLICIES_SERVICES,
    reduxStateId,
    configLocation
});

export const postProcess = (response, payload) => {
    if (!payload || !payload.type)
        return emptyAction;
    let { type, postAction } = payload;
    let result = postAction && postAction(response);
    if (type === SHOW_MODAL) {
        let { modal } = result;
        if (modal) {
            return {
                type: SHOW_MODAL,
                modal
            };
        }
    }
    if (type === UPDATE_MODAL) {
        let { id, props } = result;
        if (id && props) {
            return {
                type: UPDATE_MODAL,
                id,
                props
            };
        }
    }
    return emptyAction;
}

export const persistFilterTextInRedux = (reduxStateId, filterText) => ({
    type: PERSIST_FILTER_TEXT,
    reduxStateId,
    filterText
});

export const fetchConfigItemsEpic = (action$, store) => {
    return action$.pipe(
        ofType(FETCH_CONFIG_ITEMS),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath)),
                ajax(getRecordsActionObservable(action.serviceName, action.location)).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                        loadInReduxConfigItems(response, action.reduxStateId, action.successCallback)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        }, finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath));
                    }),
                )
            );
        })
    );
};

export const fetchConfigItemEpic = (action$, store) => {
    return action$.pipe(
        ofType(FETCH_CONFIG_ITEM),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath)),
                ajax(getRecordActionObservable(action.serviceName, action.recordId, action.location)).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                        loadInReduxConfigItems(response, action.reduxStateId)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        }, finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath));
                    }),
                )
            )
        })
    );
};

export const addConfigItemsEpic = action$ => {
    return action$.pipe(
        ofType(ADD_CONFIG_ITEM),
        mergeMap(action => {
            return concat(
                of(startFormLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath)),
                ajax(addRecordActionObservable(
                    action.configItem,
                    action.serviceMap.POST,
                    action.location
                )).pipe(
                    mergeMap(response =>
                        of(
                            finishFormLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                            hideForm(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                            action.fetchConfigItems === true ?
                                fetchConfigItems(action.serviceMap, action.reduxStateId, action.location, action.successCallback) : {type:""},
                            saveConfigSuccess(action.reduxStateId.reduxId, true)
                        )
                    ),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        }, finishFormLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                            saveConfigSuccess(action.reduxStateId.reduxId, false)
                        );
                    }),
                ),
            )
        })
    );
};

export const editConfigItemsEpic = action$ => {
    return action$.pipe(
        ofType(EDIT_CONFIG_ITEM),
        mergeMap(action => {
            if (action.configItem.old['@name'] !== action.configItem.new['@name']) {
                return concat(
                    of(startFormLoading(action.reduxStateId.reduxId, action.location)),
                    ajax(editRecordActionObservable(
                        action.configItem,
                        action.serviceMap.PUT,
                        action.location
                    )).pipe(
                        map(response =>
                            renameConfigItem(
                                action.configItem,
                                action.serviceMap,
                                action.reduxStateId,
                                action.location
                            ),
                        ),
                        catchError(error => {
                            return of({
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId.reduxId,
                            }, finishFormLoading(action.reduxStateId.reduxId));
                        }),
                    ),
                )
            } else {
                return concat(
                    of(startFormLoading(action.reduxStateId.reduxId, action.location)),
                    ajax(editRecordActionObservable(
                        action.configItem,
                        action.serviceMap.PUT,
                        action.location
                    )).pipe(
                        mergeMap(response =>
                            of(
                                finishFormLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                                hideForm(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                                fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                            )
                        ),
                        catchError(error => {
                            return of({
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId.reduxId,
                            }, finishFormLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath));
                        }),
                    ),
                )
            }
        })
    );
};

export const renameConfigItemsEpic = action$ => {
    return action$.pipe(
        ofType(RENAME_CONFIG_ITEM),
        mergeMap(action =>
            ajax(renameRecordActionObservable(
                action.configItem,
                action.serviceMap.PUT,
                action.location
            )).pipe(
                mergeMap(response =>
                    of(
                        finishFormLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                        hideForm(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )
                ),
                catchError(error => {
                    return of({
                        type: SERVER_ERROR,
                        errorMessage: parseError(error),
                        reduxStateId: action.reduxStateId.reduxId,
                    }, finishFormLoading(action.reduxStateId.reduxId, action.location));
                })
            )
        )
    );
};

export const deleteConfigItemsEpic = action$ => {
    return action$.pipe(
        ofType(DELETE_CONFIG_ITEM),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath)),
                ajax(deleteRecordsActionObservable(
                    action.configItems,
                    action.serviceMap.DELETE,
                    action.location
                )).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        }, finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath));
                    }),
                )
            )
        })
    );
};

export const cloneConfigItemsEpic = action$ => {
    return action$.pipe(
        ofType(CLONE_CONFIG_ITEM),
        mergeMap(action => {
            return concat(
                of(startGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath)),
                ajax(cloneRecordsActionObservable(
                    action.configItems,
                    action.serviceMap.CLONE,
                    action.location
                )).pipe(
                    mergeMap((response) => of(
                        finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath),
                        fetchConfigItems(action.serviceMap, action.reduxStateId, action.location)
                    )),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        }, finishGridLoading(action.reduxStateId.reduxId, action.reduxStateId.locationPath));
                    }),
                )
            )
        })
    );
};

export const getURLFilteringCategoriesEpic = (action$) => {
    return action$.pipe(
        ofType(GET_URL_FILTERING_CATEGORIES),
        mergeMap(action => {
            return concat(
                from(
                    getGenericAutoComplete(
                        '',
                        '$.config.devices.entry.device-group.entry.profiles.url-filtering.entry.alert.member',
                        action.configLocation,
                    ),
                ).pipe(
                    mergeMap(response =>
                        of(
                            loadExtraInfo(action.reduxStateId, {
                                URLFilteringCategories: response,
                            }),
                        ),
                    ),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        });
                    }),
                ),
            );
        }),
    );
};

export const getPoliciesAddressesEpic = (action$) => {
    return action$.pipe(
        ofType(GET_POLICIES_ADDRESSES),
        mergeMap(action => {
            return concat(
                from(
                    getGenericAutoComplete(
                        '',
                        "$.config.devices.entry.device-group.entry.post-rulebase.security.rules.entry[@name='__noname__'].source.member",
                        action.configLocation,
                    ),
                ).pipe(
                    mergeMap(response =>
                        {
                            response = response.filter (item => item['type'] !== 'address');
                            return of(
                                loadExtraInfo(action.reduxStateId, {
                                    policiesAddresses: response,
                                }),
                            );
                        }
                    ),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        });
                    }),
                ),
            );
        }),
    );
};

export const getPoliciesApplicationEpic = (action$) => {
    return action$.pipe(
        ofType(GET_POLICIES_APPLICATIONS),
        mergeMap(action => {
            return concat(
                from(
                    getGenericAutoComplete(
                        '',
                        "$.config.devices.entry.device-group.entry.post-rulebase.security.rules.entry[@name='__noname__'].application.member",
                        action.configLocation,
                    ),
                ).pipe(
                    mergeMap(response =>
                        {
                            return of(
                                loadExtraInfo(action.reduxStateId, {
                                    policiesApplications: response,
                                }),
                            );
                        }
                    ),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        });
                    }),
                ),
            );
        }),
    );
};

export const getPoliciesServicesEpic = (action$) => {
    return action$.pipe(
        ofType(GET_POLICIES_SERVICES),
        mergeMap(action => {
            return concat(
                from(
                    getGenericAutoComplete(
                        '',
                        "$.config.devices.entry.device-group.entry.post-rulebase.security.rules.entry[@name='__noname__'].service.member",
                        action.configLocation,
                    ),
                ).pipe(
                    mergeMap(response =>
                        {
                            response = response.filter (item => item['type'] !== 'service');
                            return of(
                                loadExtraInfo(action.reduxStateId, {
                                    policiesServices: response,
                                }),
                            );
                        }
                    ),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId.reduxId,
                        });
                    }),
                ),
            );
        }),
    );
};