import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap, takeUntil, concatMap } from 'rxjs/operators'
import { forkJoin, interval, of } from 'rxjs'
import { getLogs, getLogsComposite, getLogsCount, getSchema, pollJob, getUserContext, initExport, cancelExport, getExportStatus, getExportHistory, downloadExport } from './services'
import _ from 'lodash'
import moment from 'moment'
import Constants from '../../utils/Constants'
import * as actionTypes from './actionTypes'
import * as actions from './actions'

export const fetchUserContextEpic = (action$) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_USRCTX_REQUEST),
		mergeMap((action) => {
			return getUserContext(action).pipe(
				mergeMap(({ response }) => {
					var keys = Object.keys(response)
					if (keys.length === 1) {
						response = response[keys]
					}
					return of(actions.fetchUserContextSuccess(response))
				}),
				catchError(() => {
					return of(actions.fetchUserContextFailure())
				})
			)
		})
	)
}

export const fetchSchemaEpic = (action$) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_SCHEMA_REQUEST),
		mergeMap(() =>
			getSchema().pipe(
				mergeMap(({ response }) => {
					const schema = _.omit(response, ['version', 'super'])
					Object.keys(schema).forEach((vendor) => {
						if (Constants.VISIBLE_LOG_TYPES.hasOwnProperty(vendor)) {
							Object.keys(schema[vendor].content).forEach((type) => {
								if (!Constants.VISIBLE_LOG_TYPES[vendor].includes(type)) {
									delete schema[vendor].content[type]
								}
							})
						} else {
							delete schema[vendor]
						}
					})
					return of(actions.fetchSchemaSuccess(_.omit(schema, 'version')))
				}),
				catchError((error) => {
					return of(actions.fetchSchemaFailure({ error }))
				})
			)
		)
	)
}

export const updatePageEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_PAGE_REQUEST),
		mergeMap((action) => {
			// Check pageNumber and pageLimit
			let withinLimit = (action.params.pageNumber + 1) * Constants.DEFAULT_PAGE_SIZE <= store.value.visibility.pageLimit
			if (withinLimit && !action.params.sortOrderChanged) {
				// 1. within page limit, return POLL_JOB_REQUEST
				return of(
					actions.updatePageNumber(action.params.pageNumber),
					actions.pollJobRequest({
						...action.params,
						jobId: store.value.visibility.logsRequestId,
						pageLimit: store.value.visibility.pageLimit,
						jobType: 'update_page'
					})
				)
			} else {
				// 2. exceed page limit, return FETCH_LOGS_REQUEST
				// Do not update pageLimit here. Set pageLimit only after receive logs. Otherwise rapid updatePageRequests will fail. It will poll job with a smaller pageLimit
				const pageLimit = Math.ceil(((action.params.pageNumber + 1) * Constants.DEFAULT_PAGE_SIZE) / Constants.DEFAULT_PAGE_LIMIT) * Constants.DEFAULT_PAGE_LIMIT
				return of(
					actions.updatePageNumber(action.params.pageNumber),
					actions.fetchLogsRequest({
						...action.params,
						pageLimit,
						jobType: 'update_page'
					})
				)
			}
		})
	)
}

export const pollJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_JOB_REQUEST),
		mergeMap((action) =>
			pollJob(store.value.visibility, action.params.jobId).pipe(
				mergeMap(({ response }) => {
					if (
						// If jobId expired, drop it.
						(response.jobId !== store.value.visibility.logsRequestId &&
							response.jobId !== store.value.visibility.logsCountRequestId &&
							response.jobId !== store.value.visibility.relatedLogsRequestId) ||
						// If pageNumber expired, drop it.
						action.params.pageNumber !== store.value.visibility.pageNumber
					) {
						console.log('Cancelled', action.params.pageNumber)
						return of(actions.pollJobFailure({ jobId: response.jobId, error: 'Cancelled' }))
					} else if (response.state === 'RUNNING') {
						return of(actions.pollJobRequest({ ...action.params, jobId: response.jobId }))
					} else {
						console.log('pageLimit: ', action.params.pageLimit, store.value.visibility.pageLimit, action.params.jobType)
						if (action.params.jobType === 'fetch_logs_count') {
							// 1. jobType is fetch_logs_count, set logsCount state
							return of(actions.fetchLogsCountSuccess(response.totalCount))
						} else if (action.params.jobType === 'update_page' && action.params.pageLimit !== store.value.visibility.pageLimit) {
							// 2. jobType is update_page and pageLimit changed, set pageLimit and logs
							console.log('Logs set inside update page', action.params.pageNumber, action.params.jobType)
							return of(actions.updatePageLimit(action.params.pageLimit), actions.fetchLogsSuccess(response.page.result.data, response.jobId))
						} else {
							// 3. jobType is fetch_logs, set logs
							console.log('Logs set inside poll', action.params.pageNumber, action.params.jobType)
							return of(actions.fetchLogsSuccess(response.page.result.data, response.jobId))
						}
					}
				}),
				catchError((error) => {
					if (action.params.jobType === 'fetch_logs_count') {
						// 1. jobType is fetch_logs_count, set logsCount state
						return of(actions.fetchLogsCountFailure(error))
					} else {
						// 2. jobType is fetch_logs, set logs
						return of(actions.fetchLogsFailure(error))
					}
				})
			)
		)
	)
}

export const fetchLogsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_LOGS_REQUEST),
		// If handling of prev req is in process inside, drop it
		switchMap((action) => {
			//jobType not set means it is not from update_page
			if (!action.params.jobType) {
				action.params.jobType = 'fetch_logs'
				action.params.pageNumber = 0
				action.params.pageLimit = Constants.DEFAULT_PAGE_LIMIT
			}
			return getLogs(store.value.visibility, action.params.pageLimit).pipe(
				mergeMap(({ response }) => {
					// Check response state
					if (response.state === 'RUNNING') {
						if (action.params.jobType === 'update_page') {
							return of(actions.setLogsRequestId(response.jobId), actions.pollJobRequest({ ...action.params, jobId: response.jobId }))
						} else {
							return of(
								actions.updatePageLimit(Constants.DEFAULT_PAGE_LIMIT),
								actions.setLogsRequestId(response.jobId),
								actions.pollJobRequest({
									...action.params,
									jobId: response.jobId
								})
							)
						}
					} else {
						if (action.params.jobType === 'update_page') {
							// 1. jobType is update_page, poll latest job with jobId
							return of(actions.setLogsRequestId(response.jobId), actions.pollJobRequest({ ...action.params, jobId: response.jobId }))
						} else {
							// 2. jobType is fetch_logs, set logs state
							console.log('Logs set inside fetch logs', action.params.pageNumber, action.params.jobType)
							return of(actions.updatePageLimit(Constants.DEFAULT_PAGE_LIMIT), actions.fetchLogsSuccess(response.page.result.data, response.jobId))
						}
					}
				}),
				catchError((error) => of(actions.fetchLogsFailure(error)))
			)
		})
	)
}

export const fetchLogsCountEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_LOGS_COUNT_REQUEST),
		// If handling of prev req is in process inside, drop it
		switchMap((action) => {
			if (!action.params.jobType) {
				action.params.jobType = 'fetch_logs_count'
				action.params.pageNumber = 0
				action.params.pageLimit = Constants.DEFAULT_PAGE_LIMIT
			}
			return getLogsCount(store.value.visibility).pipe(
				mergeMap(({ response }) => {
					if (response.state === 'RUNNING') {
						return of(
							actions.setLogsCountRequestId(response.jobId),
							actions.pollJobRequest({
								...action.params,
								jobId: response.jobId,
								pageNumber: 0
							})
						)
					} else {
						return of(actions.fetchLogsCountSuccess(response.totalCount, response.jobId))
					}
				}),
				catchError((error) => of(actions.fetchLogsCountFailure(error)))
			)
		})
	)
}

export const fetchRelatedLogsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_RELATED_LOGS_REQUEST),
		// If handling of prev req is in process inside, drop it
		switchMap((action) => {
			if (!action.params.jobType) {
				action.params.jobType = 'fetch_related_logs'
				action.params.pageNumber = 0
				action.params.pageLimit = Constants.DEFAULT_PAGE_LIMIT
			}

			// Append Logical ID
			let queryAppender = ''
			if (store.value.visibility.query.trim().length > 0) {
				queryAppender = ' AND '
			}
			// const filters = ''
			const log = action.params
			const filters = `${queryAppender}dest_ip.value = '${log.dest_ip.value}' AND dest_port = ${log.dest_port} AND from_zone = '${log.from_zone}' AND log_source_id = '${
				log.log_source_id
				}' AND session_id = ${log.session_id} AND source_ip.value = '${log.source_ip.value}' AND source_port = ${log.source_port} AND to_zone = '${log.to_zone}'  AND time_generated >= '${moment(
					(parseInt(log.time_generated) - 86400000000) / 1000
				).format('MM/DD/YYYY hh:mm:ss A')}' AND time_generated <= '${moment((parseInt(log.time_generated) + 86400000000) / 1000).format('MM/DD/YYYY hh:mm:ss A')}'`

			let trafficCall = getLogsComposite(store.value.visibility, 'firewall.traffic', filters)
			let threatCall = getLogsComposite(store.value.visibility, 'firewall.threat', filters)
			let urlCall = getLogsComposite(store.value.visibility, 'firewall.url', filters)
			let fileCall = getLogsComposite(store.value.visibility, 'firewall.file_data', filters)

			return forkJoin([trafficCall, threatCall, urlCall, fileCall]).pipe(mergeMap((response) => of(actions.fetchCombinedRelatedLogs(response))))
		})
	)
}

export const initExportEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_EXPORT_REQUEST),
		switchMap((action) => {
			return initExport(store.value.visibility, action.params).pipe(
				mergeMap(({ response }) => {
					return of(actions.initExportSuccess(response.jobId), actions.fetchExportHistoryRequest(), actions.fetchExportStatusRequest(response.jobId))
				}),
				catchError((error) => {
					return of(actions.initExportFailure(error))
				})
			)
		})
	)
}

export const cancelExportEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CANCEL_EXPORT_REQUEST),
		switchMap((action) => {
			return cancelExport(store.value.visibility).pipe(
				mergeMap(({ response }) => {
					if (response.ok) {
						return of(actions.cancelExportSuccess(), actions.fetchExportHistoryRequest(), actions.removeCurrentJobId())
					} else {
						return of(actions.cancelExportFailure('uknown'), actions.fetchExportHistoryRequest(), actions.removeCurrentJobId())
					}
				}),
				catchError((error) => {
					return of(actions.cancelExportFailure(error), actions.fetchExportHistoryRequest(), actions.removeCurrentJobId())
				})
			)
		})
	)
}

export const checkExportStatusEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_EXPORT_STATUS_REQUEST),
		switchMap((action) => {
			return interval(1500).pipe(
				// CANCEL_EXPORT_SUCCESS is the break loop condition. Dispatch CANCEL_EXPORT_SUCCESS to jump out the loop.
				takeUntil(action$.pipe(ofType(actionTypes.CANCEL_EXPORT_SUCCESS, actionTypes.CANCEL_EXPORT_FAILURE))),
				concatMap(() =>
					getExportStatus(action.exportJobId).pipe(
						switchMap(({ response }) => {
							if (response.response.status === 'DONE') {
								return of(
									actions.fetchExportStatusSuccess(response.response),
									actions.cancelExportSuccess(),
									actions.fetchExportHistoryRequest(),
									actions.fetchExportFileRequest(Object.assign({ hdrDownload: true }, response.response))
								)
							}
							if (response.response.status === 'FAILED') {
								return of(actions.fetchExportStatusFailure(response.response), actions.cancelExportSuccess(), actions.fetchExportHistoryRequest())
							}
							return of(actions.fetchExportStatusSuccess(response.response))
						}),
						catchError((error) => of(actions.fetchExportStatusFailure(error)))
					)
				)
			)
		})
	)
}

export const fetchExportHistoryEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_EXPORT_HISTORY_REQUEST),
		switchMap((action) => {
			return getExportHistory().pipe(
				switchMap(({ response }) => {
					const exportHistory = response.response || []
					if (store.value.visibility.exportJobId && store.value.visibility.exportJobId != -1) {
						return of(actions.fetchExportHistorySuccess(exportHistory))
					} else {
						const exportJob = exportHistory.find((job) => job.status === 'RUNNING')
						if (exportJob) {
							return of(actions.fetchExportHistorySuccess(exportHistory), actions.initExportSuccess(exportJob.jobId), actions.fetchExportStatusRequest(exportJob.jobId))
						} else {
							return of(actions.fetchExportHistorySuccess(exportHistory))
						}
					}
				}),
				catchError((error) => {
					if (error.status === 404 && error.response.message === 'No jobs found!') {
						return of(actions.fetchExportHistorySuccess([]))
					}
					return of(actions.fetchExportHistoryFailure(error))
				})
			)
		})
	)
}

export const fetchExportFileEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_EXPORT_FILE_REQUEST),
		mergeMap((action) => {
			return downloadExport(action.params).pipe(
				switchMap(({ response }) => {
					console.log('downloading ...')
					const typeMap = { csv: 'text/csv', xml: 'application/xml', json: 'application/json' }
					const file = new Blob([response], { type: typeMap[action.params.envVariables.filetype] })
					if (typeof window.navigator.msSaveBlob !== 'undefined') {
						// For IE browser
						window.navigator.msSaveBlob(file, action.params.envVariables.filename)
					} else {
						const URL = window.URL || window.webkitURL
						const fileUrl = URL.createObjectURL(file)
						const a = document.createElement('a')
						a.href = fileUrl
						a.download = action.params.envVariables.filename
						document.body.appendChild(a)
						a.click()
						document.body.removeChild(a)
					}
					if (action.params.hdrDownload === true) {
						delete action.params.hdrDownload;
						return of(actions.fetchExportFileSuccess(action.params), actions.removeCurrentJobId())
					}
					return of(actions.fetchExportFileSuccess(action.params))
				}),
				catchError((error) => {
					if (action.params.hdrDownload === true) {
						delete action.params.hdrDownload;
						return of(actions.fetchExportFileFailure(error, action.params), actions.removeCurrentJobId())
					}
					return of(actions.fetchExportFileFailure(error, action.params))
				})
			)
		})
	)
}
