import React, { Component } from 'react';
import getServiceInfrastructureViewerWithActions from './ServiceInfrastructureViewerWithActions';
import { FormGroup, Col, Label } from "reactstrap";
import classnames from "classnames";
import { getStoreState, Pan } from 'ui-lib';
import _ from 'lodash';
import { SERVICE_INFRASTRUCTURE_REDUX_ID } from '../main/actions';

export default class ServiceInfrastructureBaseViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ServiceInfrastructureViewerWithActions: null
        }
    }

    componentDidMount() {
        this.setState({
            ServiceInfrastructureViewerWithActions: getServiceInfrastructureViewerWithActions(this.serviceName, this.reduxId, this.prepareAddRecord),
            ServiceInfrastructureViewerWithActions_DNSList: getServiceInfrastructureViewerWithActions(this.serviceName, this.secondaryReduxId, this.prepareAddRecord_DNSList, 'secondary')
        });
    }

    renderDefaultViewer = (ServiceInfra, DNSList) => {
        let ServiceInfrastructureViewerWithActions = this.state.ServiceInfrastructureViewerWithActions;
        let ServiceInfrastructureViewerWithActionsDNSList = this.state.ServiceInfrastructureViewerWithActions_DNSList;

        return (
            ServiceInfrastructureViewerWithActions &&
            <div className='service-infra-viewer'>
                {ServiceInfra}
                {ServiceInfrastructureViewerWithActionsDNSList &&
                    DNSList
                }
            </div>
        );
    }

    renderSimpleViewer = (ServiceInfra, DNSList) => {
        let { className } = this.props;

        let ServiceInfrastructureViewerWithActions = this.state.ServiceInfrastructureViewerWithActions;
        let ServiceInfrastructureViewerWithActionsDNSList = this.state.ServiceInfrastructureViewerWithActions_DNSList;

        return (
            ServiceInfrastructureViewerWithActions &&
            <div className='service-infra-viewer'>
                <FormGroup className={classnames(className, 'd-flex flex-row')}>
                    <Label className={"flex-grow-0 flex-shrink-0 col-form-label "} for={'ServiceInfrastructureViewerWithActions'}>
                        {this.header}
                    </Label>
                    <Col>
                        {ServiceInfra}
                    </Col>
                </FormGroup>
                {ServiceInfrastructureViewerWithActionsDNSList &&
                    <FormGroup className={classnames(className, 'd-flex flex-row')}>
                        <Label className={"flex-grow-0 flex-shrink-0 col-form-label "} for={'ServiceInfrastructureViewerWithActionsDNSList'}>
                            {this.secondaryHeader}
                        </Label>
                        <Col>
                            {DNSList}
                        </Col>
                    </FormGroup>
                }
            </div>
        );
    }

    render() {
        let ServiceInfrastructureViewerWithActions = this.state.ServiceInfrastructureViewerWithActions;
        let ServiceInfrastructureViewerWithActionsDNSList = this.state.ServiceInfrastructureViewerWithActions_DNSList;

        const gridOverride = {
			gridActionsAvail: {
				isAddDisabled: () => {
                    const serviceInfraData = _.get(getStoreState(),'gpcs.'+SERVICE_INFRASTRUCTURE_REDUX_ID+'.result.entry');
                    return Pan.isArray(serviceInfraData) && serviceInfraData[0] ? false: true;
				}
			}
		};

        const ServiceInfra = <ServiceInfrastructureViewerWithActions
            id="ServiceInfrastructureViewerWithActions"
            style ={{height: '150px'}}
            header={this.header}
            columns={this.columns}
            simpleColumns={this.simpleColumns}
            inputFields={this.fields}
            displayMode={this.props.displayMode || 'default'}
            recordConfigPath={this.recordConfigPath}
            editorMode={this.editorMode || 'default'}
            showDefaultCloneAction={this.showDefaultCloneAction}
            showDefaultAddAction={this.showDefaultAddAction}
            showDefaultDeleteAction={this.showDefaultDeleteAction}
            showConfigureActions={this.showConfigureActions}
            showFilter={this.showFilter}
            mode={this.props.mode || 'grid'}
            isViewerDisplay={true}
            onCancel={this.props.onCancel}
            afterSubmit={this.props.afterSubmit}
            configLocation={this.props.configLocation}
            rbaPermission={this.rbaPermission}
            resultPath={this.resultPath}
            record={this.props.record}
            formData={this.props.formData}
            checkboxSelection={this.checkboxSelection}
            idColumn={this.idColumn}
            agGridProps = { 
                {
                    onRowDataUpdated: (params) => {
                        let gridApi = params.api;
                        if (this.refreshColumns) {
                            gridApi.refreshCells({
                                columns: this.refreshColumns,
                                force: true
                            });
                        }
                    }
                }
            }
        />;
        const DNSList = <ServiceInfrastructureViewerWithActionsDNSList
            id="ServiceInfrastructureViewerWithActionsDNSList"
            style ={{height: '24vh', overflow: 'auto'}}
            header={this.secondaryHeader}
            columns={this.secondaryColumns}
            inputFields={this.secondaryFields}
            displayMode={this.props.displayMode || 'default'}
            recordConfigPath={this.recordConfigPathSecondary}
            editorMode={this.editorMode || 'default'}
            showDefaultAddAction={true}
            showDefaultDeleteAction={true}
            showDefaultCloneAction={this.showDefaultCloneAction}
            showConfigureActions={false}
            showFilter={this.showFilter}
            mode={this.props.mode || 'grid'}
            isViewerDisplay={this.props.isViewerDisplay || false}
            onCancel={this.props.onCancel}
            afterSubmit={this.props.afterSubmit}
            configLocation={this.props.configLocation}
            rbaPermission={this.rbaPermission}
            resultPath={this.resultPath}
            record={this.props.record}
            formData={this.props.formData}
            checkboxSelection={true}
            idColumn={this.secondaryIdColumn}
            gridOverride={gridOverride}
        />

        if (this.props.displayMode === 'simple')
            return this.renderSimpleViewer(ServiceInfra, DNSList)

        return this.renderDefaultViewer(ServiceInfra, DNSList);
    }
}