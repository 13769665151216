import React from "react";
import PropTypes from "prop-types";
import {BaseInput} from "../BaseInput";
const UpDownInput = BaseInput;
import './UpDownWidget.scss';
// min={min} max={max} step={step}
export function UpDownWidget(props) {
    return <UpDownInput type="number" {...props} />;
}

if (process.env.NODE_ENV !== "production") {
    UpDownWidget.propTypes = {
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };
}