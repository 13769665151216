import { ViewerManager, Pan, registerBuilder } from 'ui-lib';
import React from "react";
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";
import AppFilter from './AppFilter';

const _T = str => str;

const characteristicTotalFileds = [
    'evasive',
    'excessive-bandwidth-use',
    'has-known-vulnerabilities',
    'is-saas',
    'pervasive',
    'prone-to-misuse',
    'transfers-files',
    'tunnels-other-apps',
    'used-by-malware'
];

registerBuilder('AppFilterBuilder',AppFilter);

const applicationFilterObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    },
    {
        name: 'AppFilter',
        uiHint: {
            builder:'AppFilterBuilder'
        }
    }, {
        name: '$',
        childrenNames: [
            'name',
            '$.new-appid',
            'AppFilter'
        ],
        uiHint: {
            labelWidthSize: 3,
            layout: [{
                'name': 'col-4',
                '$.new-appid': 'col-4'
            }, {
                'AppFilter': 'col-12',
            }]
        }
    }
];

const aplicationFilterObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.vsys.entry.address.entry',
    minWidth: 80
}, {
    headerName: 'Location',
    field: '@loc',
    minWidth: 200
}, {
    headerName: 'Category',
    field: 'category',
    minWidth: 100
}, {
    headerName: 'Subcategory',
    field: 'subcategory',
    minWidth: 150
}, {
    headerName: 'Technology',
    field: 'technology',
    minWidth: 100
}, {
    headerName: 'Risk',
    field: 'risk',
    minWidth: 50
}, {
    headerName: 'Characteristic',
    minWidth: 100,
    valueGetter: params => {
        const characteristicFileds = Object.keys(params.data).filter(
            (field) => {
                return characteristicTotalFileds.indexOf(field) >= 0
            }
        );
        return characteristicFileds;
    }
}];

const viewConfig = {
    serviceName: 'Objects/ApplicationFilters',
    reduxId: 'applicationFilterObjects',
    header: 'Application Filters',
    widerForm: true,
    columns: aplicationFilterObjectColumns,
    fields: applicationFilterObjectFields,
    recordConfigPath: '$.config.devices.entry.device-group.entry.application-filter.entry',
    rbaPath: 'objects.application-filters',
    className: 'ApplicationFilters'
}

export default class ApplicationFilters extends DGBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);