import React, { Component } from "react";
import { SelectWidget } from "ui-lib";

export default class AdminSelection extends Component {
  render() {
    const customStyles = {
      control: styles => ({ ...styles, borderRadius: '20px' })
    };
    return (
      <div className="location-dropdown" style={{ width: '400px' }}>
        <SelectWidget value={this.props.value} options={this.props.options} onChange={this.props.onChange} styles={customStyles} isClearable={false} />
      </div>
    );
  }
}
