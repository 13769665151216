import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';

export class ProgressWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || 0,
            status: props.status || 'PEND'
        };
    }

    static getDerivedStateFromProps(nextProps, _) {
        return {
            value: nextProps.value,
            status: nextProps.status,
        };
    };

    isProgressCompleted() {
        return this.state.status === 'FIN';
    }

    getColor() {
        let color;
        switch (this.props.status) {
            case 'FAIL':
                color = 'danger';
                break;
            case 'PEND':
                color = 'success';
                break;
            default:
                color = 'info';
                break;
        }
        return color;
    }

    render() {
        let currentValue = this.state.value;
        if (this.props.showTextWhenDone && this.isProgressCompleted()) {
            return 'Done';
        } else {
            return <Progress animated color={this.getColor()} value={currentValue}>{currentValue}%</Progress>;
        }
    }
}

ProgressWidget.defaultProps = {
    value: '0',
    showTextWhenDone: true,
    status: 'PEND',
};

ProgressWidget.propTypes = {
    /**
     * current progress value
     */
    value: PropTypes.string.isRequired,
    /**
     * options - 'FIN','PEND','FAIL'
     */
    status: PropTypes.string,
    showTextWhenDone: PropTypes.bool,
};
