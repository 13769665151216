import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Button } from 'reactstrap';
import {getBuilder}from '../../../utils/BuilderManager';
import { FormContext, FormProvider } from '../../FormContext';
import { getField, getLeafNodesMapForDefaults, deepEquals } from "../../schema/utils";
import classnames from 'classnames';
import Pan from '../../schema/Pan';
import './SchemaForm.scss';
import _ from "lodash";
import { formDataPreProcess, formDataPostProcess, formDataSetDefaults, isPermissionEnabled, isAllFieldsValid } from "../util";
import { associationService } from "../../AssociationService";
import { updateModal, showModal, hideModal } from "../../../services/actions";
import { unsavedChangesWarning, unsavedChangesDialogTitle } from "../../../../constants";

export class SchemaForm extends Component {
    constructor(props) {
        super(props);
        this.updateStateWithErrors = _.debounce(
            this.updateStateWithErrors.bind(this),
            100,
            { trailing: true, leading: true },
        );
        this.updateFormState = _.debounce(
            this.updateFormState.bind(this),
            100,
            { trailing: true, leading: true },
        );
        this.getStateFromProps = this.getStateFromProps.bind(this);
        this.state = this.getStateFromProps(props);
        this.onChange = this.onChange.bind(this);
        this.addError = this.addError.bind(this);
        this.removeError = this.removeError.bind(this);
    }

    errors = {};
    formState = {};
    fieldIds = [];

    getStateFromProps(props) {
        const schema = 'schema' in props ? props.schema : this.props.schema;
        const fields = 'fields' in props ? props.fields : this.props.fields;
        let {
            recordConfigPath,
            formData,
            formDataBackup,
            errors,
            formatFormData,
            configLocation,
            rbaPermission,
            locationPermission,
            isNewRecord,
            filters,
        } = props;
        this.errors = Pan.clone(errors);
        let showErrors = Pan.isBoolean(props.showErrors)
            ? props.showErrors
            : true;
        let formDataCopy = Pan.clone(formData);
        if (formatFormData) {
            if (isNewRecord) {
                //only while adding new record
                let leafNodesMap = getLeafNodesMapForDefaults(
                    [this.props.itemId || '$'],
                    fields,
                    false,
                );
                formDataCopy = formDataSetDefaults(
                    formDataCopy,
                    fields,
                    leafNodesMap[this.props.itemId || '$'],
                    filters,
                );
            }
            formDataCopy = formDataPreProcess(formDataCopy, fields, [
                this.props.itemId || '$',
            ]);
        }

        return {
            schema,
            fields,
            formData: formDataCopy,
            formDataBackup,
            recordConfigPath,
            errors,
            showErrors,
            configLocation,
            rbaPermission,
            locationPermission,
            isNewRecord,
            isRecordDirty: !Pan.isEmpty(this.formState)
        }
    }

    static getDerivedStateFromProps(nextProps, _) {
        if (nextProps.storybook) {
            return {
                fields: nextProps.fields,
            };
        }
        return null;
    }

    componentDidUpdate() {
        if (this.props.isDynamicLoadData && !deepEquals(this.props.formData, this.state.formData)) {
            //only in case the data is dynamically loaded, set state in componentWilReceiveProps
            //costly operation. Use only when needed
            this.setState({ formData: this.props.formData });
        }
    }

    componentDidMount() {
        let getSubmitHandler = this.props.getSubmitHandler;
        if (getSubmitHandler && Pan.isFunction(getSubmitHandler)) {
            getSubmitHandler(this.onSubmit);
        }
        if (this.props.displayMode == 'modal') {
            this.updateModalButtons();
        }
    }

    updateStateWithErrors() {
        let errorList = Pan.clone(this.errors);
        this.setState({
            errors: errorList,
        });
    }

    updateFormState() {
        let fieldIds = [...this.fieldIds];
        this.fieldIds = [];
        this.setState(
            this.formState, () => {
                for (let fieldId of fieldIds) {
                    associationService.sendEvent(fieldId);
                }
            }
        )
    }

    addError(errorObj) {
        Pan.apply(this.errors, errorObj);
        this.updateStateWithErrors();
    }

    removeError(errorKey) {
        if (errorKey && this.errors[errorKey]) {
            delete this.errors[errorKey];
        }
        this.updateStateWithErrors();
    }

    onChange(data) {
        let state = { ...data };
        //accumulate fieldIds to an array for Rxjs Event service
        this.fieldIds = _.uniq([...this.fieldIds, state.fieldId]);
        //delete fieldId as this is not required
        delete state.fieldId;

        Pan.apply(this.formState, state);
        this.updateFormState();
    }

    onBlur = (...args) => {
        if (this.props.onBlur) {
            this.props.onBlur(...args);
        }
    };

    onFocus = (...args) => {
        if (this.props.onFocus) {
            this.props.onFocus(...args);
        }
    };

    onCancel = event => {
        let modal;
        if (!Pan.isEmpty(this.formState)) {
            modal = {
                id: "cancelWarningModal",
                title: unsavedChangesDialogTitle,
                type: 'Warning',
                message: unsavedChangesWarning,
                toggle: (e) => {
                    e.preventDefault();
                    hideModal("cancelWarningModal");
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                },
                actions: [{
                    text: 'Cancel',
                    color: "secondary",
                    action: (e) => {
                        e.preventDefault();
                        hideModal("cancelWarningModal");
                        if (this.props.onCancel) {
                            this.props.onCancel();
                        }
                    }
                }, {
                    text: 'Save',
                    action: (e) => {
                        hideModal("cancelWarningModal");
                        this.onSubmit(e);
                    }
                }]
            };
        }
        if (modal) {
            showModal(modal);
        } else {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    onSubmit = event => {
        event.preventDefault();
        if (!Pan.isEmpty(this.state.errors)) {
            this.setState({
                showErrors: true,
            });
            return;
        }

        if (this.props.onSubmit) {
            if (this.props.formatFormDataPostProcess) {
                let formData = Pan.clone(this.state.formData);
                formDataPostProcess(formData, this.props.fields, [
                    this.props.itemId || '$',
                ]);
                let state = { ...this.state, formData };
                this.props.onSubmit({ ...state, status: 'submitted' });
            } else {
                this.props.onSubmit({ ...this.state, status: 'submitted' });
            }
        }
    };

    isAllFieldsValid = (errors) => {
        return Object.entries(errors).length === 0 ? false : true;
    }

    updateModalButtons = () => {
        if (!this.props.modalId) {
            return;
        }
        updateModal(
            this.props.modalId,
            {
                size: this.props.modalFormSize || 'xl',
                actions: [{
                    text: "Cancel",
                    color: "secondary",
                    action: (e) => {
                        e.preventDefault();
                        this.onCancel(e);
                    },
                }, {
                    text: "Save",
                    color: "primary",
                    action: this.onSubmit.bind(this),
                    disabled: isAllFieldsValid(this.state.errors)
                }]
            }
        );
    }

    render() {
        let {
            id,
            children,
            itemId,
            className,
            rbaPermission,
            locationPermission,
            hideOk,
            hideCancel,
        } = this.props;

        hideOk = Pan.isBoolean(hideOk) ? hideOk : false;
        hideCancel = Pan.isBoolean(hideCancel) ? hideCancel : false;

        let { filters } = this.props;
        filters = filters || [];

        const { fields } = this.state;

        const Builder = getBuilder({ name: itemId, fields });
        const field = getField(fields, itemId);
        let disabled = !(isPermissionEnabled(rbaPermission) && isPermissionEnabled(locationPermission));

        return (
            <FormProvider value={this.state}>
                <div id={id} className={classnames(className, 'd-flex flex-column border border-light')}>
                    <FormGroup>
                        <Builder
                            name={itemId}
                            field={field}
                            onChange={this.onChange}
                            addError={this.addError}
                            removeError={this.removeError}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            onCancel={this.onCancel}
                            filters={filters}
                            showErrors={this.state.showErrors}
                            disabled={disabled}
                        />
                    </FormGroup>
                    {this.props.displayMode != 'modal' && (children ? (children) : (!hideCancel || !hideOk)) ?
                        (
                            <div className="form-footer-actions d-flex bg-white justify-content-end btnContainer">
                                {
                                    !hideCancel &&
                                    <Button
                                        color="secondary"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.onCancel();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                }
                                {
                                    !disabled && !hideOk &&
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isAllFieldsValid(this.state.errors)}
                                        onClick={e => {
                                            this.onSubmit(e);
                                        }}
                                    >
                                        Save
                                    </Button>
                                }
                            </div>
                        ) :
                        (
                            <div />
                        )
                    }
                </div>
            </FormProvider>
        );
    }
}

SchemaForm.contextType = FormContext;

SchemaForm.defaultProps = {
    formData: {},
};

if (process.env.NODE_ENV !== 'production') {
    SchemaForm.propTypes = {
        id: PropTypes.string,
        children: PropTypes.array,
        itemId: PropTypes.string,
        className: PropTypes.string,
        schema: PropTypes.object,
        fields: PropTypes.array.isRequired,
        record: PropTypes.any,
        formData: PropTypes.any,
        formDataBackup: PropTypes.any,
        onCancel: PropTypes.func,
        onChange: PropTypes.func,
        onError: PropTypes.func,
        onSubmit: PropTypes.func,
        errors: PropTypes.any,
        rbaPermission: PropTypes.string,
        locationPermission: PropTypes.string,
        hideOk: PropTypes.bool,
        hideCancel: PropTypes.bool,
        isNewRecord: PropTypes.bool,
    };
}
