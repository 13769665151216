import React, { Component } from 'react';
import { Pan, getStore, getPathValue, getStoreState } from 'ui-lib';
import { getEffectiveServiceMap, getWorkflowWithActions, fetchConfigItems, GPCS_COMMON_TPL } from 'service-lib';
import {
    addPushGpcsItem,
    editPushGpcsItem,
    fetchJobsDetails,
    fetchMapRegionInfo
} from './actions';

export default class GPCSWorkflowBaseViewer extends Component {
    constructor(props) {
        super(props);
        const configLocation = props.configLocation ? getPathValue(props.configLocation, 'extension.gpcs.name') : null;
        this.state = {
            configLocation: configLocation ? { extension: { gpcs: { name: configLocation } } } : null,
            WorkflowWithActions: null
        }
    }

    componentDidMount() {
		const serviceMap = getEffectiveServiceMap(this.serviceName);
        this.setState({
            WorkflowWithActions: getWorkflowWithActions(this.serviceName, this.reduxId, serviceMap, this.prepareAddRecord, this.summaryAvail, 'gpcs', this.state.configLocation),
            reduxId: this.reduxId,
			reduxStateId: {
				reduxId: this.reduxId,
				locationPath: 'gpcs'
            },
            serviceMap: serviceMap
        });
        let fetched = false;
        if (this.props.mode !== 'form') {
            fetched = true;
        }
        if (!fetched || this.serviceName !== 'workflow/remoteNetworks') {
            const rnServiceMap = { GET: 'workflow/remoteNetworks' };
            const rnreduxStateId = { reduxId: 'remoteNetworks', locationPath: 'gpcs' };
            getStore().dispatch(fetchConfigItems(rnServiceMap, rnreduxStateId, this.state.configLocation));
        }
        const regionInfo = getPathValue(getStoreState(), 'gpcs.regionInfo');
        if (regionInfo.length === 0) {
            getStore().dispatch(fetchMapRegionInfo());
        }
        
    }

    onAddAndPushRecord(record) {
        getStore().dispatch(
            addPushGpcsItem(record, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation)
        );
    }

    onEditAndPushRecord(record, backup) {
        getStore().dispatch(
            editPushGpcsItem(record, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
        );
    }

    onGoToMapPage() {
        console.log('go here')
    }

    getJobDetails(jobId) {
        console.log('jobId', jobId);
        getStore().dispatch(
            fetchJobsDetails(jobId)
        );
    }

    render() {
        let WorkflowWithActions = this.state.WorkflowWithActions;
        return (
            WorkflowWithActions &&
            <WorkflowWithActions
                {...this.props}
                reduxStateId={this.state.reduxStateId}
                header={this.props.header || this.header}
                columns={this.columns}
                simpleColumns={this.simpleColumns}
                inputFields={this.fields}
                recordConfigPath={this.recordConfigPath}
                mode={this.props.mode || 'grid'}
                displayMode={this.props.displayMode || 'default'}
                editorMode={this.props.editorMode || 'default'}
                singleStepForAdd={this.singleStepForAdd}
                showDefaultCloneAction={Pan.isDefined(this.props.showDefaultCloneAction) ? this.props.showDefaultCloneAction : this.showDefaultCloneAction}
                showDefaultAddAction={Pan.isDefined(this.props.showDefaultAddAction) ? this.props.showDefaultAddAction : this.showDefaultAddAction}
                showDefaultDeleteAction={Pan.isDefined(this.props.showDefaultDeleteAction) ? this.props.showDefaultDeleteAction : this.showDefaultDeleteAction}
                showFilter={this.props.showFilter || this.showFilter}
                nextTextOnFinalActionStep={this.nextTextOnFinalActionStep}
                customValidationToSpecialSubmit={this.customValidationToSpecialSubmit}
                rbaPermission={this.rbaPermission}
                locationPermission={this.locationPermission}
                maxCount={this.maxCount}
                showConfigureActions={this.showConfigureActions}
                showPaging={this.showPaging}
                checkboxSelection={this.checkboxSelection}
                onAddAndPushRecord={this.onAddAndPushRecord.bind(this)}
                onEditAndPushRecord={this.onEditAndPushRecord.bind(this)}
                onGoToMapPage={this.onGoToMapPage.bind(this)}
                getJobDetails={this.getJobDetails.bind(this)}
                initActions={this.initActions}
                initActionsConfigLocation={{ tpl: { name: GPCS_COMMON_TPL } }}
                minRows={this.props.minRows || this.minRows}
                suppressColsSizeToFit={Pan.isDefined(this.props.suppressColsSizeToFit) ?  this.props.suppressColsSizeToFit : (Pan.isDefined(this.suppressColsSizeToFit) ? this.suppressColsSizeToFit : true)}
                agGridProps = { 
                    {
                        onRowDataUpdated: (params) => {
                            let gridApi = params.api;
                            if (this.refreshColumns) {
                                gridApi.refreshCells({
                                    columns: this.refreshColumns,
                                    force: true
                                });
                            }
                        }
                    }
                }
            />
        );
    }
}