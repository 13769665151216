/* JSONPath - XPath for JSON
 *
 */
import jsonQ from "jsonq";
let jp = require('jsonpath');
import Pan from '../autorender/schema/Pan';
import _ from 'lodash';
import { INJECTED_REC_ID, CURRENT_ITEM_KEY } from "../autorender/widgets/util";
export function jsonPath(obj, expr, arg) {
    var P = {
        resultType: arg && arg.resultType || "VALUE",
        result: [],
        normalize: function (expr) {
            var subx = [];
            return expr.replace(/[\['](\??\(.*?\))[\]']/g, function ($0, $1) { return "[#" + (subx.push($1) - 1) + "]"; })
                .replace(/'?\.'?|\['?/g, ";")
                .replace(/;;;|;;/g, ";..;")
                .replace(/;$|'?\]|'$/g, "")
                .replace(/#([0-9]+)/g, function ($0, $1) { return subx[$1]; });
        },
        asPath: function (path) {
            var x = path.split(";"), p = "$";
            for (var i = 1, n = x.length; i < n; i++)
                p += /^[0-9*]+$/.test(x[i]) ? ("[" + x[i] + "]") : ("['" + x[i] + "']");
            return p;
        },
        store: function (p, v) {
            if (p) P.result[P.result.length] = P.resultType == "PATH" ? P.asPath(p) : v;
            return !!p;
        },
        trace: function (expr, val, path) {
            if (expr) {
                var x = expr.split(";"), loc = x.shift();
                x = x.join(";");
                if (val && val.hasOwnProperty(loc))
                    P.trace(x, val[loc], path + ";" + loc);
                else if (loc === "*")
                    P.walk(loc, x, val, path, function (m, l, x, v, p) { P.trace(m + ";" + x, v, p); });
                else if (loc === "..") {
                    P.trace(x, val, path);
                    P.walk(loc, x, val, path, function (m, l, x, v, p) { typeof v[m] === "object" && P.trace("..;" + x, v[m], p + ";" + m); });
                }
                else if (/,/.test(loc)) { // [name1,name2,...]
                    for (var s = loc.split(/'?,'?/), i = 0, n = s.length; i < n; i++)
                        P.trace(s[i] + ";" + x, val, path);
                }
                else if (/^\(.*?\)$/.test(loc)) // [(expr)]
                    P.trace(P.eval(loc, val, path.substr(path.lastIndexOf(";") + 1)) + ";" + x, val, path);
                else if (/^\?\(.*?\)$/.test(loc)) // [?(expr)]
                    P.walk(loc, x, val, path, function (m, l, x, v, p) { if (P.eval(l.replace(/^\?\((.*?)\)$/, "$1"), v[m], m)) P.trace(m + ";" + x, v, p); });
                else if (/^(-?[0-9]*):(-?[0-9]*):?([0-9]*)$/.test(loc)) // [start:end:step]  phyton slice syntax
                    P.slice(loc, x, val, path);
            }
            else
                P.store(path, val);
        },
        walk: function (loc, expr, val, path, f) {
            if (val instanceof Array) {
                for (var i = 0, n = val.length; i < n; i++)
                    if (i in val)
                        f(i, loc, expr, val, path);
            }
            else if (typeof val === "object") {
                for (var m in val)
                    if (val.hasOwnProperty(m))
                        f(m, loc, expr, val, path);
            }
        },
        slice: function (loc, expr, val, path) {
            if (val instanceof Array) {
                var len = val.length, start = 0, end = len, step = 1;
                loc.replace(/^(-?[0-9]*):(-?[0-9]*):?(-?[0-9]*)$/g, function ($0, $1, $2, $3) { start = parseInt($1 || start); end = parseInt($2 || end); step = parseInt($3 || step); });
                start = (start < 0) ? Math.max(0, start + len) : Math.min(len, start);
                end = (end < 0) ? Math.max(0, end + len) : Math.min(len, end);
                for (var i = start; i < end; i += step)
                    P.trace(i + ";" + expr, val, path);
            }
        },
        eval: function (x, _v, _vname) {
            try { return $ && _v && eval(x.replace(/@/g, "_v")); }
            catch (e) { throw new SyntaxError("jsonPath: " + e.message + ": " + x.replace(/@/g, "_v").replace(/\^/g, "_a")); }
        }
    };

    var $ = obj;
    if (expr && obj && (P.resultType == "VALUE" || P.resultType == "PATH")) {
        P.trace(P.normalize(expr).replace(/^\$;/, ""), obj, "$");
        return P.result.length ? P.result : false;
    }
}

export function getPathValues(obj, expr, filters, key) {
    if (!Pan.isEmpty(filters)) {
        expr = getRecordPathWithFilters(obj, expr, filters, key);
    }
    expr = expr || '';
    if (expr === '$') {
        expr = '$.*';
    }
    if (!obj) {
        return false;
    }
    let data = jsonPath(obj, expr);
    return data;
}

export function insertArrayItemInPath(obj, expr, index, insertObj, filters) {
    let objArray = getPathValue(obj, expr, filters) || [];
    if (!Pan.isEmpty(objArray) && !Pan.isArray(objArray)) {
        // objArray = [objArray];
        objArray = []; //when grid has default string value 'any' 
    }
    objArray.push(insertObj);
    setPathValue(obj, expr, objArray, filters);
    return obj;
}

export function deleteArrayItemInPath(obj, expr, key, filters) {
    let objArray = getPathValue(obj, expr, filters);
    if (objArray && Array.isArray(objArray)) {
        _.remove(objArray, (item) => {
            return item[INJECTED_REC_ID] === key;
        });
        // objArray.splice(index, 1);
    }
    setPathValue(obj, expr, objArray, filters);
    return obj;
}

export function getRecordPathWithFilters(obj, path, filters, key) {
    if (!Pan.isEmpty(filters) && !Pan.isEmpty(path)) {
        filters.forEach((filter) => {
            if (filter && filter.find && filter.replace) {
                let replace = filter.replace;
                if (replace.indexOf(CURRENT_ITEM_KEY) && key) {
                    replace = replace.replace(CURRENT_ITEM_KEY, key);
                }
                path = path.replace(filter.find, replace);
            }
        })
        let result = getPathValue(obj, path);
        let lastFilter = filters[filters.length - 1];
        //if last filter is of type append
        if (lastFilter && lastFilter.append && Pan.isArray(result)) {
            //append key for the case of multiple type
            let append = lastFilter.append;
            if (append.indexOf(CURRENT_ITEM_KEY) && key) {
                //replace current item key with actual value
                append = append.replace(CURRENT_ITEM_KEY, key);
                path = path + append;
            } else if (append.indexOf(CURRENT_ITEM_KEY) < 0) {
                //curr item key already replaced in append
                path = path + append;
            }
        }
    }
    return path
}

export function getPathValue(obj, expr, filters, key) {
    let data = getPathValues(obj, expr, filters, key);

    return (data ? data[0] : data);
}

export function setPathValue(obj, expr, val, filters, key) {
    if (!Pan.isEmpty(filters)) {
        expr = getRecordPathWithFilters(obj, expr, filters, key);
    }
    return setPathValueRecursive(obj, expr, val, filters, key);
}

export function setPathValueRecursive(obj, expr, val, filters, key) {
    expr = expr.replace(/\?\(\@\./g, '?(@,');
    let exprParts = expr.split('.');
    let path = [], stackPaths = '';
    let startStacking = false;
    for (let part of exprParts) {
        part = part.replace(/\?\(\@\,/g, '?(@.');

        if (!startStacking) {
            if (part.indexOf('-') >= 0 || part.indexOf('@name') >= 0) {
                //to avoid lexical error for - and @
                stackPaths += "['" + part + "']";
            } else {
                stackPaths += (stackPaths != "" ? "." + part : part);
            }

            let p = jp.paths(obj, stackPaths);
            if (p && p.length > 0) {
                path = p[0];
            } else {
                startStacking = true;
            }
        }

        if (startStacking) {
            if (part.indexOf('?(@.') >= 0) {
                //entry or member filter
                if (part.indexOf('entry') > 0) {
                    path.push('entry'); //entry
                } else {
                    path.push('member'); //member                 
                }
                path.push(0);
            } else {
                path.push(part);
            }
        }
    }
    if (path && path.length > 0 && path[0] === '$') {
        path.shift();
    }
    if (path.length <= 0) {
        return false;
    }
    let newPath = path.map((item) => {
        if (isNaN(item)) {
            return item;
        } else {
            return Number(item);
        }
    })
    let jsonObj = jsonQ(obj);
    if (!val || val === 'null' || Pan.isObject(val) && (val.value && val.value === 'null')) {
        jsonObj.setPathValue(newPath, undefined);
    } else {
        jsonObj.setPathValue(newPath, val);
    }
    return jsonObj;
}

export function deletePath(obj, expr, filters, key) {
    if (Pan.isEmpty(obj))
        return false;

    if (!Pan.isEmpty(filters)) {
        expr = getRecordPathWithFilters(obj, expr, filters, key);
    }
    expr = expr.replace(/\?\(\@\./g, '?(@,'); //to avoid splitting the filter string replace . with ,
    let exprParts = expr.split('.');
    let path = [], stackPaths = '';
    let startStacking = false;
    for (let part of exprParts) {
        part = part.replace(/\?\(\@\,/g, '?(@.');

        if (!startStacking) {
            if (part.indexOf('-') >= 0 || part.indexOf('@name') >= 0) {
                //to avoid lexical error for - and @
                stackPaths += "['" + part + "']";
            } else {
                stackPaths += (stackPaths != "" ? "." + part : part);
            }

            let p = jp.paths(obj, stackPaths);
            if (p && p.length > 0) {
                path = p[0];
            } else {
                startStacking = true;
            }
        }

        if (startStacking) {
            if (part.indexOf('?(@.') >= 0) {
                //entry or member filter
                if (part.indexOf('entry') > 0) {
                    path.push('entry'); //entry
                } else {
                    path.push('member'); //member                 
                }
                path.push(0);
            } else {
                path.push(part);
            }
        }
    }
    if (path && path.length > 0 && path[0] === '$') {
        path.shift();
    }
    if (path.length <= 0) {
        return false;
    }
    for (var i = 0; i < path.length - 1; i++) {
        obj = obj[path[i]];
        if (Pan.isEmpty(obj))
            return false;
    }
    if (!Pan.isObject(obj)) {
        return false;
    }
    delete obj[path[path.length - 1]];
}

export function mergeJson(deep, target, src) {
    jsonQ(deep, target, src);
}

export function JSONStringify(object) {
    var str = JSON.stringify(object,
        // custom replacer fxn - gets around "TypeError: Converting circular structure to JSON" 
        function (key, value) {
            if (typeof value === 'function' && value !== null) {
                // Circular reference found, it's a function
                return value.toString();
            }
            return value;
        }, 2
    );
    return str;
}

export function prettyPrint(jsObject, indentLength, outputTo, fullFunction) {
    var indentString,
        newLine,
        newLineJoin,
        TOSTRING,
        TYPES,
        valueType,
        repeatString,
        prettyObject,
        prettyObjectJSON,
        prettyObjectPrint,
        prettyArray,
        functionSignature,
        pretty,
        visited;

    TOSTRING = Object.prototype.toString;

    TYPES = {
        "undefined": "undefined",
        "number": "number",
        "boolean": "boolean",
        "string": "string",
        "[object Function]": "function",
        "[object RegExp]": "regexp",
        "[object Array]": "array",
        "[object Date]": "date",
        "[object Error]": "error"
    };

    if (!Object.keys) {
        Object.keys = (function () {
            "use strict";
            var hasOwnProperty = Object.prototype.hasOwnProperty,
                hasDontEnumBug = !({
                    toString: null
                }).propertyIsEnumerable("toString"),
                dontEnums = [
                    "toString",
                    "toLocaleString",
                    "valueOf",
                    "hasOwnProperty",
                    "isPrototypeOf",
                    "propertyIsEnumerable",
                    "constructor"
                ],
                dontEnumsLength = dontEnums.length;

            return function (obj) {
                if (typeof obj !== "function" && (typeof obj !== "object" || obj === null)) {
                    throw new TypeError("Object.keys called on non-object");
                }

                var result = [],
                    prop, i;

                for (prop in obj) {
                    if (hasOwnProperty.call(obj, prop)) {
                        result.push(prop);
                    }
                }

                if (hasDontEnumBug) {
                    for (i = 0; i < dontEnumsLength; i++) {
                        if (hasOwnProperty.call(obj, dontEnums[i])) {
                            result.push(dontEnums[i]);
                        }
                    }
                }
                return result;
            };
        }());
    }

    valueType = function (o) {
        var type = TYPES[typeof o] || TYPES[TOSTRING.call(o)] || (o ? "object" : "null");
        return type;
    };

    repeatString = function (src, length) {
        var dst = "",
            index;
        for (index = 0; index < length; index += 1) {
            dst += src;
        }

        return dst;
    };

    prettyObjectJSON = function (object, indent) {
        var value = [];

        indent += indentString;
        Object.keys(object).forEach(function (property) {
            value.push(indent + "\"" + property + "\": " + pretty(object[property], indent));
        });

        return value.join(newLineJoin) + newLine;
    };

    prettyObjectPrint = function (object, indent) {
        var value = [];

        indent += indentString;
        Object.keys(object).forEach(function (property) {
            value.push(indent + property + ": " + pretty(object[property], indent));
        });
        return value.join(newLineJoin) + newLine;
    };

    prettyArray = function (array, indent) {
        var index,
            length = array.length,
            value = [];

        indent += indentString;
        for (index = 0; index < length; index += 1) {
            value.push(pretty(array[index], indent, indent));
        }

        return value.join(newLineJoin) + newLine;
    };

    functionSignature = function (element) {
        var signatureExpression,
            signature;

        element = element.toString();
        signatureExpression = new RegExp("function\\s*.*\\s*\\(.*\\)");
        signature = signatureExpression.exec(element);
        signature = signature ? signature[0] : "[object Function]";
        return fullFunction ? element : "\"" + signature + "\"";
    };

    pretty = function (element, indent, fromArray) {
        var type;

        type = valueType(element);
        fromArray = fromArray || "";
        if (visited.indexOf(element) === -1) {
            switch (type) {
                case "array":
                    visited.push(element);
                    return fromArray + "[" + newLine + prettyArray(element, indent) + indent + "]";

                case "boolean":
                    return fromArray + (element ? "true" : "false");

                case "date":
                    return fromArray + "\"" + element.toString() + "\"";

                case "number":
                    return fromArray + element;

                case "object":
                    visited.push(element);
                    return fromArray + "{" + newLine + prettyObject(element, indent) + indent + "}";

                case "string":
                    return fromArray + JSON.stringify(element);

                case "function":
                    return fromArray + functionSignature(element);

                case "undefined":
                    return fromArray + "undefined";

                case "null":
                    return fromArray + "null";

                default:
                    if (element.toString) {
                        return fromArray + "\"" + element.toString() + "\"";
                    }
                    return fromArray + "<<<ERROR>>> Cannot get the string value of the element";
            }
        }
        return fromArray + "circular reference to " + element.toString();
    };

    if (jsObject) {
        if (indentLength === undefined) {
            indentLength = 2;
        }

        outputTo = (outputTo || "print").toLowerCase();
        indentString = repeatString(outputTo === "html" ? "&nbsp;" : " ", indentLength);
        prettyObject = outputTo === "print" ? prettyObjectPrint : prettyObjectJSON;
        newLine = outputTo === "html" ? "<br/>" : "\n";
        newLineJoin = "," + newLine;
        visited = [];
        return pretty(jsObject, "") + newLine;
    }

    return "Error: no Javascript object provided";
}
