
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormContext, FormConsumer } from "../../FormContext";
import './DatefieldBuilder.scss'

export class DatefieldBuilder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            name,
            field,
            onBlur,
            onFocus
        } = this.props;

        return (
            <div>DatefieldBuilder for {name}</div>
        );
    }

}

DatefieldBuilder.contextType = FormContext;

DatefieldBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    DatefieldBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        schema: PropTypes.object.isRequired,
        field: PropTypes.object
    };
}
