import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormContext, FormConsumer } from "../../FormContext";
import './SequenceCheckboxBuilder.scss';

export class SequenceCheckboxBuilder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            name,
            fields,
            onBlur,
            onFocus
        } = this.props;

        return (
            <div>SequenceCheckboxBuilder for {name}</div>
        );
    }
}

SequenceCheckboxBuilder.contextType = FormContext;

SequenceCheckboxBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    SequenceCheckboxBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        field: PropTypes.object
    };
}
