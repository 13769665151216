import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;
const applicationTagObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            builder: 'TextfieldBuilder',
            disabled:true,
            fieldLabel: _T('Name')
        }
    },
    // {
    //     name: '$.disable-override',
    //     uiHint: {
    //         fieldLabel: _T('Disable override')
    //     }
    // },
    {
        name: '$.tag.member',
        uiHint: {
            builder: 'TagBuilder',
            fieldLabel: _T('Tags')
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            //'$.disable-override',
            '$.tag.member',
        ]
    }
];
const tagsColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
    }
];


export default class ApplicationTag extends DGBaseViewer {
    serviceName = "Objects/ApplicationTags";
    reduxId = 'applicationTagObject';
    header = "Application Tag ";
    fields = applicationTagObjectFields;
    columns = tagsColumns;
    recordConfigPath = "$.config.devices.entry.device-group.entry.application-tag.entry";
    rbaPath='objects.applications';
} 