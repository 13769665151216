import React, { Component } from 'react';
import FilterInput from './FilterInput';
import { Container, Row, Col ,Spinner} from 'reactstrap';
import DataProxy from './AppDataProxy';
import { Pan, SpinnerWidget } from "ui-lib";
import _ from 'lodash';

import './FilterViewer.scss';

export default class FilterViewer extends Component {

    constructor(props) {
        super(props);
    }

    getFilterData = (filters, type) => {
        for (var i = 0; i < filters.length; i++) {
            var filter = filters[i];
            if (type == filter.type)
                return filter.data;
        }
        return [];
    }

    render() {
        const { result = {}, filters = [], onFilterChange, isLoading = false } = this.props;
        const category = Pan.apply({}, result.category);
        const catetoryItems = DataProxy.toFilters(category, false, false, this.getFilterData(filters, 'category'));
        const subcategory = Pan.apply({}, result.subcategory);
        const subcategoryItems = DataProxy.toFilters(subcategory, false, false, this.getFilterData(filters, 'subcategory'));
        const technology = Pan.apply({}, result.technology);
        const technologyItems = DataProxy.toFilters(technology, false, false, this.getFilterData(filters, 'technology'));
        const risk = Pan.apply({}, result.risk);
        const riskItems = DataProxy.toFilters(risk, true, false, this.getFilterData(filters, 'risk'));
        const characteristic = Pan.apply({}, result.characteristic);
        const characteristicItems = DataProxy.toFilters(characteristic, false, true, this.getFilterData(filters, 'characteristic'));

        return (
            <>
                {isLoading && (<SpinnerWidget />)}
                <Container fluid>
                    <Row className="filter-viewer">
                        <Col sm="2" className="filter-input">
                            <FilterInput type="category" title="Category" items={catetoryItems} onFilterChange={onFilterChange} />
                        </Col>
                        <Col sm="3" className="filter-input">
                            <FilterInput type="subcategory" title="Subcategory" items={subcategoryItems} onFilterChange={onFilterChange} />
                        </Col>
                        <Col sm="2" className="filter-input">
                            <FilterInput type="technology" title="Technology" items={technologyItems} onFilterChange={onFilterChange} />
                        </Col>
                        <Col sm="2" className="filter-input">
                            <FilterInput type="risk" title="Risk" items={riskItems} onFilterChange={onFilterChange} />
                        </Col>
                        <Col sm="3" className="filter-input">
                            <FilterInput type="characteristic" title="Characteristic" items={characteristicItems} onFilterChange={onFilterChange} />
                        </Col>
                    </Row>
                 </Container>
            </>
        );
    }
}