import { ViewerManager, Pan } from "ui-lib";
import TPLBaseViewer from "../../template/TPLBaseViewer";
import getTPLRecordViewer from '../../template/TPLRecordViewer';

const _T = str => str;

const validateMinMaxVersion = (min, max) => {
    //use a hardcoded array map here to compare version, need to get the data from store/endpoints in the future
    const lookup = ['tls1-0', 'tls1-1', 'tls1-2', 'max'];
    let minIndex = lookup.indexOf(min);
    let maxIndex = lookup.indexOf(max);
    if (minIndex > maxIndex) {
        return false;
    }
    return true;
};

const sslTlsServiceProfileFields = [
    {
        type: "string",
        name: "name",
        mapping: "@name",
        uiHint: {
            fieldLabel: _T("Name")
        }
    },
    {
        name: "ProtocolSettings",
        childrenNames: ["$.protocol-settings.min-version", "$.protocol-settings.max-version"],
        uiHint: {
            fieldLabel: _T("Protocol Settings"),
            builder: "FieldSetBuilder",
            useHelpStringAsDisplay: true
        }
    },
    {
        name: "$.protocol-settings.min-version",
        uiHint: {
            fieldRequired: true,
            association: {
                fields: ['$.protocol-settings.max-version'],
                updateFormData: function (values, formData, filters, key) {
                    //values is from max-version
                    if (values && values[0] && formData && formData['protocol-settings'] && formData['protocol-settings']['min-version']) {
                        if (!validateMinMaxVersion(formData['protocol-settings']['min-version'], values[0])) {
                            return {
                                '$.protocol-settings.min-version': values[0]
                            };
                        }
                    }
                }
            },
            useHelpStringAsDisplay: true
        }
    },
    {
        name: "$.protocol-settings.max-version",
        uiHint: {
            fieldRequired: true,
            largeSelectionCount: 5,
            association: {
                fields: ['$.protocol-settings.min-version'],
                updateFormData: function (values, formData, filters, key) {
                    //values is from min-version
                    if (values && values[0] && formData && formData['protocol-settings'] && formData['protocol-settings']['max-version']) {
                        if (!validateMinMaxVersion(values[0], formData['protocol-settings']['max-version'])) {
                            return {
                                '$.protocol-settings.max-version': values[0]
                            };
                        }
                    }
                }
            },
            useHelpStringAsDisplay: true
        }
    },
    {
        name: "$.certificate",
        uiHint: {
            completionConfig: {
                actions: [
                    {
                        text: "Import",
                        viewerName: "CertificateImport",
                        rbaPath: 'device.certificate-management.certificates',
                        viewerProps: {
                            windowSize: "lg",
                            title: _T('Import Certificate'),
                            labelWidthSize: 3
                        }
                    }
                ]
            }
        }
    },
    {
        name: "$",
        childrenNames: ["name", "$.certificate", "ProtocolSettings"],

    }
];

const sslTlsServiceProfileColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        recordConfigPath: "$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.ssl-tls-service-profile.entry"
    }
];

const viewConfig = {
    widerForm : true,
    serviceName : 'Device/SSLTLSServiceProfiles',
    reduxId : 'sslTlsServiceProfile',
    header : 'SSL/TLS Service Profile',
    columns : sslTlsServiceProfileColumns,
    fields : sslTlsServiceProfileFields,
    rbaPath: 'device.certificate-management.ssl-tls-service-profile',
    recordConfigPath : "$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.ssl-tls-service-profile.entry",
    className: 'SSLTLSServiceProfile'
}

export default class SSLTLSServiceProfile extends TPLBaseViewer {
	constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getTPLRecordViewer);
