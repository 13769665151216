import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Collapse, Button } from "reactstrap";
import classnames from 'classnames';
import { getBuilder } from '../../../utils/BuilderManager';
import { FormContext, FormConsumer } from '../../FormContext';
import { getPathValue, getPathValues } from "../../../utils/json";
import { capitalizeLetter } from "../../schema/utils";
import './SummaryBuilder.scss';
import { BaseBuilder } from '../BaseBuilder';
import Pan from '../../schema/Pan';
import { getStoreState } from "../../../utils/storeRegistry";
import { unsavedChangesWarning, unsavedChangesDialogTitle } from "../../../../constants";
import { showModal, hideModal } from "../../../services/actions";
import { INJECTED_REC_ID } from "../../widgets/util";
import { ErrorBoundary } from "../../../utils/ErrorBoundary";

export class SummaryBuilder extends BaseBuilder {
    constructor(props) {
        super(props);
        let stageNameList = props.field.childrenNames;
        let collapses = stageNameList.map(name => true);

        this.state = {
            collapses: collapses
        };
    }

    toggle = (idx) => {
        this.setState(state => ({ collapses: state.collapses.map((collapse, i) => i === idx ? !state.collapses[i] : state.collapses[i]) }));
    }

    /**
     * Find field from fields list
     */
    findStepField = (fieldName) => {
        let index = this.props.fields.findIndex((f) => {
            return f.name === fieldName;
        });
        return this.props.fields[index];
    }

    /**
     * Flatten out all fields include their children
     */
    flattenFieldIncludeChildren = (fields) => {
        const flat = [];
        const fieldsData = [];
        let { formData } = this.context;

        fields.childrenNames.forEach(fieldName => {
            let field = this.findStepField(fieldName);
            if (!Pan.isEmpty(field.childrenNames)) {
                let flattenChildren = this.flattenFieldIncludeChildren(field);
                flat.push(...flattenChildren.flat);
                fieldsData.push(...flattenChildren.fieldsData);
            } else {
                if (field.uiHint.hideLabelInSummary) {
                    return;
                }

                // support displayLabel
                let fieldLabel = this.getFieldLabel(field);

                if (fieldName.endsWith('member.*')) {
                    let objs = getPathValues(formData, fieldName) || [];
                    let vals = [];
                    if (objs.length > 0) {
                        objs.map(obj => {
                            let val = obj['value'] || obj;
                            if (val[INJECTED_REC_ID]) val = '';
                            vals.push(val);
                        });
                    }
                    if (fieldLabel && !Pan.isEmpty(vals)) {
                        flat.push({ [fieldLabel]: vals });
                        fieldsData.push(field);
                    }
                } else {
                    let val = this.getPathValue(formData, field) || '';
                    const dataSource = field.uiHint.fakeSchemaDataSource;
                    if (Pan.isEmpty(dataSource) && fieldLabel && val) {
                        flat.push({ [fieldLabel]: val });
                        fieldsData.push(field);
                    } else {
                        const dataMapping = field.uiHint.dataMapping;
                        const dataBaseOn = field.uiHint.dataBaseOn;
                        const dataBaseOnObject = dataBaseOn && _.get(formData, dataBaseOn.substring(2));
                        if (Pan.isEmpty(val) && !Pan.isEmpty(dataSource) && Pan.isEmpty(dataBaseOnObject)) {
                            const fakeDataHelpStringKey = _.get(formData, dataSource.substring(2));
                            const fakeSchemaLabelMapping = field.uiHint.fakeSchemaLabelMapping;
                            const encoderSelectedData = this.props.fields.filter(field => field.name === dataSource)[0].uiHint.helpStrings[fakeDataHelpStringKey];
                            if (encoderSelectedData) {
                                const decoderSelectedData = JSON.parse(decodeURIComponent(encoderSelectedData));
                                const dataObject = decoderSelectedData[dataMapping];
                                Object.keys(dataObject).map(key => {
                                    const label = fakeSchemaLabelMapping && fakeSchemaLabelMapping[key] || capitalizeLetter(key);
                                    if (Number.isInteger(dataObject[key])) {
                                        flat.push({ [label]: dataObject[key].toString() });
                                    } else {
                                        flat.push({ [label]: dataObject[key] });
                                    }
                                    fieldsData.push(field);
                                });
                            }
                        }
                    }
                }
            }
        });

        return { 'flat': flat, 'fieldsData': fieldsData };
    }

    getFieldLabel = (field) => {
        // support displayLabel
        let fieldLabel = field.uiHint.fieldLabel;
        if (field.uiHint.displayLabel !== undefined) {
            fieldLabel = field.uiHint.displayLabel;
        }
        if (field.uiHint.useStoreLabel !== undefined) {
            fieldLabel = field.uiHint.useStoreLabel;
        }
        return fieldLabel;
    }

    getPathValue = (formData, field) => {
        let getPathValueFn = getPathValue;
        if (field.uiHint.getDisplayValue) {
            getPathValueFn = field.uiHint.getDisplayValue;
        }
        let val = getPathValueFn(formData, field.name) || '';
        if (Pan.isObject(val) && Pan.isDefined(val.flat)) {
            // if val is of format { 'flat': flat, 'fieldsData': fieldsData }
            val = this.renderArrayObject(val, field.uiHint.lineBreak);
        }
        return val;
    }

    /**
     * Return stage name from stages map defined inside SummaryBuilder uiHint
     */
    findStage = (name) => {
        const { field } = this.props;
        const stages = field.uiHint.stages;
        return stages[name];
    }

    findStageNameList = () => {
        const { field } = this.props;
        const childrenNames = field.childrenNames;
        return childrenNames.map(name => this.findStage(name));
    }

    upcaseFirstLetter = (str) => {
        if (!Pan.isString(str)) {
            return '';
        }
        str = str.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
        return str;
    }

    renderItem = (label, value, firstLetterUpper = true) => {
        if (Pan.isEmpty(label)) {
            return <span key={Pan.id()} className="d-inline-block">{value}</span>
        }
        if (firstLetterUpper === false) {
            return (
                <span key={Pan.id()} className="d-inline-block"><b>{label + ':  '}</b>{value} </span>
            );
        }
        return (
            <span key={Pan.id()} className="d-inline-block"><b>{this.upcaseFirstLetter(label) + ':  '}</b>{value} </span>
        );
    }

    /**
     * Rendering an array of values
     */
    renderArray = (strArray) => {
        return strArray.reduce((acc, cur) => {
            return acc + ', ' + cur;
        });
    }

    /**
     * Rendering an array object of any kind
     */
    renderArrayObject = (arrayObject, lineBreak = false) => {
        const { flat, fieldsData } = arrayObject;
        return flat.map((obj, idx) => {
            let key = this.upcaseFirstLetter(Object.keys(obj)[0]);
            let val = Object.values(obj)[0];
            let ITEM = '';
            if (Pan.isEmpty(val)) {
                ITEM = this.renderItem(key, "None", false);
            } else if (Pan.isArray(val)) {
                ITEM = this.renderItem(key, this.renderArray(val), false);
            } else if (fieldsData[idx] && fieldsData[idx].type === 'password') {
                ITEM = this.renderItem(key, '******', false);
            } else {
                ITEM = this.renderItem(key, val, false);
            }
            return lineBreak ? <div>{ITEM}<br /></div> : ITEM;
        });
    }

    /**
     * Rendering column => a cell of one step (stageIdx, index)
     */
    renderCol = (formData, stepName) => {
        let childrenNames = this.findStepField(stepName)['childrenNames'] || [];
        let renderArray = childrenNames.map(name => {
            const field = this.findStepField(name);

            // using a builder
            if (field.uiHint.builderInSummary) {
                const Builder = getBuilder({
                    name,
                    fields: this.props.fields,
                    builder: field.uiHint.builderInSummary
                });
                const from = field.uiHint.from
                return (
                    <Builder
                        key={name}
                        name={name}
                        field={field}
                        from={from}
                    />
                );
            }

            const label = this.getFieldLabel(field);
            let val = this.getPathValue(formData, field) || '';

            // Name field
            if (name == 'name') {
                if (formData['@name']) {
                    const value = formData['@name'] || '';
                    return this.renderItem(label, value);
                }
            }

            // Use data from redux
            if (field.uiHint.useStoreData) {
                let vals = getPathValues(getStoreState(), field.uiHint.useStoreData);
                if (vals && vals.length !== 0) {
                    return this.renderItem(field.uiHint.useStoreLabel, this.renderArray(vals));
                }
            }

            // Leaf field
            if (!field.childrenNames) {
                if (val) {
                    const { actionMap } = this.props;
                    const { useLinkInSummary } = field.uiHint;
                    // useLinkInSummary
                    if (useLinkInSummary) {
                        const text = Pan.isFunction(useLinkInSummary.text) ? useLinkInSummary.text(this.context.formData, field) : useLinkInSummary.text;
                        return (
                            <span className='d-inline-block' key={Pan.id()}>
                                <b>{field.uiHint.displayLabel + ': '}</b> {this.upcaseFirstLetter(val)} <br />
                                <a className="rowLink" onClick={() => actionMap[useLinkInSummary.atype](...useLinkInSummary.params)}>
                                    {text}
                                </a>
                            </span>
                        );
                    }
                    // password field
                    if (this.findStepField(name).type === 'password') {
                        return this.renderItem(label, '******');
                    }
                    return this.renderItem(label, val);
                }
            } else if (field.uiHint.showSummaryLabel) {
                // SummaryLabel field
                if (val == '') {
                    return this.renderItem(label, 'None');
                }
                return this.renderItem('[' + label + ']', this.renderArrayObject(this.flattenFieldIncludeChildren(field)));
            } else {
                // if field contains childrenNames and getDisplayValue function, we should use it
                if (field.uiHint.getDisplayValue) {
                    return this.renderItem(label, val);
                }

                let html = this.renderArrayObject(this.flattenFieldIncludeChildren(field));
                if (html.length == 0) {
                    return (
                        <></>
                    );
                }
                return (
                    <span key={Pan.id()}>
                        {html}
                    </span>
                );
            }
        });

        childrenNames.forEach((name, index) => {
            if (this.findStepField(name).uiHint.showSummaryLastOrder) {
                let element = renderArray[index];
                renderArray.splice(index, 1);
                renderArray.splice(renderArray.length - 1, 0, element);
            }
        });

        return renderArray;
    }

    onJumpToRoute(field) {
        let { formData, formDataBackup } = this.context;
        let actionButtons = [];
        actionButtons.push({
            text: "Cancel",
            color: "secondary",
            action: () => {
                hideModal("JumpToRouteWarning");
            }
        });
        actionButtons.push({
            text: "Save",
            color: "primary",
            action: (e) => {
                hideModal("JumpToRouteWarning");
                this.props.onSubmit(formData, formDataBackup);
            }
        });
        showModal({
            id: "JumpToRouteWarning",
            open: true,
            size: "md",
            toggle: () => {
                hideModal("JumpToRouteWarning");
            },
            title: unsavedChangesDialogTitle,
            message: field.uiHint && field.uiHint.jumpToRouteWarning || unsavedChangesWarning,
            actions: actionButtons
        });
    }


    /**
     * Rendering row
     */
    renderRow = (field, childrenNames, stagelist) => {
        let { formData } = this.context;
        let currentStage = this.findStage(field.name);
        let stageIdx = stagelist.indexOf(currentStage);
        const collapsible = field.uiHint.stageCollapseTitle || field.uiHint.stageCollapseStyle;
        const dataInStore = field.uiHint.dataInStore && field.uiHint.dataInStore();

        // If stage configured or data in redux store
        if (Object.keys(formData).indexOf(currentStage) !== -1 || dataInStore) {
            return childrenNames.map((name, index) => {
                const currentField = this.findStepField(name);
                const showRow = currentField.childrenNames !== undefined;
                const showEditInSummary = (currentField.uiHint.hideEditInSummary !== true);
                const jumpToRouteFromSummary = (currentField.uiHint.jumpToRouteFromSummary === true);
                const jumpToRouteButtonText = jumpToRouteFromSummary && (currentField.uiHint.jumpToRouteButtonText || 'Go');
                let columnWidths = collapsible ? [3, 8, 1] : [0, 10, 2];

                if (showRow) {
                    return (
                        <Row key={index} className="summary-row m-0">
                            {collapsible &&
                                <Col xs={columnWidths[0]} className="summary-table-first-col" scope="row">
                                    <Row className="m-0">
                                        <Col xs="3">
                                            {<b>Step {index + 1}</b>}
                                        </Col>
                                        <Col xs="9">
                                            {currentField.uiHint.stepTitle}
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col xs={columnWidths[1]} className="summary-table-content-col">
                                {this.renderCol(formData, name)}
                            </Col>
                            {showEditInSummary &&
                                <Col xs={columnWidths[2]} className="edit-button">
                                    <Button
                                        type="button"
                                        size="sm"
                                        onClick={() => this.props.onEdit(index, stageIdx)}
                                        id={`edit-button-${index}`}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            }
                            {jumpToRouteFromSummary && currentField.uiHint.jumpToRoute &&
                                <Col xs={columnWidths[2]} className="edit-button go-button">
                                    {Pan.isEmpty(this.props.formState) ?
                                        <Button
                                            size="sm"
                                            tag={NavLink} to={currentField.uiHint.jumpToRoute}
                                            id={`go-button-${index}`}
                                        >
                                            {jumpToRouteButtonText}
                                        </Button> :
                                        <Button
                                            type="button"
                                            size="sm"
                                            onClick={() => this.onJumpToRoute(currentField)}
                                            id={`go-button-${index}`}
                                        >
                                            {jumpToRouteButtonText}
                                        </Button>}
                                </Col>
                            }
                        </Row>
                    );
                }
                return null;
            });
        }
    }

    renderStageTitleCol = (field, fieldIdx, colWidth, stageIdx, configured) => {
        const { actionMap } = this.props;
        const { useLinkInSummary, stageTitle } = field.uiHint;

        return (
            <Col xs={colWidth}>
                <div className="stageMainTitle">
                    <div className="numberCircle">{fieldIdx + 1}</div>
                    <div className="stageTitle" onClick={() => this.toggle(stageIdx)}>
                        {stageTitle}
                        {
                            configured && field.uiHint.stageCollapseTitle &&
                            <span className={classnames('arrow', { active: !this.state.collapses[stageIdx] })}>
                                <span></span>
                                <span></span>
                            </span>
                        }
                        {useLinkInSummary &&
                            <div className="link">
                                <p>
                                    <a onClick={() => actionMap[useLinkInSummary.atype](...useLinkInSummary.params)}>
                                        {useLinkInSummary.text}
                                    </a>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </Col>
        );
    };

    renderStageSetupButton = (stageIdx, colWidth) => {
        return (
            <Col xs={colWidth} className="edit-button">
                <Button
                    id={`setup-button-${stageIdx}`} type="button" size="sm"
                    onClick={() => this.props.onSetup(stageIdx)}
                >
                    Set Up
                </Button>
            </Col>
        );
    }

    renderStageCollapsibleTitle = (field, stageIdx) => {
        return (
            <div className="collapse-title" onClick={() => this.toggle(stageIdx)}>
                <span className="stage-collapse-title">
                    {field.uiHint.stageCollapseTitle}
                </span>
                <span className={classnames('arrow', { active: !this.state.collapses[stageIdx] })}>
                    <span></span>
                    <span></span>
                </span>
            </div>
        );
    }

    renderStage = (childrenNames, stagelist) => {
        return childrenNames.map((name, index) => {
            const { formData } = this.context;
            // Changes for ADI-1546
            // let localAuth = formData && formData['stage2'] && formData['stage2']['local-auth'];
            // console.log('localAuth', localAuth);
            const field = this.findStepField(name);
            const currentStage = this.findStage(name);
            const stageIdx = stagelist.indexOf(currentStage);
            const dataInStore = field.uiHint.dataInStore && field.uiHint.dataInStore();

            const configured = (Object.keys(formData).indexOf(currentStage) !== -1 || dataInStore);

            if (field.uiHint.stageCollapseTitle || field.uiHint.stageCollapseStyle) {
                return (
                    <Container className="summary-table" key={name}>
                        <Row className="summary-row">
                            {this.renderStageTitleCol(field, index, 3, stageIdx, configured)}
                            <Col>
                                <Row className="summary-row">
                                    {configured && !this.state.collapses[stageIdx] &&
                                        <Col xs="10" className="summary-table-content-col">
                                            <b>Configured</b>
                                        </Col>
                                    }
                                    {!configured && <Col xs="10"></Col>}
                                    {!configured && this.renderStageSetupButton(stageIdx, 2)}
                                </Row>
                            </Col>
                        </Row>
                        {/* {configured && field.uiHint.stageCollapseTitle && this.renderStageCollapsibleTitle(field, stageIdx)} */}
                        <ErrorBoundary>
                            <Collapse isOpen={this.state.collapses[stageIdx]}>
                                {this.renderRow(field, field.childrenNames, stagelist)}
                            </Collapse>
                        </ErrorBoundary>
                    </Container>
                );
            } else {
                return (
                    <Container className="summary-table" key={name}>
                        <Row className="summary-row">
                            {this.renderStageTitleCol(field, index, 3, stageIdx, configured)}
                            <Col>
                                {this.renderRow(field, field.childrenNames, stagelist)}
                                <Row className="summary-row">
                                    <Col xs="10"></Col>
                                    {!configured && this.renderStageSetupButton(stageIdx, 2)}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                );
            }
        });
    }

    renderCancelButton = () => {
        let { formData, formDataBackup } = this.context;
        return (
            <Button
                id="commit-button" type="button" color="secondary"
                onClick={() => this.props.onCancel(formData, formDataBackup)}
            >
                Cancel
            </Button>
        );
    }

    renderSaveButton = () => {
        let { formData, formDataBackup } = this.context;
        return (
            <Button
                id="commit-button" type="button" color="primary"
                onClick={() => this.props.onSubmit(formData, formDataBackup)}
            >
                Save
            </Button>
        );
    }

    renderActionButtons = (actions) => {
        if (!actions) {
            return <div />
        }

        const { actionMap } = this.props;
        return actions.map((btn, index) => {
            const actionFn = actionMap[btn.atype];
            return (
                <Button
                    key={index} type="button" color="primary"
                    onClick={() => actionFn(...btn.params)}
                >
                    {btn.text}
                </Button>
            )
        });
    }

    renderChildren = () => {
        const { field } = this.props;
        const childrenNames = field.childrenNames;
        if (!childrenNames) {
            return (
                <div />
            );
        }

        let { formData } = this.context;
        const stagelist = this.findStageNameList();
        return (
            <React.Fragment>
                <div className="summary-title">
                    {formData['@name'] || ''}
                </div>
                {field.uiHint.actions &&
                    <div className="action-buttons d-flex flex-row bg-white" test_id={field.attrPath}>
                        {this.renderActionButtons(field.uiHint.actions)}
                    </div>
                }
                <div className="summary-body" test_id={field.attrPath}>
                    {this.renderStage(childrenNames, stagelist)}
                </div>
                <div className="action-buttons d-flex justify-content-between bg-white" test_id={field.attrPath}>
                    {this.renderCancelButton()}
                    {this.props.showSave && this.renderSaveButton()}
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <ErrorBoundary>
                <React.Fragment>
                    <FormConsumer>
                        {() => this.renderChildren()}
                    </FormConsumer>
                </React.Fragment>
            </ErrorBoundary>
        );
    }
}

SummaryBuilder.contextType = FormContext;

SummaryBuilder.defaultProps = {
    field: {}
};

if (process.env.NODE_ENV !== "production") {
    SummaryBuilder.propTypes = {
        field: PropTypes.object
    };
}
