import React from 'react';
import { ViewerManager, Pan, setPathValue } from 'ui-lib';
import TPLBaseViewer from '../../template/TPLBaseViewer';
import getTPLRecordViewer from "../../template/TPLRecordViewer";

const _T = str => str;

const certificateProfileFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name')
        }
    },
    {
        name: '$.username-field',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.username-field.subject',
        saveMap: function (formData, value) {
            if (value !== false) {
                setPathValue(formData, "$.username-field.subject", "common-name");
            }
        },
        uiHint: {
            builder: (field) => {
                return (field) => {
                    return <div class="col-9 form-control">common-name</div>
                }
            },
            allowBlankValidation: true
        }
    }, {
        name: '$.username-field.subject-alt',
        uiHint: {
            allowBlankValidation: true
        }
    }, {
        name: '$.domain'
    }, {
        name: '$.use-crl',
        uiHint: {
            fieldLabel: _T('Use CRL')
        }
    }, {
        name: '$.use-ocsp',
        uiHint: {
            fieldLabel: _T('Use OCSP'),
            showHelpString: _T('OCSP takes precedence over CRL')
        }
    }, {
        name: '$.crl-receive-timeout',
        uiHint: {
            fieldLabel: _T('CRL Receive Timeout')
        }
    }, {
        name: '$.ocsp-receive-timeout',
        uiHint: {
            fieldLabel: _T('OCSP Receive Timeout')
        }
    }, {
        name: '$.cert-status-timeout',
        uiHint: {
            fieldLabel: _T('Certificate Status Timeout')
        }
    }, {
        name: '$.block-unknown-cert',
        uiHint: {
            fieldLabel: _T('Block session if certificate status is unknown')
        }
    }, {
        name: '$.block-timeout-cert',
        uiHint: {
            fieldLabel: _T('Block session if certificate status cannot be retrieved within timeout')
        }
    }, {
        name: '$.block-unauthenticated-cert',
        uiHint: {
            fieldLabel: _T('Block session if the certificate was not issued to the authenticating device')
        }
    },
    {
        name: '$.block-expired-cert',
        uiHint: {
            fieldLabel: _T('Block sessions with expired certificates')
        }
    }, {
        name: '$.CA.entry',
        uiHint: {
            fieldLabel: _T('CA Certificates'),
            hasGridFilter: false,
            isOrdered: true,
            height: 150,
            showHelpString: _T('Default OCSP URL (must start with http:// or https://)'),
            dialogSize: 'md',
            useColumns: [
                '$.CA.entry.*.@name',
                '$.CA.entry.*.default-ocsp-url',
                '$.CA.entry.*.ocsp-verify-cert'
            ]
        }
    }, {
        name: '$.CA.entry.*.default-ocsp-url',
        uiHint: {
            fieldLabel: _T('Default OCSP URL'),
            regexText: _T('Invalid URL (must start with http:// or https://)')
        }
    }, {
        name: '$.CA.entry.*.ocsp-verify-cert',
        uiHint: {
            fieldLabel: _T('OCSP Verify Certificate'),
            columnConfig: {
                headerName: _T('OCSP Verify Certificate')
            }
        }
    }, {
        name: '$.CA.entry.*',
        childrenNames: [
            '$.CA.entry.*.@name',
            '$.CA.entry.*.default-ocsp-url',
            '$.CA.entry.*.ocsp-verify-cert'
        ],
        uiHint: {
            labelWidthSize: 5
        }
    }, {
        name: '$.CA.entry.*.@name',
        uiHint: {
            fieldLabel: _T('CA Certificate'),
            columnConfig: {
                headerName: _T('Name')
            },
            completionConfig: {
                actions: [
                    {
                        text: 'Import',
                        rbaPath: 'device.certificate-management.certificates',
                        viewerName: 'CertificateImport',
                        viewerProps: {
                            windowSize: 'lg',
                            title: _T('Import Certificate'),
                            labelWidthSize: 3
                        }

                    }
                ]
            }
        }
    }, {
        name: 'CheckBoxes',
        childrenNames: [
            '$.use-crl',
            '$.use-ocsp'
        ]
    }, {
        name: 'CheckBoxes2',
        childrenNames: [
            '$.block-unknown-cert',
            '$.block-timeout-cert',
            '$.block-unauthenticated-cert',
            '$.block-expired-cert'
        ]
    }, {
        name: 'TextFields',
        childrenNames: [
            '$.crl-receive-timeout',
            '$.ocsp-receive-timeout',
            '$.cert-status-timeout'
        ]
    }, {
        name: '$',
        childrenNames: [
            'name',
            '$.CA.entry',
            'AdvancedFields'
        ],
        uiHint: {
            labelWidthSize: 3
        }
    }, {
        name: 'AdvancedFields',
        childrenNames: [
            '$.username-field',
            '$.domain',
            'Options'
        ],
        uiHint: {
            builder: 'CollapseContainerBuilder'
        }
    }, {
        name: 'Options',
        saveMap: undefined,
        childrenNames: [
            'CheckBoxes',
            'TextFields',
            'CheckBoxes2'
        ],
        uiHint: {
            hideLabel: true
        }
    }
];

const certificateProfileColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.certificate-profile.entry'
}];

const viewConfig = {
    serviceName: 'Device/CertificateProfiles',
    reduxId: 'certificateProfile',
    header: 'Certificate Profile',
    columns: certificateProfileColumns,
    fields: certificateProfileFields,
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.certificate-profile.entry',
    rbaPath: 'device.certificate-management.certificate-profile',
    className: 'CertificateProfile'
}
export default class CertificateProfile extends TPLBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
        this.widerForm = true;
    }
}

ViewerManager.registerViewConfig(viewConfig, getTPLRecordViewer);