import React, { Component } from 'react';
import getGPCSRecordViewer from './GPCSRecordViewer';
import { GPCS_COMMON_TPL } from 'service-lib';
import ExportCert from './ExportCert';
import { get } from "lodash"
import { getStore, getPathValue, getStoreState, ViewerDisplayActionBuilder } from "ui-lib";
import {
	FORWARD_TRUST_CERT_NAME,
	markCertAsForwardTrust,
	FORWARD_TRUST_CERT_NAME_ECDSA
} from "../main/actions";

const _T = str => str;

window.__pan_schema.config.injected.forwardTrust = {
	"@attr": { "node-type": "sequence" },
	choiceTrust: {
		"@attr": { "node-type": "choice" },
		default: {
			"@attr": {
				"node-type": "sequence",
				"help-string": "Default forward trust certificate"
			}
		},
		custom: {
			"@attr": {
				"node-type": "sequence",
				"help-string": "Custom forward trust certificate"
			}
		}
	},
	cert: {
		"@attr": {
			"node-type": "element",
			type: "string",
			optional: "yes"
		}
	}
};

export default class ForwardTrustCert extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cert: {},
			record: false,
			GPCSRecordViewer: null
		};
	}

	getFields() {
		const tpl = get(this.props, "configLocation.tpl.name", GPCS_COMMON_TPL);
		return [
			{
				name: "$",
				childrenNames: ["$.choiceTrust"]
			},
			{
				name: "$.cert",
				uiHint: {
					fieldLabel: _T("Forward Trust Certificate")
				}
			},
			{
				name: "$.choiceTrust",
				uiHint: {
					fieldLabel: _T("Forward Trust"),
					association: {
						fields: ["$.choiceTrust", "$.default", "$.custom"],
						updateFormDataAsync: function (values, formData, filters, key) {
							if (!values[2]) {
								return new Promise(function (resolve, reject) {
									let store = getStore();
									store.dispatch(
										markCertAsForwardTrust(FORWARD_TRUST_CERT_NAME, {
											tpl: { name: GPCS_COMMON_TPL }
										})
									);
									store.dispatch(
										markCertAsForwardTrust(FORWARD_TRUST_CERT_NAME_ECDSA, {
											tpl: { name: GPCS_COMMON_TPL }
										})
									);
									resolve({ custom: {} });
								});
							} else {
								return new Promise(function (resolve, reject) {
									resolve([]);
								});
							}
						}
					}
				}
			},
			{
				name: "$.default",
				childrenNames: ["ExportCert"]
			},
			{
				name: "$.custom",
				childrenNames: ["ExportImportCert"]
			},
			{
				name: "ExportCert",
				uiHint: {
					builder: field => {
						return () => {
							let certName = this.getCertName();
							return (
								<div>
									<div>
										<ExportCert
											modalTitleText={_T("Export this Forward Trust CA certificate")}
											modaleMessageText={_T(
												"This is the CA certificate that user should install on their endpoint and trust."
											)}
											confirmationModalEnabled
											certificateNames={[this.state.cert["rsa"], this.state.cert["ecdsa"]]}
											certLocation="api/config/v9.0/Device/CertificateManagement/ExportCertificate"
										/>
									</div>
									<span className="btn indent-align-col-form-label">{certName}</span>
								</div>
							);
						};
					}
				}
			},
			{
				name: "ExportImportCert",
				attrPath: "$.custom.ImportCert",
				uiHint: {
					builder: field => {
						return () => {
							let certName = this.getCertName() || "Import Certificate";
							return (
								<div>
									<div>
										<ExportCert
											modalTitleText={_T("Export this Forward Trust CA certificate")}
											modaleMessageText={_T(
												"This is the CA certificate that user should install on their endpoint and trust."
											)}
											confirmationModalEnabled
											certificateNames={[this.state.cert["rsa"], this.state.cert["ecdsa"]]}
											certLocation="api/config/v9.0/Device/CertificateManagement/ExportCertificate"
										/>
										<ViewerDisplayActionBuilder
											field={{
												uiHint: {
													rbaPath: "device.certificate-management.certificates",
													viewerName: "CertificateImport",
													actionText: "Import",
													actionProps: {
														className: "indent-align-col-form-label"
													},
													showViewer: true,
													viewerProps: {
														windowSize: "lg",
														title: _T("Import Certificate"),
														labelWidthSize: 3,
														mode: "form",
														configLocation: { tpl: { name: tpl } },
														fetchAfterSuccess: false,
														afterSubmit: record => {
															if (record && record.name) {
																//set this certificate are forward trust
																let store = getStore();
																store.dispatch(
																	markCertAsForwardTrust(record.name, { tpl: { name: tpl } })
																);
															}
														}
													}
												}
											}}
										/>
									</div>
									<span className="btn indent-align-col-form-label">{certName}</span>
								</div>
							);
						};
					}
				}
			}
		];
	}

	/**
	 * set state for SSL decryption based on cert type
	 * @param {object} cert - object from redux store
	 */
	setCertType = cert => {
		let store = getStore();
		let record = {};
		if (
			cert["rsa"] &&
			cert["rsa"] === FORWARD_TRUST_CERT_NAME &&
			cert["ecdsa"] &&
			cert["ecdsa"] === FORWARD_TRUST_CERT_NAME_ECDSA
		) {
			//default cert should be both RSA and EDCSA
			record = { default: {}, custom: {} };
		} else if (cert["rsa"] || cert["ecdsa"]) {
			//custom cert
			record = {
				custom: {
					ImportCert: ""
				}
			};
		} else {
			//no forward trust cert
			//set the default as forward trust
			store.dispatch(
				markCertAsForwardTrust(FORWARD_TRUST_CERT_NAME, {
					tpl: { name: GPCS_COMMON_TPL }
				})
			);
			store.dispatch(
				markCertAsForwardTrust(FORWARD_TRUST_CERT_NAME_ECDSA, {
					tpl: { name: GPCS_COMMON_TPL }
				})
			);
			record = { default: {}, custom: {} };
		}
		this.setState({
			record
		})
	};

	getCertName = () => {
		let cert = getPathValue(
			getStoreState(),
			"gpcs.sslDecryptionDetails.forward-trust-certificate"
		);
		let certName = "";
		if (
			cert["rsa"] &&
			cert["rsa"] === FORWARD_TRUST_CERT_NAME &&
			cert["ecdsa"] &&
			cert["ecdsa"] === FORWARD_TRUST_CERT_NAME_ECDSA
		) {
			certName = FORWARD_TRUST_CERT_NAME + " (RSA) / " + FORWARD_TRUST_CERT_NAME_ECDSA + " (ECDSA)";
		} else if (cert["rsa"] || cert["ecdsa"]) {
			if (cert["ecdsa"]) {
				certName = cert["ecdsa"] + " (ECDSA)";
			}
			if (cert["rsa"]) {
				if (certName === "") {
					certName = cert["rsa"] + " (RSA)";
				} else {
					certName = certName + " / " + cert["rsa"] + " (RSA)";
				}
			}
		}
		return certName;
	}

	componentDidMount() {
		let cert = getPathValue(
			getStoreState(),
			"gpcs.sslDecryptionDetails.forward-trust-certificate"
		);
		this.setState({
			GPCSRecordViewer: getGPCSRecordViewer("", "", "", "forwardTrust"),
			cert: cert
		});
		this.setCertType(cert);
	}

	render() {
		let GPCSRecordViewer = this.state.GPCSRecordViewer;
		return (
			GPCSRecordViewer &&
			this.state.record && (
				<GPCSRecordViewer
					configLocation={this.props.configLocation}
					fetchRecord={false}
					rbaPermission="enable"
					locationPermission="enable"
					panSchema={window.__pan_schema}
					recordConfigPath="$.config.injected.forwardTrust"
					inputFields={this.getFields()}
					hideOk={true}
					hideCancel={true}
					header=""
					record={this.state.record}
				/>
			)
		);
	}
}
