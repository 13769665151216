import { getPathValue } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const wildFireAnalysisFields = [
	{
		type: 'string',
		name: 'name',
		mapping: '@name',
		uiHint: {
			fieldLabel: _T('Name')
		}
	}, {
		name: '$.rules.entry.*.@name',
		uiHint: {
			fieldLabel: _T('Rule Name'),
			columnConfig: {
				type: 'clickableColumn'
			}
		}
	}, {
		name: '$.rules.entry',
		uiHint: {
			editorType: 'record-form',
			dialogSize: 'lg',
			useColumns: [
				'$.rules.entry.*.@name',
				'$.rules.entry.*.analysis',
				'$.rules.entry.*.application',
				'$.rules.entry.*.direction',
				'$.rules.entry.*.file-type',
			]
		}
	}, {
		name: '$.rules.entry.*.direction',
		defaultValue: 'both',
		uiHint: {
			allowBlank: false
		}
	}, {
		name: '$.rules.entry.*.analysis',
		uiHint: {
			fieldLabel: 'Use Public Cloud Analysis',
			allowBlank: false,
			enum: [
				['public-cloud', 'public-cloud']
			]
		}
	}, {
		name: '$',
		childrenNames: [
			'name',
			'$.description',
			'$.rules.entry'
		]
	}
];

const wildfireAnalysisObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
	recordConfigPath: '$.config.devices.entry.vsys.entry.profiles.wildfire-analysis.entry',
	minWidth: 100
}, {
	headerName: 'Location',
	field: '@loc',
	minWidth: 150
}, {
	headerName: 'Rule Name',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['@name']);
	},
	minWidth: 100
}, {
	headerName: 'Applications',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['application']['member'].join(','));
	},
	minWidth: 150
}, {
	headerName: 'File Types',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['file-type']['member'].join(','));
	},
	minWidth: 100
}, {
	headerName: 'Direction',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['direction']);
	},
	minWidth: 100
}, {
	headerName: 'Analysis',
	valueGetter: params => {
		let rules = getPathValue(params.data, 'rules.entry') || [];
		return rules.map(rule => rule['analysis']);
	},
	minWidth: 100
}];

export default class WildFireAnalysis extends DGBaseViewer {

	serviceName = 'Objects/WildFireAnalysisSecurityProfiles';
	reduxId = 'wildFireAnalysisObjects';
	header = 'WildFire Analysis';
	widerForm = true;
	columns = wildfireAnalysisObjectColumns;
	fields = wildFireAnalysisFields;
	recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.wildfire-analysis.entry';
	rbaPath = 'objects.security-profiles.wildfire-analysis';
}
