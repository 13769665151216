import React from "react";

const defaultButton = props => <button {...props}>{props.children}</button>;

const regex = /^(\s*|\d+)$/;

class Pagination extends React.Component {
	constructor(props) {
		super();
		this.state = {
			currentPage: 1
		};
	}

	render() {
		const { PageButtonComponent = defaultButton, pages: totalPages } = this.props;
		const { currentPage } = this.state;

		return (
			<div className="pagination-control">
				<span>Page</span>
				<input
					className="form-control form-control-sm page-input form-control-outline"
					value={currentPage}
					onChange={e => {
						const value = e.target.value;
						if (regex.test(value)) {
							const nextPageNumber = parseInt(value);
							if (isNaN(nextPageNumber)) {
								this.setState({currentPage: value});
							} else if (1 <= nextPageNumber && nextPageNumber <= totalPages){
								this.setState({currentPage: nextPageNumber});
								this.props.changePage(nextPageNumber);
							}
						}
					}}
					size="2"
				></input>
				<span className="full-count">of {totalPages}</span>

				<PageButtonComponent
					className="prev-page btn btn-secondary btn-icon-only"
					onClick={() => {
						if (currentPage <= 1) return;
						this.setState({currentPage: currentPage - 1});
						this.props.changePage(currentPage - 1);
					}}
					disabled={currentPage <= 1}
				></PageButtonComponent>

				<PageButtonComponent
					className="next-page btn btn-secondary btn-icon-only"
					onClick={() => {
						if (currentPage >= totalPages) return;
						this.setState({currentPage: currentPage + 1});
						this.props.changePage(currentPage + 1);
					}}
					disabled={currentPage >= totalPages}
				></PageButtonComponent>
			</div>
		);
	}
}

export default Pagination;
