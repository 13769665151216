import React, { Component } from 'react';
import './App.css';
import Main from '../containers/main';
import { ViewerManager, ErrorBoundary } from 'ui-lib';

import { dgViews } from '../containers/deviceGroup';
import { tplViews } from '../containers/template';
import { gpcsViews } from '../routes';

export default class App extends Component {
    constructor(props) {
        super(props);
        ViewerManager.registerAll(dgViews);
        ViewerManager.registerAll(tplViews);
        ViewerManager.registerAll(gpcsViews);
    }

    render() {
        return (
            <ErrorBoundary>
                <Main />
            </ErrorBoundary>
        );
    }
}