import React from 'react';
import PropTypes from 'prop-types';
import { BaseInput } from '../BaseInput';
import './NumberWidget.scss';
export function NumberWidget(props) {
    return <BaseInput type="number" {...props} />;
}
NumberWidget.defaultProps = {
    required: false,
    disabled: false,
    readonly: false,
    autoFocus: false,
};
if (process.env.NODE_ENV !== 'production') {
    NumberWidget.propTypes = {
        value: PropTypes.any,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        error: PropTypes.string,
        className: PropTypes.string,
        min:PropTypes.number,
        max:PropTypes.number,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autoFocus: PropTypes.bool,
        showErrors: PropTypes.bool,
        onKeyDown: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        type: PropTypes.string,
        onFocus: PropTypes.func,
        options: PropTypes.shape({
            inputType: PropTypes.string,
            emptyValue: PropTypes.string,
        }),
    };
}