import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
} from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import './MapWidget.scss';

export class MapWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wrapperStyles : props.wrapperStyles
        }

        this.defaultFillColor = this.defaultFillColor.bind(this);
        this.hoverFillColor = this.hoverFillColor.bind(this);
        this.pressedFillColor = this.pressedFillColor.bind(this);
    }

    componentDidMount( ) {
        this.tooltipItem = document.getElementById('map-tooltip');        
    }

    defaultFillColor(geography) {
        if (this.props.defaultFillColor) {
            return this.props.defaultFillColor(geography);
        }
        return "#d9d9d9";
    }

    hoverFillColor(geography) {
        if (this.props.hoverFillColor) {
            return this.props.hoverFillColor(geography);
        }
        return "#d9d9d9";
    }

    pressedFillColor(geography) {
        if (this.props.pressedFillColor) {
            return this.props.pressedFillColor(geography);
        }
        return "#d9d9d9";
    }

    onClickMark = (area) => {
        if (this.props.onClickMark) {
            this.props.onClickMark(area);
        }
    }

    onMouseEnter = (region,evt) => {
        if(this.tooltipItem && region && this.props.showTooltip) {
            this.tooltipItem.style.left = evt.clientX + 'px';
            this.tooltipItem.style.top = evt.clientY + 'px';
            this.tooltipItem.style.opacity = 1;
            this.tooltipItem.style.display = 'block';
            this.tooltipItem.innerHTML = region.display_name;
        }
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(region.name);
        }
    }

    onMouseLeave = () => {
        this.tooltipItem.style.display='none';
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave();
        }
    }


    numToStr = (num) => {
        if (num < 10 && num > 0) {
            return '0' + num;
        } else {
            return num;
        }
    }

    showPollingStatusMarker = (data, region) => {
        let info = data.find(item => item === region.name);
        if (!info) {
            return <></>;
        } else {
            let tooltip = `m 100 140 l 10 -10 l 70 0 v -60 h -160 v 60 h 70 l 10 10`;
            let statusText = [];
            if (info.status === 'OK') {
                statusText.push(<image key={1} href="/images/dashboard/icon_green.svg" x="-15" y="-40" height="12" width="12"/>);
                statusText.push(<text key={2} textAnchor="middle" x="35" y="-30">OK</text>);
            } else {
                statusText.push(<image key={1} href="/images/dashboard/icon_yellow.svg" x="-15" y="-40" height="12" width="12"/>);
                statusText.push(<text key={2} textAnchor="middle" x="35" y="-30">In progress</text>);
            }
 
            return (
                <>
                    <path 
                        d={tooltip}
                        transform="translate(-100,-140)"
                        style={{
                            fill: "#e5e5e5",
                            opacity: 0.8
                        }}
                        stroke="#e2e2e2"
                    >
                    </path>   
                    <text textAnchor="middle" x="0" y={-50} fontWeight="bold">
                        {region.name}
                    </text>
                    <text textAnchor="middle" x="-40" y={-30} >
                        Status: 
                    </text> 
                    {statusText}
                </>
            ) 
        }
    }

    showStatusMarker = (data, region) => {
        if (data && data[region['name']]) {  
            let info = data[region['name']];
            let line = 0;
            if (info.OK !== 0) {
                line++;
            } 
            if (info.Warning !== 0) {
                line++;
            }
            if (info.Error !== 0) {
                line++;
            }
            let tooltip = `m 100 140 l 10 -10 l 70 0 v ${-(45 + line * 20)} h -160 v ${45 + line * 20} h 70 l 10 10`;
            let detla = (3 - line) * 20;
            let statusText = [];
            let visit = [];
            let pos = 0;
            for (let i = 0; i < line; i++) {
                if (info.OK !== 0 && !visit.includes('OK')) {
                    statusText.push(<image style={{cursor: 'pointer'}} key={i * 2} href="/images/dashboard/icon_green.svg" x="-15" y={-85 + pos * 20 + detla} height="12" width="12"/>);
                    statusText.push(<text style={{cursor: 'pointer'}} key={i * 2 + 1} textAnchor="start" x="0" y={-75 + pos * 20 + detla}>OK({info.OK})</text>); 
                    pos++;
                    visit.push('OK');
                    continue;
                }

                if (info.Warning !== 0 && !visit.includes('Warning')) {
                    statusText.push(<image style={{cursor: 'pointer'}} key={i * 2} href="/images/dashboard/icon_yellow.svg" x="-15" y={-85 + pos * 20 + detla} height="12" width="12"/>);
                    statusText.push(<text style={{cursor: 'pointer'}} key={i * 2 + 1} textAnchor="start" x="0" y={-75 + pos * 20 + detla}>Warning({info.Warning})</text>); 
                    pos++;
                    visit.push('Warning');
                    continue;
                }

                if (info.Error !== 0 && !visit.includes('Error')) {
                    statusText.push(<image style={{cursor: 'pointer'}} key={i * 2} href="/images/dashboard/icon_red.svg" x="-15" y={-85+ pos * 20 + detla} height="12" width="12"/>);
                    statusText.push(<text style={{cursor: 'pointer'}} key={i * 2 + 1} textAnchor="start" x="0" y={-75 + pos * 20 + detla}>Error({info.Error})</text>); 
                    pos++;
                    visit.push('Error')
                    continue;
                }

            }

            return (
                <>
                    <path 
                        d={tooltip}
                        transform="translate(-100,-140)"
                        style={{
                            fill: "#e5e5e5",
                            opacity: 0.8,
                            cursor: 'pointer'
                        }}
                        stroke="#e2e2e2"
                    >
                    </path>   
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-60" y={-95 + detla} fontWeight="bold">
                        {region.name}
                    </text>
                    <text style={{cursor: 'pointer'}} textAnchor="middle" x="-40" y={-75 + detla} >
                        Status: 
                    </text> 
                    {statusText}
                    {(this.props.header === 'Remote Networks' || this.props.header === 'Service Connections') && <text style={{cursor: 'pointer'}} textAnchor="start" x="-60" y="-18"> Tunnels: {info.total}</text>} 
                    {this.props.header === 'Mobile Users' && <text style={{cursor: 'pointer'}} textAnchor="start" x="-60" y="-18"> Users: {info.users || 0}</text>} 
                </>
            )
        } else {
            return <></>;
        }
    }

    showDetailStatusMarker = (data, region) => {
        let info = data.find(d => d.name === region.name);
        return (
            <>
                {info.icon === 'OK' && <image data-tip={info.tooltip} href="/images/dashboard/icon_green.svg" x="-9" y="0" height="18" width="18"/>}
                {info.icon === 'Warning' && <image data-tip={info.tooltip} href="/images/dashboard/icon_yellow.svg" x="-9" y="0" height="18" width="18"/>}
                {info.icon === 'Error' && <image data-tip={info.tooltip} href="/images/dashboard/icon_red.svg" x="-9" y="0" height="18" width="18"/>}
            </>
        )
    }

    showBlinkCircleMaker = (data,region)=>{
        return (<>
            {data.indexOf(region.name) >= 0 && <circle r="2.75" cx="0" cy="0" opacity="1" fill="#3e91e3"  filter="url(#f1)">
            <animate 
                attributeName="opacity"
                dur="1.5s"
                values="1; 0; 1"
                keyTimes="0; 0.5; 1"
                fill="freeze" 
                repeatCount="indefinite"
                id="circ-anim"/>
        </circle>}</>);
    }

    showCircleMarker = (data, region) => {
        let names = region.name.split(' ');
        let textColor = this.props.selectArea === region.name ? "white" : "black";
        let total = (data && data[region.name] && data[region.name].total) || 0;
        let choice = (data && data[region.name] && data[region.name].choice) || 0;
        let circleColor = choice != 0 ? '#a6c43a' : '#419ad5';
        if (names.length === 1 && total !== 0) {
            return (
                <>
                    <circle
                        cx={0}
                        cy={0}
                        r={40}
                        style={{
                        fill: circleColor,
                        stroke: circleColor,
                        strokeWidth: 3,
                        opacity: 0.4,
                        }}
                    />
                    <text textAnchor="middle" x="0" y="-5" fontWeight="bold" fill={textColor}>
                        {region.name}:
                    </text>
                    <text textAnchor="middle" x="0" y="15" fontWeight="bold" fill={textColor}>
                        {choice}/{total}
                    </text>
                </>
            )
        } else if (names.length === 2 && total !== 0) {
            return (
                <>
                    <circle
                        cx={0}
                        cy={0}
                        r={40}
                        style={{
                        fill: circleColor,
                        stroke: circleColor,
                        strokeWidth: 3,
                        opacity: 0.4,
                        }}
                    />
                    <text textAnchor="middle" x="0" y="-20" fontWeight="bold" fill={textColor}>
                        {names[0]}
                    </text>
                    <text textAnchor="middle" x="0" y="0" fontWeight="bold" fill={textColor}>
                        {names[1]}:
                    </text>
                    <text textAnchor="middle" x="0" y="20" fontWeight="bold" fill={textColor}>
                        {choice}/{total}
                    </text>
                </>
            )
        } else {
            return <></>
        }
    }

    onRectMouseEnter = () => {
        this.setState({
            rectangleColor: ['#5991d8', '#5991d8']
        });
    }

    onRectMouseLeave = () => {
        this.setState({
            rectangleColor: ['#8d9bae', "#89b68a"]
        });
    }

    onRectMouseDown = () => {
        this.setState({
            rectangleColor: ['#5991d8', '#5991d8']
        });
    }

    onRectMouseUp = () => {
        this.setState({
            rectangleColor: ['#8d9bae', "#89b68a"]
        });
    }

    showRectMarker = (data, region) => {
        let total = (data && data[region.name] && data[region.name].total) || 0; 
        let choice = (data && data[region.name] && data[region.name].choice) || 0;
        let tooltip = `m 100 140 l 10 -10 l 50 0 v -50 h -120 v 50 h 50 l 10 10`;
        if (choice === 0) {
            return (
                <g className='rect'>
                    <path 
                        d={tooltip}
                        transform="translate(-100,-140)"
                        style={{
                            fill: '#8d9bae',//"#5991d8",
                            opacity: 0.9,
                            cursor: 'pointer'
                        }}
                        stroke="#e2e2e2"
                    >
                    </path>   
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-40" fill="white" fontWeight="bold">
                        {region.name}
                    </text>
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-20" fill="white">
                        Locations: {this.numToStr(total)}
                    </text> 
                </g>
            )
        } else {
            return (
                <g className='rect'>
                    <path 
                        d={tooltip}
                        transform="translate(-100,-140)"
                        
                        style={{
                            fill: "#89b68a",
                            opacity: 0.9,
                            cursor: 'pointer'
                        }}
                        stroke="#e2e2e2"
                    >
                    </path>   
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-40" fill="white" fontWeight="bold">
                        {region.name}
                    </text>
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-20" fill="white">
                        Selected: {this.numToStr(choice)}/{this.numToStr(total)}
                    </text> 
                </g>
            )
        }
    }

    showRectMarker2 = (data, region) => {
        let total = (data && data[region.name] && data[region.name].total) || 0; 
        let choice = (data && data[region.name] && data[region.name].choice) || 0;
        let tooltip = `m 100 140 l 10 -10 l 50 0 v -50 h -120 v 50 h 50 l 10 10`;
        if (choice === 0) {
            return (
                <g className='rect'>
                    <path 
                        d={tooltip}
                        transform="translate(-100,-140)"
                        style={{
                            fill: 'white',//"#5991d8",
                            opacity: 0.9,
                            cursor: 'pointer'
                        }}
                        stroke="#e2e2e2"
                    >
                    </path>   
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-40" fill="black" fontWeight="bold">
                        {region.name}
                    </text>
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-20" fill="black">
                        Locations: {this.numToStr(total)}
                    </text> 
                </g>
            )
        } else {
            return (
                <g className='rect'>
                    <path 
                        d={tooltip}
                        transform="translate(-100,-140)"
                        
                        style={{
                            fill: "white",
                            opacity: 0.9,
                            cursor: 'pointer'
                        }}
                        stroke="#e2e2e2"
                    >
                    </path>   
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-40" fill="black" fontWeight="bold">
                        {region.name}
                    </text>
                    <text style={{cursor: 'pointer'}} textAnchor="start" x="-50" y="-20" fill="#89b68a">
                        Selected: {this.numToStr(choice)}/{this.numToStr(total)}
                    </text> 
                </g>
            )
        }
    }

    showSelectCircleMarker = (data, region) => {
        if (!data.includes(region.name)) {
            return (
                <>
                    <circle
                        cx={0}
                        cy={0}
                        r={8}
                        style={{
                        fill: '#5BA9F0',
                        stroke: '#5BA9F0',
                        strokeWidth: 1,
                        cursor: 'pointer'
                        }}
                        data-tip={region.display_name}
                        onClick={() => this.props.onClickMark(data)}
                    />
                    <line 
                        x1="-3" 
                        y1="0" 
                        x2="3" 
                        y2="0" 
                        style={{
                            stroke:'#ffffff',
                            strokeWidth:2
                        }} 
                    />
                    <line 
                        x1="0" 
                        y1="-3" 
                        x2="0" 
                        y2="3" 
                        style={{
                            stroke:'#ffffff',
                            strokeWidth:2
                        }} 
                    />
                </>
            )
        } else {
            return (
                <>
                    <circle
                        cx={0}
                        cy={0}
                        r={8}
                        style={{
                        fill: '#5CAB71',
                        stroke: '#5CAB71',
                        strokeWidth: 1,
                        cursor: 'pointer'
                        }}
                        data-tip={region.display_name}
                        onClick={() => this.props.onClickMark(data)}
                    />
                </>
            )
        }
    }

    showMarker = (data, region) => {
        if (!this.props.markType) {
            return this.showStatusMarker(data, region);
        }
        switch (this.props.markType) {
            case 'status': 
                return this.showStatusMarker(data, region);
            case 'circle':
                return this.showCircleMarker(data, region);
            case 'rectangle':
                // return this.showRectMarker(data, region);
                return this.showRectMarker2(data, region);
            case 'selectCircle': 
                return this.showSelectCircleMarker(data, region);
            case 'detailStatus':
                return this.showDetailStatusMarker(data, region);
            case 'pollingStatus':
                return this.showBlinkCircleMaker(data, region);
        }
    }

    renderChildren(geographies, projection) {
        return geographies.map((geography, i) => {
            return <Geography
                key={i}
                geography={geography}
                projection={projection}
                style={{
                    default: {
                        fill: this.defaultFillColor(geography),
                        stroke: "#e2e2e2",
                        strokeWidth: 0.75,
                        outline: "none",
                    },
                    hover: {
                        fill: this.hoverFillColor(geography),
                        stroke: "#e2e2e2",
                        strokeWidth: 0.75,
                        outline: "none",
                    },
                    pressed: {
                        fill: this.pressedFillColor(geography),
                        stroke: "#e2e2e2",
                        strokeWidth: 0.75,
                        outline: "none",
                    }
                }}
            />
        });
    }

    render() {
        const center = this.props.center || [0, 20];
        const zoom = this.props.zoom || 1;
        const mapSource = this.props.mapSource || '/world-50m-with-population.json'; 
        const data = this.props.data || {};
        const w = this.props.width || 880;
        const h = this.props.height || 551;
        const viewbox = this.props.viewbox || `0 0 ${w} ${h}`;
        const disablePanning = this.props.disablePanning === undefined ? true : this.props.disablePanning;

        const _wrapperStyles = this.props.wrapperStyles

        const _bottomOperationButtonsStyle = this.props.bottomOperationButtonsStyle || { display: 'flex', position: 'absolute', left: '750px', top: '400px' };

        return (
            <div style={_wrapperStyles} id="adelphi_map_view" onMouseDownCapture={(e)=>{
                if(disablePanning) return;
                if(e.currentTarget.id === 'adelphi_map_view') {
                    e.currentTarget.style.cursor = 'grabbing';
                }
            }} onMouseUpCapture={(e)=>{
                if(disablePanning) return;
                if(e.currentTarget.id === 'adelphi_map_view') {
                    e.currentTarget.style.cursor = 'default';
                }
            }}>
                {this.props.operationButtons && <div style={{display: 'flex', position: 'absolute', left: '520px', top: '23px'}}>
                    {this.props.operationButtons}
                </div>}
                {this.props.bottomOperationButtons && <div style= {_bottomOperationButtonsStyle} >
                    {this.props.bottomOperationButtons}
                </div>}
                <div className="hover-tooltip" id="map-tooltip"></div>
                <ComposableMap
                    projectionConfig={{
                        scale: 205,
                        rotation: [-11, 0, 0]
                    }}
                    width={w}
                    height={h}
                    viewBox={viewbox}
                    defs={
                        <filter id="f1" x="0" y="0">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="0.1" />
                        </filter>
                    }
                >
                    <ZoomableGroup disablePanning={disablePanning} center={center} zoom={zoom}>
                        <Geographies geography={mapSource} disableOptimization={true}>
                            {(geographies, projection) => this.renderChildren(geographies, projection)}
                        </Geographies>
                        <Markers>
                            {
                                this.props.markArea && this.props.markArea.map((region, i) => (
                                    <Marker 
                                        key={i} 
                                        tabable={false}
                                        marker={region} 
                                        onClick={()=>{
                                            this.onClickMark(region.name)
                                        }}
                                        onMouseEnter={ (marker, evt) => this.onMouseEnter(region, evt) }
                                        onMouseLeave={ this.onMouseLeave }
                                    >
                                        { this.showMarker(data, region) }
                                    </Marker>
                                ))
                            }
                        </Markers>
                    </ZoomableGroup>
                </ComposableMap>
                <ReactTooltip />
            </div>
        );
    }
}

MapWidget.defaultProps = {
    
};

if (process.env.NODE_ENV !== "production") {
    MapWidget.propTypes = {
       mapSource:PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
       zoom:PropTypes.number,
       data:PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
           OK:PropTypes.string,
           total:PropTypes.number,
           Warning:PropTypes.string,
           Error:PropTypes.string,
           users:PropTypes.array
       })),PropTypes.object,PropTypes.arrayOf(PropTypes.string)]),
       center:PropTypes.array,
       markArea:PropTypes.arrayOf(PropTypes.shape({
           name:PropTypes.string,
           coordinates:PropTypes.arrayOf(PropTypes.number)
       })),
       onMouseEnter:PropTypes.func,
       onMouseLeave:PropTypes.func,
       defaultFillColor:PropTypes.func,
       hoverFillColor:PropTypes.func,
       pressedFillColor:PropTypes.func,
       onClickMark:PropTypes.func,
       markType:PropTypes.oneOf(['circle', 'selectCircle','detailStatus','pollingStatus','status',''])

    };
}