import React, { Component } from 'react';
import Pan from '../../schema/Pan';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import PropTypes from 'prop-types';
import {
    convertArraySelectionValue,
    processSelectionValueObject,
} from '../util';
import './TagWidget.scss';
export class TagWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            selectedOption: convertArraySelectionValue(props.value),
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            selectedOption: convertArraySelectionValue(nextProps.value),
        };
    }

    handleInputChange = newValue => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleCompletionResponse(response) {
        let success =
            response.result && response.result['@status'] === 'success';
        let options = [];
        if (success) {
            let completions = response.result.completions && response.result.completions.completion;
            completions.forEach(completion => {
                options.push({
                    value: completion['@value'],
                    label: completion['@value'],
                });
            });
        }
        return options;
    }

    loadOptions(inputValue) {
        //remote query when inputValue is empty (first time load not filter) or when the query is remote
        let { loadOptions, completionXpath, configLocation } = this.props;
        //do remote query
        let promise = Pan.isFunction(loadOptions)
            ? loadOptions(inputValue, completionXpath, configLocation)
            : new Promise(resolve => {
                resolve([]);
            });
        return promise;
    }

    render() {
        const { selectedOption } = this.state;
        const {
            id,
            onChange,
            onBlur,
            onFocus,
            disabled,
            error,
            showErrors,
            styles,
            onCreateOption
        } = this.props;
        let errorClassName = error && showErrors && !disabled ? 'select-error' : '';
        const customStyles = {
            ...styles,
            menuPortal: styles => ({ ...styles, zIndex: 9999 }),
        };

        return (
            <React.Fragment>
                <AsyncCreatableSelect
                    id={this.props.id}
                    defaultOptions={true}
                    isClearable={false}
                    className={"tag-select-container " + errorClassName}
                    classNamePrefix='tag-select'
                    loadOptions={this.loadOptions}
                    onInputChange={this.handleInputChange}
                    onBlur={onBlur && (event => onBlur(id, event.target.value))}
                    onFocus={onFocus && (event => onFocus(id, event.target.value))}
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    isDisabled={this.props.isDisabled}
                    autoFocus={this.props.autoFocus}
                    value={selectedOption}
                    isMulti={true}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    onCreateOption={onCreateOption}
                    createOptionPosition='first'
                    onChange={(valueObj, actionType) => {
                        let value = processSelectionValueObject(valueObj, true);
                        this.handleChange(valueObj);
                        onChange(value);
                    }}
                />
            </React.Fragment>
        );
    }
}

TagWidget.defaultProps = {
    autoFocus: false,
    placeholder: 'Select an option',
};

TagWidget.propTypes = {
    isDisabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    showErrors: PropTypes.bool,
    error: PropTypes.string,
    /**
     * if error=true and showErrors=true and disabled=false 'select-error' class 
     * is added to the widget
     */
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    styles: PropTypes.object,
    /**
     * custom create handler that replaces the default create option handler
     */
    onCreateOption: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
     /**
     * Asyc load function to fetch data
     */
    loadOptions: PropTypes.func.isRequired,
    /**
     * in case getGenericAutoComplete instead of custom loadOptions function,
     * completionXpath along with configLocation is used to get xPath which is passed in the 
     * body of the api request to get widget options data
     */
    completionXpath: PropTypes.string,
    configLocation: PropTypes.object,
};
