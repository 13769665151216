import { ViewerManager, Pan } from 'ui-lib';
import DGBaseViewer from "../../deviceGroup/DGBaseViewer";
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

const _T = str => str;

const addressGroupObjectFields = [
	{
		type: "string",
		name: "name",
		mapping: "@name",
		uiHint: {
			fieldLabel: _T("Name"),
			autoFocus: true
		}
	},
	{
		name: "$.choice",
		uiHint: {
			fieldLabel: _T("Type"),
			largeSelectionCount: 1
		}
	},
	{
		name: "$.dynamic.filter",
		uiHint: {
			fieldLabel: _T("Filter"),
			showHelpString: _T("Press ESC to close suggestion"),
			builder: 'AutoSuggestBuilder',
			builderConfig: {
				xpath: '$.tag.member',
				showNotCondition: false
			}
		}
	},
	{
		name: "$.static.member",
		uiHint: {
			fieldLabel: _T("Addresses")
		}
	},
	{
		name: "$.tag.member",
		uiHint: {
			fieldLabel: _T("Tag")
		}
	},
	{
		name: "$.static.member.*",
		uiHint: {
			groupOrder: ['address', 'address-group'],
			completionConfig: {
				actions: [
					{
						text: "Add Address",
						viewerName: 'AddressRecordViewer',
						rbaPath: "objects.addresses",
						viewerProps: {
							windowSize: 'lg',
							title: _T('Address')
						}
					}
				]
			}
		}
	},
	{
		name: "$",
		childrenNames: [
			"name",
			"location",
			"$.description",
			"$.choice",
			// '$.dynamic.filter',
			"$.tag.member"
		]
	}
];

const addressGroupObjectColumns = [
	{
		type: ['nameColumn', 'clickableColumn'],
		recordConfigPath: "$.config.devices.entry.vsys.entry.address-group.entry",
		minWidth: 100
	},
	{
		headerName: "Location",
		field: "@loc",
		minWidth: 100
	},
	{
		headerName: _T("Members Count"),
		field: "memberCount",
		valueGetter: params => {
			let staticMember = params.data.static;
			if (staticMember) {
				return staticMember && staticMember["member"] ? staticMember["member"].length : "";
			} else {
				return "dynamic";
			}
		},
		minWidth: 100
	},
	{
		headerName: _T("Addresses"),
		style: { whiteSpace: "unset" },
		field: "static.member",
		minWidth: 200
	},
	{
		headerName: _T("Tags"),
		field: "tag.member",
		minWidth: 100,
	}
];

const viewConfig = {
	serviceName: "Objects/AddressGroups",
	reduxId: "addressGroups",
	header: "Address Groups",
	columns: addressGroupObjectColumns,
	fields: addressGroupObjectFields,
	recordConfigPath: "$.config.devices.entry.device-group.entry.address-group.entry",
	rbaPath: "objects.address-groups",
	className: 'AddressGroups'
}


export default class AddressGroups extends DGBaseViewer {
	constructor(props) {
		super(props);
		Pan.apply(this, viewConfig);
	}
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);
