import React, { Component } from 'react';
import { Badge } from 'reactstrap';

import './Risk.scss';

export default class Risk extends Component {

    render() {
        const { title } = this.props;
        const colorCodeMapping = {
            '1': 'color13',
            '2': 'color3',
            '3': 'color15',
            '4': 'color6',
            '5': 'color1',
            'informational': 'color13',
            'low': 'color3',
            'medium': 'color15',
            'high': 'color6',
            'critical': 'color1'
        };
        const iconColorCls = 'icon-combo-' + colorCodeMapping[title];

        return (
            <Badge className={iconColorCls}>{title}</Badge>
        );
    }
}