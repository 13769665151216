import React, { Component } from 'react';
import {
    parseError,
    SERVER_ERROR,
    getCustomServiceURL,
    getStore, 
    ButtonWidget,
    getAuthHeaders,
    showModal, 
    hideModal, 
    MOCK_SERVICES
} from 'ui-lib';
import { GPCS_COMMON_TPL } from 'service-lib';
import { ajax } from 'rxjs/ajax';

export default class ExportCert extends Component {
    exportCert = () => {
        const {
            certLocation,
            certificateNames,
        } = this.props;
        const store = getStore();
        const { SERVICE_URL } = getCustomServiceURL(certLocation);
        if (certificateNames && certificateNames.length > 0) {
            certificateNames.forEach(certificateName => {
                const obs = ajax({
                    withCredentials: true,
                    method: 'POST',
                    url: SERVICE_URL,
                    responseType: 'blob',
                    body: JSON.stringify({
                        '@location': 'template',
                        '@template': GPCS_COMMON_TPL,
                        certificateName,
                        format: 'pem',
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...getAuthHeaders()
                    },
                });
        
                obs.toPromise().then(
                    ({ xhr: request }) => {
        
                        // var disposition = request.getResponseHeader('content-disposition');
                        // var matches = /"([^"]*)"/.exec(disposition);
                        const filename = (certificateName ? certificateName + '.crt' : 'file.crt');
        
                        // The actual download
                        var blob = new Blob([request.response], { type: 'text/plain' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
        
                        document.body.appendChild(link);
        
                        link.click();
        
                        document.body.removeChild(link);
                    },
                    err => {
                        store.dispatch({
                            type: SERVER_ERROR,
                            errorMessage: parseError(err),
                        });
                    },
                );
                
            });

        }

        
    }

    triggerConfirmationModal = () => {
        const { modalTitleText, modaleMessageText } = this.props
        showModal({
            id: "ExportCertModal",
            open: true,
            size: 'sm',
            toggle: () => {
                hideModal("ExportCertModal");
            },
            title: modalTitleText,
            message: modaleMessageText,
            actions: [
                {
                    text: "OK",
                    color: "secondary",
                    action: () => {
                        this.exportCert();
                        hideModal('ExportCertModal');
                    }
                },
                {
                    text: "Cancel",
                    color: "secondary",
                    action: () => {
                        hideModal('ExportCertModal')
                    }
                },
            ]
        });
    }

    render() {
        const {
            confirmationModalEnabled,
            className
        } = this.props;
        return (
            <>
                <ButtonWidget
                    color="primary"
                    size="sm"
                    className={`${className} indent-align-col-form-label`}
                    onClick={confirmationModalEnabled ? this.triggerConfirmationModal : this.exportCert}
                    text="Export"
                />
            </>
        );
    }
}
