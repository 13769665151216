import Pan from './Pan';
let CONTEXT = {};
export function getSettingsForCurrentTemplate() {
    //for 1.0 adelphi we are hardcoding this
    return {
        'isMultiVsys': true,
        'deviceOperationalMode': 'normal',
        'vpnDisableMode': false,
        'schemaPruneOn':['multi-vsys-mode', 'non-fips-mode', 'non-cc-only-mode', 'vpn-disable-mode']
    };
}

export function  schemaPruneOn() {
    let settings = getSettingsForCurrentTemplate();
    if (!Pan.isEmpty(settings) && settings['schemaPruneOn']) {
        return settings['schemaPruneOn'];
    }
    return [];
}