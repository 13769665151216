import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import { BaseBuilder } from "../BaseBuilder";
import { FormContext, FormConsumer } from "../../FormContext";
import "./JumpToWithWarningBuilder.scss";

export class JumpToWithWarningBuilder extends BaseBuilder {

	renderJumpTo() {
		const { field } = this.props;
		const iconImage = (field && field.uiHint && field.uiHint.iconImage) || "";
		const iconFooter = (field && field.uiHint && field.uiHint.iconFooter) || "";
		const iconFooterForDisabled = (field && field.uiHint && field.uiHint.iconFooterForDisabled) || "";
		const jumpTo = (field && field.uiHint && field.uiHint.jumpTo) || "";
		const jumpToButtonText = (field && field.uiHint && field.uiHint.jumpToButtonText) || "";
		const disabled = this.context.isRecordDirty;
		return (
			<div className="jumpto-sc-viewer d-flex flex-column align-items-center" test_id={field.attrPath}>
				{iconImage && (
					<div className="scCircle">
						<img width="207" height="148" src={iconImage} />
					</div>
				)}
				<div width="100%" >{disabled ? iconFooterForDisabled : iconFooter}</div>
				{<Button className="go-to-sc-btn" disabled={disabled} color="primary" tag={NavLink} to={jumpTo}>
					{jumpToButtonText}
				</Button>}
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				<FormConsumer>{() => this.renderJumpTo()}</FormConsumer>
			</React.Fragment>
		)
	}
}

JumpToWithWarningBuilder.contextType = FormContext;