import React, { Component } from "react";
import {
	getFieldLabelComp
} from "ui-lib";
import {
	FormGroup,
	Col
} from "reactstrap";
import "./SamlIdpInfo.scss";
import classnames from "classnames";


export default class SamlIdpInfo extends Component {
	render() {
		let {
			name,
			className,
			field,
			hideLabel,
			containerLabelWidthSize,
			isEditorGridColumnEditor
		} = this.props;
		let LabelComp = getFieldLabelComp(
			field,
			name,
			isEditorGridColumnEditor,
			containerLabelWidthSize
        );
        
		const oktaLink = 'https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-cloud-managed-admin/secure-mobile-users-with-prisma-access/enable-user-authentication-for-prisma-access/configure-saml-authentication-using-okta-as-the-idp-for-mobile-users.html';
        const adfsLink = 'https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-cloud-managed-admin/secure-mobile-users-with-prisma-access/enable-user-authentication-for-prisma-access/configure-saml-authentication-using-adfs-as-the-idp-for-mobile-users.html';

		return (
			<FormGroup className={classnames(className, `d-flex flex-row`)}>
				{!hideLabel && LabelComp}
				<Col>
                    <a href={oktaLink} target="_blank" rel="noopener noreferrer">OKTA</a>, <a href={adfsLink} target="_blank" rel="noopener noreferrer">ADFS</a>
				</Col>
			</FormGroup>
		);
	}
}
