import React from 'react';
import PropTypes from 'prop-types';
import './SelectWidget.scss';
import { BaseSelectWidget } from '../BaseSelectWidget';

export class SelectWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options || [],
        };
        this.select = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { options } = nextProps;
        if (options) {
            return { options };
        }
        return null;
    }

    focus = () => {
        if (this.select && this.select.current.focus) {
            this.select.current.focus();
        }
    }

    render() {
        return (
            <React.Fragment>
                <BaseSelectWidget
                    ref={this.select}
                    widgetType="select"
                    {...this.props}
                    options={this.state.options}
                />
            </React.Fragment>
        );
    }
}

SelectWidget.defaultProps = {
    autoFocus: false,
};

if (process.env.NODE_ENV !== 'production') {
    SelectWidget.propTypes = {
        options: PropTypes.array,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        multiple: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
