import * as React from 'react';
import RcTreeSelect, { TreeNode, SHOW_ALL, SHOW_PARENT, SHOW_CHILD } from 'rc-tree-select';
import classNames from 'classnames';
import _ from 'lodash';
import warning from 'warning';
import caretDown from '../../../../images/icon-caret-down.svg'
import close from '../../../../images/icon-close.svg'
import closeCircle from '../../../../images/icon-close-circle.svg'
import down from '../../../../images/chevron-down.svg'
import './TreeSelectWidget.scss'

export class TreeSelectWidget extends React.Component {
    static TreeNode = TreeNode;

    static SHOW_ALL = SHOW_ALL;

    static SHOW_PARENT = SHOW_PARENT;

    static SHOW_CHILD = SHOW_CHILD;

    static defaultProps = {
        transitionName: 'slide-up',
        choiceTransitionName: 'zoom',
    };


    constructor(props) {
        super(props);

        warning(
            props.multiple !== false || !props.treeCheckable,
            'TreeSelect `multiple` will alway be `true` when `treeCheckable` is true',
        );
    }

    state = { value: null };

    onChange = value => this.setState({value})

    saveTreeSelect = (node) => {
        this.rcTreeSelect = node;
    };

    focus() {
        this.rcTreeSelect.focus();
    }

    blur() {
        this.rcTreeSelect.blur();
    }

    renderSwitcherIcon = (prefixCls, { isLeaf, loading }) => {
        if (isLeaf) {
            return null;
        }
        return <i className={`${prefixCls}-switcher-icon`}><img width={'12px'} height={'12px'} alt="caret-down" src={caretDown}  /></i>;
    };

    render() {
        const {
            className,
            size,
            notFoundContent,
            dropdownStyle,
            dropdownClassName,
            getPopupContainer,
            ...restProps
        } = this.props;
        const rest = _.omit(restProps, ['inputIcon', 'removeIcon', 'clearIcon', 'switcherIcon']);

        const prefixCls = 'pan-select';
        const getContextPopupContainer=triggerNode => triggerNode
        const cls = classNames(
            {
                [`${prefixCls}-lg`]: size === 'large',
                [`${prefixCls}-sm`]: size === 'small',
            },
            className,
        );

        // showSearch: single - false, multiple - true
        let { showSearch } = restProps;
        if (!('showSearch' in restProps)) {
            showSearch = !!(restProps.multiple || restProps.treeCheckable);
        }

        let checkable = rest.treeCheckable;
        if (checkable) {
            checkable = <span className={`${prefixCls}-tree-checkbox-inner`} />;
        }

        const inputIcon = (
            <i className={`${prefixCls}-arrow-icon`}><img width={'12px'} height={'12px'} alt="down" src={down}  /></i>
        );

        const finalRemoveIcon = (
            <i className={`${prefixCls}-remove-icon`}><img width={'12px'} height={'12px'} alt="close" src={close}  /></i>
        );

        const finalClearIcon = (
            <i className={`${prefixCls}-clear-icon`}><img width={'12px'} height={'12px'} alt="close-circle" src={closeCircle}/></i>
        );

        return (
            <RcTreeSelect
                onChange={this.onChange}
                value={this.state.value}
                switcherIcon={(nodeProps) =>
                    this.renderSwitcherIcon(prefixCls, nodeProps)
                }
                inputIcon={inputIcon}
                removeIcon={finalRemoveIcon}
                clearIcon={finalClearIcon}
                {...rest}
                showSearch={showSearch}
                getPopupContainer={getPopupContainer || getContextPopupContainer}
                dropdownClassName={classNames(dropdownClassName, `${prefixCls}-tree-dropdown`)}
                prefixCls={prefixCls}
                className={cls}
                dropdownStyle={{ maxHeight: '100vh', overflow: 'auto', ...dropdownStyle }}
                treeCheckable={checkable}
                notFoundContent={notFoundContent}
                ref={this.saveTreeSelect}
            />
        );
    }
}