import CertificateProfile from '../device/certificateProfile';
import QoSProfile from '../network/qosProfile';
import CertificateImport from '../device/certificate';
import LocalUsers from '../device/localUsers';
import { ClientLessApp } from '../network/clientLessApp'
import SSLTLSServiceProfile from '../device/sslTlsServiceProfile';
import { SAMLIDPProfile, ImportSAMLIDP} from '../device/samlIDPProfile';
import LocalCertificate from '../device/localCertificate';
import { ClientLessAppGroup } from '../network/clientLessAppGroup';

export const tplViews = {
    'CertificateProfile': CertificateProfile,
    'QoSProfile': QoSProfile,
    'CertificateImport': CertificateImport,
    'LocalUsers': LocalUsers,
    'SSLTLSServiceProfile': SSLTLSServiceProfile,
    'SAMLIDPProfile': SAMLIDPProfile,
    'ImportSAMLIDP' : ImportSAMLIDP,
    'LocalCertificate': LocalCertificate,
    'ClientLessApp': ClientLessApp,
    'ClientLessAppGroup': ClientLessAppGroup
}
