export class ViewerManager {
    static ViewMap = {};

    static register(name, component) {
        Object.assign(ViewerManager.ViewMap, { [name]: component });
    }

    static registerViewConfig(viewConfig, getComponentFn) {
        const component = getComponentFn(viewConfig.className, viewConfig.serviceName, "", viewConfig.reduxId);
        Object.assign(ViewerManager.ViewMap, { [`${viewConfig.className}RecordViewer`]: component });
    }

    static registerAll(nameCmpMap) {
        Object.assign(ViewerManager.ViewMap, nameCmpMap)
    }

    static getView(name) {
        return ViewerManager.ViewMap[name];
    }

    static getViewInstance(name) {
        const Viewer = ViewerManager.getView(name);
        if (Viewer) {
            return new Viewer();
        }
        return null;
    }
}
