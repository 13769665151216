import { Pan } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str;

const fields = unionBy([{
    name: '$.port',
    uiHint: {
        showHelpString: _T("Valid values [0 - 65535].") + "\n" + _T("Port number can be individual numbers (e.g. 80) or ranges (e.g. 80-100). You can also have multiple values separeted by commas (e.g. 80,90-100).")
    }
}, {
    // Create a new rule
    name: '$',
    childrenNames: [
        '$.general-box',
        '$.tabs-box'
    ]
}, {
    // Create a new rule
    name: '$.tabs-box',
    childrenNames: [
        '$.source-box',
        '$.destination-box',
        '$.app-protocol-box'
    ],
    uiHint: {
        builder: 'TabBuilder',
        deferredRender: false
    }
}, {
    name: '$.protocol',
    uiHint: {
        useHelpStringAsDisplay: true
    }
}, {
    name: '$.app-protocol-box',
    childrenNames: [
        '$.protocol',
        '$.port',
        '$.application'
    ],
    uiHint: {
        fieldLabel: _T('Protocol/Application')
    }
}], commonFields, 'name');

const columns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    {
        headerName: 'Source',
        children: [
            Pan.apply({}, {}, columnConfig.from),
            Pan.apply({}, {}, columnConfig.source_negate)
        ]
    },
    {
        headerName: 'Destination',
        children: [
            Pan.apply({}, {}, columnConfig.to),
            Pan.apply({}, {}, columnConfig.destination_negate)
        ]
    },
    Pan.apply({}, {}, columnConfig.protocol),
    Pan.apply({}, {}, columnConfig.port),
    Pan.apply({}, {}, columnConfig.single_application)
]
export default class AppOverridePolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/ApplicationOverridePreRules';
    reduxId = 'applicationOverridePreRules';
    header = 'Application Override Rules';
    suppressColsSizeToFit = false;
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.application-override.rules.entry';
    rbaPath = 'policies.application-override-rulebase';
}
