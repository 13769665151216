import React from 'react';
import {
    jsonPath,
    Pan,
    parseError,
    autoCompletionObservable,
    parseAutoCompletionToOptions,
    getPathValue,
    getCompletionXpath,
    generateGridRecId,
    SERVER_ERROR,
    INJECTED_REC_ID
} from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import URLFilteringCategoriesTableBuilder from "./URLFilteringCategoriesTableBuilder";
import { store } from '../../../store';
import { ajax } from 'rxjs/ajax';
import { filter } from 'lodash';
import { getURLFilteringCategories } from 'service-lib';
import { DisplayCategories } from './DisplayCategories';

const _T = str => str;

const fields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
        }
    },
    {
        name: '$.license-expired',
        uiHint: {
            fieldLabel: _T('Action On License Expiration'),
            allowBlank: true,
            hidden: true
        }
    },
    {
        name: '$.enable-container-page',
        uiHint: {
            hidden: true,
        }
    },
    {
        name: '$.safe-search-enforcement',
        uiHint: {
            fieldLabel: _T('Safe Search Enforcement'),
            hideLabel: true,
        }
    },
    {
        name: '$.log-http-hdr-xff',
        uiHint: {
            fieldLabel: _T('X-Forwarded-For'),
        }
    },
    {
        name: '$.log-http-hdr-user-agent',
        uiHint: {
            fieldLabel: _T('User-Agent'),
        }
    },
    {
        name: '$.log-http-hdr-referer',
        uiHint: {
            fieldLabel: _T('Referer'),
        }
    },
    {
        name: '$.dynamic-url',
        uiHint: {
            fieldLabel: _T('Dynamic URL'),
            hidden: true
        }
    },
    {
        name: '$.log-container-page-only',
        uiHint: {
            hideLabel: true
        }
    },
    {
        name: 'categories-tab',
        uiHint: {
            fieldLabel: _T('Categories'),
            builder: () => {
                return URLFilteringCategoriesTableBuilder;
            }
        }
    },
    {
        name: 'httpheaderfieldset',
        childrenNames: [
            '$.log-http-hdr-user-agent',
            '$.log-http-hdr-referer',
            '$.log-http-hdr-xff',
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('HTTP Header Logging'),
        }
    },
    {
        name: 'settings-tab',
        childrenNames: [
            '$.dynamic-url',
            '$.log-container-page-only',
            '$.safe-search-enforcement',
            '$.enable-container-page',
            'httpheaderfieldset',
        ],
        uiHint: {
            fieldLabel: _T('URL Filtering Settings')
        }
    },
    {
        name: 'user-credential-detection-tab',
        childrenNames: ['UserCredentialFieldset', 'LogSeverityFieldset'],
        uiHint: {
            fieldLabel: _T('User Credential Detection'),
        }
    },
    {
        name: 'UserCredentialFieldset',
        childrenNames: [
            //'$.log-http-hdr-user-agent','$.log-http-hdr-referer','$.log-http-hdr-xff'
            '$.credential-enforcement.mode',
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('User Credential Detection')
        }
    },
    {
        name: '$.credential-enforcement.mode',
        uiHint: {
            useHelpStringAsDisplay: true,
            hideLabel: true,
            singleLineLayout: false,
        }
    },
    {
        name: '$.credential-enforcement.mode.group-mapping',
        uiHint: {
            fieldLabel: 'Group Mapping Settings',
            hideLabel: false,
        }
    },
    {
        name: 'LogSeverityFieldset',
        childrenNames: ['$.credential-enforcement.log-severity'],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('Log Severity'),
            avail: {
                availHide: true,
                match: {
                    evaluate: '!==',
                    operands: [
                        { field: '$.credential-enforcement.mode' },
                        'disabled',
                    ],
                },
            },
        }
    },
    {
        name: '$.credential-enforcement.log-severity',
        uiHint: {
            fieldLabel: _T('Valid Username Detected Log Severity'),
            completionConfig: {
                // Setting the order for this severity drop-down list (critical, high, medium, etc.)
                sortInfo: {
                    field: 'value',
                    direction: 'ASC'
                },
            },
        }
    },
    {
        name: 'http-header-insertion-tab',
        childrenNames: ['$.http-header-insertion.entry'],
        uiHint: {
            fieldLabel: _T('HTTP Header Insertion'),
        }
    },
    {
        name: '$.http-header-insertion.entry',
        uiHint: {
            disableInlineEdit: true,
            useCheckBoxSelection: true,
            hasAdjustedColumnMenu: true,
            editorType: 'record-form',
            dialogSize: 'xl',
            useColumns: [
                "$.http-header-insertion.entry.*.@name",
                "typeName",
                "typeDomains",
                "typeHeader",
                "typeHeaderValue",
                "typeHeaderLog"
            ]
        }
    },
    {
        name: "typeName",
        uiHint: {
            fieldLabel: 'Type',
            columnConfig: {
                valueGetter: params => jsonPath(params.data, 'type.entry.*.@name')
            },
        },
        minWidth: 150
    },
    {
        name: "typeDomains",
        uiHint: {
            fieldLabel: 'Domains',
            columnConfig: {
                valueGetter: params => jsonPath(params.data, 'type.entry.*.domains.member.*.value')
            },
        },
        minWidth: 100
    }, {
        name: "typeHeader",
        uiHint: {
            fieldLabel: 'Header',
            columnConfig: {
                valueGetter: params => {
                    return jsonPath(params.data, 'type.entry.*.headers.entry.*.header');
                }
            },
        },
        minWidth: 100
    }, {
        name: "typeHeaderValue",
        uiHint: {
            fieldLabel: 'Value',
            columnConfig: {
                valueGetter: params => jsonPath(params.data, 'type.entry.*.headers.entry.*.value')
            },
        },
        minWidth: 100
    }, {
        name: "typeHeaderLog",
        uiHint: {
            fieldLabel: 'Log',
            columnConfig: {
                id: 'typeHeaderLog',
                valueGetter: params => jsonPath(params.data, 'type.entry.*.headers.entry.*.log')
            },
        },
        minWidth: 100
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry',
        uiHint: {
            editorType: 'record-form',
            dialogSize: 'xl',
            useColumns: [
                "$.http-header-insertion.entry.*.type.entry.*.@name",
                "$.http-header-insertion.entry.*.type.entry.*.domains",
                "$.http-header-insertion.entry.*.type.entry.*.headers"
            ]
        }
    },
    {
        name: "$.http-header-insertion.entry.*.type.entry.*.headers",
        uiHint: {
            columnConfig: {
                valueGetter: params => {
                    let d = params.data;
                    if (Pan.isObject(d.headers) && Array.isArray(d.headers.entry)) {
                        return d.headers.entry.map(item => {
                            return item.header + ": " + item.value;
                        })
                    }
                    return '';
                },
            }
        }
    },
    {
        name: '$.http-header-insertion.entry.*.@name',
        uiHint: {
            windowConfig: {
                width: 480,
                title: _T('HTTP Header Insertion'),
            },
            columnConfig: {
                type: 'clickableColumn'
            },
            fieldLabel: _T('Name'),
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry',
        uiHint: {
            fieldLabel: '',
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*',
        uiHint: {
            fieldLabel: ''
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.@name',
        uiHint: {
            fieldLabel: _T('Type'),
            completionConfig: {
                multiple: false,
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    let completionXpath2 = getCompletionXpath('$.http-header-insertion.entry.*.type', filters, formData, "$.config.devices.entry.device-group.entry.profiles.url-filtering.entry");
                    let completionObservable = ajax(autoCompletionObservable(inputValue, completionXpath2, configLocation));
                    return new Promise(function (resolve, reject) {
                        completionObservable.toPromise().then((resp) => {
                            let completions = parseAutoCompletionToOptions(resp);
                            resolve(completions);
                        }, (err) => {
                            store.dispatch({
                                type: SERVER_ERROR,
                                errorMessage: parseError(err),
                                showMessage: false
                            });
                            // reject(new Error('Error in Auto Completion: ' + err ));
                        });
                    });
                }
            },
            columnConfig: {
                type: 'clickableColumn'
            }
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.domains',
        uiHint: {
            fieldLabel: _T('Domains'),
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.domains.member',
        uiHint: {
            fieldLabel: _T('Domains'),
            showFilter: false,
            association: {
                fields: ['$.http-header-insertion.entry.*.type.entry.*.@name'],
                updateFormDataAsync: (values, formData, filters, context) => getPrefilledHTTPHeaderInsertionPromise('domain', filters, formData, context)
            }
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.domains.member.*',
        looseMembership: true,
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
            },
            builder: 'CompletionBuilder',
            completionConfig: {
                associationFields: ['$.http-header-insertion.entry.*.type.entry.*.@name'],
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    let type = getPathValue(formData, '$.http-header-insertion.entry.*.type.entry.*.@name');
                    if (type) {
                        let completionXpath = getCompletionXpath('$.http-header-insertion.entry.*.type.entry.*.domains.member.*', filters, formData, "$.config.devices.entry.device-group.entry.profiles.url-filtering.entry");
                        let completionObservable = ajax(autoCompletionObservable(inputValue, completionXpath, configLocation));
                        return new Promise(function (resolve, reject) {
                            completionObservable.toPromise().then((resp) => {
                                let completions = parseAutoCompletionToOptions(resp);
                                resolve(completions);
                            }, (err) => {
                                store.dispatch({
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(err),
                                    showMessage: false
                                });
                                // reject(new Error('Error in Auto Completion: ' + err ));
                            });
                        });
                    } else {
                        return new Promise(function (resolve, reject) {
                            resolve([]);
                        });
                    }
                }
            }
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.headers.entry',
        uiHint: {
            fieldLabel: _T('Headers'),
            editorType: 'record-form',
            autoGenField: '@name',
            showFilter: false,
            association: {
                fields: ['$.http-header-insertion.entry.*.type.entry.*.@name'],
                updateFormDataAsync: (values, formData, filters, context) => getPrefilledHTTPHeaderInsertionPromise('header', filters, formData, context)
            },
            customValidation: function (value, field, formData) {
                const emptyValues = filter(value, (rec) => { return !rec.value; });
                let errMsg = '';
                if (emptyValues.length) {
                    if (emptyValues.length > 1) {
                        errMsg = `There are ${emptyValues.length} headers whose values are not set`;
                    }
                    else {
                        errMsg = `One header's value is not set`;
                    }
                }
                return errMsg;
            }
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*',
        childrenNames: [
            // '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.@name',
            '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.header',
            '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.value',
            '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.log'
        ]
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.@name',
        uiHint: {
            fieldLabel: _T('Name')
            // columnConfig: {
            //     columnAvail: function() {
            //         return false;
            //     }
            // }
        }
    },
    {
        name:
            '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.header',
        looseMembership: true,
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
            },
            builder: 'CompletionBuilder',
            completionConfig: {
                associationFields: ['$.http-header-insertion.entry.*.type.entry.*.@name'],
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    let type = getPathValue(formData, '$.http-header-insertion.entry.*.type.entry.*.@name');
                    if (type) {
                        let completionXpath = getCompletionXpath('$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.header', filters, formData, "$.config.devices.entry.device-group.entry.profiles.url-filtering.entry");
                        let completionObservable = ajax(autoCompletionObservable(inputValue, completionXpath, configLocation));
                        return new Promise(function (resolve, reject) {
                            completionObservable.toPromise().then((resp) => {
                                let completions = parseAutoCompletionToOptions(resp);
                                resolve(completions);
                            }, (err) => {
                                store.dispatch({
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(err),
                                    showMessage: false
                                });
                                // reject(new Error('Error in Auto Completion: ' + err ));
                            });
                        });
                    } else {
                        return new Promise(function (resolve, reject) {
                            resolve([]);
                        });
                    }
                }
            }
        }
    },
    {
        name: '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.value',
        looseMembership: true
    },
    {
        name: 'TabGroup',
        childrenNames: [
            'categories-tab',
            'settings-tab',
            'user-credential-detection-tab',
            'http-header-insertion-tab',
        ],
        uiHint: {
            builder: 'TabBuilder'
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            '$.description',
            'location',
            'TabGroup'
        ],
        uiHint: {
            labelWidthSize: 3
        }
    }
];

const columns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        minWidth: 100
    },
    {
        headerName: 'Location',
        field: '@loc',
        minWidth: 100
    },
    {
        headerName: _T("Site Access"),
        cellRendererParams: {
			renderCell: params => {
                return <DisplayCategories data={params.data} />;
			}
		},
        minWidth: 100
    },
    {
        headerName: _T("User Credential Submission"),
        cellRendererParams: {
			renderCell: params => {
                return <DisplayCategories data={params.data['credential-enforcement']} />;
			}
		},
        minWidth: 100
    },
    // {
    //     dataIndex: 'credentialPost',
    //     headerName: _T("User Credential Submission"),
    //     renderer: Pan.objects.URLFilteringCredentialPostRenderer,
    //     enableGlobalFind: false,
    //     globalFindValueExtractor: Pan.objects.URLFilteringGlobalFindValueExtractor,
    //     width: 150,
    //     columnActions: [{
    //         atype: 'pan-inspectoraction',
    //         inspectorXType: 'Pan.objects.URLFilteringCategoriesInspector',
    //         rbaPath: 'objects/security-profiles/url-filtering'
    //     }]
    // },
    {
        headerName: _T("HTTP Header Insertion"),
        valueGetter: params => {
            let entries = jsonPath(params.data['http-header-insertion'], 'entry');
            if (!entries) {
                return params.data['http-header-insertion'];
            }
            entries = entries[0];
            let display = [];
            entries.forEach(function (entry) {
                let entryDisplay = "";
                entryDisplay += entry['@name'] + ' - ';
                entryDisplay += entry['type']['entry'][0]['@name'];
                display.push(entryDisplay);
            });
            return display;
        }
    }
];



const getPrefilledHTTPHeaderInsertionPromise = (httpInsertionType, filters, formData, context) => {
    const completionPath = {
        "header": {
            "path": '$.http-header-insertion.entry.*.type.entry.*.headers.entry.*.header',
            parseFn: (completions) => {
                let headerList = completions.map(header => {
                    const id = generateGridRecId();
                    return { [INJECTED_REC_ID]: id, '@name': `fill_${id}`, header: header.value };
                });
                return { '$.http-header-insertion.entry.*.type.entry.*.headers.entry': headerList };
            }
        },
        "domain": {
            "path": '$.http-header-insertion.entry.*.type.entry.*.domains.member',
            parseFn: (completions) => {
                let domainList = completions.map(domain => { return { [INJECTED_REC_ID]: generateGridRecId(), value: domain.value } })
                return { '$.http-header-insertion.entry.*.type.entry.*.domains.member': domainList };
            }
        }
    }
    let type = getPathValue(formData, '$.http-header-insertion.entry.*.type.entry.*.@name');
    let { configLocation } = context;
    let promise;
    if (type) {
        let completionXpath = getCompletionXpath(completionPath[httpInsertionType].path, filters, formData, "$.config.devices.entry.device-group.entry.profiles.url-filtering.entry");
        let completionObservable = ajax(autoCompletionObservable("__new__", completionXpath, configLocation));
        promise = new Promise(function (resolve, reject) {
            completionObservable.toPromise().then((resp) => {
                let completions = parseAutoCompletionToOptions(resp);
                resolve(completionPath[httpInsertionType].parseFn(completions));
            }, (err) => {
                store.dispatch({
                    type: SERVER_ERROR,
                    errorMessage: parseError(err),
                    showMessage: false
                });
                // reject(new Error('Error in Auto Completion: ' + err ));
            });
        });
    } else {
        promise = new Promise(function (resolve, reject) {
            resolve([]);
        });
    }
    return promise;
}

export default class URLFiltering extends DGBaseViewer {

    serviceName = 'Objects/URLFilteringSecurityProfiles';
    reduxId = 'urlFilteringObjects';
    header = 'URL Filtering';
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.url-filtering.entry';
    rbaPath = 'objects.security-profiles.url-filtering';
    extraInfoActions = [getURLFilteringCategories];
}
