import React, { Component } from 'react';
import { Pan } from "ui-lib";
import { getEffectiveServiceMap, getHLViewerWithActions } from "service-lib";
export default class TPLBaseViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TPLHLViewerWithActions: null
        }
    }

    componentDidMount() {
        const serviceMap = getEffectiveServiceMap(this.serviceName, this.serviceMap);
        this.setState({
            TPLHLViewerWithActions: getHLViewerWithActions(
                this.serviceName,
                this.reduxId,
                this.hlComponent,
                this.headerComponent,
                serviceMap,
                this.resultPath,
                'tpl',
				this.extraInfoActions
            ),
            reduxId: this.reduxId,
            reduxStateId: {
                reduxId: this.reduxId,
                locationPath: 'tpl'
            },
            serviceMap: serviceMap
        });
    }

    getGridActions() {
        let gridActions = this.props.gridActions || [];
        return gridActions;
    }

    render() {
        let TPLHLViewerWithActions = this.state.TPLHLViewerWithActions;
        return (
            TPLHLViewerWithActions &&
            <TPLHLViewerWithActions
                {...this.props}
                header={this.props.header || this.header}
                columns={this.columns}
                simpleColumns={this.simpleColumns}
                inputFields={this.fields}
                recordConfigPath={this.recordConfigPath}
                mode={this.props.mode || 'grid'}
                displayMode={this.props.displayMode || 'default'}
                editorMode={this.props.editorMode || 'default'}
                rbaPath={this.rbaPath}
                afterUpdate={this.props.afterUpdate}
                gridActions={this.getGridActions()}
                minRows={this.props.minRows || this.minRows}
                suppressColsSizeToFit={Pan.isDefined(this.props.suppressColsSizeToFit) ? this.props.suppressColsSizeToFit : this.suppressColsSizeToFit}
                showDefaultAddAction={this.showDefaultAddAction}
                showDefaultCloneAction={false}  //INFO: clone is disabled on template items ADI-1116
                agGridProps={this.agGridProps}
            />
        );
    }
}