import React, { Component } from 'react';
import { FormContext } from "ui-lib";
import './TestInstructionsBuilder.scss';

export default class TestInstructionsViewer extends Component {  

    constructor(props){
        super(props);
        this.state={};
    }
    
    componentDidMount( ){
        let { formData } = this.context;
        let hostName = formData['@name'] || '';
        this.setState({
            hostName
        })
    }
    
    render() {
        let hostName = this.state.hostName;
        return (
            <div>
                <h1 className='test-instructions-title'>READY TO GO</h1>
                <p className='test-instructions'> 
                    Your Prisma Access for Users environment is ready!
                </p>
                <hr></hr>
                <p> 
                    Your mobile users can now safely browse to the internet using predefined best practice security policy. What does this mean? It means your users will automatically be...
                </p>
                <br></br>
                <div className='notes'>
                    <div className='iconCircle'>
                        <div className='pic'></div>
                    </div>
                    <div className='details'>
                        <ul>
                            <li><p>Blocked from visiting any known bad websites based on URL category.</p></li>
                            <li><p>Blocked from uploading or downloading files that are commonly known to be malicious.</p></li>
                            <li><p>Protected from unknown threats.</p></li>
                            <li><p>Protected from viruses, spyware, and vulnerabilities.</p></li>
                        </ul>
                    </div>
                </div>
                <br></br>
                <hr></hr>
                <h4 className='test-instructions'> 
                    To use the environment:
                </h4>
                <br></br>
                <br></br>
                <div className='notes'>
                    <div className='iconCircle'>
                        <div className='gearIcon'></div>
                    </div>
                    <div className='details'>
                        <ul>
                            <li><p>Download and install the GlobalProtect app from  <a href={`https://${hostName}`} rel="noopener noreferrer" target="_blank">{`https://${hostName}`}</a>.</p></li>
                            <li><p>{`Launch the GlobalProtect app and point it to the Prisma Access URL you defined, for example, https://${hostName}.`}</p></li>
                            <li><p>Log in using the test account you created during environment setup and then browse to the internet to verify that everything is working. <a href="https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-cloud-managed-admin.html" rel="noopener noreferrer" target="_blank">Learn More</a></p></li>
                            <li><p>Monitor your test users’ activity. <a href="https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-cloud-managed-admin.html" rel="noopener noreferrer" target="_blank">Learn More</a></p></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

TestInstructionsViewer.contextType = FormContext;
