/*!
 * PAN Management UI
 * Copyright(c) Palo Alto Networks, Inc.
 */
import Pan from './Pan';
import PanDecode from './PanDecode';
import PanType from './PanType';
import { jsonPath } from '../../utils/json';
import { schemaPruneOn, getTemplate } from './PanGlobal';


const yes2TrueFn = function (value) {
    return value === "yes";
}

export default class PanSchema {
    constructor(schema, path, fields) {
        if (!window.__PAN__SCHEMA) {
            window.__PAN__SCHEMA = this.preProcessPruneOn(schema);
        }
        schema = window.__PAN__SCHEMA;
        let _schema = jsonPath(schema, path);
        if (_schema)
            _schema = _schema[0];

        _schema=Pan.clone(_schema);

        this.schemaPruneOn = schemaPruneOn();
        _schema = this.visitInternal(_schema, null, null, this.processPruneOn);

        // This will clean up json to the format we need.
        this.transformSchema(_schema);

        // This will add fieldHint and uiHint information based on what's in the schema.
        this.schema = this.visitInternal(_schema, null, null, this.postprocessSchema, this, this.postprocessPostVisitFn, false);

        // Populate the fields with uiHint
        this.fields = this.populateFields(fields);
    }

    /**
     *
     * @param enumValues {Array}
     * @param valueOnly {Boolean}
     * @return {Object}
     */
    convertEnum(enumValues, valueOnly) {
        let values = [];
        let helpStrings = {};
        let helpTips = {};
        for (let i = 0; i < enumValues.length; i++) {
            let struct = enumValues[i];
            let valueString, displayString;
            displayString = valueString = struct['value'];
            if (!valueOnly) {
                let helpString = struct['help-string'];
                if (helpString && helpString !== displayString) {
                    displayString = helpString;
                }
            }
            values.push([valueString, displayString]);
            helpStrings[valueString] = struct['help-string'];
            helpTips[valueString] = struct['show-help-tip'];
        }

        return {
            values: values,
            helpStrings: helpStrings,
            helpTips: helpTips
        };
    }

    conversionProperties = [
            // from schema (non-type dependent and no defaults)
            {
                property: "optional",
                tag: "allowBlank",
                type: "uiHint",
                fn: function (value, attrs) {
                    value = yes2TrueFn(value);
                    attrs.allowBlank = value;
                    attrs.fieldHint = attrs.fieldHint || {};
                    attrs.fieldHint.allowBlank = value;
                    return value;
                },
                "default": "no"
            },
            {
                property: "regex", tag: "regex", type: "uiHint", fn: function (value) {
                    value = value.replace(/\[:cntrl:\]/g, "\x01-\x1F\x7F");
                    return new RegExp(value);
                }
            },
            {
                property: "subtype", tag: "type", type: "fieldHint", fn: function (value) {
                    return value === 'mac-address' ? "mac" : value;
                }
            },
            { property: "help-string", tag: "helpstring", type: "uiHint" },
            {
                property: "show-help-tip", //show helpstring as helpTip
                tag: "helpTip",
                type: "uiHint",
                fn: function (value) {
                    return value === "yes" ? true : undefined;
                }
            },
            { property: "ipv4-only", tag: "ipv4-only", type: "fieldHint" },
            { property: "ipv6-only", tag: "ipv6-only", type: "fieldHint" },
            { property: "multicast-only", tag: "multicast-only", type: "fieldHint" },
            { property: "unicast-with-mask", tag: "unicast-with-mask", type: "fieldHint", fn: yes2TrueFn },
            {
                property: "encrypt", tag: "type", type: "fieldHint", fn: function (value) {
                    return value === "yes" ? "password" : undefined;
                }
            },
            {
                property: "enum",
                tag: "enum",
                type: "uiHint",
                // need to extract the enum values
                fn: function (value, attrs) {
                    let result = this.convertEnum(value, false);
                    attrs.uiHint['helpStrings'] = result.helpStrings;
                    attrs.uiHint['helpTips'] = result.helpTips;
                    return result.values;
                }
            },
            {
                property: "ui-hint",
                tag: "uiHint",
                type: "uiHint",
                fn: function (value, attrs) {
                    let schemaHints = undefined;
                    if (Pan.isString(value)) {
                        try {
                            if (!value.match(/^ *\{.*\} *$/)) {
                                value = "{" + value + "}";
                            }
                            schemaHints = eval("(" + value + ")")
                        } catch (ex) {
                            schemaHints = eval("(" + value + ")")
                            console.error("Unable to parse ui-hint " + value);
                        }
                    } else if (Pan.isObject(value)) {
                        schemaHints = value;
                    }
                    Pan.apply(attrs.uiHint, schemaHints);
                    return undefined;
                }
            },
            { property: "uiHint-fieldLabel", tag: "fieldLabel", type: 'uiHint' },
            {
                property: "autocomplete",
                tag: "autoComplete",
                type: "fieldHint",
                fn: yes2TrueFn
            },
            {
                property: "loose-membership",
                tag: "looseMembership",
                type: "fieldHint",
                fn: yes2TrueFn
            },
            { property: "max-count", tag: "maxCount", type: 'fieldHint' },
            { property: "min", tag: "minValue", type: "uiHint" },
            { property: "max", tag: "maxValue", type: "uiHint" },
            { property: "tlo", tag: "tlo", type: 'fieldHint', fn: yes2TrueFn },
            {
                property: "ui-field-hint",
                tag: "fieldHint",
                type: "fieldHint",
                fn: function (value, attrs) {
                    let schemaHints = undefined;
                    if (Pan.isString(value)) {
                        try {
                            if (!value.match(/^ *\{.*\} *$/)) {
                                value = "{" + value + "}";
                            }
                            schemaHints = eval("(" + value + ')');
                        } catch (ex) {
                            console.error("Unable to parse ui-field-hint " + value);
                        }
                    } else if (Pan.isObject(value)) {
                        schemaHints = value;
                    }
                    Pan.apply(attrs.fieldHint, schemaHints);
                    return undefined;
                }
            },

            // from internal
            { property: "isStar", tag: "isStar", type: "fieldHint" },
            { property: "isCollection", tag: "isCollection", type: "fieldHint" },
            { property: "choices", tag: "choices", type: "fieldHint" }, // choice member string attrNames
            { property: "choiceAttr", tag: "choiceAttr", type: "fieldHint" }, // choice node @attr
            { property: "choiceParentAttr", tag: "choiceParentAttr", type: "fieldHint" } // choice parent node @attr
        ];

        postConversionProperties = [
            // type dependent or has defaults
            { property: "maxlen", tag: "maxLength", type: "uiHint" },
            {
                property: "minlen", tag: "minLength", type: "uiHint", fn: function (value, attrs) {
                    // if the field is optional, no need to set the minlen
                    if (attrs.uiHint && attrs.uiHint.allowBlank) {
                        value = undefined;
                    }
                    return value;
                }
            },
            //We are not supporting platform specific attribute values as of now
            /*{
                property: "platform-max", tag: "maxValue", type: "uiHint", fn: function (value, attrs) {
                    let rv = value ;
                    if (!Pan.isEmpty(rv)) {
                        return rv;
                    }
                    return attrs["maxValue"];
                }
            },*/
            {
                property: "default",
                tag: "defaultValue",
                type: "fieldHint",

                // need to convert "yes"/"no" defaults to true/false
                fn: function (value, attrs) {
                    let extType = attrs.fieldHint && attrs.fieldHint.extType;
                    if (extType) {
                        return extType.convert(value);
                    }
                    return value;
                }
            },
            {
                property: "multi-types",
                tag: "multitypes",
                type: "fieldHint",
                fn: function (value, attrs) {
                    //The following handles the enum in "multitypes"
                    if (value["enum"]) {
                        value = Pan.apply({}, value); // make a copy
                        let result = this.convertEnum(value["enum"], true);
                        value["enum"] = result.values;
                        attrs.uiHint = attrs.uiHint || {}; // keep help strings/tips inside uiHint, same as 'enum'
                        attrs.uiHint['helpStrings'] = result.helpStrings;
                        attrs.uiHint['helpTips'] = result.helpTips;
                    }

                    let multiValidationInfo = false;
                    let looseMembership = false;
                    for (let m in value) {
                        if (value.hasOwnProperty(m)) {
                            let info = value[m];
                            //noinspection FallthroughInSwitchStatementJS
                            switch (m) {
                                case "enum":
                                    break;
                                case "string":
                                    break; //for now break as we do not have SDB support for eg: Pan.global.SDBGENERAL['cfg.policy.skip-addr-check'] == 'True'
                                    let currentIsLooseMembership = false;
                                    if (info["loose-membership"]) {
                                        currentIsLooseMembership = yes2TrueFn(info["loose-membership"]);
                                    }
                                    looseMembership = looseMembership || currentIsLooseMembership;
                                    if (attrs["autocomplete"] && (!info["regex"] || !currentIsLooseMembership)) {
                                        break; // this is when autocomplete is strict (autocomplete without regex or not loose)
                                    }
                                // fall through ...
                                default:
                                    let validationInfo = {};
                                    let panType = PanType.getTypeInfo(m);
                                    if (Pan.isObject(info)) { // check original value[m]
                                        Pan.apply(validationInfo, info);
                                        this.convertAllAttrs(validationInfo, panType.defaults);
                                        validationInfo = validationInfo.uiHint || validationInfo;
                                    }

                                    if (panType) {
                                        let uiHint = panType.uiHint;
                                        if (uiHint) {
                                            Pan.applyIf(validationInfo, {
                                                vtype: uiHint.vtype
                                            });
                                            if (Pan.isFunction(validationInfo.vtype)) {
                                                validationInfo.vtype = validationInfo.vtype(info);
                                            }
                                            if (uiHint.regex) {
                                                Pan.applyIf(validationInfo, {
                                                    regex: new RegExp(uiHint.regex)
                                                });
                                            }
                                        }
                                    } else {
                                        console.log("Unrecognized type: " + m);
                                    }
                                    if (!multiValidationInfo) {
                                        multiValidationInfo = [];
                                    }
                                    multiValidationInfo.push(validationInfo);

                                    break;
                            }
                        }
                    }
                    if (looseMembership) {
                        attrs.fieldHint.looseMembership = true;
                    } else if (multiValidationInfo) {
                        attrs.uiHint.vtype = "multiVtype";
                        attrs.uiHint.multiValidationInfo = multiValidationInfo;
                    }
                    return value;
                }
            }
        ];

    /**
     * Process node for fieldHint and uiHint before child node processing.
     * @see PanSchema~nodeHandler
     */
    postprocessSchema(attrs, node, attrName, parentNode) {
        let fieldHint = attrs.fieldHint;
        if (!fieldHint) {
            fieldHint = attrs.fieldHint = {};
        }
        let uiHint = attrs.uiHint;
        if (!uiHint) {
            uiHint = attrs.uiHint = {};
        }
        this.convertAttrs(this.conversionProperties, attrs);

        Pan.applyIf(fieldHint, {
            nodetype: attrs['node-type'],
            type: (attrs.fieldHint && attrs.fieldHint['type']) || attrs['type']
        });
        let typeInfo = PanType.getTypeInfo(fieldHint.type || fieldHint['nodetype']);
        if (!typeInfo) {
            typeInfo = PanType.getTypeInfo("auto");
        }

        Pan.applyIf(fieldHint, typeInfo.fieldHint);
        Pan.applyIf(uiHint, typeInfo.uiHint);

        this.convertAttrs(this.postConversionProperties, attrs, typeInfo.defaults);

        if (!parentNode || !parentNode['@attr']) {
            attrs.attrPath = "$";
            attrs.attrName = "$";
            uiHint.fieldLabel = "";
        } else {
            let pattrs = parentNode['@attr'];
            attrs.attrPath = pattrs.attrPath + "." + attrName;
            attrs.attrName = attrName;
            attrs.parentNode = parentNode;
        }

        return attrs.skipChildHintProcessing;
    }

    /**
     * Process node for fieldHint and uiHint after child node processing.
     * @see PanSchema~nodeHandler
     */
    postprocessPostVisitFn(attrs, node) {
        let childrenPaths = [];
        let childrenNames = [];
        for (let m in node) {
            if (node.hasOwnProperty(m)) {
                switch (m) {
                    case "@attr":
                        break;
                    default:
                        let nodeMAttr = node[m]['@attr'];
                        if (nodeMAttr) {
                            if (nodeMAttr['choiceAttr']) {
                                // for choice child (parent choice attr = nodeMAttr['choiceAttr']), 
                                // populate the children path of the choice node
                                let choiceAttr = nodeMAttr['choiceAttr'];
                                choiceAttr.childrenPaths = choiceAttr.childrenPaths || [];
                                choiceAttr.childrenNames = choiceAttr.childrenNames || [];
                                choiceAttr.childrenPaths.push(nodeMAttr.attrPath);
                                choiceAttr.childrenNames.push(nodeMAttr.attrName);
                            } else if (nodeMAttr.attrPath) {
                                childrenPaths.push(nodeMAttr.attrPath);
                                childrenNames.push(nodeMAttr.attrName);
                            }
                        }
                        break;
                }
            }
        }
        if (childrenPaths.length > 0) {
            attrs.childrenPaths = childrenPaths;
            attrs.childrenNames = childrenNames;
        }
    }

    convertAllAttrs(attrs, defaults) {
        this.convertAttrs(this.conversionProperties, attrs);
        this.convertAttrs(this.postConversionProperties, attrs, defaults);
    }

    convertAttrs(conversionProperties, attrs, defaults) {
        for (let i = 0; i < conversionProperties.length; i++) {
            let prop = conversionProperties[i];
            let attrValue = attrs[prop.property];
            if (attrValue === undefined) {
                attrValue = defaults && defaults[prop.property];
                if (attrValue === undefined) {
                    attrValue = prop["default"];
                }
            }
            if (attrValue !== undefined && Pan.isFunction(prop.fn)) {
                attrValue = prop.fn.call(this, attrValue, attrs);
            }
            if (attrValue !== undefined) {
                PanDecode.setValue(attrs, prop.type + "." + prop.tag, attrValue);
            }
        }
    }

    mapping2Path(expr) {
        if (expr) {
            expr = String(expr).replace(/['"]*[\]][\[]['"]*|['"]*[\]][.]*|[.]*[\[]['"]*/g, ".");
            expr = expr.replace(/^[.]*|[.]*$/g, "");
        }
        return expr;
    }

    getSchemaInfo(attrPath) {
        let result = jsonPath(this.schema, attrPath + ".@attr", undefined);
        if (result) {
            return result[0];
        }
        return undefined;
    }

    visit(fn, scope) {
        return this.visitInternal(this.schema, null, null, fn, scope, false);
    }

    // function to visit the fields before they are data fields
    fieldVisit(fn, scope, field, fieldMap) {
        let result = fn.call(scope || this, field);
        if (result !== false && result.childrenNames) {
            for (let i = 0; i < result.childrenNames.length; i++) {
                let childField = fieldMap[result.childrenNames[i]];
                if (childField) {
                    this.fieldVisit(fn, scope, childField, fieldMap);
                }
            }
        }
    }

    visitInternal(json, member, parentJson, fn, scope, postVisitFunction, visitExclusion) {
        if (Pan.isObject(json)) {
            // bgn star node logic --- visit own node first, because doing so may introduce the * node
            // if it is the star node, the @attr node need not be processed again
            let stopChildProcessing;
            if (json["@attr"]) {
                let rv = fn.call(scope || this, json["@attr"], json, member, parentJson);
                if (Pan.isObject(rv) && rv.renamed) {
                    member = rv.renamed;
                } else {
                    stopChildProcessing = rv;
                }
            }
            // end star node logic
            if (!(stopChildProcessing)) {
                for (let m in json) {
                    if (json.hasOwnProperty(m)) {
                        switch (m) {
                            case "@attr":
                                break;
                            default:
                                if (visitExclusion || !(json["@attr"] && json["@attr"].exclusionMap && json["@attr"].exclusionMap[m])) {
                                    this.visitInternal(json[m], m, json, fn, scope, postVisitFunction, visitExclusion);
                                }
                                break;
                        }
                    }
                }
            }
            if (postVisitFunction && json["@attr"]) {
                postVisitFunction.call(scope || this, json["@attr"], json, member, parentJson);
            }
        }
        return json;
    }

    populateFields(fields) {
        if (this.fields) {
            return this.fields;
        } else {
            fields = fields.slice(0);
        }
        let fieldMap = {};
        let fieldMapByPath = {};
        let attachmentFields = {};
        let pruneFieldMap = {};
        let pruneIfNoChildMap = {};

        // first map attrPath to user specified fields
        for (let i = 0, n = fields.length; i < n; i++) {
            let field = fields[i];
            if (Pan.isString(field)) {
                field = { name: field };
                fields[i] = field;
            } else {
                field = fields[i] = Pan.apply({}, field);
                if (field.childrenNames) {
                    field.childrenNames = field.childrenNames.slice(0);
                }
                if (field.uiHint) {
                    field.uiHint = Pan.apply({}, field.uiHint);
                }
            }

            if (!field.initialFieldConfig) {
                let initialFieldConfig = Pan.apply({}, field);
                field.initialFieldConfig = initialFieldConfig;
                if (initialFieldConfig.uiHint) {
                    initialFieldConfig.uiHint = Pan.apply({}, initialFieldConfig.uiHint);
                }
            }

            let attrPath = field.attrPath || (field.mapping && this.mapping2Path(field.mapping)) || field.name;
            let schemaInfo = this.getSchemaInfo(attrPath);
            if (schemaInfo) {
                if (!field.children && schemaInfo.childrenPaths) {
                    field.childrenPaths = Pan.clone(schemaInfo.childrenPaths);
                }
                fieldMap[field.name] = fieldMapByPath[schemaInfo.attrPath] = field;
            } else {
                if (field.pruneIfNoChild) {
                    pruneIfNoChildMap[field.name] = field;
                }
                if (field.pruneIfNotInSchema) {
                    pruneFieldMap[field.name] = field;
                } else if (field.parentFieldPath) {
                    attachmentFields[field.parentFieldPath] = attachmentFields[field.parentFieldPath] || [];
                    attachmentFields[field.parentFieldPath].push(field.name);
                    fieldMap[field.name] = field;
                } else {
                    fieldMap[field.name] = field;
                }
            }
            this.setField(field, schemaInfo, false);
        }

        this.visit(function (attr) {
            let generatedField = false;
            let field = fieldMapByPath[attr.attrPath];
            if (!field) {
                generatedField = true;
                field = {};
                if (attr.childrenPaths) {
                    field.childrenPaths = Pan.clone(attr.childrenPaths);
                }
                this.setField(field, attr, true);
            }
            if (generatedField) {
                fields.push(field);
                fieldMap[field.name] = fieldMapByPath[attr.attrPath] = field;
            }
        }, this);

        // third for those with childrenPath, change them to fields.
        for (let k = fields.length - 1; k >= 0; k--) {
            let f = fields[k];
            if (pruneFieldMap[f.name]) {
                fields.splice(k, 1);
            }
            if (!f.childrenNames) {
                if (attachmentFields[f.attrPath]) {
                    f.childrenNames = attachmentFields[f.attrPath];
                }
                if (f.childrenPaths) {
                    f.childrenNames = f.childrenNames || [];
                    for (let l = 0; l < f.childrenPaths.length; l++) {
                        let childField = fieldMapByPath[f.childrenPaths[l]];
                        if (!childField || !childField.uiHint) {
                            console.log("Missing: " + f.childrenPaths[l]);
                        }
                        if (childField.uiHint.isKeyField) {
                            f.childrenNames.unshift(childField.name);
                        } else {
                            f.childrenNames.push(childField.name);
                        }
                    }
                    if (f.prependChildrenNames) {
                        for (let po = f.prependChildrenNames.length - 1; po >= 0; po--) {
                            f.childrenNames.unshift(f.prependChildrenNames[po]);
                        }
                        delete f.prependChildrenNames;
                    }
                    if (f.additionalChildrenNames) {
                        for (let o = 0; o < f.additionalChildrenNames.length; o++) {
                            f.childrenNames.push(f.additionalChildrenNames[o]);
                        }
                        delete f.additionalChildrenNames;
                    }
                    if (f.appendChildrenAfterNamedField) {
                        let searchforIndex = -1;
                        let searchfor = f.appendChildrenAfterNamedField[0];
                        let toBeInserted = f.appendChildrenAfterNamedField[1];
                        for (let q = 0; q < f.childrenNames.length; q++) {
                            if (f.childrenNames[q] == searchfor) {
                                searchforIndex = q;
                                break;
                            }
                        }
                        if (searchforIndex != -1) {
                            let args = [searchforIndex + 1, 0];
                            if (Pan.isArray(toBeInserted)) {
                                Pan.each(toBeInserted, function (item) {
                                    args.push(item);
                                });
                            } else {
                                args.push(toBeInserted);
                            }
                            f.childrenNames.splice.apply(f.childrenNames, args);
                        }
                        delete f.appendChildrenAfterNamedField;
                    }
                }
            } else {
                for (let p = f.childrenNames.length - 1; p >= 0; p--) {
                    if (!fieldMap[f.childrenNames[p]]) {
                        f.childrenNames.splice(p, 1);
                    }
                }
            }
            if (Pan.isFunction(f.modifyChildrenNames)) {
                f.childrenNames = f.modifyChildrenNames(f.childrenNames);
            }
            if (f.childrenPaths) {
                delete f.childrenPaths;
            }
        }

        for (let r = fields.length - 1; r >= 0; r--) {
            let ff = fields[r];
            if (pruneIfNoChildMap[ff.name]) {
                if (!ff.childrenNames || ff.childrenNames.length === 0) {
                    fields.splice(r, 1);
                } else {
                    // when all children are pruned, also prune the parent node
                    for (let s = 0; s < ff.childrenNames.length; s++) {
                        if (!pruneFieldMap[ff.childrenNames[s]]) {
                            break;
                        }
                    }
                    if (s == ff.childrenNames.length) {
                        fields.splice(r, 1);
                    }
                }
            }
        }

        // fourth set additional settinngs
        for (let m = 0; m < fields.length; m++) {
            let fi = fields[m];
            this.fieldVisit(function (ff) {
                //The following logic is for rendering optional 'sequence' types in a certain way based on its child nodes.
                //If all the children of a sequence are optional render it as container
                //If the sequence is optional with at least one required fields inside then it is shown as field set with checkbox toggle
                //If the sequence has only one leaf child that is required then we make that child optional and show it as container
                if (ff.type === 'sequence' && ff.uiHint.allowBlank && ff.childrenNames) {
                    // if not overridden by the programmer, set the allowBlank to false (no checkbox on fieldset)
                    let showAsContainer = !(ff.initialFieldConfig && ff.initialFieldConfig.uiHint && Pan.isDefined(ff.initialFieldConfig.uiHint.allowBlank));
                    if (showAsContainer) {
                        for (let n = 0; n < ff.childrenNames.length; n++) {
                            let childField = fieldMap[ff.childrenNames[n]];
                            if (!childField || !childField.type) continue;
                            if (childField.type === 'sequence' && childField.schemaAllowBlank) { //field set inside fieldset
                                showAsContainer = true;
                                break;
                            }
                            if (!childField.uiHint.allowBlank && childField.nodetype !== 'array') {
                                if (ff.childrenNames.length == 1) {
                                    showAsContainer = true;
                                    Pan.apply(childField.uiHint, {
                                        allowBlank: true
                                    });
                                    Pan.apply(childField, {
                                        allowBlank: true
                                    });
                                    if (childField.uiHint.fieldLabelAutoGen) {
                                        Pan.apply(childField.uiHint, {
                                            fieldLabel: ff.uiHint.fieldLabel || ff.name
                                        });
                                    }
                                    if (!Pan.isDefined(childField.defaultValue)) {
                                        Pan.applyIf(childField.uiHint, {
                                            noneString: 'none'
                                        });
                                    }
                                    Pan.apply(ff.uiHint, {
                                        fieldLabel: ''
                                    });
                                } else {
                                    showAsContainer = false;
                                }
                            }
                        }
                    }
                    if (showAsContainer) {
                        Pan.apply(ff.uiHint, {
                            allowBlank: false
                        });

                        Pan.apply(ff, {
                            allowBlank: ff.uiHint.allowBlank
                        });
                    }
                    return ff;
                } else {
                    return false;
                }
            }, this, fi, fieldMap);
        }
        return this.fields = fields;
    }

    setField(field, attr) {
        let hasTypeOverride = Pan.isDefined(field.type);
        let hasDefaultOverride = Pan.isDefined(field.defaultValue);
        if (attr) {
            // copy stuff not in fieldHint
            Pan.applyIf(field, {
                name: attr.attrPath,
                attrPath: attr.attrPath,
                attrName: attr.attrName,
                schemaAllowBlank: attr.allowBlank
            });

            // apply general fields
            Pan.applyIf(field, attr.fieldHint);
            field.type = field.type || attr['node-type'];
        }

        let uiHint = field.uiHint = field.uiHint || {};

        if (attr) {
            // if star field with no children, star field is the key field
            // @name defaults to key field
            if ((field.isStar && !field.childrenPaths) || (field.attrName === "@name")) {
                Pan.applyIf(uiHint, {
                    isKeyField: true,
                    allowBlank: false
                });
                if (uiHint.allowBlank !== field.allowBlank) {
                    field.allowBlank = uiHint.allowBlank;
                }
            }
        }

        if (attr) {
            // this should be last, because attr.uiHint is from schema.  all user specifications should be first priority
            Pan.applyIf(uiHint, attr.uiHint);
            if (field.attrName === '@name') {
                if (uiHint.regex) {
                    if (attr.subtype === 'object-name') {
                        Pan.applyIf(uiHint, {
                            regexText: uiHint.helpstring
                        });
                    }
                } else if (attr.subtype === 'object-name') {
                    Pan.applyIf(uiHint, {
                        vtype: 'objectName',
                        maxLength: 31
                    });
                }
            }
        }

        if (field.autoComplete) {
            Pan.applyIf(uiHint, {
                builder: 'PanCompletionBuilder'
            });
        }

        if (uiHint.fieldLabel === undefined) {
            let parentNodeAttr, label;
            if (field.isCollection) {
                parentNodeAttr = attr.parentNode["@attr"];
                label = (parentNodeAttr.uiHint.fieldLabel != undefined) ? parentNodeAttr.uiHint.fieldLabel : parentNodeAttr.attrName;
            } else if (field.isStar) {
                parentNodeAttr = attr.parentNode["@attr"].parentNode["@attr"];
                label = (parentNodeAttr.uiHint.fieldLabel != undefined) ? parentNodeAttr.uiHint.fieldLabel : parentNodeAttr.attrName;
                if (label === "") {
                    label = attr.parentNode["@attr"].attrName;
                }
            } else if (field.attrName === "@name" && attr.parentNode["@attr"].isStar) {
                let collectionParent = attr.parentNode["@attr"].parentNode["@attr"];
                label = (collectionParent.parentNode["@attr"].uiHint.fieldLabel != undefined) ? collectionParent.parentNode["@attr"].uiHint.fieldLabel : collectionParent.parentNode["@attr"].attrName;
            } else {
                label = field.name;
                if (attr && attr.attrPath == field.name) {
                    label = attr.attrName;
                }
            }
            uiHint.fieldLabel = label;
            uiHint.fieldLabelAutoGen = uiHint.fieldLabel;
        }
    }

    /**
     * Transverses the specified schema node and call the callbacks on each node.
     * @param rootNode                  The target root node to transverse with.
     * @param {PanSchema~nodeHandler} preChildCallback The callback to call on each node before processing child nodes.
     * @param {PanSchema~nodeHandler} [postChildCallback] The callback to call on each node after processing child nodes.
     * @param [thisArg]                 Optional. Value to use as this when executing `callback`
     * @returns {*}
     */
    transverse(rootNode, preChildCallback, postChildCallback, thisArg) {
        return this.visitInternal(rootNode, null, null, preChildCallback, thisArg, postChildCallback);
    };

    transformSchema(schema) {
        return this.transverse(schema,
            /**
             * Converts array node to "*" sequence node
             * @see PanSchema~nodeHandler
             */
            function (attrs, node, attrName, parentNode) {
                if (attrName == "*") {
                    return;
                }
                if (parentNode && parentNode['@attr']) {
                    let parentAttrs = parentNode['@attr'];
                    // bgn * node logic --- special case logic for * field
                    if (parentAttrs['node-type'] == "array" && attrName.indexOf("@") != 0) {
                        // create new * child node
                        let star = {
                            "@attr": {
                                isStar: true
                            }
                        };
                        if (attrName === "entry" || attrName === "member") {
                            Pan.applyIf(star, node);
                            Pan.applyIf(star["@attr"], node["@attr"]);
                            PanDecode.deleteAllChildren(node);
                            node["@attr"] = Pan.applyIf({ "isCollection": true }, parentAttrs);
                            node["@attr"]['max-count'] = star["@attr"]['max-count'];
                            delete star["@attr"]['max-count'];
                            parentAttrs['node-type'] = "sequence";
                            delete parentAttrs['tlo']; // parent of entry is now a sequence and should not be a tlo
                            delete parentAttrs['ui-field-hint'];
                            delete parentAttrs['uiHint-fieldLabel'];
                            //delete parentAttrs['ui-hint'];
                            /*                parentAttrs['uiHint'] = {
                             autoHeight: true
                             };*/

                            // for autocomplete that is non-multi-types, put the autocomplete on the parent, so that we get a selectable grid
                            //if (star["@attr"].autocomplete && !star["@attr"]["multi-types"]) {
                            if (star["@attr"].autocomplete) {
                                node["@attr"].autocomplete = star["@attr"].autocomplete;
                            }

                            if (star["@attr"].memberof) {
                                node["@attr"].memberof = star["@attr"].memberof;
                            }

                            if (star["@attr"]["multi-types"]) {
                                let defaultValue = star["@attr"]["default"];
                                if (defaultValue) {
                                    node["@attr"]["default"] = [defaultValue];
                                }
                            }
                            node["*"] = star;
                        } else {
                            if (!parentNode["*"]) {
                                Pan.applyIf(star, parentNode);
                                star['@attr']['node-type'] = "sequence";

                                let parentNodeAttr = parentNode["@attr"];
                                PanDecode.deleteAllChildren(parentNode);
                                parentNode["@attr"] = parentNodeAttr;
                                Pan.applyIf(parentNode["@attr"], { "isCollection": true });
                                parentNode["*"] = star;
                            }
                        }
                    }
                    // end * node logic
                }
            },
            /**
             * Process choice and disabled nodes. Choice nodes need to be processed after processing child nodes because
             * it depends on child node information. I'm not sure about disabled nodes.
             * @see PanSchema~nodeHandler
             */
            function (attrs, node, attrName, parentNode) {
                if (parentNode) {
                    // disabled nodes should be removed
                    if (attrs['disabled'] === 'yes') {
                        delete parentNode[attrName];
                    } else if (attrs['node-type'] == "choice") {
                        let parentAttrs = parentNode['@attr'];

                        attrs['choices'] = [];
                        for (let m in node) {
                            if (node.hasOwnProperty(m) && m.indexOf("@") != 0) {
                                parentNode[m] = node[m];
                                attrs['choices'].push(m);
                                attrs['choiceParentAttr'] = parentAttrs;
                                node[m]['@attr']['choiceAttr'] = attrs; // keep the choice node, this is needed in PanType choice dataMap and saveMap
                                node[m]['@attr'].slevel = 1; // add one more to my saveLast level, because the choice child is artificially moved up one level
                                delete node[m];
                            }
                        }
                    }
                }
            }
        );
    };

    /**
     * Prune (remove) the specified node if it contains `prune-on` or `prune-on-sdb` value that matches the environment.
     * This function perform a further adjustment on the node name if it is in the format of `xyz__[0-9]` created due
     * to duplicate node names with difference `prune-on` values.
     * Note that the node renaming takes the last-win entry if there are more than one nodes satisfy the `prune-on`
     * criteria. Typically this indicates an error in the schema.
     */
    processPruneOn(attrs, node, attrName, parentNode) {
        let rv;
        if (parentNode) {
            let pruneOn = attrs['prune-on'];
            // let pruneOnSDB = (attrs['prune-on-sdb'] && getTemplate() !== undefined)
            //                  ? undefined
            //                  : attrs['prune-on-sdb'];
            if (pruneOn) {
                if ((pruneOn && this.pruneSchemaNode(pruneOn))){
                //  ||
                //     (pruneOnSDB && this.pruneSDBSchemaNode(pruneOnSDB))) {
                    delete parentNode[attrName];
                    return true; // stop processing child (if exists)
                }
                else {
                    let oldAttrName = attrName;
                    attrName = attrName.replace(/__[0-9]+$/, '');
                    if (oldAttrName !== attrName) {
                        parentNode[attrName] = node;
                        attrs.oldAttrName = oldAttrName;
                        delete parentNode[oldAttrName];
                        rv = {
                            'renamed': attrName
                        };
                    }
                }
            }
        }
        return rv;
    };

    pruneSchemaNode(pruneOnString) {
        let a = pruneOnString.split(/[ ,]/);
        if (this.schemaPruneOn.length > 0) {
            for (let i = 0; i < a.length; i++) {
                if (this.schemaPruneOn.indexOf(a[i]) >= 0) {
                    return true;
                }
            }
        }
        return false;
    };

    /**
     * Pre-process prune-on schema nodes.
     * For prune-on schema nodes, there could be multiple nodes with the same name.
     * In order to send it over from php to browser in json, they are named with `_*` to
     * keep them unique (for example `minimum-length_5` and `minimum-length_6`).
     * This function will process the json and rename the first prune-on node name from `xyz_n` to `xyz`
     * so that it can pick up correctly by the system.
     */
    preProcessPruneOn(schema) {
        let resp = {};
        resp.config = this.transverse(schema.config, function (attrs, node, attrName, parentNode) {
            let result;
            if (parentNode && !attrs.oldAttrName) {
                let pruneOn = attrs['prune-on'];
                if (pruneOn) {
                    let oldAttrName = attrName;
                    attrName = attrName.replace(/__[0-9]+$/, '');
                    if (oldAttrName !== attrName) {
                        if (!parentNode[attrName]) {
                            parentNode[attrName] = node;
                            attrs.oldAttrName = oldAttrName;
                            delete parentNode[oldAttrName];
                            result = {
                                'renamed': attrName
                            };
                        }
                    }
                }
            }
            return result;
        });
        return resp;
    };
}