import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";
import './RadioWidget.scss';

export function RadioWidget(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autoFocus,
        onChange,
        error,
        showErrors,
    } = props;

    // Generating a unique field name to identify this set of radio buttons
    const name = Math.random().toString();
    const { enumOptions, inline } = options;
    const invalid = showErrors && !!error;
    const invalidClass = invalid ? ' is-invalid' : '';
    // checked={checked} has been moved above name={name}, As mentioned in #349;
    // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.

    return (
        <div className={'field-radio-group form-control' + invalidClass}>
            {enumOptions.map((option, i) => {
                return (
                    <FormGroup
                        key={option.value}
                        check
                        inline={inline}
                        disabled={disabled || readonly}
                    >
                        <Label check>
                            <Input
                                type="radio"
                                checked={option.value === value}
                                name={name}
                                required={required}
                                value={option.value}
                                disabled={disabled || readonly}
                                autoFocus={autoFocus && i === 0}
                                onChange={_ => onChange(option.value)}
                            />{' '}
                            {option.label}
                        </Label>
                    </FormGroup>
                );
            })}
        </div>
    );
}

RadioWidget.defaultProps = {
    autoFocus: false,
    required: false,
    readonly: false,
    disabled: false,
};

if (process.env.NODE_ENV !== 'production') {
    RadioWidget.propTypes = {
        id: PropTypes.string.isRequired,
        /**
         * options:{enumOptions:[{value:string,label:string}]}
         */
        options: PropTypes.shape({
            enumOptions: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }),
            ),
            inline: PropTypes.bool,
        }).isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        error: PropTypes.string,
        showErrors: PropTypes.bool,
    };
}
