import React from 'react';
import PropTypes from 'prop-types';
import Pan from '../../schema/Pan';
import { FormContext, FormConsumer } from '../../FormContext';
import { TagWidget } from '../../widgets/TagWidget';
import { BaseFieldBuilder } from '../BaseBuilder';
import { FormGroup, Col } from 'reactstrap';
import {
    getCompletionLoadConfig,
    getFieldLabelComp,
    getFieldWidthClass,
    getCompletionXpath,
} from '../../schema/utils';
import classnames from 'classnames';
import './TagBuilder.scss';
import { getGenericAutoComplete } from '../../../../core/services/CommonServices';
import { getStore, getStoreState } from '../../../utils/storeRegistry';
import { getPathValue } from '../../../utils/json';
import { FormFeedbackWidget } from '../../widgets/FormFeedbackWidget';

export class TagBuilder extends BaseFieldBuilder {
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = super.setRecordValue.bind(this);
        this.setNewTag = this.setNewTag.bind(this);
        this.state = {
            loading: false,
            value: null,
            newTag: null
        };
    }

    setNewTag(newRecords) {
        console.log('newRecord', newRecords);
        const { newTag } = this.state;
        const currentValue = this.state.value || this.getRecordValue() || [];
        currentValue.push({
            label: newTag,
            value: newTag,
        });
        this.setState({
            value: currentValue,
            newTag: null
        });
    }

    onCreateOption = tag => {
        let tagObj = {
            '@name': tag,
        };
        let store = getStore();
        let context = getStoreState();
        let dg = context ? getPathValue(context, 'main.configLocation.dg') : null;
        let location = dg ? { dg: dg } : null;
        let serviceName = 'Objects/Tags';
        if (location) {
            store.dispatch({
                type: 'ADD_DG_ITEM',
                dgItem: tagObj,
                serviceName: serviceName,
                reduxStateId: 'tagsObjects',
                location: location,
                successCallback: this.setNewTag,
                serviceMap: {
                    GET: serviceName,
                    POST: serviceName,
                    PUT: serviceName,
                    DELETE: serviceName,
                    CLONE: serviceName,
                },
            });
            this.setState({
                newTag: tag
            });
        }
    };

    renderTagField = () => {
        const {
            name,
            className,
            field,
            disabled,
            onBlur,
            onFocus,
            containerLabelWidthSize,
            isEditorGridColumnEditor,
            showErrors,
        } = this.props;

        const completionConfig = getCompletionLoadConfig(field);
        const completionServiceFunction =
            completionConfig && completionConfig.CustomCompletion
                ? completionConfig.CustomCompletion
                : getGenericAutoComplete;
        let completionXpath = getCompletionXpath(
            this.props.field.attrPath,
            this.props.filters,
            this.context.formData,
            this.context.recordConfigPath,
        );
        const { configLocation } = this.context;
        const value = this.state.value || this.getRecordValue();
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? '' : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(
            field,
            name,
            isEditorGridColumnEditor,
            containerLabelWidthSize,
        );
        let fieldClassName = getFieldWidthClass(
            field,
            isEditorGridColumnEditor,
        );
        return (
            <FormGroup className={classnames(className, 'd-flex flex-row')} test_id={field.attrPath}>
                {LabelComp}
                <Col>
                    <div className={'select-display-same-row ' + fieldClassName} style={{ padding: 0 }}>
                        <TagWidget
                            id={this.id}
                            name={name}
                            value={value}
                            configLocation={configLocation}
                            onChange={this.setRecordValue}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            isDisabled={disabled}
                            loadOptions={completionServiceFunction}
                            error={error}
                            showErrors={showErrors}
                            completionXpath={completionXpath}
                            onCreateOption={this.onCreateOption}
                        />
                    </div>
                    {error && showErrors && !disabled && (<FormFeedbackWidget target={this.id} feedback={error} />)}
                </Col>
            </FormGroup>
        );
    };

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderTagField()}</FormConsumer>
            </React.Fragment>
        );
    }
}

TagBuilder.contextType = FormContext;

TagBuilder.defaultProps = {
    disabled: false,
    field: {},
};

if (process.env.NODE_ENV !== 'production') {
    TagBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        containerLabelWidthSize: PropTypes.string,
        isEditorGridColumnEditor: PropTypes.bool,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onChange: PropTypes.func,
        showErrors: PropTypes.bool,
        cellInfo: PropTypes.object,
        /**
        * *attrPath* - defines the attribute schema path eg: "$.a.b.c"
        * *defaultValue* - sets the default value of the field.
        * UiHint properties :
        * *allowBlank* - if false,blank values will add error
        * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
        * *fieldWidthSize* - The size of the label - ranges from 1 to 12.
        * Takes precedence over containerLabelWidthSize
        * *fieldLabel* - adds a label to the field
        * *hideLabel* - hides the label
        * *customValidation* - custom function for validation
        * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
        * The string values available are -
        * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
        * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
        * isIpAddressMask,ipRange,multiVtype
        * completionConfig properties :
        * *CustomCompletion* - custom function to fetch options array
        * *options* - array of options displayed in the widget
        */
        field: PropTypes.shape({
            defaultValue: PropTypes.string,
            attrPath: PropTypes.string,
            uiHint: PropTypes.shape({
                completionConfig: PropTypes.oneOfType([
                    PropTypes.shape({
                        type: PropTypes.string,
                        CustomCompletion: PropTypes.func,
                        fieldPath: PropTypes.string,
                        valueField: PropTypes.string,
                        props: PropTypes.shape({
                            options: PropTypes.arrayOf(
                                PropTypes.shape({
                                    label: PropTypes.string,
                                    value: PropTypes.string,
                                }),
                            ),
                        }),
                    }),
                    PropTypes.string,
                ]),
                allowBlank: PropTypes.bool,
                labelWidthSize: PropTypes.string,
                fieldWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                vtype: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            }),
        }),
    };
}
