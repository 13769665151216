import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getBuilder } from '../../../utils/BuilderManager';
import { Card, CardBody, CardTitle, CardText, FormGroup } from "reactstrap";
import { FormContext, FormProvider } from '../../FormContext';
import { getField, getFieldLabel, setState, getLeafNodesMapForDefaults, deepEquals } from "../../schema/utils";
import classnames from 'classnames';
import Pan from "../../schema/Pan";
import _ from "lodash";
import { formDataPreProcess, formDataPostProcess, formDataSetDefaults } from "../util";
import { Wizard } from '../Wizard';
import { getPathValues, getPathValue } from '../../../utils/json';
import { associationService } from '../../AssociationService';

export class Stage extends Component {
    constructor(props) {
        super(props);

        this.updateStateWithErrors = _.debounce(this.updateStateWithErrors.bind(this), 250, { trailing: true, leading: true });
        this.updateFormState = _.debounce(this.updateFormState.bind(this), 250, { trailing: true, leading: true });
        this.onChange = this.onChange.bind(this);
        this.addError = this.addError.bind(this);
        this.removeError = this.removeError.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.next = this.next.bind(this);
        this.wizard = React.createRef();
        this.errors = Pan.clone(props.errors || {});
    }

    errors = {};
    formState = {};
    fieldIds = [];

    componentDidUpdate() {
        this.formState = this.props.formData || {};
        let stageErrors = !Pan.isEmpty(this.errors) ? this.errors : this.state ? this.state.errors : null;
        stageErrors = stageErrors || {};
        if (!deepEquals(this.state.errors, stageErrors)) {
            this.setState({
                errors: stageErrors
            })
        }
    }

    componentWillUnmount() {
        // update the changes to workflowviewer
        if (this.props.onChange) {
            this.props.onChange(this.state, this.formState);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const schema = nextProps.schema;
        const fields = nextProps.fields;
        let {
            recordConfigPath,
            formData,
            formDataBackup,
            formatFormData,
            configLocation,
            rbaPermission,
            locationPermission,
            activeStage,
            isNewRecord,
            filters
        } = nextProps;
        let showErrors = Pan.isBoolean(nextProps.showErrors) ? nextProps.showErrors : true;
        let formDataCopy = prevState && !Pan.isEmpty(prevState.formData) ? Pan.clone(prevState.formData) : Pan.clone(formData);
        let isSetDefaults = false;
        let activeStageField = getField(fields, activeStage);
        let activeStageRecConfigPath = activeStageField ? activeStageField.recordConfigPath : null;
        let isStageEmpty = activeStageRecConfigPath ? getPathValue(formDataCopy, activeStageRecConfigPath) === false : false;
        if (formatFormData) {
            if ((!prevState || !prevState.isSetDefaults) && isStageEmpty) {
                let leafNodesMap = getLeafNodesMapForDefaults([activeStage], fields);
                formDataCopy = formDataSetDefaults(formDataCopy, fields, leafNodesMap[activeStage], filters);
            }
            formDataCopy = formDataPreProcess(formDataCopy);
        }
        isSetDefaults = true;

        return {
            schema,
            fields,
            formData: formDataCopy,
            formDataBackup,
            recordConfigPath,
            isNewRecord,
            errors: (prevState && prevState.errors) || {},
            showErrors,
            configLocation,
            rbaPermission,
            locationPermission,
            isSetDefaults,
            isRecordDirty: !Pan.isEmpty(nextProps.formState),
        }
    }

    updateStateWithErrors() {
        let errorList = Pan.clone(this.errors);
        this.setState({
            errors: errorList
        });
    }

    updateFormState() {
        let fieldIds = [...this.fieldIds];
        this.fieldIds = [];
        this.setState(this.formState, () => {
            for (let fieldId of fieldIds) {
                associationService.sendEvent(fieldId);
            }
        });
    }

    addError(errorObj) {
        Pan.apply(this.errors, errorObj);
        this.updateStateWithErrors();
    }

    removeError(errorKey) {
        if (errorKey && this.errors[errorKey]) {
            delete this.errors[errorKey]
        }
        this.updateStateWithErrors();
    }

    onChange(data) {
        let state = { ...data };
        // accumulate fieldIds to an array for Rxjs Event service
        this.fieldIds = [...this.fieldIds, state.fieldId];
        // delete fieldId as this is not required
        delete state.fieldId;

        Pan.apply(this.formState, state);
        this.updateFormState();
    }

    onBlur = (...args) => {
        if (this.props.onBlur) {
            this.props.onBlur(...args);
        }
    }

    onFocus = (...args) => {
        if (this.props.onFocus) {
            this.props.onFocus(...args);
        }
    }

    onSubmit = event => {
        event.preventDefault();
        if (!Pan.isEmpty(this.state.errors)) {
            this.setState({
                showErrors: true
            });
            return;
        }

        if (this.props.onSubmit) {
            if (this.props.formatFormDataPostProcess) {
                let formData = Pan.clone(this.state.formData);
                formDataPostProcess(formData);
                let state = { ...this.state, formData };
                setState(this, state, () => {
                    this.props.onSubmit({ ...this.state, status: "submitted" });
                });
            } else {
                this.props.onSubmit({ ...this.state, status: "submitted" });
            }
        }
    }

    onSpecialSubmit = event => {
        event.preventDefault();
        if (this.props.onSpecialSubmit) {
            if (this.props.formatFormDataPostProcess) {
                let formData = Pan.clone(this.state.formData);
                formDataPostProcess(formData);
                let state = { ...this.state, formData };
                setState(this, state, () => {
                    this.props.onSpecialSubmit({ ...this.state, status: "submitted" });
                });
            } else {
                this.props.onSpecialSubmit({ ...this.state, status: "submitted" });
            }
        }
    }

    onGoToSummary = event => {
        this.props.onGoToSummary();
    }

    onGoToMapPage = event => {
        this.onSpecialSubmit(event);
        this.props.onGoToMapPage();
    }

    buildSteps = (field, fields, filters) => {
        // childrenNames is required
        const childrenNames = field.childrenNames;
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        let stageNumber = field.uiHint.stageNumber;

        let steps = childrenNames.filter(name => {
            const thisField = getField(fields, name);
            let avail = true;
            if (thisField.uiHint.avail) {
                avail = thisField.uiHint.avail();
            }
            return avail;
        });

        return steps.map(name => {
            const Builder = getBuilder({name, fields});
            const thisField = getField(fields, name);
            const fieldLabel = getFieldLabel(thisField);
            const { hideStepHeader, stepTitle, fieldDescription, showStageNumber, showWideForm } = thisField.uiHint;

            const builder =
                <Card className="wizard-step form-group">
                    <CardBody>
                        {!hideStepHeader &&
                            <div className="mb-4">
                                <CardTitle>
                                    <div className="stageMainTitle">
                                        {showStageNumber !== false && <div className="numberCircle">{stageNumber}</div>}
                                        <div className="stageTitle">{stepTitle}</div>
                                    </div>
                                </CardTitle>
                                <CardText>{fieldDescription}</CardText>
                            </div>
                        }
                        <Builder
                            key={name}
                            name={name}
                            field={thisField}
                            onChange={this.onChange}
                            addError={this.addError}
                            removeError={this.removeError}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            onEdit={this.onEdit}
                            filters={filters}
                            containerLabelWidthSize={containerLabelWidthSize}
                            showErrors={this.state.showErrors}
                            onGoToSummary={this.onGoToSummary.bind(this)}
                            onSubmit={this.onSubmit}
                            actionMap={this.props.actionMap}
                        />
                    </CardBody>
                </Card>

            return ({
                name: fieldLabel,
                stepName: name,
                field: thisField,
                showWideForm,
                component: builder
            })
        });
    }

    isValidated(stepField) {
        let { errors, formData } = this.state;
        let childrenNames = stepField.childrenNames;
        let childInError = false;
        if (!Pan.isEmpty(errors)) {
            for (let key of childrenNames) {
                if (key.endsWith('choice')) {
                    key = key.substr(0, key.length - 7);
                }
                for (let err in errors) {
                    if (err.indexOf(key) >= 0) {
                        childInError = true;
                        break;
                    }
                }
                if (childInError)
                    break;
            }
        }
        return (!childInError && !Pan.isEmpty(formData));
    }

    next(currentStep, _context, _next) {
        const { fields } = this.state;
        const { itemId } = this.props;
        const { formData, formDataBackup, isNewRecord } = _context;
        const field = getField(fields, itemId);
        const onNext = field.uiHint.onNext;
        if (!onNext) {
            return _next && _next();
        }
        onNext(currentStep, formData, formDataBackup, isNewRecord, _next);
    }

    render() {
        let { id, itemId, className, filters } = this.props;
        filters = filters || [];

        const { fields } = this.state;
        const field = getField(fields, itemId);
        const steps = this.buildSteps(field, fields, filters);
        const { jumpToSummaryFromStep, jumpToMapPage } = field.uiHint;;

        return (
            <FormProvider value={this.state}>
                <div id={id} className={classnames(className, "d-flex flex-column border border-light")}>
                    <FormGroup>
                        <Wizard
                            ref={this.wizard}
                            steps={steps}
                            showSteps={this.props.showSteps}
                            preventEnterSubmission={true}
                            nextTextOnFinalActionStep={this.props.nextTextOnFinalActionStep || 'Next'}
                            customValidationToSpecialSubmit={this.props.customValidationToSpecialSubmit}
                            prevBtnOnLastStep={false}
                            backButtonText={this.props.backButtonText || 'Back'}
                            backButtonCls={this.props.backButtonCls}
                            startAtStep={0}
                            jumpToSummaryFromStep={jumpToSummaryFromStep}
                            jumpToMapPage={jumpToMapPage}
                            onSubmit={e => this.onSubmit(e)}
                            onSpecialSubmit={e => this.onSpecialSubmit(e)}
                            onGoToSummary={e => this.onGoToSummary(e)}
                            onGoToMapPage={e => this.onGoToMapPage(e)}
                            onCancel={this.props.onCancel}
                            onNext={this.next}
                            errors={this.state.errors}
                            isValidated={this.isValidated}
                            onStepChange={step => console.log('Step - ' + step)}
                            goToSummary={!this.props.skipGoToSummary}
                            singleStepForAdd={this.props.singleStepForAdd}
                            fromEditToSummary={this.props.fromEditToSummary}
                        />
                    </FormGroup>
                </div>
            </FormProvider>
        );
    }
}

Stage.contextType = FormContext;

Stage.defaultProps = {
    schema: {},
    fields: []
};

if (process.env.NODE_ENV !== "production") {
    Stage.propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        itemId: PropTypes.string,
        containerLabelWidthSize: PropTypes.string,
        schema: PropTypes.object.isRequired,
        fields: PropTypes.array,
        showErrors:PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onSubmit: PropTypes.func,
        formatFormDataPostProcess: PropTypes.bool,
        onSpecialSubmit: PropTypes.func,
        onGoToMapPage: PropTypes.func,
        onGoToSummary: PropTypes.func,
        needJumpToMapPage:PropTypes.bool,
        filters:PropTypes.array,
        showSteps:PropTypes.bool,
        nextTextOnFinalActionStep:PropTypes.string,
        backButtonText:PropTypes.string,
        onCancel:PropTypes.func,
        hideEditToSummary:PropTypes.bool,
        fromEditToSummary:PropTypes.func,
        recordConfigPath:PropTypes.string,
        errors:PropTypes.object,
        formatFormData:PropTypes.bool,
        configLocation:PropTypes.object,
        rbaPermission:PropTypes.string,
        locationPermission:PropTypes.string,
        activeStage:PropTypes.string,
        isNewRecord:PropTypes.bool,
    };
}