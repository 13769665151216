import "./Main.css";
import React, { Component } from "react";
import {
	isUserLoggedInAction,
	hideSuccessMessage,
	fetchLicenseInfo,
	fetchTokenInfo,
	fetchApplicationsInfo
} from "./actions";
import { 
	withRouter, 
	RouteViewer, 
	SpinnerWidget, 
	FeedbackWidget, 
	Pan, 
	getPathValue, 
	getLoginServiceURL, 
	connect, 
	ModalStackWidget, 
	LoadingWidget, 
	registerMapboxToken,
	registerAGGridLicenseKey
} from "ui-lib";
import { navigation, defaultRoutes } from "../../routes";
import AppBar from "./AppBar";
import "@pan/cortex-app-bar";
import logo from "./Prisma-Access-Logo.svg";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Main extends Component {
	constructor(props) {
		super(props);
		this.onRevertSuccess = this.onRevertSuccess.bind(this);

		let { history } = props;
		history.listen(this.routeChange);
	}

	state = {
		selectedItem: "dashboard",
		hasUserRBA: false,
		defaultRoutes: defaultRoutes,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!prevState.hasUserRBA) {
			let userRba = getPathValue(nextProps, "loggedInUser.rba");
			if (!Pan.isEmpty(userRba)) {
				return {
					hasUserRBA: true
				};
			}
		}
		return null;
	}

	componentDidUpdate() {
		const {
			isLoggedIn,
			loggedInUser,
			licenseInfo,
			tokenInfo,
			fetchLicenseError,
			fetchTokenError,
			fetchApplicationsError,
			content: {
				applications,
				fetchingApplications
			} = {},
		} = this.props;

		if (isLoggedIn === false) {
			let loginUrl = getLoginServiceURL("api/sso/v1/login")["SERVICE_URL"];

			const urlParams = new URLSearchParams(window.location.search);
			const tenantId = urlParams.get("tenantId");

			if (tenantId) {
				loginUrl += `?tenantId=${tenantId}`;
			}
			window.location = loginUrl;
		}

		if (isLoggedIn === true && loggedInUser && Pan.isEmpty(licenseInfo) && !fetchLicenseError) {
			this.props.dispatch(fetchLicenseInfo());
		}

		if (isLoggedIn === true && Pan.isEmpty(tokenInfo) && !fetchTokenError) {
			this.props.dispatch(fetchTokenInfo());
		} else if (isLoggedIn === true && tokenInfo) {
			registerAGGridLicenseKey(tokenInfo.agGrid);
			registerMapboxToken(tokenInfo.mapBox);
		}

		if (isLoggedIn === true && loggedInUser && Pan.isEmpty(applications) && !fetchingApplications && !fetchApplicationsError) {
			this.props.dispatch(fetchApplicationsInfo());
		}

		const cortexAppBar = document.querySelector('cortex-app-bar')
		let logoutUrl = getLoginServiceURL("api/sso/v1/logout")["SERVICE_URL"];
		if (cortexAppBar) {
			cortexAppBar.hasBeenMount = () => {
				cortexAppBar.shadowRoot.querySelector('.cortex-appbar-skelton').querySelector('.cortex-brand-container').style.cursor = 'default';
			}

			cortexAppBar.linkHandler = (args) => {
				if (args === 'logo') {
					console.log('sign out page API be here')
				}
			}

			cortexAppBar.addEventListener('cortex-sign-out', () => {
				window.location = logoutUrl;
			})
		}
	}

	routeChange = (location, action) => {
		const pathname = location.pathname;
		const keys = ['dashboard', 'explore', 'policies', 'objects', 'configure'];
		const selected = keys.find(key => {
			return pathname.startsWith('/' + key);
		});
		const appSelected = selected || 'dashboard';
		const { defaultRoutes } = this.state;
		defaultRoutes[appSelected] = pathname;
		this.setState({ appSelected, defaultRoutes });
	};

	navSelection(url) {
		this.setState({ selectedItem: url });
	}

	componentDidMount() {
		if(window.location.search.endsWith("loginFailed=true")) {
			alert("We were unable to log you in. Your session may have expired or you do not have access to this tenant.");
			window.location = getLoginServiceURL("api/sso/v1/logout")["SERVICE_URL"];
		} else {
			this.props.dispatch(isUserLoggedInAction());
		}
	}

	onOk() {
		this.props.dispatch(hideSuccessMessage());
	}

	onRevertSuccess() {
		window.location.reload();
	}

	renderCortexAppBar(loggedinUser, tenant) {
		const { pendo } = window;
		let { currentSupportAccountId, email, profile } = loggedinUser || {};
		let { accesstoken, pingcert, notonorafter, firstname, lastname, supportaccountids } = profile || {};
		let account = _.find(JSON.parse(supportaccountids), {
			accountid: parseInt(currentSupportAccountId || 0)
		});
		let companyName = account && account.name ? account.name : "Palo Alto Networks";
		let userName = firstname + " " + lastname;
		let userData = {
			userName,
			email,
			companyName
		};
		let rbac = { user: "data" };

		pendo.initialize({
			visitor: {
				id: email,
				email,
				displayName: userName
			},
			account: {
				id: currentSupportAccountId
			}
		});

		return (
			<cortex-app-bar
				id="pan-nav"
				accesstoken={accesstoken}
				pingcert={pingcert}
				rbac={JSON.stringify(rbac)}
				showAppSwitcher={true}
				notonorafter={notonorafter}
				supportedAccounts={supportaccountids}
				userData={JSON.stringify(userData)}
				appTitle="Prisma Access"
				appBarLogo={logo}
				selectedAccount={currentSupportAccountId}
				_baseURL={`${window.__admin_cluster_url}/api`}
			>
				<AppBar
					hasUserRBA={this.state.hasUserRBA}
					loggedInUser={loggedinUser}
					tenant={tenant}
					defaultRoutes={this.state.defaultRoutes}
				/>
			</cortex-app-bar>
		);
	}

	render() {
		let {isLoggedIn,loggedInUser,tenant,modals,licenseInfo, tokenInfo} = this.props;
		let defaultRoutes = this.state.defaultRoutes;
		if(!_.isEmpty(licenseInfo) && defaultRoutes['configure'] === '') {
			if(licenseInfo['mu-license-expiry'] && new Date(licenseInfo['mu-license-expiry']) - Date.now() > 0 ) {
				defaultRoutes['configure'] = '/configure/mobileusers';
			}else if(licenseInfo['rn-license-expiry'] && new Date(licenseInfo['rn-license-expiry']) - Date.now() > 0) {
				defaultRoutes['configure'] = '/configure/remotenetworks';
			}else{
				defaultRoutes['configure'] = '/configure/serviceconnections';
			}
		}
		// let loggedInUser = this.props.loggedInUser;
		// let tenant = this.props.tenant;
		// let modals = this.props.modals;
	
		// null -> show spinner and make a login check
		// false -> show login button as user not loggedin
		// true -> show main app
		return (
			<div>
				{this.props.success && (
					<FeedbackWidget
						isOpen={this.props.success}
						message={this.props.successMessage}
						actions={[{ text: "Ok", action: this.onOk.bind(this) }]}
						type="Error"
						title="Success"
					/>
				)}

				<ModalStackWidget modals={modals} />

				{isLoggedIn === null && <SpinnerWidget />}
				{/* {isLoggedIn === false && <NavLogin />} */}
				{isLoggedIn === true && loggedInUser && licenseInfo && tokenInfo && (
					<div>
						<RouteViewer
							appBar={this.renderCortexAppBar.bind(this)(loggedInUser, tenant)}
							loggedInUser={loggedInUser}
							tenant={tenant}
							navigation={navigation}
							configLocation={this.props.configLocation}
							licenseInfo={licenseInfo}
							defaultRoutes={defaultRoutes}
						/>
					</div>
				)}
				<ToastContainer
					enableMultiContainer
					containerId={"mainToastContainer"}
					position="top-center"
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					autoClose={5000}
					pauseOnHover
					style={{
						width: "550px"
					}}
				/>
				<ToastContainer
					enableMultiContainer
					containerId={"pollingToastContainer"}
					position="top-right"
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					autoClose={5000}
					pauseOnHover
					style={{
						width: "550px"
					}}
				/>
				{this.props.loading && this.props.loading.show && <LoadingWidget renderTo={this.props.loading.renderTo} />}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		loggedInUser: state.main ? state.main.loggedInUser : null,
		tenant: state.main ? state.main.tenant : null,
		extensions: state.main ? state.main.extensions : {},
		isLoggedIn: state.main ? state.main.isLoggedIn : null,
		showRevertSuccess: state.main ? state.main.showRevertSuccess : false,
		revertMsg: state.main ? state.main.revertMsg : "",
		commitType: state.main ? state.main.commitType : "commit",
		error: state.main ? state.main.error : null,
		errorMessage: state.main ? state.main.errorMessage : null,
		extra: state.main ? state.main.extra : null,
		success: state.main ? state.main.success : null,
		successMessage: state.main ? state.main.successMessage : null,
		configLocation: state.main ? state.main.configLocation : null,
		licenseInfo: state.main ? state.main.licenseInfo : null,
		tokenInfo: state.main ? state.main.tokenInfo : null,
		fetchLicenseError: state.main && state.main.fetchLicenseError,
		fetchTokenError: state.main && state.main.fetchTokenError,
		modals: state.main ? state.main.modals : [],
		loading: state.main ? state.main.loading : {},
		content: state.main ? state.main.content : {},
		fetchApplicationsError: state.main && state.main.fetchApplicationsError,
	};
};

export default withRouter(connect(mapStateToProps)(Main));
