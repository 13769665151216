import { getPathValues } from 'ui-lib';
import { 
    LOAD_CONFIG_ITEMS,
    START_FORM_LOADING,
	FINISH_FORM_LOADING,
	START_GRID_LOADING,
	FINISH_GRID_LOADING,
	HIDE_FORM,
    SHOW_FORM,
    SAVE_CONFIG_SUCCESS 
} from 'service-lib';
import {
    LOAD_GPCS_ITEMS,
    START_GPCS_LOADING,
    FINISH_GPCS_LOADING,
    HIDE_WORKFLOW,
    SHOW_WORKFLOW,
    START_WORKFLOW_LOADING,
    FINISH_WORKFLOW_LOADING,
    HIDE_GPCS_ERROR,
    GET_GLOBAL_MAP_REGION_INFO_SUCCESS,
    DO_MU_COMMIT_PUSH_SUCCESS,
    FETCH_MU_JOB_DETAILS_SUCCESS,
    POLLING_MU_STATUS_SUCCESS,
    GET_NOTIFICATION_SUCCESS,
    POST_NOTIFICATION_SUCCESS,
    GET_NETWORK_DETAIL_DATA_SUCCESS,
    SHOW_GPCS_FORM,
    HIDE_GPCS_FORM,
    REMOTE_NETWORK_DETAIL_DATA_SUCCESS,
    FETCH_SSL_DECRYPTION_SUCCESS,
    SERVICE_CONNECTION_DETAIL_DATA_SUCCESS,
    MOBILE_USERS_NETWORK_DETAILS_DATA_SUCCESS,
    GET_API_KEY_SUCCESS,
    GENERATE_API_KEY_SUCCESS,
    FETCH_GPCS_CONFIG_STATUS_SUCCESS,
    BGP_STATUS_DATA_SUCCESS,
    MU_PROVISIONING_SUCCESS,
    STOP_POLLING_MU_PROVISION_STATUS,
    MOBILE_USERS_CHECK_PORTAL_FQDN_SUCCESS,
    UPDATE_GPCS_REDUX_STATE
} from './actions';

const ACTION_HANDLERS = {
    [LOAD_CONFIG_ITEMS]: (state, { configItems, reduxStateId, successCallback }) => {
        let items = configItems && configItems.response ? configItems.response : {};
        successCallback && successCallback(items);
        return {
            ...state,
            [reduxStateId.reduxId]: items
        };
    },
    [START_GRID_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], GRID_LOADING: true }
		};
	},
	[FINISH_GRID_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], GRID_LOADING: false }
		};
	},
	[START_FORM_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], FORM_LOADING: true }
		};
	},
	[FINISH_FORM_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], FORM_LOADING: false }
		};
    },
    [SAVE_CONFIG_SUCCESS]: (state, { reduxStateId, success }) => {
		return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId], SAVE_SUCCESS: success }
		};
	},
	[HIDE_FORM]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], SHOW_FORM: false }
		};
	},
	[SHOW_FORM]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], SHOW_FORM: true }
		};
	},
    [LOAD_GPCS_ITEMS]: (state, { items, reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: items && items.response ? items.response : {},
        };
    },
    [START_GPCS_LOADING]: (state, { reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId], pushJobId:undefined,GPCS_LOADING: true },
        };
    },
    [FINISH_GPCS_LOADING]: (state, { reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId], pushJobId:undefined,GPCS_LOADING: false },
        };
    },
    [START_WORKFLOW_LOADING]: (state, { reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId],pushJobId:undefined, WORKFLOW_LOADING: true },
        };
    },
    [FINISH_WORKFLOW_LOADING]: (state, { reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId],pushJobId:undefined, WORKFLOW_LOADING: false },
        };
    },
    [HIDE_WORKFLOW]: (state, { reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId],pushJobId:undefined, SHOW_WORKFLOW: false },
        };
    },
    [SHOW_WORKFLOW]: (state, { reduxStateId }) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId],pushJobId:undefined, SHOW_WORKFLOW: true },
        };
    },
    [HIDE_GPCS_ERROR]: (state) => {
        return {
            ...state,
            error: false,
            errorMessage: null
        }
    },
    [GET_GLOBAL_MAP_REGION_INFO_SUCCESS]: (state, { info, globalMapRegionInfo, regionInfo }) => {
        return {
            ...state,
            info,
            globalMapRegionInfo,
            regionInfo
        };
    },
    [DO_MU_COMMIT_PUSH_SUCCESS]: (state, { response }) => {
        let jid = getPathValues(response, 'response.result.result.job')[0];
        if (jid) {
            return { ...state, showMapPage: true, pushJobId: getPathValues(response, 'response.result.result.job')[0],jobDetails:undefined };
        }
        else {
            return { ...state, error: true,pushJobId:undefined, errorMessage: 'Unable to do commit and push',jobDetails:undefined };
        }
    },
    [FETCH_MU_JOB_DETAILS_SUCCESS]: (state, { response }) => {
        let data = [];
        if (getPathValues(response, 'response.data')) {
            data = getPathValues(response, 'response.data')[0];
        }
        return { ...state, pushJobId:undefined,jobDetails: { jobPercent: data[0]['percent'], jobStatus: data[0]['status_str'], jobResult: data[0]['result_str'],details:data[0]['details'] } };
    },
    [POLLING_MU_STATUS_SUCCESS]: (state, { mapPageData, pollingSuccess }) => {
        return {
            ...state,
            pollingSuccess,
            mapPageData
        };
    },
    [GET_NOTIFICATION_SUCCESS]: (state, { notification_url }) => {
        return {
            ...state,
            notification_url
        };
    },
    [POST_NOTIFICATION_SUCCESS]: (state, { notification_url, notification_status }) => {
        return {
            ...state,
            notification_url,
            notification_status
        };
    },
    [GET_NETWORK_DETAIL_DATA_SUCCESS]: (state, { service_infra_status }) => {
        return {
            ...state,
            service_infra_status
        };
    },
    [SHOW_GPCS_FORM]: (state, {reduxStateId}) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId], SHOW_GPCS_FORM: true }
        };
    },
    [HIDE_GPCS_FORM]: (state, {reduxStateId}) => {
        return {
            ...state,
            [reduxStateId]: { ...state[reduxStateId], SHOW_GPCS_FORM: false }
        };
    },
    [FETCH_GPCS_CONFIG_STATUS_SUCCESS]: (state, { gpcs }) => {
        return {
            ...state,
            gpcs_status: gpcs
        };
    },
    [REMOTE_NETWORK_DETAIL_DATA_SUCCESS]: (state, { remoteNetworkDetails }) => {
        return {
            ...state,
            remoteNetworkDetails
        };
    },
    [FETCH_SSL_DECRYPTION_SUCCESS]: (state, { sslDecryptionDetails }) => {
        return {
            ...state,
            sslDecryptionDetails
        };
    },
    [SERVICE_CONNECTION_DETAIL_DATA_SUCCESS]: (state, { serviceConnectionNetworkDetails }) => {
        return {
            ...state,
            serviceConnectionNetworkDetails
        };
    }, 
    [MOBILE_USERS_NETWORK_DETAILS_DATA_SUCCESS]: (state, { mobileUsersNetworkDetails }) => {
        return {
            ...state,
            mobileUsersNetworkDetails
        };
    }, 
    [GET_API_KEY_SUCCESS] : (state, { apiKey }) => {
        return {
            ...state,
            apiKey
        };
    },
    [GENERATE_API_KEY_SUCCESS] : (state, { apiKey }) => {
        return {
            ...state,
            apiKey
        };
    },
    [BGP_STATUS_DATA_SUCCESS]: (state, { bgpStatus }) => {
        return {
            ...state,
            bgpStatus
        };
    }, 
    [MU_PROVISIONING_SUCCESS]: (state, { muProvisionStatus }) => {
        return {
            ...state,
            muProvisionStatus
        };
    },
    [STOP_POLLING_MU_PROVISION_STATUS]: (state, { muProvisionStatus }) => {
        return {
            ...state,
            muProvisionStatus
        };
    },
    [MOBILE_USERS_CHECK_PORTAL_FQDN_SUCCESS]: (state, { fqdnAvail }) => {
        return {
            ...state,
            fqdnAvail
        };
    },
    [UPDATE_GPCS_REDUX_STATE]: (state, { item, reduxStateId }) => {
        let updatedItem = state[reduxStateId];
        if(updatedItem && updatedItem['result'] && updatedItem['result']['entry']) {
            updatedItem['result']['entry'] = [item];
        } else {
            updatedItem = {
                result: {
                    entry: [item]
                }
            };
        }
        return {
            ...state,
            [reduxStateId]: updatedItem
        };
    }
};

const initialState = {
    info:[],
    globalMapRegionInfo:[],
    regionInfo:[]
};

export const gpcsItemsReducer = (state = initialState, action) => {
    const locationPath = (action.reduxStateId && action.reduxStateId.locationPath) || action.locationPath;
    if (locationPath && locationPath !== 'gpcs') {
        return state;
    }
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
