import React from "react";
import { Label } from "reactstrap";
import { getPathValue, getRecordPathWithFilters } from "../../utils/json";
import "core-js";
import Pan from "../schema/Pan";
import { VSYS } from '../../services/CommonServices';
import { get, isArray, isString, each, escape } from 'lodash'

export function capitalizeLetter(str) {
    if (!Pan.isDefined(str)) {
        return '';
    }

    str = str.replace(/-/g, ' ');
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        if (str[i]) {
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }
    }

    return str.join(" ");
}

export function getFieldLabel(field) {
    const { uiHint } = field;
    if (uiHint) {
        let label = uiHint.fieldLabel;
        let hideLabel = uiHint.hideLabel;
        if (hideLabel) {
            return false;
        }
        if (label === "@name") {
            return "Name";
        }

        if (label !== uiHint.fieldLabelAutoGen) {
            return label;
        }

        if (label) {
            return capitalizeLetter(label);
        }
    }
    return false;
}

export function getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize, customField = undefined) {

    const fieldLabel = customField ? customField : getFieldLabel(field);
    let showLabel = fieldLabel && !isEditorGridColumnEditor;
    let labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
    let labelClassName = labelWidthSize ? 'col-' + labelWidthSize :
        (containerLabelWidthSize ? 'col-' + containerLabelWidthSize : '');
    return (
        showLabel &&
        <Label className={"flex-grow-0 flex-shrink-0 col-form-label " + labelClassName} for={name}>
            {fieldLabel}
        </Label>
    )
}

export function getFieldWidthClass(field, isEditorGridColumnEditor) {
    let labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
    let fieldWidthSize = getPathValue(field, 'uiHint.fieldWidthSize');
    let colWidth = fieldWidthSize || (labelWidthSize ? 12 - labelWidthSize : 9);
    let fieldClassName = isEditorGridColumnEditor ? "col-12" : `col-${colWidth}`;
    return fieldClassName;
}

export function getFieldHelpString(field, formData) {
    let showHelpString = getPathValue(field, 'uiHint.showHelpString');
    let fieldHelpString = Pan.isFunction(showHelpString) ? showHelpString(field, formData) : (showHelpString === true ? getPathValue(field, 'uiHint.helpstring') : showHelpString);
    return showHelpString && fieldHelpString;
}

export function getFieldWidthClassForSingleLineLayout(field, isEditorGridColumnEditor) {
    let labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
    let fieldWidthSize = getPathValue(field, 'uiHint.fieldWidthSize');
    let colWidth = fieldWidthSize || (labelWidthSize ? 12 - labelWidthSize : 9);
    colWidth = Math.round(colWidth / 2);
    let fieldClassName = isEditorGridColumnEditor ? "col-12" : `col-${colWidth}`;
    return fieldClassName;
}

export function emptyTextFormat(field) {
    let rv = '';
    let defaultValue = field.defaultValue;
    let min = getPathValue(field, 'uiHint.minValue');
    let max = getPathValue(field, 'uiHint.maxValue');

    if (Pan.isDefined(defaultValue)) {
        // the ' ' is necessary because combo box emptyText cannot be the same as one of its value.
        rv = defaultValue + ' ';
    }

    if (Pan.isDefined(min) && Pan.isDefined(max)) {
        rv += '[' + min + ' - ' + max + ']';
    }
    else if (Pan.isDefined(min)) {
        rv += '[>= ' + min + ']';
    }
    else if (Pan.isDefined(max)) {
        rv += '[<= ' + max + ']';
    }

    return rv;
}

export function getCompletionLoadConfig(field) {
    const { uiHint } = field;
    if (uiHint && uiHint.completionConfig) {
        return uiHint.completionConfig;
    }
    return;
}

export function isFieldEndsWithMember(field) {
    return field && field.attrPath && field.attrPath.endsWith('.member');
}

export function isFieldEndsWithMemberStar(field) {
    return field && field.attrPath && field.attrPath.endsWith('.member.*');
}

export function getMemberCompletionField(field, fields) {
    let fieldPath = field.attrPath + '.*';
    let index = fields.findIndex((f) => {
        return f.attrPath === fieldPath;
    });
    return index >= 0 ? fields[index] : null;
}

export function getEnumsFromMemberCompletionField(field) {
    let ret = undefined;
    if (isFieldEndsWithMemberStar(field)) {
        const enums = get(field, "multitypes.enum");
        if (enums) {
            ret = enums.map(val => {
                return {
                    value: val[0],
                    label: val[1]
                };
            });
        }
    }
    return ret;
}

export function getEnumsFromMemberField(field) {
    let ret = undefined;
    if (isFieldEndsWithMember(field)) {
        const memberField = getMemberCompletionField(field, this.context.fields);
        ret = getEnumsFromMemberCompletionField(memberField);
    }
    return ret;
}

export function getLabelWidth(field) {
    const { uiHint } = field;
    if (uiHint) {
        const label = uiHint.fieldLabel;
        const labelWidth = uiHint.labelWidth;
        if (label) {
            // default labelWidth to 250px
            return labelWidth || 250;
        }
    }
    return false;
}

/**
 * Return the schema object for itemId
 * @param {Object} schema
 * @param {string} itemId
 */
export function getSchemaNode(schema, name) {
    let schemaNode = getPathValue(schema, name);
    return schemaNode;
}

/**
 * Return the uiSchema object for itemId
 * @param {Array} fields
 * @param {string} itemId
 */
export function getField(fields, name) {
    let found = fields.find(item => {
        return item.name === name || item.itemId === name;
    });
    return found || {};
}

/**
 * Return the erros for all leaf nodes
 * @param {Array} fields
 * @param {string} itemId
 */
export function getChildError(fieldNames, fields, errors) {
    //get all the leaf nodes for the given fieldNames
    //verify if there are any erros for those fields
    let errorMap = {}, fieldErrorMap = {};
    let leafNodesMap = getLeafNodesMap(fieldNames, fields, true);
    if (leafNodesMap && !Pan.isEmpty(leafNodesMap) && errors && !Pan.isEmpty(errors)) {
        for (let err in errors) {
            if (err) {
                //replace the key in err path
                let str = err.replace(/\[\?\(\@\.[^?]*\)\]/g, '\.\*');
                errorMap[str] = errors[err][0]
            }
        }
        for (let fieldName of fieldNames) {
            let fieldLeafNodes = leafNodesMap[fieldName];
            if (fieldLeafNodes && !Pan.isEmpty(fieldLeafNodes)) {
                let errorResult = null;
                for (let node of fieldLeafNodes) {
                    if (errorMap[node]) {
                        //this child has error
                        errorResult = errorMap[node];
                        break;
                    }
                }
                fieldErrorMap[fieldName] = errorResult;
            }
        }
    }
    return fieldErrorMap;
}

/**
 * Recursively merge deeply nested objects, optionaly concate array elements
 * @param {Object} obj1
 * @param {Object} obj2
 * @param {boolean} concatArrays
 */
export function mergeObjects(obj1, obj2, concatArrays = false) {
    // Recursively merge deeply nested objects.
    var acc = Object.assign({}, obj1); // Prevent mutation of source object.
    return Object.keys(obj2).reduce((acc, key) => {
        const left = obj1[key],
            right = obj2[key];
        if (obj1.hasOwnProperty(key) && isObject(right)) {
            acc[key] = mergeObjects(left, right, concatArrays);
        } else if (
            concatArrays &&
            Array.isArray(left) &&
            Array.isArray(right)
        ) {
            acc[key] = left.concat(right);
        } else {
            acc[key] = right;
        }
        return acc;
    }, acc);
}

/**
 * This function checks if the given thing is an object
 * @param {Object} thing
 */
export function isObject(thing) {
    return typeof thing === "object" && thing !== null && !Array.isArray(thing);
}

/**
 * Convert value to number
 * @param {Object} value
 */
export function asNumber(value) {
    if (value === "") {
        return undefined;
    }
    if (/\.$/.test(value)) {
        // "3." can't really be considered a number even if it parses in js. The
        // user is most likely entering a float.
        return value;
    }
    if (/\.0$/.test(value)) {
        // we need to return this as a string here, to allow for input like 3.07
        return value;
    }
    const n = Number(value);
    const valid = typeof n === "number" && !Number.isNaN(n);

    if (/\.\d*0$/.test(value)) {
        // It's a number, that's cool - but we need it as a string so it doesn't screw
        // with the user when entering dollar amounts or other values (such as those with
        // specific precision or number of significant digits)
        return value;
    }

    return valid ? n : value;
}

function isArguments(object) {
    return Object.prototype.toString.call(object) === "[object Arguments]";
}

export function deepEquals(a, b, ca = [], cb = []) {
    // Partially extracted from node-deeper and adapted to exclude comparison
    // checks for functions.
    // https://github.com/othiym23/node-deeper
    if (a === b) {
        return true;
    } else if (typeof a === "function" || typeof b === "function") {
        // Assume all functions are equivalent
        // see https://github.com/mozilla-services/react-jsonschema-form/issues/255
        return true;
    } else if (typeof a !== "object" || typeof b !== "object") {
        return false;
    } else if (a === null || b === null) {
        return false;
    } else if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
    } else if (a instanceof RegExp && b instanceof RegExp) {
        return (
            a.source === b.source &&
            a.global === b.global &&
            a.multiline === b.multiline &&
            a.lastIndex === b.lastIndex &&
            a.ignoreCase === b.ignoreCase
        );
    } else if (isArguments(a) || isArguments(b)) {
        if (!(isArguments(a) && isArguments(b))) {
            return false;
        }
        let slice = Array.prototype.slice;
        return deepEquals(slice.call(a), slice.call(b), ca, cb);
    } else {
        if (a.constructor !== b.constructor) {
            return false;
        }

        let ka = Object.keys(a);
        let kb = Object.keys(b);
        // don't bother with stack acrobatics if there's nothing there
        if (ka.length === 0 && kb.length === 0) {
            return true;
        }
        if (ka.length !== kb.length) {
            return false;
        }

        let cal = ca.length;
        while (cal--) {
            if (ca[cal] === a) {
                return cb[cal] === b;
            }
        }
        ca.push(a);
        cb.push(b);

        ka.sort();
        kb.sort();
        for (var j = ka.length - 1; j >= 0; j--) {
            if (ka[j] !== kb[j]) {
                return false;
            }
        }

        let key;
        for (let k = ka.length - 1; k >= 0; k--) {
            key = ka[k];
            if (!deepEquals(a[key], b[key], ca, cb)) {
                return false;
            }
        }

        ca.pop();
        cb.pop();

        return true;
    }
}

export function shouldRender(comp, nextProps, nextState) {
    const { props, state } = comp;
    return !deepEquals(props, nextProps) || !deepEquals(state, nextState);
}

export function pad(num, size) {
    let s = String(num);
    while (s.length < size) {
        s = "0" + s;
    }
    return s;
}

export function setState(instance, state, callback) {
    const { safeRenderCompletion } = instance.props;
    if (safeRenderCompletion) {
        instance.setState(state, callback);
    } else {
        instance.setState(state);
        setImmediate(callback);
    }
}

export function dataURItoBlob(dataURI) {
    // Split metadata from data
    const splitted = dataURI.split(",");
    // Split params
    const params = splitted[0].split(";");
    // Get mime-type from params
    const type = params[0].replace("data:", "");
    // Filter the name property from params
    const properties = params.filter(param => {
        return param.split("=")[0] === "name";
    });
    // Look for the name and use unknown if no name property.
    let name;
    if (properties.length !== 1) {
        name = "unknown";
    } else {
        // Because we filtered out the other property,
        // we only have the name case here.
        name = properties[0].split("=")[1];
    }

    // Built the Uint8Array Blob parameter from the base64 string.
    const binary = atob(splitted[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Create the blob object
    const blob = new window.Blob([new Uint8Array(array)], { type });

    return { blob, name };
}

/**
 * This function gives all the leaf nodes  map for each of the given fieldNames using fieldsMap
 */
export function getLeafNodes(fieldNames, fieldsMap, includeArray) {
    let resultMap = {};

    for (let fieldName of fieldNames) {
        let result = [];
        getLeafNodeFromMap(fieldName, fieldsMap, result, includeArray);
        resultMap[fieldName] = result;
    }
    return resultMap;
}

/**
 * This function gives all the leaf nodes map for each of the given fieldNames using fieldsList
 */
export function getLeafNodesMap(fieldNames, fieldsList, includeArray) {
    let fieldsMap = {};
    fieldsList.forEach((item) => {
        fieldsMap[item.attrPath || item.name] = item;
    });
    return getLeafNodes(fieldNames, fieldsMap, includeArray);
}

/**
 * This function gives list of leaf nodes for the given fieldName using fieldsMap
 */
export function getLeafNodeFromMap(fieldName, fieldsMap, result, includeArray) {
    if (fieldsMap && fieldsMap[fieldName] && fieldsMap[fieldName]['childrenNames']) {
        for (let child of fieldsMap[fieldName]['childrenNames']) {
            getLeafNodeFromMap(child, fieldsMap, result, includeArray);
        }
        if (includeArray && fieldsMap[fieldName]['nodetype'] === 'array') {
            //array can have errors
            result.push(fieldName);
        }
    } else {
        result.push(fieldName);
    }
}

/**
 * This function gives all the leaf nodes (first child for choice) map for each of the given fieldNames using fieldsList
 */
export function getLeafNodesMapForDefaults(fieldNames, fieldsList, parentAllowBlank) {
    let fieldsMap = {};
    fieldsList.forEach((item) => {
        fieldsMap[item.attrPath || item.name] = item;
    });
    return getLeafNodesForDefaults(fieldNames, fieldsMap, parentAllowBlank);
}

/**
 * This function gives all the leaf nodes(first child for choice)  map for each of the given fieldNames using fieldsMap
 */
export function getLeafNodesForDefaults(fieldNames, fieldsMap, parentAllowBlank) {
    let resultMap = {};

    for (let fieldName of fieldNames) {
        let result = [];
        getLeafNodeFromMapForDefaults(fieldName, fieldsMap, result, parentAllowBlank);
        resultMap[fieldName] = result;
    }
    return resultMap;
}

export function getLeafNodeFromMapForDefaults(fieldName, fieldsMap, result, parentAllowBlank) {
    let field = fieldsMap[fieldName];
    if (field && field['childrenNames'] && (!field.attrPath || !field.attrPath.endsWith('.entry')) && (!field.uiHint.allowBlank || field.uiHint.fieldRequired|| parentAllowBlank)) {
        let children = [];
        if (field.type === 'choice' || field.type === 'union') {
            if (field.defaultValue || field.uiHint.fieldRequiredValue) {
                //set default value
                const attrPath = field.choiceParentAttr ? field.choiceParentAttr.attrPath : field.attrPath;
                let childValue = attrPath + '.' + (field.defaultValue || field.uiHint.fieldRequiredValue);
                if (field['childrenNames'].indexOf(childValue) >= 0) {
                    children.push(childValue);
                } else {
                    //fallback on first child
                    children.push(field['childrenNames'][0]);
                }
            } else {
                //set first child as default value
                children.push(field['childrenNames'][0]);
            }
        } else {
            children = field['childrenNames'];
        }
        for (let child of children) {
            getLeafNodeFromMapForDefaults(child, fieldsMap, result);
        }
    } else if (field && (!field.uiHint.allowBlank || field.uiHint.fieldRequired) && (!field.attrPath || !field.attrPath.endsWith('.entry') || field.uiHint.fieldRequired)) {
        result.push(fieldName);
    }
}

export function getCompletionXpath(fieldAttrPath, filters, formData, recordConfigPath) {
    // let {field, filters} = props;
    // let {formData, recordConfigPath} = context;
    let completionXpath = "";
    //go over the field attrPath and replace any entry.* with entry[@name] using the form data and filters
    let completionPath = fieldAttrPath;
    if (completionPath) {
        let currentpath = "";
        let attrPathSplit = completionPath.split('.entry.*.');
        for (let index = 0; index < attrPathSplit.length; index++) {
            currentpath += attrPathSplit[index];
            completionXpath += attrPathSplit[index];
            if (index !== (attrPathSplit.length - 1)) {
                currentpath += '.entry.*';
                let pathObj = getPathValue(formData, currentpath, filters);
                if (pathObj && pathObj['@name']) {
                    //object name exist. Append name
                    let objName = pathObj['@name'];
                    //we want to preserve dot in object names. We will replace this with . when we convert to exact xpath
                    objName = objName.replace(/\./g, '%dot%');
                    completionXpath += ".entry[@name='" + escape(objName) + "'].";
                } else {
                    //append noname
                    completionXpath += ".entry[@name='__noname__']."
                }
                currentpath += '.';
            }
        }
    }
    let recName = getPathValue(formData, '$.@name') || '__noname__';
    recName = recName.replace(/\./g, '%dot%');
    completionXpath = completionXpath.replace(/^\$\./, '');
    completionXpath = recordConfigPath + "[@name='" + escape(recName) + "']." + completionXpath;
    completionXpath = completionXpath.replace(/\.\*$/, '');
    return completionXpath;
}

export function getFieldXpath(fieldpath, location) {
    let xpath = fieldpath.replace(/\./g, '/');
    xpath = xpath.replace(/%dot%/g, '.');
    xpath = xpath.replace(
        /\/config\/devices\/entry/g,
        "/config/devices/entry[@name='localhost.localdomain']"
    );

    // DG
    if (location && location.dg) {
        xpath = xpath.replace(
            'device-group/entry',
            "device-group/entry[@name='" + location.dg.name + "']",
        );
    }

    // Tpl
    if (location && location.tpl) {
        xpath = xpath.replace(
            'template/entry',
            "template/entry[@name='" + location.tpl.name + "']",
        );
        xpath = xpath.replace(
            'vsys/entry',
            "vsys/entry[@name='" + VSYS + "']",
        );
    }

    xpath = xpath.replace(/\/@name$/g, '');
    xpath = xpath.replace(/\/entry$/g, '');
    xpath = xpath.replace(/\/entry\[@name='\w*'\]$/, '');
    xpath = xpath.replace(/\/choice/g, '');
    xpath = xpath.startsWith('$/') ? xpath.slice(1) : xpath;
    return xpath;
}

export function parseServerError(title, serverErrorMessage) {
    let msgBody = [];
    msgBody.push(<div>{serverErrorMessage.msg}</div>);
    let detailMsg = get(serverErrorMessage, "extra.message");
    if (detailMsg) {
        msgBody.push(<div>Detail error(s):</div>);
        if (isString(detailMsg)) {
            msgBody.push(<div>&nbsp;&nbsp;&nbsp;&nbsp;{detailMsg}</div>);
        }
        else if (isArray(detailMsg)) {
            each(detailMsg, msg => {
                msgBody.push(<div>&nbsp;&nbsp;&nbsp;&nbsp;{msg}</div>);
            });
        }
        else {
            msgBody.push(<div>&nbsp;&nbsp;&nbsp;&nbsp;JSON.stringify(detailMsg)}</div>);
        }
    }
    if (get(serverErrorMessage, "extra.errorType")) {
        title += ` - ${serverErrorMessage.extra.errorType}`;
    }
    return { title, msgBody };
}

export function getFieldValueFromType(field) {
    let stringTypes = ['string', 'auto', 'date', 'multiple', 'password', 'ipspec', 'iprangespec', 'mac', 'rangelistspec', 'enum'];
    let boolTypes = ['bool', 'reverseBool'];
    let fieldType = field.type;
    if (stringTypes.indexOf(fieldType) >= 0) {
        return "";
    } else if (boolTypes.indexOf(fieldType) >= 0) {
        return false;
    }
    return {};
}

export function xmlStringToDocument(str) {
    if (!isString(str)) {
        return null;
    }
    return new DOMParser().parseFromString(str, 'text/xml');
}

export function xmlToJson(xml) {
    // Create the return object
    var obj = {};
    if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (var j = 0; j < xml.attributes.length; j++) {
                var attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType == 3) { // text
        obj = xml.nodeValue;
    }

    // do children
    // If just one text node inside
    if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
        obj = xml.childNodes[0].nodeValue;
    }
    else if (xml.hasChildNodes()) {
        for (var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;
            if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof (obj[nodeName].push) == "undefined") {
                    var old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xmlToJson(item));
            }
        }
    }
    return obj;
}

export function getFieldErrors (field, filters, formData, errors, CURRENT_KEY) {
    //check if any child has errors
    let recPath = getRecordPathWithFilters(formData, field.attrPath, filters, CURRENT_KEY);
    for (let key in errors) {
        if (key.indexOf(recPath) === 0) {
            //child has error
            return { [recPath]: errors[key] };
        }
    }
}
