import moment from 'moment'


export const getRange = (key, rangeDef) => {
  if(!rangeDef){
    rangeDef=[
      [15, 'minutes'],
      [60, 'minutes'],
      [10, 'hours'],
      [24, 'hours'],
      [7, 'days'],
      [30, 'days'],
      [60, 'days'],
      [90, 'days'],
    ]
  }
  const RANGES = rangeDef.map(([num, unit]) => ({
    key: `past_${num}_${unit}`,
    display: `Past ${num} ${unit}`,
    timeval: () => [moment().subtract(num, unit), moment()]
  }))
  const RANGE_MAP = new Map(RANGES.map(r => [r.key, r]))
  return RANGE_MAP.get(key)
}

export const getRanges = (rangeDef) => {
  if(!rangeDef){
    rangeDef=[
      [15, 'minutes'],
      [60, 'minutes'],
      [10, 'hours'],
      [24, 'hours'],
      [7, 'days'],
      [30, 'days'],
      [60, 'days'],
      [90, 'days'],
    ]
  }
  return rangeDef.map(([num, unit]) => ({
    key: `past_${num}_${unit}`,
    display: `Past ${num} ${unit}`,
    timeval: () => [moment().subtract(num, unit), moment()]
  }))
}
