import { ViewerManager, Pan } from 'ui-lib';
import TPLBaseViewer from '../../template/TPLBaseViewer';
import getTPLRecordViewer from '../../template/TPLRecordViewer';

const _T = str => str;

const qosProfileFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name')
        }
    },  {
        name: '$',
        childrenNames: [
            'profile.info',
            'class.info'
        ]
    }, {
        name: 'profile.info',
        childrenNames: [
            'name',
            '$.aggregate-bandwidth.egress-max',
            '$.aggregate-bandwidth.egress-guaranteed'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('Profile')
        }
    }, {   name: '$.aggregate-bandwidth.egress-max',
        uiHint: {
            maxValue: 10000
        }
    }, {   name: '$.aggregate-bandwidth.egress-guaranteed',
        uiHint: {
            maxValue: 10000
        }
    },{
        name: 'class.info',
        childrenNames: [
            '$.class'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('Classes')
        }
    }, {
        name: '$.class.entry',
        uiHint: {
            fieldLabelAutoGen: _T('Classes'),
            fieldLabel: _T('Classes')
        }
    }, {
        name: '$.class.entry.*',
        childrenNames: [
            '$.class.entry.*.@name',
            '$.class.entry.*.priority',
            '$.class.entry.*.class-bandwidth.egress-guaranteed',
            '$.class.entry.*.class-bandwidth.egress-max'
        ],
        uiHint: {
            height: 225,
            showGridFilter: false,
            showHelpString: _T("class 4 is the default class")
        }
    }, {
        name: '$.class.entry.*.priority',
        uiHint: {
            useValueAsDisplay: true
        }
    }, {
        name: '$.class.entry.*.class-bandwidth.egress-guaranteed',
        uiHint: {
            columnConfig: {
                field: 'class-bandwidth.egress-guaranteed'
            }
        }
    }, {
        name: '$.class.entry.*.class-bandwidth.egress-max',
        uiHint: {
            columnConfig: {
                field: 'class-bandwidth.egress-max'
            }
        }
    }
];

const qosProfileColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.network.qos.profile.entry'
}];


const viewConfig = {
    serviceName : 'Network/QoSProfiles',
    reduxId : 'qosProfile',
    header : 'QoS Profile',
    columns : qosProfileColumns,
    fields : qosProfileFields,
    recordConfigPath : '$.config.devices.entry.template.entry.config.devices.entry.network.qos.profile.entry',
    rbaPath:'network.network-profiles.qos-profile',
    className: 'QoSProfile'
}

export default class QoSProfile extends TPLBaseViewer {
	constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getTPLRecordViewer);