import React from "react";

import { MOBILE_USERS_DG, SERVICE_CONNECTION_DG } from "service-lib";
import { RemoteNetworks, ServiceConnections, MobileUsers, ServiceInfrastructure, ForwardTrustCert, ForwardUntrustCert, APIKeyForMU } from "gpcs";

// main tabs
const Dashboard = React.lazy(() => import("./containers/dashboard/"));

// explore tabs
const LogViewer = React.lazy(() => import("./containers/explore/logViewer/LogViewer"));
const LogViewer_v2 = React.lazy(() => import("./containers/explore/logViewer_v2/LogViewer"));
const AccViewer = React.lazy(() => import("./containers/explore/accViewer/AccViewer"));
const SaasViewer = React.lazy(() => import("./containers/explore/saasViewer/SaasViewer"));

// policies tabs
const CorporateAccessPolicies = React.lazy(() => import("./containers/policies/corporateAccess/CorporateAccessPolicies"));
const InternetAccessPolicies = React.lazy(() => import("./containers/policies/internetAccess/InternetAccessPolicies"));
const AppOverridePolicies = React.lazy(() => import("./containers/policies/appOverride/AppOverridePolicies"));
const QoSPolicies = React.lazy(() => import("./containers/policies/qos/QoSPolicies"));
// const AuthPolicies = React.lazy(() => import('./containers/policies/auth/AuthPolicies'));
const DecryptionPolicies = React.lazy(() => import("./containers/policies/decryption/DecryptionPolicies"));

// objects tabs
const Address = React.lazy(() => import("./containers/objects/address/Address"));
const AddressGroups = React.lazy(() => import("./containers/objects/addressGroups"));
const Applications = React.lazy(() => import("./containers/objects/applications"));
const ApplicationFilters = React.lazy(() => import("./containers/objects/applicationFilters"));
const DoSProtectionProfile = React.lazy(() => import("./containers/objects/dosProtectionProfile/DoSProtectionProfile"));
const Regions = React.lazy(() => import("./containers/objects/regions/Regions"));
const Services = React.lazy(() => import("./containers/objects/services/Services"));
const ServiceGroups = React.lazy(() => import("./containers/objects/serviceGroups"));
const Tags = React.lazy(() => import("./containers/objects/tags"));
const FileBlocking = React.lazy(() => import("./containers/objects/fileBlocking/FileBlocking"));
const WildFireAnalysis = React.lazy(() => import("./containers/objects/wildFireAnalysis/WildFireAnalysis"));
const HIPObjects = React.lazy(() => import("./containers/objects/hipObjects"));
const ApplicationGroups = React.lazy(() => import("./containers/objects/applicationGroups"));
const VulnerabilityProtection = React.lazy(() => import("./containers/objects/vulnerabilityProtection"));
const ExternalDynamicLists = React.lazy(() => import("./containers/objects/externalDynamicLists"));
const HIPProfiles = React.lazy(() => import("./containers/objects/hipProfiles"));
const URLFiltering = React.lazy(() => import("./containers/objects/urlFiltering"));
const SecurityProfileGroup = React.lazy(() => import("./containers/objects/securityProfileGroup"));
const AntivirusSecurityProfiles = React.lazy(() => import("./containers/objects/antiVirus"));
const AntiSpywareSecurityProfiles = React.lazy(() => import("./containers/objects/antiSpyware"));
const URLCategory = React.lazy(() => import("./containers/objects/urlCategory"));
//const DataFiltering = React.lazy(() => import('./containers/objects/dataFiltering'));
const DecryptionProfile = React.lazy(() => import("./containers/objects/decryptionProfile"));

// configure tabs
export const gpcsViews = {
	ServiceConnections: ServiceConnections,
	ForwardTrustCert: ForwardTrustCert,
	ForwardUntrustCert: ForwardUntrustCert,
	APIKeyForMU: APIKeyForMU,
	ServiceInfrastructure: ServiceInfrastructure
};

export const exploreNavigation = {
	items: [
		{
			name: "Logs",
			url: "/explore/logviewer",
			icon: "logs",
			noRBACheck: true,
			component: LogViewer_v2
		},
		{
			name: "Network",
			url: "/explore/accviewer",
			icon: "network",
			noRBACheck: true,
			component: AccViewer
		},
		{
			name: "SaaS",
			url: "/explore/saasviewer",
			icon: "saas",
			noRBACheck: true,
			component: SaasViewer
		}
	]
};

export const policiesNavigation = {
	items: [
		{
			title: true,
			name: "Security",
			rbaPath: "policies.security-rulebase",
			wrapper: {
				element: "i",
				attributes: { className: "nav-icon inbound-security" }
			},
			avail: function(config) {
				return config.dg && config.dg.name && config.dg.name !== SERVICE_CONNECTION_DG;
			}
		},
		{
			name: "Internet Access",
			url: "/policies/outbound",
			rbaPath: "policies.security-rulebase",
			component: InternetAccessPolicies,
			avail: function(config) {
				return config.dg && config.dg.name && config.dg.name !== SERVICE_CONNECTION_DG;
			}
		},
		{
			name: "Corporate Access",
			url: "/policies/inbound",
			rbaPath: "policies.security-rulebase",
			component: CorporateAccessPolicies,
			avail: function(config) {
				return config.dg && config.dg.name && config.dg.name !== SERVICE_CONNECTION_DG;
			}
		},
		{
			name: "QoS",
			url: "/policies/qos-rulebase",
			icon: "qos",
			component: QoSPolicies,
			avail: function(config) {
				return config.dg && config.dg.name && config.dg.name !== MOBILE_USERS_DG;
			}
		},
		{
			name: "Decryption",
			url: "/policies/ssl-decryption-rulebase",
			icon: "decryption",
			component: DecryptionPolicies,
			avail: function(config) {
				return config.dg && config.dg.name && config.dg.name !== SERVICE_CONNECTION_DG;
			}
		},
		{
			name: "Application Override",
			url: "/policies/application-override-rulebase",
			icon: "app-override",
			component: AppOverridePolicies,
			avail: function(config) {
				return config.dg && config.dg.name && config.dg.name !== SERVICE_CONNECTION_DG;
			}
			// },
			// {
			// 	name: 'Authentication',
			// 	url: '/policies/authentication-rulebase',
			// 	icon: 'authentication',
			// 	component: AuthPolicies,
			// 	avail: function (config) {
			// 		return config.dg && config.dg.name && config.dg.name !== SERVICE_CONNECTION_DG;
			// 	}
		}
	]
};

export const objectsNavigation = {
	items: [
		{
			name: "Addresses",
			url: "/objects/addresses",
			icon: "addresses",
			component: Address
		},
		{
			name: "Address Groups",
			url: "/objects/address-groups",
			icon: "address-groups",
			component: AddressGroups
		},
		{
			name: "Regions",
			url: "/objects/regions",
			icon: "regions",
			component: Regions
		},
		{
			name: "Applications",
			url: "/objects/applications",
			icon: "applications",
			component: Applications
		},
		{
			name: "Application Groups",
			url: "/objects/application-groups",
			icon: "application-groups",
			component: ApplicationGroups
		},
		{
			name: "Application Filters",
			url: "/objects/application-filters",
			icon: "application-filter",
			component: ApplicationFilters
		},
		{
			name: "Services",
			url: "/objects/services",
			icon: "services",
			component: Services
		},
		{
			name: "Service Groups",
			url: "/objects/service-groups",
			icon: "service-groups",
			component: ServiceGroups
		},
		{
			name: "Tags",
			url: "/objects/tags",
			icon: "tags",
			component: Tags
		},
		{
			name: "HIP Objects",
			url: "/objects/global-protect/hip-objects",
			icon: "hip-objects",
			component: HIPObjects
		},
		{
			name: "HIP Profiles",
			url: "/objects/global-protect/hip-profiles",
			icon: "hip-profiles",
			component: HIPProfiles
		},
		{
			url: "/objects/dynamic-block-lists",
			name: "External Dynamic Lists",
			icon: "edl",
			component: ExternalDynamicLists
		},
		{
			url: "/objects/custom-objects/url-category",
			name: "URL Categories",
			icon: "url-category",
			component: URLCategory
		},
		{
			name: "Security Profiles",
			icon: "security-profiles",
			children: [
				{
					url: "/objects/security-profiles/antivirus",
					name: "Antivirus",
					icon: "antivirus",
					component: AntivirusSecurityProfiles
				},
				{
					url: "/objects/security-profiles/anti-spyware",
					name: "Anti-Spyware",
					icon: "anti-spyware",
					component: AntiSpywareSecurityProfiles
				},
				{
					url: "/objects/security-profiles/vulnerability-protection",
					name: "Vulnerability Protection",
					icon: "vulnerability-protection",
					component: VulnerabilityProtection
				},
				{
					url: "/objects/security-profiles/url-filtering",
					name: "URL Filtering",
					icon: "url-filtering",
					component: URLFiltering
				},
				{
					url: "/objects/security-profiles/file-blocking",
					name: "File Blocking",
					icon: "file-blocking",
					component: FileBlocking
				},
				{
					url: "/objects/security-profiles/wildfire-analysis",
					name: "WildFire Analysis",
					icon: "wildfire-analysis",
					component: WildFireAnalysis
				},
				/*{
					url: '/objects/security-profile/datafiltering',
					name: 'Data Filtering',
					icon: 'data-filtering'
				},
				{
					url: "/objects/security-profiles/dos-protection",
					name: "DoS Protection",
					icon: "dos-protection",
					component: DoSProtectionProfile
				}*/
			]
		},
		{
			url: "/objects/security-profile-groups",
			name: "Security Profile Groups",
			icon: "security-profile-groups",
			component: SecurityProfileGroup
		},
		{
			url: "/objects/decryption/decryption-profile",
			name: "Decryption Profile",
			icon: "decryption-profile",
			component: DecryptionProfile
		}
	]
};

export const configureNavigation = {
	items: [
		{
			name: "Mobile Users",
			url: "/configure/mobileusers",
			rbaPath: "",
			noRBACheck: true,
			icon: "mobile-users",
			component: MobileUsers,
			avail: function(config, licenseInfo) {
				return licenseInfo && licenseInfo["mu-active"] === true;
			}
		},
		{
			name: "Remote Networks",
			url: "/configure/remotenetworks",
			rbaPath: "",
			noRBACheck: true,
			icon: "remote-networks",
			component: RemoteNetworks,
			avail: function(config, licenseInfo) {
				return licenseInfo && licenseInfo["rn-active"] === true;
			}
		},
		{
			name: "Service Connections",
			url: "/configure/serviceconnections",
			rbaPath: "",
			noRBACheck: true,
			component: ServiceConnections,
			icon: "service-connections"
		},
		{
			name: "Service Infrastructure",
			url: "/configure/serviceinfrastructure",
			rbaPath: "",
			noRBACheck: true,
			component: ServiceInfrastructure,
			icon: "app-override"
		}
	]
};

export const defaultRoutes = {
	dashboard: '/dashboard',
	explore: '/explore/logviewer',
	policies: '/policies/outbound',
	objects: '/objects/addresses',
	configure: ''
};

export const navigation = [
	{
		name: "dashboard",
		url: "/dashboard",
		component: Dashboard
	},
	{
		name: "explore",
		url: "/explore",
		items: exploreNavigation.items
	},
	{
		name: "policies",
		url: "/policies",
		items: policiesNavigation.items
	},
	{
		name: "objects",
		url: "/objects",
		items: objectsNavigation.items
	},
	{
		name: "configure",
		url: "/configure",
		items: configureNavigation.items
	}
];
