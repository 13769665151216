import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ViewerManager } from '../ViewerManager';

export class AddRecordAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formEditorDisplay: false,
            viewer: this.generateViewer(props),
        };
        this.showAddRecordForm = this.showAddRecordForm.bind(this);
    }

    generateViewer(props) {
        let { viewerName } = props;
        return ViewerManager.getView(viewerName);
    }

    showAddRecordForm() {
        this.props.onAddRecordAction(this.state.viewer, this.props.viewerProps);
    }

    render() {
        const { text, disabled } = this.props;

        return (
            <React.Fragment>
                <Button
                    key={text}
                    color="primary"
                    size="sm"
                    onClick={e => this.showAddRecordForm()}
                    disabled={disabled}
                >
                    {text}
                </Button>
            </React.Fragment>
        );
    }
}

AddRecordAction.defaultProps = {
    disabled: false,
};

if (process.env.NODE_ENV !== 'production') {
    AddRecordAction.propTypes = {
        viewerName: PropTypes.string.isRequired,
        viewerProps: PropTypes.object,
        onAddRecordAction: PropTypes.func,
        text: PropTypes.string,
        disabled: PropTypes.bool,
    };
}
