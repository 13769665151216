import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import { RadioWidget } from '../RadioWidget';
import { CheckboxWidget } from '../CheckboxWidget';
import { ListGroup, ListGroupItem, Label, FormGroup } from 'reactstrap';

export class AutoSuggestList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            id,
            showSuggestions,
            options = [],
            filteredOptions,
            condition,
            currentCondition,
            showCondition,
            showNotCondition,
            positionStyle,
            isNegate,
            onCheckBoxChange,
            onConditionChange,
            onKeyDown,
            onBlur,
            onClick
        } = this.props;
        if (!showSuggestions || options.length === 0) {
            return null;
        }

        const wrapper = (
            <div
                className="autocompletewidget-wrapper"
                style={positionStyle}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
            >
                {showCondition && (
                    <div className="autocompletewidget-condition d-flex align-items-center border-bottom">
                        <FormGroup inline>
                            <Label for={id + '-radio'} className="pr-2">Operators</Label>
                            <RadioWidget
                                id={id + '-radio'}
                                options={{
                                    enumOptions: condition,
                                    inline: true
                                }}
                                autoFocus
                                value={currentCondition}
                                onChange={onConditionChange}
                                className="field-radio-group form-control"
                            />
                            {showNotCondition && (
                                <div className="autocompletewidget-not-condition">
                                    <CheckboxWidget
                                        className="field-radio-group form-control"
                                        id={id+ "basic"}
                                        value={isNegate}
                                        label='Not'
                                        onChange={onCheckBoxChange}
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </div>
                )}
                <div className="autocompletewidget-options">
                    <ListGroup className="autocompletewidget-list">
                        {filteredOptions.map((suggestion) => (
                            <ListGroupItem key={suggestion} onMouseDown={onClick}>
                                {suggestion}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>
            </div>
        );
        return createPortal(wrapper, document.body);
    }
}
