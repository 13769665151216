import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BaseInput } from '../BaseInput';
import './TextWidget.scss';


export class TextWidget extends Component {
    constructor() {
        super();
        this.input = React.createRef();
    }
    render() {
        return (
            <BaseInput 
                ref={this.input}
                type="text" 
                {...this.props} />
        )
    }
    focus = () => {
        if (this.input && this.input.current && this.input.current.focus) {
            this.input.current.focus();
        }
    }
}
TextWidget.defaultProps = {
    type: 'text',
    required: false,
    disabled: false,
    readonly: false,
    autoFocus: false,
};
if (process.env.NODE_ENV !== 'production') {
    TextWidget.propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        error: PropTypes.string,
        className: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autoFocus: PropTypes.bool,
        showErrors: PropTypes.bool,
        onKeyDown: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        type: PropTypes.string,
        onFocus: PropTypes.func,
        options: PropTypes.shape({
            inputType: PropTypes.string,
            emptyValue: PropTypes.string,
        }),
    };
}
