import React from "react";
import PropTypes from "prop-types";
import {BaseInput} from "../BaseInput";
const DateInput = BaseInput;
import './DateWidget.scss';
export function DateWidget(props) {
    const { onChange } = props;
    return (
        <DateInput
            type="date"
            {...props}
            onChange={value => onChange(value || undefined)}
        />
    );
}

if (process.env.NODE_ENV !== "production") {
    DateWidget.propTypes = {
        value: PropTypes.string,
    };
}