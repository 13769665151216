const md5 = require('md5');

let md5Salt = "";

export function initAuth(getUserResponse) {
    if (getUserResponse && getUserResponse.xhr && getUserResponse.xhr.getResponseHeader("x-csrf-salt")) {
        md5Salt = getUserResponse.xhr.getResponseHeader("x-csrf-salt");
    } else {
        console.log("auth"); // As little as possible
    }
}

export function getAuthHeaders() {
    const now = new Date ().getTime();
    return {
        "x-csrf-token": now + ":" + md5(`${now}:${md5Salt}`)
    }
}
