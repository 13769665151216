import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { ViewerDisplayActionBuilder } from "../ViewerDisplayActionBuilder";
import { FormContext, FormConsumer } from "../../FormContext";
import { BaseFieldBuilder } from "../BaseBuilder";
import { FormGroup, Col, Label } from "reactstrap";
import { getFieldLabelComp } from "../../schema/utils";
import "./LabelBuilder.scss";

export class LabelBuilder extends BaseFieldBuilder {
	constructor(props) {
		super(props);
		this.state = {};
		this.setRecordValue = this.setRecordValue.bind(this);
	}

	setRecordValue(value) {
		super.setRecordValue(value, true);
	}

	afterSubmit = record => {
		if (record && record.name) {
			//add this record name to formData
			this.setRecordValue(record.name, true);
			let { field } = this.props;
			let afterSubmitProps = get(field, "uiHint.manageViewer.viewerProps.afterSubmit");
			afterSubmitProps(record);
		}
	};

	getViewerProps = field => {
		const showViewer = get(field, "uiHint.manageViewer.showViewer") ? true : false;
		const viewerProps = showViewer && get(field, "uiHint.manageViewer.viewerProps") ? field.uiHint.manageViewer.viewerProps : null;
		return viewerProps ? { ...viewerProps, afterSubmit: this.afterSubmit } : null;
	};

	getManageViewer = (viewerProps, field) => {
		let manageViewer;
		if (viewerProps && field.uiHint.manageViewer.viewerProps) {
			manageViewer = { ...field.uiHint.manageViewer };
			manageViewer.viewerProps = { ...viewerProps };
		}
		return manageViewer;
	};

	showErrorMessage = (displayLabel, value) => {
		if (displayLabel) {
			if (!displayLabel(value)) {
				return "Please Import a certificate";
			}
		}
		return "";
	};

	renderLabel = () => {
		const { field, name, isEditorGridColumnEditor, containerLabelWidthSize } = this.props;
		let { hideLabel } = this.props;
		const customClassName = get(field, "uiHint.customClassName");
		const displayLabel = get(field, "uiHint.displayLabel");
		const processInput = get(field, "uiHint.processInput");
		const disableLabel = get(field, "uiHint.disableLabel");
		const viewerProps = this.getViewerProps(field);
		const manageViewer = this.getManageViewer(viewerProps, field);
		const LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
		hideLabel = displayLabel ? true : hideLabel;
		let value = this.getRecordValue();
		value = processInput ? processInput(value) : value;
		const showLabel = displayLabel ? displayLabel(value) : true;
		const errorMessage = this.showErrorMessage(displayLabel, value);
		return (
			<FormGroup row className={`d-flex flex-row label-builder ${displayLabel ? "col-form-label" : ""}`}>
				{!disableLabel && (
					<>
						{!hideLabel && LabelComp}
						<Col>
							<Label className={`label-builder-field ${customClassName || ""}`}>
								{errorMessage || (showLabel && value)}
							</Label>
						</Col>
					</>
				)}
				{viewerProps && (
					<div className={"select-display-same-row " + (customClassName || "")} style={{ padding: 0 }}>
						<ViewerDisplayActionBuilder
							field={{
								uiHint: manageViewer
							}}
						/>
					</div>
				)}
			</FormGroup>
		);
	};

	render() {
		return (
			<React.Fragment>
				<FormConsumer>{() => this.renderLabel()}</FormConsumer>
			</React.Fragment>
		);
	}
}

LabelBuilder.contextType = FormContext;

LabelBuilder.defaultProps = {};

if (process.env.NODE_ENV !== "production") {
	LabelBuilder.propTypes = {
		field: PropTypes.object
	};
}
