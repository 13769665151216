import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormContext } from "../../FormContext";
import Pan from '../../schema/Pan';
import './EditorGrid.scss';
import { GridWidget } from '../GridWidget';
import { SelectWidget } from '../SelectWidget';
import {
    insertArrayItemInPath,
    setPathValue,
    getRecordPathWithFilters
} from '../../../utils/json';
import { isFieldEndsWithMember, getMemberCompletionField, getEnumsFromMemberField, getFieldErrors } from '../../schema/utils';
import {
    getGridRecordFilterFinder,
    getGridRecordFilterCurrentItemReplace,
    INJECTED_REC_ID,
    CURRENT_ITEM_KEY,
    generateGridRecId,
    updateFilterCurrentIndex
} from '../util';
import { get } from "lodash";

export class EditorGrid extends Component {
    constructor(props) {
        super(props);
        let filters = EditorGrid.getFilter(props);
        this.state = {
            filters: filters,
            modifiedColumns: this.injectCellEdit(props.columns, filters)
        };
        this.gridRef = React.createRef();
        // this.onFilter = this.onFilter.bind(this);
    }

    isGridDataArray() {
        return Array.isArray(this.props.gridData);
    }

    componentDidMount() {
        this.setState({
            enumOptions: this.getEnumOptions()
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            filters: EditorGrid.getFilter(nextProps)
        };
    }

    static getFilter(props) {
        let recordConfigPath = props.recordConfigPath ? props.recordConfigPath : '';
        let { field } = props;
        if (recordConfigPath.endsWith('.entry') || recordConfigPath.endsWith('.member')) {
            let filterItem = {
                find: getGridRecordFilterFinder(recordConfigPath),
                replace: getGridRecordFilterCurrentItemReplace(recordConfigPath)
            }
            return props.filters.concat(filterItem);
        } else if (field && field.type === 'multiple') {
            let filterItem = {
                append: `[?(@.${INJECTED_REC_ID}==\'${CURRENT_ITEM_KEY}\')]`
            }
            return props.filters.concat(filterItem);
        } else {
            return props.filters;
        }
    }

    getEnumsFromMemberField = (field) => {
        if (isFieldEndsWithMember(field)) {
            const memberField = getMemberCompletionField(field, this.context.fields);
            const enums = get(memberField, "multitypes.enum");
            if (enums) {
                let ret = enums.map(val => {
                    return {
                        value: val[0],
                        label: val[1]
                    };
                });
                return ret;
            }
        }
        return null;
    }

    getEnumOptions = () => {
        const { field } = this.props;
        let enumOptions = this.getEnumsFromMemberField(field);
        if (enumOptions) {
            // add a special value to the enumOptions
            const value = '__select__';
            const label = 'Select';
            enumOptions.push({ value, label });
        }
        return enumOptions;
    }

    getIdColumn = () =>  {
        return this.props.idColumn || "@name";
    }
    
    deleteRecord(selected) {
        let { gridData, removeError } = this.props;
        let updateGridData = [];
        gridData.forEach((rec, index) => {
            var selIdx = rec[INJECTED_REC_ID] || index;
            if (Pan.isDefined(rec) && !Pan.isDefined(selected[selIdx]) && !Pan.isDefined(selected[rec[this.getIdColumn()]])) {
                updateGridData.push(rec);
            }
        });
        let recordConfigPath = this.props.recordConfigPath ? this.props.recordConfigPath : '';
        let formData = Pan.clone(this.context.formData);
        let errors = this.context.errors;
        if (!Pan.isEmpty(errors)) {
            for (let item in selected) {
                if (item) {
                    let updatedFilters = updateFilterCurrentIndex(this.state.filters, item);
                    let recPath = getRecordPathWithFilters(formData, recordConfigPath + ".*", updatedFilters, item);
                    for (let key in errors) {
                        if (key.indexOf(recPath) === 0) {
                            // child has error
                            removeError(key);
                        }
                    }
                }
            }
        }
        setPathValue(formData, recordConfigPath, updateGridData, this.state.filters);
        this.props.onChange({ formData });
    }

    injectCellEdit(columns, filters) {
        return columns.map(column => {
            column.editorProps.filters = filters;
            column.cellClassRules = {
                "invalid-cell": this.isInvalidCell
            };
            return column;
        });
    }
    
    isInvalidCell = (params) => {
        let cellError = false;
        if (params.colDef && !Pan.isEmpty(params.colDef.editorProps)) {
            let { field, filters } = params.colDef.editorProps;
            let context = this.context;
            let { formData, errors } = context || {};
            let fieldErrors = getFieldErrors(field, filters, formData, errors, params.data[INJECTED_REC_ID]);
            cellError = !Pan.isEmpty(fieldErrors);
        }
        return cellError;
    }


    mapfieldsDefaultVauleToFormData = ({ columns }) => {
        const defaultFieldData = columns.map(({ editorProps: { field } }) => field).reduce(
            (formedDefaultData, { defaultValue, childrenNames, attrPath }) => {
                if (!defaultValue || childrenNames || attrPath.endsWith('.entry')) return formedDefaultData;
                const subFieldPath = attrPath.replace(/.*\.\*\./g, '');
                const fieldLevelsArray = subFieldPath.split('.');
                const fieldLevelDepth = fieldLevelsArray.length;
                let reference = formedDefaultData;
                fieldLevelsArray.forEach((level, index) => {
                    if (index + 1 >= fieldLevelDepth) {
                        reference[level] = defaultValue;
                    } else {
                        if (reference[level]) {
                            reference = reference[level]
                        } else {
                            reference[level] = {};
                            reference = reference[level];
                        }
                    }
                })
                return formedDefaultData;

            },
            {}
        );
        return defaultFieldData;
    }

    addEditRecord = () => {
        const {
            recordConfigPath,
            onChange,
            gridData,
            columns
        } = this.props;
        const { formData } = this.context;
        const { filters } = this.state;
        const addIndex = this.isGridDataArray() && gridData ? gridData.length : 0;
        const fieldsDefaultVaule = this.mapfieldsDefaultVauleToFormData({ columns });
        const injectObj = {
            ...fieldsDefaultVaule,
            [INJECTED_REC_ID]: generateGridRecId()
        }; //field.type === 'multiple' || field.type === 'string' ? '' : {};
        const newformData = insertArrayItemInPath(Pan.clone(formData), recordConfigPath, addIndex, injectObj, filters);

        this.setEditingRowColumn(addIndex, columns[0].field);
        onChange({ formData: newformData });
    }

    setEditingRowColumn = (rowIndex, columnKey) => {
        if (this.gridRef.current && this.gridRef.current.setEditingRowColumn) {
            this.gridRef.current.setEditingRowColumn(rowIndex, columnKey)
        }
    }

    isDeleteDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        return !(selectedKeys.length > 0);
    }

    isMoveDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        return !(selectedKeys.length === 1);
    }

    moveUpRecord(selected) {
        if (!selected) {
            return;
        }
        let { gridData } = this.props;
        let fromIndex = gridData.indexOf(_.values(selected)[0]);
        let toIndex = fromIndex >= 1 ? fromIndex - 1 : 0;
        let updateGridData = [...gridData];
        updateGridData.splice(toIndex, 0, updateGridData.splice(fromIndex, 1)[0]);
        let recordConfigPath = this.props.recordConfigPath ? this.props.recordConfigPath : '';
        let formData = Pan.clone(this.context.formData);
        setPathValue(formData, recordConfigPath, updateGridData);
        this.props.onChange({ formData });
    }

    moveDownRecord(selected) {
        if (!selected) {
            return;
        }
        let { gridData } = this.props;
        let fromIndex = gridData.indexOf(_.values(selected)[0]);
        let toIndex = fromIndex < gridData.length ? fromIndex + 1 : fromIndex;
        let updateGridData = [...gridData];
        updateGridData.splice(toIndex, 0, updateGridData.splice(fromIndex, 1)[0]);
        let recordConfigPath = this.props.recordConfigPath ? this.props.recordConfigPath : '';
        let formData = Pan.clone(this.context.formData);
        setPathValue(formData, recordConfigPath, updateGridData);
        this.props.onChange({ formData });
    }

    setDefaultValue = (valueObj) => {
        const isSelect = valueObj && valueObj.value ? valueObj.value === '__select__' : valueObj === '__select__';
        let value = valueObj && valueObj.value ? valueObj.value : valueObj;
        let { field, cellInfo, removeError, setDefaultValue } = this.props;
        if (!isSelect) {
            //value changed to default value
            //remove all grid errors
            let { errors, formData } = this.context;

            let recPath = getRecordPathWithFilters(formData, field.attrPath, this.state.filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            if (!Pan.isEmpty(errors)) {
                for (let key in errors) {
                    if (key.indexOf(recPath) === 0) {
                        // child has error
                        removeError(key);
                    }
                }
            }
        }
        setDefaultValue(value);
    }

    isAddDisabled(selected, records) {
        if (this.maxCount && records && records.length >= this.maxCount) {
            return true;
        }
        return false;
    }

    /***
     * EditorGrid - for inline editing of grid data. Expects data available before rendering.
     * 1. panSchema - pan schema 
     * 2. recordConfigPath - schema path for the root of the record form editor
     * 3. inputFields - input fields list to display inthe form`
     * 4. updateRecord - function call back on form submit
     */

    render() {
        let gridActions = this.props.gridActions || [];
        gridActions = [...gridActions];
        var gridActionsAvail = this.props.gridActionsAvail || {};
        let { editingrow, editingcolumn, errors, showErrors, disabled, displayGridMode } = this.props;;
        if (this.props.showDefaultAddAction !== false && !disabled) {
            gridActions.push({
                text: 'Add',
                avail: get(gridActionsAvail, "isAddDisabled", this.isAddDisabled),
                maxCount: parseInt(get(this, "props.field.maxCount", 0)),
                color: 'primary',
                callback: this.addEditRecord
            });
        }

        if (this.props.showDefaultDeleteAction !== false && !disabled) {
            gridActions.push({
                text: 'Delete',
                avail: get(gridActionsAvail, "isDeleteDisabled",  this.isDeleteDisabled),
                callback: (selected) => {
                    this.deleteRecord(selected);
                }
            });
        }

        if (this.props.isOrdered === true) {
            gridActions.push({
                text: 'Move Up',
                avail: get(gridActionsAvail, "isMoveDisabled",  this.isMoveDisabled),
                callback: (selected) => {
                    this.moveUpRecord(selected);
                }
            });
            gridActions.push({
                text: 'Move Down',
                avail: get(gridActionsAvail, "isMoveDisabled",  this.isMoveDisabled),
                callback: (selected) => {
                    this.moveDownRecord(selected);
                }
            });
        }

        let selectOptions = this.state.enumOptions;
        let selectWidgetValue = this.props.selectWidgetValue; //typeof (this.props.gridData) === 'string' ? this.props.gridData : '';
        let gridData = typeof this.props.gridData === 'string' ? [] : this.props.gridData;
        let showFilter = !!this.props.showFilter && gridData.length > this.props.pageSize;
        return (
            <React.Fragment>
                {selectOptions &&
                    <SelectWidget
                        className="col-sm-10"
                        onChange={this.setDefaultValue}
                        options={selectOptions}
                        value={selectWidgetValue}
                        isClearable={false}
                    />
                }
                {displayGridMode &&
                    <GridWidget
                        style={{ maxHeight: 285, ...this.props.style }}
                        className="editor-grid"
                        ref={this.gridRef}
                        gridData={gridData}
                        columns={this.state.modifiedColumns}
                        pageSize={this.props.pageSize}
                        minRows={this.props.minRows}
                        suppressColsSizeToFit={this.props.suppressColsSizeToFit}
                        gridActions={gridActions}
                        toolbarPosition={'top'}
                        showPaging={this.props.showPaging}
                        showFilter={showFilter}
                        filterText={this.state.filterText}
                        onFilter={this.onFilter}
                        errors={errors}
                        showErrors={showErrors}
                        disabled={disabled}
                        noDataText={this.props.noDataText}
                        editable={true}
                        agContext={{fields: this.context.fields}}
                        allowDrag={this.props.allowDrag}
                        allowDrop={this.props.allowDrop}
                        onMoveDrag={this.props.onMoveDrag}
                    />
                }
            </React.Fragment>
        );
    }
}

EditorGrid.contextType = FormContext;

EditorGrid.defaultProps = {
    columns: [],
    filters: [],
    // showDefaultAddAction:false,
    // showDefaultDeleteAction:false
};

if (process.env.NODE_ENV !== 'production') {
    EditorGrid.propTypes = {
        gridData: PropTypes.array,
        columns: PropTypes.array.isRequired,
        pageSize: PropTypes.number,
        minRows: PropTypes.number,
        onChange: PropTypes.func,
        recordConfigPath: PropTypes.string,
        filters: PropTypes.array,
        disabled: PropTypes.bool,
        gridActions: PropTypes.array,
        isOrdered: PropTypes.bool,
        showPaging: PropTypes.bool,
        showFilter: PropTypes.bool,
        field: PropTypes.object,
        errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        showErrors: PropTypes.bool,
        defaultValue: PropTypes.array,
        selectWidgetValue: PropTypes.any,
        setDefaultValue: PropTypes.func,
        displayGridMode: PropTypes.bool,
        editingrow: PropTypes.number,
        editingcolumn: PropTypes.string,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        gridActions: PropTypes.array,
        showDefaultAddAction: PropTypes.bool,
        showDefaultDeleteAction: PropTypes.bool,
        cellInfo: PropTypes.object,
        getTdProps: PropTypes.func
    };
}