import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { TextWidget } from '../TextWidget';
import './FilterWidget.scss';

export class FilterWidget extends Component {
    constructor(props) {
        super(props);
        let { value } = props;
        value = value || '';
        this.state = { value };
        this.keyPress = this.keyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ value: e });
    }
    
    keyPress(e) {
        if (e.keyCode == 13) {
            this.props.onFilter && this.props.onFilter(this.state.value);
        }
    }
    
    render() {
        return (
            <InputGroup className="flex-grow-1">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText className="hlgrid-header-search border-right-0 border">
                        <i className="fa fa-search" />
                    </InputGroupText>
                </InputGroupAddon>
                <TextWidget
                    id="filterTextWidget"
                    className="filter-input border-left-0 border"
                    value={this.state.value}
                    placeholder={
                        'Search ' + this.props.objectName
                    }
                    onKeyDown={this.keyPress}
                    onChange={this.handleChange}
                />
            </InputGroup>
        );
    }
}

FilterWidget.propTypes = {
    /**
     * Object Name
     */
    objectName: PropTypes.string.isRequired,
    /**
     * Function to execute upon enter key
     */
    onFilter: PropTypes.func,
};
