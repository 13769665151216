import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Col, FormText } from "reactstrap";
import { getBuilder } from "../../../utils/BuilderManager";
import { FormContext, FormConsumer } from "../../FormContext";
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";
import { RadioWidget } from "../../widgets/RadioWidget";
import { SelectWidget } from "../../widgets/SelectWidget";
import Pan from "../../schema/Pan";
import { getPathValue, getPathValues, setPathValue, deletePath } from "../../../utils/json";
import { getField, getFieldLabel, getFieldLabelComp, getFieldWidthClass, getFieldHelpString, getLeafNodesMapForDefaults, getFieldValueFromType } from "../../schema/utils";
import './RadioCardBuilder.scss';
import { BaseFieldBuilder } from '../BaseBuilder';
import { INJECTED_REC_ID, formDataSetDefaults, deleteRecursivelyEmptyParents } from '../../widgets/util';

const EMPTY_CARD_VALUE = '__empty_card__';

export class RadioCardBuilder extends BaseFieldBuilder {
    constructor(props) {
        super(props);
        this.id = Pan.id();

        this.state = {
            hideField: false,
            selection: ''
        };
    }

    execValidationRule(field, value, rule) {
        // for now no validation on this builder
        return '';
    }

    getRecordValue() {
        const { field, filters, cellInfo } = this.props;
        const { formData } = this.context;
        let attrPath = field.attrPath;
        if (field.choiceParentAttr) {
            attrPath = field.choiceParentAttr.attrPath;
        }
        return getPathValue(formData, attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]) || '';
    }

    getOptions = () => {
        const { field } = this.props;
        const { fields, formData } = this.context;
        const childrenNames = field.childrenNames || [];
        const inline = true;
        const availableChildren = childrenNames.filter(child => {
            const cField = getField(fields, child);
            let radioAvail = cField.uiHint ? cField.uiHint.radioAvail : null;
            return !Pan.isDefined(radioAvail) || radioAvail(formData);
        });
        const enumOptions = availableChildren.map((name) => {
            const radioField = getField(fields, name);
            const label = field.uiHint && field.uiHint.useHelpStringAsDisplay ? radioField.uiHint.helpstring : getFieldLabel(radioField);
            const value = name;
            return { label, value };
        });

        if (enumOptions && field.uiHint && field.uiHint.allowBlank && !field.defaultValue) {
            if (enumOptions[0]['value'] !== '') {
                enumOptions.unshift({ label: 'None', value: EMPTY_CARD_VALUE });
            }
        }
        const options = {
            enumOptions,
            inline
        };
        return options;
    }

    getSelection = () => {
        let { selection, value } = this.state;
        if (selection !== '') {
            return selection;
        }
        if (value) {
            return value;
        }
        const { field, filters } = this.props;
        const { formData } = this.context;
        const childrenNames = field.childrenNames || [];
        const result = childrenNames.filter((name) => {
            return getPathValue(formData, name, filters) !== false;
        });
        if (result.length > 0) {
            return result[0];
        } else if (field.uiHint && field.uiHint.allowBlank && !field.defaultValue) {
            return EMPTY_CARD_VALUE;
        }
        if (field.defaultValue) {
            const attrPath = field.choiceParentAttr ? field.choiceParentAttr.attrPath : field.attrPath;
            let childValue = attrPath + '.' + field.defaultValue;
            return childValue;
        }
        return childrenNames[0];
    }

    onSelectionChange = (value, dontSetState) => {
        const { field, onChange, filters } = this.props;
        const { formData, formDataBackup } = this.context;
        let newFormData = Pan.clone(formData);
        let newFormDataBackup = Pan.clone(formDataBackup);
        if (value === undefined) {
            if (field.uiHint && !field.uiHint.allowBlank && field.defaultValue) {
                value = field.defaultValue;
            } else if (field.uiHint && field.uiHint.allowBlank && !field.defaultValue) {
                value = EMPTY_CARD_VALUE;
            }
        }
        const selection = value;

        // make a copy of the current formData before switching to the new selection
        field.childrenNames.forEach((name) => {
            if (name !== selection) {
                //check if choice is leaf
                let index = this.context.fields.findIndex((f) => {
                    return f.attrPath === selection;
                });
                const selectedField = this.context.fields[index];
                if (selectedField && !selectedField.childrenNames) {
                    //leaf node. set the field value
                    let fieldValue = getFieldValueFromType(selectedField);
                    setPathValue(newFormData, selection, fieldValue, filters);
                }
                let data = getPathValue(newFormData, name, filters);
                if (data) {
                    setPathValue(newFormDataBackup, name, data, filters);
                }
                deletePath(newFormData, name, filters);
                deleteRecursivelyEmptyParents(newFormData, name, filters);
            }
        });
        //set default for selected node children
        let leafNodesMap = getLeafNodesMapForDefaults([selection], this.context.fields, true);
        newFormData = formDataSetDefaults(newFormData, this.context.fields, leafNodesMap[selection], filters);

        // get backup copy from the old formData and merge it back to formData
        const oldData = getPathValue(newFormDataBackup, selection, filters);
        if (oldData) {
            setPathValue(newFormData, selection, oldData, filters);
        }

        if (!dontSetState) {
            // setState to notify form to re-render
            setTimeout(() => {
                this.setState({ selection });
            }, 250);
        }
        onChange({ formData: newFormData, formDataBackup: newFormDataBackup, fieldId: field.attrPath });
    }

    renderRadioSelection = () => {
        const {
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            disabled,
            field,
            showErrors
        } = this.props;

        const { fields } = this.context;
        const selection = this.getSelection();
        if (selection == EMPTY_CARD_VALUE) {
            return (<div />);
        }
        const Builder = getBuilder({name: selection, fields});
        const thisField = getField(fields, selection);
        let containerLabelWidthSize = getPathValues(thisField, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        let hideLabel = field.uiHint && field.uiHint.singleLineLayout;
        return (
            <FormGroup key={selection}>
                <Builder
                    name={selection}
                    field={thisField}
                    onChange={onChange}
                    addError={addError}
                    removeError={removeError}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    filters={filters}
                    disabled={disabled}
                    hideLabel={hideLabel}
                    containerLabelWidthSize={containerLabelWidthSize}
                    showErrors={showErrors}
                />
            </FormGroup>
        );
    }

    renderChildren = () => {
        let {
            name,
            field,
            onBlur,
            onFocus,
            disabled,
            showErrors,
            containerLabelWidthSize,
            isEditorGridColumnEditor
        } = this.props;

        if (this.props.hidden || this.state.hideField || getPathValues(field, 'uiHint.hidden')) {
            return (
                <div />
            );
        }

        const options = this.getOptions();
        const selection = this.getSelection();
        const largeSelectionCount = field && field.uiHint && field.uiHint.largeSelectionCount ? field.uiHint.largeSelectionCount : 4;
        let selectWidget = '';
        let fieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let id = this.id;
        if (options.enumOptions && options.enumOptions.length >= largeSelectionCount) {
            id = selection + this.id;
            selectWidget = <SelectWidget
                id={id}
                name={name}
                value={selection}
                isClearable={false}
                onChange={this.onSelectionChange}
                onBlur={onBlur}
                onFocus={onFocus}
                options={options.enumOptions}
                disabled={disabled}
                showErrors={showErrors}
            />;
        } else {
            selectWidget = <RadioWidget
                options={options}
                id={id}
                name={name}
                className={"form-control " + fieldClassName}
                value={selection}
                onChange={this.onSelectionChange}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                showErrors={showErrors}
            />;
        }
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let helpString = getFieldHelpString(field, this.context.formData);
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';

        // hideSingleChoice => hide choice component if there's only one option
        let { hideSingleChoice, singleLineLayout } = field.uiHint;
        if (Pan.isFunction(hideSingleChoice)) {
            hideSingleChoice = hideSingleChoice();
        }

        if (singleLineLayout) {
            return (
                <React.Fragment>
                    <FormGroup className="d-flex flex-row single-line-layout" test_id={field.attrPath}>
                        {
                            !hideSingleChoice && LabelComp
                        }
                        <Col className={hideSingleChoice ? 'd-none' : ''}>
                            <div className={"select-display-same-row " + fieldClassName} style={{ padding: 0 }}>
                                {selectWidget}
                            </div>
                            {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                            {error && showErrors && !disabled && <FormFeedbackWidget target={id} feedback={error} />}
                        </Col>
                        <Col>
                            {this.renderRadioSelection()}
                        </Col>
                    </FormGroup>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <FormGroup className={hideSingleChoice ? "d-none flex-row" : "d-flex flex-row"} test_id={field.attrPath}>
                        {
                            LabelComp
                        }
                        <Col>
                            <div className={"select-display-same-row " + fieldClassName} style={{ padding: 0 }}>
                                {selectWidget}
                            </div>
                            {error && showErrors && !disabled && <FormFeedbackWidget target={id} feedback={error} />}
                        </Col>
                    </FormGroup>
                    {this.renderRadioSelection()}
                </React.Fragment>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderChildren()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

RadioCardBuilder.contextType = FormContext;

RadioCardBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    RadioCardBuilder.propTypes = {
        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        cellInfo: PropTypes.object,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        isEditorGridColumnEditor: PropTypes.bool,
        name: PropTypes.string,
        containerLabelWidthSize: PropTypes.string,
        hidden: PropTypes.bool,
		/**
         * *largeSelectionCount* - count of the number of options
		 * *attrPath* - defines the attribute schema path eg: "$.a.b.c"
         * *defaultValue* - sets the default value of the field.
		 * *childrenNames* - array of strings containing names of child fields
         * *allowBlank* - if false,blank values will add error
         * UiHint properties :
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *fieldLabel* - adds a label to the field
         * *hideLabel* - hides the label
		 * *singleLineLayou* - hides label and displays everything in single line
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
		 */
        field: PropTypes.shape({
            childrenNames: PropTypes.array,
            defaultValue: PropTypes.string,
            allowBlank: PropTypes.bool,
            attrPath: PropTypes.string,
            choiceParentAttr: PropTypes.shape({
                attrPath: PropTypes.string
            }),
            uiHint: PropTypes.shape({
                largeSelectionCount: PropTypes.number,
                helpString: PropTypes.string,
                labelWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.bool,
                fieldLabelAutoGen: PropTypes.string,
                singleLineLayout: PropTypes.bool,
                vtype: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    fields: PropTypes.object,
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            }),
        }),

    };
}
