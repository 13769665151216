import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { queryConverter } from '../logViewer_v2/LogQuery'

const handleArrayTypePure = (data) => {
	//Arrays should be rendered as a list
	const rows = []
	data.forEach((dataObj, i) => {
		let v
		if (_.isObject(dataObj)) {
			v = dataObj[Object.keys(dataObj)[0]]
		} else {
			v = dataObj.toString()
		}
		rows.push(`<li key=${i}>${v}</li>`)
	})
	const ul = document.createElement('ul')
	ul.setAttribute('class', 'flex-item-value-ul')
	ul.innerHTML = rows.join('')
	return ul
}

const handleArrayTypeReact = (data) => {
	//Arrays should be rendered as a list
	const rows = []
	data.forEach((dataObj, i) => {
		let v
		if (_.isObject(dataObj)) {
			v = dataObj[Object.keys(dataObj)[0]]
		} else {
			v = dataObj.toString()
		}
		rows.push(<li key={i}>{v}</li>)
	})
	return <ul className="flex-item-value-ul">{rows}</ul>
}

const handleArray = (data) => {
	//Arrays should be rendered as a list
	const rows = []
	data.forEach((dataObj, i) => {
		let v
		if (_.isObject(dataObj)) {
			v = dataObj[Object.keys(dataObj)[0]]
		} else {
			v = dataObj.toString()
		}
		rows.push(v)
	})
	return rows.join('|')
}

const isOfArrayType = (fieldObj) => {
	if (_.isArray(fieldObj.origType)) {
		for (const t of fieldObj.origType) {
			if (_.isObject(t) && t.type === 'array') {
				return true
			}
		}
	} else if (_.isObject(fieldObj.origType) && fieldObj.origType.type === 'array') {
		return true
	} else if (fieldObj.origType === 'array') {
		return true
	}

	return false
}

export default {
	// Humanize info
	getReadableBytes(bytes, decimal_places) {
		if (decimal_places === undefined) {
			decimal_places = 0
		}
		if (bytes === 0 || bytes === undefined) {
			return '0B'
		}
		const i = Math.floor(Math.log(bytes) / Math.log(1024))
		const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		if (i > 2) {
			decimal_places = 2
		}
		const readableByte = Number((bytes / Math.pow(1024, i)).toFixed(2) * 1).toFixed(decimal_places)
		const readableMetric = sizes[i]
		return `${readableByte} ${readableMetric}`
	},

	getStyledRisk(risk) {
		return ['<span class="ag-risk ag-risk-', risk, '">', risk, '</span>'].join('')
	},

	getStyledSeverity(severity) {
		const severity_to_risk_number_lut = {
			Critical: 5,
			High: 4,
			Medium: 3,
			Informational: 2,
			Low: 1
		}
		return ['<span class="ag-risk ag-risk-', severity_to_risk_number_lut[severity], '">', severity, '</span>'].join('')
	},

	getReadableNumber(num, decimal_places) {
		if (decimal_places === undefined) {
			decimal_places = 0
		}
		const neg = num < 0
		const units = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
		if (neg) {
			num = -num
		}
		if (num < 1) {
			return (neg ? '-' : '') + num
		}
		const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
		// num = Math.round(Number((num / Math.pow(1000, exponent)).toFixed(2)))
		if (exponent >= 1) {
			decimal_places = 2
		}
		num = Number((num / Math.pow(1000, exponent)).toFixed(decimal_places))
		const unit = units[exponent]
		return `${(neg ? '-' : '') + num}${unit}`
	},

	// Schema Related Utils
	getFields(logtype, schema) {
		const [vendor, type] = logtype.split('.', 2)
		const { fields } = schema[vendor]['content'][type]
		return fields
	},

	isArrayTypeField(fieldObj) {
		return isOfArrayType(fieldObj)
	},

	getFirstAvailableLogTypeFromSchema(schema) {
		if (Object.keys(schema).length >= 1) {
			const category = Object.keys(schema)[0]
			const field = Object.keys(schema[category].content)[0]
			return `${category}.${field}`
		} else {
			return ''
		}
	},

	getTransformedTime(time, fieldObj) {
		//default format for panw
		let date = ''
		if (!isNaN(+time)) {
			date = moment(time / 1000).format('MM/DD/YYYY hh:mm:ss A')
		} else {
			date = moment(time).format('MM/DD/YYYY hh:mm:ss A')
		}
		return date
	},

	getTransformedQueryBuilderTime(time) {
		//default format for panw
		let date = ''
		if (time) {
			date = moment.unix(time).format('MM/DD/YYYY hh:mm:ss A')
			if (date === 'Invalid date') {
				//defaut format for traps
				return time
			}
		}
		return date
	},

	escapeHtml(string) {
		const entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#39;',
			'/': '&#x2F;',
			'`': '&#x60;',
			'=': '&#x3D;'
		}
		return String(string).replace(/[&<>"'`=\/]/g, (s) => {
			return entityMap[s]
		})
	},

	computeLogQurySchemaFromSchema(schema) {
		// Filter Schema
		const logQuerySchema = {}
		_.forOwn(schema, (v, k) => {
			if (k !== 'super') {
				logQuerySchema[k] = _.cloneDeep(schema[k])
			}
		})

		// Scan all categories
		_.forOwn(logQuerySchema, (v1, k1) => {
			if (typeof v1 !== 'undefined') {
				// For each category, remove array type fields so that it won't appear in query
				_.forOwn(v1.content, (v2, k2) => {
					logQuerySchema[k1].content[k2].fields = _.filter(v2.fields, (i) => {
						return !isOfArrayType(i)
					})
				})
			}
		})

		// Remove Empty Categories
		_.forOwn(logQuerySchema, (v, k) => {
			if (Object.keys(v.content).length === 0) {
				delete logQuerySchema[k]
			}
		})
		return logQuerySchema
	},

	computeAccessor(data, fieldObj, pure) {
		/*
          fieldObj.name = "messageData.eventParameters"
          fieldObj.name.split('.'): Array(2)
          0: "messageData"
          1: "eventParameters"
       */
		const fieldObjHierArray = fieldObj.name.split('.')

		//Nested iterate i.e. a.b.c.d
		for (let i = 0; i < fieldObjHierArray.length; i++) {
			if (data) {
				data = data[fieldObjHierArray[i]]
			} else {
				return ''
			}
		}

		if (typeof data === 'string') {
			data = pure ? this.escapeHtml(data) : data
		}

		if (fieldObj.type === 'timestamp') {
			return this.getTransformedTime(data, fieldObj)
		}

		if (data instanceof Array) {
			return pure ? handleArrayTypePure(data) : handleArrayTypeReact(data)
		}

		if (_.isBoolean(data)) {
			return data.toString()
		}

		if (fieldObj.primaryField && data) {
			if (data[fieldObj.primaryField] instanceof Array) {
				return pure ? handleArrayTypePure(data[fieldObj.primaryField]) : handleArrayTypeReact(data[fieldObj.primaryField])
			}
			return _.get(data, `${fieldObj.primaryField}`).toString()
		}
		//Should not technically be an object
		if (data instanceof Object) {
			return ''
		}

		return data
	},

	computeQueryBuilderAccessor(data, fieldObj) {
		/*
          fieldObj.name = "messageData.eventParameters"
          fieldObj.name.split('.'): Array(2)
          0: "messageData"
          1: "eventParameters"
       */
		const fieldObjHierArray = fieldObj.name.split('.')

		//Nested iterate i.e. a.b.c.d
		for (let i = 0; i < fieldObjHierArray.length; i++) {
			if (data) {
				data = data[fieldObjHierArray[i]]
			} else {
				return ''
			}
		}

		if (data instanceof Array) {
			return handleArray(data)
		}

		if (fieldObj.type === 'timestamp') {
			return this.getTransformedQueryBuilderTime(data, fieldObj)
		}

		if (_.isBoolean(data)) {
			return data.toString()
		}

		if (fieldObj.primaryField && data) {
			if (data[fieldObj.primaryField] instanceof Array) {
				return handleArray(data[fieldObj.primaryField])
			}
			return _.get(data, `${fieldObj.primaryField}`).toString()
		}

		//Should not technically be an object
		if (data instanceof Object) {
			return ''
		}

		return data
	},

	convertQueryTimeStamp(query, fields) {
		const queryExp = queryConverter.queryParseConverter(query, fields, queryConverter.escapeStringValue)
		const convertedQuery = queryExp.forEachToken((token) => {
			if (token.kind && token.field) {
				if (token.kind === 'value' && token.field.type === 'timestamp') {
					const timestamp = moment(token.value.trim(), 'MM/DD/YYYY hh:mm:ss A', true)
					if (timestamp.isValid()) {
						token.value = timestamp.toISOString()
					} else {
						throw new Error('Invalid Date')
					}
				}
			}
		})
		return convertedQuery.toString()
	},

	getMainMenuItems(params) {
		params.defaultItems.pop() // removes "resetColumns"
		params.defaultItems.pop() // removes "separator"
		params.defaultItems.pop() // removes "separator"

		return params.defaultItems
	}
}
