import React, { Component, Fragment } from "react";
import { FormContext, FormConsumer } from '../../FormContext';
import { getRecordPathWithFilters, getPathValue } from "../../../utils/json";
import Pan from "../../schema/Pan";
import './EditorGridCellBuilder.scss';
import bldrmap from '../../builders';
import { INJECTED_REC_ID, updateFilterCurrentIndex } from "../../widgets/util";
import ReactDOM from "react-dom";
import CellComponentPortal from "./CellComponentPortal";
import { Input } from "reactstrap";

export class EditorGridCellBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditor: false,
            builderId: 'cell-' + Math.floor(Math.random() * 100)
        }
        this.builder = React.createRef();
        this.offsetBuilder = React.createRef();
    }

    afterGuiAttached = () => {
        if (this.props.cellStartedEdit) {
            this.focus();
        }
    }

    focusIn = () => {
        setTimeout(() => {
            this.focus();
        }, 100);
    }

    focus = () => {
        if (this.builder && this.builder.current && this.builder.current.focus) {
            this.builder.current.focus();
        } else {
            let builder = ReactDOM.findDOMNode(this.builder);
            if (builder && builder.focus) {
                builder.focus();
            }
        }
    }

    getBuilder = (editorProps) => {
        let {
            builder,
            field
        } = editorProps;

        //dynamic builder
        if (typeof builder === 'function') {
            builder = builder(field);
        }

        //predefined builder
        if (typeof builder === 'string') {
            builder = bldrmap[builder];
        }
        return builder;
    }

    startEditing = () => {
        this.setState({
            showEditor: true
        })
    }


    renderCell = () => {
        let editorProps = this.props.colDef.editorProps;
        const {
            field,
            filters
        } = editorProps;
        let type = field.type;
        let Builder = this.getBuilder(editorProps);
        if (this.offsetBuilder && this.offsetBuilder.current && this.offsetBuilder.current.getBoundingClientRect) {
            this.builderOffset = this.offsetBuilder.current.getBoundingClientRect();
        }

        const { formData, showErrors } = this.context;

        let value = this.props.value || getPathValue(formData, field.attrPath, filters, this.props.data[INJECTED_REC_ID]);
        let displayVlue = value;
        let valueGetter = getPathValue(field, 'uiHint.columnConfig.valueGetter');
        if (Pan.isFunction(valueGetter)) {
            displayVlue = valueGetter({ data: this.props.data });
        } else if (value && value.member && Array.isArray(value.member)) {
            displayVlue = value.member.map(val => val.value).join(', ');
        } else if (field.attrPath.endsWith('.member.*')) {
            displayVlue = value && Pan.isString(value) ? value : value.label || value.value;
        } else {
            displayVlue = value ? (Pan.isString(value) ? value : JSON.stringify(value)) : '';
        }
        let builderName = Builder ? Builder.NAME : '';
        let inlineBuilders = ['TextfieldBuilder', 'CompletionBuilder', 'CheckboxBuilder', 'NumberfieldBuilder'];
        

        if (inlineBuilders.indexOf(builderName) >= 0) {
            //show the Builder directly in grid cell
            return (
                <div
                    className={'editorgrid-cell-editor ' }
                    fieldid={field.attrPath}
                    tabIndex={1}>
                    <div>
                        <Builder
                            {...editorProps}
                            ref={this.builder}
                            filters={updateFilterCurrentIndex(filters, this.props.data[INJECTED_REC_ID])}
                            cellInfo={this.props.data}
                            showErrors={showErrors}
                            autoFocus={true} />
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    className={'editorgrid-cell-editor '}
                    fieldid={field.attrPath}
                    tabIndex={1}
                    ref={this.offsetBuilder}
                    id={this.state.builderId}>
                    <Input
                        onFocus={this.startEditing}
                        type="text"
                        innerRef={this.builder}
                        value={Pan.clone(displayVlue)}
                        className='editorgrid-cell-input'
                        autoFocus={true} />
                    <CellComponentPortal
                        {...editorProps}
                        dontHandleMouseClik = {type && type === 'sequence'}
                        filters={updateFilterCurrentIndex(filters, this.props.data[INJECTED_REC_ID])}
                        cellInfo={this.props.data}
                        showErrors={showErrors}
                        builderId={this.state.builderId}
                        offset={this.builderOffset}
                        Builder={Builder}
                        showEditor={this.state.showEditor}
                        api={this.props.api}
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <Fragment>
                <FormConsumer>
                    {() => this.renderCell()}
                </FormConsumer>
            </Fragment>
        )
    }
}
EditorGridCellBuilder.contextType = FormContext;