import React, { Component, Fragment } from 'react';
import { AutoSuggestList } from './AutoSuggestList';
import { TextareaWidget } from '../TextareaWidget';

import Pan from '../../schema/Pan';

import './AutoSuggestWidget.scss';

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_RETURN = 13;
const KEY_ENTER = 14;
const KEY_ESCAPE = 27;
const KEY_SPACE = 32;
const KEY_BACK_SPACE = 8;

export class AutoSuggestWidget extends Component {
    constructor(props) {
        super(props);

        this.textarea = React.createRef();

        this.loadOptions = this.loadOptions.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onConditionChange = this.onConditionChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleCompletionResponse = this.handleCompletionResponse.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.state = {
            showSuggestions: false,
            // What the user has entered
            userInput: '',
            currentValue: props.value || '',
            currentCondition: 'and',
            condition: [
                {
                    value: 'and',
                    label: 'And',
                },
                {
                    value: 'or',
                    label: 'OR',
                },
            ],
            isNegate: false,
            showNotCondition: !!props.showNotCondition,
            showCondition: !!props.showCondition  || true
        };
    }

    componentDidMount() {
        this.loadOptions('').then(this.handleCompletionResponse, function (err) {
            console.log(err); // Error: "It broke"
        });
    }

    onFocus(event) {
        this.setState({
            showSuggestions: true,
        }, () => {
            if (this.props.onFocus) {
                this.props.onFocus(event);
            }
        });
    }

    onConditionChange(value) {
        this.setState({
            currentCondition: value,
        });
    }
    onCheckBoxChange(value) {
        this.setState({
            isNegate: value,
        });
    }

    onBlur(event) {
        if (!event.relatedTarget) {
            this.setState({
                showSuggestions: false,
                userInput: ''
            }, () => {
                if (this.props.onBlur) {
                    this.props.onBlur(event);
                }
            });
        }
    }

    onClick(e) {
        let { currentValue, currentCondition, userInput ,isNegate } = this.state;
        let notCondition =  isNegate ? ' not ' : '';
        let text = `'${e.currentTarget.innerText}'`;
        if (!currentValue) {
            currentValue = `${notCondition} ${text}`;
        } else if (userInput != '') {
            currentValue = currentValue.slice(0, -userInput.length);
            currentValue += `${text}`;
        } else {
            currentValue += ` ${currentCondition} ${notCondition} ${text}`;
        }
        this.setState({
            showSuggestions: false,
            currentValue,
            userInput: '',
        }, () => {
            this.props.onChange(currentValue);
        });
    }

    onKeyDown(e) {
        // User pressed the enter key
        if (e.keyCode === KEY_SPACE) {
            this.setState({
                userInput: '',
            });
        }
        // User pressed the esc
        else if (e.keyCode === KEY_ESCAPE) {
            this.setState({
                showSuggestions: false,
                userInput: ''
            });
        }
    }

    onChange(value) {
        const { options } = this.state;
        let userInput = value;

        if (userInput) {
            userInput = userInput.split(' ');
            if (userInput.length > 0) {
                userInput = userInput[userInput.length - 1];
            } else {
                userInput = userInput;
            }
        }
        // Filter our suggestions that don't contain the user's input
        const filteredOptions = options.filter(
            option => !userInput ? true : option.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            filteredOptions,
            showSuggestions: true,
            userInput: userInput,
            currentValue: value,
        }, () => {
            this.props.onChange(value);
        });
    }

    handleCompletionResponse(response) {
        let options = [];
        response.forEach(element => {
            options.push(element.label);
        });
        // console.log('options', options);
        this.setState({
            options,
            filteredOptions: options,
        });
    }

    loadOptions(inputValue) {
        //remote query when inputValue is empty (first time load not filter) or when the query is remote
        let { loadOptions, completionXpath, configLocation } = this.props;
        //do remote query
        let promise = Pan.isFunction(loadOptions)
            ? loadOptions(inputValue, completionXpath, configLocation)
            : new Promise(resolve => {
                resolve([]);
            });
        return promise;
    }

    render() {
        const {
            placeholder,
            required,
            disabled,
            readonly,
            className,
            showErrors,
            maxLength,
            error
        } = this.props;

        let positionStyle;
        if (this.textarea && this.textarea.current) {
            const rect = this.textarea.current.getBoundingClientRect();
            positionStyle = {
                top: rect.bottom,
                left: rect.left,
                width: rect.width,
                maxHeight: 400
            };
        }

        return (
            <Fragment>
                <div ref={this.textarea} onKeyDown={this.onKeyDown} onClick={this.onFocus}>
                    <TextareaWidget
                        id={this.props.id + '-textarea'}
                        placeholder={placeholder}
                        className={className}
                        onFocus={this.onFocus}
                        onChange={this.onChange}
                        required={required}
                        disabled={disabled}
                        maxLength={maxLength}
                        readOnly={readonly}
                        value={this.state.currentValue}
                        invalid={showErrors && !!error}
                    />
                </div>
                <AutoSuggestList
                    {...this.state}
                    positionStyle={positionStyle}
                    onBlur={this.onBlur}
                    onClick={this.onClick}
                    onKeyDown={this.onKeyDown}
                    onConditionChange={this.onConditionChange}
                    onCheckBoxChange={this.onCheckBoxChange}
                />
            </Fragment>
        );
    }
}
