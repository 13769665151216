import React from 'react';
import './PrismaSaasSettingsBuilder.scss';
import {
    BaseBuilder,
    ButtonWidget,
    getAuthHeaders,
    getLoginServiceURL,
    getStoreState,
    getPathValue,
    showModal,
    hideModal,
    MOCK_SERVICES
} from "ui-lib";
import { ajax } from 'rxjs/ajax';
import _ from 'lodash';

const _T = str => str;

export default class PrismaSaasSettingsBuilder extends BaseBuilder {

    constructor(props) {
        super(props);
        this.state = {
            tenantUrl: null,
            modalMessage: _T('Fetching Prisma SaaS details. Please wait.')
        }
    }

    componentDidMount() {
        let arrExtensions = getPathValue(getStoreState(), 'main.tenant.extensions');
        let psTenantobj = _.find(arrExtensions, (extension) => extension.name.toLowerCase() === 'aperture');
        let psTenantObjInfo = {
            tenant_id: null
        }
        if (psTenantobj) {
            psTenantObjInfo = JSON.parse(psTenantobj.info)
        }
        else
            this.setState({
                modalMessage: _T('Looks like Prisma SaaS is not configured.')
            })
        if (psTenantObjInfo.tenant_id) {
            ajax({
                withCredentials: MOCK_SERVICES ? false : true,
                method: 'POST',
                url: getLoginServiceURL("api/actionservice/aperture/prisma/v1/access/getApertureUrl?tenantId=" + psTenantObjInfo.tenant_id)["SERVICE_URL"],
                responseType: "json",
                headers: {
                    'Content-Type': 'application/json',
                    ...getAuthHeaders()
                },
            }).subscribe((res) => {
                this.setState({
                    tenantUrl: _.get(res, 'response.url', null),
                    modalMessage: _T('To review and modify the SAML configuration on Prisma SaaS, click OK. When you are done, come back here to finish configuring Clientless VPN.')
                })},
                (err) => {
                    console.error(err);
                    this.setState({
                        tenantUrl: null,
                        modalMessage: _T('We cannot reach Prisma SaaS at the moment. Please try again later.')
                    })
                }
            )
        }
    }

    render() {
        let actionButtons = [];
        if (this.state.tenantUrl)
            actionButtons.push({
                text: "OK",
                color: "secondary",
                action: () => {
                    window.open(this.state.tenantUrl, '_blank');
                    hideModal('PrismaSaasSettings');
                }
            });
        actionButtons.push({
            text: "Cancel",
            color: "secondary",
            action: () => {
                hideModal('PrismaSaasSettings')
            }
        });

        return (
            <div className={'prismaSaasSettingsBuilder'}>
                <div className="d-flex flex-row form-group btn-holder">
                    <label className="flex-grow-0 flex-shrink-0 col-form-label col-3">Configure SaaS</label>
                    <div className="col">
                        <div className="select-display-same-row">
                            <div className="field-radio-group form-control">
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <ButtonWidget
                                            color="primary"
                                            size="sm"
                                            className={'btnSettings'}
                                            onClick={() => {
                                                showModal({
                                                    id: "PrismaSaasSettings",
                                                    open: true,
                                                    size: 'sm',
                                                    toggle: () => {
                                                        hideModal("PrismaSaasSettings");
                                                    },
                                                    title: _T('Go to Prisma SaaS Settings'),
                                                    message: this.state.modalMessage,
                                                    actions: actionButtons
                                                });
                                            }}
                                            text="Configure"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}