import { jsonPath, Pan, capitalizeLetter, getPathValue } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const externalDynamicListColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        recordConfigPath: '$.config.devices.entry.vsys.entry.external-list.entry',
        minWidth: 80,
        valueGetter: params => {
            const str = getPathValue(params.data, "$.display-name") || getPathValue(params.data, "$.@name");
            return Pan.htmlEncode(str);
        }
    },
    {
        headerName: 'Location',
        field: '@loc',
        minWidth: 200
    },
    {
        headerName: _T('Type'),
        valueGetter: params => {
            let rec = params.data;
            let type = rec.type ? Object.keys(rec['type'])[0] : '';
            if (type === '' && rec['@name'].indexOf('-ip-list') > 0) {
                type = 'ip';
            }
            return type;
        },
        minWidth: 100
    },
    {
        headerName: _T('Description'),
        valueGetter: params => {
            let rec = params.data;
            const value = getPathValue(rec, "$.description") || getPathValue(rec, "$.type.*.description");
            const str = value ? value : "";
            return Pan.htmlEncode(str);
        },
        minWidth: 200
    },
    {
        headerName: _T('Source'),
        valueGetter: params => {
            let rec = params.data;
            if (rec['type'] === 'predefined-ip') {
                return this.getPredefinedListName(rec.type['predefined-ip'].url);
            }
            const typeStr = rec.type ? Object.keys(rec['type'])[0] : '';
            const str = typeStr ? rec.type[typeStr].url : '';
            return Pan.htmlEncode(str);
        },
        minWidth: 150
    },
    {
        headerName: _T('Certificate Profile'),
        valueGetter: params => {
            let rec = params.data;
            if (rec['type'] === 'predefined-ip') {
                return '';
            }
            const typeStr = rec.type ? Object.keys(rec['type'])[0] : '';
            return typeStr ? rec.type[typeStr]['certificate-profile'] : '';
        },
        minWidth: 100
    },
    {
        headerName: _T('Frequency'),
        valueGetter: params => {
            let rec = params.data;
            const typeStr = rec.type ? Object.keys(rec['type'])[0] : '';
            const value = typeStr ? jsonPath(rec.type[typeStr], 'recurring')[0] : '';
            if (Pan.isEmpty(value)) return '';
            if (value.hourly) {
                return _T('Hourly');
            }
            if (value['five-minute']) {
                return _T('Five Minute');
            }
            let at = '';
            let day = '';
            if (value.daily) {
                at = value.daily.at;
                return `Daily at ${at}:00`;
            }

            if (value.monthly) {
                at = value.monthly.at;
                day = value.monthly['day-of-month'];
                return `Monthly on ${day} at ${at}:00`;
            }

            if (value.weekly) {
                at = value.weekly.at;
                day = capitalizeLetter(value.weekly['day-of-week']);
                return `Weekly on ${day} at ${at}:00`;
            }

            return '';
        },
        minWidth: 100
    }
];

const gridOverride = {
    gridActionsAvail: {
        isCloneDisabled: function (selected) {
            let selectedObjs = Object.values(selected);
            let cloneDisabled = true;
            if (selectedObjs && selectedObjs.length > 0) {
                let predefinedObj = selectedObjs.find((obj) => obj['@loc'] === 'predefined');
                cloneDisabled = predefinedObj ? true : false;
            }
            return cloneDisabled;
        }
    }
};
export default class ExternalDynamicLists extends DGBaseViewer {

    getPredefinedListName = v => {
        var value = v;
        var panwList = [];
        if (Pan.isArray(panwList)) {
            for (var i = 0; i < panwList.length; i++) {
                if (panwList[i]['@name'] === v && panwList[i]['display-name']) {
                    value = panwList[i]['display-name'];
                    break;
                }
            }
        }
        return value;
    };

    getPredefinedListValue = v => {
        var value = v;
        var panwList = Pan.base.json.paths(
            Pan.base.predefinedContentJSON,
            '$.ip-block-list-v2.entry.*',
        );
        if (Pan.isArray(panwList)) {
            for (var i = 0; i < panwList.length; i++) {
                if (
                    panwList[i]['display-name'] &&
                    panwList[i]['display-name'] === v
                ) {
                    value = panwList[i]['@name'];
                    break;
                }
            }
        }
        return value;
    };

    groupRendererForType = function (gvalue, obj, record) {
        if (!record.json.EXTRA) {
            return '';
        }
        var sGroupValue = _T('Dynamic IP Lists');
        if (record.json.EXTRA.group === '2-domain') {
            sGroupValue = _T('Dynamic Domain Lists');
        } else if (record.json.EXTRA.group === '3-url') {
            sGroupValue = _T('Dynamic URL Lists');
        }
        return sGroupValue;
    };

    getType = function (fields, type) {
        // need to render exception on second tab
        // so in first tab, exclude "type" exception list from childrenNames
        var path = '$.type.' + type;
        var exceptionList = [
            {
                name: path + '.exception-list.member',
                childrenNames: [path + '.exception-list.member.*'],
                uiHint: {
                    checkboxSelection: true,
                    height: 220,
                },
            },
            {
                name: path + '.exception-list.member.*',
                uiHint: {
                    columnConfig: {
                        headerName: _T('List Entries'),
                    },
                },
            },
        ];

        fields = fields.concat(exceptionList);
        var typeCreateListChildren = [path + '.description', path + '.url'];
        if (type === 'domain') {
            var expandDomain = {
                name: '$.type.domain.expand-domain',
                uiHint: {
                    fieldLabel: _T(
                        'Automatically expand to include subdomains',
                    ),
                },
            };
            typeCreateListChildren = typeCreateListChildren.concat([path + '.expand-domain']);
            fields.push(expandDomain);
        }
        if (type !== 'predefined-ip') {
            var serverAuthFS = {
                name: type + 'ServerAuth',
                childrenNames: [path + '.certificate-profile'],
                uiHint: {
                    builder: 'FieldSetBuilder',
                    fieldLabel: _T('Server Authentication'),
                },
            };
            typeCreateListChildren = typeCreateListChildren.concat([
                type + 'ServerAuth',
                path + '.auth',
                path + '.recurring',
            ]);
            fields.push(serverAuthFS);
        }

        fields.push({
            name: "tabCreateList-" + type,
            childrenNames: typeCreateListChildren,
            uiHint: {
                fieldLabel: _T("Create List")
            }
        });
        fields.push({
            name: "tabExceptionList-" + type,
            childrenNames: [path + '.exception-list.member'],
            uiHint: {
                fieldLabel: _T("Exception List")
            }
        });
        fields.push({
            name: 'theTabs-' + type,
            childrenNames: ["tabCreateList-" + type, "tabExceptionList-" + type],
            uiHint: {
                builder: 'TabBuilder'
            }
        });
        fields.push({
            name: path,
            childrenNames: ['theTabs-' + type]
        });
        return fields;
    };

    getRecurring = function (type) {
        var path = "$.type." + type;
        return [{
            name: path + ".recurring.choice",
            uiHint: {
                fieldLabel: _T("Check for updates"),
                autoSelect: true
            }
        },
        {
            name: path + ".recurring.daily.at",
            uiHint: {
                width: 50
            }
        },
        {
            name: path + ".recurring.daily",
            childrenNames: [
                path + ".recurring.daily.atLabel",
                path + ".recurring.daily.at"
            ],
            uiHint: {
                // layout: [{
                //     dailyAtLabel : 'col-6',
                //     dailyAt : 'col-6'
                // }],
                border: false
            }
        },
        {
            name: path + ".recurring.daily.atLabel",
            defaultValue: _T("at"),
            uiHint: {
                width: 30,
                style: 'margin: 3px 6px 3px 6px'
            }
        },
        {
            name: path + ".recurring.daily.at",
            uiHint: {
                width: 70,
                format: "H:i",
                maxLength: 5,
                disabled: false,
                increment: 60
            }
        },
        {
            name: path + ".recurring.monthly",
            childrenNames: [
                path + ".recurring.monthly.dayOfMonthLabel",
                path + ".recurring.monthly.day-of-month",
                path + ".recurring.monthly.atLabel",
                path + ".recurring.monthly.at"
            ],
            uiHint: {
                border: false
            }
        },
        {
            name: path + ".recurring.monthly.dayOfMonthLabel",
            defaultValue: _T("on"),
            uiHint: {
                width: 25
            }
        },
        {
            name: path + ".recurring.monthly.day-of-month",
            uiHint: {
                width: 75
            }
        },
        {
            name: path + ".recurring.monthly.atLabel",
            defaultValue: _T("at"),
            uiHint: {
                width: 25
            }
        },
        {
            name: path + ".recurring.monthly.at",
            uiHint: {
                width: 70,
                format: "H:i",
                maxLength: 5,
                disabled: false,
                increment: 60
            }
        },
        {
            name: path + ".recurring.weekly",
            childrenNames: [
                path + ".recurring.weekly.dayOfWeekLabel",
                path + ".recurring.weekly.day-of-week",
                path + ".recurring.weekly.atLabel",
                path + ".recurring.weekly.at"
            ],
            uiHint: {
                border: false
            }
        },
        {
            name: path + ".recurring.weekly.dayOfWeekLabel",
            defaultValue: _T("on"),
            uiHint: {
                width: 25,
                style: 'padding: 3px 6px 3px 5px'
            }
        },
        {
            name: path + ".recurring.weekly.day-of-week",
            uiHint: {
                useHelpStringAsDisplay: true,
                width: 100
            }
        },
        {
            name: path + ".recurring.weekly.atLabel",
            defaultValue: _T("at"),
            uiHint: {
                width: 25,
                style: 'margin: 3px 6px 3px 8px'
            }
        },
        {
            name: path + ".recurring.weekly.at",
            uiHint: {
                width: 70,
                format: "H:i",
                maxLength: 5,
                disabled: false,
                increment: 60
            }
        }, {
            name: path + ".auth",
            uiHint: {
                builder: 'FieldSetBuilder',
                checkboxToggle: true,
                fieldLabel: _T('Client Authentication'),
                association: {
                    fields: [path + '.certificate-profile', path + '.url'],
                    availHide: function (values, formData, filters, key) {
                        let certificateProfile = getPathValue(formData, path + '.certificate-profile');
                        const source = getPathValue(formData, path + '.url');
                        const protocol = !Pan.isEmpty(source) && source ? source.split(':')[0] : '';
                        return !certificateProfile || certificateProfile === "None" || protocol !== 'https' ? true : false;
                    }
                }
            }
        },
        {
            name: path + '.recurring.daily.at',
            uiHint: {
                width: 50,
            },
        },
        {
            name: path + '.recurring.daily',
            childrenNames: [
                path + '.recurring.daily.atLabel',
                path + '.recurring.daily.at',
            ],
            uiHint: {
                //layout: 'hbox',
                border: false,
            },
        },
        {
            name: path + '.recurring.daily.atLabel',
            defaultValue: _T('at'),
            uiHint: {
                width: 30,
                style: 'margin: 3px 6px 3px 6px',
            },
        },
        {
            name: path + '.recurring.daily.at',
            uiHint: {
                width: 70,
                format: 'H:i',
                maxLength: 5,
                editable: false,
                increment: 60,
            },
        },
        {
            name: path + '.recurring.monthly',
            childrenNames: [
                path + '.recurring.monthly.dayOfMonthLabel',
                path + '.recurring.monthly.day-of-month',
                path + '.recurring.monthly.atLabel',
                path + '.recurring.monthly.at',
            ],
            uiHint: {
                border: false,
            },
        },
        {
            name: path + '.recurring.monthly.dayOfMonthLabel',
            defaultValue: _T('on'),
            uiHint: {
                width: 25,
            },
        },
        {
            name: path + '.recurring.monthly.day-of-month',
            uiHint: {
                width: 75,
            },
        },
        {
            name: path + '.recurring.monthly.atLabel',
            defaultValue: _T('at'),
            uiHint: {
                width: 25,
            },
        },
        {
            name: path + '.recurring.monthly.at',
            uiHint: {
                width: 70,
                format: 'H:i',
                maxLength: 5,
                editable: false,
                increment: 60,
            },
        },
        {
            name: path + '.recurring.weekly',
            childrenNames: [
                path + '.recurring.weekly.dayOfWeekLabel',
                path + '.recurring.weekly.day-of-week',
                path + '.recurring.weekly.atLabel',
                path + '.recurring.weekly.at',
            ],
            uiHint: {
                border: false,
            },
        },
        {
            name: path + '.recurring.weekly.dayOfWeekLabel',
            defaultValue: _T('on'),
            uiHint: {
                width: 25,
                style: 'padding: 3px 6px 3px 5px',
            },
        },
        {
            name: path + '.recurring.weekly.day-of-week',
            uiHint: {
                useHelpStringAsDisplay: true,
                width: 100,
            },
        },
        {
            name: path + '.recurring.weekly.atLabel',
            defaultValue: _T('at'),
            uiHint: {
                width: 25,
                style: 'margin: 3px 6px 3px 8px',
            },
        },
        {
            name: path + '.recurring.weekly.at',
            uiHint: {
                width: 70,
                format: 'H:i',
                maxLength: 5,
                editable: false,
                increment: 60,
            },
        },
        /*{
            name: path + '.auth',
            uiHint: {
                builder: 'FieldSetBuilder',
                checkboxToggle: true,
                fieldLabel: _T('Client Authentication'),
                clearUserPasswordValues: function() {
                    // remove username/password by uncheck fieldset Client Authentication
                    var auth = this.__pdefaults.__dataExtractor(
                        path + '.auth',
                        '',
                    );
                    if (auth) {
                        auth.setValue(false);
                    }
                },
                allowClientSideCertification: function() {
                    var path =
                        '$.type.' +
                        this.__pdefaults.__dataExtractor('$.type');
                    var urlValue = this.__pdefaults.__dataExtractor(
                        path + '.url',
                    );
                    var isHTTPS =
                        urlValue && urlValue.indexOf('https') >= 0;
                    var hasCertProfile =
                        this.__pdefaults.__dataExtractor(
                            path + '.certificate-profile',
                        ) !== 'None';
                    return isHTTPS && hasCertProfile;
                },
            },
        },*/
        {
            name: path + '.certificate-profile',
            looseMembership: true,
            uiHint: {
                builder: 'CompletionBuilder',
                completionConfig: {
                    actions: [
                        {
                            text: 'Add Certificate Profile',
                            viewerName: 'CertificateProfileRecordViewer',
                            rbaPath: 'device.certificate-management.certificate-profile',
                            viewerProps: {
                                windowSize: 'xl',
                                formSize: 'xl',
                                title: _T('Certificate Profile')
                            }
                        }
                    ]
                }
            }
        },
        ];
    };

    reloadIPList = function (config) {
        // var grid = config.__component.__pdefaults.__dataExtractor('Objects/EDL List Viewer', '');
        // if (grid) {
        //     if (grid.store.isLoading) {
        //         grid.store.isLoading = false;
        //         return;
        //     }
        //     grid.store.removeAll();
        //     var listType = config.__component.__pdefaults.__dataExtractor('$.type');
        //     var listNamePath = listType == 'predefined-ip'
        //         ? '$.type.predefined-ip.url'
        //         : 'name';
        //     var lastOptions = grid.store.lastOptions ? grid.store.lastOptions : { params: {} };
        //     Ext.apply(lastOptions, {
        //         params: {
        //             listName: config.__component.__pdefaults.__dataExtractor(listNamePath),
        //             listType: listType
        //         }
        //     });
        //     grid.store.reload(lastOptions);
        // }
    };

    getExternalDynamicListFields = () => {
        let baseFields = [
            {
                type: 'string',
                name: 'name',
                mapping: '@name',
                uiHint: {
                    fieldLabel: _T('Name'),
                    autoFocus: true
                },
            },
            {
                name: '$.type',
                uiHint: {
                    fieldLabel: _T('Type'),
                    useHelpStringAsDisplay: true,
                },
            },
            // {
            //     name: '$.type.predefined-ip.url',
            //     uiHint: {},
            // },
            // {
            //     name: 'List Entries',
            //     uiHint: {
            //         height: 275,
            //     },
            // },
            // {
            //     name: 'dummy',
            //     saveMap: undefined,
            //     uiHint: {
            //         iconCls: 'icon-run',
            //         style: 'padding-top: 150px',
            //         hideLabel: true,
            //         handler: function() {
            //             var selectedNodes = this.ownerCt.ownerCt
            //                 .findByItemId('Objects/EDL List Viewer')
            //                 .getSelectionModel()
            //                 .getSelections();
            //             var recArray = [],
            //                 i;
            //             var path =
            //                 '$.type.' +
            //                 this.__pdefaults.__dataExtractor('$.type') +
            //                 '.exception-list.member';
            //             var storeManualException = this.ownerCt.ownerCt.findByItemId(
            //                 path,
            //             ).store;
            //             for (i = 0; i < selectedNodes.length; i++) {
            //                 var rec = new storeManualException.recordType(),
            //                     record = selectedNodes[i];

            //                 if (
            //                     storeManualException.findExact(
            //                         path + '.*',
            //                         record.id,
            //                     ) == -1
            //                 ) {
            //                     rec.data[path + '.*'] = record.id;
            //                     recArray.push(rec);
            //                 }
            //             }
            //             storeManualException.add(recArray);
            //         },
            //     },
            // },
            {
                name: '$',
                childrenNames: [
                    'name',
                    'location',
                    '$.type',
                ],
            },
        ];
        baseFields = baseFields.concat(this.getRecurring('ip'));
        baseFields = baseFields.concat(this.getRecurring('domain'));
        baseFields = baseFields.concat(this.getRecurring('url'));
        baseFields = this.getType(baseFields, 'predefined-ip');
        baseFields = this.getType(baseFields, 'ip');
        baseFields = this.getType(baseFields, 'domain');
        baseFields = this.getType(baseFields, 'url');
        return baseFields;
    };

    serviceName = 'Objects/ExternalDynamicLists';
    reduxId = 'externalDynamicListObjects';
    header = 'External Dynamic Lists';
    columns = externalDynamicListColumns;
    fields = this.getExternalDynamicListFields();
    recordConfigPath = '$.config.devices.entry.device-group.entry.external-list.entry';
    rbaPath = 'objects.dynamic-block-lists';
    gridOverride = gridOverride;
}

