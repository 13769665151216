import CalendarLocale from 'rc-calendar/lib/locale/en_US';

const locale = {
    lang: {
        placeholder: 'Select date',
        rangePlaceholder: ['Start date', 'End date'],
        ...CalendarLocale,
    },
    timePickerLocale: {
        placeholder: 'Select time',
    },
};

export default locale;