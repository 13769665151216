import { Pan } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str;

const fields = unionBy([
    {
        name: '$.type.ssl-inbound-inspection',
        uiHint: {
            boxLabel: _T("SSL Inbound Inspection"),
            fieldLabel: _T('Certificate'),
            radioAvail: function () {
                return false;
            }
        }
    },
    {
        name: '$',
        childrenNames: [
            '$.general-box',
            '$.tabs-box'
        ]
    },
    {
        name: '$.tabs-box',
        childrenNames: [
            '$.source-with-user-box',
            '$.destination-box',
            '$.service-category-box',
            'option-box'
        ],
        uiHint: {
            builder: 'TabBuilder',
            deferredRender: false
        }
    },
    {
        name: 'option-box',
        childrenNames: [
            '$.action',
            '$.type',
            '$.profile',
            '$.log-settings'
        ],
        uiHint: {
            fieldLabel: _T('Options')
        }
    },
    {
        name: '$.action',
        uiHint: {
            fieldLabel: _T('Action'),
            largeSelectionCount: 1,
            enum: [["no-decrypt", "no-decrypt"], ["decrypt", "decrypt"]]
        }
    },
    {
        name: '$.type',
        uiHint: {
            fieldLabel: _T('Type'),
            largeSelectionCount: 1,
            useHelpStringAsDisplay: true
        }
    },
    {
        name: '$.type.ssh-proxy',
        uiHint: {
            radioAvail: function () {
                return false;
            }
        }
    },
    {
        name: '$.profile',
        uiHint: {
            fieldLabel: _T('Decryption Profile')
        }
    }
], commonFields, 'name');

const columns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    {
        headerName: 'Source',
        children: [
            Pan.apply({}, {}, columnConfig.from),
            Pan.apply({}, {}, columnConfig.source_negate),
            Pan.apply({}, {}, columnConfig.source_user)]
    },
    {
        headerName: 'Destination',
        children: [
            Pan.apply({}, {}, columnConfig.to),
            Pan.apply({}, {}, columnConfig.destination_negate)
        ]
    },
    Pan.apply({}, { hidden: false }, columnConfig.category),
    {
        headerName: 'Decrypt Options',
        children: [
            {
                headerName: 'Action',
                sortable: false,
                valueGetter: params => {
                    return params.data.action;
                }
            },
            {
                headerName: 'Type',
                sortable: false,
                valueGetter: params => {
                    if (params.data.type) {
                        for (let p in params.data.type) {
                            return p;
                        }
                    }
                }
            },
            {
                headerName: 'Decryption Profile',
                sortable: false,
                valueGetter: params => {
                    return params.data.profile || '';
                }
            }
        ]
    }
];

export default class DecryptionPolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/DecryptionPreRules';
    reduxId = 'decryptionPreRules';
    header = 'Decryption Rules';
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.decryption.rules.entry';
    rbaPath = 'policies.ssl-decryption-rulebase';
}