import React, { Component } from 'react';
import getGPCSRecordViewer from './GPCSRecordViewer';
import { GPCS_COMMON_TPL } from 'service-lib';
import { getStore, getPathValue, getStoreState, ViewerDisplayActionBuilder } from "ui-lib";
import {
	FORWARD_UNTRUST_CERT_NAME,
	markCertAsForwardUntrust,
	FORWARD_UNTRUST_CERT_NAME_ECDSA
} from "../main/actions";
import ExportCert from "./ExportCert";
import { get } from "lodash";

const _T = str => str;

window.__pan_schema.config.injected.forwardUntrust = {
	"@attr": { "node-type": "sequence" },
	choice: {
		"@attr": { "node-type": "choice" },
		default: {
			"@attr": {
				"node-type": "sequence",
				"help-string": "Default forward untrust certificate"
			}
		},
		custom: {
			"@attr": {
				"node-type": "sequence",
				"help-string": "Custom forward untrust certificate"
			}
		}
	},
	cert: {
		"@attr": {
			"node-type": "element",
			type: "string",
			optional: "yes"
		}
	}
};

export default class ForwardUntrustCert extends Component {
	constructor(props) {
		super(props);
		this.state = {
            cert: {},
			record: false,
			GPCSRecordViewer: null
		};
	}

	getFields() {
		const tpl = get(this.props, "configLocation.tpl.name", GPCS_COMMON_TPL);
		return [
			{
				name: "$",
				childrenNames: ["$.choice"]
			},
			{
				name: "$.cert",
				uiHint: {
					fieldLabel: _T("Forward Untrust Certificate")
				}
			},
			{
				name: "$.choice",
				uiHint: {
					fieldLabel: _T("Forward Untrust"),
					association: {
						fields: ["$.choice", "$.default", "$.custom"],
						updateFormDataAsync: function(values, formData, filters, key) {
							if (!values[2]) {
								return new Promise(function(resolve, reject) {
									let store = getStore();
									store.dispatch(
										markCertAsForwardUntrust(FORWARD_UNTRUST_CERT_NAME, {
											tpl: { name: GPCS_COMMON_TPL }
										})
									);
									store.dispatch(
										markCertAsForwardUntrust(FORWARD_UNTRUST_CERT_NAME_ECDSA, {
											tpl: { name: GPCS_COMMON_TPL }
										})
									);
									resolve({ custom: {} });
								});
							} else {
								return new Promise(function(resolve, reject) {
									resolve([]);
								});
							}
						}
					}
				}
			},
			{
				name: "$.default",
				childrenNames: ["ExportCert"]
			},
			{
				name: "$.custom",
				childrenNames: ["ExportImportCert"]
				// childrenNames: ["ExportCert", "ImportCert"]
			},
			{
				name: "ExportCert",
				uiHint: {
					builder: field => {
                        return () => {
							let certName = this.getCertName();
							return (
								<div>
									<div>
										<ExportCert
											modalTitleText={_T("Export Certificate")}
											modaleMessageText={_T(
												"Prisma Access re-signs the server certificate for untrusted sites using this CA. Do not distribute this certificate to your users endpoints."
											)}
											confirmationModalEnabled
											certificateNames={[this.state.cert["rsa"], this.state.cert["ecdsa"]]}
											certLocation="api/config/v9.0/Device/CertificateManagement/ExportCertificate"
										/>
									</div>
									<span className="btn indent-align-col-form-label">{certName}</span>
								</div>
							);
                        };
					}
				}
			},
			{
                name: "ExportImportCert",
                attrPath: "$.custom.ImportCert",
				uiHint: {
					builder: field => {
						return () => {
                            let certName = this.getCertName() || "Import Certificate";
							return (
                                <div>
                                    <div>
                                        <ExportCert
                                        confirmationModalEnabled
                                        modalTitleText={_T("Export Certificate")}
                                        modaleMessageText={_T(
                                            "Prisma Access re-signs the server certificate for untrusted sites using this CA. Do not distribute this certificate to your users endpoints."
                                        )}
                                        certificateNames={[this.state.cert["rsa"], this.state.cert["ecdsa"]]}
                                        certLocation="api/config/v9.0/Device/CertificateManagement/ExportCertificate"
                                    />
                                        <ViewerDisplayActionBuilder
                                            field={{
                                                uiHint: {
                                                    rbaPath: "device.certificate-management.certificates",
                                                    viewerName: "CertificateImport",
                                                    actionText: "Import",
                                                    actionProps: {
                                                        className: "indent-align-col-form-label"
                                                    },
                                                    showViewer: true,
                                                    viewerProps: {
                                                        windowSize: "lg",
                                                        title: "Import Certificate",
                                                        labelWidthSize: 3,
                                                        mode: "form",
                                                        configLocation: { tpl: { name: tpl } },
                                                        fetchAfterSuccess: false,
                                                        afterSubmit: record => {
                                                            if (record && record.name) {
                                                                //set this certificate are forward untrust
                                                                let store = getStore();
                                                                store.dispatch(
                                                                    markCertAsForwardUntrust(record.name, {
                                                                        tpl: { name: tpl }
                                                                    })
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                        /> 
                                    </div>
                                    <span className="btn indent-align-col-form-label">{certName}</span>
                                </div>
							);
						};
					}
				}

			}
		];
	}

	/**
	 * set state for SSL decryption based on cert type
	 * @param {object} cert - object from redux store
	 */
	setCertType = cert => {
        let store = getStore();
		let record = {};
		if (
			cert["rsa"] &&
			cert["rsa"] === FORWARD_UNTRUST_CERT_NAME &&
			cert["ecdsa"] &&
			cert["ecdsa"] === FORWARD_UNTRUST_CERT_NAME_ECDSA
		) {
            record = { default: {}, custom: {} };
		} else if (cert["rsa"] || cert["ecdsa"]) {
			//custom cert
            record= {
                custom: {
                    ImportCert: ""
                }
            };
		} else {
			//no forward untrust cert
			//set the default as forward untrust
			store.dispatch(
				markCertAsForwardUntrust(FORWARD_UNTRUST_CERT_NAME, {
					tpl: { name: GPCS_COMMON_TPL }
				})
			);
			store.dispatch(
				markCertAsForwardUntrust(FORWARD_UNTRUST_CERT_NAME_ECDSA, {
					tpl: { name: GPCS_COMMON_TPL }
				})
			);
            record= { default: {}, custom: {} };
        }
        this.setState({
			record
		})
    };
    

    getCertName = () => {
        let cert = getPathValue(
			getStoreState(),
			"gpcs.sslDecryptionDetails.forward-untrust-certificate"
		);
        let certName = "";
		if (
			cert["rsa"] &&
			cert["rsa"] === FORWARD_UNTRUST_CERT_NAME &&
			cert["ecdsa"] &&
			cert["ecdsa"] === FORWARD_UNTRUST_CERT_NAME_ECDSA
		) {
            certName = FORWARD_UNTRUST_CERT_NAME + " (RSA) / " + FORWARD_UNTRUST_CERT_NAME_ECDSA + " (ECDSA)";
		} else if (cert["rsa"] || cert["ecdsa"]) {
			if (cert["ecdsa"]) {
				certName = cert["ecdsa"] + " (ECDSA)";
			}
			if (cert["rsa"]) {
				if (certName === "") {
					certName = cert["rsa"] + " (RSA)";
				} else {
					certName = certName + " / " + cert["rsa"] + " (RSA)";
				}
			}
        } 
        return certName;
    }

	componentDidMount() {
		let cert = getPathValue(
			getStoreState(),
			"gpcs.sslDecryptionDetails.forward-untrust-certificate"
		);
		this.setState({
            GPCSRecordViewer: getGPCSRecordViewer("", "", "", "forwardUntrust"),
            cert: cert
		});
		this.setCertType(cert);
	}

	render() {
		let GPCSRecordViewer = this.state.GPCSRecordViewer;
		return (
			GPCSRecordViewer &&
			this.state.record && (
				<GPCSRecordViewer
					configLocation={this.props.configLocation}
					fetchRecord={false}
					rbaPermission="enable"
					locationPermission="enable"
					panSchema={window.__pan_schema}
					recordConfigPath="$.config.injected.forwardUntrust"
					inputFields={this.getFields()}
					hideOk={true}
					hideCancel={true}
					header=""
					record={this.state.record}
				/>
			)
		);
	}
}
