import React from 'react';
import { ajax } from "rxjs/ajax";
import _ from 'lodash';
import {
    Pan,
    VSYS_SHARED, 
    SERVER_ERROR,
    getPathValue,
    getStoreState, 
    showModal, 
    hideModal,
    getCustomServiceURL,
    getAuthHeaders,
    getStore,
    parseError,
    MOCK_SERVICES,
    isUserRolePemission,
} from 'ui-lib';
import { REMOTE_NETWORKS_TPL, GPCS_COMMON_TPL } from 'service-lib';
import GPCSWorkflowBaseViewer from '../main/GPCSWorkflowBaseViewer';
import { gpcsCommonFields, validateID, displayValue } from './GPCSCommonFields';
import gpcsCommonColumnConfig from './GPCSCommonColumns';
import unionBy from 'lodash/unionBy';
import {
    getRemoteNetworksDetails,
    generateMUDefaultConfigCerts,
    fetchGPCSStatus,
    getSSLDecryption,
    fetchServiceInfra
} from '../main/actions';

import './RemoteNetworks.scss';
import BGPStatus from '../main/BGPStatus';
import {
    PRISMA_ACCESS_SE_LICENSE,
    PRISMA_ACCESS_LAB_LICENSE
} from '../../constants';


const _T = str => str;

export const remoteNetworkFields = unionBy([{
    name: '$',
    childrenNames: [
        '$.stage_1',
        '$.stage_2',
        '$.stage_3',
        '$.stage_4',
        '$.stage_5',
        '$.stage_summary'
    ],
    uiHint: {
        labelWidthSize: 3
    }
}, {
    // must defined name field, it used for nameProprityId
    type: 'string',
    name: 'name',
    mapping: '@name',
    uiHint: {
        fieldLabel: _T('Branch Name'),
        disableOnEditRecord: true,
        autoFocus: true
    }
}, {
    name: '$.stage1.region',
    uiHint: {
        builder: 'CompletionBuilder',
        fieldLabel: _T('Prisma Access Location'),
        getDisplayValue: function (formData, fieldName) {
            let val = getPathValue(formData, '$.stage1.region') || '';
            if (getStoreState().gpcs.regionInfo) {
                let region = getStoreState().gpcs.regionInfo.find(r => r['@value'] === val);
                if (region) {
                    val = region['@display'];
                }
            }
            return val;
        },
        completionConfig: {
            CustomCompletion: () => {
                let {
                    SERVICE_URL
                } = getCustomServiceURL(
                    `api/extensions/gpcs/status/completeRegions`
                );

                let completinObservable = ajax({
                    withCredentials: MOCK_SERVICES ? false : true,
                    method: 'GET',
                    responseType: 'json',
                    url: SERVICE_URL,
                    headers: {
                        'Content-Type': 'application/json',
                        ...getAuthHeaders()
                    },
                });

                let respPromise = new Promise(function (resolve, reject) {
                    completinObservable.toPromise().then((resp) => {
                        let objs = getPathValue(resp, 'response.msg.completions');
                        if (Array.isArray(objs) && objs.length > 0) {
                            let completions = objs.map(obj => {
                                return { value: obj['@value'], label: obj['@display'] };
                            });
                            resolve(completions);
                        }
                        resolve([]);
                    }, (err) => {
                        getStore().dispatch({
                            type: SERVER_ERROR,
                            errorMessage: parseError(err),
                            showMessage: false
                        });
                        // reject(new Error('Error in Auto Completion: ' + err ));
                    });
                });
                return respPromise;
            }
        },
        showHelpString: () => {
            const state = getStore().getState();
            const rnLicenseType = _.get(state, 'main.licenseInfo.rn-license-type');

            if (rnLicenseType === PRISMA_ACCESS_SE_LICENSE || rnLicenseType === PRISMA_ACCESS_LAB_LICENSE) {
                return _T('Your current subscription allows you to provision a maximum of two locations.');
            }
            return '';
        }
    }
}, {
    name: '$.stage1.product',
    uiHint: {
        fieldLabel: _T('Branch IPSec Device'),
        useValueAsDisplay: true
    }
}, {
    name: '$.stage_summary',
    childrenNames: [
        '$.stage_1',
        '$.stage_2',
        '$.stage_3',
        '$.stage_4',
        '$.stage_5'
    ],
    uiHint: {
        builder: 'SummaryBuilder',
        stages: {
            '$.stage_1': 'stage1',
            '$.stage_2': 'stage2',
            '$.stage_3': 'stage3',
            '$.stage_4': 'stage4',
            '$.stage_5': 'stage5'
        },
        fieldLabel: _T('Stage 1 / Summary'),
        fieldDescription: _T('')
    }
}, {
    name: '$.stage_1',
    recordConfigPath: '$.stage1',
    childrenNames: [
        // 'get_started',
        '$.stage1_step1',
        '$.stage1_step2',
        '$.stage1_step3',
        '$.stage1_step4',
        '$.stage1_step5'
    ],
    uiHint: {
        jumpToSummaryFromStep: [3, 4, 5],
        stageTitle: 'Primary Tunnel',
        stageNumber: '1',
        stageCollapseTitle: 'Primary Tunnel',
        onNext: (currentStep, formData, formDataBackup, isNewRecord, _next) => {
            let region1 = formData && formData.stage1 ? formData.stage1.region : null;
            let region2 = formDataBackup && formDataBackup.stage1 ? formDataBackup.stage1.region : null;

            let bw1 = formData && formData.stage1 ? formData.stage1.bandwidth : null;
            let bw2 = formDataBackup && formDataBackup.stage1 ? formDataBackup.stage1.bandwidth : null;

            let peerAddress = formData && formData.stage1 ? formData.stage1['peer-address'] : null;
            let peerId = formData && formData.stage1 && formData.stage1['authentication'] && formData.stage1['authentication']['pre-shared-key'] ? formData.stage1['authentication']['pre-shared-key']['peer-id'] : null;

            if (currentStep === 0 && !isNewRecord) {
                let modal;
                if (region1 !== region2) {
                    modal = {
                        id:"remoteNetworksRegionChangeWarningModal",
                        title:_T('Region Change Warning'),
                        type:'Warning',
                        message:_T('A region change may require Prisma Access to redeploy the current setup and cause downtime at the remote network. With the new setup your service IP address will change. To resume connectivity, you might need to reconfigure the IPSec VPN tunnel between the corporate networks or the remote networks, and the cloud service.'),
                        toggle: () => {
                            hideModal("remoteNetworksRegionChangeWarningModal");
                        },
                        actions:[{
                            text:'Yes',
                            action:()=>{
                                _next && _next();
                                hideModal("remoteNetworksRegionChangeWarningModal");
                            }
                        }, {
                            text: 'No',
                            action: () => {
                                hideModal("remoteNetworksRegionChangeWarningModal");
                            }
                        }]
                    };
                } else if (bw1 !== bw2) {
                    modal = {
                        id:"remoteNetworksBandwidthChangeWarningModal",
                        title:_T('Bandwidth Change Warning'),
                        type:'Warning',
                        message:_T('A bandwidth change may require Prisma Access to redeploy the current setup and cause downtime at the remote network. With the new setup your service IP address will change. To resume connectivity, you might need to reconfigure the IPSec VPN tunnel between the corporate networks, or the remote networks, and the cloud service.'),
                        toggle: () => {
                            hideModal("remoteNetworksBandwidthChangeWarningModal");
                        },
                        actions:[{
                            text:'Yes',
                            action:()=>{
                                _next && _next();
                                hideModal("remoteNetworksBandwidthChangeWarningModal");
                            }
                        }, {
                            text: 'No',
                            action: () => {
                                hideModal("remoteNetworksBandwidthChangeWarningModal");
                            }
                        }]
                    };
                }
                if (modal) {
                    showModal(modal);
                } else {
                    _next && _next();
                }
            } else if(currentStep === 1 && peerAddress && peerAddress.dynamic) { //ADI-2009
                if( _.isEmpty(peerId) || _.isEmpty(peerId.type) || _.isEmpty(peerId.type)){
                    let modal = {
                        id:"requireBranchIkeIdModal",
                        title:_T('Validation Error'),
                        type:'Warning',
                        message:_T('Branch IKE ID is required when using Dynamic IP Address.'),
                        toggle: () => {
                            hideModal("requireBranchIkeIdModal");
                        },
                        actions:[{
                            text:'OK',
                            action:()=>{
                                hideModal("requireBranchIkeIdModal");
                            }
                        }]
                    };
                    showModal(modal);
                }
                else{
                    _next && _next();
                }
            } else {
                _next && _next();
            }
        }
    }
}, {
    name: '$.stage1_step1',
    childrenNames: [
        'name',
        '$.stage1.bandwidth',
        '$.stage1.region',
        '$.stage1.product'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: _T('Tunnel Information'),
        stepTitle: 'Tunnel Information',
        fieldDescription: _T('Name your branch and select the IPSec device you are using at your branch to connect to Prisma Access.  ')
    }
}, {
    name: '$.stage1_step2',
    childrenNames: [
        '$.stage1.version',
        '$.stage1.authentication'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: _T('IPSec Peer Authentication'),
        stepTitle: 'IPSec Peer Authentication',
        fieldDescription: _T('To set up a VPN tunnel between the branch and Prisma Access, specify a pre-shared key and IKE ID for each end of the tunnel.')
    }
}, {
    name: '$.stage1_step3',
    childrenNames: [
        '$.stage1.proxy-id',
        '$.stage1.tunnel-monitor-separator',
        '$.stage1.tunnel-monitor.destination-ip',
        '$.stage1.tunnel-monitor.proxy-id',
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: _T('Tunnel Settings'),
        stepTitle: 'Tunnel Settings',
        buttonsAvail: {
            next: false,
            back: true,
            save: isUserRolePemission
        },
        fieldDescription: _T('Specify an IP address on your branch network to use to monitor the tunnel. Also, for policy-based VPN devices only, specify the Proxy IDs that match your policies.')
    }
}, {
    name: '$.stage1.proxy-id',
    builder: 'ContainerBuilder',
    childrenNames: [
        '$.stage1.proxy-id.entry-separator',
        '$.stage1.proxy-id.entry',
    ],
    association: {
        fields: ['$.stage1.product'],
        availHide: function (values, formData, filters, key) {
            return (values && !(values[0] === 'Cisco-ASA' || values[0] === 'Other Devices' || values[0] === 'Palo Alto Networks'));
        }
    },

}, {
    name: '$.stage1.proxy-id.entry-separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('Proxy-IDs'),
        association: {
            fields: ['$.stage1.product'],
            availHide: function (values, formData, filters, key) {
                return (values && !(values[0] === 'Cisco-ASA' || values[0] === 'Other Devices' || values[0] === 'Palo Alto Networks'));
            }
        }
    }
}, {
    name: '$.stage1_step4',
    childrenNames: [
        '$.stage1.ike-crypto-profiles.name',
        '$.stage1.ike-crypto-profiles.choice'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        optional: true,
        fieldLabel: '',
        stepTitle: _T('IKE Crypto Settings') + ' (Optional)',
        buttonsAvail: {
            next: false,
            back: false,
            save: isUserRolePemission
        },
        fieldDescription: _T('Based on the IPSec device type you selected, Prisma Access provides a recommended set of ciphers and a key lifetime for the IKE Phase 1 key exchange process between your branch device and Prisma Access. You can use the recommended settings, or customize the settings as needed for your environment.')
    }
}, {
    name: '$.stage1_step5',
    childrenNames: [
        '$.stage1.ipsec-crypto-profiles.name',
        '$.stage1.ipsec-crypto-profiles.choice'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        optional: true,
        fieldLabel: '',
        buttonsAvail: {
            next: false,
            save: isUserRolePemission
        },
        stepTitle: _T('IPSec Crypto Settings') + ' (Optional)',
        fieldDescription: _T('Based on the IPSec device type you selected, Prisma Access provides a recommended set of IPSec protocol and key lifetime settings to use to secure data within the IPSec tunnel between your branch device and Prisma Access in IKE Phase 2 for the Security Association (SA). You can use the recommended settings, or customize the settings as needed for your environment.')
    }
}, {
    name: '$.stage_2',
    recordConfigPath: '$.stage2',
    childrenNames: [
        '$.stage2_step1',
        '$.stage2_step2',
        '$.stage2_step3',
        '$.stage2_step4'
    ],
    uiHint: {
        jumpToSummaryFromStep: [2, 3, 4],
        stageTitle: 'Secondary Tunnel',
        stageNumber: '2',
        stageCollapseTitle: 'Secondary Tunnel'
    }
}, {
    name: '$.stage3.routing.subnets.member',
    uiHint: {
        fieldLabel: _T('Branch IP Subnets'),
    }
}, {
    name: '$.stage3.routing.bgp.do-not-export-routes',
    uiHint: {
        fieldLabel: _T('Don\'t Export Routes'),
    }
}, {
    name: '$.stage3.qos-profile-separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('QoS')
    }
}, {
    name: '$.stage3.qos-profile',
    uiHint: {
        builder: 'CompletionBuilder',
        completionConfig: {
            actions: [
                {
                    text: 'Add QoS Profile',
                    rbaPath: 'network.network-profiles.qos-profile',
                    viewerName: 'QoSProfileRecordViewer',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('QoS Profile'),
                        configLocation: {
                            tpl: {
                                name: REMOTE_NETWORKS_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'QoSProfile',
            rbaPath: 'network.network-profiles.qos-profile',
            viewerProps: {
                displayCancel: true,
                title: 'QoS Profile',
                configLocation: {
                    tpl: {
                        name: REMOTE_NETWORKS_TPL
                    }
                }
            },
            actionText: 'Manage QoS Profiles',
            actionProps: {
                style: { margin: 8, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage_3',
    recordConfigPath: '$.stage3',
    childrenNames: [
        '$.stage3_step'
    ],
    uiHint: {
        jumpToSummaryFromStep: 1,
        stageTitle: 'Routing and QoS',
        stageNumber: '3',
        dataInStore: () => {
            return getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails');
        },
        stageCollapseStyle: false
    }
}, {
    name: '$.stage3_step',
    childrenNames: [
        '$.stage3.routing',
        '$.stage3.qos-profile-separator',
        '$.stage3.qos-profile'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        fieldLabel: 'Step 1',
        buttonsAvail: {
            next: false,
            save: isUserRolePemission
        },
        stepTitle: 'Routing and QoS',
        fieldDescription: _T('Enable routing—static routes and/or BGP—to the subnet or individual IP addresses at the branch site. Optionally, configure QoS to prioritize business-critical traffic or traffic that requires low latency.')
    }
}, {
    name: '$.stage_4',
    childrenNames: [
        '$.stage4_step'
    ],
    uiHint: {
        stageTitle: 'SSL Decryption',
        jumpToSummaryFromStep: 1,
        stageNumber: '4',
        dataInStore: () => {
            return getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails');
        },
        stageCollapseStyle: false
    }
}, {
    name: '$.stage4_step_ssl_separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('SSL Decryption Certificates')
    }
}, {
    name: '$.stage4_step_cert_separator',
    uiHint: {
        builder: 'HorizontalSeparatorBuilder',
        fieldLabel: _T('SSL Decryption Rules')
    }
}, {
    name: '$.stage4_step',
    childrenNames: [
        '$.stage4_step_ssl_separator',
        'SSLDecryption',
        '$.stage4_step_cert_separator',
        'forward-trust-cert',
        'forward-untrust-cert'
    ],
    uiHint: {
        builder: 'ContainerBuilder',
        showSummaryLabel: true,
        fieldLabel: 'SSL Decryption',
        fieldDescription: _T('Quickly and easily enable SSL Decryption using the default certificates generated for your Prisma Access instance, or import certificates from your enterprise PKI.'),
        buttonsAvail: {
            next: false,
            save: isUserRolePemission
        },
        stepTitle: _T('SSL Decryption')
    }
}, {
    name: 'SSLDecryption',
    uiHint: {
        builder: 'ViewerDisplayActionBuilder',
        viewerName: 'DecryptionPolicies',
        actionText: 'Update SSL Decryption',
        viewerProps: {
            windowSize: 'xl',
            displayCancel: true,
            editorMode: 'modal',
            displayMode: 'simple',
            title: 'Decryption Rules',
            modalBodyHelperText: 'Review the SSL decryption rules and take actions.'
        },
        actionProps: {
            className: 'indent-align-col-form-label'
        }
    }
}, {
    name: 'forward-trust-cert',
    uiHint: {
        showSummaryLabel: true,
        useStoreLabel: 'Forward Trust',
        getDisplayValue: (formData, fieldName) => {
            const cert = getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails.forward-trust-certificate');
            let certName = ''
            if (cert['ecdsa']) {
                certName = cert['ecdsa'] + ' (ECDSA)'
            }
            if (cert['rsa']) {
                if (certName === '') {
                    certName = cert['rsa'] + ' (RSA)'
                } else {
                    certName = certName + ' / ' + cert['rsa'] + ' (RSA)'
                }
            }
            return certName
        },
        builder: 'ViewerDisplayBuilder',
        viewerName: 'ForwardTrustCert',
        viewerProps: {
            configLocation: {
                tpl: {
                    name: GPCS_COMMON_TPL
                }
            }
        }
    }
}, {
    name: 'forward-untrust-cert',
    uiHint: {
        builder: 'ViewerDisplayBuilder',
        viewerName: 'ForwardUntrustCert',
        useStoreLabel: 'Forward Untrust',
        getDisplayValue: (formData, fieldName) => {
            const cert = getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails.forward-untrust-certificate');
            let certName = '';
            if (cert['ecdsa']) {
                certName = cert['ecdsa'] + ' (ECDSA)'
            }
            if (cert['rsa']) {
                if (certName === '') {
                    certName = cert['rsa'] + ' (RSA)'
                } else {
                    certName = certName + ' / ' + cert['rsa'] + ' (RSA)'
                }
            }
            return certName
        },
        viewerProps: {
            configLocation: {
                tpl: {
                    name: GPCS_COMMON_TPL
                }
            }
        }
    }
}, {
    name: '$.stage_5',
    recordConfigPath: '$.stage4',
    childrenNames: ['$.stage5_step1', '$.stage5_step2'],
    uiHint: {
        stageTitle: 'Access to Internal Resources',
        stageNumber: '5',
        stageCollapseTitle: 'Access to Internal Resources',
        dataInStore: () => true
    }
}, {
    name: '$.stage5_step1',
    childrenNames: ['infra_settings'],
    uiHint: {
        fieldLabel: 'Infrastructure Settings',
        stepTitle: 'Infrastructure Settings',
        builder: 'ContainerBuilder',
        fieldDescription: _T('To enable Prisma Access to route traffic between you mobile users, branch offices, and your HQ and data centers, provide an RFC-1918 compliant  infrastructure subnet (at least a /24 subnet) that does not overlap with other IP addresses in your network to use as the network backbone. Also, specify the DNS servers Prisma Access should use to resolve internal domains.')
    }
}, {
    name: '$.stage5_step2',
    childrenNames: [
        'service_connections'
    ],
    uiHint: {
        stepTitle: 'Service Connections',
        fieldLabel: 'Service Connections',
        buttonsAvail: {
            next: false,
            back: true,
            save: isUserRolePemission
        },
        fieldDescription: _T('Enable secure access to your headquarters and data centers for all your Prisma Access users, whether they are mobile or at your remote networks by setting up Service Connections.'),
        hideEditInSummary: true,
        jumpToRouteFromSummary: true,
        jumpToRoute: '/configure/serviceconnections'
    }
}, {
    name: 'service_connections',
    uiHint: {
        fieldLabel: '',
        getDisplayValue: function () {
            return 'Add or edit service connections'
        },
        builder: 'JumpToWithWarningBuilder',
        jumpTo: '/configure/serviceconnections',
        jumpToButtonText: 'Add or View Service Connections',
        iconImage: '/images/dashboard/service_connection.svg',
        iconFooter: 'To add or view service connections',
        iconFooterForDisabled: 'To add or view service connections, please save your changes to configuration',
    }
}, {
    name: 'infra_settings',
    uiHint: {
        labelWidthSize: 3,
        displayLabel: '',
        builder: 'ViewerDisplayBuilder',
        viewerName: 'ServiceInfrastructure',
        viewerProps: {
            editorMode: 'modal',
            displayMode: 'simple',
            isViewerDisplay: true,
            hasGridFilter: false,
            windowSize: 'lg',
            configLocation: {
                extension: {
                    gpcs: {
                        name: "gpcs"
                    }
                }
            },
        },
        getDisplayValue: function () {
            let serviceInfra = getPathValue(getStoreState(), 'gpcs.serviceInfrastructure.result.entry.*');
            let dnsList = [];
            if (serviceInfra && serviceInfra['internal-dns-list'] && serviceInfra['internal-dns-list']['entry']) {
                dnsList = serviceInfra['internal-dns-list']['entry'].map(dns => dns['@name']);
            }
            const flat = [
                { 'Service Subnet': serviceInfra && serviceInfra['service-subnet'] ? serviceInfra['service-subnet'] : '' },
                { 'BGP AS': serviceInfra && serviceInfra['infra-bgp-as'] ? serviceInfra['infra-bgp-as'] : '' },
                { 'Internal DNS List': dnsList }
            ];
            return { flat, 'fieldsData': [] };
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key',
    childrenNames: [
        '$.stage1.authentication.pre-shared-key.key',
        '$.stage1.peer-address.choice',
        '$.stage1.authentication.pre-shared-key.local-id',
        '$.stage1.authentication.pre-shared-key.peer-id'
    ],

    uiHint: {
        fieldLabel: _T('Pre-Shared Key')
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.key',
    uiHint: {
        fieldLabel: _T('Pre-Shared Key'),
        hideLabelInSummary: true,
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.peer-id.type',
    uiHint: {
        fieldLabel: _T('Identification Type'),
        hideLabelInSummary: true,
        getDisplayValue: function (formData) {
            let val = getPathValue(formData, '$.stage1.authentication.pre-shared-key.peer-id.type');
            return val ? displayValue(val) : '';
        },
        association: {
            // fields: ['$.stage1.authentication.pre-shared-key.peer-id.type'],
            runValidation: function (values, formData, field, key) {
                const idVal = getPathValue(formData, '$.stage1.authentication.pre-shared-key.peer-id.id');
                return { '$.stage1.authentication.pre-shared-key.peer-id.id': idVal };
            }
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.peer-id',
    uiHint: {
        fieldLabel: _T('Branch IKE ID'),
        customValidation: function(value, field, formData) {
            return validateID(value, field, formData, '$.stage1.authentication.pre-shared-key.peer-id.type');
        },
        association: {
            fields: ['$.stage1.peer-address.choice'],
            updateFormData: function (values, formData, filters, key) {
                const dynamic = getPathValue(formData, 'stage1.peer-address.dynamic');
                    if (!getPathValue(formData, 'stage1.authentication.pre-shared-key.peer-id.type')) {
                        return { '$.stage1.authentication.pre-shared-key.peer-id': dynamic ? {'type': ''}  : false};
                    }
                    return {};
            },
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.peer-id.id',
    uiHint: {
        fieldLabel: _T('Identifier'),
        displayLabel: _T('Branch IKE ID'),
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage1.authentication.pre-shared-key.peer-id.type');
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.local-id.type',
    uiHint: {
        fieldLabel: _T('Identification Type'),
        hideLabelInSummary: true,
        association: {
            // fields: ['$.stage1.authentication.pre-shared-key.local-id.type'],
            runValidation: function (values, formData, field, key) {
                const idVal = getPathValue(formData, '$.stage1.authentication.pre-shared-key.local-id.id');
                return { '$.stage1.authentication.pre-shared-key.local-id.id': idVal };
            }
        }
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.local-id',
    uiHint: {
        fieldLabel: _T('Prisma Access IKE ID')
    }
}, {
    name: '$.stage1.authentication.pre-shared-key.local-id.id',
    uiHint: {
        fieldLabel: _T('Identifier'),
        displayLabel: 'Prisma Access IKE ID',
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage1.authentication.pre-shared-key.local-id.type');
        }
    }
}, {
    name: '$.stage1.authentication.certificate.local-id',
    uiHint: {
        fieldLabel: _T('Prisma Access IKE ID')
    }
}, {
    name: '$.stage2.authentication.pre-shared-key',
    childrenNames: ['$.stage2.authentication.pre-shared-key.key',
        '$.stage2.peer-address.choice',
        '$.stage2.authentication.pre-shared-key.local-id',
        '$.stage2.authentication.pre-shared-key.peer-id'],

    uiHint: {
        fieldLabel: _T('Pre-Shared Key')
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.key',
    uiHint: {
        fieldLabel: _T('Pre-Shared Key'),
        hideLabelInSummary: true,
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.peer-id.type',
    uiHint: {
        fieldLabel: _T('Identification Type'),
        hideLabelInSummary: true,
    }
}, {
    name: '$.stage2.authentication.certificate.peer-id',
    uiHint: {
        fieldLabel: _T('Branch IKE ID')
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.peer-id.id',
    uiHint: {
        fieldLabel: _T('Identifier'),
        displayLabel: 'Branch IKE ID',
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage2.authentication.pre-shared-key.peer-id.type');
        }
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.local-id.type',
    uiHint: {
        hideLabelInSummary: true,
        fieldLabel: _T('Identification Type')
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.local-id.id',
    uiHint: {
        displayLabel: 'Prisma Access IKE ID',
        fieldLabel: _T('Identifier'),
        customValidation: function (value, field, formData) {
            return validateID(value, field, formData, '$.stage2.authentication.pre-shared-key.local-id.type');
        }
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.peer-id',
    uiHint: {
        fieldLabel: _T('Branch IKE ID'),
        association: {
            fields: ['$.stage2.peer-address.choice'],
            updateFormData: function (values, formData, filters, key) {
                const dynamic = getPathValue(formData, 'stage2.peer-address.dynamic');
                if (!getPathValue(formData, 'stage2.authentication.pre-shared-key.peer-id.type')) {
                    return { '$.stage2.authentication.pre-shared-key.peer-id': dynamic ? {'type': ''}  : false};
                }
                return {};
            },
        }
    }
}, {
    name: '$.stage2.authentication.pre-shared-key.local-id',
    uiHint: {
        fieldLabel: _T('Prisma Access IKE ID')
    }
}, {
    name: '$.stage1.authentication.certificate.local-certificate.name',
    uiHint: {
        fieldLabel: _T('Local Certificate'),
        completionConfig: {
            actions: [
                {
                    text: 'Import',
                    viewerName: 'CertificateImport',
                    rbaPath: 'device.certificate-management.certificates',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('Import Certificate'),
                        configLocation: {
                            tpl: {
                                name: REMOTE_NETWORKS_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'LocalCertificate',
            viewerProps: {
                displayCancel: true,
                title: 'Local Certificate',
                configLocation: {
                    tpl: {
                        name: REMOTE_NETWORKS_TPL,
                        vsys: VSYS_SHARED
                    }
                }
            },
            actionText: 'Manage Local Certificate',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage1.authentication.certificate.certificate-profile',
    uiHint: {
        builder: 'CompletionBuilder',
        completionConfig: {
            actions: [
                {
                    text: 'Add Certificate Profile',
                    rbaPath: 'device.certificate-management.certificate-profile',
                    viewerName: 'CertificateProfileRecordViewer',
                    viewerProps: {
                        windowSize: 'xl',
                        title: _T('Certificate Profile'),
                        configLocation: {
                            tpl: {
                                name: REMOTE_NETWORKS_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'CertificateProfile',
            viewerProps: {
                displayCancel: true,
                title: 'Certificate Profile',
                configLocation: {
                    tpl: {
                        name: REMOTE_NETWORKS_TPL
                    }
                }
            },
            actionText: 'Manage Certificate Profiles',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage2.authentication.certificate.local-certificate.name',
    uiHint: {
        fieldLabel: _T('Local Certificate'),
        completionConfig: {
            actions: [
                {
                    text: 'Import',
                    viewerName: 'CertificateImport',
                    rbaPath: 'device.certificate-management.certificates',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('Import Certificate'),
                        configLocation: {
                            tpl: {
                                name: REMOTE_NETWORKS_TPL
                            }
                        }
                    }
                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'LocalCertificate',
            viewerProps: {
                displayCancel: true,
                title: 'Local Certificate',
                configLocation: {
                    tpl: {
                        name: REMOTE_NETWORKS_TPL
                    }
                }
            },
            actionText: 'Manage Local Certificate',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}, {
    name: '$.stage2.authentication.certificate.certificate-profile',
    uiHint: {
        builder: 'CompletionBuilder',
        completionConfig: {
            actions: [
                {
                    text: 'Add Certificate Profile',
                    rbaPath: 'device.certificate-management.certificate-profile',
                    viewerName: 'CertificateProfileRecordViewer',
                    viewerProps: {
                        windowSize: 'xl',
                        title: _T('Certificate Profile'),
                        configLocation: {
                            tpl: {
                                name: REMOTE_NETWORKS_TPL
                            }
                        }
                    }

                }
            ]
        },
        manageViewer: {
            showViewer: true,
            viewerName: 'CertificateProfile',
            viewerProps: {
                displayCancel: true,
                title: 'Certificate Profile',
                configLocation: {
                    tpl: {
                        name: REMOTE_NETWORKS_TPL
                    }
                }
            },
            actionText: 'Manage Certificate Profiles',
            actionProps: {
                style: { margin: 10, marginLeft: 0 }
            }
        }
    }
}], gpcsCommonFields);

export const remoteNetworkColumns = [
    {
        headerName: _T('Branch'),
        children: [
            Pan.apply({}, {}, gpcsCommonColumnConfig.name),
            Pan.apply({}, {}, gpcsCommonColumnConfig.bandwith),
            {
                headerName: _T("Subnets"),
                field: 'stage3.routing.subnets',
                minWidth: 150
            },
            {
                headerName: _T("EBGP Router & AS"),
                field: 'ebgp_router_as',
                cellRendererParams: {
                    renderCell: params => {
                        let value = '';
                        if (getStoreState().gpcs.remoteNetworkDetails && getStoreState().gpcs.remoteNetworkDetails !== 'error') {
                            const val = findColumnData(params.data, getStoreState().gpcs.remoteNetworkDetails, '@name', 'Branch AS and Router');
                            value = Pan.isArray(val) && val.length > 0 && val[0]['AS'] ? val[0]['AS'] : [];
                        }
                        return (
                            <React.Fragment>
                                <div>{value}</div>
                                {value &&
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a onClick={() => {
                                        let modal = {
                                            id: "BGPStatus",
                                            title: "BGP Status",
                                            size: "xl",
                                            type: "Info",
                                            toggle: () => {
                                                hideModal("BGPStatus");
                                            },
                                            modalBody: {
                                                component: BGPStatus,
                                                props: {
                                                    site: getPathValue(params, 'data.@name')
                                                }
                                            },
                                            actions: [
                                                {
                                                    text: "Close",
                                                    color: "secondary",
                                                    action: () => {
                                                        hideModal("BGPStatus");
                                                    }
                                                }
                                            ]
                                        };
                                        showModal(modal);
                                    }}>
                                        Show BGP Status
                                </a>
                                }
                            </React.Fragment>
                        )
                    }
                },
                minWidth: 200
            }
        ]
    }, {
        headerName: _T('Prisma Access'),
        children: [
            Pan.apply({}, {}, gpcsCommonColumnConfig.region),
            {
                headerName: _T('Service IP'),
                field: 'service_ip',
                cellRendererParams: {
                    renderCell: params => {
                        let val = '';
                        if (getStoreState().gpcs.remoteNetworkDetails && getStoreState().gpcs.remoteNetworkDetails !== 'error') {
                            val = findColumnData(params.data, getStoreState().gpcs.remoteNetworkDetails, '@name', 'Service IP Address');
                        }
                        return <div>{val}</div>
                    }
                },
                minWidth: 125
            },
            {
                headerName: _T('EBGP Router'),
                field: 'ebgp_router',
                cellRendererParams: {
                    renderCell: params => {
                        let val = '';
                        if (getStoreState().gpcs.remoteNetworkDetails && getStoreState().gpcs.remoteNetworkDetails !== 'error') {
                            val = findColumnData(params.data, getStoreState().gpcs.remoteNetworkDetails, '@name', 'EBGP Router');
                        }
                        return <div>{val}</div>
                    }
                },
                minWidth: 150
            }]
    },
    {
        headerName: _T('Status'),
        children: [{
            headerName: _T('Primary Tunnel'),
            valueGetter: params => {
                const val = getPathValue(params.data, 'stage1.product')
                return (val) ? _T('Configured') : ''
            },
            minWidth: 150
        },
        {
            headerName: _T('Secondary Tunnel'),
            valueGetter: params => {
                const val = getPathValue(params.data, 'stage2') || {};
                return Pan.isEmpty(val) ? '' : _T('Configured')
            },
            minWidth: 150
        },
        {
            headerName: _T('Config Status'),
            field: 'config_status',
            cellRendererParams: {
                renderCell: params => {
                    let val = '';
                    if (getStoreState().gpcs.gpcs_status && getStoreState().gpcs.gpcs_status !== 'error') {
                        const gpcs_status = getStoreState().gpcs.gpcs_status;
                        const matched = gpcs_status ? gpcs_status.find(entry => entry['Remote Peer'] === params.data['@name']) : undefined;
                        val = matched && matched['Config Status'] ? matched['Config Status'].label : '';
                    }
                    return <div>{val}</div>
                }
            },
            // valueGetter: params => {
            //     if (getStoreState().gpcs.gpcs_status && getStoreState().gpcs.gpcs_status !== 'error') {
            //         const gpcs_status = getStoreState().gpcs.gpcs_status;
            //         const matched = gpcs_status ? gpcs_status.find(entry => entry['Remote Peer'] === params.data['@name']) : undefined;
            //         return matched && matched['Config Status'] ? matched['Config Status'].label : '';
            //     }
            //     return '';
            // },
            minWidth: 150
        }]
    }
];

const findColumnData = (columnData, responseData, matchProperty, valueProperty) => {
    const matched = responseData ? responseData.find(entry => entry.name === columnData[matchProperty]) : undefined;
    return matched ? matched[valueProperty] : '';
};

export default class RemoteNetworks extends GPCSWorkflowBaseViewer {
    serviceName = 'workflow/remoteNetworks';
    reduxId = 'remoteNetworks';
    header = 'Remote Networks';
    columns = remoteNetworkColumns;
    fields = remoteNetworkFields;
    suppressColsSizeToFit = false;
    rbaPermission=isUserRolePemission() ? 'enable':'disable';
    recordConfigPath = '$.config.devices.entry.plugins.cloud_services.adelphi_gpcs_workflow.remote-networks.onboarding.entry';
    initActions = [getRemoteNetworksDetails, getSSLDecryption, generateMUDefaultConfigCerts, fetchGPCSStatus, fetchServiceInfra];
    refreshColumns = ['ebgp_router_as', 'service_ip', 'ebgp_router', 'config_status']
}
