import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { SchemaForm } from '../SchemaForm';
import { SpinnerWidget } from '../SpinnerWidget';
import _ from 'lodash';
import PanSchema from '../../schema/PanSchema';
import { hideModal } from '../../../services/actions';

class RecordViewer extends Component {
    constructor(props) {
        super(props);
        let panSchema = new PanSchema(props.panSchema, props.recordConfigPath, props.inputFields);
        this.state = {
            record: props.record || {},
            formDataBackup: props.record || {},
            formData: props.record || {},
            panSchema: panSchema,
            filterText: '',
            errors: {},
            isNewRecord: _.isEmpty(props.record)
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            record: nextProps.record || prevState.record,
            formDataBackup: nextProps.record || prevState.formDataBackup,
            formData: nextProps.record || prevState.formData,
            isNewRecord: _.isEmpty(nextProps.record)
        };
    }

    onAddRecord(record, configPath) {
        if (this.props.onAddRecord) {
            this.props.onAddRecord(record, configPath);
        }
    }

    onEditRecord(record, configPath) {
        if (this.props.onEditRecord) {
            this.props.onEditRecord(record, this.state.record, configPath);
        }
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        hideModal(this.props.modalId);
    }


    renderView() {
        console.log("record ==== ", this.state.record);

        let { widerForm } = this.props;
        const wideFormClassName = widerForm ? 'hlgrid-form hlgrid-form-wide' : '';
        let header = this.props.displayMode != 'modal' ? this.props.header : '';
        return (
            <Card className="m-0">
                {header && <CardHeader className="hlgrid-header bg-light border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="p-2 mr-auto hlgrid-header-title">
                            {header}
                        </div>
                    </div>
                </CardHeader>}
                <CardBody className="p-0">
                    {this.props.formLoading && <SpinnerWidget />} 
                    <SchemaForm
                        id={this.props.recordConfigPath}
                        itemId={this.props.itemId || '$'}
                        className={wideFormClassName}
                        schema={this.state.panSchema.schema}
                        fields={this.state.panSchema.fields}
                        recordConfigPath={this.props.recordConfigPath}
                        record={this.state.record}
                        formData={this.state.formData}
                        formDataBackup={this.state.formDataBackup}
                        errors={this.state.errors}
                        formatFormData={true}
                        isNewRecord={this.state.isNewRecord}
                        formatFormDataPostProcess={true}
                        configLocation={this.props.configLocation}
                        rbaPermission={this.props.rbaPermission}
                        locationPermission={this.props.locationPermission}
                        hideOk={this.props.hideOk}
                        hideCancel={this.props.hideCancel}
                        getSubmitHandler={this.props.getSubmitHandler}
                        modalId={this.props.modalId}
                        modalFormSize={this.props.modalFormSize}
                        displayMode={this.props.displayMode || 'default'}
                        isDynamicLoadData={this.props.isDynamicLoadData}
                        onSubmit={({ formData, formDataBackup }) => {
                            // invoke updateRecord function
                            if (_.isEmpty(this.state.record) || !this.state.record) {
                                //Add record
                                console.log('Add Record', formData);
                                this.onAddRecord(formData, this.props.recordConfigPath);
                            } else {
                                // Edit record
                                console.log('Edit Record', formData);
                                this.onEditRecord(formData, this.props.recordConfigPath);
                            }
                            // this.hideRecordEditor();
                        }}
                        onBlur={(id, value) =>
                            console.log(`Touched ${id} with value ${value}`)
                        }
                        onFocus={(id, value) =>
                            console.log(`Focused ${id} with value ${value}`)
                        }
                        onError={() => console.log("errors")}
                        onCancel={() => this.onCancel()}
                    />
                </CardBody>
            </Card>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        )
    }
}

export { RecordViewer };