import React from "react";
import PropTypes from "prop-types";
import {BaseInput} from "../BaseInput";
const RangeInput = BaseInput;
import './RangeWidget.scss';
// min={min} max={max}
export function RangeWidget(props) {
    const { value } = props;
    return (
        <div className="field-range-wrapper">
            <RangeInput type="range" {...props} />
            <span className="range-view">{value}</span>
        </div>
    );
}

RangeWidget.defaultProps = {
    required: false,
    disabled: false,
    readonly: false,
    autoFocus: false,
    min:0,
    max:100
};

if (process.env.NODE_ENV !== 'production') {
    RangeWidget.propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        type: PropTypes.string,
        error: PropTypes.string,
        className: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        options: PropTypes.shape({
            inputType: PropTypes.string,
            emptyValue: PropTypes.string,
        }),
    };
}