import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { SchemaForm } from '../SchemaForm';
import { FormContext } from '../../FormContext';
import { GridWidget } from '../GridWidget';
import _ from 'lodash';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { insertArrayItemInPath, setPathValue } from '../../../utils/json';
import Pan from '../../schema/Pan';
import { isClickable } from '../../../utils/CommonColumnConfig';
import classnames from 'classnames';

import './RecordFormEditorGrid.scss';
import {
    getGridRecordFilterFinder,
    getGridRecordFilterReplace,
    INJECTED_REC_ID,
    generateGridRecId,
} from '../util';

export class RecordFormEditorGrid extends Component {
    constructor(props) {
        super(props);
        this.displayRecordEditor = this.displayRecordEditor.bind(this);
        this.hideRecordEditor = this.hideRecordEditor.bind(this);
        let modifiedColumns = this.injectEditRecordCallback(props.columns);
        this.onFilter = this.onFilter.bind(this);
        this.state = {
            data: [],
            pages: null,
            loading: true,
            formEditorDisplay: false,
            addRecord: false,
            filterText: '',
            modifiedColumns: modifiedColumns,
        };
    }

    displayRecordEditor(original) {
        let { formData, formDataBackup } = this.context;
        let recordConfigPath = this.props.recordConfigPath
            ? this.props.recordConfigPath
            : '';
        let filterItem = {
            find: getGridRecordFilterFinder(recordConfigPath),
            replace: getGridRecordFilterReplace(original, recordConfigPath),
        };
        this.setState({
            formEditorDisplay: true,
            addRecord: false,
            formDataBackup: formDataBackup,
            formData: formData,
            filters: this.props.filters.concat(filterItem),
        });
    }

    displayAddRecord() {
        let addIndex = this.props.gridData ? this.props.gridData.length : 0;
        let { formData, formDataBackup } = this.context;
        let recordConfigPath = this.props.recordConfigPath ? this.props.recordConfigPath : '';
        let addRecord = { [INJECTED_REC_ID]: generateGridRecId() };
        if (this.props.field.uiHint.autoGenField) {
            addRecord[this.props.field.uiHint.autoGenField] = `auto_${generateGridRecId()}`;
        }
        let filterItem = {
            find: getGridRecordFilterFinder(recordConfigPath),
            replace: getGridRecordFilterReplace(addRecord, recordConfigPath),
        };

        this.setState({
            formEditorDisplay: true,
            addRecord: true,
            addIndex: addIndex,
            formData: insertArrayItemInPath(Pan.clone(formData), recordConfigPath, addIndex, addRecord, this.props.filters),
            formDataBackup: formDataBackup,
            filters: this.props.filters.concat(filterItem),
        });
    }

    hideRecordEditor() {
        this.setState({
            formEditorDisplay: false,
            formDataBackup: {},
            formData: {},
            filters: this.props.filters,
            addRecord: false,
            addIndex: null,
        });
    }

    injectEditRecordCallback(columns) {
        if (columns) {
            return columns.map(column => {
                if (isClickable(column) && this.displayAddRecord) {
                    column.onClick = this.displayRecordEditor;
                }
                if (column.children) {
                    column.children = this.injectEditRecordCallback(column.children);
                }
                return column;
            });
        }
    }

    isDeleteDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        return !(selectedKeys.length > 0);
    }

    getIdColumn = () => {
		return this.props.idColumn || "@name";
    }
    
    deleteRecord(selected) {
        let { gridData } = this.props;
        let updateGridData = [];
        gridData.forEach(rec => {
            if (rec && !selected[rec[INJECTED_REC_ID]] && !selected[rec[this.getIdColumn()]]) {
                updateGridData.push(rec);
            }
        });
        let recordConfigPath = this.props.recordConfigPath
            ? this.props.recordConfigPath
            : '';
        let formData = Pan.clone(this.context.formData);
        setPathValue(formData, recordConfigPath, updateGridData, this.props.filters);
        this.props.onChange({ formData });
    }

    isMoveDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        return !(selectedKeys.length === 1);
    }

    moveUpRecord(selected) {
        if (!selected) {
            return;
        }
        let { gridData } = this.props;
        let fromIndex = gridData.indexOf(_.values(selected)[0]);
        let toIndex = fromIndex >= 1 ? fromIndex - 1 : 0;
        let updateGridData = [...gridData];
        updateGridData.splice(
            toIndex,
            0,
            updateGridData.splice(fromIndex, 1)[0],
        );
        let recordConfigPath = this.props.recordConfigPath
            ? this.props.recordConfigPath
            : '';
        let formData = Pan.clone(this.context.formData);
        setPathValue(formData, recordConfigPath, updateGridData, this.props.filters);
        this.props.onChange({ formData });
    }

    moveDownRecord(selected) {
        if (!selected) {
            return;
        }
        let { gridData } = this.props;
        let fromIndex = gridData.indexOf(_.values(selected)[0]);
        let toIndex = fromIndex < gridData.length ? fromIndex + 1 : fromIndex;
        let updateGridData = [...gridData];
        updateGridData.splice(
            toIndex,
            0,
            updateGridData.splice(fromIndex, 1)[0],
        );
        let recordConfigPath = this.props.recordConfigPath
            ? this.props.recordConfigPath
            : '';
        let formData = Pan.clone(this.context.formData);
        setPathValue(formData, recordConfigPath, updateGridData, this.props.filters);
        this.props.onChange({ formData });
    }

    onChange({ formData }) {
        const { onChange } = this.props;
        onChange({ formData });
    }

    onFilter(filterText) {
        this.setState({
            filterText: filterText,
        });
    }

    isAddDisabled(selected, records) {
        if (this.disableDefaultAddAction) {
            return this.disableDefaultAddAction;
        }
        if (this.maxCount && records && records.length >= this.maxCount) {
            return true;
        }
        return false;
    }

    /***
     * GridWidget contraints
     * 1. panSchema - pan schema
     * 2. recordConfigPath - schema path for the root of the record form editor
     * 3. inputFields - input fields list to display inthe form
     * 4. updateRecord - function call back on form submit
     */

    render() {
        const { modifiedColumns } = this.state;
        const {
            gridActions = [],
            disableDefaultAddAction = false,
        } = this.props;
        let gridData = typeof this.props.gridData === 'string' ? [] : this.props.gridData;
        let showFilter = !!this.props.showFilter && gridData.length > this.props.pageSize;
        if (this.props.showDefaultAddAction !== false) {
            gridActions.push({
                text: 'Add',
                color: 'primary',
                disableDefaultAddAction: disableDefaultAddAction,
                maxCount: parseInt(_.get(this, "props.field.maxCount", 0)),
                avail: this.isAddDisabled,
                callback: () => {
                    this.displayAddRecord();
                },
            });
        }

        if (this.props.showDefaultDeleteAction !== false) {
            gridActions.push({
                text: 'Delete',
                avail: this.isDeleteDisabled,
                callback: selected => {
                    this.deleteRecord(selected);
                },
            });
        }

        if (this.props.isOrdered === true) {
            gridActions.push({
                text: 'Move Up',
                avail: this.isMoveDisabled,
                callback: (selected) => {
                    this.moveUpRecord(selected);
                },
            });
            gridActions.push({
                text: 'Move Down',
                avail: this.isMoveDisabled,
                callback: (selected) => {
                    this.moveDownRecord(selected);
                },
            });
        }

        let { recordConfigPath, configLocation, rbaPermission, locationPermission } = this.context;
        let { errors, showErrors, widerForm } = this.props;
        const wideFormClassName = widerForm ? 'hlgrid-form-wide' : '';

        return (
            <React.Fragment>
                <div style={{ 'flexDirection': 'column' }}>
                    <GridWidget
                        style={{ maxHeight: 285, ...this.props.style }}
                        field={this.props.field}
                        gridData={gridData}
                        className="editor-grid"
                        columns={modifiedColumns}
                        minRows={this.props.minRows}
                        suppressColsSizeToFit={this.props.suppressColsSizeToFit}
                        pageSize={this.props.pageSize}
                        gridActions={gridActions}
                        checkboxSelection={this.props.checkboxSelection}
                        toolbarPosition={'top'}
                        showPaging={this.props.showPaging}
                        showFilter={showFilter}
                        onFilter={this.onFilter}
                        errors={errors}
                        showErrors={showErrors}
                        noDataText={this.props.noDataText}
                        allowDrag={this.props.allowDrag}
                        allowDrop={this.props.allowDrop}
                        onMoveDrag={this.props.onMoveDrag}
                        agContext={{ fields: this.context.fields }}
                    />
                </div>
                <Modal
                    isOpen={this.state.formEditorDisplay}
                    size={this.props.dialogSize}
                    centered
                    toggle={() => this.hideRecordEditor()}
                >
                    <ModalHeader>
                        {this.props.title || this.props.text}
                    </ModalHeader>
                    <ModalBody className="px-1">
                        <Col sm="12">
                            <SchemaForm
                                id={this.props.recordConfigPath}
                                itemId={this.props.itemId || this.props.recordConfigPath + '.*' || '$'}
                                className={classnames("hlgrid-form", wideFormClassName)}
                                schema={this.props.panSchema}
                                fields={this.props.inputFields}
                                recordConfigPath={recordConfigPath}
                                filters={this.state.filters}
                                configLocation={configLocation}
                                formData={this.state.formData}
                                formatFormData={true}
                                formatFormDataPostProcess={false}
                                isNewRecord={this.state.addRecord}
                                rbaPermission={rbaPermission}
                                locationPermission={locationPermission}
                                errors={{}}
                                formDataBackup={this.state.formDataBackup}
                                onSubmit={({ formData }) => {
                                    //invoke updateRecord function
                                    if (this.state.addRecord) {
                                        //Add record
                                        this.onChange({ formData });
                                    } else {
                                        //Edit record
                                        this.onChange({ formData });
                                    }
                                    this.hideRecordEditor();
                                }}
                                onBlur={(id, value) =>
                                    console.log(`Touched ${id} with value ${value}`)
                                }
                                onFocus={(id, value) =>
                                    console.log(`Focused ${id} with value ${value}`)
                                }
                                onBlur={(id, value) =>
                                    console.log(`Touched ${id} with value ${value}`)
                                }
                                onFocus={(id, value) =>
                                    console.log(`Focused ${id} with value ${value}`)
                                }
                                onError={() => console.log('errors')}
                                onCancel={() => this.hideRecordEditor()}
                            />
                        </Col>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

RecordFormEditorGrid.contextType = FormContext;

RecordFormEditorGrid.defaultProps = {
    columns: [],
    filters: [],
};

if (process.env.NODE_ENV !== 'production') {
    RecordFormEditorGrid.propTypes = {
        gridData: PropTypes.array,
        columns: PropTypes.array.isRequired,
        pageSize: PropTypes.number,
        minRows: PropTypes.number,
        onChange: PropTypes.func,
        recordConfigPath: PropTypes.string,
        filters: PropTypes.array,
        gridActions: PropTypes.array,
        isOrdered: PropTypes.bool,
        showPaging: PropTypes.bool,
        field: PropTypes.shape({
            uiHint: PropTypes.shape({
                showFilter: PropTypes.bool
            })
        }),
        showErrors: PropTypes.bool,
        style: PropTypes.object,
        checkboxSelection: PropTypes.bool,
        dialogSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        itemId: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        panSchema: PropTypes.object,
        inputFields: PropTypes.array,
        showDefaultAddAction: PropTypes.bool,
        showDefaultDeleteAction: PropTypes.bool,
        errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    };
}
