import React from 'react';
import DataProxy from "../applications/AppDataProxy";
import { Button } from "reactstrap";
import { GridWidget, BaseBuilder, FormContext, getPathValue, Pan, INJECTED_REC_ID, generateGridRecId, connect} from 'ui-lib';
import FilterViewer from '../applications/FilterViewer';
import _ from 'lodash';
import './appfilter.scss';
const filterCategories = ['category', 'subcategory', 'technology', 'risk'];
const characteristicItems = [
	'is-saas',
	'pervasive',
	'prone-to-misuse',
	'tunnels-other-apps',
	'has-known-vulnerabilities',
	'transfers-files',
	'used-by-malware',
	'excessive-bandwidth-use',
	'evasive'];

const getAllData = data => {
		let alldata = {};
		for (let type in data) {
			let json = data[type] || [];
			alldata[type] = json;
		}
		return alldata;
	};

const updateAppData = ({
	filters,
	predefinedApps,
	useFor,
	gridData
}) => {

	const selectable = useFor === "APP_GROUP";

	const alldata = getAllData(gridData);
	alldata["predefined"] = predefinedApps;
	const result = DataProxy.loadData(alldata, selectable);

	const newresult = DataProxy.loadFilteredData(result, filters);
	DataProxy.updateDisplayList(result, newresult);

	return {
		filters,
		alldata,
		result,
		newresult
	};
};

class AppFilter extends BaseBuilder {

	constructor(props) {
		super(props);

		let { filters } = props;
		filters = filters || [];

		this.state = {
			filters
		};
	}

	componentDidMount() {
		let { formData } = this.context;
		let filters = [];
		filterCategories.forEach(cat => {
			let filterData = getPathValue(formData, '$.' + cat + '.member');
			let filterValue = [];
			if (filterData) {
				filterValue = _.reduce(filterData, (result, data, key) => {
					result.push(data.value)
					return result;
				}, [])
				filters.push({ type: cat, data: filterValue });
			}
		});

		let characteristicFilters = null;
		characteristicItems.forEach(item => {
			let filterData = getPathValue(formData, '$.' + item);
			if (filterData && filterData === 'yes') {
				characteristicFilters = characteristicFilters || [];
				characteristicFilters.push(item);
			}
		});
		if (characteristicFilters) {
			filters.push({ type: 'characteristic', data: characteristicFilters });
		}

		const { useFor, gridData, predefinedApps } = this.props;

		const { alldata, result, newresult } = updateAppData({ filters, predefinedApps, useFor, gridData });

		this.setState({
			filters,
			alldata,
			result,
			newresult
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let { filters } = prevState;
		let { useFor, gridData, predefinedApps = [] } = nextProps;
		
		const { alldata, result, newresult } = updateAppData({ filters, predefinedApps, useFor, gridData });

		return {
			filters,
			alldata,
			result,
			newresult
		};
	};

	saveFilterData = (filters) => {
		let fieldValues = [];
		filters.forEach((filter) => {
			console.log(filter);
			let filterData = filter && filter.data ? filter.data : '';
			let filterType = filter && filter.type ? filter.type : '';
			let filterValue = null;
			if (filterType === 'characteristic') {
				console.log(filterData);
				let charItems = Pan.clone(characteristicItems);
				if (filterData) {
					filterData.forEach((filterItem) => {
						fieldValues.push({ field: { attrPath: '$.' + filterItem }, value: 'yes' });
						if (charItems.indexOf(filterItem)) {
							charItems.splice(charItems.indexOf(filterItem), 1);
						}
					});
				}
				charItems.forEach(item => {
					fieldValues.push({ field: { attrPath: '$.' + item }, value: null });
				})
				// fieldValues.push({field: {attrPath : '$.'+filterType+'.member'}, value: Pan.isEmpty(filterValue) ? null : filterValue});
			} else {
				filterValue = _.reduce(filterData, (result, value) => {
					result.push({
						value: value,
						[INJECTED_REC_ID]: generateGridRecId()
					});
					return result;
				}, []);
				fieldValues.push({ field: { attrPath: '$.' + filterType + '.member' }, value: Pan.isEmpty(filterValue) ? null : filterValue });
			}
		});
		super.setFieldValues(fieldValues, true);
	}

	updateFilters = (type, filter) => {
		// if type and filter are not defined, we are clearing the filters
		if (type === undefined && filter === undefined) {
			return [];
		}

		let { filters } = this.state;

		// add current filter
		let ret = [{ type: type, data: filter }];

		// add the remain filters
		for (let i = 0; i < filters.length; i++) {
			let item = filters[i];
			if (item.type !== type) {
				ret.push(item);
			}
		}
		return ret;
	};

	onFilterChange = (type, filter) => {
		let { result } = this.state;
		let filters = this.updateFilters(type, filter);

		this.saveFilterData(filters);

		var newresult = DataProxy.loadFilteredData(result, filters);
		DataProxy.updateDisplayList(result, newresult);

		this.setState({
			filters,
			newresult
		});
	};

	onClearFilters = e => {
		e.preventDefault();
		let filters = [];
		
		let { result } = this.state;
		this.saveFilterData(filters);

		var newresult = DataProxy.loadFilteredData(result, filters);
		DataProxy.updateDisplayList(result, newresult);
		this.setState({
			filters,
			newresult
		});
	};

	columns = [{
		type: ['nameColumn', 'clickableColumn'],
		field: 'title',
		cellRendererParams: {
			renderCell: params => {
				let { treeClass, iconClass } = params.data;
				return (
					<div>
						<span style={{ paddingRight: '2px' }}>{treeClass && <img alt='' src='/images/blank.gif' className={`${treeClass}`} />}</span>
						<span style={{ paddingRight: '2px', border: 'none' }}><img alt='' src='/images/blank.gif' style={{ paddingRight: '2px', border: 'none' }} className={`${iconClass}`} /></span>
						{params.value}
					</div>
				);
			}
		},
		minWidth: 250,
		headerCheckboxSelection: false,
		headerCheckboxSelectionFilteredOnly: false,
		checkboxSelection: false
	}, {
		headerName: 'Location',
		field: '@loc',
		minWidth: 200
	}];
	render() {
		let {
			filters,
			newresult,
		} = this.state;
		let { loading, fetchingPredefionedApps } = this.props;
		let gridData = (newresult && newresult.data) || [];

		return (
			<div className="">
				<div className="clearfix app-filter-margin">
					<Button
						className="float-right"
						color="secondary"
						onClick={e => this.onClearFilters(e)}
					>
						Clear Filters
					</Button>
				</div>
				<div className='filter-border'>
					<FilterViewer
					  isLoading={fetchingPredefionedApps}
						result={newresult}
						filters={filters}
						onFilterChange={this.onFilterChange}
					/>
				</div>
				<GridWidget
					gridData={gridData}
					columns={this.columns}
					pageSize={200}
					gridActions={[]}
					checkboxSelection={false}
					showFilter={false}
					loading={loading || fetchingPredefionedApps}
					className={"app-browser"}
					idColumn="title"
				/>
			</div>
		)
	}
}

AppFilter.contextType = FormContext;

const mapStateToProps = ({
	main: {
		content: {
			applications = [],
			fetchingApplications = true
		}
	}
}) => {
	return {
		predefinedApps: applications,
		fetchingPredefionedApps: fetchingApplications
	};
};

export default connect(mapStateToProps)(AppFilter);
