import React, { Component } from 'react';
import { ModalWindowWidget } from '../ModalWindowWidget';
import PropTypes from 'prop-types';

export class ModalStackWidget extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                {this.props.modals.map(item => {
                    let modal = item.modal;
                    return <ModalWindowWidget
                        id={modal.id}
                        key={modal.id} 
                        open={modal.open}
                        message={modal.message}
                        actions={modal.actions}
                        toggle={modal.toggle}
                        size={modal.size}
                        type={modal.type}
                        title={modal.title}
                        style={modal.style}
                        modalBody={modal.modalBody} 
                        modalHeaderStyle={modal.modalHeaderStyle}
                        modalBodyStyle={modal.modalBodyStyle}
                        modalFooterStyle={modal.modalFooterStyle}
                        modalHeaderClassName={modal.modalHeaderClassName}
                        modalBodyClassName={modal.modalBodyClassName}
                        modalFooterClassName={modal.modalFooterClassName}
                    />
                })
            }
        </div>);
    }
}

ModalStackWidget.defaultProps = {};

if (process.env.NODE_ENV !== 'production') {
    ModalStackWidget.propTypes = {
        modals: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                message: PropTypes.string,
                size: PropTypes.string,
                type: PropTypes.string,
                title: PropTypes.string,
                /** component */
                modalBody: PropTypes.object,
                style: PropTypes.object,
                toggle: PropTypes.func,
                /**
                 * actions is an array which displays buttons in the modal widget.
                 * action is the callback function
                 */
                actions: PropTypes.arrayOf(
                    PropTypes.shape({
                        text: PropTypes.string,
                        color: PropTypes.string,
                        action: PropTypes.func,
                    }),
                ),
            }),
        ),
    };
}
