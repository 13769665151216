import * as React from "react";
import {
    autoCompletionObservable,
    BaseBuilder,
    FormContext,
    generateGridRecId,
    getPathValue,
    getPathValues,
    GridWidget,
    INJECTED_REC_ID,
    Pan,
    parseAutoCompletionToOptions,
    SelectWidget
} from "ui-lib";
import { each as lodashEach, find, groupBy, isEmpty, map, mapKeys, mapValues } from 'lodash';
import { ajax } from 'rxjs/ajax';
const SITEACCESS = 'siteAccess';
const USERCREDENTIALSUBMISSION = 'userCredentialSubmission';

export default class URLFilteringCategoriesTableBuilder extends BaseBuilder {

    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.state = {
            value: null,
            hideField: false,
            categories: [],
            type: getPathValues(props.field, 'uiHint.type')
        }
    }

    componentDidMount() {
        let { recordConfigPath, configLocation, formData } = this.context;
        ajax(autoCompletionObservable("", `${recordConfigPath}.alert.member`, configLocation)).subscribe((response) => {
            const categoriesCompletion = parseAutoCompletionToOptions(response, true, '@value').sort((s1, s2) => {
                return s1.label.localeCompare(s2.label);
            });

            const siteAccesses = {};
            lodashEach(["alert", "block", "continue", "override"], (action) => {
                const categories = getPathValue(formData, `$.${action}.member`) || [];
                //Now construct an array from this
                lodashEach(categories, (category) => {
                    siteAccesses[category.value] = action;
                })
            });

            const userCredentialSubmissions = {};
            lodashEach(["alert", "block", "continue"], (action) => {
                const categories = getPathValue(formData, `$.credential-enforcement.${action}.member`) || [];
                lodashEach(categories, (category) => {
                    userCredentialSubmissions[category.value] = action;
                })
            });

            const categories = map(categoriesCompletion, (category) => {
                return {
                    ...category,
                    siteAccess: siteAccesses[category.value] || "allow",
                    userCredentialSubmission: userCredentialSubmissions[category.value] || "allow"
                };
            });
            this.setState((state) => {
                return {
                    ...state,
                    categories
                }
            });
        });
    }

    render() {
        const onChange = (params, propertyName) => {
            return (newValue) => {
                let newState = { ...this.state };
                newState.categories[params.rowIndex][propertyName] = newValue;
                if (propertyName === SITEACCESS && newValue === 'block') {
                    newState.categories[params.rowIndex][USERCREDENTIALSUBMISSION] = newValue;
                }
                this.setState(newState, () => {
                    this.setRecordValueFromState();
                });
            }
        };

        const columns = [
            {
                headerName: "Category",
                field: "label",
                cellRendererParams: {
                    renderCell: params => {
                        return (
                            <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                {params.value}
                            </div>
                        );
                    }
                }
            },
            {
                headerName: "Site Access",
                cellRendererParams: {
                    renderCell: params => {
                        return (
                            <SelectWidget
                                options={[
                                    { value: "alert", label: "Alert" },
                                    { value: "allow", label: "Allow" },
                                    { value: "block", label: "Block" },
                                    { value: "continue", label: "Continue" },
                                    { value: "override", label: "Override" },
                                ]}
                                value={params.data.siteAccess}
                                onChange={onChange(params, SITEACCESS).bind(this)}
                            />
                        );
                    }
                }
            },
            {
                headerName: "User Credential Submission",
                cellRendererParams: {
                    renderCell: params => {
                        return (
                            <SelectWidget
                                options={[
                                    { value: "alert", label: "Alert" },
                                    { value: "allow", label: "Allow" },
                                    { value: "block", label: "Block" },
                                    { value: "continue", label: "Continue" }
                                ]}
                                value={params.data.userCredentialSubmission}
                                onChange={onChange(params, USERCREDENTIALSUBMISSION).bind(this)}
                            />
                        );
                    }
                }
            }
        ];
        return (
            <GridWidget
                columns={columns}
                gridData={this.state.categories}
                checkboxSelection={false}
                showPaging
                showGridToolBar
                pageSize={10}
                minRows={10}
            />
        );
    }

    setRecordValueFromState() {
        let fieldNameToValues = {
            "$.credential-enforcement.alert.member": [],
            "$.credential-enforcement.allow.member": [],
            "$.credential-enforcement.block.member": [],
            "$.credential-enforcement.continue.member": [],
            "$.alert.member": [],
            "$.allow.member": [],
            "$.block.member": [],
            "$.continue.member": [],
            "$.override.member": [],
        };

        let values = map(this.state.categories, (category) => {
            return {
                siteAccess: category.siteAccess,
                userCredentialSubmission: category.userCredentialSubmission,
                category: category.value
            }
        });

        let userCredentialSubmissionValues = mapValues(groupBy(values, "userCredentialSubmission"), (values) => map(values, (value) => {
            return { value: value.category, [INJECTED_REC_ID]: generateGridRecId() };
        }));
        delete userCredentialSubmissionValues["allow"]; // Allow is the default, we can remove it
        if (!isEmpty(userCredentialSubmissionValues)) {
            userCredentialSubmissionValues = mapKeys(userCredentialSubmissionValues, (value, key) => `$.credential-enforcement.${key}.member`);
            fieldNameToValues = { ...fieldNameToValues, ...userCredentialSubmissionValues };
        }

        let siteAccessValues = mapValues(groupBy(values, "siteAccess"), (values) => map(values, (value) => {
            return { value: value.category, [INJECTED_REC_ID]: generateGridRecId() };
        }));
        delete siteAccessValues["allow"];
        if (!isEmpty(siteAccessValues)) {
            siteAccessValues = mapKeys(siteAccessValues, (value, key) => `$.${key}.member`);
            fieldNameToValues = { ...fieldNameToValues, ...siteAccessValues };
        }
        let { fields } = this.context;
        const fieldToValues = map(fieldNameToValues, (fieldValue, fieldName) => {
            let field = find(fields, (field) => field.name === fieldName);
            if (field) {
                if (isEmpty(fieldValue)) {
                    return { field, value: null };
                } else {
                    return { field, value: fieldValue };
                }
            } else {
                console.error(`Could not locate field [${fieldName}] in the list of fields. `, fields);
            }
        });
        this.setFieldValues(fieldToValues, true);
    }
}
URLFilteringCategoriesTableBuilder.contextType = FormContext;
