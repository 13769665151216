import React, { Component } from 'react';
import PropTypes from "prop-types";
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import { getMapboxToken } from '../../../utils/licenseInfo';
let MAP;
export class MapboxWidget extends Component {
    constructor(props) {
        super(props);
    }


    componentDidMount( ) {    
    }

    /**
     * 
     * fitBounds requires coordinates in south west, north east order.
     * currently, no good way to calculate bounds
     */
    calculateBounds(markers){
    }

    render() {
        const { markers, width, height, zoom, center, controlPosition, fitBounds } = this.props;
        const onMarkerClick = this.props.onMarkerClick || function(){ return undefined; };
        const mapWidth = width || '100%';
        const mapHeight = height || 600;
        const defaultZoom = zoom || 1.2;
        const mapCenter = center || [41.069128, 29.088259];
        const zoomControlPosition = controlPosition || "bottom-left";
        const mapFitBounds = fitBounds || undefined;
        const token = getMapboxToken();
        if (!MAP && token) {
            MAP = ReactMapboxGl({
                accessToken: token,
                scrollZoom: false
            });
        }
        
        return (
            <div>
                {MAP && <MAP
                    style="mapbox://styles/mapbox/light-v9"
                    containerStyle={{
                        width: mapWidth,
                        height: mapHeight
                    }}
                    zoom={[defaultZoom]}
                    fitBounds={mapFitBounds}
                    center={mapCenter}                  
                >
                    {
                        markers && markers.map((region, i) => (
                            <Marker 
                                key={i} 
                                coordinates={region.coordinates}
                                onClick={() => onMarkerClick(region)}
                                offset={region.offset}
                                children={region.children}
                            />
                        ))
                    }
                    <ZoomControl position={zoomControlPosition} />
                </MAP>}
            </div>
        );
    }
}

MapboxWidget.defaultProps = {
};

if (process.env.NODE_ENV !== "production") {
    MapboxWidget.propTypes = {
    };
}