import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup } from 'reactstrap';
import { getBuilder } from '../../../utils/BuilderManager';
import { FormContext, FormConsumer } from '../../FormContext';
import { getField, getFieldLabelComp, getFieldWidthClass } from '../../schema/utils';
import { getPathValues } from '../../../utils/json';
import './RowBuilder.scss';
import { BaseBuilder } from '../BaseBuilder';

export class RowBuilder extends BaseBuilder {
    static NAME = 'RowBuilder';
    constructor(props) {
        super(props);
        this.state = {
            showEditor: false,
        };
    }

    layoutChildren(layout) {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
        } = this.props;
        const { fields } = this.context;
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        return layout.map((row, index) => {
            return (
                <Row key={index} className="d-flex flex-row">
                    {
                        Object.keys(row).map(name => {
                            const Builder = getBuilder({ name, fields });
                            const thisField = getField(fields, name);
                            return (
                                <Col key={name} className={row[name]}>
                                    <Builder
                                        name={name}
                                        field={thisField}
                                        onChange={onChange}
                                        addError={addError}
                                        removeError={removeError}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        filters={filters}
                                        cellInfo={cellInfo}
                                        containerLabelWidthSize={
                                            containerLabelWidthSize
                                        }
                                        isEditorGridColumnEditor={
                                            isEditorGridColumnEditor
                                        }
                                        disabled={disabled}
                                        showErrors={showErrors}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            );
        });
    }

    showEditor() {
        this.setState({
            showEditor: true,
        });
    }

    hideEditor() {
        this.setState({
            showEditor: false,
        });
    }

    layoutChildren(layout) {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors
        } = this.props;
        const { fields } = this.context;
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        return layout.map((row, index) => {
            return (
                <Row key={index} className={`d-flex flex-row col-12`}>
                    {
                        Object.keys(row).map((name) => {
                            const Builder = getBuilder({ name, fields });
                            const thisField = getField(fields, name);
                            return (
                                <Col key={name} className={row[name]}>
                                    <Builder
                                        name={name}
                                        field={thisField}
                                        onChange={onChange}
                                        addError={addError}
                                        removeError={removeError}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        filters={filters}
                                        cellInfo={cellInfo}
                                        containerLabelWidthSize={containerLabelWidthSize}
                                        isEditorGridColumnEditor={isEditorGridColumnEditor}
                                        disabled={disabled}
                                        showErrors={showErrors}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            )
        });
    }

    renderChildren() {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
        } = this.props;
        const { fields } = this.context;
        const childrenNames = field.childrenNames;
        if (!childrenNames) {
            return <div />;
        }
        let containerLabelWidthSize =
            getPathValues(field, 'uiHint.labelWidthSize') ||
            this.props.containerLabelWidthSize;
        let LabelComp = getFieldLabelComp(
            field,
            name,
            isEditorGridColumnEditor,
            containerLabelWidthSize,
        );
        let containerClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        const layout = field.uiHint.layout;
        let children = [];
        if (layout) {
            children = this.layoutChildren(layout);
        } else {
            children = childrenNames.map(name => {
                const Builder = getBuilder({ name, fields });
                const thisField = getField(fields, name);
                return (
                    <Col>
                        <Builder
                            name={name}
                            field={thisField}
                            onChange={onChange}
                            addError={addError}
                            removeError={removeError}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            filters={filters}
                            showErrors={showErrors}
                            containerLabelWidthSize={containerLabelWidthSize}
                            disabled={disabled}
                        />
                    </Col>
                );
            });
        }
        let required = '';//allowBlank === false ? 'required' : '';

        return (
            <FormGroup className={`row-builder d-flex flex-row ${required}`} test_id={field.attrPath}>
                {LabelComp}
                <Col>
                    <div className={`d-flex flex-row no-padding ${containerClassName}`}>{children}</div>
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderChildren()}</FormConsumer>
            </React.Fragment>
        );
    }
}

RowBuilder.contextType = FormContext;

RowBuilder.defaultProps = {
    field: {},
    disabled: false,
};

if (process.env.NODE_ENV !== 'production') {
    RowBuilder.propTypes = {

        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        containerLabelWidthSize: PropTypes.string,
        isEditorGridColumnEditor: PropTypes.bool,
        cellInfo: PropTypes.object,
		/**
         * *defaultValue* - sets the default value of the field.
		 * *childrenNames* - array of strings containing names of child fields
         * UiHint properties :
         * *allowBlank* - adds error on blank values
         * *fieldWidthSize* - The size of the field - range from 1 to 12. 
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *fieldLabel* - adds a label to the field
         * *hideLabel* - hides the label
		 * *layout* - an array of objects that specifies which field will have what width eg [{'$.action':'col-2'}]
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
		 */
        field: PropTypes.shape({
            childrenNames: PropTypes.array,
            defaultValue: PropTypes.string,
            uiHint: PropTypes.shape({
                allowBlank: PropTypes.bool,
                fieldLabelAutoGen: PropTypes.string,
                fieldWidthSize: PropTypes.string,
                labelWidthSize: PropTypes.string,
                layout: PropTypes.array,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.bool,
                vtype: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    fields: PropTypes.object,
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            }),
        }),
    };
}