import {
	jsonPath,
	autoCompletionObservable,
	parseAutoCompletionToOptions,
	getPathValue,
	getCompletionXpath,
	getFieldXpath,
	ViewerManager,
	parseError,
	SERVER_ERROR,
	Pan
} from "ui-lib";
import DGBaseViewer from "../../deviceGroup/DGBaseViewer";
import { ajax } from 'rxjs/ajax';
import { store } from '../../../store';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

const _T = str => str;

const urlCategoryObjectFields = [
	{
		type: "string",
		name: "name",
		mapping: "@name",
		uiHint: {
			fieldLabel: _T("Name"),
			autoFocus: true
		}
	},
	{
		name: "$.description",
		uiHint: {
			fieldLabel: _T("Description")
		}
	},
	{
		name: "$.type",
		uiHint: {
			fieldLabel: _T("Type")
		}
	},
	{
		name: "$.list.member",
		uiHint: {
			fieldLabel: _T(" ")
		}
	},
	{
		name: "$.list.member.*",
		looseMembership: true,
		uiHint: {
			builder: "CompletionBuilder",
			completionConfig: {
				CustomCompletion: (
					inputValue,
					completionXpath,
					configLocation,
					formData,
					filters
				) => {
					let categoryType = getPathValue(formData, "$.type");
					if (categoryType) {
						let additional = [
							{
								name: "type",
								value: categoryType || ""
							}
						];

						let fieldPath = getCompletionXpath(
							"$.type",
							filters,
							formData,
							"$.config.devices.entry.device-group.entry.profiles.custom-url-category.entry"
						);
						let pobj = getFieldXpath(fieldPath, configLocation);
						let completinObservable = ajax(
							autoCompletionObservable(
								inputValue,
								completionXpath,
								configLocation,
								additional,
								pobj
							)
						);
						return new Promise(function (resolve, reject) {
							completinObservable.toPromise().then(
								resp => {
									let completions = parseAutoCompletionToOptions(resp, true, '@value');
									resolve(completions);
								},
								err => {
									store.dispatch({
										type: SERVER_ERROR,
										errorMessage: parseError(err),
										showMessage: false
									});
									// reject(new Error('Error in Auto Completion: ' + err ));
								}
							);
						});
					} else {
						return new Promise(function (resolve, reject) {
							resolve([]);
						});
					}
				}
			}
		}
	},

	{
		name: "$",
		childrenNames: ["name", "$.description", "$.type", "$.list.member"]
	}
];

const urlCategoryObjectColumns = [
	{
		type: ['nameColumn', 'clickableColumn'],
	},
	{
		headerName: "Location",
		field: "@loc"
	},
	{
		headerName: "Type",
		field: "type"
	},
	{
		headerName: "Match",
		valueGetter: params => {
			return jsonPath(params.data, "list.member");
		}
	}
];

const viewConfig = {
	serviceName: "Objects/CustomURLCategories",
	reduxId: "URLCategory",
	header: "URL Categories",
	columns: urlCategoryObjectColumns,
	fields: urlCategoryObjectFields,
	recordConfigPath: "$.config.devices.entry.device-group.entry.profiles.custom-url-category.entry",
	rbaPath: "objects.custom-objects.url-category",
	className: 'URLCategory'
}

export default class URLCategory extends DGBaseViewer {
	constructor(props) {
		super(props);
		Pan.apply(this, viewConfig);
	}
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);