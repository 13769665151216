import React, { Component } from 'react'

class GridTotal extends Component {
    render() {
        return (
            <span className="items-area btn-height-text btn-height-text-sm">
                {this.props.totalItem} {this.props.itemName}
            </span>
        )
    }
}

export default GridTotal;
