import React, { Fragment } from 'react';
import { Input } from 'reactstrap';
import { jsonPath, getRecordPathWithFilters } from '../../../utils/json';
import Pan from '../../../autorender/schema/Pan';
import { FormContext } from '../../FormContext';
import { INJECTED_REC_ID } from '../util';
import { getFieldErrors } from '../../schema/utils';

export class PanCellRenderer extends React.Component {

    render() {
        let CellComponent = '';
        const { value, column, renderCell, colDef } = this.props;
        if (renderCell) {
            if (Pan.isFunction(renderCell)) {
                CellComponent = renderCell(this.props);
            } else {
                CellComponent = renderCell;
            }
        } else if (column.type === 'bool') {
            // Checkbox for bool
            CellComponent = checkboxRenderer(value);
        } else if (column.type === 'reverseBool') {
            // Reverse checkbox for reversebool
            CellComponent = reverseCheckboxRenderer(value);
        } else {
            // default renderere to handle Array
            CellComponent = arrayRenderer(value);
        }
        return <Fragment>{CellComponent}</Fragment>
    }
}

export function arrayRenderer(value) {
    if (value && typeof value === 'object' && value.member) {
        value = jsonPath(value, 'member.*.value') || jsonPath(value, 'member.*');
    }

    if (Array.isArray(value)) {
        return value.map((val, index) => {
            if (Array.isArray(val)) {
                return <div key={index} className="array-value-inner-cell">{arrayRenderer(val)}</div>;
            } else if (typeof val === 'object') {
                //we dont know how to display this es: {__KEY: '1234567'}
                return <Fragment key={index}><div className="array-value-cell"></div></Fragment>;
            } else {
                return <Fragment key={index}><div className="array-value-cell">{val}</div></Fragment>;
            }
        });
    } else if (!Pan.isEmpty(value) && typeof value === 'object') {
        return <div>{JSON.stringify(value)}</div>;
    } else {
        value = Pan.isEmpty(value) ? '' : value;
        return <div>{value}</div>;
    }
}

export function checkboxRenderer(value) {
    let check = false;
    if (value === 'yes' || value === 'true' || value === true) {
        check = true;
    }
    return <Input type="checkbox" checked={check} disabled />;
}

export function reverseCheckboxRenderer(value) {
    let check = true;
    if (value === 'yes' || value === 'true' || value === true) {
        check = false;
    }
    return <Input type="checkbox" checked={check} disabled />;
}