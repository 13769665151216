import React, { Component } from 'react';
import Select, { components } from 'react-select';
import PropTypes from "prop-types";

export class TagsGroupWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.items,
            type: props.type,
        };
    }

    handleOnChange(value) {
        let difference = this.state.options.filter(x => !value.includes(x));
        if (difference && difference.length > 0) {
            console.log('Removed: ', difference);
            this.props.onTagRemove && this.props.onTagRemove(this.state.type, difference[0].value);
        }
    }

    render() {
        const divStyle = {
            // width: '250px',
            float: 'left'
        }

        return (
            <div className="tag-group-item-wrapper" style={divStyle}>
                <span>{this.state.type}&nbsp;</span>
                <Select
                    value={this.props.items}
                    isMulti={true}
                    options={this.props.items}
                    className=""
                    classNamePrefix=""
                    noOptionsMessage={() => null}
                    isClearable={false}
                    isSearchable={false}
                    placeholder={''}
                    onChange={this.handleOnChange.bind(this)}
                    styles={{
                        container: base => ({
                            ...base,
                            border: 0,
                            padding: 0,
                            width: this.props.items * 100,
                            borderRadius: '0px',
                        }),
                    }}
                    components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: null,
                    }}
                />
            </div>
        );
    }
}


TagsGroupWidget.defaultProps = {
};

if (process.env.NODE_ENV !== "production") {
    TagsGroupWidget.propTypes = {
       items:PropTypes.arrayOf(PropTypes.shape({
           value:PropTypes.string,
           label:PropTypes.string
       })),
       /**
        * The label for the select widget
        */
       type:PropTypes.string,
       onTagRemove:PropTypes.func
    }
}