import React, { Component } from 'react';
import { Card, CardBody, FormGroup, Modal, ModalBody } from 'reactstrap';
import { Stage } from './Stage';
import PanSchema from '../../schema/PanSchema';
import { HLGrid } from '../HLGrid';
import { FormProvider } from '../../FormContext';
import { SpinnerWidget } from '../SpinnerWidget';
import _ from 'lodash';
import './WorkflowViewer.scss';
import { ExtraInfoContext } from '../../ExtraInfoContext';
import Pan from '../../schema/Pan';
import { getField } from '../../schema/utils';
import { SummaryBuilder } from '../../builders/SummaryBuilder';
import { formDataPostProcess } from '../util';
import { getPathValues } from '../../../utils/json';
import { showModal, hideModal } from "../../../services/actions";
import { unsavedChangesWarning, unsavedChangesDialogTitle } from '../../../../constants';
import PropTypes from "prop-types";
import classnames from 'classnames';

const _T = str => str;
export class WorkflowViewer extends Component {
    constructor(props) {
        super(props);
        let panSchema = new PanSchema(props.panSchema, props.recordConfigPath, props.inputFields);
        const rootField = props.inputFields.find(function (fld) {
            return fld.name === '$';
        })
        const childrenNames = rootField.childrenNames;
        this.state = {
            data: [],
            activeStage: childrenNames[0],
            pages: null,
            loading: true,
            showWorkflow: props.mode === 'workflow',
            record: {},
            isNewRecord: true,
            formDataBackup: {},
            formData: {},
            panSchema: panSchema,
            schema: props.panSchema,
            fields: panSchema.fields,
            showSummaryPage: false,
            showMapPage: props.showMapPage,
            pushJobId: props.pushJobId,
            jumpToStep: 0,
            fromSummary: false,
            singleStepForAdd: props.singleStepForAdd,
            jobPollingStarted: false,
            showModal: false,
            hideEditToSummary: props.hideEditToSummary,
            formState: props.formState
        };
        this.displayRecordEditor = this.displayRecordEditor.bind(this);
        this.hideRecordEditor = this.hideRecordEditor.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSpecialSubmit = this.onSpecialSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onGoToSummary = this.onGoToSummary.bind(this);
        this.onGoToMapPage = this.onGoToMapPage.bind(this);
        this.onAddRecord = this.onAddRecord.bind(this);
        this.onEditRecord = this.onEditRecord.bind(this);
        this.onDeleteRecord = this.onDeleteRecord.bind(this);
        this.onCloneRecord = this.onCloneRecord.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSetup = this.onSetup.bind(this);
        this.fromEditToSummary = this.fromEditToSummary.bind(this);
        this.getJobDetails = this.getJobDetails.bind(this);
        this.resetJobSync = this.resetJobSync.bind(this);
        this.stages = {};
        childrenNames.forEach((childName) => {
            this.stages[childName] = React.createRef();
        });
    }

    componentDidMount() {
        let pathname = this.props.location && this.props.location.pathname || '';
        let page = pathname.substr(pathname.lastIndexOf('/') + 1);
        if (page === 'setup') {
            this.displayRecordEditor();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !nextProps.loading;
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        if (Pan.isBoolean(nextProps.showWorkflow) && nextProps.showWorkflow !== prevState.showWorkflow) {
            // change form display state
            state = {
                showWorkflow: nextProps.showWorkflow,
                pushJobId: undefined,
                jobPollingStarted: false,
                jobDetails: undefined,
                intervalId: undefined,
            };
        } else {
            if (nextProps.gpcs.pushJobId && !prevState.pushJobId) {
                state = {
                    pushJobId: nextProps.gpcs.pushJobId,
                    jobPollingStarted: true,
                    jobDetails: nextProps.gpcs.jobDetails,
                };
                if (!prevState.intervalId) {
                    let intervalId = WorkflowViewer.getJobIntervalId(nextProps.gpcs.pushJobId, prevState.intervalId, nextProps.getJobDetails);
                    state = {
                        ...state,intervalId
                    }
                    // this.resetJobSync(nextProps.gpcs.pushJobId);
                }
            }
            if (
                nextProps.gpcs.jobDetails &&
                nextProps.gpcs.jobDetails.jobStatus &&
                nextProps.gpcs.jobDetails.jobStatus === 'FIN'
            ) {
                clearInterval(prevState.intervalId);
                state = {
                    ...state,
                    pushJobId: undefined,
                    jobPollingStarted: false,
                    jobDetails: nextProps.gpcs.jobDetails,
                    mapPageData: nextProps.mapPageData,
                    intervalId: undefined
                };
            }
            if (
                nextProps.gpcs.jobDetails &&
                nextProps.gpcs.jobDetails.jobStatus &&
                nextProps.gpcs.jobDetails.jobStatus !== 'FIN'
            ) {
                state = {
                    ...state,
                    jobDetails: nextProps.gpcs.jobDetails,
                };
            }
            if (nextProps.formState) {
                state = {
                    ...state,
                    formState: nextProps.formState
                };
            }
            if(nextProps.reduxStateId && nextProps.reduxStateId.reduxId) {
                const {reduxId} = nextProps.reduxStateId;
                if(nextProps.gpcs && nextProps.gpcs[reduxId] && nextProps.gpcs[reduxId]['SAVE_SUCCESS'] !== undefined) {
                    state = {
                        ...state,
                        isNewRecord: !nextProps.gpcs[reduxId]['SAVE_SUCCESS']
                    }   
                }  
            }
        }
        return state;
    }
    
    componentDidUpdate(prevProps, _) {
        if (prevProps.showWorkflow === this.state.showWorkflow) {
            if (prevProps.gpcs.pushJobId && !this.state.pushJobId) {
                if (!this.state.intervalId) {
                    this.resetJobSync(prevProps.gpcs.pushJobId);
                }
            }
            if (prevProps.gpcs.jobDetails && prevProps.gpcs.jobDetails.jobStatus && prevProps.gpcs.jobDetails.jobStatus === 'FIN') {
                clearInterval(this.state.intervalId);
            }
        }
        if (this.state.showWorkflow && this.state.fromSummary) {
            this.stages[this.state.activeStage].current.wizard.current.jumpToStep(this.state.jumpToStep);
            this.setState({
                fromSummary: false
            })
        }
    }

    jumpToStep = (stageName, stepName) => {
        this.setState({
            activeStage: stageName,
            showSummaryPage: false,
            jumpToStep: stepName,
            fromSummary: true
        });
    }

    getRootChildrenNames() {
        const fields = this.state.panSchema.fields;
        const rooFieldName = this.props.itemId || '$';
        const rootField = fields.find(function (fld) {
            return fld.name === rooFieldName;
        });
        return rootField.childrenNames;
    }

    displayRecordEditor(record) {
        let state = {};
        if (!Pan.isEmpty(record)) {
            let _showSummaryPage = true;
            if( this.props.summaryAvail && Pan.isFunction(this.props.summaryAvail) ) {
                _showSummaryPage = this.props.summaryAvail( );
            }
            state = {
                record: record || {},
                isNewRecord: _.isEmpty(record),
                formDataBackup: record || {},
                formData: record || {},
                singleStepForAdd: false,
                showSummaryPage: _showSummaryPage
            };
        } else {
            state = {
                record:  {},
                isNewRecord: _.isEmpty(record),
                formDataBackup: {},
                formData: {},
            };
        }

        if (this.props.onShowWorkflow) {
            this.setState({
                ...state
            }, () => {
                this.props.onShowWorkflow()
            });
        } else {
            this.setState({
                ...state,
                showWorkflow: true
            });
        }
    }

    hideRecordEditor() {
        let state = {
            record: {},
            formDataBackup: {},
            formData: {}
        };
        if (this.props.onHideWorkflow) {
            this.setState ({
                ...state,
            }, () => {
                this.props.onHideWorkflow()
            });
        } else {
            this.setState({
                ...state,
                showWorkflow: false
            });
        }
    }

    findGridDataRecord(formData, gridData) {
        let record = null;
        if (gridData && gridData.length > 0) {
            record = gridData.find((rec) =>  rec['@name'] === formData['@name'])
        }
        return record;
    }


    onSubmit(formData, formDataBackup) {
        formDataPostProcess(formData, this.state.panSchema.fields, [this.props.itemId || '$']);
        // invoke function callback
        if (this.state.isNewRecord) {
            // Add record
            this.onAddRecord(formData, this.props.recordConfigPath, false);
        } else {
            let record = this.findGridDataRecord(formDataBackup, this.props.gridData);
            if (!record) {
                formDataBackup['@name'] = formData['@name'];
            }
            // Edit record
            this.onEditRecord(formData, formDataBackup, this.props.recordConfigPath, false);
        }
        // Maybe for ADI-1242
        // this.props.onHideWorkflow();
    }

    onSpecialSubmit(formData, formDataBackup) {
        formDataPostProcess(formData);

        if (this.state.isNewRecord) {
            // Add record
            this.onAddRecord(formData, this.props.recordConfigPath, true);
        } else {
            let record = this.findGridDataRecord(formDataBackup, this.props.gridData);
            if (!record) {
                formDataBackup['@name'] = formData['@name'];
            }
            // Edit record
            this.onEditRecord(formData, formDataBackup, this.props.recordConfigPath, true);
        }

    }

    onGoToSummary() {
        this.setState({
            showSummaryPage: true
        });
    }
    
    onGoToMapPage() {
        if (this.props.onGoToMapPage) {
            this.props.onGoToMapPage();
        }
        this.setState({
            showMapPage: true,
            showSummaryPage: false,
            showWorkflow: true
        });
    }

    getJobDetails(jobId) {
        this.props.getJobDetails(jobId);
    }

    static getJobIntervalId(pushJobId, intervalId, getJobDetails) {
        if (intervalId) {
            clearInterval(intervalId);
        }
        if (pushJobId) {
            let intervalId = setInterval(() => {
                getJobDetails(pushJobId);
            }, 5000);
            return intervalId;
        }
    }

    resetJobSync(pushJobId) {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        if (pushJobId) {
            let intervalId = setInterval(() => {
                this.props.getJobDetails(pushJobId);
            }, 5000);
            this.setState({ intervalId });
        }
    }

    onEdit(stepNumber, stageNumber) {
        const fields = this.state.panSchema.fields;
        const rootField = getField(fields, '$');
        const childrenNames = rootField.childrenNames;
        this.setState({
            activeStage: childrenNames[stageNumber],
            showSummaryPage: false,
            jumpToStep: stepNumber,
            fromSummary: true
        });
    }

    onSetup(stageNumber) {
        const childrenNames = this.getRootChildrenNames();
        this.setState({
            activeStage: childrenNames[stageNumber],
            showSummaryPage: false
        });
    }

    onCancel(formData, formDataBackup) {
        let modal;
        if (!Pan.isEmpty(this.state.formState)) {
            modal = {
                id: "cancelWarningModal",
                title: unsavedChangesDialogTitle,
                type: 'Warning',
                message: unsavedChangesWarning,
                toggle: (e) => {
                    e.preventDefault();
                    hideModal("cancelWarningModal");
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    const childrenNames = this.getRootChildrenNames();
                    this.setState({
                        activeStage: childrenNames[0],
                        showSummaryPage: false
                    });
                    this.hideRecordEditor();
                },
                actions: [{
                    text: 'Cancel',
                    color: "secondary",
                    action: (e) => {
                        e.preventDefault();
                        hideModal("cancelWarningModal");
                        if (this.props.onCancel) {
                            this.props.onCancel();
                        }
                        const childrenNames = this.getRootChildrenNames();
                        this.setState({
                            activeStage: childrenNames[0],
                            showSummaryPage: false
                        });
                        this.hideRecordEditor();
                    }
                }, {
                    text: 'Save',
                    action: (e) => {
                        hideModal("cancelWarningModal");
                        this.onSubmit(formData, formDataBackup);
                    }
                }]
            };
        }
        if (modal) {
            showModal(modal);
        } else {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            const childrenNames = this.getRootChildrenNames();
            this.setState({
                activeStage: childrenNames[0],
                showSummaryPage: false
            });
            this.hideRecordEditor();
        }
    }

    fromEditToSummary() {
        this.setState({
            showSummaryPage: true
        });
    }

    onFormDataChange(data, formState) {
        this.setState({ ...data });
        if (!Pan.isEmpty(formState)) { //update workflow state only if any stage changed its formState. This formState will be reset on Save.
            this.setState({
                formState: formState
            });
        }
    }

    onAddRecord(record, configPath, isCommitAndPush) {
        if (isCommitAndPush) {
            if (this.props.onAddAndPushRecord) {
                this.props.onAddAndPushRecord(record, configPath);
            }
        }
        else {
            if (this.props.onAddRecord) {
                this.props.onAddRecord(record, configPath);
            }
        }
    }

    onConfigureRecord() {
        let record = this.props.gridData ? this.props.gridData[0] : undefined;
        this.displayRecordEditor(record);
    }

    onEditRecord(record, backup, configPath, isCommitAndPush) {
        if (isCommitAndPush) {
            if (this.props.onEditAndPushRecord) {
                this.props.onEditAndPushRecord(record, backup, configPath);
            }
        }
        else {
            if (this.props.onEditRecord) {
                this.props.onEditRecord(record, backup, configPath);
            }
        }
    }

    onDeleteRecord(selected, configPath) {
        if (this.props.onDeleteRecord) {
            this.props.onDeleteRecord(selected, configPath);
        }
    }

    onCloneRecord(selected, configPath) {
        if (this.props.onCloneRecord) {
            this.props.onCloneRecord(selected, configPath);
        }
    }


    onMapPageNextButtonClick = () => {
        this.setState({
            showSummaryPage: true,
            showMapPage: false
        })
    }

    onMapPageCancelButtonClick = () => {
        this.setState({
            showSummaryPage: false,
            showMapPage: false,
            showWorkflow: false
        })
    }

    updateStateSchema(fieldName, prop, obj) {
        let fields = [...this.state.panSchema.fields];
        let thisField = getField(fields, fieldName);
        thisField[prop] = obj;
        this.setState({
            fields
        });
    }

    renderStage() {
        const fields = this.state.panSchema.fields;
        const rooFieldName = this.props.itemId || '$';
        const rootField = fields.find(function (fld) {
            return fld.name === rooFieldName;
        })
        const childrenNames = rootField.childrenNames;
        let containerLabelWidthSize = getPathValues(rootField, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;

        return childrenNames.map(name => {
            const thisField = getField(fields, name);
            let { 
                nextTextOnFinalActionStep, 
                nextTextDependsOnRedux, 
                nextTextDependsOnReduxId, 
                stageClassName = '',
                customValidationToSpecialSubmit
             } = thisField.uiHint;
            if (nextTextDependsOnRedux) {
                let pageData = this.props[nextTextDependsOnRedux] ? this.props[nextTextDependsOnRedux][nextTextDependsOnReduxId] : '';
                if (pageData) {
                    nextTextOnFinalActionStep = false;
                }
            }
            let showSteps = thisField.uiHint && thisField.uiHint.showSteps === undefined ? thisField.childrenNames && thisField.childrenNames.length > 1 : thisField.uiHint.showSteps;
            return (
                (name == this.state.activeStage) &&
                <Stage
                    id={this.props.recordConfigPath}
                    key={name}
                    ref={this.stages[name]}
                    activeStage={this.state.activeStage}
                    itemId={this.props.itemId || thisField.name}
                    className={"workflow-stage " + stageClassName}
                    schema={this.state.panSchema.schema}
                    fields={this.state.panSchema.fields}
                    containerLabelWidthSize={containerLabelWidthSize}
                    recordConfigPath={this.props.recordConfigPath}
                    isNewRecord={this.state.isNewRecord}
                    showSteps={showSteps}
                    formData={this.state.formData}
                    formDataBackup={this.state.formDataBackup}
                    onChange={this.onFormDataChange.bind(this)}
                    configLocation={this.props.configLocation}
                    formatFormData={true}
                    formatFormDataPostProcess={true}
                    singleStepForAdd={this.state.singleStepForAdd}
                    rbaPermission={this.props.rbaPermission}
                    locationPermission={this.props.locationPermission}
                    nextTextOnFinalActionStep={nextTextOnFinalActionStep || this.props.nextTextOnFinalActionStep}
                    customValidationToSpecialSubmit={customValidationToSpecialSubmit || this.props.customValidationToSpecialSubmit}
                    onSubmit={({ formData, formDataBackup }) => this.onSubmit(formData, formDataBackup)}
                    onSpecialSubmit={({ formData, formDataBackup }) => this.onSpecialSubmit(formData, formDataBackup)}
                    onGoToSummary={() => this.onGoToSummary()}
                    onGoToMapPage={() => this.onGoToMapPage()}
                    onBlur={(id, value) => console.log(`Touched ${id} with value ${value}`)}
                    onFocus={(id, value) => console.log(`Focused ${id} with value ${value}`)}
                    onError={() => console.log('errors')}
                    onCancel={() => this.onCancel()}
                    onNext={this.props.onNext}
                    fromEditToSummary={() => this.fromEditToSummary()}
                    backButtonCls={this.state.backButtonCls}
                    formState={this.state.formState}
                    actionMap={{
                        jumpToStep: this.jumpToStep
                    }}
                />
            );
        });
    }

    renderSummary() {
        const fields = this.state.panSchema.fields;
        const rooFieldName = this.props.itemId || '$';
        const rootField = fields.find(function (fld) {
            return fld.name === rooFieldName;
        });
        const childrenNames = rootField.childrenNames;
        const name = childrenNames[childrenNames.length - 1];
        const thisField = getField(fields, name);
        const hasActionsClass = thisField.uiHint.actions ? 'has-top-buttons' : '';
        return (
            <FormProvider value={this.state}>
                 <div id={name} className={classnames("workflow-stage d-flex flex-column border border-light", hasActionsClass)}>
                    <FormGroup>
                        <SummaryBuilder
                            name={name}
                            field={thisField}
                            fields={this.state.panSchema.fields}
                            schema={this.state.panSchema.schema}
                            onSubmit={this.onSubmit}
                            onSpecialSubmit={this.onSpecialSubmit}
                            onCancel={this.onCancel}
                            onEdit={this.onEdit}
                            onSetup={this.onSetup}
                            actionMap={{
                                jumpToStep: this.jumpToStep
                            }}
                            activeStage={this.state.activeStage}
                            formState={this.state.formState}
                            showSave={this.props.rbaPermission==='enable'}
                        />
                    </FormGroup>
                </div>
            </FormProvider>
        )
    }

    renderWorkflow = () => {
        return (
            <Card>
                <CardBody className="p-0">
                    {this.props.formLoading && <SpinnerWidget />}
                    {!this.state.showSummaryPage && this.renderStage()}
                    {this.state.showSummaryPage && this.renderSummary()}
                </CardBody>
            </Card>
        );
    }

    /***
     * WorkflowViewer contraints
     * 1. panSchema - pan schema 
     * 2. recordConfigPath - schema path for the root of the record form editor
     * 3. inputFields - input fields list to display inthe form
     */

    renderView() {
        let editorMode = this.props.editorMode || 'default';
        let gridActions = this.props.showConfigureActions ? [{
            text: 'Configure',
            callback: () => {
                if (this.props.prepareAddRecord) {
                    this.props.prepareAddRecord(this.onConfigureRecord.bind(this), this);
                } else {
                    this.onConfigureRecord();
                }
            }
        }, {
            text: 'Remove',
            avail: () => { 
                return !(this.props.gridData.length>0); 
            },
            callback: () => {
                let record = this.props.gridData ? this.props.gridData[0] : undefined;
                let modal = {
                    id: 'WorkflowViewerWarningModal',
                    title: _T(`${this.props.header}`),
                    type: 'Warning',
                    message: _T(`Do you really want to delete ${ record ? record['@name'] : ''}?`),
                    toggle: () => {
                        hideModal("WorkflowViewerWarningModal");
                    },
                    actions: [{
                        text: 'Yes',
                        color: "secondary",
                        action: () => {
                            this.onDeleteRecord(record);
                            hideModal("WorkflowViewerWarningModal");
                        }
                    }, {
                        text: 'No',
                        action: () => {
                            hideModal("WorkflowViewerWarningModal");
                        }
                    }]
                };
                showModal(modal);
            }
        }] : undefined;

        if (editorMode == 'modal') {
            // Showing grid viewer
            return (
                <React.Fragment>
                    <HLGrid
                        {...this.props}
                        displayRecordEditor={this.displayRecordEditor}
                        hideRecordEditor={this.hideRecordEditor}
                        gridActions={gridActions}
                        showDefaultCloneAction={false}
                    />
                    <Modal isOpen={this.state.showWorkflow} size='xl' fade centered className={'modal-form'}>
                        <ModalBody>{this.renderWorkflow()}</ModalBody>
                    </Modal>
                </React.Fragment>
            );
        } else {
            if (!this.state.showWorkflow) {
                // Showing grid viewer
                return (
                    <React.Fragment>
                        <HLGrid
                            {...this.props}
                            displayRecordEditor={this.displayRecordEditor}
                            hideRecordEditor={this.hideRecordEditor}
                            gridActions={gridActions}
                            showDefaultCloneAction={false}
                        />
                    </React.Fragment>
                );
            } else {
                // Showing form viewer
                return this.renderWorkflow()
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        )
    }
}

HLGrid.contextType = ExtraInfoContext;

WorkflowViewer.defaultProps = {
    gpcs:{}
};

if (process.env.NODE_ENV !== "production") {
    WorkflowViewer.propTypes = {
        panSchema:PropTypes.object,
        recordConfigPath:PropTypes.string,
        inputFields:PropTypes.array,
        /**
         * if mode=='workflow', showWorkFlow=true
         */
        mode:PropTypes.string,
        /**
         * shows the map page
         */
        showMapPage:PropTypes.bool,
        gpcs:PropTypes.shape({
            jobDetails:PropTypes.shape({
                jobStatus:PropTypes.string
            }),
            pushJobId:PropTypes.string
        }),
        pushJobId:PropTypes.string,
        singleStepForAdd:PropTypes.bool,
        /**
         * shows the workflow using stage and wizard widgets
         */
        showWorkflow:PropTypes.bool,
        jobDetails:PropTypes.shape({
            jobStatus:PropTypes.string
        }),
        /**
         * Data for map page
         */
        mapPageData:PropTypes.arrayOf(PropTypes.shape({
            name:PropTypes.string,
            status:PropTypes.PropTypes.shape({
                value:PropTypes.string
            })
        })),
        itemId:PropTypes.string,
        onShowWorkflow:PropTypes.func,
        onHideWorkflow:PropTypes.func,
        onGoToMapPage:PropTypes.func,
        getJobDetails:PropTypes.func,
        onCancel:PropTypes.func,
        onAddAndPushRecord:PropTypes.func,
        onAddRecord:PropTypes.func,
        /** 
         * gridData added by the 
         */
        gridData:PropTypes.array,
        onEditAndPushRecord:PropTypes.func,
        onEditRecord:PropTypes.func,
        onDeleteRecord:PropTypes.func,
        onCloneRecord:PropTypes.func,
        containerLabelWidthSize:PropTypes.string,
        configLocation:PropTypes.object,
        rbaPermission:PropTypes.string,
        locationPermission:PropTypes.string,
        /**
         * Text to be displayed on button
         * on final step eg:"save","submit" etc
         */
        nextTextOnFinalActionStep:PropTypes.string,
        hideEditToSummary:PropTypes.bool,
        /**
         * if true, shows spinner widget while form is loading
         */
        formLoading:PropTypes.bool,
        /**
         * editor mode can be "default" or "modal"
         * "modal" will show the entire workFlow in a modal
         */
        editorMode:PropTypes.string,
        /**
         * add a configure and remove button
         */
        showConfigureActions:PropTypes.bool,
    }}