import React from "react";
import TPLBaseViewer from '../../template/TPLBaseViewer';
import getTPLRecordViewer from "../../template/TPLRecordViewer";
import { ViewerManager, Pan } from 'ui-lib';
import ApplicationIcon from './ApplicationIcon';

const _T = str => str;
const clientLessAppFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            disableOnEditRecord: true
        }
    },
    {
        name: '$.description',
        uiHint: {
            fieldLabel: _T('Application Description')
        }
    },
    {
        name: '$.app-icon',
        uiHint: {
            emptyText: 'None',
            // xtype: 'pan-customizefilefield',
            // contentType: 'image',
            // filenameValidation: {
            //     // https://regex101.com/r/eV7jO9/3
            //     regex: /^[\w\W]+\.(jpeg|png|jpg|gif|tif|tiff)/i,
            //     failureMessage: _T('Please select a file with extension: jpeg, png, jpg, gif, tif, or tiff.')
            // },
            fieldLabel: _T('Application Icon'),
            builder: (field) => {
                return () => {
                    return (
                        <ApplicationIcon field={field} />
                    )
                }
            }
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            '$.application-home-url',
            '$.description',
            '$.app-icon'
        ],
        uiHint: {
            labelWidthSize: 5
        }
    }
];

const clientLessAppColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.global-protect.clientless-app.entry'
}];

const viewConfig = {
    serviceName : 'Network/GlobalProtect/ClientlessApps',
    reduxId : 'clientLessApp',
    header : 'ClientLess App',
    columns : clientLessAppColumns,
    fields : clientLessAppFields,
    rbaPath: 'network.global-protect.clientless-apps',
    recordConfigPath : '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.global-protect.clientless-app.entry',
    className: 'ClientLessApp'
}
export default class ClientLessApp extends TPLBaseViewer {
	constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getTPLRecordViewer);