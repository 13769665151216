import { Pan } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str;

const fields = unionBy([{
    name: '$.action.choice',
    uiHint: {
        fieldLabel: _T('Action'),
        largeSelectionCount: 1
    }
}, {
    name: '$.protection.aggregate.profile',
    uiHint: {
        menuActions: [{
            atype: 'addRemoteRecordAction',
            text: _T("DoS Protection"),
            componentItemId: '$.protection.aggregate.profile',
            recordFieldName: '$.protection.aggregate.profile',
            treePath: "Objects/Security Profiles/DoS Protection",
            recordFormPostLoadChangeConfig: [{
                copyFromData: 'aggregate',
                copyToPath: '$.type',
                disabled: true
            }]
        }]
    }
}, {
    name: '$.protection.classified.profile',
    uiHint: {
        menuActions: [{
            atype: 'addRemoteRecordAction',
            text: _T("DoS Protection"),
            componentItemId: '$.protection.classified.profile',
            recordFieldName: '$.protection.classified.profile',
            treePath: "Objects/Security Profiles/DoS Protection",
            recordFormPostLoadChangeConfig: [{
                copyFromData: 'classified',
                copyToPath: '$.type',
                disabled: true
            }]
        }]
    }
}, {
    // Create a new security rule
    name: '$',
    childrenNames: [
        '$.general-box',
        '$.tabs-box'
    ]
}, {
    // Create a new security rule
    name: '$.tabs-box',
    childrenNames: [
        '$.source-box',
        '$.destination-box',
        '$.option-protection-box'
    ],
    uiHint: {
        builder: 'TabBuilder',
        deferredRender: false
    }
}, {
    name: '$.source-box',
    childrenNames: [
        '$.from.member',
        'source-with-negate',
        '$.source-user.member'
    ],
    uiHint: {
        layout: [
            {'$.from.member': 'col-10'},
            {'source-with-negate': 'col-12'},
            {'$.source-user.member': 'col-10'},
        ],
        fieldLabel: _T('Source')
    }
}, {
    name: 'action-schedule-protection',
    childrenNames: [
        '$.action.choice',
        // '$.schedule',
        '$.log-setting',
        '$.protection.aggregate',
        '$.protection.classified'
    ],
    uiHint: {
        fieldLabel: _T('Option/Protection')
    }
}, {
    name: '$.option-protection-box',
    childrenNames: [
        '$.service.member',
        'action-schedule-protection'
    ],
    uiHint: {
        layout: [
            {'$.service.member': 'col-6', 'action-schedule-protection': 'col-6'},
        ],
        fieldLabel: _T('Option/Protection')
    }
}], commonFields, 'name');


const columns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    {
        headerName: 'Source',
        children: [
            Pan.apply({}, {}, columnConfig.if_from),
            Pan.apply({}, {}, columnConfig.source_negate),
            Pan.apply({}, {}, columnConfig.source_user)
        ]
    },
    {
        headerName: 'Destination',
        children: [
            Pan.apply({}, {}, columnConfig.if_to),
            Pan.apply({}, {}, columnConfig.destination_negate)
        ]
    },
    Pan.apply({}, {}, columnConfig.service),
    Pan.apply({}, {}, columnConfig.dos_action),
    {
        headerName: 'Protection',
        children: [
            Pan.apply({}, {}, columnConfig.aggregate),
            Pan.apply({}, {}, columnConfig.classified)
        ]
    },
    Pan.apply({}, {}, columnConfig.schedule),
    Pan.apply({}, {}, columnConfig.log_setting),
    Pan.apply({}, {}, columnConfig.description)
];
export default class DosPolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/DoSPreRules';
    reduxId = 'doSPreRules';
    header = 'DoS Rules';
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.dos.rules.entry';
    rbaPath = 'policies.dos-rulebase';
}
