import React from "react";
import PropTypes from "prop-types";
import './AsyncSelectWidget.scss';
import { BaseSelectWidget } from "../BaseSelectWidget";

export class AsyncSelectWidget extends React.Component {
    constructor(props) {
        super(props);
        this.select = React.createRef();
    }

    focus = () => {
        if (this.select && this.select.current.focus) {
            this.select.current.focus();
        }
    }

    render() {
        let widgetProps = {
            widgetType: 'async'
        };
        return (
            <React.Fragment>
                <BaseSelectWidget
                    ref={this.select}
                    {...this.props}
                    {...widgetProps}
                />
            </React.Fragment>
        );
    }

}

AsyncSelectWidget.defaultProps = {
    autoFocus: false,
};

if (process.env.NODE_ENV !== "production") {
    AsyncSelectWidget.propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value:PropTypes.string,
            label:PropTypes.string
        })),
        loadOptions: PropTypes.func.isRequired,
        query: PropTypes.oneOf(['local','remote']),
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        multiple: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        selectKey: PropTypes.number,
        id: PropTypes.string,
        name: PropTypes.string,
        styles: PropTypes.object,
        menuPortalTarget: PropTypes.object,
        error: PropTypes.string,
        showErrors: PropTypes.bool,
        customProps: PropTypes.object,
        MenuList: PropTypes.func,
        creatable: PropTypes.bool,
        isClearable: PropTypes.bool,
        searchable: PropTypes.bool,
    };
}
