import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MapListSwitchWidget.scss';

export class MapListSwitchWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    onClick = () => {
        if (this.state.value === 'map') {
            if (this.props.onClick) {
                this.props.onClick('list');
            }
            this.setState({
                value: 'list'
            });
        }
        if (this.state.value === 'list') {
            if (this.props.onClick) {
                this.props.onClick('map');
            }
            this.setState({
                value: 'map'
            });
        }
    }

    render() {
        if (this.state.value === 'map') {
            return (
                <span className={this.props.className} >
                    <div className='blueMapSwitchButton'></div>
                    <div className='whiteListSwitchButton' onClick={this.onClick} ></div>
                </span>
            )
        } 
        if (this.state.value === 'list') {
            return (
                <span className={this.props.className} >
                    <div className='whiteMapSwitchButton' onClick={this.onClick} ></div>
                    <div className='blueListSwitchButton'></div>
                </span>
            )
        }
    }
}
MapListSwitchWidget.defaultProps = {
    value: 'map',
};

MapListSwitchWidget.propTypes = {
    value: PropTypes.string.isRequired,
};