import * as React from 'react';
import * as moment from 'moment';
import Calendar from 'rc-calendar';
import RcDatePicker from 'rc-calendar/lib/Picker';
import classNames from 'classnames';
import {interopDefault} from './utils';
import InputIcon from './InputIcon';
import closeCircle from '../../../../../images/icon-close-circle.svg';

function formatValue(value, format) {
  return (value && value.format(format)) || '';
}

class WeekPicker extends React.Component {
  static defaultProps = {
    format: 'gggg-wo',
    allowClear: true,
  };

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps || 'open' in nextProps) {
      const state = {};
      if ('value' in nextProps) {
        state.value = nextProps.value;
      }
      if ('open' in nextProps) {
        state.open = nextProps.open;
      }
      return state;
    }
    return null;
  }

  constructor(props) {
    super(props);
    const value = props.value || props.defaultValue;
    if (value && !interopDefault(moment).isMoment(value)) {
      throw new Error(
        'The value/defaultValue of WeekPicker must be ' +
          'a moment object after `antd@2.0`, see: https://u.ant.design/date-picker-value',
      );
    }
    this.state = {
      value,
      open: props.open,
    };
  }

  componentDidUpdate(_, prevState) {
    if (!('open' in this.props) && prevState.open && !this.state.open) {
      this.focus();
    }
  }

  saveInput = (node) => {
    this.input = node;
  };

  weekDateRender = (current) => {
    const selectedValue = this.state.value;
    const { prefixCls } = this;
    const { dateRender } = this.props;
    const dateNode = dateRender ? dateRender(current) : current.date();
    if (
      selectedValue &&
      current.year() === selectedValue.year() &&
      current.week() === selectedValue.week()
    ) {
      return (
        <div className={`${prefixCls}-selected-day`}>
          <div className={`${prefixCls}-date`}>{dateNode}</div>
        </div>
      );
    }
    return <div className={`${prefixCls}-date`}>{dateNode}</div>;
  };

  handleChange = (value) => {
    if (!('value' in this.props)) {
      this.setState({ value });
    }
    this.props.onChange(value, formatValue(value, this.props.format));
  };

  handleOpenChange = (open) => {
    const { onOpenChange } = this.props;
    if (!('open' in this.props)) {
      this.setState({ open });
    }

    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  clearSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleChange(null);
  };

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  renderFooter = (...args) => {
    const { prefixCls, renderExtraFooter } = this.props;
    return renderExtraFooter ? (
      <div className={`${prefixCls}-footer-extra`}>{renderExtraFooter(...args)}</div>
    ) : null;
  };

  render() {
    const {
      prefixCls: customizePrefixCls,
      className,
      disabled,
      pickerClass,
      popupStyle,
      pickerInputClass,
      format,
      allowClear,
      locale,
      localeCode,
      disabledDate,
      style,
      onFocus,
      onBlur,
      id,
      suffixIcon,
      defaultPickerValue,
    } = this.props;

    const prefixCls = 'pan-calendar';
    // To support old version react.
    // Have to add prefixCls on the instance.
    // https://github.com/facebook/react/issues/12397
    this.prefixCls = prefixCls;

    const { open, value: pickerValue } = this.state;
    if (pickerValue && localeCode) {
      pickerValue.locale(localeCode);
    }

    const placeholder =
        'placeholder' in this.props ? this.props.placeholder : locale.lang.placeholder;

    const calendar = (
        <Calendar
            showWeekNumber
            dateRender={this.weekDateRender}
            prefixCls={prefixCls}
            format={format}
            locale={locale.lang}
            showDateInput={false}
            showToday={false}
            disabledDate={disabledDate}
            renderFooter={this.renderFooter}
            defaultValue={defaultPickerValue}
        />
    );
    const clearIcon =
        !disabled && allowClear && this.state.value ? (
            <i className={`${prefixCls}-picker-clear`} onClick={this.clearSelection}><img width={'12px'} height={'12px'} alt="close-circle" src={closeCircle}/></i>
        ) : null;

    const inputIcon = <InputIcon suffixIcon={suffixIcon} prefixCls={prefixCls} />;

    const input = ({ value }) => (
        <span style={{ display: 'inline-block', width: '100%' }}>
        <input
            ref={this.saveInput}
            disabled={disabled}
            readOnly
            value={(value && value.format(format)) || ''}
            placeholder={placeholder}
            className={pickerInputClass}
            onFocus={onFocus}
            onBlur={onBlur}
        />
          {clearIcon}
          {inputIcon}
      </span>
    );
    return (
        <span className={classNames(className, pickerClass)} style={style} id={id}>
        <RcDatePicker
            {...this.props}
            calendar={calendar}
            prefixCls={`${prefixCls}-picker-container`}
            value={pickerValue}
            onChange={this.handleChange}
            open={open}
            onOpenChange={this.handleOpenChange}
            style={popupStyle}
        >
          {input}
        </RcDatePicker>
      </span>
    );
  }
}

export default WeekPicker;
