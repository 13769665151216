import { Pan } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str;

const fields = unionBy([{
    // Create a new rule
    name: '$.tabs-box',
    childrenNames: [
        '$.source-box',
        '$.destination-box',
        '$.service-category-box',
        'action-box'
    ],
    uiHint: {
        builder: 'TabBuilder',
        deferredRender: false
    }
}, {
    // Create a new rule
    name: '$',
    childrenNames: [
        '$.general-box',
        '$.tabs-box'
    ]
}, {
    name: '$.source-box',
    childrenNames: [
        '$.from.member',
        'source-with-negate',
        '$.source-user.member',
        '$.hip-profiles.member'
    ],
    uiHint: {
        layout: [
            { '$.from.member': 'col-10' },
            { 'source-with-negate': 'col-12' },
            { '$.source-user.member': 'col-10' },
            { '$.hip-profiles.member': 'col-10' }
        ],
        fieldLabel: _T('Source')
    }
}, {
    name: 'action-box',
    childrenNames: [
        '$.authentication-enforcement',
        '$.timeout',
        'log-setting-box'
    ],
    uiHint: {
        fieldLabel: _T("Actions")
    }
}, {
    name: 'log-setting-box',
    childrenNames: [
        '$.log-authentication-timeout',
        '$.log-setting'
    ],
    uiHint: {
        builder: 'FieldSetBuilder',
        fieldLabel: _T('Log Setting')
    }
}, {
    name: '$.authentication-enforcement',
    uiHint: {
        menuActions: [{
            atype: 'addRemoteRecordAction',
            text: _T('Authentication'),
            componentItemId: '$.authentication-enforcement',
            recordFieldName: '$.authentication-enforcement',
            treePath: "Objects/Authentication"
        }]
    }
}, {
    name: '$.hip-profiles.member.*',
    uiHint: {
        menuActions: [{
            atype: 'addRemoteRecordAction',
            text: _T('HIP Profile'),
            componentItemId: '$.hip-profiles.member',
            treePath: "Objects/GlobalProtect/HIP Profiles"
        }]
    }
}, {
    name: '$.timeout',
    uiHint: {
        // unitText: Pan.base.Constants.unit.minutes
    }
}, {
    name: '$.log-authentication-timeout',
    uiHint: {
        fieldLabel: _T('Log Authentication Timeouts'),
        labelWidthSize: '2.5'
    }
}], commonFields, 'name');

const columns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    {
        headerName: 'Source',
        children: [
            Pan.apply({}, {}, columnConfig.from),
            Pan.apply({}, {}, columnConfig.source_negate),
            Pan.apply({}, {}, columnConfig.source_user),
            Pan.apply({}, {}, columnConfig.hip_profile)
        ]
    },
    {
        headerName: 'Destination',
        children: [
            Pan.apply({}, {}, columnConfig.to),
            Pan.apply({}, {}, columnConfig.destination_negate)
        ]
    },
    Pan.apply({}, {}, columnConfig.category),
    Pan.apply({}, {}, columnConfig.authentication_enforcement)
];

export default class AuthPolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/AuthenticationPreRules';
    reduxId = 'authenticationPreRules';
    header = 'Authentication Rules';
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.authentication.rules.entry';
    rbaPath = 'policies.authentication-rulebase';
}
