import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './GridLayoutPanel.scss';
import React from 'react';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import { Observable } from 'rxjs';


const GridLayout = WidthProvider(ReactGridLayout);
export default class GridLayoutPanel extends React.Component {
    constructor(props) {
        super(props);
        this.emitter = null;
        this.resizeObservable = Observable.create((observable) => {
            this.emitter = observable;
        });
    }

    onResizeStop = (args) => {
        if (this.emitter) {
            this.emitter.next(args);
        }
    }

    createWidgets = (widgets, resizeObservable) => {
        if (widgets && widgets.length > 0) {
            return widgets.map((widget) => {
                const { props, Widget } = widget;
                let dataGrid;
                if (widget.fullscreen) {
                    const mainframe = document.getElementById('mainframe');
                    let height = Math.floor(mainframe.clientHeight / 50);
                    dataGrid = { x: 0, y: 0, w: 12, h: height };
                } else {
                    dataGrid = { x: widget.x, y: widget.y, w: widget.width, h: widget.height };
                }
                return (
                    <div className='widget-container bg-light'
                        key={widget.id}
                        data-grid={dataGrid}
                    >
                        <Widget {...props} resizeObservable={resizeObservable} />
                    </div>
                );
            });
        };
    }

    render() {
        let { widgets, isDraggable, isResizable } = this.props;
        isDraggable = isDraggable === undefined ? true : isDraggable;
        isResizable = isResizable === undefined ? true : isResizable;
        return (
            <GridLayout
                className='grid-layout'
                draggableHandle={'.grid-layout-panel>.panel-header'}
                cols={12}
                rowHeight={24}
                isDraggable={isDraggable}
                isResizable={isResizable}
                measureBeforeMount={true}
                compactType="vertical"
                margin={[24, 24]}
                onResizeStop={(args) => this.onResizeStop(args)}
            >
                {this.createWidgets(widgets, this.resizeObservable)}
            </GridLayout>
        );
    }
}
