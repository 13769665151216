import React from "react";
import _ from 'lodash';
import {
    getStoreState,
    jsonPath,
    FormFeedbackWidget,
    Pan,
    getPathValue,
    validationRulesMap,
    arrayRenderer,
    isUserRolePemission
} from 'ui-lib';
import { MOBILE_USERS_TPL, GPCS_COMMON_TPL } from 'service-lib';
import GPCSWorkflowBaseViewer from '../main/GPCSWorkflowBaseViewer';
import {
    INJECTED_REC_ID,
    getStore,
    SERVER_ERROR
} from 'ui-lib';
import {
    generateMUDefaultConfigCerts,
    fetchMUFQDN,
    fetchGPCSStatus,
    getMobileUsersNetworkDetails,
    //SAML_SIGNING_CERT_NAME,
    pullingProvisionStatus,
    getSSLDecryption,
    fetchServiceInfra,
    checkMobileUserPortalFqdn
} from '../main/actions';
import './MobileUsers.scss';
import SamlIdpInfo from './SamlIdpInfo';
import SamlSetupGuides from './SamlSetupGuides';
import PrismaSaasSettingsBuilder from './PrismaSaasSettingsBuilder';
import TestInstructionsBuilder from './TestInstructionsBuilder';

const _T = str => str;
const ipPoolsDefaultValue = [
    {
        "@name": "worldwide",
        "ip-pool": {
            member: ["100.127.0.0/16"]
        }
    }
];
const dnsServerDefaultValue = [
    {
        "@name": "worldwide",
        "enable-use-internal-as-public": "no"
    }
];

const doFqdnAvailCheck = (formData, handler) => {
    if (!_.isFunction(handler)) {
        console.error(`handler is not a function`);
        return;
    }
    const store = getStore();
    const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const fqdnAvail = _.get(state, 'gpcs.fqdnAvail');
        if (fqdnAvail && fqdnAvail.fqdn === formData['@name']) {
            unsubscribe();
            if (fqdnAvail.avail === 'ok') {
                handler();
            } else {
                store.dispatch({
                    type: SERVER_ERROR,
                    errorMessage: {
                        msg: _T('Portal Address is not available.')
                    },
                });
            }
        }
    });
    store.dispatch(checkMobileUserPortalFqdn('fqdnAvail', formData['@name']));
};

/**
 * give a config status it returns path for image
 * @todo - this method is written in Dashboard/util.js we need to make it part of framework or try to reuse it
 *
 * @param status {string} Warning | Ok | Error
 * @returns {string}
 */
const getIconSrc = (status) => {
    let imageSrc = '';
    if (status === 'OK') {
        imageSrc = "/images/dashboard/icon_green.svg";
    } else if (status === 'Warning') {
        imageSrc = "/images/dashboard/icon_yellow.svg";
    } else if (status === 'Error') {
        imageSrc = "/images/dashboard/icon_red.svg";
    }
    return imageSrc;
}
export const mobileUsersFields = [
    {
        name: '$',
        childrenNames: [
            '$.stage_1',
            '$.stage_2',
            '$.stage_3',
            '$.stage_4',
            '$.stage_5',
            '$.stage_summary'
        ],
        uiHint: {
            labelWidthSize: 3
        }
    },
    {
        name: '$.stage_summary',
        childrenNames: [
            '$.stage_1',
            '$.stage_2',
            '$.stage_3',
            '$.stage_4',
            '$.stage_5',
        ],
        uiHint: {
            builder: 'SummaryBuilder',
            stages: {
                '$.stage_1': 'stage1',
                '$.stage_2': 'stage2',
                '$.stage_3': 'stage3',
                '$.stage_4': 'stage4',
                '$.stage_5': 'stage5'
            },
            fieldLabel: _T('Stage 1 / Summary'),
            fieldDescription: _T(''),
            actions: [
                {
                    // supported actions: jumpToStep (expand others later ...)
                    atype: 'jumpToStep',
                    text: "Test Instructions",
                    params: ['$.stage_1', '$.stage1_step3']  // stage 1, step 3 for Test Instructions
                }
            ]
        }
    },
    {
        name: '$.stage_1',
        childrenNames: [
            '$.stage1_step1',
            '$.stage1_step2',
            '$.stage1_step3'
        ],
        recordConfigPath: '$.stage1',
        uiHint: {
            buttonsAvail: {
                overview: function () {
                    return getPathValue(getStoreState(), 'gpcs.muProvisionStatus') !== 'init';
                }
            },
            stageTitle: 'Environment Setup',
            stageNumber: '1',
            showSteps: false,
            customValidationToSpecialSubmit: (currentStep, formData, _specialSubmit) => {
                if (currentStep !== 0) {
                    _specialSubmit();
                    return;
                }
                doFqdnAvailCheck(formData, _specialSubmit);
            },
            onNext: (currentStep, formData, formDataBackup, isNewRecord, _next) => {
                if (currentStep !== 0) {
                    _next();
                    return;
                }
                doFqdnAvailCheck(formData, _next);
            }
        }
    },
    {
        name: '$.stage1_step2',
        uiHint: {
            buttonsAvail: {
                overview: false,
                back: false,
                next: false
            },
            stepTitle: 'Initial Setup',
            builder: 'MapPageBuilder',
            hideStepHeader: true,
            showWideForm: true
        }
    },
    {
        name: '$.stage1_step3',
        uiHint: {
            showStageNumber: false,
            builder: (field) => {
                return () => {
                    return (
                        <TestInstructionsBuilder />
                    )
                }
            }
        }
    },
    {
        name: '$.stage_2',
        recordConfigPath: '$.stage2',
        childrenNames: [
            '$.stage2_step1', '$.stage2_step2'
        ],
        uiHint: {
            jumpToSummaryFromStep: 1,
            builder: 'ContainerBuilder',
            stageTitle: 'User Authentication',
            stageNumber: '2',
            showSteps: false,
            fieldDescription: _T('')
        }
    },
    {
        name: '$.stage_3',
        recordConfigPath: '$.stage3',
        childrenNames: [
            '$.stage3_step1'
        ],
        uiHint: {
            jumpToSummaryFromStep: 1,
            builder: 'ContainerBuilder',
            dataInStore: () => {
                return getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails');
            },
            stageTitle: 'SSL Decryption',
            stageNumber: '3',
            fieldDescription: _T(''),
            stageCollapseStyle: false
        }
    },
    {
        name: '$.stage3_step_ssl_separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('SSL Decryption Rules')
        }
    },
    {
        name: '$.stage3_step_cert_separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('SSL Decryption Certificates')
        }
    },
    {
        name: '$.stage3_step1',
        childrenNames: [
            '$.stage3_step_ssl_separator',
            '$.SSLDecryption',
            '$.stage3_step_cert_separator',
            '$.forward-trust-cert',
            '$.forward-untrust-cert'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            stepTitle: _T('SSL Decryption'),
            buttonsAvail: {
                next: false,
                save: isUserRolePemission
            },
            fieldDescription: _T('Quickly and easily enable SSL Decryption using the default certificates generated for your Prisma Access instance, or import certificates from your enterprise PKI.')
        }
    },
    {
        name: '$.SSLDecryption',
        uiHint: {
            builder: 'ViewerDisplayActionBuilder',
            viewerName: 'DecryptionPolicies',
            actionText: 'Manage SSL decryption rules',
            showSummaryLabel: false,
            viewerProps: {
                windowSize: 'xl',
                formSize: 'xl',
                displayCancel: true,
                editorMode: 'modal',
                displayMode: 'simple',
                title: 'Decryption Rules'
            },
            actionProps: {
                className: 'indent-align-col-form-label'
            }
        }
    },
    {
        name: '$.forward-trust-cert',
        uiHint: {
            builder: 'ViewerDisplayBuilder',
            viewerName: 'ForwardTrustCert',
            showSummaryLabel: true,
            useStoreLabel: 'Forward Trust',
            getDisplayValue: (formData, fieldName) => {
                const cert = getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails.forward-trust-certificate');
                let certName = ''
                if (cert['ecdsa']) {
                    certName = cert['ecdsa'] + ' (ECDSA)'
                }
                if (cert['rsa']) {
                    if (certName === '') {
                        certName = cert['rsa'] + ' (RSA)'
                    } else {
                        certName = certName + ' / ' + cert['rsa'] + ' (RSA)'
                    }
                }
                return certName
            },
            viewerProps: {
                configLocation: {
                    tpl: {
                        name: GPCS_COMMON_TPL
                    }
                }
            }
        }
    }, {
        name: '$.forward-untrust-cert',
        uiHint: {
            builder: 'ViewerDisplayBuilder',
            showSummaryLabel: true,
            viewerName: 'ForwardUntrustCert',
            useStoreLabel: 'Forward Untrust',
            getDisplayValue: (formData, fieldName) => {
                const cert = getPathValue(getStoreState(), 'gpcs.sslDecryptionDetails.forward-untrust-certificate');
                let certName = '';
                if (cert['ecdsa']) {
                    certName = cert['ecdsa'] + ' (ECDSA)'
                }
                if (cert['rsa']) {
                    if (certName === '') {
                        certName = cert['rsa'] + ' (RSA)'
                    } else {
                        certName = certName + ' / ' + cert['rsa'] + ' (RSA)'
                    }
                }
                return certName
            },
            viewerProps: {
                configLocation: {
                    tpl: {
                        name: GPCS_COMMON_TPL
                    }
                }
            }
        }
    }, {
        name: 'stage2-config-idp-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Configure your IdP')
        }
    }, {
        name: 'stage2-saml-idp-profile-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Configure Prisma Access (SAML service provider)')
        }
    }, {
        name: 'stage2-saml-attribute-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('SAML Attributes')
        }
    }, {
        name: 'stage2-users-n-logout-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Authorize')
        }
    }, {
        name: '$.stage2_step1',
        childrenNames: [
            'stage2-config-idp-separator',
            '$.stage2.saml-certificate',
            'SamlIdpInfo',
            'SamlSetupGuides',
            'stage2-saml-idp-profile-separator',
            '$.stage2.saml-idp-profile',
            'stage2-saml-attribute-separator',
            '$.stage2.username-attribute',
            '$.stage2.usergroup-attribute',
            'stage2-users-n-logout-separator',
            // '$.stage2.enable-single-logout',
            '$.stage2.allow-list'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            stepTitle: 'User Authentication',
            fieldDescription: _T("Prisma Access provides enterprise authentication for your mobile users using SAML. You must configure Prisma Access (the SAML service provider) and your SAML identity provider (IdP) to work together to authenticate your users."),
            buttonsAvail: {
                next: false,
                save: isUserRolePemission,
                overview: true,
            },
        }
    },
    {
        name: '$.stage2_step2',
        childrenNames: [
            '$.stage2.local-auth',
            'stage2-test-users'
        ],
        uiHint: {
            stepTitle: _T('Temporary test users'),
            hideEditInSummary: true
        }
    },
    {
        name: '$.stage2.local-auth',
        uiHint: {
            fieldLabel: _T('Switch authentication to Temporary test users'),
            displayLabel: _T('Authentication Method'),
            getDisplayValue: function (formData) {
                let data = formData && formData.stage2 && formData.stage2['local-auth'];
                return data === 'yes' ? 'Temporary test users' : 'SAML';
            },
            useLinkInSummary: {
                atype: 'jumpToStep',
                text: (formData, field) => {
                    let data = formData && formData.stage2 && formData.stage2['local-auth'];
                    const val = data === 'yes' ? 'SAML' : 'Temporary test users';
                    return 'Switch authentication to ' + val
                },
                params: ['$.stage_2', '$.stage2_step2']
            }
        }
    },
    {
        name: 'stage2-test-users',
        uiHint: {
            fieldLabel: _T('Temporary test users'),
            labelWidthSize: 3,
            builder: 'ViewerDisplayBuilder',
            viewerName: 'LocalUsers',
            viewerProps: {
                editorMode: 'modal',
                displayMode: 'simple',
                hasGridFilter: false,
                windowSize: 'lg',
                title: 'Test User',
                minRows: 1,
                configLocation: {
                    tpl: {
                        name: MOBILE_USERS_TPL,
                    },
                },
            },
            showHelpString: 'Create temporary test user accounts to test the environment and make sure secure access to internet is available. Temporary test users do not have access to the corporate network.',
        },
    },
    {
        name: '$.stage_4',
        recordConfigPath: '$.stage4',
        childrenNames: [
            '$.stage4_step1'
        ],
        uiHint: {
            jumpToSummaryFromStep: 2,
            stageTitle: 'Clientless VPN',
            stageNumber: '4',
            stageCollapseStyle: false

        }
    },
    {
        name: '$.stage4_step1',
        childrenNames: [
            '$.stage4.enable-clientless-vpn-separator',
            '$.stage4.enable-clientless-vpn',
            '$.stage4.choice',
            '$.stage4_step2_separator',
            '$.stage4_step2'
        ],
        uiHint: {
            buttonsAvail: {
                next: false,
                save: isUserRolePemission
            },
            fieldLabel: _T('Clientless VPN'),
            stepTitle: 'Clientless VPN',
            fieldDescription: _T("Clientless VPN provides secure remote access to common enterprise web applications for users on endpoints that do not have the GlobalProtect app, such as partners, contractors, or employees using personal devices. To enable Clientless VPN you must configure SAML authentication, either using your existing Prisma Access SAML configuration, creating a new SAML configuration, or by leveraging an existing Prisma SaaS SAML configuration. You can then specify the apps and application groups that you want to publish for your Clientless VPN users to access.")
        }
    },
    {
        name: '$.stage4.enable-clientless-vpn-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Settings')
        }
    },
    {
        name: '$.stage4_step2_separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Publish Applications')
        }
    },
    {
        name: '$.stage4.enable-clientless-vpn',
        uiHint: {
            fieldLabel: _T('Enable Clientless VPN'),
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS',
        childrenNames: [
            '$.stage4.configure-SAML-Prisma-SaaS-separator',
            'apiKeyViewer',
            '$.stage4.SAML-Prisma-SaaS.settings',
            '$.stage4.configure-SAML-Prisma-SaaS.saml-idp-profile-separator',
            '$.stage4.SAML-Prisma-SaaS.saml-idp-profile',
            '$.stage4.clientless-SAML-Prisma-SaaS.saml-idp-profile-separator',
            '$.stage4.SAML-Prisma-SaaS.username-attribute',
            '$.stage4.SAML-Prisma-SaaS.usergroup-attribute',
            '$.stage4.clientless-SAML-Prisma-SaaS.saml-authorize-separator',
            // '$.stage4.SAML-Prisma-SaaS.enable-single-logout',
            '$.stage4.SAML-Prisma-SaaS.allow-list.member'
        ]
    },
    {
        name: '$.stage4.clientless-SAML-Prisma-SaaS.saml-authorize-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Authorize')
        }
    },
    {
        name: '$.stage4.configure-SAML-Prisma-SaaS-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Configure your Prisma Saas')
        }
    },
    {
        name: 'apiKeyViewer',
        uiHint: {
            builder: 'ViewerDisplayBuilder',
            viewerName: 'APIKeyForMU',
            viewerProps: {
                configLocation: {
                    extension: {
                        gpcs: {
                            name: "gpcs"
                        }
                    }
                }
            }
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS.settings',
        uiHint: {
            fieldLabel: 'Configure Saas',
            hideLabel: false,
            builder: () => {
                return PrismaSaasSettingsBuilder;
            }
        }
    },
    {
        name: '$.stage4.configure-SAML-Prisma-SaaS.saml-idp-profile-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Configure your Prisma Access')
        }
    },
    {
        name: '$.stage4.clientless-SAML-Prisma-SaaS.saml-idp-profile-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('SAML Attributes')
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS.usergroup-attribute',
        uiHint: {
            placeholder: _T('Enter a usergroup attribute')
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS.username-attribute',
        uiHint: {
            placeholder: _T('Enter username attributes')
        }
    },
    {
        name: '$.stage4_step2',
        childrenNames: [
            '$.stage4.enable-custom-app-URL-address-bar',
            '$.stage4.applications',
            'clientlessAppManeger',
        ]
    },
    {
        name: '$.stage4.enable-custom-app-URL-address-bar',
        uiHint: {
            fieldLabel: _T('Allow application access by URL'),
            labelWidthSize: 0
        }
    },
    {
        name: 'clientlessAppManeger',
        childrenNames: [
            'manage_apps',
            'manage_apps_group',
        ],
        uiHint: {
            layout: [{
                'manage_apps': "offset-3 col-2",
                'manage_apps_group': "col-2"
            }]
        }
    },
    {
        name: 'manage_apps',
        uiHint: {
            builder: "LabelBuilder",
            disableLabel: true,
            manageViewer: {
                showViewer: true,
                viewerName: 'ClientLessApp',
                rbaPath: 'network.global-protect.clientless-apps',
                viewerProps: {
                    displayCancel: true,
                    title: _T('Clientless Applications'),
                    configLocation: {
                        tpl: {
                            name: MOBILE_USERS_TPL,
                            vsys: 'vsys1'
                        }
                    }
                },
                actionText: 'Manage Applications',
                actionProps: {
                    style: {margin: 8, marginLeft: 0}
                }
            }
        }
    },
    {
        name: 'manage_apps_group',
        uiHint: {
            builder: "LabelBuilder",
            disableLabel: true,
            manageViewer: {
                showViewer: true,
                viewerName: 'ClientLessAppGroup',
                rbaPath: 'network.global-protect.clientless-app-groups',
                viewerProps: {
                    displayCancel: true,
                    title: _T('Clientless Application Groups'),
                    configLocation: {
                        tpl: {
                            name: MOBILE_USERS_TPL,
                            vsys: 'vsys1'
                        }
                    }
                },
                actionText: 'Manage Application Groups',
                actionProps: {
                    style: {margin: 8, marginLeft: 0}
                }
            }
        }
    },
    {
        name: '$.stage4.applications.member',
        uiHint: {
            fieldLabel: _T('Applications')
        }
    },
    {
        name: '$.stage4.applications.member.*',
        minWidth: 200,
        uiHint: {
            groupOrder: ['clientless-app', 'clientless-app-group'],
            completionConfig: {
                actions: [{
                    text: 'Add Clientless App',
                    rbaPath: 'network.global-protect.clientless-apps',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('Clientless App'),
                        configLocation: {
                            tpl: {
                                name: MOBILE_USERS_TPL,
                                vsys: 'vsys1'
                            }
                        },
                    },
                    viewerName: 'ClientLessAppRecordViewer'
                },
                {
                    text: 'Add Clientless App Group',
                    rbaPath: 'network.global-protect.clientless-app-groups',
                    viewerProps: {
                        windowSize: 'lg',
                        title: _T('Clientless App Group'),
                        configLocation: {
                            tpl: {
                                name: MOBILE_USERS_TPL,
                                vsys: 'vsys1'
                            }
                        }
                    },
                    viewerName: 'ClientLessAppGroupRecordViewer'
                }]
            }
        }
    },
    {
        name: '$.stage_5',
        recordConfigPath: '$.stage5',
        childrenNames: [
            '$.stage5_step1',
            '$.stage5_step2',
            '$.stage5_step3'
        ],
        uiHint: {
            dataInStore: () => true,
            jumpToSummaryFromStep: 3,
            stageTitle: 'Access to Internal Resources',
            stageNumber: '5',
            stageCollapseStyle: true
        }
    }, {
        name: '$.stage5_step3',
        childrenNames: [
            'service_connections'
        ],
        uiHint: {
            fieldLabel: _T('Service Connections'),
            buttonsAvail: {
                next: false,
                back: true,
                save: isUserRolePemission
            },
            stepTitle: 'Service Connections',
            fieldDescription: _T('Enable secure access to your headquarters and data centers for all your Prisma Access users, whether they are mobile or at your remote networks by setting up Service Connections.'),
            hideEditInSummary: true,
            jumpToRouteFromSummary: true,
            jumpToRoute: '/configure/serviceconnections'
        }
    }, {
        name: 'service_connections',
        uiHint: {
            getDisplayValue: function () {
                return 'Add or edit service connections'
            },
            fieldLabel: '',
            builder: 'JumpToWithWarningBuilder',
            iconImage: '/images/dashboard/service_connection.svg',
            iconFooter: 'To add or view service connections',
            iconFooterForDisabled: 'To add or view service connections, please save your changes to configuration',
            jumpTo: '/configure/serviceconnections',
            jumpToButtonText: 'Add or View Service Connections',
            jumpToModalWarning: ['You are almost done configuring for your mobile users once you add your service connections.',
                'Before you move on to adding service connections, please save your changes to configuration.']
        }
    },
    {
        name: '$.stage5_step1',
        childrenNames: [
            '$.stage5'
        ],
        uiHint: {
            fieldLabel: _T('Network Settings'),
            stepTitle: 'Network Settings',
            fieldDescription: _T('Configure the network services. Define how Prisma Access assigns IP addresses to the mobile users in each region and what DNS servers to use to resolve internal and public domains.'),
            showWideForm: true
        }
    },
    {
        name: '$.stage5_step2',
        childrenNames: [
            'infra_settings'
        ],
        uiHint: {
            fieldLabel: _T('Infrastructure Settings'),
            stepTitle: 'Infrastructure Settings',
            fieldDescription: _T('To enable Prisma Access to route traffic between your mobile users, branch offices, and your HQ and data centers, you must provide an infrastructure subnet to use as the network backbone and set the DNS servers for Prisma Access to use to resolve internal domains.')
        }
    },
    {
        name: 'infra_settings',
        uiHint: {
            labelWidthSize: 3,
            fieldDescription: _T('To enable Prisma Access to route traffic between your mobile users, branch offices, and your HQ and data centers, you must provide an infrastructure subnet to use as the network backbone and set the DNS servers for Prisma Access to use to resolve internal domains.'),
            builder: 'ViewerDisplayBuilder',
            viewerName: 'ServiceInfrastructure',
            viewerProps: {
                editorMode: 'modal',
                displayMode: 'simple',
                isViewerDisplay: true,
                hasGridFilter: false,
                windowSize: 'lg',
                configLocation: {
                    extension: {
                        gpcs: {
                            name: "gpcs"
                        }
                    }
                }
            },
            displayLabel: '',
            getDisplayValue: function () {
                let serviceInfra = getPathValue(getStoreState(), 'gpcs.serviceInfrastructure.result.entry.*');
                let dnsList = [];
                if (serviceInfra && serviceInfra['internal-dns-list'] && serviceInfra['internal-dns-list']['entry']) {
                    dnsList = serviceInfra['internal-dns-list']['entry'].map(dns => dns['@name']);
                }
                const flat = [
                    { 'Service Subnet': serviceInfra && serviceInfra['service-subnet'] ? serviceInfra['service-subnet'] : '' },
                    { 'BGP AS': serviceInfra && serviceInfra['infra-bgp-as'] ? serviceInfra['infra-bgp-as'] : '' },
                    { 'Internal DNS List': dnsList }
                ];
                return { flat, 'fieldsData': [] };
            },
        }
    },
    {
        name: '$.stage5',
        uiHint: {
            allowBlank: false,
            builder: 'ContainerBuilder',
            fieldLabel: _T('Network Settings'),
            lineBreak: true,
            displayLabel: '',
            getDisplayValue: (formData, fieldName) => {
                let val = getPathValue(formData, '$.stage5');
                let flat = [];
                if (val) {
                    if (Pan.isObject(val) && Pan.isDefined(val['ip-pools']) && Pan.isArray(val['ip-pools']['entry'])) {
                        let ipPools = [];
                        val['ip-pools']['entry'].forEach((item) => {
                            item['ip-pool']['member'].forEach((mem) => {
                                if (Pan.isObject(mem)) {
                                    ipPools.push(mem.value);
                                } else {
                                    ipPools.push(mem);
                                }
                            });
                        });
                        if (Pan.isEmpty(ipPools)) {
                            ipPools.push(ipPoolsDefaultValue[0]['ip-pool']['member']);
                        }
                        flat.push({ 'IP Pools': ipPools });
                    }
                    if (Pan.isObject(val) && Pan.isDefined(val['dns-servers']) && Pan.isArray(val['dns-servers']['entry'])) {
                        let dnsServers = [];
                        val['dns-servers']['entry'].forEach((item) => {
                            if (item['primary']) {
                                dnsServers.push(item['primary']);
                            }
                            if (item['secondary']) {
                                dnsServers.push(item['secondary']);
                            }
                            if (item['enable-use-internal-as-public'] === 'no') {
                                if (!dnsServers.includes('Cloud Default')) {
                                    dnsServers.push('Cloud Default');
                                }
                            }
                        });
                        if (Pan.isEmpty(dnsServers)) {
                            dnsServers.push('Cloud Default');
                        }
                        flat.push({ 'DNS Servers': dnsServers });
                    }
                } else {
                    flat.push({ 'IP Pools': ipPoolsDefaultValue[0]['ip-pool']['member'] });
                    flat.push({ 'DNS Servers': 'Cloud Default' });
                }
                return { flat, fieldsData: [] };
            }
        }
    },
    {
        name: '$.stage1_step1',
        childrenNames: [
            '$.stage1.choice',
            '$.stage1.deployment',
            '$.stage1.internal-host-detection',
            '$.stage1.test-instruction',
            'stage1-test-users'
        ],
        uiHint: {
            avail: function () {
                return getPathValue(getStoreState(), 'gpcs.muProvisionStatus') !== 'in-progress';
            },
            buttonsAvail: {
                overview: function (...args) {
                    return getPathValue(getStoreState(), 'gpcs.muProvisionStatus') !== 'init';
                },
                next: false,
                save: function (...args) {
                    return isUserRolePemission && getPathValue(getStoreState(), 'gpcs.muProvisionStatus') === 'success';
                },
                commitandpush: function (...args) {
                    return getPathValue(getStoreState(), 'gpcs.muProvisionStatus') === 'init';
                }
            },
            stepTitle: _T('Environment Setup'),
            fieldDescription: _T("Quickly deploy your Prisma Access environment and create a test account and use it to verify that the environment is safely enabling mobile access to the internet.")
        }
    },
    {
        name: '$.stage1.internal-host-detection',
        uiHint: {
            fieldLabel: _T('Internal Host Detection'),
            showSummaryLabel: true,
            showSummaryLastOrder: true, // start 0
            association: {
                fields: ['$.stage1.internal-host-detection'],
                availHide: function (values, formData, filters, key) {
                    console.log('formData:', formData);
                    return Pan.isEmpty(formData);
                }
            }
        }
    },
    {
        name: '$.stage1.internal-host-detection.ip-address',
        uiHint: {
            fieldLabel: _T('IP Address'),
        }
    },
    {
        name: '$.stage1.choice',
        uiHint: {
            fieldLabel: _T('Portal Address Type'),
            useHelpStringAsDisplay: true,
            hideSingleChoice: function () {
                return getPathValue(getStoreState(), 'gpcs.muProvisionStatus') !== 'success';
            },
        },
    },
    {
        name: '$.stage1.default-domain.hostname',
        uiHint: {
            fieldLabel: _T('Portal Address'),
            allowBlank: false,
            hideLabelInSummary: false,
            builder: 'InputGroupBuilder',
            getAddOn: function () {
                return (getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn') || '');
            },
            separator: '.',
            addonType: 'append',
            defaultAddOn: '',
            getDisplayValue: function (formData, fieldName) {
                let defaultName = getPathValue(formData, '$.stage1.default-domain.hostname');
                let customName = getPathValue(formData, '$.stage1.custom-domain.hostname');
                const fqdnVal = getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn');
                if (defaultName && !customName) {
                    return defaultName + (fqdnVal ? '.' + fqdnVal : '');
                } else {
                    return customName;
                }
            },
            association: {
                fields: ['$.stage1.default-domain.hostname'],
                updateFormData: function (values, formData, filters, key) {
                    if (values && values[0]) {
                        const fqdnVal = getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn');
                        return { '$.@name': values[0] + (fqdnVal ? '.' + fqdnVal : '') };
                    }
                },
            },
            vtype: 'objectName'
        },
    },
    {
        name: '$.stage1.custom-domain',
        childrenNames: ['$.stage1.custom-domain.hostname', '$.stage1.custom-domain.cname', '$.stage1.custom-domain.ssl-tls-service-profile'],
        uiHint: {
            radioAvail: function (formData) {
                // return (formData && formData['stage2']);
                return ((formData && formData['stage2']) || getPathValue(getStoreState(), 'gpcs.muProvisionStatus') === 'success');
            }
        }
    },
    {
        name: '$.stage1.custom-domain.hostname',
        uiHint: {
            hideLabelInSummary: true,
            fieldLabel: _T('Portal Address'),
            association: {
                fields: ['$.stage1.custom-domain.hostname'],
                updateFormData: function (values, formData, filters, key) {
                    if (values && values[0]) {
                        return { '$.@name': values[0] };
                    }
                },
            },
        }
    },
    {
        name: '$.stage1.custom-domain.cname',
        uiHint: {
            fieldLabel: _T('Portal DNS CNAME'),
            builder: 'InputGroupBuilder',
            hideLabelInSummary: true,
            separator: '.',
            addonType: 'append',
            getAddOn: function () {
                return (getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn') || '');
            },
            showHelpString: 'The FQDN will be published to public domain servers.',
            association: {
                fields: ['$.stage1.custom-domain.cname'],
                updateFormData: function (values, formData, filters, key) {
                    if (values && values[0]) {
                        const fqdnVal = getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn');
                        return { '$.@name': values[0] + (fqdnVal ? '.' + fqdnVal : '') };
                    }
                },
            },
        },
    },
    {
        name: '$.stage1.custom-domain.ssl-tls-service-profile',
        uiHint: {
            fieldLabel: _T('SSL/TLS Service Profile'),
            builder: 'CompletionBuilder',
            hideLabelInSummary: true,
            completionConfig: {
                actions: [
                    {
                        text: 'Add SSL/TLS Service Profile',
                        viewerName: 'SSLTLSServiceProfileRecordViewer',
                        rbaPath: 'device.certificate-management.ssl-tls-service-profile',
                        viewerProps: {
                            windowSize: 'lg',
                            title: _T('SSL/TLS Service Profile'),
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        }
                    }
                ]
            },
            manageViewer: {
                showViewer: true,
                viewerName: 'SSLTLSServiceProfile',
                viewerProps: {
                    formSize: 'lg',
                    displayCancel: true,
                    title: "SSL/TLS Service Profile",
                    configLocation: {
                        tpl: {
                            name: MOBILE_USERS_TPL
                        }
                    }
                },
                actionText: 'Manage SSL/TLS Service Profile',
                actionProps: {
                    style: { margin: 10, marginLeft: 0 }
                }
            }
        },
    },
    {
        name: '$.stage1.deployment',
        uiHint: {
            fieldLabel: _T('Locations'),
            builderInSummary: 'ContainerBuilder',
            from: 'summary'
        }
    },
    {
        name: '$.stage1.deployment.region.entry',
        uiHint: {
            fieldLabel: _T('Locations'),
            builder: 'RegionMapBuilder'
        }
    },
    {
        name: 'stage1-test-users',
        uiHint: {
            association: {
                availHide: function (values, formData, filters, key) {
                    return ((formData && formData['stage2']) || getPathValue(getStoreState(), 'gpcs.muProvisionStatus') === 'success');
                }
            },
            customValidation: function() {
                let testUsers = getPathValue(getStoreState(), 'tpl.localUsers.result.@count');
                if ( !testUsers || testUsers === '0' ) {
                    return 'At least one Test User is required';
                } 
                return '';
            }, 
            fieldLabel: _T('Temporary test users'),
            labelWidthSize: 3,
            builder: 'ViewerDisplayBuilder',
            viewerName: 'LocalUsers',
            viewerProps: {
                editorMode: 'modal',
                displayMode: 'simple',
                hasGridFilter: false,
                windowSize: 'lg',
                minRows: 1,
                configLocation: {
                    tpl: {
                        name: MOBILE_USERS_TPL,
                    }
                }
            },
            showHelpString: 'Create test user accounts to test the environment and make sure secure access to internet is available. Test users do not have access to the corporate network.',
        },
    },
    {
        name: '$.stage2.saml-certificate',
        uiHint: {
            fieldLabel: _T('SAML Signing Certificate'),
            completionConfig: {
                actions: [
                    {
                        text: 'Import',
                        viewerName: 'CertificateImport',
                        rbaPath: 'device.certificate-management.certificates',
                        viewerProps: {
                            windowSize: 'lg',
                            title: _T('Import Certificate'),
                            configLocation: {
                                tpl: {
                                    name: GPCS_COMMON_TPL
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        name: '$.stage2.saml-idp-profile',
        uiHint: {
            fieldLabel: _T('SAML IDP Profile'),
            builder: 'CompletionBuilder',
            completionConfig: {
                actions: [
                    {
                        text: 'Add SAML IDP Profile',
                        rbaPath: 'device.server-profile.saml_idp',
                        viewerProps: {
                            labelWidthSize: '5',
                            title: _T('SAML IDP Profile'),
                            windowSize: 'lg',
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        },
                        viewerName: "SAMLIDPProfileRecordViewer"
                    }, {
                        text: 'Import SAML IDP',
                        viewerName: 'ImportSAMLIDP',
                        rbaPath: 'device.server-profile.saml_idp',
                        viewerProps: {
                            title: _T('Import SAML IDP'),
                            labelWidthSize: '5',
                            windowSize: 'lg',
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        }
                    }
                ]
            },
            manageViewer: {
                showViewer: true,
                viewerName: 'SAMLIDPProfile',
                viewerProps: {
                    displayCancel: true,
                    title: "SAML IDP Profile",
                    configLocation: {
                        tpl: {
                            name: MOBILE_USERS_TPL
                        }
                    }
                },
                actionText: 'Manage SAML IdP Profiles',
                actionProps: {
                    style: { margin: 10, marginLeft: 0 }
                }
            }
        }
    },
    {
        name: 'SamlIdpInfo',
        uiHint: {
            fieldLabel: 'SAML IDP INFORMATION',
            builder: () => {
                return SamlIdpInfo;
            }
        }
    },
    {
        name: 'SamlSetupGuides',
        uiHint: {
            fieldLabel: 'SAML SETUP GUIDES',
            builder: () => {
                return SamlSetupGuides;
            }
        }
    },
    {
        name: '$.stage2.allow-list.member',
        defaultValue: ['all'],
        uiHint: {
            fieldLabel: _T('Allow Users')
        }
    },
    {
        name: '$.stage2.allow-list.member.*',
        uiHint: {
            fieldLabel: _T('Users/Groups')
        }
    }, {
        name: '$.stage2.usergroup-attribute',
        uiHint: {
            fieldLabel: _T('User Group Attribute')
        }
    }, {
        name: '$.stage2.enable-single-logout',
        uiHint: {
            fieldLabel: _T('Enable Single Logout (SLO)')
        }
    },
    {
        name: '$.stage4.choice',
        defaultValue: 'SAML-standalone-portal',
        childrenNames: [
            '$.stage4.SAML-standalone-portal',
            '$.stage4.SAML-Prisma-SaaS'
        ],
        uiHint: {
            fieldLabel: _T("Authentication Method"),
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS.saml-idp-profile',
        uiHint: {
            fieldLabel: _T('SAML IDP Profile'),
            builder: 'CompletionBuilder',
            completionConfig: {
                actions: [
                    {
                        text: 'Add SAML IDP Profile',
                        rbaPath: 'device.server-profile.saml_idp',
                        viewerProps: {
                            labelWidthSize: '5',
                            windowSize: 'lg',
                            title: _T('SAML IDP Profile'),
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        },
                        viewerName: 'SAMLIDPProfileRecordViewer'
                    }, {
                        text: 'Import SAML IDP',
                        viewerName: 'ImportSAMLIDP',
                        rbaPath: 'device.server-profile.saml_idp',
                        viewerProps: {
                            title: _T('Import SAML IDP'),
                            labelWidthSize: '5',
                            windowSize: 'lg',
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        }
                    }
                ]
            },
            manageViewer: {
                showViewer: true,
                viewerName: 'SAMLIDPProfile',
                viewerProps: {
                    displayCancel: true,
                    title: "SAML IDP Profile",
                    configLocation: {
                        tpl: {
                            name: MOBILE_USERS_TPL
                        }
                    }
                },
                actionText: 'Manage SAML IDP Profiles',
                actionProps: {
                    style: { margin: 10, marginLeft: 0 }
                }
            }
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS.allow-list.member',
        defaultValue: ['all'],
        uiHint: {
            fieldLabel: _T('Allow Users')
        }
    },
    {
        name: '$.stage4.SAML-Prisma-SaaS.allow-list.member.*',
        uiHint: {
            fieldLabel: _T('Users/Groups')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.choice',
        uiHint: {
            fieldLabel: ' ',
            useHelpStringAsDisplay: true
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.choice',
        uiHint: {
            fieldLabel: ' '
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.choice',
        uiHint: {
            fieldLabel: ' '
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.existing-saml',
        uiHint: {
            fieldLabel: _T('Authentication Method'),
            getDisplayValue: function (formData, fieldName) {
                let samlV1 = getPathValue(formData, '$.stage4.SAML-standalone-portal.new-saml'),
                    samlV2 = getPathValue(formData, '$.stage4.SAML-Prisma-SaaS');
                return samlV2 ? '' : samlV1 ? '' : 'Use existing SAML configuration';
            }
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml',
        childrenNames: [
            '$.stage4.SAML-standalone-portal-idp-separator',
            '$.stage4.SAML-standalone-portal.new-saml.saml-certificate',
            'SamlIdpInfo',
            'SamlSetupGuides',
            '$.stage4.SAML-standalone-portal-saml-separator',
            '$.stage4.SAML-standalone-portal.new-saml.saml-idp-profile',
            '$.stage4.SAML-standalone-portal-saml-attr-separator',
            '$.stage4.SAML-standalone-portal.new-saml.username-attribute',
            '$.stage4.SAML-standalone-portal.new-saml.usergroup-attribute',
            '$.stage4.SAML-standalone-portal-user-separator',
            // '$.stage4.SAML-standalone-portal.new-saml.enable-single-logout',
            '$.stage4.SAML-standalone-portal.new-saml.allow-list.member'
        ],
        uiHint: {
            fieldLabel: _T('Authentication Method')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml.usergroup-attribute',
        uiHint: {
            placeholder: 'Enter a usergroup attribute'
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml.username-attribute',
        uiHint: {
            placeholder: 'Enter username attributes'
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml.allow-list.member',
        defaultValue: ['all'],
        uiHint: {
            fieldLabel: _T('Allow Users')
        }

    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml.allow-list.member.*',
        uiHint: {
            fieldLabel: _T('Users/Groups')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal-idp-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Configure your IdP')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal-saml-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Configure Prisma Access (SAML service provider)')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal-saml-attr-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('SAML Attributes')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal-user-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Authorize')
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml.saml-certificate',
        uiHint: {
            fieldLabel: _T('Prisma Access Signing Certificate'),
            completionConfig: {
                actions: [
                    {
                        text: 'Import',
                        viewerName: 'CertificateImport',
                        rbaPath: 'device.certificate-management.certificates',
                        viewerProps: {
                            windowSize: 'lg',
                            title: _T('Import Certificate'),
                            configLocation: {
                                tpl: {
                                    name: GPCS_COMMON_TPL
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        name: '$.stage4.SAML-standalone-portal.new-saml.saml-idp-profile',
        uiHint: {
            fieldLabel: _T('SAML IDP Profile'),
            builder: 'CompletionBuilder',
            placeholder: 'Select a SAML IDP profile',
            completionConfig: {
                actions: [
                    {
                        text: 'Add SAML IDP Profile',
                        rbaPath: 'device.server-profile.saml_idp',
                        viewerProps: {
                            labelWidthSize: '5',
                            title: _T('SAML IDP Profile'),
                            windowSize: 'lg',
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        },
                        viewerName: 'SAMLIDPProfileRecordViewer'
                    }, {
                        text: 'Import SAML IDP',
                        viewerName: 'ImportSAMLIDP',
                        rbaPath: 'device.server-profile.saml_idp',
                        viewerProps: {
                            title: _T('Import SAML IDP'),
                            labelWidthSize: '5',
                            windowSize: 'lg',
                            configLocation: {
                                tpl: {
                                    name: MOBILE_USERS_TPL,
                                }
                            }
                        }
                    }
                ]
            },
            manageViewer: {
                showViewer: true,
                viewerName: 'SAMLIDPProfile',
                viewerProps: {
                    displayCancel: true,
                    title: "SAML IDP Profile",
                    configLocation: {
                        tpl: {
                            name: MOBILE_USERS_TPL
                        }
                    }
                },
                actionText: 'Manage SAML IdP Profiles',
                actionProps: {
                    style: { margin: 10, marginLeft: 0 }
                }
            }
        }
    },
    {
        name: '$.stage5.ip-pools.entry',
        defaultValue: Pan.clone(ipPoolsDefaultValue),
        uiHint: {
            fieldRequired: true,
            fieldLabel: _T('IP Pools'),
            editorType: 'record-form',
            dialogSize: 'lg',
            title: 'IP Pool',
            useColumns: [
                '$.stage5.ip-pools.entry.*.@name',
                '$.stage5.ip-pools.entry.*.ip-pool.member'
            ],
            childrenNames: [
                '$.stage5.ip-pools.entry.*.@name',
                '$.stage5.ip-pools.entry.*.ip-pool.member'
            ]
        }
    },
    {
        name: '$.stage5.ip-pools.entry.*.@name',
        uiHint: {
            builder: 'CompletionBuilder',
            fieldLabel: _T("IP Pools Region"),
            enum: [
                ['worldwide', 'Worldwide'],
                ['americas', 'North America & South America'],
                ['emea', 'Africa, Europe & Middle East'],
                ['apac', 'Asia, Australia & Japan']
            ],
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    let d = params.data;
                    if (!d) {
                        return '';
                    }
                    const map = {
                        'worldwide': 'Worldwide',
                        'americas': 'North America & South America',
                        'emea': 'Africa, Europe & Middle East',
                        'apac': 'Asia, Australia & Japan'
                    };
                    return map[d['@name']];
                }
            }
        }
    },
    {
        name: '$.stage5.ip-pools.entry.*.ip-pool.member',
        uiHint: {
            hasGridFilter: false,
            fieldLabel: _T('IP Pool'),
            columnConfig: {
                headerName: "IP Subnets",
                valueGetter: params => {
                    let dl = getPathValue(params.data, 'ip-pool.member') || [];
                    if (Array.isArray(dl)) {
                        return dl.map(c => c[INJECTED_REC_ID] ? c['value'] : c).join(', ');
                    }
                    return dl;
                }
            }
        }
    },
    {
        name: '$.stage5.ip-pools.entry.*.ip-pool.member.*',
        uiHint: {
            fieldLabel: _T('IP Pool'),
        }
    },
    {
        name: '$.stage5.dns-servers.entry',
        defaultValue: Pan.clone(dnsServerDefaultValue),
        uiHint: {
            fieldRequired: true,
            fieldLabel: _T('DNS Servers'),
            editorType: 'record-form',
            dialogSize: 'lg',
            title: 'DNS Server',
            useColumns: [
                '$.stage5.dns-servers.entry.*.@name',
                'PrivateDNS',
                '$.stage5.dns-servers.entry.*.dns-suffix.member',
                '$.stage5.dns-servers.entry.*.enable-use-internal-as-public'
            ]
        }
    },
    {
        name: 'PrivateDNS',
        uiHint: {
            fieldLabel: 'Internal Domain',
            columnConfig: {
                children: [
                    {
                        headerName: "Primary DNS",
                        minWidth: 120,
                        valueGetter: params => {
                            return jsonPath(params.data, "primary");
                        }
                    },
                    {
                        headerName: "Secondary DNS",
                        minWidth: 150,
                        valueGetter: params => {
                            return jsonPath(params.data, "secondary");
                        }
                    },
                    {
                        headerName: "Domain List",
                        minWidth: 120,
                        valueGetter: params => {
                            let dl = jsonPath(params.data, "domain-list.member") || [];
                            if (Array.isArray(dl[0])) {
                                return dl[0].map(c => c[INJECTED_REC_ID] ? c['value'] : c);
                            }
                            return dl;
                        }
                    }
                ]
            }
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.@name',
        uiHint: {
            hasGridFilter: false,
            fieldLabel: _T("Region"),
            builder: 'CompletionBuilder',
            enum: [
                ['worldwide', 'Worldwide'],
                ['americas', 'North America & South America'],
                ['emea', 'Africa, Europe & Middle East'],
                ['apac', 'Asia, Australia & Japan']
            ],
            columnConfig: {
                headerName: 'Region',
                type: 'clickableColumn',
                valueGetter: params => {
                    let d = params.data;
                    if (!d) {
                        return '';
                    }
                    const map = {
                        'worldwide': 'Worldwide',
                        'americas': 'North America & South America',
                        'emea': 'Africa, Europe & Middle East',
                        'apac': 'Asia, Australia & Japan'
                    };
                    return map[d['@name']];
                }
            }   
        }
    },
    {
        name: 'privatedns',
        childrenNames: [
            '$.stage5.dns-servers.entry.*.primary',
            '$.stage5.dns-servers.entry.*.secondary',
        ],
        uiHint: {
            builder: "FieldSetBuilder",
            fieldLabel: "Internal DNS Server"
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.primary',
        uiHint: {
            fieldLabel: _T("Primary DNS"),
            placeholder: 'Enter a primary DNS server',
            customValidation: function (value, field, formData) {
                let check = getPathValue(formData, '$.stage5.dns-servers.entry.*.enable-use-internal-as-public');
                if (check === 'yes') {
                    return validationRulesMap.noAllowBlank(value);
                }
            }
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.secondary',
        uiHint: {
            fieldLabel: _T("Secondary DNS"),
            placeholder: 'Enter a secondary DNS server'
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.enable-use-internal-as-public',
        uiHint: {
            builder: 'CheckboxBuilder',
            fieldLabel: _T(`Use these DNS servers to resolve both internal and public domains. \n By default, public domains are resolved using Prisma Access cloud DNS`),
            columnConfig: {
                headerName: 'Public Domain',
                children: [{
                    headerName: 'DNS Server',
                    id: "publicDomain",
                    valueGetter: params => {
                        let data = jsonPath(params.data, "enable-use-internal-as-public");
                        return (data[0] === 'yes' ? 'Same as Internal DNS Server' : 'Cloud Default');
                    }
                }]
            },
            association: {
                runValidation: function (values, formData, field, key) {
                    const val = getPathValue(formData, '$.stage5.dns-servers.entry.*.primary') || '';
                    return { '$.stage5.dns-servers.entry.*.primary': val };
                }
            }
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*',
        childrenNames: [
            '$.stage5.dns-servers.entry.*.@name',
            '$.stage5.dns-servers.entry.*.enable-use-internal-as-public',
            'privatedns',
            '$.stage5.dns-servers.entry.*.domain-list.member',
            '$.stage5.dns-servers.entry.*.dns-suffix.member',
        ]
    },
    {
        name: '$.stage5.dns-servers.entry.*.domain-list',
        uiHint: {
            fieldLabel: _T('Domain List'),
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.domain-list.member',
        uiHint: {
            hasGridFilter: false,
            fieldLabel: _T('Domain List'),
            association: {
                fields: ['$.stage5.dns-servers.entry.*.enable-use-internal-as-public'],
                availHide: function (values, formData, filters, key) {
                    return (values[0] === 'yes');
                }
            }
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.dns-suffix.member',
        uiHint: {
            hasGridFilter: false,
            fieldLabel: _T('Client DNS Suffix search list'),
            columnConfig: {
                headerName: "Client DNS Suffix Search List",
                valueGetter: params => {
                    let dl = getPathValue(params.data, 'dns-suffix.member') || [];
                    if (Array.isArray(dl)) {
                        return dl.map(c => c[INJECTED_REC_ID] ? c['value'] : c);
                    }
                    return dl;
                }
            }
        }
    },
    {
        name: '$.stage5.dns-servers.entry.*.dns-suffix.member.*',
        uiHint: {
            fieldLabel: _T('Client DNS Suffix'),
        }
    }
];

export const mobileUsersColumns = [
    {
        headerName: _T('Portal Address'),
        field: '@name',
        minWidth: 130
    }, {
        headerName: _T('Config Status'),
        field: 'config_status',
        cellRendererParams: {
            renderCell: params => {
                if (getStoreState().gpcs.gpcs_status && getStoreState().gpcs.gpcs_status !== 'error') {
                    const gpcs_status = getStoreState().gpcs.gpcs_status;
                    let config_status = '';
                    let config_status_tooltip = '';
                    if (gpcs_status && gpcs_status[0] && gpcs_status[0]['Config Status']) {
                        config_status = gpcs_status[0]['Config Status'].value;
                        config_status_tooltip = gpcs_status[0]['Config Status'].tooltip;

                        //@todo we need to change API repsonse to generate tooltip.
                        // this is bad hack for 1.0, code is copied pasted from dashboard.
                        let tooltipArr = [];
                        for (const [key, value] of Object.entries(config_status_tooltip)) {
                            tooltipArr.push(`${key} ${value}`);
                        }
                        const toolTipItems = tooltipArr.reduce((acc, item, idx, array) => {
                            if (idx === array.length - 1) {
                                acc += item + ', ';
                                return '' + acc + '';
                            }
                            acc += item + ', ';
                            return acc;
                        }, '');
                        //hack, originally we need to ask API to give us correct string
                        let imgSrc = getIconSrc(config_status);
                        return (
                            <div>
                                <div className='muConfigStatusHolder' title={config_status_tooltip[config_status]}>
                                    <img className='muConfigStatusIcon' src={imgSrc} alt="Configuration Status" data-tip={toolTipItems} id='mobileUserconfigStatusWarning' data-for='mobileUserconfigStatusWarning' />
                                    {config_status}
                                    <FormFeedbackWidget placement="bottom" target="mobileUserconfigStatusWarning" feedback={toolTipItems} />
                                </div>

                            </div>
                        );
                    }
                }
                return '';
            }
        },
        width: 115
    }, {
        headerName: _T("IP Pools"),
        valueGetter: params => {
            if (getPathValue(params.data, '$.stage5.ip-pools')) {
                let ipPools = getPathValue(params.data, '$.stage5.ip-pools')
                return ipPools.entry.map((ipPool) => {
                    let pools = [];
                    pools.push(ipPool['@name'] + ': ' + ipPool['ip-pool']['member']);
                    return pools;
                });
            }
            return ipPoolsDefaultValue[0]['@name'] + ': ' + ipPoolsDefaultValue[0]['ip-pool']['member'];
        }
    }, {
        headerName: _T("DNS Servers"),
        valueGetter: params => {
            let dnsServers = dnsServerDefaultValue;
            if (getPathValue(params.data, '$.stage5.dns-servers.entry')) {
                dnsServers = getPathValue(params.data, '$.stage5.dns-servers.entry');
            }
            let values = [];
            dnsServers.forEach(server => {
                values.push(server['@name'] + ': ' + (Pan.isDefined(server['primary']) ? server['primary'] : 'Cloud Default'));
            });
            return values;
        }
    }, {
        headerName: _T('Gateways'),
        field: 'gateways',
        valueGetter: params => {
            let val = [];
            if (getStoreState().gpcs.mobileUsersNetworkDetails && getStoreState().gpcs.mobileUsersNetworkDetails !== 'error') {
                let mobileUsersNetworkDetails = getStoreState().gpcs.mobileUsersNetworkDetails;
                mobileUsersNetworkDetails.map((detail) => {
                    let gateways = detail['Gateways'];
                    gateways.map((gateway)=>{
                        val.push(gateway);
                    });
                });
            }
            return val;
        },
        cellRenderer: (params) => {
            let str = '';
            if(params.value && Array.isArray(params.value)){
                params.value.map((val)=>{
                    str += '<div class="array-value-cell">'+val+'</div>';
                });
            }
            params.api.resetRowHeights();
            return str;
        },
        minWidth: 300
    }
];

function prepareAddRecord(callback) {
    const store = getStore();
    store.dispatch(pullingProvisionStatus('mobileUsers', callback));
}

function summaryAvail() {
    return getPathValue(getStoreState(), 'gpcs.muProvisionStatus') !== 'in-progress';
};

export default class MobileUsers extends GPCSWorkflowBaseViewer {
    serviceName = 'workflow/mobileUsers';
    reduxId = 'mobileUsers';
    header = 'Mobile Users';
    columns = mobileUsersColumns;
    fields = mobileUsersFields;
    singleStepForAdd = true;
    suppressColsSizeToFit = false;
    showDefaultCloneAction = false;
    rbaPermission=isUserRolePemission() ? 'enable':'disable';
    recordConfigPath = '$.config.devices.entry.plugins.cloud_services.adelphi_gpcs_workflow.mobile-users.onboarding.entry';
    maxCount = 1;
    showDefaultAddAction = false;
    showDefaultDeleteAction = false;
    showConfigureActions = isUserRolePemission();
    showPaging = false;
    showFilter = false;
    checkboxSelection = false;
    summaryAvail = summaryAvail;
    prepareAddRecord = prepareAddRecord;
    initActions = [generateMUDefaultConfigCerts, fetchMUFQDN, getMobileUsersNetworkDetails, fetchGPCSStatus, pullingProvisionStatus, getSSLDecryption, fetchServiceInfra]
    refreshColumns = ['gateways', 'config_status']
}
