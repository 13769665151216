import * as React from 'react';
import classNames from 'classnames';
import calendar from '../../../../../images/icon-calendar.svg';

export default function InputIcon(props) {
  const { suffixIcon, prefixCls } = props;
  return (
    (suffixIcon &&
      (React.isValidElement(suffixIcon) ? (
        React.cloneElement(suffixIcon, {
          className: classNames({
            [suffixIcon.props.className]: suffixIcon.props.className,
            [`${prefixCls}-picker-icon`]: true,
          }),
        })
      ) : (
        <span className={`${prefixCls}-picker-icon`}>{suffixIcon}</span>
      ))) || <i className={`${prefixCls}-picker-icon`} ><img width={'12px'} height={'12px'} alt="calendar" src={calendar}/></i>
  );
}
