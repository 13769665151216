import React from 'react';
import { getPathValue, RecordViewer, connect, ViewerManager } from 'ui-lib';
import {
    addConfigItem,
    editConfigItem,
    fetchConfigItem,
    getEffectiveServiceMap
} from 'service-lib';
import { GPCS_LOADING } from '../main/actions';

const getGPCSRecordViewer = (viewerName, serviceName, recordId, reduxId, recordPath, recordKey, serviceMap) => {
    class GPCSRecordViewer extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                configLocation: props.configLocation && getPathValue(props.configLocation, 'extension.gpcs.name') ? { extension: { gpcs: { name: getPathValue(props.configLocation, 'extension.gpcs.name') } } } : null,
                rbaPermission: props.rbaPermission || '',
                serviceMap: getEffectiveServiceMap(serviceName, serviceMap),
                reduxStateId: {
                    reduxId: reduxId,
                    locationPath: 'gpcs'
                }
            }
        }

        componentDidMount() {
            if (this.props.fetchRecord) {
                //fetch the record for editing
                //if fetchRecord is not set, it will be treated as addRecord
                this.props.dispatch(
                    fetchConfigItem(this.state.serviceMap, recordId, this.state.reduxStateId, this.state.configLocation)
                );
            }
        }

        onAddRecord(record) {
            this.props.dispatch(
                addConfigItem(record, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation)
            );
        }

        onEditRecord(record, backup) {
            this.props.dispatch(
                editConfigItem(record, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
            );
        }

        render() {
            let responseData = this.props[reduxId] || {};
            let record = responseData && this.props.fetchRecord ? getPathValue(responseData, recordPath || `result`) || {} : this.props.record || {};
            if (record && recordKey) {
                let recordObj = {};
                recordObj[recordKey] = record;
                record = recordObj;
            }

            const Viewer = ViewerManager.getViewInstance(viewerName) || {};
            const viewerProps = {
                ...this.props,
                recordConfigPath: Viewer.recordConfigPath || this.props.recordConfigPath,
                inputFields: Viewer.fields || this.props.inputFields,
            };

            return (
                <RecordViewer
                    {...viewerProps}
                    record={record}
                    onAddRecord={this.onAddRecord.bind(this)}
                    onEditRecord={this.onEditRecord.bind(this)}
                    panSchema={window.__pan_schema}
                    formLoading={responseData[GPCS_LOADING]}
                    configLocation={this.state.configLocation}
                    rbaPermission={this.state.rbaPermission}
                    locationPermission={'enable'}
                />
            );
        }
    }

    const mapStateToProps = state => {
        return state.gpcs;
    };

    return connect(mapStateToProps, null, null)(GPCSRecordViewer);
};
export default getGPCSRecordViewer;
