import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import Pan from '../../schema/Pan';
import PropTypes from 'prop-types';

/*
    The ModalWindowWidget accept any react component as its body.
*/

export class ModalWindowWidget extends Component {
    constructor(props) {
        super(props);
    }

    renderMessage() {
        let i = 1;
        let msgBody = [];
        if(Pan.isArray(this.props.message)) {
            msgBody = this.props.message.map(msg => <div key={i++}>{msg}</div> );
        }else{
            msgBody.push( <div>{this.props.message}</div> );
        }
        return msgBody;
    }

    renderComponent() {
        let BodyComp = this.props.modalBody.component;
        let componentProps = this.props.modalBody.props;
        return <BodyComp {...componentProps} modalId={this.props.id} />;
    }

    renderChildren() {
        return this.props.children;
    }

    render() {
        const {
            actions = [],
            modalBody,
            modalHeaderStyle = {},
            modalBodyStyle = {},
            modalFooterStyle = {},
            modalHeaderClassName = '',
            modalBodyClassName = '',
            modalFooterClassName = '',
            size,
            style,
            toggle,
            title
        } = this.props;
        const buttons = actions.map((item, index) => {
            return (
                <Button
                    disabled={item.disabled}
                    color={item.color || 'primary'}
                    onClick={item.action}
                    key={item.text || index}
                    style={item.style}
                >
                    {item.text}
                </Button>
            );
        });
        let _body = modalBody ? this.renderComponent() : this.props.children ? this.renderChildren() : this.renderMessage();
        return (
            <React.Fragment>
                <Modal isOpen={true} size={size} style={style} fade={false} toggle={toggle} centered>
                    {title && (
                        <ModalHeader style={modalHeaderStyle} className={modalHeaderClassName} toggle={toggle}>
                            {title}
                        </ModalHeader>
                    )}                   
                    <ModalBody style={modalBodyStyle} className={modalBodyClassName}>{_body}</ModalBody>
                    <ModalFooter style={modalFooterStyle} className={modalFooterClassName}>
                        {buttons}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
ModalWindowWidget.defaultProps = {
    open: false,
    size: 'lg',
};
if (process.env.NODE_ENV !== 'production') {
    ModalWindowWidget.propTypes = {
        open: PropTypes.bool,
        onWindowClose: PropTypes.func,
        title: PropTypes.string,
        size: PropTypes.oneOf(['lg', 'sm']),
        toggle: PropTypes.func,
        /**
         * actions is an array which displays buttons in the modal widget.
         * action is the callback function
         */
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                color: PropTypes.string,
                action: PropTypes.func,
            }),
        ),
    };
}
