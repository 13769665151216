import React from "react";

import {
    autoCompletionObservable,
    parseAutoCompletionToOptions,
    getPathValue,
    getCompletionXpath,
    getFieldXpath,
    capitalizeLetter,
    Pan,
    ViewerManager,
    parseError,
    SERVER_ERROR
} from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

import AppBrowser from './AppBrowser';
import Risk from './Risk';
import { ajax } from 'rxjs/ajax';
import { store } from '../../../store';

import { tagColorIconMap } from '../tags';

const _T = str => str;

const riskValues = ['1', '2', '3', '4', '5'];

const applicationObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    },
    {
        name: "$.default",
        uiHint: {
            hideLabel: true,
            largeSelectionCount: 10
        }
    },
    {
        name: "$.default.port.member",
        uiHint: {
            showHelpString: _T('Enter each port in the form of [tcp|udp]/[dynamic|0-65535]') + ' - ' +
                _T('Example: tcp/dynamic or udp/32'),
            height: 90,
            hasGridFilter: false
        }
    },
    {
        name: "$.default.ident-by-ip-protocol",
        uiHint: {
            //hideLabel: true,
            boxLabel: _T("IP Protocol"),
            fieldLabel: _T("IP Protocol"),
            showHelpString: _T('Enter a number between 0-255, except the reserved ip protocol 6 and 17.')
        }
    },
    {
        name: "$.default.ident-by-icmp-type",
        uiHint: {
            //hideLabel: true,
            boxLabel: _T("ICMP Type"),
            fieldLabel: _T("ICMP Type"),
            showHelpString: _T('Enter a number between 0-255.')
        }
    },
    {
        name: "$.default.ident-by-icmp6-type",
        uiHint: {
            //hideLabel: true,
            boxLabel: _T("ICMP6 Type"),
            fieldLabel: _T("ICMP6 Type"),
            showHelpString: _T('Enter a number between 0-255.')
        }
    }, {
        name: '$.signature.entry',
        uiHint: {
            editorType: 'record-form',
            dialogSize: 'lg',
            useColumns: [
                '$.signature.entry.*.@name',
                '$.signature.entry.*.comment',
                '$.signature.entry.*.order-free',
                '$.signature.entry.*.scope'
                //'$.signature.entry.*.and-condition'
            ],
            childrenNames: ['$.signature.entry.*.@name',
                '$.signature.entry.*.comment',
                '$.signature.entry.*.order-free',
                '$.signature.entry.*.scope',
                '$.signature.entry.*.and-condition'
            ]
        }
    },
    {
        name: '$.signature.entry.*.@name',
        uiHint: {
            fieldLabel: _T('Signature Name'),
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.signature.entry.*.scope',
        uiHint: {
            columnConfig: {
                valueGetter: params => {
                    const val = getPathValue(params.data, "scope");
                    return (!val || val === 'protocol-data-unit') ? 'Transaction' : capitalizeLetter(val);
                }
            }
        }
    },
    {
        name: '$.signature.entry.*.order-free',
        type: 'reverseBool',
        uiHint: {
            fieldLabel: _T('Ordered Condition Match')
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry',
        uiHint: {
            isOrdered: true,
            editorType: 'record-form',
            useColumns: [
                '$.signature.entry.*.and-condition.entry.*.@name',
            ],
            childrenNames: [
                '$.signature.entry.*.and-condition.entry.*.@name',
                '$.signature.entry.*.and-condition.entry.*.or-condition'
            ]
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry',
        uiHint: {
            editorType: 'record-form',
            useColumns: [
                '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.@name',
            ],
            childrenNames: [
                '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.@name',
                '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator'
            ]
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.qualifier.entry',
        uiHint: {
            editorType: 'record-form',
            childrenNames: [
                '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.qualifier.entry.@name',
                '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.qualifier.entry.value'
            ]
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.pattern',
        uiHint: {
            vtype: 'isValidPattern'
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.qualifier.entry.*.@name',
        uiHint: {
            builder: 'CompletionBuilder',
            completionConfig: {
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    const context = getPathValue(formData, '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.context');
                    if (context) {
                        let additional = [{
                            name: 'context',
                            value: context,
                        }];
                        const completinObservable = ajax(autoCompletionObservable(inputValue, completionXpath, configLocation, additional));
                        return new Promise(function (resolve, reject) {
                            completinObservable.toPromise().then((resp) => {
                                const completions = parseAutoCompletionToOptions(resp);
                                resolve(completions);
                            }, (err) => {
                                store.dispatch({
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(err),
                                    showMessage: false
                                });
                                // reject(new Error('Error in Auto Completion: ' + err ));
                            });
                        });
                    } else {
                        return new Promise(function (resolve, reject) {
                            resolve([]);
                        });
                    }
                }
            }
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.qualifier.entry.*.value',
        uiHint: {
            builder: 'CompletionBuilder',
            completionConfig: {
                associationFields: ['$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.pattern-match.qualifier.entry.*.@name'],
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    const completinObservable = ajax(autoCompletionObservable(inputValue, completionXpath, configLocation));
                    return new Promise(function (resolve, reject) {
                        completinObservable.toPromise().then((resp) => {
                            const completions = parseAutoCompletionToOptions(resp);
                            resolve(completions);
                        }, (err) => {
                            store.dispatch({
                                type: SERVER_ERROR,
                                errorMessage: parseError(err),
                                showMessage: false
                            });
                            // reject(new Error('Error in Auto Completion: ' + err ));
                        });
                    });
                }
            }
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.greater-than.qualifier.entry',
        uiHint: {
            editorType: 'record-form',
            disableDefaultAddAction: true
        }
    },
    {
        name: '$.signature.entry.*.and-condition.entry.*.or-condition.entry.*.operator.less-than.qualifier.entry',
        uiHint: {
            editorType: 'record-form',
            disableDefaultAddAction: true
        }
    },
    {
        name: '$',
        childrenNames: [
            'appconfig'
        ],
        uiHint: {
            labelWidthSize: 3
        }
    }, {
        name: 'appconfig',
        childrenNames: [
            'configuration',
            'advanced',
            'signatures'
        ],
        uiHint: {
            builder: "TabBuilder"
        }
    }, {
        name: 'configuration',
        childrenNames: [
            'general',
            'props',
            'characteristics'
        ],
    }, {
        name: 'general',
        childrenNames: [
            'name',
            '$.description'
        ],
        uiHint: {
            builder: "FieldSetBuilder"
        }
    }, {
        name: 'props',
        childrenNames: [
            '$.category',
            '$.subcategory',
            '$.technology',
            '$.risk',
            '$.parent-app'
        ],
        uiHint: {
            builder: "FieldSetBuilder",
            fieldLabel: 'Properties',
            labelWidthSize: 4,
            layout: [{
                '$.category': "col-4",
                '$.subcategory': "col-4",
                '$.technology': "col-4"
            }, {
                '$.parent-app': "col-4",
                '$.risk': "col-4"
            }]
        }
    }, {
        name: '$.category',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.subcategory',
        uiHint: {
            builder: 'CompletionBuilder',
            completionConfig: {
                associationFields: ['$.category'],
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    let category = getPathValue(formData, '$.category');
                    if (category) {
                        let additional = [{
                            name: 'category',
                            value: category || ""
                        }];

                        let fieldPath = getCompletionXpath('$.category', filters, formData, "$.config.devices.entry.device-group.entry.application.entry");
                        let pobj = getFieldXpath(fieldPath, configLocation);
                        let completinObservable = ajax(autoCompletionObservable(inputValue, completionXpath, configLocation, additional, pobj));
                        return new Promise(function (resolve, reject) {
                            completinObservable.toPromise().then((resp) => {
                                let completions = parseAutoCompletionToOptions(resp);
                                resolve(completions);
                            }, (err) => {
                                store.dispatch({
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(err),
                                    showMessage: false
                                });
                                // reject(new Error('Error in Auto Completion: ' + err ));
                            });
                        });
                    } else {
                        return new Promise(function (resolve, reject) {
                            resolve([]);
                        });
                    }
                }
            }
        }
    }, {
        name: '$.technology',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.risk',
        uiHint: {
            builder: 'CompletionBuilder',
            completionConfig: {
                type: 'local',
                props: {
                    options: riskValues.map((risk) => ({ value: risk, label: risk }))
                }
            }
        }
    }, {
        name: '$.parent-app',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: 'characteristics',
        childrenNames: [
            '$.able-to-transfer-file',
            '$.consume-big-bandwidth',
            '$.tunnel-other-application',
            '$.has-known-vulnerability',
            '$.used-by-malware',
            '$.evasive-behavior',
            '$.pervasive-use',
            '$.prone-to-misuse',
            '$.tunnel-applications'
        ],
        uiHint: {
            builder: "FieldSetBuilder",
            labelWidthSize: 1,
            layout: [{
                '$.able-to-transfer-file': "col-4",
                '$.consume-big-bandwidth': "col-4",
                '$.tunnel-other-application': "col-4"
            }, {
                '$.has-known-vulnerability': "col-4",
                '$.used-by-malware': "col-4",
                '$.evasive-behavior': "col-4"
            }, {
                '$.pervasive-use': "col-4",
                '$.prone-to-misuse': "col-4",
                '$.tunnel-applications': "col-4"
            }]
        }
    }, {
        name: "$.able-to-transfer-file",
        uiHint: {
            boxLabel: _T("Capable of File Transfer")
        }
    }, {
        name: "$.consume-big-bandwidth",
        uiHint: {
            boxLabel: _T("Excessive Bandwidth Use")
        }
    }, {
        name: "$.evasive-behavior",
        uiHint: {
            boxLabel: _T("Evasive")
        }
    }, {
        name: "$.pervasive-use",
        uiHint: {
            boxLabel: _T("Pervasive")
        }
    }, {
        name: "$.tunnel-applications",
        uiHint: {
            boxLabel: _T("Continue scanning for other \n Applications")
        }
    }, {
        name: 'advanced',
        childrenNames: [
            'defaults',
            'timeouts',
            'scanning'
        ]
    }, {
        name: 'defaults',
        childrenNames: [
            '$.default'
        ],
        uiHint: {
            builder: "FieldSetBuilder"
        }
    }, {
        name: 'timeouts',
        childrenNames: [
            '$.timeout',
            '$.tcp-timeout',
            '$.udp-timeout',
            '$.tcp-half-closed-timeout',
            '$.tcp-time-wait-timeout'
        ],
        uiHint: {
            builder: "FieldSetBuilder",
            layout: [{
                '$.timeout': "col-6",
                '$.tcp-timeout': "col-6"
            }, {
                '$.tcp-half-closed-timeout': "col-6",
                '$.tcp-time-wait-timeout': "col-6"
            }, {
                '$.udp-timeout': "col-6"
            }]
        }
    }, {
        name: 'scanning',
        childrenNames: [
            '$.file-type-ident',
            '$.virus-ident',
            '$.data-ident'
        ],
        uiHint: {
            builder: "FieldSetBuilder",
            labelWidthSize: 1,
            layout: [{
                '$.file-type-ident': "col-4",
                '$.virus-ident': "col-4",
                '$.data-ident': "col-4"
            }]
        }
    }, {
        name: "$.file-type-ident",
        uiHint: {
            boxLabel: _T("File Types")
        }
    }, {
        name: "$.virus-ident",
        uiHint: {
            boxLabel: _T("Viruses")
        }
    }, {
        name: "$.spyware-ident",
        uiHint: {
            boxLabel: _T("Spyware")
        }
    }, {
        name: "$.data-ident",
        uiHint: {
            boxLabel: _T("Data Patterns")
        }
    }, {
        name: 'signatures',
        childrenNames: [
            '$.signature.entry'
        ]
    }
];

const applicationObjectColumns = [{
    type: 'clickableColumn',
    field: 'title',
    cellRendererParams: {
        renderCell: params => {
            let { treeClass, iconClass } = params.data;
            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={e => {
                    if (params.column.colDef.onClick) {
                        params.column.colDef.onClick(params.data, params.rowIndex);
                    }
                }}>
                    <div>
            <span style={{ paddingRight: '2px' }}>{treeClass && <img alt='' src='/images/blank.gif' className={`${treeClass}`} />}</span>
                        <span style={{ paddingRight: '2px', border: 'none' }}><img alt='' src='/images/blank.gif' style={{ paddingRight: '2px', border: 'none' }} className={`${iconClass}`} /></span>
                        {params.value}
                    </div>
                </a>
            );
        }
    },
    recordConfigPath: '$.config.devices.entry.device-group.entry.application.entry',
}, {
    headerName: 'Location',
    field: '@loc',
    maxWidth: 150
}, {
    headerName: 'Category',
    field: 'category',
    cellRendererParams: {
        renderCell: params => {
            if (params.data.children) {
                return '';
            }
            return params.value;
        }
    },
    maxWidth: 125
}, {
    headerName: 'Subcategory',
    field: 'subcategory',
    cellRendererParams: {
        renderCell: params => {
            if (params.data.children) {
                return '';
            }
            return params.value;
        }
    },
    maxWidth: 135
}, {
    headerName: 'Risk',
    field: 'risk',
    cellRendererParams: {
        renderCell: params => {
            if (params.data.children) {
                return '';
            }
            return (<Risk title={params.value} />);
        }
    },
    maxWidth: 75
}, {
    headerName: 'Tags',
    field: 'tag-info',
    style: { whiteSpace: 'unset' },
    cellRendererParams: {
        renderCell: params => {
            if (params.value && params.value.length > 0) {
                let tags = params.value ? params.value : [];
                var formatted = tags.map(function (tag) {
                    let color = tag.color;
                    let styleColor = tagColorIconMap[color];
                    return (
                        <span key={tag.name}>
                            <div
                                style={{
                                    backgroundColor: styleColor,
                                    display: "inline-block",
                                    borderRadius: 10,
                                    height: 10,
                                    width: 10
                                }}
                            />{" "}
                            {tag.name}
                            <br />
                        </span>

                    );
                });
                return (<div>{formatted}</div>);
            }
            return '';
        }
    },
}, {
    headerName: 'Technology',
    field: 'technology',
    cellRendererParams: {
        renderCell: params => {
            if (params.data.children) {
                return '';
            }
            return params.value;
        }
    },
    maxWidth: 120
}, {
    headerName: 'Standard Ports',
    field: 'xml_port_value',
    cellRendererParams: {
        renderCell: params => <span style={{ whiteSpace: 'nowrap' }}>{params.value}</span>
    },
}];

const viewConfig = {
    serviceName: "Objects/AppBrowser",
    serviceMap: {
        GET: "Objects/AppBrowser",
        POST: "Objects/Applications",
        DELETE: "Objects/Applications",
        PUT: "Objects/Applications",
        CLONE: "Objects/Applications"
    },
    reduxId: 'applicationObjects',
    header: "Applications",
    widerForm: true,
    columns: applicationObjectColumns,
    fields: applicationObjectFields,
    recordConfigPath: "$.config.devices.entry.device-group.entry.application.entry",
    hlComponent: AppBrowser,
    rbaPath: 'objects.applications',
    className: 'Applications',
    resultPath: "$"
}

export default class Applications extends DGBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);