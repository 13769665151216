import { getCustomServiceURL, MOCK_SERVICES } from '../../core/services/CommonServices';
import { getAuthHeaders } from '../../auth/lib';

export function getJobsActionObservable({start, limit, filter}) {
    let { SERVICE_URL } = getCustomServiceURL(`api/jobs/v1/?includeTotal=true&start=${start}&limit=${limit}&filter=${filter}`);
    return {
        withCredentials: MOCK_SERVICES ? false : true,
        method: 'GET',
        responseType: 'json',
        url: SERVICE_URL,
        headers: getAuthHeaders()
    };
}

export function getJobDetailsActionObservable(jobid) {
    let { SERVICE_URL } = getCustomServiceURL('api/jobs/v1/' + jobid);
    return {
        withCredentials: MOCK_SERVICES ? false : true,
        method: 'GET',
        responseType: 'json',
        url: SERVICE_URL,
        headers: getAuthHeaders()
    };
}
