import React from 'react';
import PropTypes from 'prop-types';
import Pan from '../../schema/Pan';
import { FormContext, FormConsumer } from '../../FormContext';
import {
    FormGroup,
    Col,
    FormText,
    ListGroup,
    ListGroupItem,
    Input,
} from 'reactstrap';
import { FormFeedbackWidget } from '../../widgets/FormFeedbackWidget/FormFeedbackWidget';
import {
    getFieldLabelComp,
    getFieldWidthClass,
    getFieldHelpString,
    capitalizeLetter,
} from '../../schema/utils';
import classnames from 'classnames';

import './ListGroupBuilder.scss';
import _ from 'lodash';
import { BaseFieldBuilder } from '../BaseBuilder/BaseFieldBuilder';
import { deleteKeyInArray } from '../../widgets/util';
import { validationRulesMap } from '../../../utils/validationRules';

export class ListGroupBuilder extends BaseFieldBuilder {
    static NAME = 'ListGroupBuilder';
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.state = {
            hideField: false,
            value: false,
        };
    }

    setRecordValue(checked, selValue) {
        let valueObj = checked === true ? selValue : '';
        let curValue = this.getRecordValue() || [];
        if (!Array.isArray(curValue)) {
            curValue = [curValue];
        }
        if (valueObj) {
            curValue.push(valueObj);
        } else {
            curValue = curValue.filter(item => {
                if (item.value) {
                    return item.value != selValue;
                } else {
                    return item != selValue;
                }
            });
        }
        super.setRecordValue(curValue, false);
        this.setState({
            value: valueObj,
        });
    }

    execValidationRules(field, value, rules) {
        let errors = super.execValidationRules(field, value, rules);
        if (field && field.uiHint && field.uiHint.allowBlank === false) {
            let error = validationRulesMap['noAllowBlank'](value, field);
            if (error) {
                errors = errors || [];
                errors.push(error);
            }
        }
        if (errors && errors.length > 0) {
            return errors;
        }
        return null;
    }

    getListItems() {
        let { field, disabled } = this.props;
        const value = field.uiHint && (field.uiHint.listOptions || field.uiHint.enum) || [];
        if (!Array.isArray(value)) {
            value = [value];
        }
        let curValue = this.getRecordValue() || [];
        curValue = deleteKeyInArray(curValue, true);
        let displayStrFn = field.uiHint.getDisplayStr;
        let listItems = value.map((item, indx) => {
            let val = Pan.isObject(item) && item.value ? item.value : item;
            let isSelected = curValue && curValue.indexOf(val) >= 0;
            const displayStr = displayStrFn && displayStrFn(val) ? displayStrFn(val) : capitalizeLetter(val);
            return (
                <ListGroupItem disabled={disabled || this.state.disableField} key={indx}>
                    <Input type="checkbox" checked={isSelected} onChange={event => this.setRecordValue(event.target.checked, val)} />
                    {displayStr}
                </ListGroupItem>
            );
        });
        return listItems;
    }

    renderList() {
        let {
            name,
            className,
            field,
            hideLabel,
            containerLabelWidthSize,
            onBlur,
            onFocus,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            autoFocus,
        } = this.props;

        let helpString = getFieldHelpString(field, this.context.formData);
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let textFieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor) + ' pr-0';
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        return (
            <FormGroup className={classnames(className, 'd-flex flex-row list-group')} test_id={field.attrPath}>
                {!hideLabel && LabelComp}
                <Col>
                    {!this.state.hideField &&
                        <ListGroup
                            id={this.id}
                            name={name}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            disabled={disabled || this.state.disableField}
                            className={textFieldClassName}
                            autoFocus={autoFocus}
                        >
                            {this.getListItems()}
                        </ListGroup>
                    }
                    {error && showErrors && <FormFeedbackWidget target={this.id} feedback={error} inline={true} />}
                    {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderList()}</FormConsumer>
            </React.Fragment>
        );
    }
}

ListGroupBuilder.contextType = FormContext;

ListGroupBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false,
};

if (process.env.NODE_ENV !== 'production') {
    ListGroupBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        hideLabel: PropTypes.bool,
        containerLabelWidthSize: PropTypes.string,
        isEditorGridColumnEditor: PropTypes.bool,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onChange: PropTypes.func,
        showErrors: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        disabled: PropTypes.bool,
        autoFocus: PropTypes.func,
        cellInfo: PropTypes.object,
        /**
         * *attrPath* - defines the attribute schema path eg: "$.a.b.c"
         * *defaultValue* - sets the default value of the field.
         * UiHint properties :
         * *allowBlank* - if false,blank values will add error
         * *showHelpString* - if true,will show custom help string/message
         * *helpstring* - set the help string/mesage
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *fieldWidthSize* - The size of the field - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * *fieldLabel* - adds a label to the field
         * *hideLabel* - hides the label
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
         */
        field: PropTypes.shape({
            attrPath: PropTypes.string,
            defaultValue: PropTypes.array,
            uiHint: PropTypes.shape({
                allowBlank: PropTypes.bool,
                showHelpString: PropTypes.bool,
                helpString: PropTypes.string,
                labelWidthSize: PropTypes.string,
                fieldWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                vtype: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            }),
        }),
    };
}
