import { GENERATE_TECH_SUPPORT_DATA_SUCCESS, GET_RECENT_TECH_SUPPORT_REQUESTS_SUCCESS, START_LOADING, FINISH_LOADING } from "./actions";

const ACTION_HANDLERS = {
	[GENERATE_TECH_SUPPORT_DATA_SUCCESS]: (state, { generatedTechSupportData }) => {
		return {
			...state,
			generatedTechSupportData: generatedTechSupportData && generatedTechSupportData.response ? generatedTechSupportData.response : null
		};
	},
	[GET_RECENT_TECH_SUPPORT_REQUESTS_SUCCESS]: (state, { recentTechSupportRequests }) => {
		return {
			...state,
			recentTechSupportRequests: recentTechSupportRequests && recentTechSupportRequests.response ? recentTechSupportRequests.response : null
		};
	},
	[START_LOADING]: (state, { }) => {
		return {
			...state,
			isLoading: true
		};
	},
	[FINISH_LOADING]: (state, { }) => {
		return {
			...state,
			isLoading: false
		};
	}
};

const INITIAL_STATE = {};

export const techSupportReducer = (state = INITIAL_STATE, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
