import React from "react";
import PropTypes from "prop-types";
import { FormContext, FormConsumer } from "../../FormContext";
import { BaseFieldBuilder } from "../BaseBuilder";
import './RegionMapBuilder.scss';
import { ButtonWidget, MapWidget, MapListSwitchWidget, ZoomWidget } from "../../widgets";
import { getFieldLabelComp, getFieldLabel } from "../../schema/utils";
import { FormGroup, Button, Col } from "reactstrap";
import classnames from "classnames";
import { getStoreState } from '../../../utils/storeRegistry';
import { getPathValue } from "../../../utils/json";
import Pan from '../../schema/Pan';
import { showModal, hideModal, updateModal } from "../../../../core/services/actions";
import _ from 'lodash';
import { DetailListWidget } from './DetailListWidget';

export class RegionMapBuilder extends BaseFieldBuilder {
    state = {
        selectArea: '', // after select
        modal: false,
        modalDetail: false,
        view: 'map',
        markArea: [
            { name: "North America", coordinates: [-102.6856, 43.0688], defaultZoom: 3.5, zoomcenter: [-102.6856, 30.0688] },
            { name: "South America", coordinates: [-57.5865, -14.6048], defaultZoom: 2.5, zoomcenter: [-57.5865, -14.6048] },
            { name: "Africa", coordinates: [18.6994, -16.3152], defaultZoom: 2.5, zoomcenter: [18.6994, -2.3152] },
            { name: "Europe", coordinates: [-9.0714, 40.3308], defaultZoom: 3.5, zoomcenter: [23.0714, 50.3308] },
            { name: "Middle East", coordinates: [55.7518, 35.0313], defaultZoom: 5.5, zoomcenter: [40.7518, 32.0313] },
            { name: "Asia", coordinates: [97.5223, 2.6261], defaultZoom: 3.5, zoomcenter: [100.5223, 10.6261] },
            { name: "Japan", coordinates: [140.2529, 42.2048], defaultZoom: 8.5, zoomcenter: [140.2529, 36.2048] },
            { name: "ANZ", coordinates: [141.9833, -34.1908], defaultZoom: 3.5, zoomcenter: [149.9833, -34.1908] }
        ],
        modalCenter: [0, 20],
        textColor: 'black',
        title: 'Select the regions where you want to deploy Prisma Access',
        markSecondArea: [],
        secondLevelData: [], // all data to record local select info
        regionCountryMap: {},
        detailZoom: 2.5,
        selectAllButtonText: 'Select All',
    }

    constructor(props) {
        super(props);
        this.onClickMark = this.onClickMark.bind(this);
        this.onClickDetailMark = this.onClickDetailMark.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onGoBackToOverviewPage = this.onGoBackToOverviewPage.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onZoom = this.onZoom.bind(this);
        this.selectOrDeselectAll = this.selectOrDeselectAll.bind(this);
        this.onClickViewSwitchButton = this.onClickViewSwitchButton.bind(this);
        this.onDetailListCheckboxChange = this.onDetailListCheckboxChange.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.restoreMapData();
    }

    getFormDataCountryContinentMap() {
        let { formData } = this.context;
        let objMap = {};
        let regionData = getPathValue(formData, this.props.field.name);
        const regionInfo = getPathValue(getStoreState(), 'gpcs.regionInfo') || {};
        const countryContinentMap = _.keyBy(regionInfo, '@value');
        if (!Pan.isEmpty(regionInfo) && !Pan.isEmpty(regionData)) {
            for (let i = 0; i < regionData.length; i++) {
                let country = regionData[i].locations.member;
                if (country.length > 0) {
                    country = country.map(country => {
                        return country.value ? country.value : country;
                    });
                }
                for (let j = 0; j < country.length; j++) {
                    if (countryContinentMap[country[j]]) {
                        let continent =
                            countryContinentMap[country[j]]['@continent'];
                        if (!objMap[continent]) {
                            objMap[continent] = {};
                            objMap[continent].total = 0;
                            objMap[continent].regions = [];
                        }
                        objMap[continent].total++;
                        objMap[continent].regions.push(country[j]);
                    }
                }
            }
        }
        return objMap;
    }

    restoreMapData() {
        let { formData } = this.context;
        let regionData = getPathValue(formData, this.props.field.name);
        const regionInfo = getPathValue(getStoreState(), 'gpcs.regionInfo') || {};
        const countryContinentMap = _.keyBy(regionInfo, '@value');
        if (!Pan.isEmpty(regionInfo) && !Pan.isEmpty(regionData)) {
            let secondLevelData = [];
            for (let i = 0; i < regionData.length; i++) {
                let country = regionData[i].locations.member;
                if (country.length > 0) {
                    country = country.map(country => {
                        return country.value ? country.value : country;
                    });
                }
                for (let j = 0; j < country.length; j++) {
                    if (countryContinentMap[country[j]]) {
                        secondLevelData.push(country[j]);
                    }
                }
            }
            this.setState({
                secondLevelData,
                view: 'map',
                modalDetail: false,
                detailZoom: 2.5
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        return !Pan.isEmpty(mapRegionInfo);
    }

    onClick = () => {
        this.setState({
            modal: true,
            title: this.onGetModalTitle()
        }, () => {
            this.showViewModal();
        });
    }

    hideMapModal = () => {
        hideModal("RegionMapModal");
    }

    hideDetailMapModal = () => {
        hideModal("RegionDetailMapModal");
    }

    onCancel = () => {
        this.restoreMapData();
        hideModal("RegionMapModal");
    }

    getFirstLevelData = () => {
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        let firstLevelData = _.cloneDeep(mapRegionInfo);
        let regions = this.state.secondLevelData;
        Object.keys(firstLevelData).forEach((key) => {
            for (let i = 0; i < firstLevelData[key].regions.length; i++) {
                if (regions.includes(firstLevelData[key].regions[i].name)) {
                    if (!firstLevelData[key].choice) {
                        firstLevelData[key].choice = 0;
                    }
                    firstLevelData[key].choice++;
                }
            }
        });

        return firstLevelData;
    }

    getDefaultZoom() {
        const selectArea = this.state.markArea.find(area => area['name'] === this.state.selectArea);
        return selectArea ? selectArea.defaultZoom : this.state.detailZoom;
    }

    showDetailViewMap = () => {
        let { defaultFillColorForDetailMap, hoverFillColor, pressedFillColor, onClickDetailMark } = this;
        let { modalDetail, markSecondArea, modalCenter, detailZoom, secondLevelData, title } = this.state;
        let operationButtons = this.renderOperationButtons();
        let bottomOperationButtons = this.renderBottomOperationButtons();
        let zoom = this.getDefaultZoom();
        let modal = {
            id: "RegionMapModal",
            title: title,
            size: "lg",
            style: { padding: '0' },
            type: 'Info',
            modalBody: {
                component: MapWidget,
                props: {
                    defaultFillColor: defaultFillColorForDetailMap,
                    hoverFillColor,
                    pressedFillColor,
                    onClickMark: onClickDetailMark,
                    markType: "selectCircle",
                    markArea: markSecondArea,
                    center: modalCenter,
                    zoom,
                    data: secondLevelData,
                    operationButtons,
                    bottomOperationButtons,
                    modalDetail,
                    showTooltip: true,
                    disablePanning: false
                }
            },
            modalBodyStyle: {
                padding: 0,
                maxHeight: 'calc(100vh - 220px)'
            },
            toggle: this.onGoBackToOverviewPage,
            actions: [
                {
                    text: 'Back To All Locations',
                    color: 'secondary',
                    style: { color: '#57b5ec', borderColor: '#0b93d1', borderWidth: 'thin' },
                    buttonClass: 'btn-sm btn-blue',
                    hidden: !this.state.showDetailMap,
                    action: this.onGoBackToOverviewPage
                }
            ]
        };
        showModal(modal);
    }

    showViewModal = () => {
        if (this.state.modalDetail) {
            return this.showDetailViewMap();
        }
        let { defaultFillColor, onClickMark, onMouseEnter, onMouseLeave } = this;
        let { selectArea, markArea, textColor, modalDetail } = this.state;
        let firstLevelData = this.getFirstLevelData();
        let operationButtons = this.renderOperationButtons();
        let modal = {
            id: "RegionMapModal",
            title: this.onGetModalTitle(),
            size: "lg",
            style: { padding: '0' },
            type: 'Info',
            modalBody: {
                component: MapWidget,
                props: {
                    defaultFillColor,
                    onClickMark,
                    modalDetail,
                    markType: "rectangle",
                    selectArea,
                    markArea,
                    textColor,
                    data: firstLevelData,
                    onMouseEnter,
                    onMouseLeave,
                    operationButtons
                }
            },
            modalBodyStyle: {
                padding: 0,
                maxHeight: 'calc(100vh - 220px)'
            },
            toggle: this.onCancel,
            actions: [
                {
                    text: 'Cancel',
                    color: "secondary",
                    buttonClass: 'btn-sm',
                    action: this.onCancel
                },
                {
                    text: 'Save',
                    color: "primary",
                    buttonClass: 'btn-sm',
                    action: this.onSave
                }
            ]
        };
        showModal(modal);
    }

    onZoom = (value) => {
        let zoom = this.getDefaultZoom();
        if (!value) {
            this.setState({
                detailZoom: zoom
            }, () => {
                this.updateViewModal();
            });
        } else {
            let newZoom = Math.max(this.state.detailZoom + value, zoom);
            this.setState({
                detailZoom: newZoom
            }, () => {
                this.updateViewModal();
            });
        }
    }

    onListView = () => {
        console.log('go to list view');
    }

    onGoBackToOverviewPage = () => {
        this.setState({
            title: this.onGetModalTitle(),
            selectAllButtonText: 'Select All',
            modalDetail: false,
            selectArea: '',
            detailZoom: 2.5
        }, () => {
            this.updateViewModal();
        });
    };

    onSave = () => {
        let globalMapRegionInfo = getPathValue(getStoreState(), 'gpcs.globalMapRegionInfo') || {};
        let countryRegionMap = _.keyBy(globalMapRegionInfo, 'name');
        let objMap = {};
        let regions = this.state.secondLevelData;
        for (let i = 0; i < regions.length; i++) {
            let country = regions[i];
            let region = countryRegionMap[country].region;
            if (!objMap[region]) {
                objMap[region] = [];
            }
            objMap[region].push(country);
        }
        let val = [];
        for (const [key, value] of Object.entries(objMap)) {
            val.push({
                '@name': key,
                locations: {
                    member: value,
                },
            });
        }
        this.setRecordValue(val, true);
        this.setState({
            regionCountryMap: objMap,
            secondLevelData: regions,
            view: 'map',
            modalDetail: false
        }, () => {
            this.hideMapModal();
        });
    };

    selectOrDeselectAll = () => {
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        if (this.state.modalDetail) {
            let regions = mapRegionInfo[this.state.selectArea].regions.map(item => item.name);
            if (this.state.selectAllButtonText === 'Select All') {
                this.setState({
                    selectAllButtonText: 'Deselect All',
                    secondLevelData: _.uniq([...this.state.secondLevelData, ...regions])
                }, () => {
                    this.updateViewModal();
                });
            } else {
                this.setState({
                    selectAllButtonText: 'Select All',
                    secondLevelData: this.state.secondLevelData.filter(item => !regions.includes(item))
                }, () => {
                    this.updateViewModal();
                });
            }
        } else {
            let regions = _.values(mapRegionInfo).reduce((acc, item) => acc.concat(item.regions.map(o => o.name)), []);
            if (this.state.selectAllButtonText === 'Select All') {
                this.setState({
                    selectAllButtonText: 'Deselect All',
                    secondLevelData: regions
                }, () => {
                    this.updateViewModal();
                });
            } else {
                this.setState({
                    selectAllButtonText: 'Select All',
                    secondLevelData: []
                }, () => {
                    this.updateViewModal();
                });
            }
        }
    }

    defaultFillColor = (geography) => {
        const middle_east = ['Bahrain', 'Cyprus', 'Egypt', 'Iran', 'Iraq', 'Israel', 'Jordan', 'Kuwait', 'Lebanon', 'Oman', 'Qatar', 'Saudi Arabia', 'Syria', 'Turkey', 'United Arab Emirates', 'Yemen']
        if (this.state.selectArea === '') {
            return '#d9d9d9';
        }
        if (this.state.selectArea === 'ANZ' && geography.properties.continent === 'Oceania') {
            return '#979797';
        }
        if (this.state.selectArea === 'Asia' && geography.properties.continent === 'Asia' && geography.properties.name !== 'Japan') {
            return '#979797';
        }
        if (this.state.selectArea === 'Japan' && geography.properties.name === 'Japan') {
            return '#979797';
        }
        if (this.state.selectArea === 'Middle East' && middle_east.indexOf(geography.properties.name) !== -1) {
            return '#979797';
        }
        if (this.state.selectArea === geography.properties.continent) {
            return '#979797';
        }
        return '#d9d9d9';
    };

    defaultFillColorForDetailMap = () => {
        return '#d9d9d9';
    }

    hoverFillColor = () => {
        return '#d9d9d9';
    }

    hoverFillColorForDetailMap = () => {
        return '#979797';
    }

    pressedFillColor = () => {
        return '#d9d9d9';
    }

    pressedFillColorForDetailMap = () => {
        return '#979797';
    }

    numToStr = (num) => {
        if (num < 10) {
            return '0' + num;
        } else {
            return num;
        }
    }

    onMouseEnter = (mark) => {
        // this.setState({
        //     selectArea: mark
        // }, () => {
        //     this.updateViewModal();
        // });
    }

    onMouseLeave = () => {
        // if (!this.state.modalDetail) {
        //     this.setState({
        //         selectArea: ''
        //     }, () => {
        //         this.updateViewModal();
        //     });
        // }
    }

    onGetModalTitle = (type, continent) => {
        if (type === 'detail') {
            let firstLevelData = this.getFirstLevelData();
            let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
            let area = !this.state.selectArea || this.state.selectArea === '' ? continent : this.state.selectArea;
            let total = firstLevelData[area].total;
            let regions = (mapRegionInfo[area] && mapRegionInfo[area].regions) ? mapRegionInfo[area].regions.map(obj => obj.name) : [];
            let choice = this.state.secondLevelData.filter((item) => regions.includes(item)).length;

            return (
                <div>
                    Selected: {this.numToStr(choice)}/{this.numToStr(total)}
                </div>
            );
        } else {
            return (
                <div>
                    Select the locations where you want to deploy Prisma Access
                </div>
            );
        }
    }

    onClickMark = (area) => {
        let firstLevelData = this.getFirstLevelData();
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        if (!firstLevelData[area]) {
            return;
        }
        let total = firstLevelData[area].total;
        let regions = mapRegionInfo[area].regions.map(obj => obj.name);
        let choice = this.state.secondLevelData.filter((item) => regions.includes(item)).length;

        if (this.state) {
            let _area = _.find(this.state.markArea, ['name', area]);
            let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
            let markSecondArea = mapRegionInfo[_area.name].regions.map(region => ({ name: region.name, display_name: region.display_name, coordinates: [region['@longitude'], region['@latitude']] }));
            this.setState({
                modalCenter: _area.zoomcenter,
                modalDetail: true,
                title: this.onGetModalTitle('detail', area),
                markSecondArea,
                selectAllButtonText: total === choice ? "Deselect All" : 'Select All',
                selectArea: area
            }, () => {
                this.updateViewModal();
            });
        } else {
            this.setState({
                showDetailMap: true,
                modalDetail: true,
            }, () => {
                this.updateViewModal();
            });
        }
    };

    updateViewModal() {
        let { defaultFillColorForDetailMap, hoverFillColor, pressedFillColor, onClickDetailMark, defaultFillColor, onClickMark, onMouseEnter, onMouseLeave, onDetailListCheckboxChange } = this;
        let { view, modalDetail, markSecondArea, modalCenter, secondLevelData, detailZoom, selectArea, markArea, textColor } = this.state;
        let firstLevelData = this.getFirstLevelData();
        let operationButtons = this.renderOperationButtons();
        let bottomOperationButtons = this.renderBottomOperationButtons();
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        const zoom = Math.max(this.getDefaultZoom(), detailZoom);
        let props, modalBody, title = this.onGetModalTitle(), actions = [
            {
                text: 'Cancel',
                color: "secondary",
                buttonClass: 'btn-sm',
                action: this.onCancel
            },
            {
                text: 'Save',
                color: "primary",
                buttonClass: 'btn-sm',
                action: this.onSave
            }
        ];
        if (modalDetail) {
            actions = [{
                text: 'Back To All Locations',
                color: 'secondary',
                style: { color: '#57b5ec', borderColor: '#0b93d1', borderWidth: 'thin' },
                buttonClass: 'btn-sm btn-blue',
                hidden: !this.state.showDetailMap,
                action: this.onGoBackToOverviewPage
            }]
            title = this.onGetModalTitle('detail');
        }
        if (view === 'map') {
            if (modalDetail) {
                props = {
                    defaultFillColor: defaultFillColorForDetailMap,
                    hoverFillColor,
                    pressedFillColor,
                    onClickMark: onClickDetailMark,
                    markType: "selectCircle",
                    markArea: markSecondArea,
                    center: modalCenter,
                    zoom,
                    data: secondLevelData,
                    operationButtons,
                    bottomOperationButtons,
                    disablePanning: false,
                    modalDetail,
                    showTooltip: true
                }
                modalBody = {
                    component: MapWidget,
                    props
                }
            } else {
                props = {
                    defaultFillColor,
                    onClickMark,
                    markType: "rectangle",
                    selectArea,
                    markArea,
                    textColor,
                    data: firstLevelData,
                    onMouseEnter,
                    onMouseLeave,
                    operationButtons,
                    modalDetail
                };
                modalBody = {
                    component: MapWidget,
                    props
                }
            }
        } else {
            props = {
                data: secondLevelData,
                selectArea,
                mapRegionInfo,
                operationButtons,
                onDetailListCheckboxChange,
                modalDetail
            }
            modalBody = {
                component: DetailListWidget,
                props
            }
        }
        updateModal('RegionMapModal', {
            modalBody,
            title,
            actions
        });
    }

    onDetailListCheckboxChange = (val, region) => {
        if (val) {
            if (Pan.isArray(region)) {
                this.setState({
                    secondLevelData: [...this.state.secondLevelData, ...region]
                }, () => {
                    this.updateViewModal();
                });
            } else {
                this.setState({
                    secondLevelData: [...this.state.secondLevelData, region]
                }, () => {
                    this.updateViewModal();
                });
            }
        } else {
            if (Pan.isArray(region)) {
                this.setState({
                    secondLevelData: this.state.secondLevelData.filter(data => region.indexOf(data) < 0)
                }, () => {
                    this.updateViewModal();
                });
            } else {
                this.setState({
                    secondLevelData: this.state.secondLevelData.filter(data => data !== region)
                }, () => {
                    this.updateViewModal();
                });
            }
        }
    }

    onClickDetailMark = (data) => {
        let firstLevelData = this.getFirstLevelData();
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        let area = this.state.selectArea;
        let total = firstLevelData[area].total;
        let regions = mapRegionInfo[area].regions.map(obj => obj.name);
        if (!this.state.secondLevelData.includes(data)) {
            let newSecondLevelData = [...this.state.secondLevelData, data];
            let choice = newSecondLevelData.filter((item) => regions.includes(item)).length;
            this.setState({
                secondLevelData: newSecondLevelData,
                selectAllButtonText: total === choice ? "Deselect All" : 'Select All'
            }, () => {
                this.updateViewModal();
            });
        } else {
            let newSecondLevelData = this.state.secondLevelData.filter(region => region !== data);
            let choice = newSecondLevelData.filter((item) => regions.includes(item)).length;
            this.setState({
                secondLevelData: newSecondLevelData,
                selectAllButtonText: total === choice ? "Deselect All" : 'Select All'
            }, () => {
                this.updateViewModal();
            });
        }
    };

    clickRegionInfo = (continent) => {
        let area = _.find(this.state.markArea, ['name', continent]);
        let mapRegionInfo = getPathValue(getStoreState(), 'gpcs.info') || {};
        let markSecondArea = mapRegionInfo[area.name].regions.map(region => ({ name: region.name, display_name: region.display_name, coordinates: [region['@longitude'], region['@latitude']] }));
        let formDataCountryContinentMap = this.getFormDataCountryContinentMap();
        if (this.state.secondLevelData.length === 0 && !Pan.isEmpty(formDataCountryContinentMap)) {
            this.setState({
                modal: true,
                modalDetail: true,
                modalCenter: area.zoomcenter,
                markSecondArea,
                title: this.onGetModalTitle('detail', continent),
                selectArea: continent,
                secondLevelData: formDataCountryContinentMap[continent].regions
            }, () => {
                this.showViewModal();
            });
        } else {
            this.setState({
                modal: true,
                modalDetail: true,
                modalCenter: area.zoomcenter,
                markSecondArea,
                selectArea: continent,
                title: this.onGetModalTitle('detail', continent)
            }, () => {
                this.showViewModal();
            });
        }
    };

    renderSelectRegionInfo = () => {
        let { from } = this.props;
        let formDataCountryContinentMap = this.getFormDataCountryContinentMap();
        let styles = from === 'summary' ? { 'display': 'inline-block' } : {};
        if (!Pan.isEmpty(this.state.regionCountryMap)) {
            const regionInfo = getPathValue(getStoreState(), 'gpcs.regionInfo') || {};
            const countryContinentMap = _.keyBy(regionInfo, '@value');
            let countryContinentData = {};
            this.state.secondLevelData.forEach(country => {
                if (!countryContinentData[countryContinentMap[country]['@continent']]) {
                    countryContinentData[countryContinentMap[country]['@continent']] = {};
                    countryContinentData[countryContinentMap[country]['@continent']].total = 0;
                    countryContinentData[countryContinentMap[country]['@continent']].regions = [];
                }
                countryContinentData[countryContinentMap[country]['@continent']].total++;
                countryContinentData[countryContinentMap[country]['@continent']].regions.push(country);
            });
            const len = Object.entries(countryContinentData).length;
            return (
                <div style={styles}>
                    {
                        Object.entries(countryContinentData).map((entry, i) => {
                            if (i !== len - 1) {
                                return (
                                    from === 'summary' ?
                                        <span key={entry[0]}>
                                            <u>{entry[0]}</u>({entry[1].total}),
                                        </span> :
                                        <span key={entry[0]}>
                                            <u onClick={() => this.clickRegionInfo(entry[0])}>{entry[0]}</u>({entry[1].total}), &nbsp;
                                        </span>
                                );
                            } else {
                                return (
                                    from === 'summary' ?
                                        <span key={entry[0]}>
                                            <u>{entry[0]}</u>({entry[1].total})
                                        </span> :
                                        <span key={entry[0]}>
                                            <u onClick={() => this.clickRegionInfo(entry[0])}>{entry[0]}</u>({entry[1].total})
                                        </span>
                                );
                            }
                        })
                    }
                </div>
            )
        } else if (!Pan.isEmpty(formDataCountryContinentMap)) {
            const len = Object.entries(formDataCountryContinentMap).length;
            return (
                <div style={styles}>
                    {
                        Object.entries(formDataCountryContinentMap).map((entry, i) => {
                            if (i !== len - 1) {
                                return (
                                    from === 'summary' ?
                                        <span key={entry[0]}>
                                            {entry[0]}({entry[1].total}),
                                        </span> :
                                        <span key={entry[0]}>
                                            <u onClick={() => this.clickRegionInfo(entry[0])}>{entry[0]}</u>({entry[1].total}), &nbsp;
                                        </span>
                                );
                            } else {
                                return (
                                    from === 'summary' ?
                                        <span key={entry[0]}>
                                            {entry[0]}({entry[1].total})
                                        </span> :
                                        <span key={entry[0]}>
                                            <u onClick={() => this.clickRegionInfo(entry[0])}>{entry[0]}</u>({entry[1].total})
                                        </span>
                                );
                            }
                        })
                    }
                </div>
            );
        }
        return from === 'summary' ?
            (
                <span>No</span>
            ) :
            (
                <></>
            )
    }

    onClickViewSwitchButton = (view) => {
        this.setState({
            view
        }, () => {
            this.updateViewModal();
        })
    }

    renderBottomOperationButtons = () => {
        if (this.state.modalDetail) {
            return <ZoomWidget onClick={this.onZoom} />
        } else {
            return <></>
        }
    }

    renderOperationButtons = () => {
        return (
            <>
                <Button
                    style={{ color: '#57b5ec', borderColor: '#0b93d1', borderWidth: 'thin', width: '110px' }}
                    onClick={this.selectOrDeselectAll}
                >
                    {this.state.selectAllButtonText}
                </Button>
                <img width="35" height="35" style={{ marginLeft: '2px' }} src="/images/vertical-line.svg" />
                <MapListSwitchWidget value={this.state.view} onClick={this.onClickViewSwitchButton} />
            </>
        );
    }

    renderChildren() {
        let {
            name,
            className,
            field,
            containerLabelWidthSize,
            isEditorGridColumnEditor,
            from
        } = this.props;
        let formDataCountryContinentMap = this.getFormDataCountryContinentMap();
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let LabelComp = from === 'summary' ? (<span><b>{getFieldLabel(field)}:</b></span>) : getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        return from === 'summary' ?
            (
                <React.Fragment>
                    {LabelComp}
                    {this.renderSelectRegionInfo()}
                </React.Fragment>
            ) :
            (
                <FormGroup className={classnames(className, `d-flex flex-row ${required}`)} test_id={field.attrPath}>
                    {LabelComp}
                    <Col>
                        {this.renderSelectRegionInfo()}
                        {Pan.isEmpty(formDataCountryContinentMap) && from !== 'summary' && <ButtonWidget className="btn btn-secondary" onClick={this.onClick} text="Add" />}
                        {!Pan.isEmpty(formDataCountryContinentMap) && from !== 'summary' && <ButtonWidget className="btn btn-secondary" onClick={this.onClick} text="Edit" />}
                    </Col>
                </FormGroup>
            );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderChildren()}</FormConsumer>
            </React.Fragment>
        );
    }
}

RegionMapBuilder.contextType = FormContext;

RegionMapBuilder.defaultProps = {};

if (process.env.NODE_ENV !== 'production') {
    RegionMapBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        containerLabelWidthSize: PropTypes.string,
        isEditorGridColumnEditor: PropTypes.bool,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onChange: PropTypes.func,
        showErrors: PropTypes.bool,
        cellInfo: PropTypes.object,
        /**
        * *defaultValue* - sets the default value of the field.
        * UiHint properties :
        * *allowBlank* - if false,blank values will add error
        * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
        * Takes precedence over containerLabelWidthSize
        * *fieldLabel* - adds a label to the field
        * *hideLabel* - hides the label
        * *customValidation* - custom function for validation
        * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
        * The string values available are -
        * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
        * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
        * isIpAddressMask,ipRange,multiVtype
        */
        field: PropTypes.shape({
            defaultValue: PropTypes.string,
            uiHint: PropTypes.shape({
                allowBlank: PropTypes.bool,
                labelWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                vtype: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            }),
        }),
    };
}
