import { parseError, SERVER_ERROR, parseAutoCompletionToOptions } from "ui-lib";
import DGBaseViewer from "../../deviceGroup/DGBaseViewer";
import { ajax } from 'rxjs/ajax';
import { getRecordsActionObservable } from 'service-lib';
import { store } from '../../../store';

const _T = str => str;

const hipProfilesFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        },
    },
    {
        name: '$.description',
        uiHint: {
            fieldLabel: _T('Description'),
        },
    },
    {
        name: '$.match',
        uiHint: {
            fieldLabel: _T('Match'),
            showHelpString: _T('Press ESC to close suggestion'),
            builder: 'AutoSuggestBuilder',
            builderConfig: {
                showNotCondition: true
            },
            completionConfig: {
                CustomCompletion: (inputValue, completionXpath, configLocation, formData, filters) => {
                    let url = getRecordsActionObservable('Objects/HIPCompletion', configLocation);
                    console.log('url', url);
                    let completinObservable = ajax(url);
                    return new Promise(function (resolve, reject) {
                        completinObservable.toPromise().then(
                            resp => {
                                let completions = parseAutoCompletionToOptions(resp, true, "@value");
                                resolve(completions);
                            },
                            err => {
                                store.dispatch({
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(err),
                                    showMessage: false
                                });
                                // reject(new Error('Error in Auto Completion: ' + err ));
                            }
                        );
                    });
                }
            }
        },
    },
    {
        name: '$',
        childrenNames: ['name', 'location', '$.description', '$.match'],
    },
];

const hipProfilesObjectColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        minWidth: 100
    },
    {
        headerName: 'Location',
        field: '@loc',
        minWidth: 100
    },
    {
        headerName: 'Match',
        field: 'match',
        minWidth: 100
    },
    {
        headerName: 'Description',
        field: 'description',
        minWidth: 100
    },
];

export default class HIPProfiles extends DGBaseViewer {

    serviceName = 'Objects/GlobalProtectHIPProfiles';
    reduxId = 'hipProfiles';
    header = 'HIP Profiles';
    columns = hipProfilesObjectColumns;
    fields = hipProfilesFields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.hip-profiles.entry';
    rbaPath = 'objects.global-protect.hip-profiles';
}

