import { getPathValue, arrayRenderer } from 'ui-lib';
import React from "react";
import { PolicyAddressColumn } from './PolicyAddressColumn';
import { PolicyApplicationColumn } from './PolicyApplicationColumn';
import { PolicyServiceColumn } from './PolicyServiceColumn';
import { getUserDisplayLabel } from './CommonFields';

const _T = str => str;

const columnConfig = {
    // number: {
    //     headerValueGetter: (params) => {
    //         return ' ';
    //     },
    //     field: 'ruleNumber',
    //     sortable: false,
    //     suppressMenu: true,
    //     style: {
    //         textAlign: 'center'
    //     },
    //     cellRendererParams: {
    //         renderCell: params => {
    //             return params.node && params.node.ruleNumber;
    //         }
    //     },
    //     minWidth: 40,
    //     maxWidth: 40
    // },
    name: {
        type: ['nameColumn', 'clickableColumn'],
        sortable: false 
    },
    location: {
        headerName: 'Location',
        sortable: false,
        field: '@loc',
        minWidth: 150
    },
    tag: {
        headerName: 'Tag',
        sortable: false,
        field: 'tag.member'
    },
    from: {
        headerName: _T('Zone'),
        field: 'from.member',
        sortable: false,
        minWidth: 100,
        cellRendererParams: {
            renderCell: params => {
                if (params.data.from && params.data.from.member) {
                    return (
                        <div
                            style={{textDecoration: 'none'}}>
                            {arrayRenderer(params.data.from.member)}
                        </div>);    
                }  else {
                    return 'any';
                }
            }
        }
    },
    to: {
        headerName: _T('Zone'),
        field: 'to.member',
        sortable: false,
        minWidth: 100,
        cellRendererParams: {
            renderCell: params => {
                if (params.data.to && params.data.to.member) {
                    return (
                        <div
                            style={{textDecoration: 'none'}}>
                            {arrayRenderer(params.data.to.member)}
                        </div>);    
                }  else {
                    return 'any';
                }
            }
        }
    },
    single_to: {
        headerName: _T('Zone'),
        field: 'to',
        sortable: false,
        minWidth: 100
    },
    source: {
        headerName: _T('Address'),
        field: 'source',
        sortable: false,
        minWidth: 110
    },
    source_negate: {
        headerName: _T('Address'),
        field: 'source',
        sortable: false,
        minWidth: 110,
        cellRendererParams: {
            renderCell: params => {
                if (params.data.source && params.data.source.member) {
                    return <PolicyAddressColumn data={params.data.source.member} negateStyle={params.data["negate-source"] === "yes" ? "line-through" : "none"} />;
                } else {
                    return 'any';
                }
            }
        }
    },
    source_user: {
        headerName: _T('User'),
        field: 'source-user',
        sortable: false,
        minWidth: 150,
        cellRendererParams: {
            renderCell: params => {
                if (params.data['source-user'] && params.data['source-user'].member) {
                    let users = params.data['source-user'].member;
                    users = users.map(item => getUserDisplayLabel(item));
                    return (
                        <div>
                            {arrayRenderer(users)}
                        </div>
                    );
                } else {
                    return 'any';
                }
            }
        }
    },
    source_hip: {
        headerName: _T('Device'),
        field: 'source-hip',
        sortable: false,
        minWidth: 100
    },
    destination_hip: {
        headerName: _T('Device'),
        property: 'destination-hip',
        sortable: false,
        width: 100
    },
    destination: {
        headerName: _T('Destination'),
        field: 'destination',
        sortable: false,
        minWidth: 110
    },
    destination_negate: {
        headerName: _T('Address'),
        field: 'destination.member',
        sortable: false,
        minWidth: 110,
        cellRendererParams: {
            renderCell: params => {
                if (params.data.destination && params.data.destination.member) {
					return <PolicyAddressColumn data={params.data.destination.member} negateStyle={params.data["negate-destination"] === "yes" ? "line-through" : "none"} />;
				} else {
					return "any";
				}
            }
        }
    },
    application: {
        headerName: _T('Application'),
        field: 'application.member',
        sortable: false,
        minWidth: 100,
        cellRendererParams: {
            renderCell: params => {
                return <PolicyApplicationColumn data={params.data.application ? params.data.application.member : []} />;
            }
        }
    },
    single_application: {
        headerName: _T('Application'),
        field: 'application',
        sortable: false,
        minWidth: 105
    },
    service: {
        headerName: _T('Service'),
        field: 'service',
        sortable: false,
        hide: true,
        minWidth: 150,
        cellRendererParams: {
            renderCell: params => {
                return <PolicyServiceColumn data={params.data.service ? params.data.service.member : []} />;
            }
        }
    },
    single_service: {
        headerName: _T('Service'),
        field: 'service',
        sortable: false,
        minWidth: 100
    },
    hip_profile: {
        headerName: _T('HIP Profile'),
        field: 'hip-profiles',
        sortable: false,
        minWidth: 100,
        cellRendererParams: {
            renderCell: params => {
                if (params.data['hip-profiles'] && params.data['hip-profiles'].member) {
                    let value = params.data['hip-profiles'].member;
                    let formatted = value.map((val, index) => {
                            if (val === 'any') {
                                return val;
                            }
                            return (
                                <div key={index} className="array-value-inner-cell">
                                    <img src="/images/treemenu/hip-profiles.png" alt="hip-profile" />
                                    {val}
                                </div>
                            )
                    });
                    return (
                        <div>
                            {formatted}
                        </div>
                    );
                } else {
                    return 'any';
                }
            }
        }
    },
    protocol: {
        headerName: _T('Protocol'),
        field: 'protocol',
        sortable: false,
        maxWidth: 120
    },
    port: {
        headerName: _T('Port'),
        field: 'port',
        sortable: false,
        maxWidth: 100
    },
    action: {
        headerName: _T('Action'),
        field: 'action',
        sortable: false,
        minWidth: 100
    },
    profile_setting: {
        headerName: _T('Profile'),
        field: 'profile-setting',
        //hide: true,
        sortable: false,
        style: {
            textAlign: 'left'
        },
        minWidth: 120,
        cellRendererParams: {
            renderCell: params => {
                let type = params.data['profile-setting'];
                let ret =[];
                let title = '';
                if (type) {
                    if (type["group"]) {
                        let pg = getPathValue(params.data, "profile-setting.group.member");
                        let title =  `Profile Group: ${pg}`;/* , 'profile_group' */
                        ret.push(<img src="/images/treemenu/security-profile-groups.png" alt="Profile Group" title={title}/>);
                    } else if (type["profiles"]) {
                        let p1 = getPathValue(params.data, "profile-setting.profiles.virus.member");
                        if (p1) {
                            title = `Antivirus Profile: ${p1}`;
                            ret.push( <img src="/images/treemenu/antivirus.png" alt="Antivirus Profile" title={title}/>);
                        }
                        let p2 = getPathValue(params.data, "profile-setting.profiles.spyware.member");
                        if (p2) {
                            title = `Anti-Spyware Profile: ${p2}`
                            ret.push(<img src="/images/treemenu/anti-spyware.png" alt="Anti-Spyware Profile" title={title}/>);
                        }
                        let p3 = getPathValue(params.data, "profile-setting.profiles.vulnerability.member");
                        if (p3) {
                            title = `Vulnerability Protection Profile: ${p3}`;
                            ret.push(<img src="/images/treemenu/vulnerability-protection.png" alt="Vulnerability Protection Profile" title={title}/>);
                        }
                        let p4 = getPathValue(params.data, "profile-setting.profiles.url-filtering.member");
                        if (p4) {
                            let title = `URL Filtering Profile: ${p4}`;
                            ret.push(<img src="/images/treemenu/url-filtering.png" alt="URL Filtering Profile" title={title}/>);
                        }
                        let p5 = getPathValue(params.data, "profile-setting.profiles.file-blocking.member");
                        if (p5) {
                            let title = `File Blocking Profile: ${p5}`;
                            ret.push(<img src="/images/treemenu/file-blocking.png" alt="File Blocking Profile" title={title}/>);
                        }
                        let p6 = getPathValue(params.data, "profile-setting.profiles.data-filtering.member");
                        if (p6) {
                            let title = `Data Filtering Profile: ${p6}`;
                            ret.push(<img src="/images/treemenu/data-filtering.png" alt="Data Filtering Profile" title={title}/>);
                        }
                        let p7 = getPathValue(params.data, "profile-setting.profiles.wildfire-analysis.member");
                        if (p7) {
                            let title = `WildFire Analysis Profile: ${p7}`;
                            ret.push(<img src="/images/treemenu/wildfire-analysis.png" alt="Wildfire Analysis Profile" title={title}/>);
                        }
                        let p8 = getPathValue(params.data, "profile-setting.profiles.gtp.member");
                        if (p8) {
                            let title = `Mobile Network Protection Profile: ${p8}`;
                            ret.push(<img src="/images/treemenu/data-filtering.png" alt="Data Filtering Profile" title={title}/>);
                        }
                        let p9 = getPathValue(params.data, "profile-setting.profiles.sctp.member");
                        if (p9) {
                            let title = `SCTP Protection Profile: ${p9}`;
                            ret.push(<img src="/images/treemenu/data-filtering.png" alt="SCTP Protection Profile" title={title}/>);
                        }
                    }
                }
                return (<div>{ret}</div>);
            }
        },
    },
    options: {
        headerName: _T('Options'),
        //hide: true,
        field: 'log-setting',
        cellRendererParams: {
            renderCell: params => {
                let ret = [];
                let title = '';
                let logstart = getPathValue(params.data, "$.log-start");
                let logend = getPathValue(params.data, "$.log-end");
                if (logstart && logend) {
                    title = "Traffic log sent at session start, and at session end";
                } else {
                    if (logstart) {
                        title = "Traffic log sent at session start"            
                    }
                    if (logend) {
                        title = "Traffic log sent at session end";
                    }
                }
                if (title) {
                    ret.push(<img src="/images/log.png" alt="Log" title={title}/>);
                }
                let logsetting = getPathValue(params.data, "log-setting");
                if (logsetting) { 
                    title = `Log Forwarding: ${logsetting}`;
                    ret.push(<img src="/images/log_fwd.png" alt="Log Fowarding" title={title}/>);
                }
                let val = getPathValue(params.data, "qos.marking.ip-dscp");
                if (val) {
                    title = 'QoS Marking: IP DSCP';
                    ret.push(<img src="/images/treemenu/qos.png" alt="QoS" title={title}/>);
                }
                val = getPathValue(params.data, "qos.marking.ip-precedence");
                if (val) {
                    title = 'QoS Marking: IP Precedence';
                    ret.push(<img src="/images/treemenu/qos.png" alt="QoS" title={title}/>);
                }
                return ret;
            }
        },
        sortable: false,
        style: {
            textAlign: 'left'
        },
        minWidth: 95
    },
    description: {
        headerName: _T('Description'),
        field: 'description',
        sortable: false,
        style: {
            textAlign: 'left'
        },
        minWidth: 180
    },
    // NAT Policies
    to_interface: {
        headerName: _T('Destination Interface'),
        field: 'to-interface',
        sortable: false,
        minWidth: 130
    },
    src_translation: {
        headerName: _T('Source Translation'),
        field: 'source-translation',
        sortable: false,
        minWidth: 200
    },
    dst_translation: {
        headerName: _T('Destination Translation'),
        field: 'destination-translation',
        sortable: false,
        minWidth: 200
    },
    active_active: {
        headerName: _T('Active/Active HA Binding'),
        field: 'active-active-device-binding',
        sortable: false
    },
    // QoS
    cls: {
        headerName: _T('Class'),
        field: 'action.class',
        sortable: false,
        minWidth: 100
    },
    // schedule: {
    //     headerName: _T('Schedule'),
    //     name: 'schedule',
    //     sortable: false,
    //     minWidth: 100
    // },
    dscp: {
        headerName: _T('DSCP/ToS'),
        field: 'dscp-tos',
        sortable: false,
        minWidth: 100
    },
    category: {
        headerName: _T('URL Category'),
        valueGetter: params => {
            return getPathValue(params.data, 'category.member') || 'any';
        },
        sortable: false,
        minWidth: 100
    },
    // Ssl Decryption
    decryption_action: {
        headerName: _T('Action'),
        field: 'action',
        sortable: false,
        minWidth: 130
    },
    decryption_type: {
        headerName: _T('Type'),
        field: 'type',
        sortable: false,
        minWidth: 130
    },
    decryption_profile: {
        headerName: _T('Decryption Profile'),
        field: 'profile',
        sortable: false,
        minWidth: 130
    },
    // Authentication
    authentication_enforcement: {
        headerName: _T('Authentication Enforcement'),
        field: 'authentication-enforcement',
        sortable: false,
        minWidth: 130
    },
    // PBF/DoS
    if_from: {
        headerName: _T('Zone/Interface'),
        field: 'from',
        sortable: false,
        minWidth: 120
    },
    if_to: {
        headerName: _T('Zone/Interface'),
        field: 'to',
        sortable: false,
        minWidth: 120
    },
    pbf_action: {
        headerName: _T('Action'),
        field: 'action.choice',
        sortable: false,
        minWidth: 100
    },
    egress_if: {
        headerName: _T('Egress I/F'),
        field: 'action.forward.egress-interface',
        sortable: false,
        minWidth: 100
    },
    next_hop: {
        headerName: _T('Next Hop'),
        field: 'action.forward.nexthop.choice',
        sortable: false,
        minWidth: 100
    },
    vsys_sg: {
        headerName: _T('Virtual System'),
        field: 'action.forward-to-vsys',
        sortable: false,
        hidden: true,
        minWidth: 100
    },
    enforce_symmetric_return: {
        headerName: _T('Enforce Symmetric Return'),
        field: 'enforce-symmetric-return.enabled',
        sortable: false,
        minWidth: 150
    },
    pbf_profile: {
        headerName: _T('Profile'),
        field: 'action.forward.monitor.profile',
        sortable: false,
        minWidth: 100
    },
    pbf_target: {
        headerName: _T('Target'),
        field: 'action.forward.monitor.ip-address',
        sortable: false,
        minWidth: 100
    },
    pbf_unreachable: {
        headerName: _T('Disable If Unreachable'),
        field: 'action.forward.monitor.disable-if-unreachable',
        sortable: false,
        minWidth: 150
    },
    // DoS
    dos_action: {
        headerName: _T('Action'),
        field: 'action',
        sortable: false,
        minWidth: 130
    },
    aggregate: {
        headerName: _T('Aggregate'),
        field: 'protection.aggregate.profile',
        sortable: false,
        minWidth: 120
    },
    log_setting: {
        headerName: _T('Log Forwarding'),
        field: 'log-setting',
        sortable: false,
        minWidth: 120
    },
    classified: {
        headerName: _T('Classified'),
        field: 'protection.classified',
        sortable: false,
        minWidth: 120
    },
    tunnel_id: {
        headerName: _T('Tunnel ID'),
        field: 'tunnle-id.vni.entry',
        sortable: false,
        minWidth: 200
    },
    inspection_options: {
        headerName: _T('Inspection Options'),
        field: 'inspect-options',
        sortable: false,
        minWidth: 200
    },
    security_options: {
        headerName: _T('Security Options'),
        field: 'zone-assign',
        sortable: false,
        minWidth: 200
    },
    monitor_options: {
        headerName: _T('Monitor Options'),
        field: 'monitor-options',
        sortable: false,
        minWidth: 200
    }
};

export default columnConfig;