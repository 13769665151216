import React from 'react';
import { getPathValue, RecordViewer, getRBAPermission, SpinnerWidget, connect, ViewerManager, hideModal, } from 'ui-lib';
import { FORM_LOADING, SHOW_FORM, showForm, fetchConfigItem, addConfigItem, editConfigItem, hideForm } from '../../services/actions';
import { getEffectiveServiceMap } from '../../services/util';
import { get } from "lodash";

const getHLRecordViewer = (viewerName, serviceName, recordId, reduxId, serviceMap, resultPath = "result", locationPath, widerForm = false) => {
    class HLRecordViewer extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                configLocation: { [locationPath]: get(props, `configLocation.${locationPath}`, props.main.configLocation[locationPath]) },
                rbaPermission: getRBAPermission(props.rbaPath || ''),
                serviceMap: getEffectiveServiceMap(serviceName, serviceMap),
                reduxStateId: {
                    reduxId: reduxId,
                    locationPath: locationPath
                }
            }
        }

        componentDidUpdate(prevProps, _) {
            let newMainData = this.props.main[reduxId];
            let oldData = prevProps.main ? prevProps.main[reduxId] : false;
            if (newMainData && newMainData[SHOW_FORM] === false && oldData[SHOW_FORM] === true) {
                //redux change in store data to hide the form
                //invoke afterSubmit as this case is possibel only after successful submit
                if (this.props.displayMode === 'modal') {
                    hideModal(this.props.modalId);
                }
                if (this.props.afterSubmit) {
                    this.props.afterSubmit(this.state.submitRecord);
                }
            }
        }

        componentDidMount() {
            if (this.props.fetchRecord) {
                //fetch the record for editing
                //if fetchRecord is not set, it will be treated as addRecord
                this.props.dispatch(
                    fetchConfigItem(this.state.serviceMap, recordId, this.state.reduxStateId, this.state.configLocation)
                );
            }
            this.props.dispatch(
                showForm(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        componentWillUnmount () {
            this.props.dispatch(
                hideForm(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        onAddRecord(record) {
            this.setState({
                submitRecord: record
            });
            this.props.dispatch(
                addConfigItem(record, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation, this.props.fetchRecord)
            );
        }

        onEditRecord(record, backup) {
            this.setState({
                submitRecord: record
            });

            this.props.dispatch(
                editConfigItem(record, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
            );
        }

        render() {
            let responseData = this.props[locationPath][reduxId] || {};
            let mainData = this.props.main[reduxId] || {};
            let record = responseData && this.props.fetchRecord ? getPathValue(responseData, resultPath) || {} : this.props.record || {};
            let keys = Object.keys(record);
            if (this.props.fetchRecord && keys.length === 0) {
                return (<div><SpinnerWidget /></div>)
            }

            const Viewer = ViewerManager.getViewInstance(viewerName) || {};
            const viewerProps = {
                ...this.props,
                recordConfigPath: Viewer.recordConfigPath || this.props.recordConfigPath,
                inputFields: Viewer.fields || this.props.inputFields,
            };

            return (
                <RecordViewer
                    {...viewerProps}
                    widerForm={widerForm}
                    record={record}
                    onAddRecord={this.onAddRecord.bind(this)}
                    onEditRecord={this.onEditRecord.bind(this)}
                    panSchema={window.__pan_schema}
                    formLoading={mainData[FORM_LOADING]}
                    configLocation={this.state.configLocation}
                    rbaPermission={this.state.rbaPermission}
                    locationPermission={'enable'}
                    hideOk={this.props.hideOk}
                    hideCancel={this.props.hideCancel}
                />
            );
        }
    }

    const mapStateToProps = state => {
        return { [locationPath]: state[locationPath], main: state.main };
    };

    return connect(mapStateToProps, null, null)(HLRecordViewer);
};
export default getHLRecordViewer;
