import React from 'react';
import PropTypes from 'prop-types';
import { getBuilder } from '../../../utils/BuilderManager';
import { BaseBuilder } from '../BaseBuilder';
import { FormContext, FormConsumer } from '../../FormContext';
import { getField } from '../../schema/utils';
import { getFieldLabel } from '../../schema/utils';
import Pan from '../../schema/Pan';
import { Row, Col } from 'reactstrap';

import {
    getPathValue,
    getPathValues,
    deletePath,
    setPathValue,
} from '../../../utils/json';

import './FieldSetBuilder.scss';
import { deleteRecursivelyEmptyParents } from '../../widgets/util';

export class FieldSetBuilder extends BaseBuilder {
    static NAME = 'FieldSetBuilder';
    constructor(props) {
        super(props);
        this.state = {
            hideField: false,
            check: null,
            useStateCheck: false,
        };
    }

    getBoolValue(value, type) {
        if (type === 'reverse') {
            return Pan.isEmpty(value);
        }
        return !Pan.isEmpty(value);
    }

    getFieldsetSchemaFields = (
        childrenNames,
        fieldMap,
        childSchemaFields,
        formData,
        filters,
    ) => {
        childrenNames.forEach(child => {
            let childField = fieldMap[child];
            if (childField && childField.attrPath) {
                childSchemaFields.push(child);
            }
            if (childField && childField.childrenNames) {
                if (childField.type === 'choice') {
                    let choiseExist = false;
                    childField.childrenNames.forEach(ch => {
                        let val = getPathValue(formData, ch, filters);
                        if (!Pan.isEmpty(val)) {
                            //choice node exist
                            choiseExist = true;
                            this.getFieldsetSchemaFields(
                                [ch],
                                fieldMap,
                                childSchemaFields,
                            );
                        }
                    });
                    if (!choiseExist) {
                        this.getFieldsetSchemaFields(
                            [childField.childrenNames[0]],
                            fieldMap,
                            childSchemaFields,
                        );
                    }
                } else {
                    this.getFieldsetSchemaFields(
                        childField.childrenNames,
                        fieldMap,
                        childSchemaFields,
                    );
                }
            }
        });
    };

    setFiledBackupDefaultValue = (field, formData, formDataBackup, filters) => {
        let defaultValue = field.defaultValue || null;
        let fieldValue = getPathValue(formDataBackup, field.attrPath, filters) || defaultValue;
        if (!Pan.isEmpty(fieldValue)) {
            setPathValue(formData, field.attrPath, fieldValue, filters);
        }
    }

    setChildDefaultValue = (field, formData, formDataBackup, fieldMap, filters) => {
        if (field.childrenNames) {
            field.childrenNames.forEach(child => {
                let childField = fieldMap[child];
                if (childField && childField.attrPath && !childField.uiHint.allowBlank) {
                    if (!childField.childrenNames) {
                        //child is leaf node
                        this.setFiledBackupDefaultValue(childField, formData, formDataBackup, filters);
                    } else {
                        //child can be choice or container or fieldset
                        if (childField.type === 'choice') {
                            //check if formdata has some value. If not, set first childs default values
                            let choiseExist = false;
                            for (let ch of childField.childrenNames) {
                                let val = getPathValue(formDataBackup, ch, filters);
                                if (val) {
                                    //choice node exist
                                    choiseExist = true;
                                    this.setChildDefaultValue(fieldMap[ch], formData, formDataBackup, fieldMap, filters);
                                    break;
                                }
                            }
                            if (!choiseExist) {
                                //select first child
                                this.setChildDefaultValue(fieldMap[childField.childrenNames[0]], formData, formDataBackup, fieldMap, filters);
                            }
                        } else {
                            this.setChildDefaultValue(childField, formData, formDataBackup, fieldMap, filters);
                        }
                    }

                } else if (!childField.attrPath && childField.childrenNames) {
                    //fake node
                    this.setChildDefaultValue(childField, formData, formDataBackup, fieldMap, filters)
                }
            })
        }
    }

    onSelectionChange = (value) => {
        const { field, onChange, filters } = this.props;
        const { formData, formDataBackup, fields } = this.context;

        const childrenNames = field.childrenNames;
        let fieldMap = {};
        fields.forEach(fld => {
            fieldMap[fld.name] = fld;
        });

        let newFormData = Pan.clone(formData);
        if (value) {
            //enable
            if (field.type === 'sequence') {
                //set empty sequence object
                let backupObj = getPathValue(formDataBackup, field.attrPath, filters) || {};
                setPathValue(newFormData, field.attrPath, backupObj, filters);
                //set default values for children
                this.setChildDefaultValue(field, newFormData, formDataBackup, fieldMap, filters);
            } else {
                if (field.type === 'bool') {
                    //bool type 
                    setPathValue(newFormData, field.attrPath, 'yes', filters);
                }
                //can also fake field. Go over the children and set the default/backup values.
                this.setChildDefaultValue(field, newFormData, formDataBackup, fieldMap, filters);
            }

        } else {
            //disable - delete child elements
            let fieldAttrPath = field.attrPath;
            if (field.type === 'sequence') {
                //delete sequence object
                deletePath(newFormData, fieldAttrPath, filters);
                deleteRecursivelyEmptyParents(newFormData, fieldAttrPath, filters);
            } else {
                if (field.type === 'bool') {
                    //bool type 
                    deletePath(newFormData, fieldAttrPath, filters);
                }
                //can also fake field. Go over the children and delete values.
                let fieldsetSchemaFields = [];
                this.getFieldsetSchemaFields(childrenNames, fieldMap, fieldsetSchemaFields);
                fieldsetSchemaFields.forEach(child => {
                    deletePath(newFormData, child, filters);
                });
                deleteRecursivelyEmptyParents(newFormData, fieldAttrPath, filters);
            }
        }

        onChange({ formData: newFormData, formDataBackup: formData });
        this.setState({
            useStateCheck: true
        });
        setTimeout(() => {
            this.setState({
                check: value
            });
        }, 200);
    }

    layoutChildren = (layout) => {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            disabled,
            showErrors
        } = this.props;
        const { fields } = this.context;
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        const type = getPathValues(field, 'uiHint.type');
        const showCheckbox = getPathValue(field, 'uiHint.showCheckbox');
        const fieldLabel = getFieldLabel(field);
        let value = Pan.isBoolean(this.state.check) || this.state.useStateCheck ? this.state.check : this.getBoolValue(this.getRecordValue(), type);
        let inputType = '';
        if (field.uiHint && field.uiHint.allowBlank || showCheckbox) {
            inputType = <input
                type="checkbox"
                name={fieldLabel}
                checked={typeof value === "undefined" ? false : value}
                disabled={disabled}
                onChange={(event) => this.onSelectionChange(event.target.checked)} />;
        } else {
            //no checkbox in the legent and set value to true for childrend to get enabled
            value = true;
        }

        let children = layout.map((row, index) => {
            return (
                <Row key={index} className="d-flex flex-row">
                    {Object.keys(row).map(name => {
                        const Builder = getBuilder({name, fields});
                        const thisField = getField(fields, name);
                        return (
                            <Col key={name} className={row[name]}>
                                <Builder
                                    name={name}
                                    field={thisField}
                                    onChange={onChange}
                                    addError={addError}
                                    removeError={removeError}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    filters={filters}
                                    showErrors={showErrors}
                                    containerLabelWidthSize={
                                        containerLabelWidthSize
                                    }
                                    disabled={
                                        disabled === true ? disabled : !value
                                    }
                                />
                            </Col>
                        );
                    })}
                </Row>
            );
        });

        return (
            <fieldset className="fieldset">
                <legend className="legend">
                    {inputType}
                    <span>{fieldLabel}</span>
                </legend>
                {children}
            </fieldset>
        );
    };

    renderChildren = () => {
        const { field, onChange, addError, removeError, onBlur, onFocus, filters, disabled, showErrors } = this.props;
        const { fields, schema } = this.context;
        const type = getPathValues(field, 'uiHint.type');
        const showCheckbox = getPathValue(field, 'uiHint.showCheckbox');
        const fieldLabel = getFieldLabel(field);
        let value = Pan.isBoolean(this.state.check) || this.state.useStateCheck ? this.state.check : this.getBoolValue(this.getRecordValue(), type);
        const childrenNames = field.childrenNames;
        if (!childrenNames || this.props.hidden || this.state.hideField || getPathValues(field, 'uiHint.hidden')) {
            return (
                <div />
            );
        }

        const layout = field.uiHint.layout;
        if (layout) {
            return this.layoutChildren(layout);
        }
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        let inputType = '';
        if (field.uiHint && field.uiHint.allowBlank || showCheckbox) {
            inputType = <input
                type="checkbox"
                name={fieldLabel}
                checked={typeof value === "undefined" ? false : value}
                disabled={disabled}
                onChange={(event) => this.onSelectionChange(event.target.checked)} />;
        } else {
            //no checkbox in the legent and set value to true for childrend to get enabled
            value = true;
        }
        let children = childrenNames.map(name => {
            const Builder = getBuilder({name, fields});
            const thisField = getField(fields, name);

            return (
                <Builder
                    key={name}
                    name={name}
                    field={thisField}
                    onChange={onChange}
                    addError={addError}
                    removeError={removeError}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    filters={filters}
                    containerLabelWidthSize={containerLabelWidthSize}
                    showErrors={showErrors}
                    disabled={disabled === true ? disabled : !value}
                />
            );
        });
        return (
            <fieldset className="fieldset" test_id={field.attrPath}>
                <legend className="legend">
                    {inputType}
                    <span>{fieldLabel}</span>
                </legend>
                {children}
            </fieldset>
        );
    };

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderChildren()}</FormConsumer>
            </React.Fragment>
        );
    }
}

FieldSetBuilder.contextType = FormContext;

FieldSetBuilder.defaultProps = {
    disabled: false,
};

if (process.env.NODE_ENV !== 'production') {
    FieldSetBuilder.propTypes = {
        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        containerLabelWidthSize: PropTypes.string,
        hidden: PropTypes.bool,
		/**
		 * *attrPath* - defines the attribute schema path eg: "$.a.b.c"
         * *defaultValue* - sets the default value of the field.
		 * *childrenNames* - array of strings containing names of child fields
         * UiHint properties :
         * *hidden* - hides the field
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *fieldLabel* - adds a label to the field
         * *hideLabel* - hides the label
		 * *showCheckbox* - display or hide checkbox
		 * *layout* - an array of objects that specifies which field will have what width eg [{'$.action':'col-2'}]
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
		 */
        field: PropTypes.shape({
            childrenNames: PropTypes.array,
            defaultValue: PropTypes.string,
            allowBlank: PropTypes.bool,
            type: PropTypes.string,
            attrPath: PropTypes.string,
            uiHint: PropTypes.shape({
                labelWidthSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                showCheckbox: PropTypes.bool,
                hidden: PropTypes.bool,
                layout: PropTypes.array,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.bool,
                vtype: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    fields: PropTypes.object,
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            }),
        }),
    };
}
