import { ViewerManager, Pan } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

const _T = str => str;

const addressObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    },
    {
        name: '$.ip-netmask',
        uiHint: {
            fieldLabel: _T('IP Netmask'),
            showHelpString: _T('Enter an IP address or a network using the slash notation (Ex. 192.168.80.150 or 192.168.80.0/24). You can also enter an IPv6 address or an IPv6 address with its prefix (Ex. 2001:db8:123:1::1 or 2001:db8:123:1::/64)'),
            vtype: 'isIpAddress',
            maxLength: 64
        }
    },
    {
        name: '$.ip-range',
        uiHint: {
            fieldLabel: _T('IP Range'),
            showHelpString: _T('Enter an IP address range (Ex. 10.0.0.1-10.0.0.4). Each of the IP addresses in the range can also be in an IPv6 form (Ex. 2001:db8:123:1::1-2001:db8:123:1::11)')
        }
    },
    {
        name: '$.fqdn',
        uiHint: {
            fieldLabel: _T('FQDN'),
        }
    },
    {
        name: '$.ip-wildcard',
        uiHint: {
            fieldLabel: _T('IP Wildcard Mask'),
            showHelpString: _T('Please enter an IP wildcard mask (Ex. 10.20.1.0/0.0.248.255).')
        }
    },
    {
        name: '$.dynamic',
        uiHint: {
            showHelpString: _T('Enter an identifier for the dynamic object as it is used in the XML API.  An identifier can be any string, but has to be unique.')
        }
    },
    {
        name: '$.choice',
        childrenNames: [
            "$.ip-netmask",
            "$.ip-range",
            "$.ip-wildcard",
            "$.fqdn"],
        uiHint: {
            fieldLabel: _T('Type')
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            'location',
            '$.description',
            '$.choice',
            '$.tag.member'
        ]
    }
];

const addressObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.vsys.entry.address.entry',
    minWidth: 100
}, {
    headerName: _T('Description'),
    valueGetter: params => params.data['description'] || ''
}, {
    headerName: _T('Location'),
    field: '@loc',
    minWidth: 200
}, {
    headerName: _T('Type'),
    valueGetter: params => {
        let type = params.data['ip-range'] ? 'ip-range' :
            params.data['ip-netmask'] ? 'ip-netmask' :
                params.data['fqdn'] ? 'fqdn' :
                    params.data['ip-wildcard'] ? 'ip-wildcard' : '';
        return type;
    },
    minWidth: 100
}, {
    headerName: _T('Address'),
    valueGetter: params => {
        let type = params.data['ip-range'] ? 'ip-range' :
            params.data['ip-netmask'] ? 'ip-netmask' :
                params.data['fqdn'] ? 'fqdn' :
                    params.data['ip-wildcard'] ? 'ip-wildcard' : '';
        return params.data[type];
    },
    minWidth: 200
}, {
    headerName: _T('Tags'),
    field: 'tag.member',
    minWidth: 100
}];

// const addressSimpleColumns = [{
//     type: ['nameColumn', 'clickableColumn'],
//     recordConfigPath: '$.config.devices.entry.vsys.entry.address.entry'
// }, {
//     headerName: 'Location',
//     field: '@loc'
// }, {
//     headerName: 'Address',
//     id: 'address',
//     valueGetter: params => {
//         let type = params.data['ip-range'] ? 'ip-range' :
//             params.data['ip-netmask'] ? 'ip-netmask' :
//                 params.data['fqdn'] ? 'fqdn' :
//                     params.data['ip-wildcard'] ? 'ip-wildcard' : '';
//         return params.data[type];
//     }
// }];

const viewConfig = {
    // displayMode = 'simple'; // default || simple
    // editorMode = 'modal';   // default || modal
    // simpleColumns = addressSimpleColumns;

    serviceName: 'Objects/Addresses',
    reduxId: 'addressObjects',
    header: 'Addresses',
    columns: addressObjectColumns,
    fields: addressObjectFields,
    recordConfigPath: '$.config.devices.entry.device-group.entry.address.entry',
    rbaPath: 'objects.addresses',
    className: 'Address'
};

export default class Address extends DGBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);
