import { getAuthHeaders, Pan } from "ui-lib";
import { getLoginServiceURL } from "ui-lib";

export function generateTechSupportDataObservable(caseId) {
	let { SERVICE_URL } = getLoginServiceURL("api/techSupport/generate");
	return {
		withCredentials: true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		headers: { "Content-Type": "application/json", ...getAuthHeaders() },
		body: !Pan.isEmpty(caseId) ? JSON.stringify({ caseId }) : {}
	};
}

export function getRecentTechSupportRequestsObservable(limit) {
	let { SERVICE_URL } = getLoginServiceURL("api/techSupport");
	console.log('limit', limit, SERVICE_URL);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: { "Content-Type": "application/json", ...getAuthHeaders() },
		body: Pan.isDefined(limit) ? JSON.stringify({ limit }) : {}
	};
}
