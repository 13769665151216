import React, { Component } from "react";
import { Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { GridWidget, isClickable, HLGridHeader, FilterWidget, FeedbackWidget, ViewerManager, connect, Pan } from "ui-lib";
import DataProxy from "./AppDataProxy";
import FilterViewer from "./FilterViewer";
import ApplicationTag from "../applicationTag";
import { each, find } from "lodash";
import "./AppBrowser.scss";

const getAllData = data => {
	let alldata = {};
	for (let type in data) {
		let json = data[type] || [];
		alldata[type] = json;
	}
	return alldata;
};

const updateAppData = ({
	filters,
	useFor,
	gridData,
	predefinedApps
}) => {
	const selectable = useFor === "APP_GROUP";

	// clone the original gridData
	const alldata = getAllData(gridData);
	alldata["predefined"]= predefinedApps;
	const result = DataProxy.loadData(Object.assign({}, alldata), selectable);

	const newresult = DataProxy.loadFilteredData(result, filters);
	DataProxy.updateDisplayList(result, newresult);

	return {
		alldata,
		result,
		newresult
	};
}

const requireField = [
'@name',
'able-to-transfer-file',
'alg-disable-capability',
'category',
'consume-big-bandwidth',
'data-ident',
'default',
'description',
'disable-override',
'evasive-behavior',
'file-type-ident',
'has-known-vulnerability',
'no-appid-caching',
'parent-app',
'pervasive-use',
'prone-to-misuse',
'risk',
'signature',
'subcategory',
'tcp-half-closed-timeout',
'tcp-time-wait-timeout',
'tcp-timeout',
'technology',
'timeout',
'tunnel-applications',
'tunnel-other-application',
'udp-timeout',
'used-by-malware',
'virus-ident'
]

class AppBrowser extends Component {
	// useFor = 'BROWSER' | 'APP_FILTER' | 'APP_GROUP'
	constructor(props) {
		super(props);

		let { filters, filterText } = props;
		filters = filters || [];
		filterText = filterText || "";
		let showDescription = false;

		this.state = {
			filters,
			filterText,
			showDescription,
			showTagRemoveConfirm: false,
			showEditTag: false
		};
		this.onTagRemoveConfirm = this.onTagRemoveConfirm.bind(this);
		this.hideTagRemoveConfirm = this.hideTagRemoveConfirm.bind(this);
	}

	componentDidMount() {
		const { filters } = this.state;
		const { useFor, gridData, predefinedApps } = this.props;

		const { alldata, result, newresult } = updateAppData({filters, useFor, gridData, predefinedApps})

		this.setState({
			alldata,
			result,
			newresult
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { filters } = prevState;
		const { useFor, gridData, predefinedApps } = nextProps;
		
		const { alldata, result, newresult } = updateAppData({filters, useFor, gridData, predefinedApps})

		return {
			alldata,
			result,
			newresult
		};
	};

	updateFilters = (type, filter) => {
		// if type and filter are not defined, we are clearing the filters
		if (type === undefined && filter === undefined) {
			return [];
		}

		let { filters } = this.state;

		// add current filter
		let ret = [{ type: type, data: filter }];

		// add the remain filters
		for (let i = 0; i < filters.length; i++) {
			let item = filters[i];
			if (item.type !== type) {
				ret.push(item);
			}
		}
		return ret;
	};

	onFilterChange = (type, filter) => {
		let { result } = this.state;
		let filters = this.updateFilters(type, filter);

		var newresult = DataProxy.loadFilteredData(result, filters);
		DataProxy.updateDisplayList(result, newresult);

		this.setState({
			filters,
			newresult
		});
	};

	onFilter = filterText => {
		this.onFilterChange("search", filterText);
		this.setState({ filterText });
	};

	onClearFilters = e => {
		e.preventDefault();
		this.onFilterChange();
		this.setState({ filterText: "" });
	};

	onDeleteRecord = (selected, configPath) => {
		if (this.props.onDeleteRecord) {
			this.props.onDeleteRecord(selected, configPath);
		}
	};

	onCloneRecord = (selected, configPath) => {
		if (this.props.onCloneRecord) {
			this.props.onCloneRecord(selected, configPath);
		}
	};

	onEditTag = selected => {
		let app = "";
		Object.keys(selected).forEach(function (key) {
			app = selected[key];
		});
		let tags = app["tag-info"] ? app["tag-info"] : [];
		let newTags = [];
		tags.forEach(function (tag) {
			newTags.push(tag.name);
		});
		let obj = {
			"@name": app.name,
			tag: {
				member: newTags
			}
		};
		this.setState({
			selected: obj,
			tagData: newTags,
			showEditTag: true
		});
	};

	onRemoveTag = (selected, configPath) => {
		let selectedApps = [];
		Object.keys(selected).forEach(function (key) {
			selectedApps.push(selected[key].name);
		});
		this.setState({ selected: selectedApps, showTagRemoveConfirm: true });
	};

	onTagRemoveConfirm(selected) {
		this.setState({ showTagRemoveConfirm: false });
		if (this.props.onDeleteApplicationTag) {
			this.props.onDeleteApplicationTag(this.state.selected);
		}
	}

	hideTagRemoveConfirm() {
		this.setState({ showTagRemoveConfirm: false });
	}

	toggle = () => {
		this.setState({
			showDescription: false,
			showTagRemoveConfirm: false,
			showEditTag: false
		});
	};

	displayRecordEditor = (application) => {
		if (application && application.customapp) {
			const { customApps } = this.props.gridData;
			let record = find(customApps, app => app['@name'] === application['@name']);
			for(let p in record) {
				if(!requireField.includes(p)) { //prune the properties which is not supported by the schema
					delete record[p];
				}
			}
			this.props.displayRecordEditor(record)
		} else if (application && application.description) {
			this.setState({
				showDescription: true,
				application: application
			});
		}
	};

	injectEditRecordCallback = columns => {
		return columns.map(column => {
			if (isClickable(column) && this.displayRecordEditor) {
				column.onClick = this.displayRecordEditor;
			}
			if (column.children) {
				column.children = this.injectEditRecordCallback(column.children);
			}
			return column;
		});
	};

	isDeleteDisabled = (selected, records, idColumn) => {
		let selectedKeys = Object.keys(selected);
		return !(selectedKeys.length > 0);
	};

	isCloneDisabled = (selected, records, idColumn) => {
		let selectedKeys = Object.keys(selected);
		let foundPredefined = false;
		each(selected, function (r) {
			if (!r['@uuid']) {
				foundPredefined = true;
				return false;
			}
		});
		return foundPredefined || !(selectedKeys.length > 0);
	};

	isEditTagDisabled = (selected, records, idColumn) => {
		let selectedKeys = Object.keys(selected);
		if (selectedKeys.length === 1) {
			return !!(selected[selectedKeys[0]].count); // no edit tags fir Container app. v1.0
		}
		return !(selectedKeys.length === 1);
	};

	isRemoveTagDisabled = (selected, records, idColumn) => {
		let selectedKeys = Object.keys(selected);
		return !(selectedKeys.length > 0);
	};

	generateViewer(viewer) {
		return ViewerManager.getView(viewer);
	}

	gridActions = [
		{
			text: "Add",
			color: "primary",
			callback: () => {
				this.props.displayRecordEditor();
			}
		},
		{
			text: "Delete",
			avail: this.isDeleteDisabled,
			callback: selected => {
				this.onDeleteRecord(selected, this.props.recordConfigPath);
			}
		},
		{
			text: "Clone",
			avail: this.isCloneDisabled,
			callback: selected => {
				this.onCloneRecord(selected, this.props.recordConfigPath);
			}
		},
		{
			text: "Add/Edit Tag",
			avail: this.isEditTagDisabled,
			callback: (selected, viewer) => {
				console.log(selected);
				this.onEditTag(selected);
			}
		},
		{
			text: "Remove Tag",
			avail: this.isRemoveTagDisabled,
			callback: selected => {
				this.onRemoveTag(selected, this.props.recordConfigPath);
			}
		}
	];

	render() {
		let {
			filters,
			newresult,
			filterText,
			showDescription,
			application,
			showTagRemoveConfirm,
			showEditTag
		} = this.state;
		let { header, columns, locationWidget, loading, configLocation, fetchingPredefionedApps } = this.props;

		header = header || "Applications";
		let modifiedColumns = this.injectEditRecordCallback(columns);
		let appData = (newresult && newresult.data) || [];

		return (
			<Card className="h-100">
				<FeedbackWidget
					isOpen={showTagRemoveConfirm}
					message={{msg: "Are you sure you want to remove tags?"}}
					actions={[
						{ text: "Yes", action: this.onTagRemoveConfirm },
						{ text: "No", color: "secondary", action: this.hideTagRemoveConfirm }
					]}
					type="Error"
					title="Confirm"
				/>
				<CardHeader className="hlgrid-header bg-light">
					<HLGridHeader header={header} location={locationWidget}>
						<FilterWidget
							id="filterInput"
							value={filterText}
							objectName={header}
							onFilter={this.onFilter}
						/>
						<Button
							color="secondary"
							style={{ marginLeft: "20px" }}
							onClick={e => this.onClearFilters(e)}
						>
							Clear Filters
						</Button>
					</HLGridHeader>
				</CardHeader>
				<CardBody className="app-browser-grid p-0">
					<FilterViewer
						isLoading={fetchingPredefionedApps}
						result={newresult}
						filters={filters}
						onFilterChange={this.onFilterChange}
					/>
					<GridWidget
						idColumn={'title'}
						gridData={appData}
						columns={modifiedColumns}
						pageSize={20}
						gridActions={this.gridActions}
						checkboxSelection={true}
						showFilter={false}
						loading={loading || fetchingPredefionedApps}
						className={"app-browser"}
					/>
					{showDescription && application && (
						<Modal isOpen={showDescription} toggle={this.toggle} fade centered>
							<ModalHeader toggle={this.toggle}>{application.title}</ModalHeader>
							<ModalBody>{application.description}</ModalBody>
						</Modal>
					)}
					{showEditTag && (
						<Modal
							isOpen={showEditTag}
							toggle={this.toggle}
							fade
							centered
							size="xl"
						>
							<ModalBody>
								<ApplicationTag
									mode="form"
									onCancel={this.toggle}
									afterSubmit={this.toggle}
									formData={this.state.selected}
									record={Pan.isEmpty(this.state.tagData) ? undefined: this.state.selected}
									configLocation={configLocation}
								/>
							</ModalBody>
						</Modal>
					)}
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({
	main: {
		content: {
			applications = [],
			fetchingApplications = true
		}
	}
}) => {
	return {
		predefinedApps: applications,
		fetchingPredefionedApps: fetchingApplications
	};
};

export default connect(mapStateToProps)(AppBrowser);
