
import React from "react";
import PropTypes from "prop-types";
import Pan from "../../schema/Pan";
import { FormContext, FormConsumer } from "../../FormContext";
import { PasswordWidget } from "../../widgets/PasswordWidget/PasswordWidget";
import { FormGroup, Col } from "reactstrap";
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";
import { getPathValues } from "../../../utils/json";
import { getFieldLabel, getFieldLabelComp, getFieldWidthClass, getFieldHelpString, setState } from "../../schema/utils";
import classnames from "classnames";
import { BaseFieldBuilder } from "../BaseBuilder";
import './PasswordBuilder.scss';

export class PasswordBuilder extends BaseFieldBuilder {
    static NAME = 'PasswordBuilder';
    constructor(props, context) {
        super(props, context);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.validateConfirmValue = this.validateConfirmValue.bind(this);
        this.state = {
            hideField: false,
            value: false,
            confirmValue: false
        }
    }

    componentDidMount() {
        this.setState({
            value: this.getRecordValue(),
            confirmValue: this.getRecordValue()
        }, () => {
            super.componentDidMount()
        });
    }

    setRecordValue(value) {
        setState(this, { value: value }, () => {
            super.setRecordValue(value, true);
        });
    }

    validateConfirmValue(value) {
        setState(this, { confirmValue: value }, () => {
            super.setRecordValue(this.state.value !== false ? this.state.value : this.getRecordValue(), true);
        });
    }

    execValidationRules(field, value, rules) {
        let errors = super.execValidationRules(field, value, rules);
        let val = this.state.value !== false ? this.state.value : this.getRecordValue();
        if (val != this.state.confirmValue) {
            errors = errors || [];
            errors.push(getFieldLabel(field) + ' and Confirm ' + getFieldLabel(field) + ' do not match');
        }
        return errors;
    }

    renderPasswordfields() {
        let {
            name,
            className,
            field,
            onBlur,
            onFocus,
            containerLabelWidthSize,
            isEditorGridColumnEditor,
            disabled,
            showErrors
        } = this.props;

        if (this.props.hidden || this.state.hideField || getPathValues(field, 'uiHint.hidden')) {
            return (
                <div />
            );
        }
        const fieldLabel = getFieldLabel(field);
        const confirmFieldLabel = "Confirm " + fieldLabel;
        let value;
        let confirmValue;
        if (!this.getRecordValue() && this.state.value !== false) {
            value = '';
            confirmValue = '';
        } else {
            value = this.state.value !== false ? this.state.value : this.getRecordValue();
            confirmValue = this.state.confirmValue !== false ? this.state.confirmValue : "";
        }
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let LabelPasswordConfirm = getFieldLabelComp(field, 'confirm' + name, isEditorGridColumnEditor, containerLabelWidthSize, confirmFieldLabel);
        let textFieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let helpString = getFieldHelpString(field, this.context.formData);
        return (
            <div>
                <FormGroup className={classnames(className, `d-flex flex-row ${required}`)} test_id={field.attrPath}>
                    {LabelComp}
                    <Col>
                        <PasswordWidget
                            id={this.id}
                            name={name}
                            value={value}
                            onChange={this.setRecordValue}
                            className={textFieldClassName}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            disabled={disabled}
                            error={error}
                            showErrors={showErrors} />
                        {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                        {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                    </Col>
                </FormGroup>
                <FormGroup className={classnames(className, `d-flex flex-row ${required}`)}>
                    {
                        confirmFieldLabel && !isEditorGridColumnEditor ? LabelPasswordConfirm : ''
                    }
                    <Col>
                        <PasswordWidget
                            id={'confirm ' + this.id}
                            name={'confirm' + name}
                            value={confirmValue}
                            onChange={this.validateConfirmValue}
                            onBlur={onBlur}
                            className={textFieldClassName}
                            onFocus={onFocus}
                            disabled={disabled}
                            error={error} />
                    </Col>
                </FormGroup>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderPasswordfields()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

PasswordBuilder.contextType = FormContext;

PasswordBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    PasswordBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        field: PropTypes.object
    };
}
