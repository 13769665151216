import React, { Component } from 'react';

import './HLGrid.scss';

export class HLGridHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let header = this.props.header;
        let location = this.props.location || '';
        return (
            <div className="d-flex align-items-start">
                <div className="d-flex">
                    <div>{location}</div>
                    <div className="p-2 mr-auto hlgrid-header-title">
                        {header}
                    </div>
                </div>
                { this.props.children }
            </div>
        );
    }
}
