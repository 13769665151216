import { jsonPath, getPathValue } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const fields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name')
        }
    },
    {
        name: 'recDetailData',
        saveMap: undefined,
        uiHint: {

        }
    },
    {
        name: '$.rules.entry',
        uiHint: {
            isOrdered: true,
            editorType: 'record-form',
            dialogSize: 'xl',
            useColumns: [
                '$.rules.entry.*.@name',
                '$.rules.entry.*.threat-name',
                '$.rules.entry.*.cve',
                '$.rules.entry.*.host',
                '$.rules.entry.*.severity',
                '$.rules.entry.*.vendor-id',
                '$.rules.entry.*.packet-capture'
            ],
            childrenNames: ['$.rules.entry.*.@name',
                '$.rules.entry.*.threat-name',
                '$.rules.entry.*.action',
                '$.rules.entry.*.packet-capture',
                '$.rules.entry.*.host',
                '$.rules.entry.*.category',
                '$.rules.entry.*.cve',
                '$.rules.entry.*.vendor-id',
                '$.rules.entry.*.severity'],
            layout: [{
                '$.rules.entry.*.@name': 'col-12',
                '$.rules.entry.*.threat-name': 'col-12',
                '$.rules.entry.*.action': 'col-6',
                '$.rules.entry.*.packet-capture': 'col-6',
                '$.rules.entry.*.host': 'col-6',
                '$.rules.entry.*.category': 'col-6',
                '$.rules.entry.*.cve': 'col-4',
                '$.rules.entry.*.vendor-id': 'col-4',
                '$.rules.entry.*.severity': 'col-4'
            }],
            allowDrag:(params)=>{
                return true;
            },
            allowDrop:(params,targetNode)=>{
                return params.node === targetNode;
            }
        }
    },
    {
        name: '$.rules.entry.*.packet-capture',
        uiHint: {
            largeSelectionCount: 2
        }
    }, {
        name: '$.rules.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn'
            },
            fieldLabel: _T('Rule Name')
        }
    },
    {
        name: '$.rules.entry.*.threat-name',
        uiHint: {
            showHelpString: _T('Used to match any signature containing the entered text as part of the signature name'),
            columnConfig: {
                hidden: true,
                valueGetter: params => {
                    return getPathValue(params.data, "threat-name");
                }
            }
        }
    },
    {
        name: '$.rules.entry.*.host',
        uiHint: {
            fieldLabel: _T('Host Type'),
            largeSelectionCount: 2
        }
    },
    {
        name: '$.rules.entry.*.vendor-id.member',
        uiHint: {
            fieldLabel: _T('Vendor ID')
        }
    },
    {
        name: '$.rules.entry.*.cve.member',
        uiHint: {
            fieldLabel: _T('CVE')
        }
    },
    {
        name: '$.rules.entry.*.category',
        uiHint: {
            columnConfig: {
                hidden: true
            }
        }
    },
    {
        name: '$.rules.entry.*.action.block-ip',
        uiHint: {
            builder: 'ContainerBuilder',
            labelWidthSize: 4
        }
    },
    {
        name: '$.rules.entry.*.action',
        uiHint: {
            largeSelectionCount: 2,
            columnConfig: {
                valueGetter: params => {
                    return jsonPath(params.data, 'action');
                }
            }
        }
    },
    {
        name: '$.rules.entry.*.severity.member',
        defaultValue: ['any'],
        uiHint: {
            fieldLabel: _T('Severity')
        }
    },
    {
        name: '$.rules.entry.*.severity.member.*',
        uiHint: {
            enum: [['any', 'Any'], ['critical', 'Critical'], ['high', 'High'], ['medium', 'Medium'], ['low', 'Low'], ['informational', 'Informational']],
        }
    },
    {
        name: 'cve-vendor-id-help-text',
        uiHint: {
            columnConfig: {
                hidden: true,
                hideable: false
            },
            hideLabel: true,
            showHelpString: _T('Used to match any signature containing the entered text as part of the signature CVE or Vendor ID')
        }
    }, {
        name: '$.threat-exception',
        uiHint: {
            fieldLabel: 'Exceptions'
        }
    }, {
        name: 'tab',
        childrenNames: [
            '$.rules.entry',
            '$.threat-exception'
        ],
        uiHint: {
            builder: 'TabBuilder'
        }
    }, {
        name: '$',
        childrenNames: [
            'name',
            '$.description',
            'location',
            'tab'
        ]
    },
    {
        name: '$.threat-exception.entry',
        uiHint: {
            editorType: 'record-form',
            dialogSize: 'lg',
            useColumns: [
                '$.threat-exception.entry.*.@name',
            ]
        }
    },
    {
        name: '$.threat-exception.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
];

const columns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        recordConfigPath: '$.config.devices.entry.vsys.entry.profiles.vulnerability.entry',
        minWidth: 100
    },
    {
        headerName: 'Location',
        field: '@loc',
        minWidth: 200
    },
    {
        headerName: _T("Count"),
        valueGetter: params => {
            var values = [];
            if (jsonPath(params.data, 'rules.entry'))
                values.push(_T("Rules") + ': ' + jsonPath(params.data, 'rules.entry')[0].length);
            if (jsonPath(params.data, 'threat-exception.entry'))
                values.push(_T("Exceptions") + ': ' + jsonPath(params.data, 'threat-exception.entry')[0].length);
            return values;
        },
        minWidth: 100
    }, {
        headerName: _T("Rule Name"),
        valueGetter: params => {
            return jsonPath(params.data, "rules.entry.*.@name");
        },
        minWidth: 200
    }, {
        headerName: _T("Threat Name"),
        valueGetter: params => {
            return jsonPath(params.data, "rules.entry.*.threat-name");
        },
        minWidth: 150
    }, {
        headerName: _T("Host Type"),
        valueGetter: params => {
            return jsonPath(params.data, "rules.entry.*.host");
        },
        minWidth: 150
    }, {
        headerName: _T("Severity"),
        valueGetter: params => {
            return jsonPath(params.data, "rules.entry.*.severity.member");
        },
        minWidth: 150
    }, {
        headerName: _T("Action"),
        valueGetter: params => {
            return '';
        },
        minWidth: 100
    }, {
        headerName: _T("Packet Capture"),
        valueGetter: params => {
            return jsonPath(params.data, "rules.entry.*.packet-capture");
        },
        minWidth: 200
    }];

const gridOverride = {
    gridActionsAvail: {
        isCloneDisabled: function (selected, records, idColumn) {
            let selectedKeys = Object.keys(selected);
            return selected.strict || !(selectedKeys.length > 0);
        }
    }
};

export default class VulnerabilityProtection extends DGBaseViewer {

    serviceName = 'Objects/VulnerabilityProtectionSecurityProfiles';
    reduxId = 'vulnerabilityProtectionObjects';
    header = 'Vulnerability Protection';
    widerForm = true;
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.vulnerability.entry';
    rbaPath = 'objects.security-profiles.vulnerability-protection';
    gridOverride = gridOverride;
}
