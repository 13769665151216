import { Pan } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str; 

const fields = unionBy([{
    name: '$.action.choice',
    uiHint: {
        fieldLabel: _T('Action'),
        largeSelectionCount: 1
    }
}, {
    // Create a new security rule
    name: '$',
    childrenNames: [
        '$.general-box',
        'tabs-box'
    ]
}, {
    // Create a new security rule
    name: '$.tabs-box',
    childrenNames: [
        '$.source-box',
        '$.dst-app-service-box',
        '$.forwarding-box',
        '$.HA-box'
    ],
    uiHint: {
        builder: 'TabBuilder',
        deferredRender: false
    }
}, {
    name: '$.source-box',
    childrenNames: [
        '$.from.member',
        'source-with-negate',
        '$.source-user.member'
    ],
    uiHint: {
        layout: [
            {'$.from.member': 'col-10'},
            {'source-with-negate': 'col-12'},
            {'$.source-user.member': 'col-10'},
        ],
        fieldLabel: _T('Source')
    }
}, {
    name: '$.dst-app-service-box',
    childrenNames: [
        'destination-with-negate',
        '$.application.member',
        '$.service.member'
    ],
    uiHint: {
        layout: [
            {'destination-with-negate': 'col-12'}, 
            {'$.application.member': 'col-10'},
            {'$.service.member': 'col-10'}
        ],
        fieldLabel: _T('Destination/Application/Service')
    }
}, {
    name: '$.enforce-symmetric-return.nexthop-address-list.entry',
    uiHint: {
        hasGridFilter: false,
        height: 110
    }
}, {
    name: '$.enforce-symmetric-return.enabled',
    childrenNames: [
        '$.enforce-symmetric-return.nexthop-address-list'
    ],
    uiHint: {
        builder: 'FieldSetBuilder',
        fieldLabel: _T('Enforce Symmetric Return')
    }
}, {
    name: '$.forwarding-box',
    childrenNames: [
        '$.action',
        '$.enforce-symmetric-return.enabled',
        // '$.schedule'
    ],
    uiHint: {
        fieldLabel: _T('Forwarding')
    }
}], commonFields, 'name');

const columns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    {
        headerName: 'Source',
        children: [
            Pan.apply({}, {}, columnConfig.if_from),
            Pan.apply({}, {}, columnConfig.source_negate),
            Pan.apply({}, {}, columnConfig.source_user)
        ]
    },
    {
        headerName: 'Destination',
        children: [
            Pan.apply({}, {}, columnConfig.destination_negate),
        ]
    },
    Pan.apply({}, {}, columnConfig.application),
    Pan.apply({}, {}, columnConfig.service),
    Pan.apply({}, {}, columnConfig.pbf_action),
    {
        headerName: 'Forwarding',
        children: [
            Pan.apply({}, {}, columnConfig.egress_if),
            Pan.apply({}, {}, columnConfig.next_hop),
            Pan.apply({}, {}, columnConfig.vsys_sg),
            Pan.apply({}, {}, columnConfig.enforce_symmetric_return)
        ]
    },
    {
        headerName: 'Monitoring',
        children: [
            Pan.apply({}, {}, columnConfig.pbf_profile),
            Pan.apply({}, {}, columnConfig.pbf_target),
            Pan.apply({}, {}, columnConfig.pbf_unreachable)
        ]
    },
    // Pan.apply({}, {}, columnConfig.schedule),
    Pan.apply({}, {}, columnConfig.active_active),
    Pan.apply({}, {}, columnConfig.description)
];
export default class PbfPolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/PolicyBasedForwardingPreRules';
    reduxId = 'policyBasedForwardingPreRules';
    header = 'Policy Based Forwarding';
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.pbf.rules.entry';
    rbaPath = 'policies.pbf-rulebase';
}