import { Pan, getPathValue } from 'ui-lib';
import unionBy from 'lodash/unionBy';
import {commonFields} from '../CommonFields';
import columnConfig from '../ColumnConfig';
import PoliciesBaseViewer from '../PoliciesBaseViewer';

const _T = str => str;

const fields = unionBy([
    {
        // must defined name field, it used for nameProprityId
        type: 'string',
        name: '$.@name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    }, {
        name: '$.from.member',
        uiHint: {
            fieldLabel: _T('Zone'),
            hidden: true
        }
    },
    {
        name: '$.from.member.*',
        uiHint: {
            fieldLabel: _T('Source Zone')
        }
    },
    {
        name: '$.to.member',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.to.member.*',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.source.member',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.destination.member',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.source-user.member',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.service.member',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.application.member',
        uiHint: {
            hidden: true
        }
    },
    {
        name: '$.category.member',
        uiHint: {
            hidden: true
        }
    },
    {
        // Create a new security rule
        name: '$.tabs-box',
        childrenNames: [
            '$.dscp-tos-box',
            '$.class-box'
        ],
        uiHint: {
            builder: 'TabBuilder',
            deferredRender: false
        }
    },
    {
        // Create a new security rule
        name: '$',
        childrenNames: [
            '$.general-box',
            '$.tabs-box'
        ]
    },
    {
        name: '$.general-box',
        childrenNames: [
            '$.@name',
            '$.description',
            '$.tag',
            '$.from.member',
            '$.to.member',
            '$.source.member',
            '$.destination.member',
            '$.source-user.member',
            '$.service.member',
            '$.application.member',
            '$.category.member'
        ],
        uiHint: {
            fieldLabel: _T('General')
        }
    },
    {
        name: '$.class-box',
        childrenNames: [
            '$.action.class'
        ],
        uiHint: {
            fieldLabel: _T('Class')
        }
    },
    {
        name: '$.dscp-tos-box',
        childrenNames: [
            '$.dscp-tos'
        ],
        uiHint: {
            fieldLabel: _T('DSCP/ToS')
        }
    },
    {
        name: '$.dscp-tos',
        uiHint: {
            fieldLabel: _T('DSCP/ToS')
        }
    },
    {
        name: '$.dscp-tos.codepoints.entry',
        uiHint: {
            editorType: 'record-form',
            dialogSize: 'md',
            useColumns: [
                '$.dscp-tos.codepoints.entry.*.@name',
                '$.dscp-tos.codepoints.entry.*.choice'
            ],
            childrenNames: ['$.dscp-tos.codepoints.entry.*.@name',
                '$.dscp-tos.codepoints.entry.*.choice',
                '$.dscp-tos.codepoints.entry.*.choice.codepoint']
        }
    },
    {
        name: '$.dscp-tos.codepoints.entry.*.@name',
        uiHint: {
            fieldLabel: _T('Name'),
            columnConfig: {
                type: 'clickableColumn'
            },
        }
    },
    {
        name: '$.dscp-tos.codepoints.entry.*.ef.codepoint',
        uiHint: {
            association: {
                fields: ['$.dscp-tos.codepoints.entry.*.ef.codepoint'],
                availDisable: function () {
                    return true;
                }
            }
        }
    },
    {
        name: '$.dscp-tos.codepoints.entry.*.choice',
        uiHint: {
            fieldLabel: _T('Type'),
            useHelpStringAsDisplay: true,
            columnConfig: {
                valueGetter: params => {
                    let d = params.data;
                    if (getPathValue(d, "ef")) {
                        return 'ef';
                    } else if (getPathValue(d, "af")) {
                        return 'af';
                    } else if (getPathValue(d, "cs")) {
                        return 'cs';
                    } else if (getPathValue(d, "tos")) {
                        return 'tos';
                    } else {
                        return 'custom';
                    }
                }
            },
        }
    },
    {
        name: '$.dscp-tos.codepoints.entry.*.custom.codepoint.name',
        uiHint: {
            fieldLabel: _T('Codepoint Name')
        }
    },
    {
        name: '$.dscp-tos.codepoints.entry.*.custom.codepoint.value',
        uiHint: {
            fieldLabel: _T('Binary Value'),
            vtype: "customCpValue"
        }
    }], commonFields, 'name');


const columns = [
    Pan.apply({}, {}, columnConfig.name),
    Pan.apply({}, {}, columnConfig.location),
    Pan.apply({}, {}, columnConfig.tag),
    Pan.apply({}, {
        headerName: _T('DSCP/ToS'),
        valueGetter: params => {
            let val = getPathValue(params.data, "dscp-tos");
            if (val['any']) {
                return 'any';
            } else if (val['codepoints']) {
                return 'codepoints';
            }
            return '';
        },
        sortable: false,
        minWidth: 100
    }, columnConfig.dscp),
    Pan.apply({}, {}, columnConfig.cls)
];

export default class QoSPolicies extends PoliciesBaseViewer {
    serviceName = 'Policies/QoSPreRules';
    reduxId = 'qoSPreRules';
    header = 'QoS Rules';
    suppressColsSizeToFit = false;
    columns = columns;
    fields = fields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.pre-rulebase.qos.rules.entry';
    rbaPath = 'policies.qos-rulebase';
}