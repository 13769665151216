import React from "react";
import PropTypes from "prop-types";
import { BaseInput } from "../BaseInput";
import './PasswordWidget.scss';
const PasswordInput = BaseInput;

export function PasswordWidget(props) {
    return <PasswordInput type="password" {...props} />;
}

PasswordWidget.defaultProps = {
    required: false,
    disabled: false,
    readonly: false,
    autoFocus: false,
};

if (process.env.NODE_ENV !== "production") {
    PasswordWidget.propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        error: PropTypes.string,
        className: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autoFocus: PropTypes.bool,
        showErrors: PropTypes.bool,
        onKeyDown: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        type: PropTypes.string,
        onFocus: PropTypes.func,
        options: PropTypes.shape({
            inputType: PropTypes.string,
            emptyValue: PropTypes.string,
        }),
    };
    
}