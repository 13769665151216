import { jsonPath, ViewerManager, Pan } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

const _T = str => str;
const findItemInArray = (source, key) => {
    for (let index = 0; index < source.length; index++) {
        const item = source[index];
        if (item['@name'] === key) {
            return true;
        }
    }
    return false;
};

const applicationGroupFilter = (source, gridData, filter, type) => {
    let apps = [];
    let filters = [];
    let groups = [];

    for (let index = 0; index < source.length; index++) {
        const item = source[index];
        if (findItemInArray(gridData, item)) {
            groups.push(item);
        } else if (findItemInArray(filter, item)) {
            filters.push(item);
        } else {
            apps.push(item);
        }
    }
    if (type === 'app-group') {
        return groups;
    } else if (type === 'app-filter') {
        return filters;
    }

    return apps;
};

const applicationGroupObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        },
    },
    {
        name: '$.members.member',
        uiHint: {
            fieldLabel: _T('Applications'),
            showFilter: false,
        },
    },
    {
        name: '$.members.member.*',
        uiHint: {
            fieldLabel: _T('Applications'),
            groupOrder: ['application', 'application-group', 'application-filter']
        },
    },
    {
        name: '$',
        childrenNames: ['name', 'location', '$.members'],
    },
];

const applicationGroupObjectColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        recordConfigPath: '$.config.devices.entry.vsys.entry.application-group.entry',
        minWidth: 100
    }, {
        headerName: 'Location',
        field: '@loc',
        minWidth: 100
    }, {
        headerName: _T('Count'),
        id: 'applicationsMemberCount',
        valueGetter: params => {
            let members = params.data.members;
            if (members) {
                return members && members['member'] && members['member'].length ? members['member'].length : '';
            } else {
                console.log(params.data);
            }
        },
        minWidth: 80
    },
    {
        headerName: _T('Members'),
        style: { 'white-space': 'unset' },
        valueGetter: params => {
            return jsonPath(params.data, "members.member");
        },
        minWidth: 200
    }
];

const viewConfig = {
    serviceName: 'Objects/ApplicationGroups',
    reduxId: 'applicationGroupObjects',
    header: 'Application Groups',
    columns: applicationGroupObjectColumns,
    fields: applicationGroupObjectFields,
    recordConfigPath: '$.config.devices.entry.device-group.entry.application-group.entry',
    rbaPath: 'objects.application-groups',
    className: 'ApplicationGroups'
}

export default class ApplicationGroups extends DGBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);
