import React from "react";
import PropTypes from "prop-types";
import Pan from "../../schema/Pan";
import { FormContext, FormConsumer } from "../../FormContext";
import { NumberWidget } from "../../widgets/NumberWidget/NumberWidget";
import { FormGroup, Col, FormText } from "reactstrap";
import { getFieldLabelComp, getFieldWidthClass, getFieldHelpString, emptyTextFormat } from "../../schema/utils";
import classnames from "classnames";
import { TextfieldBuilder } from "../TextfieldBuilder";
import './NumberfieldBuilder.scss';
import _ from 'lodash';
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget";

export class NumberfieldBuilder extends TextfieldBuilder {
    static NAME = 'NumberfieldBuilder';
    constructor(props) {
        super(props);
        this.id = Pan.id();
    }

    renderNumberfield() {
        let {
            name,
            className,
            field,
            hideLabel,
            containerLabelWidthSize,
            onBlur,
            onFocus,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            autoFocus
        } = this.props;

        const value = this.state.value !== false ? this.state.value : this.getRecordValue();
        let errors = this.getErrors();
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let helpString = getFieldHelpString(field, this.context.formData);
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let textFieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let min = field.uiHint.minValue;
        let max = field.uiHint.maxValue;
        let placeHolder = emptyTextFormat(field);
        return (
            <FormGroup className={classnames(className, `d-flex flex-row ${required}`)} test_id={field.attrPath}>
                {!hideLabel && LabelComp}
                <Col >
                    {!this.state.hideField &&
                        <NumberWidget
                            id={this.id}
                            name={name}
                            value={value}
                            onChange={this.setRecordValue}
                            placeholder={placeHolder}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            disabled={disabled || this.state.disableField}
                            min={min}
                            max={max}
                            className={textFieldClassName}
                            error={error}
                            autoFocus={autoFocus}
                            showErrors={showErrors} />
                    }
                    {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                    {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderNumberfield()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

NumberfieldBuilder.contextType = FormContext;

NumberfieldBuilder.defaultProps = {
    disabled: false,
    autoFocus: false,
};

if (process.env.NODE_ENV !== "production") {
    NumberfieldBuilder.propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        /**
       * *attrPath* - defines the attribute schema path eg: "$.a.b.c" 
       * UiHint properties :
       * *hidden* - hides the field
       * *allowBlank* - if false,blank values will add error
       * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
       * Takes precedence over containerLabelWidthSize
       * *fieldLabel* - adds a label to the field
       * *hideLabel* - hides the label
       * *disabled* - disables/enables the field
       * *customValidation* - custom function for validation
       * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
       * The string values available are - 
       * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
       * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
       * isIpAddressMask,ipRange,multiVtype
       * *minValue* - the minimum value in the field
       * *maxValue* - the maximum value in the field
       * *showHelpString* - if true,will show custom help string/message
       * *helpstring* - set the help string/mesage
       */
        field: PropTypes.shape({
            uiHint: PropTypes.shape({
                allowBlank: PropTypes.bool,
                labelWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                disabled: PropTypes.bool,
                customValidation: PropTypes.func,
                vtype: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
                minValue: PropTypes.number,
                maxValue: PropTypes.number,
                showHelpString: PropTypes.bool,
                helpstring: PropTypes.string,
            }).isRequired,
            attrPath: PropTypes.string.isRequired
        }).isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        hideLabel: PropTypes.bool,
        containerLabelWidthSize: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        isEditorGridColumnEditor: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        autoFocus: PropTypes.bool,
        hidden: PropTypes.bool,
        filters: PropTypes.arrayOf(PropTypes.shape({
            find: PropTypes.string,
            replace: PropTypes.string
        })),
        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func.isRequired
    };
}