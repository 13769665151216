import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pan from '../../schema/Pan';
import { getBuilder } from '../../../utils/BuilderManager';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
} from 'reactstrap';
import classnames from 'classnames';

import { FormContext, FormConsumer } from '../../FormContext';
import { getField, getFieldLabel, getChildError } from '../../schema/utils';
import './TabBuilder.scss';
import { getPathValue, getPathValues } from '../../../utils/json';

export class TabBuilder extends Component {
    constructor(props) {
        super(props);
        this.id = Pan.id();

        const { field } = props;
        const childrenNames = field.childrenNames;

        this.state = {
            activeTab: childrenNames[0],
        };
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    renderTabs = () => {
        const { field, showErrors } = this.props;
        const { fields, errors } = this.context;
        const childrenNames = field.childrenNames;
        let fieldErrorMap = showErrors ? getChildError(childrenNames, fields, errors) : {};

        return childrenNames.map(name => {
            const thisField = getField(fields, name);
            let fieldError = fieldErrorMap ? fieldErrorMap[name] : null;
            let isError = !Pan.isEmpty(fieldError);
            const iconCls = getPathValue(thisField, 'uiHint.iconCls');
            const navItemCls = iconCls ? 'nav-item-with-icon' : '';
            return (
                <NavItem key={name} className={navItemCls}>
                    <NavLink
                        className={
                            classnames({
                                active: this.state.activeTab === name,
                                errortab: isError,
                            })
                        }
                        onClick={() => this.toggle(name)}
                    >
                        {iconCls && (
                            <div className="d-flex justify-content-center">
                                <div className={`tab-top-icon ${iconCls}`} />
                            </div>
                        )}
                        <span className={classnames({ errortab: isError })}>
                            {getFieldLabel(thisField)}
                        </span>
                    </NavLink>
                </NavItem>
            );
        });
    };

    renderTabContent = () => {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            disabled,
            showErrors,
        } = this.props;

        const { fields } = this.context;
        const childrenNames = field.childrenNames;
        let containerLabelWidthSize =
            getPathValues(field, 'uiHint.labelWidthSize') ||
            this.props.containerLabelWidthSize;

        return childrenNames.map(name => {
            const Builder = getBuilder({name, fields});
            const thisField = getField(fields, name);
            return (
                <TabPane key={name} tabId={name}>
                    <Builder
                        name={name}
                        field={thisField}
                        onChange={onChange}
                        addError={addError}
                        removeError={removeError}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        filters={filters}
                        containerLabelWidthSize={containerLabelWidthSize}
                        disabled={disabled}
                        showErrors={showErrors}
                    />
                </TabPane>
            );
        });
    };

    renderChildren = () => {
        const { field } = this.props;
        const isVertical = field && field.uiHint && field.uiHint.vertical;
        if (isVertical) {
            return (
                <div>
                    <Row className="vertical-tabs" test_id={field.attrPath}>
                        <Col sm="2">
                            <Nav tabs style={{ flexDirection: 'column' }}>
                                {this.renderTabs()}
                            </Nav>
                        </Col>
                        <Col>
                            <TabContent activeTab={this.state.activeTab}>
                                {this.renderTabContent()}
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (
                <div>
                    <Nav tabs>{this.renderTabs()}</Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.renderTabContent()}
                    </TabContent>
                </div>
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderChildren()}</FormConsumer>
            </React.Fragment>
        );
    }
}

TabBuilder.contextType = FormContext;

TabBuilder.defaultProps = {
    field: {},
    disabled: false,
};

if (process.env.NODE_ENV !== 'production') {
    TabBuilder.propTypes = {
        containerLabelWidthSize: PropTypes.string,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onChange: PropTypes.func,
        showErrors: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        disabled: PropTypes.bool,
        field: PropTypes.shape({
            childrenNames: PropTypes.array,
            uiHint: PropTypes.shape({
                labelWidthSize: PropTypes.string,
                vertical: PropTypes.bool,
            }),
        }),
    };
}
