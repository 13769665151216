import { getField } from '../autorender/schema/utils';
import Pan from '../autorender/schema/Pan';
import builderMap from '../autorender/builders/index';

export const getBuilder = ({
    name,
    fields,
    builder
}) => {
    const field = getField(fields, name);

    // if builder is undefined, we default it to ContainerBuilder
    let componentBuilder = builder || (field.uiHint && field.uiHint.builder) || 'ContainerBuilder';

    // get dynamic builder from field schema 
    if (Pan.isFunction(componentBuilder)) {
        // componentBuilder(field) can return either a Builder Component or the name of the Builder
        componentBuilder = componentBuilder(field);
    }

    // get register builder from builderMap
    if (Pan.isString(componentBuilder)) {
        if (builderMap[componentBuilder]) {
            componentBuilder = builderMap[componentBuilder];
        } else {
            throw new Error(`No "${componentBuilder}" builder found for "${name}"`);
        }
    }

    if (!componentBuilder) {
        throw new Error(`No "${componentBuilder}" builder found for "${name}"`);
    }

    return componentBuilder;
}

export function registerBuilder(name, component) {
    Object.assign(builderMap, { [name]: component });
}