import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import search from 'ui-lib/src/images/icon-search.svg'
import { ClearableInputWidget as Input } from 'ui-lib'
import './LogQueryInput.scss'

export default class LogQueryInput extends Component {
	focus() {
		if (this.inputRef) {
			this.inputRef.focus()
		}
	}
	blur() {
		if (this.inputRef) {
			this.inputRef.blur()
		}
	}

	onQueryChange = (e) => {
		const query = e.target.value
		this.props.onChange(e, query)
		this.props.onQueryChange(query, e)
	}
	onQueryPressEnter = (e) => {
		const query = e.target.value
		if (!e.shiftKey && !e.altKey && !e.metaKey) {
			this.props.onPressEnter(e, query)
		} else {
			this.props.onQueryPressEnter(query, e)
		}
	}
	onKeyDown = (e) => {
		if (e.key === 'Tab' && this.props.onPressTab) {
			e.preventDefault()
			return this.props.onPressTab(e, { ref: this })
		}
		if (e.key === 'Enter' && (e.shiftKey || e.altKey || e.metaKey)) {
			e.preventDefault()
			return // skip
		}
		return this.props.onKeyDown(e)
	}
	pressEnter = () => {
		const info = {
			key: 'Enter',
			keyCode: 13,
			which: 13
		}
		const e = window.KeyboardEvent
			? new KeyboardEvent('keydown', {
					bubbles: false,
					cancelable: true,
					...info
			  })
			: { ...info, preventDefault: _.noop }
		this.props.onKeyDown(e)
	}
	getValue() {
		return (this.input && this.input.value) || ''
	}
	saveRef = (ref) => {
		this.inputRef = ref
		this.input = this.inputRef && this.inputRef.input
	}

	render() {
		const { className, value, onChange, onPressEnter, onQueryChange, onQueryPressEnter, onPressTab, onKeyDown, ...props } = this.props
		return (
			<Input
				ref={this.saveRef}
				prefix={
					<i className={'This-is-an-icon'}>
						<img width={'12px'} height={'12px'} alt="search" src={search} />
					</i>
				}
				maxLength={1000}
				className={classNames(className, 'log-query-input')}
				value={value}
				onChange={this.onQueryChange}
				onPressEnter={this.onQueryPressEnter}
				onKeyDown={this.onKeyDown}
				spellCheck={false}
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				{...props}
			/>
		)
	}
}

LogQueryInput.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onPressEnter: PropTypes.func,
	onQueryChange: PropTypes.func,
	onQueryPressEnter: PropTypes.func,
	onKeyDown: PropTypes.func,
	onPressTab: PropTypes.func
}

LogQueryInput.defaultProps = {
	value: '',
	onChange: _.noop,
	onPressEnter: _.noop,
	onQueryChange: _.noop,
	onQueryPressEnter: _.noop,
	onKeyDown: _.noop
}
