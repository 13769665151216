import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ButtonWidget as Button } from 'ui-lib'
import './LogQueryButton.scss'

const LogQueryButton = ({ className, ...props }) => <Button {...props} className={classNames(className, 'log-query-btn')} color="transparent" />
LogQueryButton.propTypes = {
	className: PropTypes.string
}

export default LogQueryButton
