import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Modal, ModalBody } from 'reactstrap';
import { SchemaForm } from '../SchemaForm';
import PanSchema from '../../schema/PanSchema';
import { HLGridHeader, HLGrid } from '../HLGrid';
import { SpinnerWidget } from '../SpinnerWidget';
import _ from 'lodash';
import './HLViewer.scss';
import { ExtraInfoContext } from '../../ExtraInfoContext';
import Pan from '../../schema/Pan';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { updateModal, hideModal } from '../../../services/actions';


export class HLViewer extends Component {
    constructor(props) {
        super(props);
        let panSchema = new PanSchema(
            props.panSchema,
            props.recordConfigPath,
            props.inputFields,
        );
        this.state = {
            showForm: props.mode === 'form',
            record: props.record || {},
            formDataBackup: props.formData || props.record || {},
            formData: props.formData || props.record || {},
            panSchema: panSchema,
            filterText: props.persisFilterText || '',
            errors: {},
            isNewRecord: _.isEmpty(props.record),
            scrollIndex:0
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (Pan.isBoolean(nextProps.showForm) && nextProps.showForm !== prevState.showForm) {
            // change form display state
            return {
                showForm: nextProps.showForm,
            };
        }

        if(nextProps.persisFilterText !== prevState.filterText) {
            return {
                filterText: nextProps.persisFilterText
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, _) {
        if (Pan.isBoolean(prevProps.showForm) && prevProps.showForm !== this.state.showForm && prevProps.showForm) {
            this.updateModalButtons();
        }
    }


    displayRecordEditor = (record,rowIndex) => {
        this.setState({
            record: record || {},
            isNewRecord: _.isEmpty(record),
            formDataBackup: record || {},
            formData: record || {},
            errors: {},
            scrollIndex:rowIndex
        });

        if (this.props.onShowForm) {
            this.props.onShowForm();
        } else {
            this.setState({
                showForm: true,
            });
        }
    };

    hideRecordEditor = () => {
        this.setState({
            record: {},
            isNewRecord: true,
            formDataBackup: {},
            formData: {},
            errors: {},
        });
        if (this.props.onHideForm) {
            this.props.onHideForm();
        } else {
            this.setState({
                showForm: false,
            });
        }
        this.updateModalButtons();
    }

    updateModalButtons = () => {
        if (!this.props.modalId || this.props.displayMode !== 'modal') {
            return;
        }
        updateModal(
            this.props.modalId,
            {
                size: this.props.modalSize,
                actions: [{
                    text: "Close",
                    color: "secondary",
                    action: () => {
                        hideModal(this.props.modalId);
                    }
                }]
            }
        );
    }

    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        this.hideRecordEditor();
    };

    onAddRecord = (record, configPath) => {
        if (this.props.onAddRecord) {
            this.props.onAddRecord(record, configPath);
        }
    };

    onEditRecord = (record, configPath) => {
        if (this.props.onEditRecord) {
            this.props.onEditRecord(record, this.state.record, configPath);
        }
    };

    onFilter = (filterText) => {
        if(this.props.onPersisFilter && Pan.isFunction(this.props.onPersisFilter)) {
            this.props.onPersisFilter(filterText);
        }else{
            this.setState({
                filterText: filterText
            });
        }
    }

    onSubmit = ({ formData }) => {
        // invoke updateRecord function
        if (this.state.isNewRecord) {
            // Add record
            this.onAddRecord(formData, this.props.recordConfigPath);
        } else {
            // Edit record
            if (this.props.prepareAddRecord) {
                return this.props.prepareAddRecord(formData, this.onEditRecord.bind(this, formData, this.props.recordConfigPath));
            }
            this.onEditRecord(formData, this.props.recordConfigPath);
        }
    };

    onConfigureRecord = () => {
        let record = this.props.gridData ? this.props.gridData[0] : undefined;
        this.displayRecordEditor(record);
    };

    renderForm = () => {
        let HeaderComponent = this.props.HeaderComponent || HLGridHeader;
        const wideFormClassName = this.props.widerForm ? 'hlgrid-form-wide' : '';
        let header = this.props.displayMode != 'modal' ? this.props.header : '';
        return (
            <Card className="m-0">
                {(header || this.props.showFilter) &&
                    <CardHeader className="hlgrid-header bg-light border-bottom">
                        <HeaderComponent header={header} onFilter={this.onFilter} showFilter={this.props.showFilter} />
                    </CardHeader>
                }
                <CardBody className="p-0">
                    {this.props.formLoading && <SpinnerWidget />}
                    <SchemaForm
                        id={this.props.recordConfigPath}
                        itemId={this.props.itemId || '$'}
                        modalId={this.props.modalId}
                        modalFormSize={this.props.modalFormSize}
                        className={classnames("hlgrid-form", wideFormClassName)}
                        schema={this.state.panSchema.schema}
                        fields={this.state.panSchema.fields}
                        recordConfigPath={this.props.recordConfigPath}
                        isNewRecord={this.state.isNewRecord}
                        formData={this.state.formData}
                        formDataBackup={this.state.formDataBackup}
                        errors={this.state.errors}
                        displayMode={this.props.displayMode}
                        formatFormData={true}
                        formatFormDataPostProcess={true}
                        configLocation={this.props.configLocation}
                        rbaPermission={this.props.rbaPermission}
                        locationPermission={this.getLocationPermission(
                            this.state.record,
                        )}
                        onSubmit={({ formData }) => this.onSubmit({ formData })}
                        onCancel={() => this.onCancel()}
                        onBlur={(id, value) => console.log(`Blured ${id} with value ${value}`)}
                        onFocus={(id, value) => console.log(`Focused ${id} with value ${value}`)}
                        onError={() => console.log("errors")}
                    />
                </CardBody>
            </Card>
        );
    };

    /***
     * HLViewer contraints
     * 1. panSchema - pan schema
     * 2. recordConfigPath - schema path for the root of the record form editor
     * 3. inputFields - input fields list to display inthe form
     * 4. updateRecord - function call back on form submit
     */
    renderView() {
        let editorMode = this.props.editorMode || 'default';
        let HLComponent = this.props.HLComponent || HLGrid;
        let configureActions = this.props.showConfigureActions ? [{
            text: 'Configure',
            callback: () => {
                this.onConfigureRecord();
            }
        }] : undefined;
        let gridActions = this.props.gridActions ? this.props.gridActions : [];
        if (configureActions) {
            gridActions = [...gridActions, ...configureActions];
        }
        if (editorMode == 'modal') {
            // Showing grid viewer
            return (
                <React.Fragment>
                    <HLComponent
                        {...this.props}
                        displayRecordEditor={this.displayRecordEditor}
                        hideRecordEditor={this.hideRecordEditor}
                        rbaPermission={this.props.rbaPermission}
                        configLocation={this.props.configLocation}
                        fields={this.state.panSchema.fields}
                        scrollIndex={this.state.scrollIndex}
                        gridActions={gridActions}
                        rowClassRules={this.props.rowClassRules}
                    />
                    <Modal
                        isOpen={this.state.showForm}
                        size="lg"
                        fade
                        centered
                        className={'modal-form'}
                    >
                        <ModalBody>{this.renderForm()}</ModalBody>
                    </Modal>
                </React.Fragment>
            );
        } else {
            if (!this.state.showForm) {
                // Showing grid viewer
                return (
                    <HLComponent
                        {...this.props}
                        displayRecordEditor={this.displayRecordEditor}
                        hideRecordEditor={this.hideRecordEditor}
                        rbaPermission={this.props.rbaPermission}
                        configLocation={this.props.configLocation}
                        fields={this.state.panSchema.fields}
                        scrollIndex={this.state.scrollIndex}
                        gridActions={gridActions}
                        pageSize={this.props.pageSize || this.props.displayMode === 'modal' ? 20 : undefined}
                        gridOverride={this.props.gridOverride}
                    />
                );
            } else {
                // Showing form viewer
                return this.renderForm();
            }
        }
    }

    getLocationPermission(record) {
        let configLocation = this.props.configLocation;
        let locationName = configLocation
            ? configLocation.dg
                ? configLocation.dg.name
                : configLocation.tpl
                ? configLocation.tpl.name
                : null
            : null;
        if (record && record['@loc'] && record['@loc'] !== locationName) {
            return 'disable';
        }
        return 'enable';
    }

    render() {
        return <React.Fragment>{this.renderView()}</React.Fragment>;
    }
}

HLGrid.contextType = ExtraInfoContext;

HLViewer.defaultProps = {};
if (process.env.NODE_ENV !== 'production') {
    HLViewer.propTypes = {
        panSchema: PropTypes.object,
        inputFields: PropTypes.array,
        recordConfigPath: PropTypes.string,
        /**
         * if mode=='form' , then showForm=true
         * i.e the schema form will be open when the HLViewer
         * loads initially
         */
        mode: PropTypes.string,
        record: PropTypes.object,
        formdata: PropTypes.object,
        showForm: PropTypes.bool,
        /**
         * if available onShowForm is run else showForm is set to true
         */
        onShowForm: PropTypes.func,
        /**
         * if available onHideForm is run else showForm is set to false
         */
        onHideForm: PropTypes.func,
        onCancel: PropTypes.func,
        onAddRecord: PropTypes.func,
        onEditRecord: PropTypes.func,
        onDeleteRecord: PropTypes.func,
        onCloneRecord: PropTypes.func,
        gridData: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
          ]),
        HeaderComponent: PropTypes.func,
        widerForm: PropTypes.bool,
        header: PropTypes.string,
        showFilter: PropTypes.bool,
        formLoading: PropTypes.bool,
        itemId: PropTypes.string,
        configLocation: PropTypes.object,
        rbaPermission: PropTypes.string,
        /**
         * value could be one of "default" or "modal"
         */
        editorMode: PropTypes.string,
        HLComponent: PropTypes.func,
        /**
         * displays Configure action button
         */
        showConfigureActions: PropTypes.bool,
        gridActions: PropTypes.array,
        /**
         * isAddDisabled is a function which returns true or false which determines if
         * Add button will be available or disabled
         */
        gridOverride: PropTypes.shape({
            gridActionsAvail: PropTypes.shape({
                isAddDisabled: PropTypes.func,
            }),
        }),
    };
}
