let store = null;

export function getStore() {
    return store;
}

export function registerStore(str) {
    store = str;
}

export function getStoreState() {
    return store ? store.getState() : {};
}