import React, { Component } from 'react';
import getGPCSRecordViewer from './GPCSRecordViewer';
import { ButtonWidget, getStore, showModal, hideModal } from 'ui-lib';
import { fetchGpcsItem } from '../main/actions';

const _T = str => str;

window.__pan_schema.config.injected.apiKey = {
    "@attr": { "node-type": "sequence" },
    result: {
        "@attr": {
            "node-type": "element", type: "string", optional: "yes"
        }
    }
};

let apiKeyFields = [{
    name: '$',
    childrenNames: ['$.result', 'Generate'],
    uiHint: {
        labelWidthSize: 4,
        layout: [
            {
                '$.result': 'col-9',
                'Generate': 'col-3'
            }
        ]
    }
}, {
    name: '$.result',
    uiHint: {
        fieldLabel: "API Key",
        showHelpString: _T('To use SAML-Integrated with Prisma SaaS to authenticate Clientless VPN, please follow the Prisma SaaS SAML Proxy workflow.'),
        disabled: true
    }
}, {
    name: 'Generate',
    uiHint: {
        builder: (field) => {
            return () => {
                return (
                    <ButtonWidget
                        color="secondary"
                        size="sm"
                        onClick={e => {
                            let store = getStore();

                            const genApiKeyModalId = 'ServiceInfraGenApiKeyModal';
                            showModal({
                                id: genApiKeyModalId,
                                open: true,
                                size: 'sm',
                                toggle: () => {
                                    hideModal(genApiKeyModalId);
                                },
                                title: _T('Generate new API key'),
                                message: _T('Generating a new API key will invalidate the current key. Make sure to update your scripts.'),
                                actions: [
                                    {
                                        text: _T('Cancel'),
                                        color: 'secondary',
                                        action: () => {
                                            hideModal(genApiKeyModalId);
                                        }
                                    },
                                    {
                                        text: _T('Generate'),
                                        color: 'primary',
                                        action: () => {
                                            store.dispatch(
                                                fetchGpcsItem('/api/extensions/gpcs/generateApiKey', '', 'API_KEY')
                                            )
                                            hideModal(genApiKeyModalId);
                                        }
                                    }
                                ]
                            });

                        }}
                        text="Generate"
                    />
                )
            }
        }
    }
}];

export default class APIKeyForMU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false,
            GPCSRecordViewer: null
        };
    }

    componentDidMount() {
        this.setState({
            GPCSRecordViewer: getGPCSRecordViewer(
                '',
                'apiKey',
                '',
                'API_KEY',
                'msg'
            )
        });
    }

    render() {
        let GPCSRecordViewer = this.state.GPCSRecordViewer;
        return (
            GPCSRecordViewer &&
            <GPCSRecordViewer
                configLocation={this.props.configLocation}
                fetchRecord={true}
                className='api-key-viewer'
                rbaPermission='enable'
                locationPermission='enable'
                panSchema={window.__pan_schema}
                recordConfigPath='$.config.injected.apiKey'
                inputFields={apiKeyFields}
                hideOk={true}
                hideCancel={true}
                header=''
                isDynamicLoadData={true}
            />
        );
    }
}
