import React, { Component } from "react";
import {
	ButtonWidget,
	getPathValue,
	getStoreState,
	getFieldLabelComp
} from "ui-lib";
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	FormGroup,
	Label,
	Col,
	Container,
	Row
} from "reactstrap";
import "./SamlIdpInfo.scss";
import classnames from "classnames";
import ExportCert from '../commonInfra/ExportCert';
import {
    SAML_SIGNING_CERT_NAME
} from '../main/actions';

export default class SamlIdpInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			copyButtonStr: "Copy",
			clickedOnce: false
		};
		this.clipboardStr = "";
	}

	toggleModal = () => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
			copyButtonStr: "Copy",
			clickedOnce: false
		}));
	};

	createUrls = (url, title) => {
		let urlList = {};
		urlList.title = `${title}`;
		urlList.entity = `Entity ID: https://${url}:443/SAML20/SP`;
		urlList.acs = `ACS URL: https://${url}:443/SAML20/SP/ACS`;
		urlList.slo = `SLO URL: https://${url}:443/SAML20/SP/SLO`;
		return urlList;
	};

	renderDataFromInfo = info => {
		let dataList = [];
		if (info.Portals) {
			dataList.push(this.createUrls(info.Portals, "Prisma Access Hostname"));
		}
		if (info.Gateways) {
			info.Gateways.map((gateway, index) => {
				dataList.push(this.createUrls(gateway, `Gateway ${index + 1}`));
				return null;
			});
		}
		return dataList;
	};

	renderData = data => {
		this.clipboardStr = "";
		return data.map(item => {
			return this.renderInfo(item);
		});
	};

	renderInfo = data => {
		return (
			<div>
				{this.renderTitle(data.title)}
				{this.renderDataRow(data.entity)}
				{this.renderDataRow(data.acs)}
				{this.renderDataRow(data.slo)}
			</div>
		);
	};

	renderTitle = title => {
		let str = `${title} \n`;
		this.clipboardStr += str;
		return (
			<p className="data">
				<strong>{title}</strong>
			</p>
		);
	};

	renderDataRow = data => {
		let str = `${data} \n`;
		this.clipboardStr += str;
		return <p className="data">{data}</p>;
	};

	copyStringToClipboard = str => {
		var el = document.createElement("textarea");
		el.value = str;
		el.setAttribute("readonly", "");
		el.style = { position: "absolute", left: "-9999px" };
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	};

	copyData = str => {
		if (!this.state.clickedOnce) {
			this.copyStringToClipboard(str);
		}
		this.setState({
			copyButtonStr: "Copied!",
			clickedOnce: true
		});
	};

	render() {
		let {
			name,
			className,
			field,
			hideLabel,
			containerLabelWidthSize,
			isEditorGridColumnEditor
		} = this.props;
		let required = field.uiHint.allowBlank === false ? "required" : "";
		let LabelComp = getFieldLabelComp(
			field,
			name,
			isEditorGridColumnEditor,
			containerLabelWidthSize
		);
		const info = getPathValue(getStoreState(), "gpcs.mobileUsersNetworkDetails");
		
		return (
			<FormGroup className={classnames(className, `d-flex flex-row ${required}`)}>
				{!hideLabel && LabelComp}
				<Col>
					<FormGroup>
						<ButtonWidget
							color="primary"
							size="sm"
							onClick={this.toggleModal}
							text="View Info"
						/>
						<Modal
							isOpen={this.state.isOpen}
							size="lg"
							toggle={this.toggleModal}
							fade
							centered
						>
							<ModalHeader toggle={this.toggle}>
								Information to Configure you SAML IdP
							</ModalHeader>
							<ModalBody>
								<FormGroup>
									<>
										<Label className="flex-grow-0 flex-shrink-0 col-form-label col-3">
											SAML SIGNING CERTIFICATE
										</Label>
										<ExportCert
											className={'exportCert'}
											certificateNames={[SAML_SIGNING_CERT_NAME]}
											certLocation='api/config/v9.0/Device/CertificateManagement/ExportCertificate'
										/>
									</>
									<br></br>
									<>
										<Label className="flex-grow-0 flex-shrink-0 col-form-label col-3">
											SAML IDP INFORMATION
										</Label>
										<div className="info-window">
											{info[0] &&
												this.renderData(this.renderDataFromInfo(info[0]))}
										</div>
									</>
								</FormGroup>
							</ModalBody>
							<ModalFooter>
								<ButtonWidget
									color="primary"
									onClick={this.toggleModal}
									text="Close"
								/>
								<ButtonWidget
									color="primary"
									onClick={() => this.copyData(this.clipboardStr)}
									text={this.state.copyButtonStr}
								/>
							</ModalFooter>
						</Modal>
					</FormGroup>
				</Col>
			</FormGroup>
		);
	}
}
