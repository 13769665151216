import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Collapse } from "reactstrap";
import { getBuilder } from "../../../utils/BuilderManager";
import { FormContext, FormConsumer } from '../../FormContext';
import { getField } from "../../schema/utils";
import { getPathValues } from "../../../utils/json";
import './CollapseContainerBuilder.scss'
import { BaseBuilder } from '../BaseBuilder';
import classnames from 'classnames';


export class CollapseContainerBuilder extends BaseBuilder {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            value: 'basic',
            expand: false,
            showEditor: false
        }
    }

    toggle() {
        this.setState({ expand: !this.state.expand });
    }

    layoutChildren(layout) {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors
        } = this.props;
        const { fields } = this.context;
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        return layout.map((row, index) => {
            return (
                <Row key={index} className="d-flex flex-row">
                    {
                        Object.keys(row).map((name) => {
                            const Builder = getBuilder({name, fields});
                            const thisField = getField(fields, name);
                            return (
                                <Col key={name} className={row[name]}>
                                    <Builder
                                        name={name}
                                        field={thisField}
                                        onChange={onChange}
                                        addError={addError}
                                        removeError={removeError}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        filters={filters}
                                        cellInfo={cellInfo}
                                        containerLabelWidthSize={containerLabelWidthSize}
                                        isEditorGridColumnEditor={isEditorGridColumnEditor}
                                        disabled={disabled}
                                        showErrors={showErrors}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            )
        });
    }

    showEditor() {
        this.setState({
            showEditor: true
        });
    }

    hideEditor() {
        this.setState({
            showEditor: false
        });
    }

    renderChildren() {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            from,
        } = this.props;
        const { fields } = this.context;
        const childrenNames = field.childrenNames;
        if (!childrenNames) {
            return (
                <div />
            );
        }

        const layout = field.uiHint.layout;
        if (layout) {
            return this.layoutChildren(layout);
        }
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;

        return childrenNames.map(name => {
            const Builder = getBuilder({name, fields});
            const thisField = getField(fields, name);

            return (
                <Builder
                    key={name}
                    name={name}
                    field={thisField}
                    onChange={onChange}
                    addError={addError}
                    removeError={removeError}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    filters={filters}
                    cellInfo={cellInfo}
                    containerLabelWidthSize={containerLabelWidthSize}
                    isEditorGridColumnEditor={isEditorGridColumnEditor}
                    disabled={disabled}
                    showErrors={showErrors}
                    from={from}
                />
            );
        });
    }

    render() {
        const collapsedClassname = this.state.expand ? 'expanded' : '';
        return (
            <div>
                {/* <div className={"field-radio-group form-control col-sm-auto col-md-6 offset-md-3"}>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" name="radio1" checked={value==='basic'} value="basic" onChange={() => this.toggle('basic')} />{' '}Basic
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check><Input type="radio" name="radio1" checked={value==='advanced'} value="advanced"  onChange={() => this.toggle('advanced')} />{' '}Advanced</Label>
                    </FormGroup>
                </div> */}
                <Button onClick={this.toggle} className={classnames(collapsedClassname, 'btn btn-secondary btn-sm col-sm-auto col-md-6 offset-md-3 collapse-btn')} >Advanced</Button>
                <Collapse isOpen={this.state.expand} className="mt-3">
                    <React.Fragment>
                        <FormConsumer>
                            {() => this.renderChildren()}
                        </FormConsumer>
                    </React.Fragment>
                </Collapse>
            </div>
        );
    }
}

CollapseContainerBuilder.contextType = FormContext;

CollapseContainerBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    CollapseContainerBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        field: PropTypes.object,
        id: PropTypes.string,
        className: PropTypes.string
    };
}
