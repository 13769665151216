import React from "react";
import { Component } from "react";
import { ExtraInfoContext } from "ui-lib";
import _ from "lodash";

export class PolicyApplicationColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getApplicationHTML = item => {
		if (!item.value || item.value === 'any') {
			return item.value;
		}
		if (item.type === "predefined-application") {
			return (
				<div key={item.value}>
					<img src="/images/appbrowser/application.png" alt="predefined-application" />
					{item.value}
				</div>
			);
		} else if (item.type === "predefined-application-container") {
			return (
				<div key={item.value}>
					<img src="/images/appbrowser/application.png" alt="Address groups" />
					{item.value}
				</div>
			);
		} else if (item.type === "predefined-application-filter") {
			return (
				<div key={item.value}>
					<img src="/images/treemenu/application-filter.png" alt="predefined-application-filter" />
					{item.value}
				</div>
			);
		} else if (item.type === "application-group") {
			return (
				<div key={item.value}>
					<img src="/images/treemenu/application-groups.png" alt="application-group" />
					{item.value}
				</div>
			);
		} else if (item.type === "custom") {
			return (
				<div key={item.value}>
					<img src="/images/appbrowser/application.png" alt="custom" />
					{item.value}
				</div>
			);
		} else if (item.type === "application-filter") {
			return (
				<div key={item.value}>
					<img src="/images/treemenu/application-filter.png" alt="application-filter" />
					{item.value}
				</div>
			);
		} else {
			return (
				<div key={item.value}>
					<img src="/images/appbrowser/application.png" alt="Applications" />
					{item.value}
				</div>
			);
		}
	};

	renderApplication = data => {
		let formattedApplications = [];
		const policiesApplications = _.get(this.context, "extraInfo.policiesApplications", []);
		data.forEach(item => {
			let foundItem = policiesApplications.find(element => element.value === item);
			foundItem = foundItem ? foundItem : { value: item, type: "application" };
			formattedApplications.push(this.getApplicationHTML(foundItem));
		});
		return formattedApplications;
	};

	render() {
		return <div>{this.renderApplication(this.props.data)}</div>;
	}
}
PolicyApplicationColumn.contextType = ExtraInfoContext;
