import React, { Component } from 'react';
import PropTypes from "prop-types";
import { UncontrolledTooltip, FormFeedback } from 'reactstrap';

import './FormFeedbackWidget.scss';

export class FormFeedbackWidget extends Component {
    render() {
        let { target, inline, feedback, placement } = this.props;
        placement = placement || "auto";

        var element = document.getElementById(target);
        if (!element) {
            // if target element unmounted, don't show tooltip
            console.log("Element unmounted, no tooltip needed.");
            return (<div />);
        }

        if (inline === true) {
            return (
                <FormFeedback>{feedback}</FormFeedback>
            );
        }

        return (
            <UncontrolledTooltip 
                delay={{show:0, hide:0}} 
                className="form-feedback" 
                placement={placement} 
                target={target}
                boundariesElement="window"
            >
                {feedback}
            </UncontrolledTooltip>
        );
    }
}

FormFeedbackWidget.defaultProps = {
};

if (process.env.NODE_ENV !== "production") {
    FormFeedbackWidget.propTypes = {
       target:PropTypes.string,
       error:PropTypes.string
    }
}
