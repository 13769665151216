import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import CreatableSelect from 'react-select/lib/Creatable';
import { convertToSelectionValue, processSelectionValueObject, INJECTED_REC_ID, generateGridRecId, groupOptions } from '../util';
import Pan from '../../schema/Pan';
import './BaseSelectWidget.scss';

const formatOptionLabel = (data) => (
    <div className='select-item-label'>{data.label}</div>
);

const Group = (props) => {
    const { children, data } = props;
    const { expanded } = data;
    const labelClass = expanded === false ? 'collapsed' : 'open';
    return (
        <div>
            <div onClick={() => props.selectProps.toggle(data)} className={'select-group ' + labelClass}>
                <span className='select-group-label'>{data.label}</span>
                <span className='select-group-badge'>{data.options.length}</span>
            </div>
            <div className='select-group-children' style={{ display: expanded === false ? "none" : "block" }}>{children}</div>
        </div>
    );
};

export class BaseSelectWidget extends React.Component {
    constructor(props) {
        super(props);
        let isLoading = false;
        if (props.loadOptions && (!props.query || props.query == 'local')) {
            // local query
            isLoading = true;
            this.getOptions(props);
        }
        const value = convertToSelectionValue(props.value, props.options);
        this.state = {
            options: groupOptions(props.options, props.groupOrder, props.duplicateOptions, value),
            selectKey: 0,
            value,
            isLoading,
            searchOn: false
        };
        this.select = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    toggle(data) {
        const { searchOn } = this.state
        this.state.options.forEach((option) => {
            option.expanded = option.label === data.label ? !option.expanded : searchOn ? option.expanded : false;
        })
        this.setState(this.state);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let { value, options } = prevState;
        if (nextProps.value !== value || (Pan.isArray(options) && Pan.isArray(nextProps.options) && options.length == 0 && nextProps.options.length > 0)) {
            let _options = options;
            if (_options.length === 0 && Pan.isArray(nextProps.options) && nextProps.options.length > 0) {
                _options = nextProps.options;
            }
            value = convertToSelectionValue(nextProps.value, _options);
            if (!value && nextProps.value) {
                // this is newly created option
                value = {
                    value: nextProps.value,
                    label: nextProps.value
                };
            }
        }
        if (nextProps.loadOptions) {
            return {
                selectKey: nextProps.selectKey || prevState.selectKey,
                value: value,
            };
        } else {
            return {
                selectKey: nextProps.selectKey || prevState.selectKey,
                value: value,
                options: groupOptions(nextProps.options, nextProps.groupOrder, nextProps.duplicateOptions, value)
            };
        }
    }

    componentDidUpdate(prevProps, _) {
        if (this.props.loadOptions && (!this.props.query || this.props.query == 'local') && prevProps.selectKey !== this.state.selectKey) {
            // local query
            this.getOptions(this.props);
        }
    }

    getOptions = (props) => {
        // only for async - local query. Need to cache otions
        props.loadOptions().then(
            response => {
                const { value } = this.state;
                let _options = groupOptions(response, props.groupOrder, props.duplicateOptions, value && value.value);
                if (value) {
                    let _value = convertToSelectionValue(value, _options);
                    this.setState({
                        options: _options,
                        value: _value,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        options: _options,
                        isLoading: false
                    });
                }
            },
            error => { },
        );
    }

    handleChange = (value) => {
        this.setState({
            value: value,
        });
    }

    handleFocus = element => {
        if (this.state.value && this.select && this.select.state) {
            this.select.state.inputValue = this.state.value.label;
        }
    };

    handleMenuClose = () => {
        this.select && this.select.blur ? this.select.blur() : null;
    };

    handleCreate = (inputValue) => {
        if (this.props.handleCreate) {
            this.props.handleCreate(inputValue);
        } else {
            let newOptions = [...this.state.options, { value: inputValue, label: inputValue }]
            this.setState({
                options: newOptions,
                value: { value: inputValue, label: inputValue }
            });
            this.props.onChange(inputValue);
        }
    };

    focus = () => {
        if (this.select && this.select.current.focus) {
            this.select.current.focus();
        }
    }

    onInputChange(searchText) {
        const { options } = this.state;
        const { groupOrder } = this.props;
        const searchOn = searchText === '' ? false : true;

        if (groupOrder) {
            options.forEach(opt => {
                opt.expanded = searchOn;
            })
            this.setState({
                options,
                searchOn
            })
        }
    }
  
    render() {
        const {
            id, 
            value,
            required,
            disabled,
            readonly,
            multiple,
            onChange,
            onBlur,
            onFocus,
            name,
            styles,
            menuPortalTarget,
            error,
            showErrors,
            customProps,
            autoFocus,
            loadOptions,
            MenuList,
            creatable,
            isClearable,
            searchable,
            placeholder,
            widgetType // select - simple Select, async - for AsyncSelect
        } = this.props;
        const emptyValue = multiple ? [] : '';
        let errorClassName = error && showErrors && !disabled ? 'select-error' : '';
        const customStyles = {
            ...styles,
            menuPortal: styles => ({ ...styles, zIndex: 9999 }),
        };

        let widgetProps = {
            id: id,
            isClearable: Pan.isBoolean(isClearable) ? isClearable : false,
            isSearchable: Pan.isBoolean(searchable) ? searchable : true,
            key: this.state.selectKey,
            cacheOptions: true,
            defaultOptions: true,
            isMulti: multiple,
            defaultValue: typeof value === 'undefined' ? emptyValue : value,
            isRequired: required,
            isDisabled: disabled || readonly,
            autoFocus: autoFocus,
            value: this.state.value,
            name: name,
            menuPortalTarget: menuPortalTarget || document.body,
            placeholder: placeholder || 'None',
            styles: customStyles,
            className: errorClassName,
            openMenuOnFocus: true,
            onBlur: onBlur && (event => { onBlur(id, this.state.value); }),
            onFocus: onFocus && (event => { onFocus(id, this.state.value); }),
            onChange: (valueObj, actionType) => {
                let value = processSelectionValueObject(valueObj, multiple);
                this.handleChange(valueObj);
                onChange(value);
            },
            isLoading: this.state.isLoading 
        };
        let Widget = null;
        if (widgetType === 'select' && this.state.options) {
            // select type should have options
            widgetProps.options = this.state.options;
            Widget = creatable === true ? CreatableSelect : Select;
        } else if (widgetType === 'async' && loadOptions) {
            // async type should have loadOptions
            widgetProps.loadOptions = loadOptions;
            Widget = creatable === true ? AsyncCreatableSelect : AsyncSelect;
        }

        if (creatable === true) {
            widgetProps.createOptionPosition = 'first';
            widgetProps.onCreateOption = this.handleCreate;
            widgetProps.onFocus = this.handleFocus;
            widgetProps.onMenuClose = this.handleMenuClose;
        }
        const components = { Group };
        MenuList ? Pan.apply(components, { MenuList }) : null;
        return (
            <React.Fragment>
                {Widget && (
                    <Widget
                        className='compact-select-container'
                        classNamePrefix='compact-select'
                        ref={this.select}
                        components={components}
                        {...widgetProps}
                        {...customProps}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#e6f5fc',
                                primary: '#0a9cdd',
                            },
                        })}
                        onInputChange={this.onInputChange}
                        formatOptionLabel={formatOptionLabel}
                        toggle={this.toggle}
                    />
                )}
            </React.Fragment>
        );
    }
}

BaseSelectWidget.defaultProps = {
    autoFocus: false,
    disabled:false,
    readonly:false,
    required:true,
    options:[]
};

if (process.env.NODE_ENV !== 'production') {
    BaseSelectWidget.propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value:PropTypes.string,
            label:PropTypes.string
        })),
        loadOptions: PropTypes.func,
        query: PropTypes.oneOf(['local','remote']),
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        multiple: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        selectKey: PropTypes.number,
        id: PropTypes.string,
        name: PropTypes.string,
        styles: PropTypes.object,
        menuPortalTarget: PropTypes.object,
        error: PropTypes.string,
        showErrors: PropTypes.bool,
        customProps: PropTypes.object,
        MenuList: PropTypes.func,
        creatable: PropTypes.bool,
        isClearable: PropTypes.bool,
        searchable: PropTypes.bool,
        widgetType: PropTypes.oneOf(['select','async']).isRequired,
    };
}
