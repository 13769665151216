import React from 'react';
import GridTotal from './GridTotal';
import GridAction from './GridAction';
import Pagination from './Pagination';
import { FilterWidget } from '../FilterWidget';
import classnames from 'classnames';
import Pan from "../../schema/Pan";

class GridToolbar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const totalItem = this.props.gridData.length;
        const { showPaging, showFilter, showExportCsvBtn, searchObjectName } = this.props;
        return (
            <div>
                {showFilter && (
                    <div className="pagination-bar">
                        <FilterWidget
                            id="editorGridFilterInput"
                            objectName={Pan.isDefined(searchObjectName) ? searchObjectName : "Object Name"}
                            onFilter={this.props.onFilter}
                        />
                    </div>
                )} 
                <div className={classnames(this.props.className,"pagination-bar")}>
                    {showPaging && (
                        <GridTotal totalItem={totalItem} itemName={'Items'} />
                    )}
                    <GridAction
                        actionList={this.props.gridActions}
                        selected={this.props.selected}
                        gridData={this.props.gridData}
                    />
                    {showPaging && this.props.pages > 1 && (
                        <Pagination
                            pages={this.props.pages}
                            data={this.props.gridData}
                            changePage={this.props.changePage}
                        />
                    )}
                    {showExportCsvBtn && (
                        <a className="btn-export-csv" onClick={ () => this.props.onExportCSV(this.props.gridData) }>Export to CSV</a>
                    )}
                </div>
            </div>
        );
    }
}

export default GridToolbar;
