import React, { Component } from 'react';
import {
    SelectWidget,
    GridWidget,
    Pan,
    FilterWidget,
    jsonPath,
    xmlStringToDocument,
    xmlToJson,
    getPathValue
} from 'ui-lib';
import { getBGPStatusObservable } from './services';
import {
    Container,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Collapse,
    Button
} from 'reactstrap';
import classnames from 'classnames';
import { ajax } from 'rxjs/ajax';
import './main.scss';


const _T = str => str

const PEER_COLUMNS = [
    {
        headerName: "Name",
        field: "name"
    },
    {
        headerName: "Value",
        field: "value"
    },
];

const RIB_IN_COLUMNS = [
    {
        headerName: "Prefix",
        field: "prefix"
    },
    {
        headerName: "Flag",
        field: "flag"
    },
    {
        headerName: "Next Hop",
        field: "nextHop"
    },
    {
        headerName: "Weight",
        field: "weight"
    },
    {
        headerName: "Local Preference",
        field: "localPreference"
    },
    {
        headerName: "AS Path",
        field: "as-path"
    },
    {
        headerName: "Origin",
        field: "origin"
    },
    {
        headerName: "MED",
        field: "med"
    },
    {
        headerName: "Flap Count",
        field: "flapCount"
    }
];

const RIB_OUT_COLUMNS = [
    {
        headerName: "Prefix",
        field: "prefix"
    },
    {
        headerName: "Flag",
        field: "flag"
    },
    {
        headerName: "Next Hop",
        field: "nextHop"
    },
    {
        headerName: "Weight",
        field: "weight"
    },
    {
        headerName: "Local Preference",
        field: "localPreference"
    },
    {
        headerName: "AS Path",
        field: "as-path"
    },
    {
        headerName: "Origin",
        field: "origin"
    },
    {
        headerName: "MED",
        field: "med"
    },
    {
        headerName: "Adv. Status",
        field: "advertise-status"
    },
    {
        headerName: "Aggr. Status",
        field: "aggregation-status"
    }
];

export default class BGPStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site: this.props.site,
            ribin: this.props.ribin,
            ribout: this.props.ribout,
            refreshInterval: '0',
            activeTab: '1',
            peerData: [],
            ribInData: [],
            ribOutData: [],
            collapseList: [],
            peerFilterStr: "",
            refreshOptions: [
                {
                    value: '0',
                    label: _T(`Manual`)
                },
                {
                    value: '10',
                    label: _T(`10 seconds`)
                },
                {
                    value: '20',
                    label: _T(`20 seconds`)
                },
                {
                    value: '30',
                    label: _T(`30 seconds`)
                }
            ]
        };
        this.handleRefreshChange = this.handleRefreshChange.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.loadBGPStatus = this.loadBGPStatus.bind(this);
        this.onClientFilter = this.onClientFilter.bind(this);
    }

    componentDidMount() {
        this.loadBGPStatus();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.subscription.unsubscribe();
    }

    loadBGPStatus() {
        let { site, ribin, ribout, peerFilterStr } = this.state;
        this.subscription = ajax(getBGPStatusObservable(site, ribout, ribin)).subscribe((xhr) => {
            let _gridData = this.groupGridData(xhr.response);
            let peerData = _gridData['peerData'] || [];
            let ribInData = _gridData['ribInData'] || [];
            let ribOutData = _gridData['ribOutData'] || [];
            if (!peerFilterStr || peerFilterStr.trim().length === 0) {
                return this.setState({
                    peerData,
                    ribInData,
                    ribOutData
                })
            }
            peerData = peerData.filter(d => d.columnData && d.columnData.find(c => c.name.indexOf(peerFilterStr) >= 0));
            peerData.forEach((p) => {
                p.columnData = p.columnData.filter(c => c.name.indexOf(peerFilterStr) >= 0);
            });
            this.setState({
                peerData,
                ribInData,
                ribOutData
            })
        });
    }

    handleRefreshChange(value) {
        this.setState({
            refreshInterval: value
        }, () => {
            if (this.state.refreshInterval !== '0' && !isNaN(this.state.refreshInterval)) {
                clearInterval(this.intervalId);
                this.intervalId = setInterval(this.loadBGPStatus.bind(this), 1000 * Number(this.state.refreshInterval));
            }
        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getPeerData(response) {
        let peerString = jsonPath(response, 'msg.result.result.peer_status.result')[0];
        let siteName = jsonPath(response, 'msg.result.result.site_name')[0];
        let peerDocument = xmlStringToDocument(peerString);
        let rawData = xmlToJson(peerDocument)["entry"];
        let data = [];
        let status = {
            name: 'Status',
            columnData: [
                {
                    name: 'Name',
                    value: siteName
                },
                {
                    name: 'Group',
                    value: rawData['peer-group']
                },
                {
                    name: 'Local IP',
                    value: rawData['local-address']
                },
                {
                    name: 'Peer IP',
                    value: rawData['peer-address']
                },
                {
                    name: 'Peer AS',
                    value: rawData['remote-as']
                },
                {
                    name: 'Password Set',
                    value: rawData['password-set']
                },
                {
                    name: 'Status',
                    value: rawData['status']
                },
                {
                    name: 'Status Duration (secs.)',
                    value: rawData['status-duration']
                }
            ]
        }

        if (rawData['last-error'] && Pan.isString(rawData['last-error'])) {
            status.columnData.push({
                name: 'Last Error',
                value: rawData['last-error']
            });
        }
        data.push(status);

        if (rawData['prefix-counter'] && rawData['prefix-counter']['entry']) {
            let counter = {};
            let counterEntry = rawData['prefix-counter']['entry'];
            let prefix = '';
            if (counterEntry['@attributes']) {
                prefix = counterEntry['@attributes']['afi-safi'] + ' ' + _T('Counters');
            }
            counter = {
                name: prefix,
                columnData: [
                    {
                        name: 'Incoming Total',
                        value: counterEntry['incoming-total']
                    },
                    {
                        name: 'Incoming Accepted',
                        value: counterEntry['incoming-accepted']
                    },
                    {
                        name: 'Incoming Rejected',
                        value: counterEntry['incoming-rejected']
                    },
                    {
                        name: 'Outgoing total',
                        value: counterEntry['outgoing-total']
                    },
                    {
                        name: 'Outgoing Advertised',
                        value: counterEntry['outgoing-advertised']
                    }
                ]
            }
            data.push(counter);
        }

        if (rawData['peer-capability'] && rawData['peer-capability']['list']) {
            let capability = {
                name: 'Capability',
                columnData: []
            }
            rawData['peer-capability']['list'].forEach((entry) => {
                capability.columnData.push({
                    name: entry['capability'],
                    value: entry['value']
                });
            });
            data.push(capability);
        }

        let configuration = {
            name: 'Configuration',
            columnData: [
                {
                    name: 'Passive',
                    value: rawData['passive']
                },
                {
                    name: 'Multi-Hop TTL',
                    value: rawData['multi-hop-ttl']
                },
                {
                    name: 'Reflector Client',
                    value: rawData['reflector-client']
                },
                {
                    name: 'Same Confederation',
                    value: rawData['same-confederation']
                },
                {
                    name: 'Aggregate Confed. AS',
                    value: rawData['aggregate-confed-as']
                },
                {
                    name: 'Peering Type',
                    value: rawData['peering-type']
                },
                {
                    name: 'Connect Retry Interval',
                    value: rawData['connect-retry-interval']
                },
                {
                    name: 'Open Delay',
                    value: rawData['open-delay']
                },
                {
                    name: 'Idle Hold',
                    value: rawData['idle-hold']
                },
                {
                    name: 'Prefix Limit',
                    value: rawData['prefix-limit']
                },
                {
                    name: 'Hold Time Config',
                    value: rawData['holdtime-config']
                },
                {
                    name: 'Keep Alive Config',
                    value: rawData['keepalive-config']
                },
                {
                    name: 'Next Hop Self',
                    value: rawData['nexthop-self']
                },
                {
                    name: 'Next Hop Peer',
                    value: rawData['nexthop-peer']
                },
                {
                    name: 'Next Hop Thirdparty',
                    value: rawData['nexthop-thirdparty']
                },
                {
                    name: 'Remove Private AS',
                    value: rawData['config']['remove-private-as']
                },
                {
                    name: 'Peer Router ID',
                    value: rawData['peer-router-id']
                },
                {
                    name: 'Hold Time',
                    value: rawData['holdtime']
                },
                {
                    name: 'Keep Alive',
                    value: rawData['keepalive']
                },
                //Last Error  last-error
                {
                    name: 'Status Flap Counts',
                    value: rawData['status-flap-counts']
                },
                {
                    name: 'Established Counts',
                    value: rawData['established-counts']
                },

                {
                    name: 'Msg. Update In',
                    value: rawData['msg-update-in']
                },
                {
                    name: 'Msg. Update Out',
                    value: rawData['msg-update-out']
                },
                {
                    name: 'Msg. Total In',
                    value: rawData['msg-total-in']
                },
                {
                    name: 'Msg. Total Out',
                    value: rawData['msg-total-out']
                },
                {
                    name: 'Last Update Age',
                    value: rawData['last-update-age']
                }
            ]
        }
        data.push(configuration);
        return data;
    }

    getRibInData(response) {
        if (!jsonPath(response, 'msg.result.result.peer_ribs.in')) {
            return [];
        }
        let ribInString = jsonPath(response, 'msg.result.result.peer_ribs.in')[0];
        let ribInDocument = xmlStringToDocument(ribInString);
        let rawData = xmlToJson(ribInDocument)['response']['result']["entry"];
        let members = [].concat(getPathValue(rawData, 'loc-rib.member'));
        return members;
    }

    getRibOutData(response) {
        if (!jsonPath(response, 'msg.result.result.peer_ribs.out')) {
            return [];
        }
        let ribOutString = jsonPath(response, 'msg.result.result.peer_ribs.out')[0];
        let ribOutDocument = xmlStringToDocument(ribOutString);
        let rawData = xmlToJson(ribOutDocument)['response']['result']["entry"];
        let members = [].concat(getPathValue(rawData, 'rib-out.member'));
        return members;
    }

    groupGridData(response) {
        let _gridData = {};
        if (!jsonPath(response, 'msg.result.result.peer_status.result') || !jsonPath(response, 'msg.result.result.site_name')) {
            return [];
        }
        _gridData['peerData'] = this.getPeerData(response);
        _gridData['ribInData'] = this.getRibInData(response);
        _gridData['ribOutData'] = this.getRibOutData(response);
        return _gridData;
    }

    toggleCollapse(item) {
        let collapseList = [...this.state.collapseList];
        let idx = collapseList.indexOf(item.name);
        if (idx >= 0) {
            collapseList.splice(idx, 1);
        } else {
            collapseList.push(item.name);
        }
        this.setState({
            collapseList
        })
    }

    renderPeer() {
        return (
            <React.Fragment><FilterWidget id="ribInPeer" objectName="" onFilter={this.onClientFilter} />
                <br />
                {
                    this.state.peerData.map((item) => {
                        return (<div className="bgp-status-peerblock" key={item.name}>
                            <div className="title" onClick={() => { this.toggleCollapse(item); }}>
                                <span className={classnames('arrow', { active: this.state.collapseList.indexOf(item.name) >= 0 })}>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span >{item.name}</span>
                            </div>
                            <Collapse isOpen={this.state.collapseList.indexOf(item.name) < 0}>
                                <GridWidget
                                    columns={PEER_COLUMNS}
                                    gridData={item.columnData || []}
                                    checkboxSelection={false}
                                    showPaging={false}
                                    showGridToolBar={false}
                                />
                            </Collapse>
                        </div>);
                    })
                }
            </React.Fragment>
        );
    }

    onFilter(obj) {
        let { ribin, ribout } = obj;
        this.setState({
            ribin,
            ribout
        }, () => {
            this.loadBGPStatus();
        })
    }

    onClientFilter(val) {
        this.setState({
            peerFilterStr: val
        }, () => {
            this.loadBGPStatus();
        })
    }

    renderRIBIn() {
        return (
            <React.Fragment>
                <FilterWidget id="ribInFilter" objectName="" onFilter={(val) => { this.onFilter({ ribin: val }) }} />
                <br />
                <GridWidget
                    columns={RIB_IN_COLUMNS}
                    gridData={this.state.ribInData || []}
                    checkboxSelection={false}
                    showPaging={false}
                    showGridToolBar={false}
                    minRows={10}
                />
            </React.Fragment>
        );
    }

    renderRIBOut() {
        return (
            <React.Fragment>
                <FilterWidget id="ribOutFilter" objectName="" onFilter={(val) => { this.onFilter({ ribout: val }) }} />
                <br />
                <GridWidget
                    columns={RIB_OUT_COLUMNS}
                    gridData={this.state.ribOutData || []}
                    checkboxSelection={false}
                    showPaging={false}
                    showGridToolBar={false}
                    minRows={10}
                />
            </React.Fragment>
        );
    }

    render() {
        const _btnStyle = {
            margin: '0.5em'
        }
        return (
            <Container>
                <Row>
                    <Col md="6">
                        <h3>BGP Status</h3>
                    </Col>
                    <Col md="6">
                        <div className="bgp-status-interval">
                            <div className="select">
                                <SelectWidget
                                    options={this.state.refreshOptions}
                                    value={this.state.refreshInterval}
                                    isClearable={false}
                                    onChange={this.handleRefreshChange}
                                />
                            </div>
                            <span>Refresh BGP Status</span>
                        </div>
                        <div className="bgp-status-interval">
                            <Button size={'md'} color="secondary" onClick={this.loadBGPStatus} style={_btnStyle}>Refresh</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    Peer
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    RIB In
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}
                                >
                                    RIB Out
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        {this.renderPeer()}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        {this.renderRIBIn()}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        {this.renderRIBOut()}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Container>
        )
    }
}
