import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { getBuilder } from "../../../utils/BuilderManager";
import { FormContext, FormConsumer } from '../../FormContext';
import { getField } from "../../schema/utils";
import { getPathValues } from "../../../utils/json";
import './ContainerBuilder.scss'
import { BaseBuilder } from '../BaseBuilder';


export class ContainerBuilder extends BaseBuilder {
    constructor(props) {
        super(props);
        this.state = {
            showEditor: false
        }
    }

    layoutChildren(layout) {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors
        } = this.props;
        const { fields } = this.context;
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;
        return layout.map((row, index) => {
            return (
                <Row key={index} className="d-flex flex-row">
                    {
                        Object.keys(row).map((name) => {
                            const Builder = getBuilder({name, fields});
                            const thisField = getField(fields, name);
                            return (
                                <Col key={name} className={row[name]} test_id={thisField.attrPath}>
                                    <Builder
                                        name={name}
                                        field={thisField}
                                        onChange={onChange}
                                        addError={addError}
                                        removeError={removeError}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        filters={filters}
                                        cellInfo={cellInfo}
                                        containerLabelWidthSize={containerLabelWidthSize}
                                        isEditorGridColumnEditor={isEditorGridColumnEditor}
                                        disabled={disabled}
                                        showErrors={showErrors}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            )
        });
    }

    showEditor() {
        this.setState({
            showEditor: true
        });
    }

    hideEditor() {
        this.setState({
            showEditor: false
        });
    }

    renderChildren() {
        const {
            field,
            onChange,
            addError,
            removeError,
            onBlur,
            onFocus,
            filters,
            cellInfo,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            from,
        } = this.props;
        const { fields } = this.context;
        const childrenNames = field.childrenNames;
        if (!childrenNames) {
            return (
                <div />
            );
        }

        const layout = field.uiHint.layout;
        if (layout) {
            return this.layoutChildren(layout);
        }
        let containerLabelWidthSize = getPathValues(field, 'uiHint.labelWidthSize') || this.props.containerLabelWidthSize;

        return childrenNames.map(name => {
            const Builder = getBuilder({name, fields});
            const thisField = getField(fields, name);

            return (
                <Builder
                    key={name}
                    name={name}
                    field={thisField}
                    onChange={onChange}
                    addError={addError}
                    removeError={removeError}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    filters={filters}
                    cellInfo={cellInfo}
                    containerLabelWidthSize={containerLabelWidthSize}
                    isEditorGridColumnEditor={isEditorGridColumnEditor}
                    disabled={disabled}
                    showErrors={showErrors}
                    from={from}
                />
            );
        });
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderChildren()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

ContainerBuilder.contextType = FormContext;

ContainerBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    ContainerBuilder.propTypes = {
        containerLabelWidthSize: PropTypes.string,
        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        filters: PropTypes.array,
        cellInfo: PropTypes.object,
        isEditorGridColumnEditor: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        field: PropTypes.shape({
            childrenNames: PropTypes.array,
            defaultValue: PropTypes.string,
            uiHint: PropTypes.shape({
                labelWidthSize: PropTypes.string,
                layout: PropTypes.array,
                vtype: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            })

        })
    };
}