import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TreeSelectWidget as TreeSelect } from 'ui-lib'
import { getPopupContainer } from './util'
import { LogSchema } from './util'

export default class LogTypeSelector extends Component {
	state = {
		logTypes: [],
		schema: undefined
	}

	static getDerivedStateFromProps(props, state) {
		if (!state.schema || !state.schema.is(props.schema)) {
			const schema = LogSchema.from(props.schema)
			const logTypes = schema.getTreeData()
			return { schema, logTypes }
		}
		return null
	}

	focus() {
		if (this.treeSelectRef) {
			this.treeSelectRef.focus()
		}
	}
	blur() {
		if (this.treeSelectRef) {
			this.treeSelectRef.blur()
		}
	}

	render() {
		const { className, value, onChange, ...props } = this.props
		const { logTypes } = this.state
		return (
			<TreeSelect
				ref={(comp) => (this.treeSelectRef = comp && comp.antTreeSelect)}
				className={classNames(className, 'log-query-logtype-select')}
				// defaultValue={logType}
				value={value}
				dropdownStyle={{
					maxHeight: 700,
					overflow: 'auto'
				}}
				treeData={logTypes}
				treeNodeLabelProp="displayName"
				placeholder="Log Type"
				showSearch
				treeDefaultExpandAll
				onChange={onChange}
				getPopupContainer={getPopupContainer}
				{...props}
			/>
		)
	}
}

LogTypeSelector.propTypes = {
	className: PropTypes.string,
	schema: PropTypes.object,
	value: PropTypes.string,
	onChange: PropTypes.func
}
