import React from 'react';
import {
    getPathValue,
    HLViewer,
    getRBAPermission,
    connect,
    ErrorBoundary,
    Pan
} from 'ui-lib';
import {
    showForm,
    hideForm,
    SHOW_FORM,
    FORM_LOADING,
    GRID_LOADING,
    fetchConfigItems,
    addConfigItem,
    editConfigItem,
    deleteConfigItem,
    cloneConfigItem,
    persistFilterTextInRedux,
} from '../../services/actions';
import _ from 'lodash';
import { ExtraInfoProvider, ExtraInfoContext } from 'ui-lib/src/core/autorender/ExtraInfoContext';
export class HLViewerWithActions extends React.Component {
    constructor(props) {
        super(props);
        //if configLocation is coming from outside use it../main/actions
        //configLocation format: {../main/actions
        this.state = {
            configLocation: props.configLocation && props.configLocation[props.locationPath] ? props.configLocation : null,
            rbaPermission: getRBAPermission(props.rbaPath || ''),
            serviceMap: props.serviceMap,
            reduxStateId: {
                reduxId: props.reduxId,
                locationPath: props.locationPath
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let newMainData = this.props.main ? this.props.main[this.props.reduxId] : false;
        let oldData = prevProps.main ? prevProps.main[this.props.reduxId] : false;
        if (newMainData && oldData && newMainData[SHOW_FORM] === false && oldData[SHOW_FORM] === true && this.props.afterSubmit) {
            //redux change in store data to hide the form
            //invoke afterSubmit as this case is possibel only after successful submit
            this.props.afterSubmit(this.state.submitRecord);
        }
        let locPath = this.state.reduxStateId.locationPath;
        if (getPathValue(this.props.configLocation, `${locPath}.name`) &&
            (!getPathValue(this.state.configLocation, `${locPath}.name`) ||
                (getPathValue(this.state.configLocation, `${locPath}.name`) !== getPathValue(this.props.configLocation, `${locPath}.name`)))) {
            // //set the configLocation
            this.setState({
                configLocation: this.props.configLocation && this.props.configLocation[locPath] ? { ...this.props.configLocation, [locPath]: this.props.configLocation[locPath] } : null
            });
            if (this.props.mode !== 'form') {
                this.props.dispatch(fetchConfigItems(this.state.serviceMap, this.state.reduxStateId, this.props.configLocation));
            }
        }
        if (this.props.afterUpdate) {
            this.props.afterUpdate();
        }
    }

    componentDidMount() {
        if (this.props.mode !== 'form') {
            this.props.dispatch(fetchConfigItems(this.state.serviceMap, this.state.reduxStateId, this.state.configLocation));
            if (this.props.extraInfoActions && this.props.extraInfoActions.length > 0) {
                this.props.extraInfoActions.forEach(action => {
                    this.props.dispatch(action(this.state.reduxStateId, this.props.configLocation));
                });
            }
        } else {
            //mode to show form 
            this.props.dispatch(
                showForm(this.props.reduxId, this.state.reduxStateId.locationPath)
            );
        }

        if (this.props.initActions && this.props.initActions.length > 0) {
            this.props.initActions.forEach(action => {
                this.props.dispatch(action(this.state.reduxStateId));
            });
        }
    }

    onAddRecord(record) {
        this.setState({
            submitRecord: record
        });
        this.props.dispatch(addConfigItem(record, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation));
    }

    onEditRecord(record, backup) {
        this.setState({
            submitRecord: record
        });
        this.props.dispatch(
            editConfigItem(record, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
        );
    }

    onDeleteRecord(records) {
        this.props.dispatch(
            deleteConfigItem(records, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
        );
    }

    onCloneRecord(records) {
        this.props.dispatch(
            cloneConfigItem(records, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
        );
    }

    onShowForm() {
        this.props.dispatch(
            showForm(this.props.reduxId, this.state.reduxStateId.locationPath)
        );
    }

    onHideForm() {
        this.props.dispatch(
            hideForm(this.props.reduxId, this.state.reduxStateId.locationPath)
        );
    }

    getRowClassRules= () => {
        let self = this;
        return {
            'inherited': function(params) {
                return params.data  && params.data['@loc'] && self.state.configLocation[self.props.locationPath].name !== params.data['@loc']
        }}
    }

    //persis filer on redux store
    onPersisFilter = (filterText) => {
        this.props.dispatch(persistFilterTextInRedux(this.props.reduxId, filterText));
    }

    render() {
        let responseData = this.props[this.props.locationPath][this.props.reduxId] || {};
        let mainData = this.props.main[this.props.reduxId] || {};
        const {
            filter: {
                [this.props.reduxId]: persisFilterText
            } = {}
        } = this.props.main;
        let gridData = [];
        if (this.props.resultPath === '$') {
            gridData = !_.isEmpty(responseData) ? responseData : [];
        } else {
            gridData = !_.isEmpty(responseData) ? getPathValue(responseData, this.props.resultPath) || [] : [];
        }

        return (
            <ErrorBoundary>
                <ExtraInfoProvider value={{
                    extraInfo: Pan.isDefined(responseData['extraInfo']) ? responseData['extraInfo'] : {}
                }}>
                    <HLViewer
                        {...this.props}
                        gridData={gridData}
                        onAddRecord={this.onAddRecord.bind(this)}
                        onEditRecord={this.onEditRecord.bind(this)}
                        onDeleteRecord={this.onDeleteRecord.bind(this)}
                        onCloneRecord={this.onCloneRecord.bind(this)}
                        panSchema={window.__pan_schema}
                        updateRecord={''}
                        loading={mainData[GRID_LOADING]}
                        showForm={mainData[SHOW_FORM]}
                        onShowForm={this.onShowForm.bind(this)}
                        onHideForm={this.onHideForm.bind(this)}
                        formLoading={mainData[FORM_LOADING]}
                        configLocation={this.state.configLocation}
                        locationWidget={this.props.locationWidget}
                        HLComponent={this.props.hlComponent}
                        HeaderComponent={this.props.headerComponent}
                        rbaPermission={this.state.rbaPermission}
                        gridOverride={this.props.gridOverride}
                        rowClassRules={this.getRowClassRules()}
                        agGridProps={this.props.agGridProps}
                        onPersisFilter = {this.onPersisFilter.bind(this)}
                        persisFilterText = {persisFilterText}
                    />
                </ExtraInfoProvider>
            </ErrorBoundary>
        );
    }

    componentWillUnmount() {
        this.props.dispatch(
            hideForm(this.props.reduxId, this.state.reduxStateId.locationPath)
        );
    }
}

HLViewerWithActions.contextType = ExtraInfoContext;

const getHLViewerWithActions = (serviceName, reduxId, hlComponent, headerComponent, serviceMap, resultPath = "result.entry", locationPath, extraInfoActions) => {
    
    const mapStateToProps = state => {
        return { [locationPath]: state[locationPath], 
            main: state.main , 
            serviceName,  
            reduxId,
            hlComponent, 
            headerComponent, 
            serviceMap, 
            resultPath, 
            locationPath,
            extraInfoActions
        };
    };

    return connect(mapStateToProps, null, null)(HLViewerWithActions);
};
export default getHLViewerWithActions;
