import React, { Component } from "react";
import { connect, hideModal, showModal, Pan, updateModal } from "ui-lib";
import { GridWidget, ButtonWidget, SpinnerWidget, TextWidget } from "ui-lib/src/core/autorender/widgets";
import { generateTechSupportData, getRecentTechSupportRequests, startLoading, finishLoading } from "./actions";
import "./TechSupportDetailsComponent.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { ajax } from "rxjs/ajax";
import { getRecentTechSupportRequestsObservable } from "./services";

const INPROGRESS = "InProgress";
const commonColumns = [
	{
		headerName: "Time",
		valueGetter: params => moment(params.data["startTime"]).format("YYYY-MMM-DD HH:mm:ss z")
	},
	{
		headerName: "Support Case ID",
		field: 'caseId',
		cellRendererParams: {
            renderCell: params => {
				const caseId = params.data.caseId;
				return (
					<div>
						<span>{caseId}</span>
						<CopyToClipboard text={caseId}>
							<span className="fa fa-clipboard copy-icon" title="Copy"></span>
						</CopyToClipboard>
					</div>
				);
			}
		}
	},
	{
		headerName: "Created By",
		field: "userName"
	},
	{
		headerName: "Status",
		valueGetter: params => (params.data["status"] === INPROGRESS ? "In Progress" : params.data["status"])
	}
];
const _T = str => str;
const GENERATE_TECHSUPPORT_CONFIRMATION_TEXT = _T("Add your case ID to associate this tech support file with a support case. Our support team uses this file to help you troubleshoot issues.");
const GENERATE_TECH_SUPPORT_MODAL = _T("GenerateTechSupportModal");

class TechSupportDetailsComponent extends Component {
	constructor(props) {
		super(props);
		let gridActions = [];
		gridActions.push({
			text: "Refresh",
			callback: () => {
				this.getRecentTechSupportDetails();
			}
		});
		this.state = {
			caseId: "",
			gridActions: gridActions
		};
	}
	componentDidMount() {
		this.getRecentTechSupportDetails();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.caseId !== prevState.caseId) {
			updateModal(GENERATE_TECH_SUPPORT_MODAL, { caseId: this.state.caseId });
		}
	}

	getRecentTechSupportDetails = () => {
		this.props.dispatch(startLoading());
		this.props.dispatch(getRecentTechSupportRequests());
	};

	checkTSRequestInProgress = (resolve, reject) => {
		const limit = 1;
		this.props.dispatch(startLoading());
		ajax(
			getRecentTechSupportRequestsObservable(limit)
		).subscribe(response => {
			const latestTSRequest = response && response.response ? response.response : [];
			const inProgressObj = latestTSRequest.find(item => item["status"] === INPROGRESS);
			if (Pan.isDefined(inProgressObj)) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};

	generateButtonHandler = () => {
		const generateTSComponent = props => (
			<div>
				<span>{_T(GENERATE_TECHSUPPORT_CONFIRMATION_TEXT)}</span>
				<div>
					<Row style={{ paddingTop: "10px" }}>
						<Col className={"col-2"}>
							<label className="case-id-label">{_T("CASE ID")}</label>
						</Col>
						<Col className={"col-10"}>
							<TextWidget
								id="caseId"
								value={this.state.caseId}
								maxLength="128"
								onChange={caseId => {
									this.setState({ caseId });
								}}
							/>
						</Col>
					</Row>
				</div>
			</div>
		);
		const GenerateTSModal = {
			id: GENERATE_TECH_SUPPORT_MODAL,
			title: _T("Create a Tech Support File"),
			type: "Warning",
			style: {
				width: "500px"
			},
			modalBody: {
				component: generateTSComponent
			},
			toggle: () => {
				hideModal(GENERATE_TECH_SUPPORT_MODAL);
			},
			actions: [
				{
					text: "Cancel",
					color: "secondary",
					action: () => {
						hideModal(GENERATE_TECH_SUPPORT_MODAL);
					}
				},
				{
					text: "Create",
					action: () => {
						this.generateTechSupportDetails();
						hideModal(GENERATE_TECH_SUPPORT_MODAL);
					}
				}
			]
		};
		const InProgressWarningModal = {
			id: "InProgressWarningModal",
			title: _T("Your Latest Tech Support File is Currently Generating"),
			type: "Warning",
			message: _T("Wait until it's done to create a new tech support file."),
			toggle: () => {
				hideModal("InProgressWarningModal");
			},
			actions: [
				{
					text: "Okay",
					action: () => {
						hideModal("InProgressWarningModal");
					}
				}
			]
		};

		const TSInProgressPromise = new Promise((resolve, reject) => {
			this.checkTSRequestInProgress(resolve, reject);
		});

		TSInProgressPromise.then(res => {
			this.props.dispatch(finishLoading());
			if (res === true) {
				return showModal(InProgressWarningModal);
			}
			return showModal(GenerateTSModal);
		});
	};

	generateTechSupportDetails = () => {
		this.setState({ caseId: "" });
		this.props.dispatch(startLoading());
		this.props.dispatch(generateTechSupportData(this.state.caseId));
	};

	render() {
		return (
			<div>
				{this.props.isLoading && <SpinnerWidget />}
				<div className="help-text">
					{_T("Use the")}
					<span> </span>
					<a href="https://support.paloaltonetworks.com/" target="_blank" rel="noopener noreferrer">
						{_T("Palo Alto Networks Support Portal")}
					</a>
					<span> </span>
					{_T("to open a new support case. If you have an open support case, continue here to create a tech support file that is automatically added to your case.")}
				</div>
				<div>
					<ButtonWidget className="generate-button" text="Create Tech Support File" onClick={this.generateButtonHandler}></ButtonWidget>
				</div>
				<div className="table-header">
					<h2>Tech Support Files</h2>
				</div>
				<GridWidget
					gridData={this.props.recentTechSupportRequests ? this.props.recentTechSupportRequests : []}
					columns={commonColumns}
					idColumn='ticketId'
					checkboxSelection={false}
					noDataText={"No Tech Support Files"}
					minRows={0}
					style={{ minHeight: "200px" }}
					loading={this.props.isLoading}
					pageSize={5}
					gridActions={this.state.gridActions}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	let obj = {};
	if (state.techSupport["generatedTechSupportData"]) {
		obj["generatedTechSupportData"] = state.techSupport["generatedTechSupportData"];
	}
	if (state.techSupport["recentTechSupportRequests"]) {
		obj["recentTechSupportRequests"] = state.techSupport["recentTechSupportRequests"];
	}
	if (state.techSupport["isLoading"]) {
		obj["isLoading"] = state.techSupport["isLoading"];
	}
	return obj;
};

export default connect(mapStateToProps)(TechSupportDetailsComponent);
