import React from 'react';
import PropTypes from 'prop-types';
import { FormContext, FormConsumer } from '../../FormContext';
import { BaseFieldBuilder } from '../BaseBuilder';
import Pan from '../../schema/Pan';
import { FormFeedbackWidget } from '../../widgets/FormFeedbackWidget/FormFeedbackWidget';
import { FormGroup, Label, Col } from 'reactstrap';
import { capitalizeLetter } from '../../schema/utils';
import classnames from 'classnames';
import { CheckboxWidget } from '../../widgets/CheckboxWidget';
import { getPathValues, getPathValue } from '../../../utils/json';
import './CheckboxBuilder.scss';

export class CheckboxBuilder extends BaseFieldBuilder {
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.state = {
            value: props.field.defaultValue || null,
            hideField: false,
            type: getPathValue(props.field, 'uiHint.type'),
        };
    }

    getBoxLabel(field) {
        const { uiHint } = field;
        if (uiHint) {
            let label = uiHint.boxLabel || uiHint.fieldLabel;
            if( label !== uiHint.fieldLabelAutoGen) {
                return label;
            }
            if (label) {
                return capitalizeLetter(label);
            }
        }
        return false;
    }

    getBoolValue(value, type) {
        const { field } = this.props;
        if (field && field.type === 'enum') {
            value = !Pan.isEmpty(value);
        }
        if (type === 'reverse') {
            return (
                value === false ||
                value === 'no' ||
                value === 'No' ||
                value === 'NO' ||
                value === 'false' ||
                value === 'FALSE'
            );
        }
        return (
            value === true ||
            value === 'yes' ||
            value === 'Yes' ||
            value === 'YES' ||
            value === 'true' ||
            value === 'TRUE'
        );
    }

    setRecordValue(value, dontSetState) {
        const { field } = this.props;
        let val = value === true ? (this.state.type === 'reverse' ? 'no' : 'yes') : (this.state.type === 'reverse' ? 'yes' : 'no');
        if (field && field.type === 'enum') {
            val = value === true ? field.uiHint.enum[0][0] : '';
        }
        super.setRecordValue(val, dontSetState);
    }

    isYesorNo(value) {
        value = value && value.toLowerCase();
        return value == 'yes' || value == 'no';
    }

    renderCheckbox() {
        let {
            name,
            className,
            field,
            containerLabelWidthSize,
            onBlur,
            onFocus,
            disabled,
            isEditorGridColumnEditor,
            showErrors,
        } = this.props;

        if (
            this.props.hidden ||
            this.state.hideField ||
            getPathValues(field, 'uiHint.hidden')
        ) {
            return <div />;
        }
        const type = this.state.type;
        const boxLabel = this.getBoxLabel(field);

        let value = this.getBoolValue(
            !Pan.isEmpty(this.getRecordValue()) ? this.getRecordValue() : this.state.value,
            type);
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors)
            ? null
            : errors[0] || 'Invalid field value';
        let labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
        let labelClassName = labelWidthSize
            ? 'col-' + labelWidthSize
            : containerLabelWidthSize
            ? 'col-' + containerLabelWidthSize
            : '';
        let LabelComp = (
            <Label
                className={`flex-grow-0 flex-shrink-0 col-form-label ${labelClassName}`}
            >
                {''}
            </Label>
        );
        return (
            <FormGroup className={classnames(className, 'd-flex flex-row')} test_id={field.attrPath}>
                {LabelComp}
                <Col>
                    <CheckboxWidget
                        id={this.id}
                        name={name}
                        label={boxLabel}
                        value={value}
                        onChange={this.setRecordValue}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        disabled={disabled || this.state.disableField}
                        showErrors={showErrors}
                        error={error} />
                    {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderCheckbox()}</FormConsumer>
            </React.Fragment>
        );
    }
}

CheckboxBuilder.contextType = FormContext;

CheckboxBuilder.defaultProps = {
    disabled: false,
    hidden: false,
    showErrors: true,
};

if (process.env.NODE_ENV !== 'production') {
    CheckboxBuilder.propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        /**
         * fieldLabel - adds a label to the field
         * allowBlank - if false,blank values will add error
         * boxLabel - adds a label to the field. Higher precedence than fieldLabel
         * labelWidthSize - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * type - values can be "reverse" or "". type="reverse" reverses the values set by the checkbox.
         */
        field: PropTypes.shape({
            attrPath: PropTypes.string,
            attrName: PropTypes.string,
            uiHint: PropTypes.shape({
                type: PropTypes.string,
                allowBlank: PropTypes.bool,
                boxLabel: PropTypes.string,
                fieldLabel: PropTypes.string,
                labelWidthSize: PropTypes.string,
            }).isRequired,
        }).isRequired,
        containerLabelWidthSize: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        isEditorGridColumnEditor: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        hidden: PropTypes.bool,
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                find: PropTypes.string,
                replace: PropTypes.string,
                append: PropTypes.string,
            }),
        ),
        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func,
    };
}
