import { getPathValue } from "./json";
import { getStoreState } from "./storeRegistry";
import Pan from "../autorender/schema/Pan";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

const _T = str => str;
const DIRECTORY_SYNC = _T('Directory Sync');

let MAPBOX_TOKEN;

export function isDirectorySyncServiceActivated() {
    let extensions = getPathValue(getStoreState(), 'main.tenant.extensions') || [];
    const directorySyncService = extensions.find(item => item.name === DIRECTORY_SYNC && item.active === true);
    return Pan.isDefined(directorySyncService);
}

/**
 * grid license key
 * @param {*} key 
 */
export function registerAGGridLicenseKey(key) {
    LicenseManager.setLicenseKey(key);
}

/**
 * mapbox license key
 * @param {*} token 
 */
export function registerMapboxToken(token) {
    MAPBOX_TOKEN = token;
}

export function getMapboxToken() {
    return MAPBOX_TOKEN;
}