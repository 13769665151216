import Pan from "../schema/Pan";
import { getPathValue, setPathValue, deletePath } from "../../utils/json";
import { getStoreState } from "../../utils/storeRegistry";
import { getLeafNodesMap, capitalizeLetter } from "../schema/utils";

function findSelectionFromOptions(value, options) {
    if (options && value) {
        for (let i = 0; i < options.length; i++) {
            let item = options[i];
            if (item.options) {
                for (let j = 0; j < item.options.length; j++) {
                    if (item.options[j].value === value) {
                        return item.options[j];
                    }
                }
            } else if (item.value === value) {
                return item;
            }
        }
    }
}

export function convertToSelectionValue(valueObj, options) {
    let dValue = [];
    if (valueObj && Array.isArray(valueObj) && valueObj.length > 0) {
        for (let index = 0; index < valueObj.length; index++) {
            const v = valueObj[index];
            const value = Pan.isObject(v) ? v.value : v;
            const key = Pan.isObject(v) ? v[INJECTED_REC_ID] : generateGridRecId();
            const selection = findSelectionFromOptions(value, options);
            let label = selection && selection.label ? selection.label : value;
            dValue.push({
                value: value,
                label: label,
                [INJECTED_REC_ID]: key
            });
        }
        return dValue;
    } else if (typeof valueObj === 'string' && !Pan.isEmpty(valueObj)) {
        let label = valueObj;
        if (options && options.length > 0) {
            const selection = findSelectionFromOptions(valueObj, options);
            if (!selection) {
                return null;
            }
            label = selection && selection.label ? selection.label : label;
        }

        return {
            value: valueObj,
            label: label,
            [INJECTED_REC_ID]: generateGridRecId()
        }
    } else if (Pan.isObject(valueObj)) {
        let value = valueObj.value || '';
        let selection = findSelectionFromOptions(value, options);
        let label = selection && selection.label ? selection.label : valueObj.label || value;
        return {
            value: value,
            label: label,
            [INJECTED_REC_ID]: valueObj[INJECTED_REC_ID] || generateGridRecId()
        };
    }
    return null;
}

export function convertArraySelectionValue(values) {
    let dValue = [];
    if (values && Array.isArray(values) && values.length > 0) {
        for (let index = 0; index < values.length; index++) {
            const valueObj = values[index];
            if (Pan.isObject(valueObj)) {
                dValue.push(Pan.apply(valueObj, {
                    label: valueObj.label || valueObj.value
                }));
            } else if (Pan.isString(valueObj)) {
                dValue.push({
                    value: valueObj,
                    label: valueObj,
                    [INJECTED_REC_ID]: generateGridRecId()
                });
            }
        }
    }
    return dValue;
}

export function groupOptions(optionItems, groupOrder, duplicateOptions , selectedValue) {
    let optionsMap = { '_empty': [] }, groupOptions = [];
    let duplicates = duplicateOptions || [];
    let expandedGroup = '';

    if (optionItems && optionItems.length > 0) {
        optionItems.forEach(item => {
            if (item.value === selectedValue) expandedGroup = item.type;
            let found = duplicates.find(obj => obj.value === item.value);
            if (!found) {
                if (groupOrder && item.type) {
                    if (optionsMap[item.type]) {
                        // existing type
                        optionsMap[item.type].push(item)
                    } else {
                        // new type
                        optionsMap[item.type] = [item];
                    }
                } else {
                    // no type
                    optionsMap._empty.push(item)
                }
            }
        });
    }
    if (Object.keys(optionsMap).length === 1) {
        // only empty label
        return optionsMap['_empty'];
    }

    if (groupOrder) {
        // group order defined
        for (let key of groupOrder) {
            let options = optionsMap[key];
            if (options && options.length > 0) {
                groupOptions.push({ label: capitalizeLetter(key), options: options, expanded: expandedGroup === key ? true : false })
                delete optionsMap[key];
            }
        }
    }

    for (let optionKey in optionsMap) {
        let options = optionsMap[optionKey];
        if (optionKey !== '_empty') {
            groupOptions.push({ label: capitalizeLetter(optionKey), options: options ,expanded: expandedGroup === optionKey ? true : false });
        } else if (options.length > 0) {
            groupOptions.push({ label: '', options: options });
        }
    }

    return groupOptions;
}

export function processSelectionValueObject(valueObj, multiple) {
    if (!valueObj || valueObj.length === 0) {
        return undefined;
    } else if (multiple && valueObj.length > 0 && Array.isArray(valueObj)) {
        return valueObj.map((obj) => {
            return obj.value;
        });
    } else if (valueObj.value || valueObj.value === "") {
        return valueObj.value;
    } else if (Array.isArray(valueObj) && valueObj.length > 0) {
        return valueObj[0].value;
    } else {
        return valueObj;
    }
}

export function updateFilterCurrentIndex(filters, key) {
    if (!Pan.isEmpty(filters)) {
        return filters.map((filter) => {
            let newFilter = {};
            if (filter && filter.find) {
                newFilter.find = filter.find;
                newFilter.replace = filter.replace;
                if (newFilter.replace.indexOf(CURRENT_ITEM_KEY) > 0 && !Pan.isEmpty(key)) {
                    newFilter.replace = newFilter.replace.replace(CURRENT_ITEM_KEY, key);
                }
            } else if (filter && filter.append && filter.append.indexOf(CURRENT_ITEM_KEY) >= 0 && !Pan.isEmpty(key)) {
                newFilter.append = filter.append;
                newFilter.append = newFilter.append.replace(CURRENT_ITEM_KEY, key);
            }
            return newFilter;
        });
    }
}

export const INJECTED_REC_ID = "__KEY__";
export const CURRENT_ITEM_KEY = "__CURRENT_ITEM_KEY__";

export function getGridRecordFilterReplace(rec, recordConfigPath) {
    let prefix = recordConfigPath.endsWith('.entry') ? 'entry' : recordConfigPath.endsWith('.member') ? 'member' : null;
    if (rec && prefix) {
        return `${prefix}[?(@.${INJECTED_REC_ID}==\'${rec[INJECTED_REC_ID]}\')]`;
    }
}

export function getGridRecordFilterCurrentItemReplace(recordConfigPath) {
    let prefix = recordConfigPath.endsWith('.entry') ? 'entry' : recordConfigPath.endsWith('.member') ? 'member' : null;
    return `${prefix}[?(@.${INJECTED_REC_ID}==\'${CURRENT_ITEM_KEY}\')]`;
}

export function getGridRecordFilterFinder(recordConfigPath) {
    return recordConfigPath.endsWith('.entry') ? 'entry.*' : recordConfigPath.endsWith('.member') ? 'member.*' : null;
}

export function formDataPreProcess(formData, fields, rootField) {
    if (fields && rootField) {
        //if a field has dataMap, call that to change any data
        let leafNodesMap = getLeafNodesMap(rootField, fields, true);
        let fieldsMap = {};
        fields.forEach(item => {
            fieldsMap[item.attrPath || item.name] = item;
        });
        for (let fieldName of rootField) {
            let fieldLeafNodes = leafNodesMap[fieldName];
            for (let child of fieldLeafNodes) {
                let childField = fieldsMap[child];
                if (childField && childField.dataMap) {
                    const fieldValue = getPathValue(
                        formData,
                        childField.attrPath,
                    );
                    childField.dataMap(formData, fieldValue);
                }
            }
        }
    }
    //insert __KEY__ in all the grid items(entry, member)
    return insertKeyInArray(formData);
}

export function formDataSetDefaults(formData, fields, subFieldsList, filters = []) {
    //set default values for those fields required and having default value or enum
    let fltrs = [...filters, { find: 'member.*', replace: 'member.0' }];
    for (let field of fields) {
        if (
            (!subFieldsList || subFieldsList.indexOf(field.attrPath) >= 0) &&
            (field.uiHint.allowBlank === false || field.uiHint.fieldRequired) &&
            (!field.attrPath.endsWith('.member') || field.uiHint.fieldRequired) &&
            (field.defaultValue || field.uiHint.fieldRequiredValue || field.type == 'enum' || (field.choiceAttr) || field.type === 'sequence')
        ) {
            let defValue = field.defaultValue || field.uiHint.fieldRequiredValue;
            if (!defValue) {
                if (field.uiHint.enum) {
                    //for enum we pick first element as default value
                    defValue = field.uiHint.enum[0][0];
                } else if (field.type === 'sequence') {
                    //for sequence leaf node, there wont be any default value
                    defValue = {}
                }
            }
            if (!Pan.isEmpty(formData)) {
                if (getPathValue(formData, field.attrPath, fltrs)) {
                    continue;
                }
            }
            setPathValue(formData, field.attrPath, defValue, fltrs);
        }
    }
    return formData;
}

function insertKeyInArray(formData) {
    if (Pan.isArray(formData)) {
        let newFormData = [];
        for (let item of formData) {
            if (Pan.isObject(item)) {
                //entry array record
                item = insertKeyInArray(item);
                newFormData.push(Pan.apply({ [INJECTED_REC_ID]: generateGridRecId() }, item));
            } else {
                //member array record
                newFormData.push(Pan.apply({ value: item }, { [INJECTED_REC_ID]: generateGridRecId() }));
            }
        }
        return newFormData;
    } else if (Pan.isObject(formData)) {
        for (let i in formData) {
            formData[i] = insertKeyInArray(formData[i]);
        }
        return formData;
    }
    return formData
}

export function formDataPostProcess(formData, fields, rootField) {
    if (fields && rootField) {
        //if a field has saveMap, call that to change any data
        let leafNodesMap = getLeafNodesMap(rootField, fields, true);
        let fieldsMap = {};
        fields.forEach(item => {
            fieldsMap[item.attrPath || item.name] = item;
        });
        for (let fieldName of rootField) {
            let fieldLeafNodes = leafNodesMap[fieldName];
            for (let child of fieldLeafNodes) {
                let childField = fieldsMap[child];
                if (childField && childField.saveMap) {
                    const fieldValue = getPathValue(
                        formData,
                        childField.attrPath,
                    );
                    childField.saveMap(formData, fieldValue);
                }
            }
        }
    }
    //remove __KEY__ from all the grid items(entry, member)
    return deleteKeyInArray(formData);
}

export function isAllFieldsValid(errors) {
    return Object.entries(errors).length === 0 ? false : true;
}

export function deleteKeyInArray(formData, isMemeberEntry) {
    if (Pan.isArray(formData)) {
        let newFormData = [];
        for (let item of formData) {
            if (Pan.isObject(item)) {
                delete item[INJECTED_REC_ID];
                for (let m in item) {
                    let isMember = m === 'member' ? true : false;
                    item[m] = deleteKeyInArray(item[m], isMember);
                }
                if (isMemeberEntry) {
                    newFormData.push(item.value);
                } else {
                    newFormData.push(item);
                }
            } else {
                newFormData.push(item);
            }
        }
        return newFormData;
    } else if (Pan.isObject(formData)) {
        for (let i in formData) {
            let isMemeber = i === 'member' ? true : false;
            formData[i] = deleteKeyInArray(formData[i], isMemeber);
        }
        return formData;
    }
    return formData
}

const CHAR_SET = '0123456789abcdefghijklmnopqrstuvwxyz';

const ID_LENGTH = 8;

export function generateGridRecId() {
    let id = '';
    for (let i = 0; i < ID_LENGTH; i++) {
        id += CHAR_SET.charAt(Math.floor(Math.random() * CHAR_SET.length));
    }
    return id;
}

export function parseAutoCompletionToOptions(resp, doSort = true, sortByKey = 'label') {
    let result = [];

    if (resp) {
        let completions = getPathValue(resp, 'response.result.completions.completion');
        if (completions && Array.isArray(completions) && completions.length > 0) {
            if (completions.length === 1 && completions[0]['@value'].length === 0) {
                return result;
            }

            result = completions.map((cpl) => {
                let value = cpl['@value'];
                let type = cpl['@type'] || 'custom';
                let label = cpl['@value'];
                if (cpl['@help-string']) {
                    label += ` (${cpl['@help-string']})`;
                }

                return {
                    value,
                    label,
                    type
                }
            });
        } else if (completions && typeof completions === 'object') {
            for (let indx in completions) {
                let label = completions['@value'];
                if (completions['@help-string']) {
                    label += ` (${completions['@help-string']})`;
                }

                result.push({
                    value: completions[indx]['@value'],
                    label: label,
                    type: completions[indx]['@type']
                });
            }
        }
    }

    if (doSort) {
        result.sort(function (a, b) {
            var x = a[sortByKey];
            var y = b[sortByKey];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    return result;
}

export function getRBAPermission(rbaPath) {
    return getPathValue(getStoreState(), 'main.loggedInUser.rba.' + rbaPath) || 'disable';
}

export function isPermissionEnabled(permission) {
    return permission === 'enable';
}

export function isPermissionDisabled(permission) {
    return permission === 'disable';
}

export function isPermissionReadOnly(permission) {
    return permission === 'read-only';
}

export function getLocationPermission(objLocation, configLocation) {
    //check if the object location same as global location
    // let globalLocation = getPathValue(getStoreState(), 'main.configLocation');
    let locationName = configLocation ? (configLocation.dg ? configLocation.dg.name : configLocation.tpl ? configLocation.tpl.name : null) : null;
    return locationName === objLocation;
}

export function sortByKey(array, key) {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

export function deleteRecursivelyEmptyParents(formData, fieldAttrPath, filters, key) {
    //check if parent is empty. Then delete it
    let parentAttrPath = fieldAttrPath.slice(0, fieldAttrPath.lastIndexOf('.'));
    let parentValue = getPathValue(formData, parentAttrPath, filters, key);
    if (parentAttrPath !== '$' && parentValue !== false && Pan.isEmpty(parentValue)) {
        deletePath(formData, parentAttrPath, filters, key);
        deleteRecursivelyEmptyParents(formData, parentAttrPath, filters, key);
    } else {
        return;
    }
}

export function getSelectedAndIndices(selected, gridData) {
    let selectedKeys = Object.keys(selected);
    gridData = gridData || [];
    let selectedIndexes = selectedKeys.map(key => {
        return _.findIndex(gridData, {'@name': key});
    });
    selectedIndexes.sort();
    let selectedValues = [];
    selectedIndexes.forEach(idx => {
        selectedValues.push(gridData[idx]);
    });
    return ({selectedValues: selectedValues, 
             min: Math.min(...selectedIndexes), 
             max: Math.max(...selectedIndexes)});
}

export const isUserRolePemission = ( ) =>{
    const roles = getPathValue(getStoreState(), 'main.loggedInUser.roles');
    return roles && Pan.isArray(roles) && roles.indexOf('superuser')>=0;
}
