import * as actionTypes from './actionTypes'
import util from '../../utils/Util'
import Constants from '../../utils/Constants'
import { TimeRangeSelectComponent } from 'ui-lib'

const getRange = TimeRangeSelectComponent.getRange

const ACTION_HANDLERS = {
	/*----- User Context Reducers -----*/
	[actionTypes.FETCH_USRCTX_REQUEST]: (state) => {
		return {
			...state,
			userCtxLoading: true,
			userCtxError: false
		}
	},
	[actionTypes.FETCH_USRCTX_FAILURE]: (state) => {
		return {
			...state,
			userCtxLoading: false,
			userCtxError: true,
			userContext: null
		}
	},
	[actionTypes.FETCH_USRCTX_SUCCESS]: (state, { userContext }) => {
		return {
			...state,
			userCtxLoading: false,
			userCtxError: false,
			userContext
		}
	},
	/*----- Schema Reducers -----*/
	[actionTypes.FETCH_SCHEMA_REQUEST]: (state) => {
		return {
			...state,
			schema: null,
			schemaForQuery: null,
			schemaLoading: true,
			schemaError: null
		}
	},
	[actionTypes.FETCH_SCHEMA_SUCCESS]: (state, { schema }) => {
		return {
			...state,
			schema,
			schemaForQuery: util.computeLogQurySchemaFromSchema(schema),
			schemaLoading: false,
			schemaError: null
		}
	},
	[actionTypes.FETCH_SCHEMA_FAILURE]: (state, { error }) => {
		return {
			...state,
			schema: null,
			schemaForQuery: null,
			schemaLoading: false,
			schemaError: error
		}
	},
	/*----- Logs Reducers -----*/
	[actionTypes.FETCH_LOGS_REQUEST]: (state) => {
		return {
			...state,
			logs: null,
			logsLoading: true,
			logsError: null,
			logsRequestId: ''
		}
	},
	[actionTypes.SET_LOGS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			logsRequestId: jobId
		}
	},
	[actionTypes.FETCH_LOGS_SUCCESS]: (state, { logs, jobId }) => {
		return {
			...state,
			logs,
			logsLoading: false,
			logsError: null,
			logsRequestId: jobId
		}
	},
	[actionTypes.FETCH_LOGS_FAILURE]: (state, { error }) => {
		return {
			...state,
			logs: null,
			logsLoading: false,
			logsError: error,
			logsRequestId: ''
		}
	},
	/*----- LogsCount Reducers -----*/
	[actionTypes.FETCH_LOGS_COUNT_REQUEST]: (state) => {
		return {
			...state,
			pageNumber: 0,
			logsCount: null,
			logsCountLoading: true,
			logsCountError: null,
			logsCountRequestId: ''
		}
	},
	[actionTypes.SET_LOGS_COUNT_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			logsCountRequestId: jobId
		}
	},
	[actionTypes.FETCH_LOGS_COUNT_SUCCESS]: (state, { logsCount, jobId }) => {
		return {
			...state,
			logsCount,
			logsCountLoading: false,
			logsCountError: null,
			logsCountRequestId: jobId
		}
	},
	[actionTypes.FETCH_LOGS_COUNT_FAILURE]: (state, { error }) => {
		return {
			...state,
			logsCount: null,
			logsCountLoading: false,
			logsCountError: error,
			logsCountRequestId: ''
		}
	},
	/*----- Related Logs Reducers -----*/
	[actionTypes.FETCH_RELATED_LOGS_REQUEST]: (state) => {
		return {
			...state,
			relatedLogs: null,
			relatedLogsLoading: true,
			relatedLogsError: null,
			relatedLogsRequestId: ''
		}
	},
	[actionTypes.SET_RELATED_LOGS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			relatedLogsRequestId: jobId
		}
	},
	[actionTypes.FETCH_RELATED_LOGS_SUCCESS]: (state, { relatedLogs, jobId }) => {
		return {
			...state,
			relatedLogs,
			relatedLogsLoading: false,
			relatedLogsError: null,
			relatedLogsRequestId: jobId
		}
	},
	[actionTypes.FETCH_RELATED_LOGS_FAILURE]: (state, { error }) => {
		return {
			...state,
			relatedLogs: null,
			relatedLogsLoading: false,
			relatedLogsError: error,
			relatedLogsRequestId: ''
		}
	},
	/*----- Init Export Reducers -----*/
	[actionTypes.INIT_EXPORT_REQUEST]: (state) => {
		return {
			...state,
			exportJobId: null,
			exportProgress: 0,
			initExportPending: true,
			initExportError: null,
			cancelExportError: null
		}
	},
	[actionTypes.SET_EXPORT_REQUEST_ID]: (state, { exportJobId }) => {
		return { ...state, exportJobId }
	},
	[actionTypes.INIT_EXPORT_SUCCESS]: (state, { exportJobId }) => {
		return {
			...state,
			exportJobId,
			initExportPending: false,
			initExportError: null
		}
	},
	[actionTypes.INIT_EXPORT_FAILURE]: (state, { error }) => {
		return {
			...state,
			initExportPending: false,
			initExportError: error
		}
	},

	[actionTypes.REMOVE_CURRENT_EXPORT_JOBID]: (state) => {
		return {
			...state,
			exportJobId: null
		}
	},
	/*----- Cancel Export Reducers -----*/
	[actionTypes.CANCEL_EXPORT_REQUEST]: (state) => {
		return {
			...state,
			cancelExportPending: true,
			cancelExportError: null,
			exportProgress: 0
		}
	},
	[actionTypes.CANCEL_EXPORT_SUCCESS]: (state) => {
		return {
			...state,
			cancelExportPending: false,
			cancelExportError: null,
		}
	},
	[actionTypes.CANCEL_EXPORT_FAILURE]: (state, { error }) => {
		return {
			...state,
			cancelExportPending: false,
			cancelExportError: error,
		}
	},

	/*----- Export Status Reducers -----*/
	[actionTypes.FETCH_EXPORT_STATUS_REQUEST]: (state) => {
		return {
			...state,
			exportStatusError: null
		}
	},
	[actionTypes.FETCH_EXPORT_STATUS_SUCCESS]: (state, { exportStatus }) => {
		return {
			...state,
			exportStatus,
			exportStatusError: null,
			exportProgress: exportStatus.progress
		}
	},
	[actionTypes.FETCH_EXPORT_STATUS_FAILURE]: (state, { error }) => {
		return {
			...state,
			exportStatusError: error
		}
	},
	/*----- Export File Reducers -----*/
	[actionTypes.FETCH_EXPORT_FILE_REQUEST]: (state, { params }) => {
		let exportFileLoading = Object.create(state.exportFileLoading)
		exportFileLoading[params.jobId] = { loading: true, error: null }
		return {
			...state,
			exportFileLoading
		}
	},
	[actionTypes.FETCH_EXPORT_FILE_SUCCESS]: (state, { params }) => {
		let exportFileLoading = Object.create(state.exportFileLoading)
		exportFileLoading[params.jobId] = { loading: false, error: null }
		return {
			...state,
			exportFileLoading
		}
	},
	[actionTypes.FETCH_EXPORT_FILE_FAILURE]: (state, { error, params }) => {
		let exportFileLoading = Object.create(state.exportFileLoading)
		exportFileLoading[params.jobId] = { loading: false, error }
		return {
			...state,
			exportFileLoading
		}
	},
	/*----- Export History Reducers -----*/
	[actionTypes.FETCH_EXPORT_HISTORY_REQUEST]: (state) => {
		return {
			...state,
			exportHistory: null,
			exportHistoryLoading: true,
			exportHistoryError: null
		}
	},
	[actionTypes.FETCH_EXPORT_HISTORY_SUCCESS]: (state, { exportHistory }) => {
		return {
			...state,
			exportHistory,
			exportHistoryLoading: false,
			exportHistoryError: null
		}
	},
	[actionTypes.FETCH_EXPORT_HISTORY_FAILURE]: (state, { error }) => {
		return {
			...state,
			exportHistoryLoading: false,
			exportHistoryError: error
		}
	},

	/*----- PollJob Reducers -----*/
	[actionTypes.POLL_JOB_REQUEST]: (state, { jobId }) => {
		return {
			...state,
			jobId,
			jobError: null
		}
	},
	[actionTypes.POLL_JOB_FAILURE]: (state, { jobId, error }) => {
		return {
			...state,
			jobId: jobId,
			jobError: error
		}
	},
	/*----- Page Reducers -----*/
	[actionTypes.UPDATE_PAGE_REQUEST]: (state) => {
		return {
			...state,
			logs: null,
			logsLoading: true,
			logsError: null
		}
	},
	[actionTypes.UPDATE_PAGE_NUMBER]: (state, { pageNumber }) => {
		return {
			...state,
			pageNumber
		}
	},
	[actionTypes.UPDATE_PAGE_LIMIT]: (state, { pageLimit }) => {
		return {
			...state,
			pageLimit
		}
	},
	/*----- Query Related Reducers -----*/
	[actionTypes.UPDATE_QUERY]: (state, { query }) => {
		return {
			...state,
			query
		}
	},
	[actionTypes.UPDATE_LOG_TYPE]: (state, { logType }) => {
		return {
			...state,
			logType
		}
	},
	[actionTypes.UPDATE_TENANT_ID]: (state, { tenantId }) => {
		return {
			...state,
			tenantId
		}
	},
	[actionTypes.UPDATE_TIME_RANGE]: (state, { timeRange }) => {
		return {
			...state,
			timeRange
		}
	},
	[actionTypes.UPDATE_TIME_PRESET]: (state, { timePreset }) => {
		return {
			...state,
			timePreset
		}
	},
	[actionTypes.UPDATE_SORT_ORDER]: (state, { sortOrder }) => {
		return {
			...state,
			sortOrder
		}
	}
}

const initialState = {
	schema: null,
	schemaLoading: true,
	schemaError: null,
	query: '',
	logs: null,
	logsLoading: true,
	logsError: null,
	logsRequestId: '',
	logsCount: null,
	logsCountLoading: true,
	logsCountError: null,
	logsCountRequestId: '',
	relatedLogs: null,
	relatedLogsLoading: true,
	relatedLogsError: null,
	relatedLogsRequestId: '',
	userContext: null,
	userCtxError: false,
	userCtxLoading: true,
	exportJobId: null,
	exportProgress: 0,
	initExportPending: false,
	initExportError: null,
	cancelExportPending: false,
	cancelExportError: null,
	exportStatus: null,
	exportStatusError: null,
	exportFileLoading: {},
	exportHistory: null,
	exportHistoryLoading: false,
	exportHistoryError: null,
	jobId: '',
	jobError: null,
	pageLimit: Constants.DEFAULT_PAGE_LIMIT,
	pageNumber: 0,
	query: '',
	tenantId: '',
	sortOrder: 'DESC',
	logType: Constants.DEFAULT_LOGTYPE_LOGS,
	timeRange: getRange(Constants.DEFAULT_TIME_PRESET).timeval(),
	timePreset: Constants.DEFAULT_TIME_PRESET
}

export const visibilityReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
