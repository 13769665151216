import {
	REGISTER_EXTENSION,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	SHOW_SUCCESS_MESSAGE,
	HIDE_SUCCESS_MESSAGE,
	CLEAR_NEW_ERRORS,
	CHANGE_CONFIG_LOCATION,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	LOAD_COMMIT_DATA,
	DO_COMMIT_SUCCESS,
	DO_COMMIT,
	DO_COMMIT_AND_PUSH,
	DO_PUSH,
	DO_REVERT,
	SET_LICENSE_INFO,
	SET_TOKEN_INFO,
	FETCH_LICENSE_ERROR,
	FETCH_TOKEN_ERROR,
	FETCH_COMMIT_DATA,
	DO_REVERT_SUCCESS,
	DIRECTORY_DOMAINS_SUCCESS,
	FETCH_APPLICATIONS_INFO,
	SET_APPLICATIONS_INFO,
	FETCH_APPLICATIONS_ERROR
} from "./actions";
import { 
	Pan, 
	parseServerError,
  SERVER_ERROR, 
	SHOW_MODAL, 
	HIDE_MODAL, 
	UPDATE_MODAL, 
	SHOW_LOADING, 
	HIDE_LOADING,
	HtmlWidget,
	hideModal
} from "ui-lib";
import {
	REMOTE_NETWORKS_DG,
	MOBILE_USERS_DG,
	SERVICE_CONNECTION_DG,
	REMOTE_NETWORKS_TPL,
	MOBILE_USERS_TPL,
	SERVICE_CONNECTION_TPL,
	GPCS_COMMON_DG,
	GPCS_COMMON_TPL,
	START_FORM_LOADING,
	FINISH_FORM_LOADING,
	START_GRID_LOADING,
	FINISH_GRID_LOADING,
	HIDE_FORM,
	SHOW_FORM,
	PERSIST_FILTER_TEXT,
} from "service-lib";


function getServerErrorModal(error) {
	const modalId = "ServerError-" + Pan.id('modal');
	let modal = {
		id: modalId,
		open: true,
		title: error.title,
		size: "lg",
		type: "Info",
		toggle: () => {
			hideModal(modalId);
		},
		modalBody: {
			component: HtmlWidget,
			props: {
				children: error.msgBody
			}
		},
		actions: [
			{
				text: "Close",
				color: "secondary",
				action: () => {
					hideModal(modalId);
				}
			}
		]
	};
	return { modal };
}

const ACTION_HANDLERS = {
	[REGISTER_EXTENSION]: (state, { extension }) => {
		let name = extension.name;
		let extn = extension.instance;
		let serviceFunctions = extn ? extn.getServiceFunctions.apply(extn) : null;
		return {
			...state,
			extensions: {
				...state.extensions,
				[name]: {
					name: name,
					serviceFunctions: serviceFunctions
				}
			}
		};
	},
	[LOGIN_SUCCESS]: (state, { tenant }) => {
		return {
			...state,
			isLoggedIn: true,
			tenant
		};
	},
	[GET_USER_SUCCESS]: (state, { user }) => {
		return {
			...state,
			loggedInUser: user
		};
	},
	[GET_USER_FAIL]: state => {
		return {
			...state,
			loggedInUser: undefined
		};
	},
	[LOGIN_FAIL]: (state, { extension }) => {
		return {
			...state,
			isLoggedIn: false
		};
	},
	[SHOW_SUCCESS_MESSAGE]: (state, { successMessage }) => {
		return {
			...state,
			success: true,
			successMessage
		};
	},
	[HIDE_SUCCESS_MESSAGE]: state => {
		return {
			...state,
			success: false,
			successMessage: null
		};
	},
	[START_GRID_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], GRID_LOADING: true }
		};
	},
	[FINISH_GRID_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], GRID_LOADING: false }
		};
	},
	[START_FORM_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], FORM_LOADING: true }
		};
	},
	[FINISH_FORM_LOADING]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], FORM_LOADING: false }
		};
	},
	[HIDE_FORM]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], SHOW_FORM: false }
		};
	},
	[SHOW_FORM]: (state, { reduxStateId }) => {
		return {
			...state,
			[reduxStateId]: { ...state[reduxStateId], SHOW_FORM: true }
		};
	},
	[SERVER_ERROR]: (state, { errorMessage, reduxStateId, showMessage=true }) => {
		let modals = [...state.modals];

		if (errorMessage && showMessage) {
			let processedMessage = parseServerError('Error', errorMessage);
			const modal = getServerErrorModal(processedMessage);
			modals.push(modal);
		}

		const server = { ...state.server };
		server.totalErrors++;
		server.newErrors++
		server.errors = [...server.errors, errorMessage];
		return {
			...state,
			server,
			modals,
			error: errorMessage ? true : false,
			errorMessage,
			loadingCommitData: false
		};
	},
	[CLEAR_NEW_ERRORS]: state => {
		const server = { ...state.server };
		server.newErrors = 0;
		return {
			...state,
			server
		};
	},
	[CHANGE_CONFIG_LOCATION]: (state, { locationValue }) => {
		return {
			...state,
			configLocation: Pan.apply(Pan.clone(state.configLocation), locationValue)
		};
	},
	[LOAD_COMMIT_DATA]: (state, { commit }) => {
		return { ...state, commitScope: commit && commit.response ? commit.response : {}, loadingCommitData: false };
	},
	[DO_COMMIT]: state => {
		return { ...state, commitBegins: true };
	},
	[DO_COMMIT_AND_PUSH]: state => {
		return { ...state, commitBegins: true };
	},
	[DO_PUSH]: state => {
		return { ...state, commitBegins: true };
	},
	[DO_REVERT]: state => {
		return { ...state, commitBegins: true };
	},
	[DO_COMMIT_SUCCESS]: state => {
		return { ...state, commitBegins: false };
	},
	[SET_LICENSE_INFO]: (state, { licenseInfo }) => {
		return { ...state, licenseInfo };
	},
	[SET_TOKEN_INFO]: (state, { tokenInfo }) => {
		return { ...state, tokenInfo };
	},	
	[FETCH_LICENSE_ERROR]: state => {
		return { ...state, fetchLicenseError: true };
	},
	[FETCH_TOKEN_ERROR]: state => {
		return { ...state, fetchTokenError: true };
	},	
	[FETCH_APPLICATIONS_INFO]: state => {
		return {
			...state,
			content: {
				...state.content,
				fetchingApplications: true,
			}
		};
	},
	[SET_APPLICATIONS_INFO]: (state, { applicationsInfo }) => {
		return {
			...state,
			content: {
				...state.content,
				applications: applicationsInfo,
				fetchingApplications: false
			}
		};
	},
	[FETCH_APPLICATIONS_ERROR]: state => {
		return {
			...state,
			content: {
				...state.content,
				fetchingApplications: false
			},
			fetchApplicationsError: true
		};
	},
	[FETCH_COMMIT_DATA]: (state) => {
		return { ...state, loadingCommitData: true };
	},
	[DO_REVERT_SUCCESS]: (state, { revertMsg }) => {
		return { ...state, commitBegins: false, success: true, successMessage: revertMsg };
	},
	[SHOW_MODAL]: (state, modal) => {
		let modals = [...state.modals];
		modals.push(modal);
		return { ...state, modals }
	},
	[HIDE_MODAL]: (state, payload) => {
		let { id } = payload;
		let modals = [...state.modals].filter(modal => modal.modal.id !== id);
		return { ...state, modals }
	},
	[UPDATE_MODAL]: (state, payload) => {
		let modals = [...state.modals];
		let { id, props } = payload;
		modals.forEach(function (modal) {
			if (modal.modal.id === id) {
				for (var p in props) {
					modal.modal[p] = props[p];
				}
			}
		});
		return { ...state, modals }
	},
	[SHOW_LOADING]: (state, payload) => {
		let { renderTo } = payload;
		const loading = {
			show: true,
			renderTo
		}
		return { ...state, loading }
	},
	[HIDE_LOADING]: (state) => {
		const loading = {
			show: false,
			renderTo: null
		}
		return { ...state, loading }
	},
	[DIRECTORY_DOMAINS_SUCCESS]: (state, { directoryDomains }) => {
		return { ...state, directoryDomains };
	},
	[PERSIST_FILTER_TEXT]:(state, { reduxStateId, filterText }) => {
		return {
				...state,
				filter:{
						...state.filter,
						[reduxStateId]: filterText
				}
		};
}
};

const initialState = {
	extensions: {},
	isLoggedIn: null,
	modals: [],
	tenant: null,
	commitScope: [],
	server: {
		totalErrors: 0,
		newErrors: 0,
		errors: []
	},
	allowedDG: [
		{ label: "Prisma Access Common", value: GPCS_COMMON_DG },
		{ label: "Remote Networks", value: REMOTE_NETWORKS_DG, dropdownDisplayLevel: 1, licenseKey: 'rn-active' },
		{ label: "Mobile Users", value: MOBILE_USERS_DG, dropdownDisplayLevel: 1, licenseKey: 'mu-active' },
		{ label: "Service Connections", value: SERVICE_CONNECTION_DG, dropdownDisplayLevel: 1}
	],
	allowedTPL: [
		{ label: "Common", value: "shared" },
		{ label: "Remote Networks", value: REMOTE_NETWORKS_TPL },
		{ label: "Mobile Users", value: MOBILE_USERS_TPL },
		{ label: "Service Connections", value: SERVICE_CONNECTION_TPL }
	],
	configLocation: {
		dg: {
			name: GPCS_COMMON_DG
		},
		tpl: {
			name: GPCS_COMMON_TPL
		},
		extension: {
			gpcs: {
				name: "gpcs"
			}
		},
		filter: {}
	},
	licenseInfo: {},
	content: {
		applications: [],
		vulnerabilities: [],
		spyware: []
	}
};

export default function mainReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
