import React from "react";
import './SpinnerWidget.scss';
export function SpinnerWidget(props) {
    return ( 
        <div className="modal fade show spinner-background">
            <div className="modal-dialog modal-dialog-centered text-center">
                <div className="spinner-grow text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
}
