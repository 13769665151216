import React, { Component } from 'react'
import { Button } from 'reactstrap';

class GridAction extends Component {
    render() {
        return (
            <div>
                {this.props.actionList.map(action => {
                    return (
                        <Button
                            key={action.text}
                            color={action.color}
                            size="sm"
                            onClick={(e) => action.callback(this.props.selected, this.props.gridData)}
                            disabled={action.disabled}
                        >
                            {action.text}
                        </Button>
                    );
                })}
            </div>
        )
    }
}

export default GridAction;
