import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { get } from 'lodash'
import { GridWidget } from '../GridWidget';
import { HLGridHeader } from './HLGridHeader';
import { FilterWidget } from '../FilterWidget';
import { isPermissionEnabled, getLocationPermission } from '../util';
import PropTypes from 'prop-types';
import Pan from '../../schema/Pan';
import './HLGrid.scss';
import { isClickable } from '../../../utils/CommonColumnConfig';
import { showModal, hideModal } from '../../../services/actions';


export class HLGrid extends Component {
    constructor(props) {
        super(props);
        this.onDeleteRecord = this.onDeleteRecord.bind(this);
        this.renderGrid = this.renderGrid.bind(this);
        this.isAddDisabled = this.isAddDisabled.bind(this);
        this.isDeleteDisabled = this.isDeleteDisabled.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.getGridActions = this.getGridActions.bind(this);
        this.gridOverride = props.gridOverride;
        let gridActions = this.getGridActions();
        let modifiedColumns = this.getGridColumns(props.columns);
        this.state = {
            loading: true,
            filterText: props.persisFilter || '',
            rbaPermission: props.rbaPermission,
            gridActions,
            modifiedColumns
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.persisFilterText !== prevState.filterText) {
            return {
                filterText: nextProps.persisFilterText
            }
        }
        return null;
    }

    getGridColumns = (columns) => {
        if (columns && columns.length > 0) {
            return columns.map(column => {
                if (isClickable(column) && this.props.displayRecordEditor) {
                    column.onClick = this.props.displayRecordEditor;
                }
                if (column.children) {
                    column.children = this.getGridColumns(column.children);
                }
                return column;
            });
        }
    }

    isAddDisabled(selected, records) {
        const maxCount = this.props.maxCount;
        if (maxCount && records && records.length >= maxCount) {
            return true;
        }
        return false;
    }

    isDeleteDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        if (selectedKeys.length > 0) {
            for (let item in selected) {
                if (item && selected[item]['@loc'] && !getLocationPermission(selected[item]['@loc'], this.props.configLocation)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    isGridActionDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        return !(selectedKeys.length > 0);
    }

    isGridMoveUpActionDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        let selectedIndices = selectedKeys.map((k) => _.findIndex(records, { [idColumn]: k }))
        return !(selectedKeys.length > 0 && selectedIndices.indexOf(0) < 0);
    }

    isGridMoveDownActionDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        let selectedIndices = selectedKeys.map((k) => _.findIndex(records, { [idColumn]: k }))
        return !(selectedKeys.length > 0 && selectedIndices.indexOf(records.length - 1) < 0);
    }

    isCloneDisabled(selected, records, idColumn) {
        let selectedKeys = Object.keys(selected);
        return !(selectedKeys.length > 0);
    }

    onDeleteRecord(selected, configPath) {
        if (this.props.onDeleteRecord) {
            this.props.onDeleteRecord(selected, configPath);
        }
    }

    onCloneRecord(selected, configPath) {
        if (this.props.onCloneRecord) {
            this.props.onCloneRecord(selected, configPath);
        }
    }

    onFilter(filterText) {
        if(this.props.onPersisFilter && Pan.isFunction(this.props.onPersisFilter)) {
            this.props.onPersisFilter(filterText);
        } else{
            this.setState({
                filterText: filterText
            });
        }
    }

    getGridActions() {
        let gridActions = this.props.gridActions || [];
        gridActions = [...gridActions];
        let permissionEnabled = isPermissionEnabled(this.props.rbaPermission);
        var gridActionsAvail = get(this.props, "gridOverride.gridActionsAvail");

        if (this.props.showDefaultCloneAction !== false && permissionEnabled) {
            gridActions.unshift({
                text: 'Clone',
                avail: get(gridActionsAvail, "isCloneDisabled", this.isCloneDisabled),
                callback: (selected) => {
                    this.onCloneRecord(selected, this.props.recordConfigPath)
                }
            });
        }
        if (this.props.showDefaultDeleteAction !== false && permissionEnabled) {
            gridActions.unshift({
                text: 'Delete',
                avail: get(gridActionsAvail, "isDeleteDisabled", this.isDeleteDisabled),
                callback: (selected) => {
                    const modalId = `delete-modal-${this.props.header}`;
                    const count = selected && Object.keys(selected).length;
                    let modal = {
                        id: modalId,
                        title: this.props.header,
                        type: 'Warning',
                        message: `Do you really want to delete ${ count ? count + ' "' + this.props.header + '"' + (count > 1 ? ' entries' : ' entry') : ' items'}?`,
                        toggle: () => {
                            hideModal(modalId);
                        },
                        actions: [{
                            text: 'Yes',
                            color: "secondary",
                            action: () => {
                                this.onDeleteRecord(selected, this.props.recordConfigPath)
                                hideModal(modalId);
                            }
                        }, {
                            text: 'No',
                            action: () => {
                                hideModal(modalId);
                            }
                        }]
                    };
                    showModal(modal);
                }
            });
        }
        if (this.props.showDefaultAddAction !== false && permissionEnabled) {
            gridActions.unshift({
                text: 'Add',
                color: 'primary',
                avail: get(gridActionsAvail, "isAddDisabled", this.isAddDisabled),
                callback: () => {
                    if (this.props.prepareAddRecord) {
                        this.props.prepareAddRecord(this.props.displayRecordEditor.bind(this));
                    } else {
                        this.props.displayRecordEditor();
                    }
                }
            });
        }

        return gridActions;
    }

    renderGrid() {
        // displayMode = default || simple
        let displayMode = this.props.displayMode || 'default';
        let columns = this.props.columns;
        if (displayMode == 'simple' && this.props.simpleColumns) {
            columns = this.props.simpleColumns;
        }

        let header = this.props.displayMode != 'modal' ? this.props.header || "????" : '';
        let noDataText = this.props.noDataText ? this.props.noDataText : 'This table will populate as you add ' + (header ? header : 'items');

        let showFilterWidget = <FilterWidget id="filterInput" objectName={header} onFilter={this.onFilter} value={this.state.filterText}/>;
        if (this.props.showFilter === false) {
            showFilterWidget = <></>;
        }
        return (
            <Card className="h-100">
                {
                    (displayMode == 'default' || displayMode == 'modal') && (!Pan.isEmpty(header) && !Pan.isEmpty(location)) &&
                    <CardHeader className="hlgrid-header bg-light">
                        <HLGridHeader header={header} location={this.props.locationWidget}>
                            {showFilterWidget}
                        </HLGridHeader>
                    </CardHeader>
                }
                <CardBody className="hlgrid p-0">
                    {/* it would be better to allow user to add component */}
                    {
                        (this.props.modalBodyHelperText) &&
                        <div className='card-text' style={{ padding: '10px', margin: '10px' }}>
                            {this.props.modalBodyHelperText}
                        </div>
                    }
                    <GridWidget
                        gridData={this.props.gridData}
                        columns={this.state.modifiedColumns}
                        pageSize={this.props.pageSize}
                        gridActions={this.state.gridActions}
                        noDataText={noDataText}
                        filterText={this.state.filterText}
                        checkboxSelection={this.props.checkboxSelection}
                        showFilter={false}
                        allowDrag={this.props.allowDrag}
                        allowDrop={this.props.allowDrop}
                        onMoveDrag={this.props.onMoveDrag}
                        showPaging={this.props.showPaging}
                        loading={this.props.loading}
                        idColumn={this.props.idColumn}
                        minRows={this.props.minRows}
                        scrollIndex={this.props.scrollIndex}
                        suppressColsSizeToFit={this.props.suppressColsSizeToFit}
                        style={{height:'', ...this.props.style}}
                        rowClassRules={this.props.rowClassRules}
                        agGridProps={this.props.agGridProps}
                        agContext={{ extraInfo: this.props.extraInfo || {}, fields: this.props.fields || {} }}
                    />
                </CardBody>
            </Card>
        );
    }

    render() {
        return <React.Fragment>{this.renderGrid()}</React.Fragment>;
    }
}

HLGrid.defaultProps = {};
if (process.env.NODE_ENV !== 'production') {
    HLGrid.propTypes = {
        rbaPermission: PropTypes.string,
        /**
         * isAddDisabled is a function which returns true or false which determines if 
         * Add button will be available or disabled
         */
        gridOverride: PropTypes.shape({
            gridActionsAvail: PropTypes.shape({
                isAddDisabled: PropTypes.func
            })
        }),
        /**
         * callback function when add button is clicked
         */
        displayRecordEditor: PropTypes.func,
        prepareAddRecord: PropTypes.func,
        extraInfo: PropTypes.object,
        /**
         * maximum count of records
         */
        maxCount: PropTypes.number,
        /**
         * callback function when a record is deleted
         */
        onDeleteRecord: PropTypes.func,
        /**
         * callback function when a record is cloned
         */
        onCloneRecord: PropTypes.func,
        recordConfigPath: PropTypes.string,
        gridData: PropTypes.array,
        gridActions: PropTypes.array,
        showDefaultAddAction: PropTypes.bool,
        showDefaultDeleteAction: PropTypes.bool,
        showDefaultCloneAction: PropTypes.bool,
        displayMode: PropTypes.string,
        columns: PropTypes.array,
        simpleColumns: PropTypes.array,
        header: PropTypes.string,
        showFilter: PropTypes.bool,
        locationWidget: PropTypes.object,
        pageSize: PropTypes.number,
        checkboxSelection: PropTypes.bool,
        showPaging: PropTypes.bool,
        loading: PropTypes.bool,
        idColumn: PropTypes.string
    };
}
