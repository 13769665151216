import { ajax } from "rxjs/ajax";
import { getPathValue, getCustomServiceURL, getStoreState, MOCK_SERVICES,isUserRolePemission } from "ui-lib";
import { parseError, SERVER_ERROR, getStore, getAuthHeaders, validationRulesMap } from 'ui-lib';

import './GPCSCommonFields.scss';

const _T = str => str;

// helper function to validate IDs (for IPSec Peer Auth)
export const validateID = (value, field, formData, path) => {
    let type = getPathValue(formData, path);
    if (type === 'ipaddr') {
        return validationRulesMap.isIpAddress(value);
    }
    if (type === 'ufqdn') {
        return validationRulesMap.isEmail(value);
    }
    if (type === "fqdn") {
        return validationRulesMap.isString(value);
    }
    if (type === "keyid") {
        return validationRulesMap.isHexadecimal(value);
    }
    if (type === "dn") {
        return validationRulesMap.isString(value);
    }
}

export const displayValue = (value) => {
    switch (value) {
        case 'ipaddr':
            return 'IP Address'
        case 'ufqdn':
            return 'User FQDN'
        case 'fqdn':
            return 'FQDN'
        case 'keyid':
            return 'Key ID'
        default:
            break;
    }
}

const priviewAllowedBandWidth = {
    'FWAAS-500Mbps-Preview': ['FWAAS-500Mbps-Preview', 'FWAAS-1000Mbps-Preview'],
    'FWAAS-1000Mbps-Preview': ['FWAAS-1000Mbps-Preview']
}


export const gpcsCommonFields = [
    {
        name: '$.stage1.tunnel-monitor-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Tunnel Monitoring IP')
        }
    },
    {
        name: '$.stage1.tunnel-monitor.destination-ip',
        uiHint: {
            fieldLabel: _T('IP Address')
        }
    },
    {
        name: '$.stage_1',
        recordConfigPath: '$.stage1',
        childrenNames: [
            // 'get_started',
            '$.stage1_step1',
            '$.stage1_step2',
            '$.stage1_step3',
            '$.stage1_step4',
            '$.stage1_step5'
        ],
        uiHint: {
            jumpToSummaryFromStep: 3,
            stageTitle: 'IPSec tunnel',
            stageNumber: '1',
            stageCollapseTitle: 'Primary Tunnel'
        }
    }, {
        name: 'get_started',
        uiHint: {
            fieldDescription: _T("We'll guide you through the steps you should follow to secure your branch sites using Prisma Access"),
            nextButtonText: "Get Started"
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.choice',
        defaultValue: 'default',
        uiHint: {
            allowBlank: false,
            fieldLabel: ' '
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.default',
        uiHint: {
            builder: 'FakeSchemaContainerBuilder',
            allowBlank: true,
            fakeSchemaDataSource: '$.stage1.product',
            dataMapping: 'ipsec',
            dataBaseOn: '$.stage1.ipsec-crypto-profiles.customized',
            fakeSchemaLabelMapping: {'dh-group': 'DH Group', 'ipsec-protocol': 'IPSec Protocol'},
            fieldLabel: _T('Use Recommended Settings')
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized',
        uiHint: {
            fieldLabel: _T('Customize Settings')
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.choice',
        uiHint: {
            fieldLabel: _T('IPSec Protocol')
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.esp',
        uiHint: {
            fieldLabel: _T('ESP')
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.ah',
        uiHint: {
            fieldLabel: _T('AH')
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.lifetime',
        uiHint: {
            singleLineLayout: true,
            helpstring: _T("Minimum lifetime = 3 mins")
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.lifesize',
        uiHint: {
            singleLineLayout: true,
            showHelpString: true,
            helpstring: _T("Recommended lifesize is 100MB or greater")
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.esp.encryption.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.esp.encryption.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.esp.authentication.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.esp.authentication.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.ah.authentication.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.ah.authentication.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage1.ipsec-crypto-profiles.customized.dh-group',
        uiHint: {
            displayLabel: 'DH Group',
            useValueAsDisplay: true
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.choice',
        defaultValue: 'default',
        uiHint: {
            allowBlank: false,
            fieldLabel: '  '
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.default',
        uiHint: {
            builder: 'FakeSchemaContainerBuilder',
            allowBlank: true,
            fakeSchemaDataSource: '$.stage1.product',
            fakeSchemaLabelMapping: {'dh-group': 'DH Group', 'ipsec-protocol': 'IPSec Protocol'},
            dataMapping: 'ike',
            dataBaseOn: '$.stage1.ike-crypto-profiles.customized',
            fieldLabel: _T('Use recommended setup')
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized',
        childrenNames: [
            '$.stage1.ike-crypto-profiles.customized.dh-group',
            '$.stage1.ike-crypto-profiles.customized.hash',
            '$.stage1.ike-crypto-profiles.customized.encryption',
            'stage-1-IKECustomCryptoProfileTimers'
        ],
        uiHint: {
            fieldLabel: _T('Customize setup')
        }
    }, {
        name: 'stage-1-IKECustomCryptoProfileTimers',
        childrenNames: ['$.stage1.ike-crypto-profiles.customized.lifetime', '$.stage1.ike-crypto-profiles.customized.authentication-multiple'],
        uiHint: {
            fieldLabel: _T('Timers'),
            builder: 'FieldSetBuilder'
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.lifetime',
        uiHint: {
            fieldLabel: _T('Key Lifetime'),
            showHelpString: true,
            helpstring: _T("Minimum lifetime = 3 mins"),
            singleLineLayout: true
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.authentication-multiple',
        uiHint: {
            fieldLabel: _T('IKEv2 Authentication Multiple'),
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.encryption.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.encryption.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.dh-group.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.dh-group.member.*',
        uiHint: {
            displayLabel: 'DH Group',
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.hash.member',
        uiHint: {
            builder: 'CompletionBuilder',
            fieldLabel: _T('Authentication')
        }
    }, {
        name: '$.stage1.ike-crypto-profiles.customized.hash.member.*',
        uiHint: {
            useValueAsDisplay: true,
            displayLabel: _T('Authentication'),
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage1.version',
        uiHint: {
            fieldLabel: _T('IKE protocol version')
        }
    }, {
        name: '$.stage1.authentication',
        childrenNames: ['$.stage1.authentication.pre-shared-key'],
        uiHint: {
            fieldLabel: _T('Authentication'),
            builder: 'ContainerBuilder'
        }
    }, {
        name: '$.stage1.authentication.certificate.peer-id.type',
        uiHint: {
            fieldLabel: _T('Identification Type'),
            hideLabelInSummary: true,
            association: {
                // fields: ['$.stage1.authentication.certificate.peer-id.type'],
                runValidation: function (values, formData, field, key) {
                    const idVal = getPathValue(formData, '$.stage1.authentication.certificate.peer-id.id');
                    return { '$.stage1.authentication.certificate.peer-id.id': idVal };
                }
            }
        }
    }, {
        name: '$.stage1.authentication.certificate.peer-id.id',
        uiHint: {
            fieldLabel: _T('Identifier'),
            displayLabel: 'Branch IKE ID',
            customValidation: function (value, field, formData) {
                return validateID(value, field, formData, '$.stage1.authentication.certificate.peer-id.type');
            }
        }
    }, {
        name: '$.stage1.authentication.certificate.peer-id.matching',
        uiHint: {
            fieldLabel: _T('Peer ID Check')
        }
    }, {
        name: '$.stage1.authentication.certificate.local-id.type',
        uiHint: {
            fieldLabel: _T('Identification Type'),
            association: {
                // fields: ['$.stage1.authentication.certificate.local-id.type'],
                runValidation: function (values, formData, field, key) {
                    const idVal = getPathValue(formData, '$.stage1.authentication.certificate.local-id.id');
                    return { '$.stage1.authentication.certificate.local-id.id': idVal };
                }
            }
        }
    }, {
        name: '$.stage1.authentication.certificate.local-id.id',
        uiHint: {
            fieldLabel: _T('Identifier'),
            displayLabel: 'HQ/DC IKE ID',
            customValidation: function (value, field, formData) {
                return validateID(value, field, formData, '$.stage1.authentication.certificate.local-id.type');
            }
        }
    }, {
        name: '$.stage1.region',
        uiHint: {
            builder: 'CompletionBuilder',
            fieldLabel: _T('Prisma Access Location'),
            getDisplayValue: function (formData, fieldName) {
                let val = getPathValue(formData, '$.stage1.region') || '';
                if (getStoreState().gpcs.regionInfo) {
                    let region = getStoreState().gpcs.regionInfo.find(r => r['@value'] === val);
                    if (region) {
                        val = region['@display'];
                    }
                }
                return val;
            },
            completionConfig: {
                CustomCompletion: () => {
                    let {
                        SERVICE_URL
                    } = getCustomServiceURL(
                        `api/extensions/gpcs/status/completeRegions`
                    );

                    let completinObservable = ajax({
                        withCredentials: MOCK_SERVICES ? false : true,
                        method: 'GET',
                        responseType: 'json',
                        url: SERVICE_URL,
                        headers: {
                            'Content-Type': 'application/json',
                            ...getAuthHeaders()
                        },
                    });

                    let respPromise = new Promise(function (resolve, reject) {
                        completinObservable.toPromise().then((resp) => {
                            let objs = getPathValue(resp, 'response.msg.completions');
                            if (Array.isArray(objs) && objs.length > 0) {
                                let completions = objs.map(obj => {
                                    return { value: obj['@value'], label: obj['@display'] };
                                });
                                resolve(completions);
                            }
                            resolve([]);
                        }, (err) => {
                            getStore().dispatch({
                                type: SERVER_ERROR,
                                errorMessage: parseError(err),
                                showMessage: false
                            });
                            // reject(new Error('Error in Auto Completion: ' + err ));
                        });
                    });
                    return respPromise;
                }
            }
        }
    }, {
        name: '$.stage1.bandwidth',
        uiHint: {
            getDisplayValue: function (formData) {
                let val = getPathValue(formData, '$.stage1.bandwidth') || '';
                return val.replace('FWAAS-', '');
            },
            builder: 'CompletionBuilder',
            completionConfig: {
                filterFn: (options, formData) => {
                    let inputValue = getPathValue(formData, 'stage1.bandwidth');
                    return options.filter((opt) => {
                        let allowed = priviewAllowedBandWidth[inputValue];
                        return allowed ? allowed.indexOf(opt['@value']) >= 0 : true;
                    });
                },
                CustomCompletion: (inputValue, completionXpath, configLocation, formData) => {
                    let {
                        SERVICE_URL
                    } = getCustomServiceURL('api/extensions/gpcs/bandwidth');
                    let completinObservable = ajax({
                        withCredentials: MOCK_SERVICES ? false : true,
                        method: 'GET',
                        responseType: 'json',
                        url: SERVICE_URL,
                        headers: {
                            'Content-Type': 'application/json',
                            ...getAuthHeaders()
                        },
                    });

                    let respPromise = new Promise(function (resolve, reject) {
                        completinObservable.toPromise().then((resp) => {
                            let objs = getPathValue(resp, 'response.msg.completions');
                            // if (filterFn && Array.isArray(objs)) {
                            //     objs = filterFn(objs, formData);
                            // }
                            if (Array.isArray(objs) && objs.length > 0) {
                                let completions = objs.map(obj => {
                                    return { value: obj['@value'], label: obj['@display'] };
                                });
                                resolve(completions);
                            }
                            resolve([]);
                        }, (err) => {
                            getStore().dispatch({
                                type: SERVER_ERROR,
                                errorMessage: parseError(err),
                                showMessage: false
                            });
                            // reject(new Error('Error in Auto Completion: ' + err ));
                        });
                    });
                    return respPromise;
                }
            }
        }
    }, {
        name: '$.stage1.proxy-id.entry',
        uiHint: {
            editorType: 'record-form',
            fieldLabel: 'Proxy-IDs',
            dialogSize: 'lg',
            showFilter: false,
            association: {
                fields: ['$.stage1.product'],
                availHide: function (values, formData, filters, key) {
                    return (values && !(values[0] === 'Cisco-ASA' || values[0] === 'Other Devices' || values[0] === 'Palo Alto Networks'));
                }
            },
        }
    },
    {
        name: '$.stage1.proxy-id.entry.*',
        uiHint: {
            labelWidthSize: 'sm-3'
        }
    }, {
        name: '$.stage1.proxy-id.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn'
            }
        }
    },
    {
        name: '$.stage1.proxy-id.entry.*.local',
        uiHint: {
            showHelpString: _T("IP Address or IP/netmask, only needed when peer requires it.")
        }
    },
    {
        name: '$.stage1.proxy-id.entry.*.remote',
        uiHint: {
            showHelpString: _T("IP Address or IP/netmask, only needed when peer requires it.")
        }
    },
    {
        name: '$.stage1.proxy-id.entry.*.protocol',
        uiHint: {
            columnConfig: {
                valueGetter: params => {
                    let protocol = getPathValue(params.data, "protocol");
                    let displayStr = '';
                    if (protocol['tcp']) {
                        displayStr = 'TCP';
                    } else if (protocol['udp']) {
                        displayStr = 'UDP';
                    } else if (protocol['any']) {
                        displayStr = 'Any';
                    } else if (protocol['number']) {
                        displayStr = 'Number ' + protocol['number'];
                    }
                    return displayStr;
                }
            }
        }
    },
    {
        name: '$.stage1.peer-address.choice',
        uiHint: {
            fieldLabel: _T('Branch IP Address Type')
        }
    }, {
        name: '$.stage1.peer-address.ip',
        uiHint: {
            fieldLabel: _T('Static IP Address')
        }
    },
    {
        name: '$.stage1.peer-address.dynamic',
        uiHint: {
            fieldLabel: _T('Dynamic IP Address')
        }
    },
    {
        name: '$.stage1.tunnel-monitor.proxy-id',
        uiHint: {
            association: {
                fields: ['$.stage1.product'],
                availHide: function (values, formData, filters, key) {
                    return (values && !(values[0] === 'Cisco-ASA' || values[0] === 'Other Devices' || values[0] === 'Palo Alto Networks'));
                }
            },
            placeholder: _T('Select a Proxy ID for tunnel monitoring'),
            completionConfig: {
                type: 'local',
                fieldPath: '$.stage1.proxy-id.entry.*',
                valueField: '@name'
            }
        }
    },
    {
        name: '$.stage1.proxy-id.entry.*.protocol.tcp',
        uiHint: {
            fieldLabel: 'TCP'
        }
    }, {
        name: '$.stage1.proxy-id.entry.*.protocol.udp',
        uiHint: {
            fieldLabel: 'UDP'
        }
    }, {
        name: '$.stage_2',
        recordConfigPath: '$.stage2',
        childrenNames: [
            '$.stage2_step1',
            '$.stage2_step2',
            '$.stage2_step3',
            '$.stage2_step4'
        ],
        uiHint: {
            jumpToSummaryFromStep: 2,
            stageTitle: 'IPSec secondary tunnel',
            stageNumber: '2',
            stageCollapseTitle: 'Secondary Tunnel'
        }
    },
    {
        name: '$.stage2_step1',
        childrenNames: [
            '$.stage2.version',
            '$.stage2.authentication',
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            fieldLabel: _T('IPSec Peer Authentication'),
            stepTitle: 'IPSec Peer Authentication',
            fieldDescription: _T('To set up a VPN tunnel, the VPN peers or gateways must authenticate each other. Choose either pre-shared key or certificate.')
        }
    }, {
        name: '$.stage2.version',
        uiHint: {
            fieldLabel: _T('IKE protocol version')
        }
    }, {
        name: '$.stage2.authentication.choice',
        uiHint: {
            fieldLabel: _T('Authentication'),
            hidden: true
        }
    }, {
        name: '$.stage2.authentication',
        childrenNames: ['$.stage2.authentication.pre-shared-key'],
        uiHint: {
            builder: 'ContainerBuilder'
        }
    }, {
        name: '$.stage2.authentication.certificate.peer-id.type',
        uiHint: {
            fieldLabel: _T('Identification Type'),
            hideLabelInSummary: true,
        }
    }, {
        name: '$.stage2.authentication.certificate.peer-id.id',
        uiHint: {
            fieldLabel: _T('Identifier'),
            displayLabel: 'Branch IKE ID',
            customValidation: function (value, field, formData) {
                return validateID(value, field, formData, '$.stage2.authentication.certificate.peer-id.type');
            }
        }
    }, {
        name: '$.stage2.authentication.certificate.peer-id.matching',
        uiHint: {
            fieldLabel: _T('Peer ID Check')
        }
    }, {
        name: '$.stage2.authentication.certificate.local-id',
        uiHint: {
            fieldLabel: _T('HQ/DC IKE ID')
        }
    }, {
        name: '$.stage2.authentication.certificate.local-id.type',
        uiHint: {
            hideLabelInSummary: true,
            fieldLabel: _T('Identification Type')
        }
    }, {
        name: '$.stage2.authentication.certificate.local-id.id',
        uiHint: {
            displayLabel: 'HQ/DC IKE ID',
            fieldLabel: _T('Identifier'),
            customValidation: function (value, field, formData) {
                return validateID(value, field, formData, '$.stage2.authentication.certificate.local-id.type');
            }
        }
    }, {
        name: '$.stage2_step2',
        childrenNames: [
            '$.stage2.tunnel-monitor.destination-ip',
            '$.stage2.proxy-id',
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            fieldLabel: _T('Tunnel Settings'),
            stepTitle: 'Tunnel Settings',
            buttonsAvail: {
                next: false,
                back: true,
                save: isUserRolePemission
            },
            fieldDescription: _T('')
        }
    },
    {
        name: '$.stage2.tunnel-monitor.destination-ip',
        uiHint: {
            fieldLabel: _T('Tunnel Monitoring IP')
        }
    },
    {
        name: '$.stage2.proxy-id.entry',
        uiHint: {
            editorType: 'record-form',
            fieldLabel: 'Proxy-IDs',
            showFilter: false,
            association: {
                fields: ['$.stage1.product'],
                availHide: function (values, formData, filters, key) {
                    return (values && !(values[0] === 'Cisco-ASA' || values[0] === 'Other Devices' || values[0] === 'Palo Alto Networks'));
                }
            },
        }
    },
    {
        name: '$.stage2.proxy-id.entry.*',
        uiHint: {
            labelWidthSize: 2.5
        }
    }, {
        name: '$.stage2.proxy-id.entry.*.local',
        uiHint: {
            showHelpString: _T("IP Address or IP/netmask, only needed when peer requires it.")
        }
    },
    {
        name: '$.stage2.proxy-id.entry.*.remote',
        uiHint: {
            showHelpString: _T("IP Address or IP/netmask, only needed when peer requires it.")
        }
    },
    {
        name: '$.stage2.proxy-id.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn'
            }
        }
    },
    {
        name: '$.stage2.proxy-id.entry.*.protocol',
        uiHint: {
            columnConfig: {
                valueGetter: params => {
                    let protocol = getPathValue(params.data, "protocol");
                    let displayStr = '';
                    if (protocol['tcp']) {
                        displayStr = 'TCP';
                    } else if (protocol['udp']) {
                        displayStr = 'UDP';
                    } else if (protocol['any']) {
                        displayStr = 'Any';
                    } else if (protocol['number']) {
                        displayStr = 'Number ' + protocol['number'];
                    }
                    return displayStr;
                }
            }
        }
    },
    {
        name: '$.stage2.proxy-id.entry.*.protocol.tcp',
        uiHint: {
            fieldLabel: 'TCP'
        }
    }, {
        name: '$.stage2.proxy-id.entry.*.protocol.udp',
        uiHint: {
            fieldLabel: 'UDP'
        }
    }, {
        name: '$.stage2.peer-address.choice',
        uiHint: {
            fieldLabel: _T('Branch IP Address Type')
        }
    }, {
        name: '$.stage2.peer-address.ip',
        uiHint: {
            fieldLabel: _T('IP')
        }
    }, {
        name: '$.stage2.tunnel-monitor.enable',
        childrenNames: [
            '$.stage2.tunnel-monitor.destination-ip',
            '$.stage2.tunnel-monitor.proxy-id'
        ],
        uiHint: {
            fieldLabel: _T('Tunnel Monitoring'),
            builder: 'FieldSetBuilder',
            showCheckbox: true,
        }
    },
    {
        name: '$.stage2.tunnel-monitor.proxy-id',
        uiHint: {
            association: {
                fields: ['$.stage1.product'],
                availHide: function (values, formData, filters, key) {
                    return (values && !(values[0] === 'Cisco-ASA' || values[0] === 'Other Devices' || values[0] === 'Palo Alto Networks'));
                }
            },
            completionConfig: {
                type: 'local',
                fieldPath: '$.stage2.proxy-id.entry.*',
                valueField: '@name'
            }
        }
    },
    {
        name: '$.stage2_step3',
        childrenNames: [
            '$.stage2.ike-crypto-profiles.name',
            '$.stage2.ike-crypto-profiles.choice'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            optional: true,
            fieldLabel: '',
            stepTitle: _T('IKE Crypto') + ' (Optional)',
            buttonsAvail: {
                next: false,
                back: false,
                save: isUserRolePemission
            },
            fieldDescription: _T('The IKE crypto profile is used to set up the encryption and authentication algorithms used for the key exchange process in IKE Phase 1. Based on the Product you choose, Prisma Access has automatically identified the recommended IKE crypto for you. You can optionally customize it.')
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.choice',
        defaultValue: 'default',
        uiHint: {
            allowBlank: false,
            fieldLabel: '  '
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.default',
        uiHint: {
            builder: 'FakeSchemaContainerBuilder',
            allowBlank: true,
            fakeSchemaDataSource: '$.stage1.product',
            dataMapping: 'ike',
            dataBaseOn: '$.stage2.ike-crypto-profiles.customized',
            fakeSchemaLabelMapping: {'dh-group': 'DH Group', 'ipsec-protocol': 'IPSec Protocol'},
            fieldLabel: _T('Use recommended setup')
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized',
        childrenNames: [
            '$.stage2.ike-crypto-profiles.customized.dh-group',
            '$.stage2.ike-crypto-profiles.customized.hash',
            '$.stage2.ike-crypto-profiles.customized.encryption',
            'stage-2-IKECustomCryptoProfileTimers'
        ],
        uiHint: {
            fieldLabel: _T('Customize setup')
        }
    }, {
        name: 'stage-2-IKECustomCryptoProfileTimers',
        childrenNames: ['$.stage2.ike-crypto-profiles.customized.lifetime', '$.stage2.ike-crypto-profiles.customized.authentication-multiple'],
        uiHint: {
            fieldLabel: _T('Timers'),
            builder: 'FieldSetBuilder'
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.lifetime',
        uiHint: {
            fieldLabel: _T('Key Lifetime'),
            showHelpString: true,
            helpstring: _T("Minimum lifetime = 3 mins"),
            singleLineLayout: true
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.authentication-multiple',
        uiHint: {
            fieldLabel: _T('IKEv2 Authentication Multiple'),
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.encryption.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.encryption.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.dh-group.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.dh-group.member.*',
        uiHint: {
            displayLabel: 'DH Group',
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.hash.member',
        uiHint: {
            builder: 'CompletionBuilder',
            fieldLabel: _T('Authentication')
        }
    }, {
        name: '$.stage2.ike-crypto-profiles.customized.hash.member.*',
        uiHint: {
            useValueAsDisplay: true,
            displayLabel: _T('Authentication'),
            completionConfig: {
                multiple: true
            }
        }
    },
    {
        name: '$.stage2_step4',
        childrenNames: [
            '$.stage2.ipsec-crypto-profiles.name',
            '$.stage2.ipsec-crypto-profiles.choice'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            optional: true,
            fieldLabel: '',
            stepTitle: _T('IPSec Crypto') + ' (Optional)',
            buttonsAvail: {
                next: false,
                back: false,
                save: isUserRolePemission
            },
            fieldDescription: _T('IPSec Crypto defines the IPSec protocols and keys used for SA in IKE Phase 2. Based on the Product you choose, Prisma Access has automatically identified the recommended IPSec crypto for you. You can optionally customize it.')
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.choice',
        defaultValue: 'default',
        uiHint: {
            allowBlank: false,
            fieldLabel: ' '
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.default',
        uiHint: {
            builder: 'FakeSchemaContainerBuilder',
            allowBlank: true,
            fakeSchemaDataSource: '$.stage1.product',
            dataMapping: 'ipsec',
            dataBaseOn: '$.stage2.ipsec-crypto-profiles.customized',
            fakeSchemaLabelMapping: {'dh-group': 'DH Group', 'ipsec-protocol': 'IPSec Protocol'},
            fieldLabel: _T('Use recommended setup')
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized',
        uiHint: {
            fieldLabel: _T('Customize setup')
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.choice',
        uiHint: {
            fieldLabel: _T('IPSec Protocol')
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.esp',
        uiHint: {
            fieldLabel: _T('ESP')
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.ah',
        uiHint: {
            fieldLabel: _T('AH')
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.lifetime',
        uiHint: {
            singleLineLayout: true,
            showHelpString: true,
            helpstring: _T("Minimum lifetime = 3 mins")
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.lifesize',
        uiHint: {
            singleLineLayout: true,
            showHelpString: true,
            helpstring: _T("Recommended lifesize is 100MB or greater")
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.esp.encryption.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.esp.encryption.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.esp.authentication.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.esp.authentication.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.ah.authentication.member',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.ah.authentication.member.*',
        uiHint: {
            useValueAsDisplay: true,
            completionConfig: {
                multiple: true
            }
        }
    }, {
        name: '$.stage2.ipsec-crypto-profiles.customized.dh-group',
        uiHint: {
            useValueAsDisplay: true,
            displayLabel: 'DH Group'
        }
    },
    {
        name: '$.stage_3',
        recordConfigPath: '$.stage3',
        childrenNames: [
            '$.stage3_step1'
        ],
        uiHint: {
            stageTitle: 'Routing and QoS',
            stageNumber: '3',
            stageCollapseTitle: 'Tunnel'
        }
    },
    {
        name: '$.stage3.routing',
        childrenNames: [
            '$.stage3.routing-separator',
            '$.stage3.routing.subnets',
            '$.stage3.routing.bgp.enable-separator',
            '$.stage3.routing.bgp.enable',
        ]
    }, {
        name: '$.stage3.qos-profile',
        uiHint: {
            builder: 'CompletionBuilder'
        }
    },
    {
        name: '$.stage3.routing.subnets.member',
        uiHint: {
            fieldLabel: _T('IP Subnets'),
            showHelpString: _T('Enter IP subnets or ranges') + ' (e.g. 192.168.74.0/24, 192.168.75.1-192.168.75.100)'
        }
    },
    {
        name: '$.stage3.routing-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('Static Routes')
        }
    },
    {
        name: '$.stage3.routing.bgp.enable-separator',
        uiHint: {
            builder: 'HorizontalSeparatorBuilder',
            fieldLabel: _T('BGP')
        }
    },
    {
        name: '$.stage3.routing.bgp.enable',
        childrenNames: [
            '$.stage3.routing.bgp.do-not-export-routes',
            '$.stage3.routing.bgp.peer-as',
            '$.stage3.routing.bgp.peer-ip-address',
            '$.stage3.routing.bgp.local-ip-address',
            '$.stage3.routing.bgp.secret'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            showCheckbox: true,
            fieldLabel: 'Enable BGP'
        }
    },
    {
        name: '$.stage3.routing.bgp.do-not-export-routes',
        uiHint: {
            fieldLabel: 'Don\'t export routes'
        }
    },
    {
        name: '$.stage_summary',
        childrenNames: [
            '$.stage_1',
            '$.stage_2',
            '$.stage_3'
        ],
        uiHint: {
            builder: 'SummaryBuilder',
            stages: {
                '$.stage_1': 'stage1',
                '$.stage_2': 'stage2',
                '$.stage_3': 'stage3'
            },
            fieldLabel: _T('Stage 1 / Summary'),
            fieldDescription: _T('')
        }
    }
];