import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


export class ChartWidget extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { chartOptions } = this.props;
        return (
            <div>
                <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                />
            </div>
        )
    }
}
