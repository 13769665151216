import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './HorizontalSeparatorWidget.scss';


export class HorizontalSeparatorWidget extends Component {
    static propTypes = {
        type: PropTypes.string,
    };

    render() {
        const textStyle="hs-text-style";
        const lineStyle="hs-line-separator";
        return (
            <React.Fragment>
                <div>
                    <div className={textStyle}>{this.props.headerText}</div>
                    <hr  className={lineStyle}></hr>
                </div>
            </React.Fragment>
        );
    }
}