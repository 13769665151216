import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get, isArray, isString, each } from 'lodash'

export class FeedbackWidget extends Component {
    constructor(props) {
        super(props);
        this.updateActions = this.updateActions.bind(this);
        this.state = {}
    }

    renderMessageBody() {
        let i = 1;
        let msgBody = [];
        msgBody.push( <div key={i++}>{this.props.message.msg}</div> );
        let detailMsg = get(this.props.message, "extra.message");
        if ( detailMsg ) {
            msgBody.push( <div key={i++}>Detail error(s):</div> );
            if (isString(detailMsg)) {
                msgBody.push( <div key={i++}>&nbsp;&nbsp;&nbsp;&nbsp;{detailMsg}</div> );
            }
            else if (isArray(detailMsg)) {
                each(detailMsg, msg => {
                    msgBody.push( <div key={i++}>&nbsp;&nbsp;&nbsp;&nbsp;{msg}</div> );
                });
            }
            else {
                msgBody.push( <div key={i++}>&nbsp;&nbsp;&nbsp;&nbsp;JSON.stringify(detailMsg)}</div> );
            }
        }
        return msgBody;
    }

    updateActions(_actions){
        this.setState({
            actions:_actions
        })
    }

    render() {
        let actions = this.state.actions || this.props.actions || [{ text: 'OK', action: () => { console.log('action') } }];
        let buttons = actions.map((item, index) => {
            return (
                <Button disabled={item.disabled} color={item.color || 'primary'} onClick={item.action} key={item.text || index}>
                    {item.text}
                </Button>
            )
        });
        let title =  this.props.title;
        if ( get(this.props.message, "extra.errorType") ) {
            title += ` - ${this.props.message.extra.errorType}`;
        }
        let BodyComp = this.props.modalBodyComponent ? this.props.modalBodyComponent.component : undefined;
        let componentProps = this.props.modalBodyComponent ? this.props.modalBodyComponent.props : {};
        let _body = BodyComp ? (<BodyComp {...componentProps} actions={actions} updateActions={this.updateActions}/>):(<div>{this.renderMessageBody()}</div>);
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className={this.props.className} style={this.props.style} centered>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody>{_body}</ModalBody>
                    <ModalFooter>
                        {buttons}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

FeedbackWidget.defaultProps = {
    type: 'Info',
    title: '',
    message: {},
};

FeedbackWidget.propTypes = {
    isOpen: PropTypes.bool,
    message: PropTypes.any,
    title: PropTypes.string,
    type: PropTypes.oneOf(['Info', 'Error', 'Warning']),
};
