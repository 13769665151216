import { MOCK_SERVICES,
		 getAuthHeaders } from 'ui-lib';
import { getServiceURLS } from 'service-lib';

export function disablePoliciesActionObservable(records, serviceName, location, disable) {
	let { DISABLE_RULES_URL } = getServiceURLS(serviceName, location);
	let names = [];
	for (let key in records) {
		let record = records[key];
		names.push(record["@name"] || record);
	}
	let url_base = DISABLE_RULES_URL;
	let ruleType = serviceName.substr(9);
	let service_url = `${url_base}&name=${names.join(",")}&disabled=${disable ? "yes" : "no"}&ruleType=${ruleType}`;

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: service_url,
		body: JSON.stringify({}),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function movePoliciesActionObservable(selectedRule, dstRule, serviceName, location, movePosition) {
	let { MOVE_RULES_URL } = getServiceURLS(serviceName, location);
	let url_base = MOVE_RULES_URL;
	let service_url = `${url_base}${url_base.indexOf("?") === -1 ? "?" : "&"}action=move&name=${selectedRule}&where=${movePosition}${dstRule ? "&dst=" + dstRule : ""}`;

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: service_url,
		body: JSON.stringify({}),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function deleteApplicationTagActionObservable(applicationTags, serviceName, location) {
	applicationTags = Array.isArray(applicationTags) ? applicationTags.join(",") : applicationTags;
	// let { SERVICE_URL } = getCustomServiceURL(`api/config/v9.0/Objects/ApplicationTags?name=${applicationTags}&location=${location});
	let { SERVICE_URL } = getServiceURLS(serviceName, location);
	SERVICE_URL = `${SERVICE_URL}&name=${applicationTags}`;
	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "DELETE",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}
