
import React from "react";
import PropTypes from "prop-types";
import Pan from "../../schema/Pan";
import { FormContext, FormConsumer } from "../../FormContext";
import { TextWidget } from "../../widgets/TextWidget/TextWidget";
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";
import { FormGroup, Col, FormText } from "reactstrap";
import { getFieldLabelComp, getFieldWidthClass, getFieldHelpString } from "../../schema/utils";
import classnames from "classnames";
import { BaseFieldBuilder } from "../BaseBuilder";
import './TextfieldBuilder.scss';
import _ from 'lodash';
import { getPathValues, getPathValue } from "../../../utils/json";

export class TextfieldBuilder extends BaseFieldBuilder {
    static NAME = 'TextfieldBuilder';
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.state = {
            hideField: false,
            value: false
        }
        this.textField = React.createRef();
    }

    setRecordValue(value, dontSetState) {
        let { field } = this.props;
        if (field.attrPath.endsWith('.member.*')) {
            //member grid
            value = Pan.apply(Pan.clone(this.getRecordValue()), { value: value });
        }
        super.setRecordValue(value, dontSetState);
    }

    renderTextfield() {
        let {
            name,
            className,
            field,
            hideLabel,
            containerLabelWidthSize,
            onBlur,
            onFocus,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            autoFocus
        } = this.props;

        if (this.props.hidden || this.state.hideField || getPathValues(field, 'uiHint.hidden')) {
            return (
                <div />
            );
        }
        const value = this.state.value !== false ? this.state.value : this.getRecordValue();
        let errors = this.getErrors();
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let helpString = getFieldHelpString(field, this.context.formData);
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let textFieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let maxLength = getPathValue(field, 'uiHint.maxLength') || '31';
        let minLength = getPathValue(field, 'uiHint.minLength') || undefined;
        let pattern = getPathValues(field, 'uiHint.pattern') ? getPathValues(field, 'uiHint.pattern')[0] : undefined;
        let placeHolder = getPathValue(field, 'uiHint.placeholder') || field.defaultValue;
        // handling autoFocus for textFieldBuilder
        let focus = getPathValue(field, 'uiHint.autoFocus') || autoFocus;

        return (
            <FormGroup className={classnames(className, `d-flex flex-row ${required}`)} test_id={field.attrPath}>
                {!hideLabel && LabelComp}
                <Col >
                    {!this.state.hideField &&
                        <TextWidget
                            id={this.id}
                            ref={this.textField}
                            name={name}
                            value={value}
                            onChange={this.setRecordValue}
                            placeholder={placeHolder}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            disabled={disabled || this.state.disableField}
                            className={textFieldClassName}
                            error={error}
                            pattern={pattern}
                            autoFocus={focus}
                            maxLength={maxLength}
                            minLength={minLength}
                            showErrors={showErrors}
                        />
                    }
                    {!this.state.hideField && helpString && (
                        <FormText>
                            {helpString.split('\n').map((i, key) => {
                                return <div key={key}>{i}</div>;
                            })}
                        </FormText>
                    )}
                    {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                </Col>
            </FormGroup>
        );
    }

    focus = () => {
        if (this.textField && this.textField.current && this.textField.current.focus) {
            this.textField.current.focus();
        }
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderTextfield()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

TextfieldBuilder.contextType = FormContext;

TextfieldBuilder.defaultProps = {
    disabled: false,
    autoFocus: false,
    hidden: false
};

if (process.env.NODE_ENV !== "production") {
    TextfieldBuilder.propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        /**
         * *attrPath* - defines the attribute schema path eg: "$.a.b.c"
         * *attrName* - specifies the name of the field attribute eg: "c"
         * *defaultValue* - sets the default value of the field.
         * UiHint properties :
         * *hidden* - hides the field
         * *allowBlank* - if false,blank values will add error
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *fieldLabel* - adds a label to the field
         * *hideLabel* - hides the label
         * *disabled* - disables/enables the field
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype
         * *showHelpString* - if true,will show custom help string/message
         * *helpstring* - set the help string/mesage
         */
        field: PropTypes.shape({
            attrPath: PropTypes.string.isRequired,
            attrName: PropTypes.string,
            uiHint: PropTypes.shape({
                hidden: PropTypes.bool,
                allowBlank: PropTypes.bool,
                labelWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                disabled: PropTypes.bool,
                customValidation: PropTypes.func,
                vtype: PropTypes.oneOfType([
                    PropTypes.func,
                    PropTypes.string,
                ]),
                showHelpString: PropTypes.string,
                helpstring: PropTypes.string,
            }).isRequired,
            defaultValue: PropTypes.string,
        }).isRequired,
        hideLabel: PropTypes.bool,
        containerLabelWidthSize: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        isEditorGridColumnEditor: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        autoFocus: PropTypes.bool,
        hidden: PropTypes.bool,
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                find: PropTypes.string,
                replace: PropTypes.string,
                append: PropTypes.string,
            }),
        ),
        onChange: PropTypes.func,
        addError: PropTypes.func,
        removeError: PropTypes.func.isRequired,
    };
}
