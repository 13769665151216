
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormContext, FormConsumer } from "../../FormContext";
import './HorizontalSeparatorBuilder.scss'
import { getFieldLabel } from "../../schema/utils";
import { HorizontalSeparatorWidget } from "../../widgets/HorizontalSeparatorWidget/HorizontalSeparatorWidget";
import { BaseBuilder } from '../BaseBuilder';

export class HorizontalSeparatorBuilder extends BaseBuilder {
    constructor(props) {
        super(props);
        this.state = {
            hideField: false
        }
    }

    render() {
        const { field } = this.props;
        const fieldLabel = getFieldLabel(field);
        return (
            <React.Fragment>
                {!this.state.hideField && <HorizontalSeparatorWidget headerText={fieldLabel} />}
            </React.Fragment>
        );
    }
}

HorizontalSeparatorBuilder.contextType = FormContext;

HorizontalSeparatorBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    HorizontalSeparatorBuilder.propTypes = {
        id: PropTypes.string,
        field: PropTypes.object
    };
}
