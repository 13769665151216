
import React from "react";
import PropTypes from "prop-types";
import Pan from "../../schema/Pan";
import { FormContext, FormConsumer } from "../../FormContext";
import { TextareaWidget } from "../../widgets/TextareaWidget/TextareaWidget";
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";
import { FormGroup, Col } from "reactstrap";

import { getFieldLabelComp, getFieldWidthClass } from "../../schema/utils";
import classnames from "classnames";
import { BaseFieldBuilder } from "../BaseBuilder";
import './TextareaBuilder.scss';
import { getPathValues } from "../../../utils/json";


export class TextareaBuilder extends BaseFieldBuilder {
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.onChange = super.setRecordValue.bind(this);
        this.state = {
            value: props.field.defaultValue || false
        }
    }

    setRecordValue(value) {
        this.setState({
            value: value
        });
        this.onChange(value);
    }

    renderTextarea = () => {
        let {
            name,
            className,
            field,
            containerLabelWidthSize,
            onBlur,
            onFocus,
            isEditorGridColumnEditor,
            disabled,
            showErrors
        } = this.props;

        const value = this.getRecordValue() || (this.state.value !== false ? this.state.value : '');
        let errors = this.getErrors();
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let fieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let maxLength = getPathValues(field, 'uiHint.maxLength');
        return (
            <FormGroup className={classnames(className, `d-flex flex-row ${required}`)} test_id={field.attrPath}>
                {LabelComp}
                <Col >
                    <TextareaWidget
                        id={this.id}
                        name={name}
                        value={value}
                        onChange={this.setRecordValue}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        disabled={disabled}
                        maxLength={maxLength}
                        className={fieldClassName}
                        error={error}
                        showErrors={showErrors}
                    />
                    {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderTextarea()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

TextareaBuilder.contextType = FormContext;

TextareaBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    TextareaBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        field: PropTypes.object,
        id: PropTypes.string,
        className: PropTypes.string
    };
}
