import React from 'react';
import {
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import {
    getPathValue,
    showModal,
    hideModal,
    HLViewer,
    TextWidget,
    Pan,
    ButtonWidget,
    connect,
    ErrorBoundary
} from 'ui-lib';
import _ from 'lodash';
import './ServiceInfrastructure.scss';
import {
    addConfigItem,
    editConfigItem,
    getEffectiveServiceMap,
    showForm,
    hideForm,
    SHOW_FORM,
} from 'service-lib';
import {
    fetchGpcsCommonInfra,
    GET_NETWORK_DETAIL_DATA,
    GET_NOTIFICATION,
    POST_NOTIFICATION,
    GET_API_KEY,
    GENERATE_API_KEY,
    SERVICE_INFRASTRUCTURE_REDUX_ID,
    updateGpcsReduxState
} from '../main/actions';
const _T = str => str;
export const SERVICE_SUBNET = '192.168.255.0/24';
export const INTERNAL_DNS_LIST_REDUX_ID = 'internalDomainList';

const getServiceInfrastructureViewerWithActions = (serviceName, reduxId, prepareAddRecord, identifier) => {
    class ServiceInfrastructureViewerWithActions extends React.Component {
        constructor(props) {
            super(props);
            //if configLocation is coming from outside use it
            this.state = {
                configLocation: props.configLocation && getPathValue(props.configLocation, 'extension.gpcs.name') ? { extension: { gpcs: { name: getPathValue(props.configLocation, 'extension.gpcs.name') } } } : null,
                url: props.url || '',
                apiKey: props.apiKey,
                columns: props.columns,
                serviceMap: getEffectiveServiceMap(serviceName),
                reduxStateId: {
                    reduxId: reduxId,
                    locationPath: 'gpcs'
                }
            }
            this.prepareAddRecord = prepareAddRecord;
            this.apiKeyInputId = Pan.id();
        }

        static getDerivedStateFromProps(nextProps, _) {
            return {
                url: nextProps.url,
                apiKey: nextProps.apiKey,
                columns: nextProps.columns
            };
        }

        componentDidMount() {
            this.props.dispatch(
                fetchGpcsCommonInfra(serviceName, reduxId, this.state.configLocation)
            );

            this.props.dispatch({
                type: GET_NOTIFICATION
            });

            this.props.dispatch({
                type: GET_API_KEY
            });

            this.props.dispatch({
                type: GET_NETWORK_DETAIL_DATA
            });
        }

        componentWillUnmount() {
            this.props.dispatch(
                hideForm(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        onAddRecord(existingRecord, record, configPath) {
            let backup;
            if (!existingRecord) {
                if (configPath.indexOf('internal-dns-list') < 0) {
                    record['@name'] = 'common-infra-object';
                } else {
                    let serviceInfraData = this.props.gpcs[SERVICE_INFRASTRUCTURE_REDUX_ID];
                    existingRecord = serviceInfraData && serviceInfraData.result && serviceInfraData.result.entry ? serviceInfraData.result.entry[0] : undefined;
                }
            }
            if (configPath.indexOf('internal-dns-list') >= 0) {
                backup = existingRecord;
                if (existingRecord['internal-dns-list'] && existingRecord['internal-dns-list'].entry) {
                    existingRecord['internal-dns-list'].entry.push(record);
                } else {
                    let entry = [];
                    entry.push(record);
                    existingRecord['internal-dns-list'] = { entry };
                }
                record = existingRecord;
            }

            if (record['internal-dns-list'] && record['internal-dns-list'].entry) {
                record['internal-dns-list'].entry.map(item => item['@name'] = item['primary']);
            }
            if (record['@uuid']) {
                this.props.dispatch(
                    editConfigItem(record, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
                );
            } else {
                this.props.dispatch(
                    addConfigItem(record, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation)
                )
            }
            this.syncReduxIDs(record, backup, configPath);
        }

        onEditRecord(parentRecord, record, backup, configPath) {
            if (Pan.isDefined(parentRecord)) {
                backup = parentRecord;
                let newDNSList = [];
                if (parentRecord['internal-dns-list'] && parentRecord['internal-dns-list'].entry) {
                    const dnsList = parentRecord['internal-dns-list'];
                    newDNSList = dnsList.entry.map(item => {
                        if (item['@uuid'] === record['@uuid']) {
                            return record;
                        }
                        return item;
                    });

                }
                parentRecord['internal-dns-list']['entry'] = newDNSList;
                record = parentRecord;
            }

            if (record['internal-dns-list'] && record['internal-dns-list'].entry) {
                record['internal-dns-list'].entry.map(item => item['@name'] = item['primary']);
            }
            this.props.dispatch(
                editConfigItem(record, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
            );
            this.syncReduxIDs(record, backup, configPath);
        }

        syncReduxIDs(record, backup, configPath) {
            // This fn keeps the redux id of the service-infra and internal DNS consistent
            if (configPath.indexOf('internal-dns-list') < 0) {
                this.props.dispatch(
                    updateGpcsReduxState(record, INTERNAL_DNS_LIST_REDUX_ID)
                );
            } else {
                this.props.dispatch(
                    updateGpcsReduxState(record, SERVICE_INFRASTRUCTURE_REDUX_ID)
                );
            }
        }

        onDeleteRecord(parentRecord, records, configPath) {
            const backup = parentRecord;
            Object.keys(records).forEach(rec => {
                if (parentRecord && parentRecord['internal-dns-list'] && parentRecord['internal-dns-list'].entry) {
                    let index = parentRecord['internal-dns-list'].entry.findIndex(item => {
                        return item['@uuid'] === records[rec]['@uuid'];
                    });
                    if (index > -1) {
                        parentRecord['internal-dns-list'].entry.splice(index, 1);
                    }
                }

            });
            this.props.dispatch(
                editConfigItem(parentRecord, backup, this.state.serviceMap, this.state.reduxStateId, this.state.configLocation),
            );
            this.syncReduxIDs(parentRecord, backup, configPath);
        }

        onShowForm() {
            this.props.dispatch(
                showForm(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        onHideForm() {
            this.props.dispatch(
                hideForm(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        copyApiKey = () => {
            document.querySelector(`#${this.apiKeyInputId}`).select();
            document.execCommand('copy');

            this.setState({
                apiKey: {
                    ...this.state.apiKey,
                    copied: true
                }
            });
        }

        onNotificationUrlLocalChange = (val) => {
            this.setState({
                url: val
            });
        }

        saveNotificationUrl = () => {
            this.props.dispatch({
                type: POST_NOTIFICATION,
                payload: this.state.url || ''
            });
        }

        showGenApiKeyModal = () => {
            const genApiKeyModalId = 'ServiceInfraGenApiKeyModal';
            showModal({
                id: genApiKeyModalId,
                open: true,
                size: 'sm',
                toggle: () => {
                    hideModal(genApiKeyModalId);
                },
                title: _T('Generate new API key'),
                message: _T('Generating a new API will invalidate the current key. Make sure to change it to the newly generated API key.'),
                actions: [
                    {
                        text: _T('Cancel'),
                        color: 'secondary',
                        action: () => {
                            hideModal(genApiKeyModalId);
                        }
                    },
                    {
                        text: _T('Generate'),
                        color: 'primary',
                        action: () => {
                            this.genApiKey(genApiKeyModalId);
                            hideModal(genApiKeyModalId);
                        }
                    }
                ]
            });
        }

        genApiKey = () => {
            this.props.dispatch({
                type: GENERATE_API_KEY
            });
        }

        render() {
            let responseData = this.props.gpcs[reduxId] || {};
            let gridData = responseData && responseData.result && responseData.result.entry ? responseData.result.entry : [{}];
            var completeData;
            if (!Pan.isEmpty(identifier)) {
                const parentData = gridData[0];
                gridData = [];
                if (parentData && parentData['internal-dns-list']) {
                    completeData = Pan.clone(parentData);
                    gridData = parentData['internal-dns-list'].entry ? parentData['internal-dns-list'].entry : [];
                }
            }
            return (
                <div >
                    <div>
                        <ErrorBoundary>
                            <HLViewer
                                {...this.props}
                                gridData={gridData}
                                showPaging={false}
                                onAddRecord={this.onAddRecord.bind(this, completeData)}
                                onEditRecord={this.onEditRecord.bind(this, completeData)}
                                onDeleteRecord={this.onDeleteRecord.bind(this, completeData)}
                                panSchema={window.__pan_schema}
                                // loading={responseData[GRID_LOADING]}
                                showForm={responseData[SHOW_FORM]}
                                onShowForm={this.onShowForm.bind(this)}
                                onHideForm={this.onHideForm.bind(this)}
                                // formLoading={responseData[FORM_LOADING]}
                                configLocation = {this.state.configLocation}
                                locationPermission={'enable'}
                                prepareAddRecord={this.prepareAddRecord.bind(this)}
                                minRows={0}
                            />
                        </ErrorBoundary>
                    </div>
                    {!this.props.isViewerDisplay &&
                        <div style={{ paddingTop: '20px', backgroundColor: 'white' }}>
                            <Row className="ServiceInfraTableHeader">
                                <Col className="ServiceInfraTableHeaderInner">
                                    <div style={{ backgroundColor: 'white' }}>{_T('Egress IP API')}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Container className={'viewer-display notification-container'} fluid>
                                        <Row className={'notification-row'}>
                                            <Col className={'col-12 notification-col'}>
                                                <label className={'notification-label-col label-word'}>
                                                    {_T('EGRESS IP API KEY')}
                                                </label>
                                                <div className={'col-6'}>
                                                    <InputGroup className="egress-api-input-group">
                                                        <TextWidget
                                                            id={this.apiKeyInputId}
                                                            value={_.get(this, 'state.apiKey.value', '')}
                                                            onChange={_.noop}
                                                        />
                                                        <InputGroupAddon
                                                            addonType="append"
                                                            onClick={_.get(this, 'state.apiKey.copied', false) ? _.noop : this.copyApiKey}>
                                                            {_.get(this, 'state.apiKey.copied', false) ? _T('Copied') : _T('Copy')}
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                                <div className="col-2">
                                                    {this.props.rbaPermission === 'enable' &&
                                                    <ButtonWidget 
                                                        className={'egress-api-btn'}
                                                        color="secondary"
                                                        text={_T('Generate New')}
                                                        onClick={this.showGenApiKeyModal} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={'notification-row'}>
                                            <Col className={'col-12 notification-col'}>
                                                <label className={'notification-label-col label-word'}>
                                                    {_T('NOTIFICATION URL')}
                                                </label>
                                                <div className={'col-6'}>
                                                    <TextWidget
                                                        id={Pan.id()}
                                                        placeholder={_T('Enter and save a URL to get notified')}
                                                        value={this.state.url || ''}
                                                        onChange={this.onNotificationUrlLocalChange} />
                                                </div>
                                                <div className={'col-2'}>
                                                {this.props.rbaPermission === 'enable' &&
                                                    <ButtonWidget 
                                                        className={'egress-api-btn'}
                                                        color="secondary"
                                                        text={_T('Save')}
                                                        disabled={this.state.url === '' ? true : false}
                                                        onClick={this.saveNotificationUrl} />
                                                }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            );
        }
    }

    const mapStateToProps = state => {
        return {
            gpcs: state.gpcs,
            url: state.gpcs.notification_url,
            service_infra_status: state.gpcs.service_infra_status,
            apiKey: state.gpcs.apiKey
        };
    };

    return connect(mapStateToProps, null, null)(ServiceInfrastructureViewerWithActions);
};
export default getServiceInfrastructureViewerWithActions;
