import React, { Component } from "react";
import { showModal, hideModal } from "../../core/services/actions";
import { ProgressWidget } from "../../core/autorender/widgets/ProgressWidget";
import { TagsGroupWidget } from "../../core/autorender/widgets/TagsGroupWidget";
import { GridToolbar, GridWidget } from "../../core/autorender/widgets/GridWidget";

import { JobDetails } from "./JobDetails";
import "./JobManager.scss";
// import ReactTable from "react-table";
import { ajax } from "rxjs/ajax";
import { getJobsActionObservable } from "./services";
import moment from 'moment-timezone/builds/moment-timezone-with-data';

export class JobManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			isloading: true,
			filters: {},
			currentPage: 0,
			pageSize: 25,
			totalCount: 0,
			expanded: {}
		};
	}

	componentDidMount() {
		this.clearInterval();
		this.loadJobs();
		this.resetJobSync();
	}

	componentWillUnmount() {
		this.clearInterval();
	}

	onPageChange = (nextPage) => {
		this.clearInterval();
		this.setState({
			currentPage: nextPage - 1,
			isloading: true
		});
		this.loadJobs(nextPage - 1);
		// this.resetJobSync();
	}

	clearInterval = () => {
		this.state.intervalId && clearInterval(this.state.intervalId);
	}

	processJobData = (data) => {
		data.forEach((job) => {
			job.id = parseInt(job.id);
			job.parent_id = parseInt(job.parent_id);
		});

		data.forEach((job) => { 
			if (job.parent_id === 0) {
				//parent job
				job.treePath = [job.id];
			} else {
				job.treePath = [job.parent_id, job.id]
			}
		});
		return data;
	}

	getDataPath = (data) => {
		return data.treePath;
	}

	loadJobs = (page) => {
		let { currentPage, pageSize } = this.state;
		currentPage = page >= 0 ? page : currentPage;
		let start = currentPage === 0 ? currentPage : (currentPage - 1) * pageSize + pageSize;
		ajax(
			getJobsActionObservable({
				filter: JSON.stringify(this.getFilter()),
				start: start,
				limit: pageSize
			})
		).subscribe(res => {
			let newData = this.processJobData(res.response.data);
			let totalCount = res.response.total;
			let pageCount = Math.ceil(totalCount / this.state.pageSize);
			this.setState({
				data: newData,
				totalCount,
				pageCount,
				isloading: false
			});
		});
	}

	getFilter = () => {
		const { filters } = this.state;
		let filtersString = {};
		Object.keys(filters).forEach(filter => {
			if (!filtersString[filter]) {
				filtersString[filter] = [];
			}
			filters[filter].forEach(item => {
				filtersString[filter].push(item.value);
			});
		});
		return filtersString;
	}

	addFilter = (params) => {
		let value = params.value;
		let type = params.colDef.field;
		let label = params.value;
		const { filters } = this.state;
		if (!filters[type]) {
			filters[type] = [];
		}
		let isExist = false;
		filters[type].forEach(function(filter) {
			if (filter.value === value) {
				isExist = true;
			}
		});
		if (!isExist) {
			filters[type].push({ value, label });
			//this.clearInterval();
			this.setState({
				filters: filters,
				currentPage: 0
				//intervalId:undefined
			});
		}
		this.loadJobs(0);
	}

	removeFilter = (type, value) => {
		const { filters } = this.state;
		if (filters[type]) {
			let newFilter = filters[type].filter(function(v, index, arr) {
				return v.value !== value;
			});
			if (newFilter && newFilter.length > 0) {
				filters[type] = newFilter;
			} else {
				delete filters[type];
			}
			this.setState({
				filters: filters,
				currentPage: 0
			});
		}
		this.loadJobs(0);
	}

	resetJobSync = () => {
		this.clearInterval();
		let intervalId = setInterval(() => {
			this.loadJobs();
		}, this.props.refreshInterval || 5000);
		this.setState({ intervalId });
	}

	showJobDetails = (params) => {
		if (this.state.intervalId) clearInterval(this.state.intervalId);
		let modal = {
			id: "JobDetailModal",
			open: true,
			title: "Job Details",
			size: "xl",
			type: "Info",
			toggle: () => {
				hideModal("JobDetailModal");
			},
			modalBody: {
				component: JobDetails,
				props: {
					jobid: params.data.id
				}
			},
			actions: [
				{
					text: "Close",
					color: "secondary",
					action: () => {
						hideModal("JobDetailModal");
						this.hideJobDetails();
					}
				}
			]
		};
		showModal(modal);
	}

	hideJobDetails = () => {
		this.resetJobSync();
	}

	getFilterComponent = () => {
		let filetrs = [];
		Object.keys(this.state.filters).forEach(item => {
			let value = this.state.filters[item];
			filetrs.push(<TagsGroupWidget key={item} type={item} items={value} onTagRemove={this.removeFilter} />);
		});
		return filetrs;
	}

	// renderExpander(isExpanded, recordInfo) {
	// 	let expander = <span />;
	// 	if (recordInfo.childJobs.length > 0) {
	// 		expander = isExpanded ? <span>&#9661;</span> : <span>&#9655;</span>;
	// 	}
	// 	return expander;
	// }

	render() {
		const pStyle = {
			height: "50px"
		};
		const { isloading, data, pageSize, pageCount } = this.state;
		let commonColumns = [
			{
				headerName: "Type",
				field: "type_str",
				// onCellClicked: this.addFilter,
				width: 150,
				cellRendererParams: {
                    renderCell: params => {
						return <div style={{ cursor: "pointer", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{params.value}</div>;
                    }
                }
			},
			{
				headerName: "Result",
				field: "result_str",
				width: 150,
				cellRendererParams: {
                    renderCell: params => {
						if (params.data && params.data.status_str !== "FIN") {
							return <ProgressWidget value={params.data.percent} status={params.data.status_str} />;
						} else {
							return <div>{params.value}</div>;
						}
					}
                }
			},
			{
				headerName: "Admin",
				field: "uname",
				// onCellClicked: this.addFilter,
				width: 200,
				cellRendererParams: {
                    renderCell: params => {
						return <div style={{ cursor: "pointer", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{params.value}</div>;
					}
                }
			},
			{
				headerName: "Description",
				field: "description",
				width: 200,
				cellRendererParams: {
                    renderCell: params => {
						return <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{params.value}</div>;
					}
                }
			},
			{
				headerName: "Summary",
				field: "summary",
				width: 200,
				cellRendererParams: {
                    renderCell: params => {
						return <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{params.value}</div>;
					}
                }
			},
			{
				headerName: "Start Time",
				// field: "start_ts",
				width: 200,
				valueGetter: params => {
					let startTS = params.data ? moment.utc(params.data.start_ts).toDate() : null;
					return startTS ? moment(startTS).local().format('YYYY-MMM-DD HH:mm:ss') : '';
				}
			},
			{
				headerName: "End Time",
				field: "end_ts",
				width: 180,
				valueGetter: params => {
					let endTS = params.data ? moment.utc(params.data.end_ts).toDate() : null;
					return endTS ? moment(endTS).local().format('YYYY-MMM-DD HH:mm:ss') : '';
				},
				cellRendererParams: {
                    renderCell: params => {
						if (params.data && params.data.status_str === "FIN") {
							return <div>{params.value}</div>;
						} else {
							return <div />;
						}
					}
                }
			}
		];
		return (
			<div className="job-manager">
				{Object.keys(this.state.filters).length > 0 && <div style={pStyle}>{this.getFilterComponent()}</div>}
				<GridWidget
					idColumn={'treePath'}
					gridData={data}
					columns={commonColumns}
					checkboxSelection={false}
					showFilter={false}
					loading={isloading}
					agGridProps={{
						treeData: true,
						getDataPath: this.getDataPath,
						autoGroupColumnDef: {
							headerName: "Job ID",
							width:130,
							sort:'desc',
							cellStyle:{color:'#0ba4e8',cursor:'pointer'},
							onCellClicked: this.showJobDetails
						}
					}}
					pages={pageCount}
					pageSize={pageSize}
					changePage={this.onPageChange}
				/>
			</div>
		);
	}
}
