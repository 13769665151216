import React, { Component } from 'react';
import { 
    getPathValue, 
    WorkflowViewer, 
    connect, 
    ErrorBoundary 
} from 'ui-lib';
import {
    fetchConfigItems,
    addConfigItem,
    editConfigItem,
    deleteConfigItem,
    cloneConfigItem,
    showWorkflow,
    hideWorkflow,
    SHOW_WORKFLOW,
    WORKFLOW_LOADING,
    SHOW_FORM,
    GRID_LOADING,
    saveConfigSuccess
} from '../../services/actions';

const getWorkflowWithActions = (serviceName, reduxId, serviceMap, prepareAddRecord, summaryAvail, locationPath, configLocation) => {
    class WorkflowWithActions extends Component {
        constructor(props) {
            super(props);

            // if configLocation is coming from outside use it
            this.state = {
                configLocation: configLocation,
                rbaPermission: props.rbaPermission || '',
                serviceMap: serviceMap,
                reduxStateId: {
                    reduxId: reduxId,
                    locationPath: locationPath
                },
                columns: props.columns,
                record: null
            }
            this.prepareAddRecord = prepareAddRecord;
            this.summaryAvail = summaryAvail;
        }

        componentDidUpdate() {
            let newData = this.props[locationPath][reduxId];
            if (newData && newData[SHOW_FORM] === false) {
                this.formState = {};
                if (this.props.afterSubmit) {
                    // redux change in store data to hide the form
                    // invoke afterSubmit as this case is possibel only after successful submit
                    this.props.afterSubmit();
                }
            }
        }

        componentDidMount() {
            if (this.props.mode !== 'form') {
                this.props.dispatch(fetchConfigItems(this.state.serviceMap, this.state.reduxStateId, this.state.configLocation));
            }
            if (this.props.initActions && this.props.initActions.length > 0) {
                this.props.initActions.forEach(action => {
                    this.props.dispatch(action(reduxId, this.props.initActionsConfigLocation));
                });
            }
            this.props.dispatch(saveConfigSuccess(false)); // reset save success
        }

        onAddRecord(record) {
            this.props.dispatch(
                addConfigItem(record, serviceMap, this.state.reduxStateId, this.state.configLocation, this.state.locationPath)
            );
        }

        onEditRecord(record, backup) {
            this.props.dispatch(
                editConfigItem(record, backup, serviceMap, this.state.reduxStateId, this.state.configLocation, this.state.locationPath),
            );
        }

        

        onDeleteRecord(records) {
            this.props.dispatch(
                deleteConfigItem(records, serviceMap, this.state.reduxStateId, this.state.configLocation, this.state.locationPath),
            );
        }

        onCloneRecord(records) {
            this.props.dispatch(
                cloneConfigItem(records, serviceMap, this.state.reduxStateId, this.state.configLocation, this.state.locationPath),
            );
        }

        onShowWorkflow() {
            this.props.dispatch(
                showWorkflow(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        onHideWorkflow() {
            this.props.dispatch(
                hideWorkflow(reduxId, this.state.reduxStateId.locationPath)
            );
        }

        render() {
            let responseData = this.props[locationPath][reduxId] || {};
            let gridData = responseData ? getPathValue(responseData, `result.entry`) || [] : [];

            return (
                <ErrorBoundary>
                    <WorkflowViewer
                        {...this.props}
                        columns={this.state.columns}
                        gridData={gridData}
                        onAddRecord={this.onAddRecord.bind(this)}
                        onEditRecord={this.onEditRecord.bind(this)}
                        onDeleteRecord={this.onDeleteRecord.bind(this)}
                        onCloneRecord={this.onCloneRecord.bind(this)}
                        panSchema={window.__pan_schema}
                        loading={responseData[GRID_LOADING]}
                        showWorkflow={responseData[SHOW_WORKFLOW]}
                        onShowWorkflow={this.onShowWorkflow.bind(this)}
                        onHideWorkflow={this.onHideWorkflow.bind(this)}
                        formLoading={responseData[WORKFLOW_LOADING]}
                        configLocation={this.state.configLocation}
                        rbaPermission={this.state.rbaPermission}
                        locationPermission={'enable'}
                        showMapPage={this.props.showMapPage}
                        pushJobId={this.props.pushJobId}
                        formState={this.formState}
                        prepareAddRecord={this.prepareAddRecord && this.prepareAddRecord.bind(this)}
                        summaryAvail={this.summaryAvail}
                    />
                </ErrorBoundary>
            );
        }

        componentWillUnmount() {
            this.props.dispatch(
                hideWorkflow(reduxId, this.state.reduxStateId.locationPath)
            );
        }
    }

    const mapStateToProps = state => {
        return { [locationPath]: state[locationPath] };
    };

    return connect(mapStateToProps, null, null)(WorkflowWithActions);
};

export default getWorkflowWithActions;