import React from 'react';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

export const tagColorNameMap = {
    '': _T("None"),
    'color1': _T("Red"),
    'color2': _T("Green"),
    'color3': _T("Blue"),
    'color4': _T("Yellow"),
    'color5': _T("Copper"),
    'color6': _T("Orange"),
    'color7': _T("Purple"),
    'color8': _T("Gray"),
    'color9': _T("Light Green"),
    'color10': _T("Cyan"),
    'color11': _T("Light Gray"),
    'color12': _T("Blue Gray"),
    'color13': _T("Lime"),
    'color14': _T("Black"),
    'color15': _T("Gold"),
    'color16': _T("Brown"),
    'color17': _T("Olive"),
    'color18': _T("White"),
    'color19': _T("Maroon"),
    'color20': _T("Red-Orange"),
    'color21': _T("Yellow-Orange"),
    'color22': _T("Forest Green"),
    'color23': _T("Turquoise Blue"),
    'color24': _T("Azure Blue"),
    'color25': _T("Cerulean Blue"),
    'color26': _T("Midnight Blue"),
    'color27': _T("Medium Blue"),
    'color28': _T("Cobalt Blue"),
    'color29': _T("Violet Blue"),
    'color30': _T("Blue Violet"),
    'color31': _T("Medium Violet"),
    'color32': _T("Medium Rose"),
    'color33': _T("Lavender"),
    'color34': _T("Orchid"),
    'color35': _T("Thistle"),
    'color36': _T("Peach"),
    'color37': _T("Salmon"),
    'color38': _T("Magenta"),
    'color39': _T("Red Violet"),
    'color40': _T("Mahogany"),
    'color41': _T("Burnt Sienna"),
    'color42': _T("Chestnut")
};

export const tagColorIconMap = {
    'color0': 'white',
    'color1': '#CD383F',
    'color2': '#72A392',
    'color3': '#6ba2b9',
    'color4': '#EBD722',
    'color5': '#C27D2A',
    'color6': '#f6851f',
    'color7': '#7D3953',
    'color8': '#5B6F7B',
    'color9': '#C9D6A6',
    'color10': '#8AD3DF',
    'color11': '#C2D1D3',
    'color12': '#80A1B6',
    'color13': '#a7c439',
    'color14': '#000000',
    'color15': '#FFC425',
    'color16': '#918A75',
    'color17': '#97A822',
    'color18': '#FFFFFF',
    'color19': '#C32148',
    'color20': '#FF681F',
    'color21': '#FFAE42',
    'color22': '#5FA777',
    'color23': '#6CDAE7',
    'color24': '#4997D0',
    'color25': '#339ACC',
    'color26': '#00468C',
    'color27': '#4570E6',
    'color28': '#8C90C8',
    'color29': '#766EC8',
    'color30': '#6456B7',
    'color31': '#8F47B3',
    'color32': '#D96CBE',
    'color33': '#BF8FCC',
    'color34': '#E29CD2',
    'color35': '#EBB0D7',
    'color36': '#FFCBA4',
    'color37': '#FF91A4',
    'color38': '#F653A6',
    'color39': '#BB3385',
    'color40': '#CA3435',
    'color41': '#AB6744',
    'color42': '#B94E48'
};

export const dot = (color = 'white') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const tagsFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true,
            regex: /^[^'\]\[]*$/,
        }
    },
    {
        name: '$.color',
        uiHint: {
            fieldLabel: _T('Color'),
            completionConfig: {
                props: {
                    options: Object.entries(tagColorNameMap).map((obj) => ({ value: obj[0], label: obj[1] })),
                    styles: {
                        option: (styles, { data }) => ({ ...styles, ...dot(tagColorIconMap[data.value]) }),
                        singleValue: (styles, { data }) => ({ ...styles, ...dot(tagColorIconMap[data.value]) })
                    }
                }
            }
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            '$.color',
            '$.comments'
        ]
    }
];

const tagsColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        minWidth: 100
    }, {
        headerName: 'Location',
        field: '@loc',
        minWidth: 100
    }, {
        headerName: 'Color',
        cellRendererParams: {
            renderCell: params => {
                let color = params.value || '';
                let display = tagColorNameMap[color];
                let styleColor = tagColorIconMap[color];
                return <span><div style={{ backgroundColor: styleColor, display: 'inline-block', borderRadius: 10, height: 10, width: 10 }}></div> {display}</span>;
            }
        },
        valueGetter: params => params.data['color'],
        minWidth: 100
    }, {
        headerName: 'Comments',
        field: 'comments',
        minWidth: 100
    }
];

export default class Tags extends DGBaseViewer {
    serviceName = 'Objects/Tags';
    reduxId = 'tagsObjects';
    header = 'Tags';
    columns = tagsColumns;
    fields = tagsFields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.tag.entry';
    rbaPath = 'objects.tags';
}
