import React, { Component } from 'react';
import { ViewerManager } from '../../widgets';
import { Button } from "reactstrap";
import { FormContext } from '../../FormContext';
import { getStoreState } from '../../../utils/storeRegistry';
import { showModal, hideModal } from '../../../services/actions';
import PropTypes from 'prop-types';
import './ViewerDisplayActionBuilder.scss';
import Pan from '../../schema/Pan';

export class ViewerDisplayActionBuilder extends Component {
    constructor(props) {
        super(props);
        this.generateViewer(props);
        this.showViewer = this.showViewer.bind(this);
        this.hideViewer = this.hideViewer.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
    }

    generateViewer(props) {
        let { field } = props;
        let uiHint = field ? field.uiHint : {};
        let { viewerName, viewer, viewerProps, actionText, actionProps, disabled } = uiHint;
        viewer = viewer && viewer() || ViewerManager.getView(viewerName);
        this.state = {
            displayViewer: false,
            viewer: viewer,
            actionText: actionText || 'Add',
            actionProps: actionProps || {},
            viewerProps,
            disabled,
        };
    }

    showViewer() {
        let Viewer = this.state.viewer;
        let viewerProps = this.state.viewerProps || {};
        let windowSize = viewerProps.windowSize ? viewerProps.windowSize : 'md'; //size of the window
        let modalFormSize = viewerProps.formSize ? viewerProps.formSize : 'lg';
        let store = getStoreState();
        let configLocation = this.props.configLocation ? this.props.configLocation :
            viewerProps.configLocation ? viewerProps.configLocation :
                store.main && store.main.configLocation ? store.main.configLocation : {};
        const modalId = 'modal-' + Pan.id();
        let modal = {
            id: modalId,
            open: true,
            title: viewerProps.title || Viewer.name,
            size: windowSize,
            type: "Info",
            toggle: () => {
                hideModal(modalId);
            },
            modalBody: {
                component: Viewer,
                props: {
                    ...viewerProps,
                    modalId: modalId,
                    displayMode: viewerProps.displayMode || 'modal',
                    modalFormSize: modalFormSize,
                    modalSize: windowSize,
                    minRows: viewerProps.minRows || 1,
                    showFilter: viewerProps.showFilter === true,
                    configLocation: configLocation,
                    modalBodyHelperText: viewerProps.modalBodyHelperText
                }
            },
            modalBodyClassName: 'viewer-display-action',
            actions: [
                {
                    text: "Close",
                    color: "secondary",
                    action: () => {
                        hideModal(modalId);
                        this.afterSubmit();
                    }
                }
            ]
        };
        showModal(modal);
    }

    hideViewer() {
        this.setState({
            displayViewer: false,
        });
    }

    onCancel() {
        this.setState({
            displayViewer: false,
        });
    }

    afterSubmit(record) {
        this.setState({
            displayViewer: false,
        });
        this.props.afterSubmit ?
            this.props.afterSubmit(record) :
            this.state.viewerProps && this.state.viewerProps.afterSubmit ? this.state.viewerProps.afterSubmit(record) : '';
    }

    render() {
        return (
            <React.Fragment>
                <Button
                    size="sm"
                    onClick={(e) => {
                        this.showViewer();
                        e.target.blur();
                    }}
                    disabled={this.state.disabled}
                    {...this.state.actionProps}
                >
                    {this.state.actionText}
                </Button>
            </React.Fragment>
        );
    }
}

ViewerDisplayActionBuilder.contextType = FormContext;

ViewerDisplayActionBuilder.defaultProps = {};

if (process.env.NODE_ENV !== 'production') {
    ViewerDisplayActionBuilder.propTypes = {
        afterSubmit: PropTypes.func,
        configLocation: PropTypes.object,
        /**
         * uiHint properties
         * *viewerName* - Name of the viewer as registered in the ViewerManager
         * *viewerProps* - props to be passed in the ViewerManager
         * *actionText* - Text on the button
         * *actionProps* - Props to be passed to the button
         */
        field: PropTypes.shape({
            uiHint: PropTypes.shape({
                viewerName: PropTypes.string,
                viewerProps: PropTypes.shape({
                    afterSubmit: PropTypes.func,
                    windowSize: PropTypes.string,
                    displayCancel: PropTypes.bool,
                    configLocation: PropTypes.object,
                }),
                actionText: PropTypes.string,
                actionProps: PropTypes.object,
                disabled: PropTypes.bool
            })
        })
    };
}
