import { 
    LOAD_CONFIG_ITEMS, LOAD_EXTRA_INFO
} from 'service-lib';
import { Pan } from 'ui-lib';

const ACTION_HANDLERS = {
	[LOAD_CONFIG_ITEMS]: (state, { configItems, reduxStateId, successCallback }) => {
		let items = configItems && configItems.response ? configItems.response : {};
		successCallback && successCallback(items);
		if (reduxStateId && reduxStateId.locationPath === 'tpl') {
			return {
				...state,
				[reduxStateId.reduxId]: 
				{ ...state[reduxStateId.reduxId], ...items }
			};
		} else {
			return state;
		}
	},
	[LOAD_EXTRA_INFO]: (state, { reduxStateId, extraInfo }) => {
		if (reduxStateId && reduxStateId.locationPath === "tpl") {
			if (Pan.isDefined(state[reduxStateId.reduxId])) {
				return {
					...state,
					[reduxStateId.reduxId]: { ...state[reduxStateId.reduxId], extraInfo: { ...state[reduxStateId.reduxId]["extraInfo"], ...extraInfo } }
				};
			} else {
				return {
					...state,
					[reduxStateId.reduxId]: { ...state[reduxStateId.reduxId], extraInfo }
				};
			}
		} else {
			return state;
		}
    },
};

const initialState = {};

export const tplItemsReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
