import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pan from '../../schema/Pan';
import { FormContext, FormConsumer } from '../../FormContext';
import { TextWidget } from '../../widgets/TextWidget/TextWidget';
import { FormFeedbackWidget } from "../../widgets/FormFeedbackWidget/FormFeedbackWidget";

import {
    FormGroup,
    Col,
    FormText,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import {
    getFieldLabelComp,
    getFieldWidthClass,
    getFieldHelpString
} from '../../schema/utils';
import classnames from 'classnames';

import './InputGroupBuilder.scss';
import _ from 'lodash';
import { BaseFieldBuilder } from '../BaseBuilder/BaseFieldBuilder';
import { getPathValues, getPathValue } from '../../../utils/json';

export class InputGroupBuilder extends BaseFieldBuilder {
    static NAME = 'InputGroupBuilder';
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.state = {
            hideField: false,
            value: false,
            addOn: '',
        };
    }

    getAddOn = () => {
        const { field } = this.props;
        let separator = getPathValue(field, 'uiHint.separator') || '';
        let addOn = '';
        if (field.uiHint && field.uiHint.getAddOn) {
            addOn = field.uiHint.getAddOn();
        }
        addOn = separator + ((Pan.isDefined(addOn) && !Pan.isEmpty(addOn)) ? addOn : ((field.uiHint && field.uiHint.defaultAddOn) ? field.uiHint.defaultAddOn : ''));
        this.setState({
            addOn: addOn
        });
    }

    componentDidMount() {
        super.componentDidMount();
        this.getAddOn();
    }

    getInputGroupItems() {
        let {
            name,
            addonType,
            field,
            onBlur,
            onFocus,
            isEditorGridColumnEditor,
            disabled,
            showErrors,
            autoFocus,
        } = this.props;

        let textFieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor) + ' pr-0';
        let placeHolder = ''; //field.defaultValue;
        const value = this.state.value !== false ? this.state.value : this.getRecordValue();
        let maxLength = getPathValues(field, 'uiHint.maxLength') || '31';
        let pattern = getPathValues(field, 'uiHint.pattern') ? getPathValues(field, 'uiHint.pattern')[0] : undefined;
        let addOn = this.state.addOn;
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        addonType = addonType || getPathValue(field, "uiHint.addonType");
        if (addonType == 'prepend') {
            return (
                <InputGroup
                    id={this.id}
                    name={name}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disabled={disabled || this.state.disableField}
                    className={textFieldClassName}
                    autoFocus={autoFocus}
                >
                    <InputGroupAddon addonType="prepend">
                        {addOn}
                    </InputGroupAddon>
                    <TextWidget
                        id={this.id}
                        name={name}
                        value={value}
                        onChange={this.setRecordValue}
                        placeholder={placeHolder}
                        maxLength={maxLength}
                        pattern={pattern}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        disabled={disabled || this.state.disableField}
                        className={textFieldClassName}
                        error={error}
                        autoFocus={autoFocus}
                        showErrors={showErrors}
                    />
                </InputGroup>
            );
        } else {
            return (
                <InputGroup
                    id={this.id}
                    name={name}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disabled={disabled || this.state.disableField}
                    className={textFieldClassName}
                    autoFocus={autoFocus}
                >
                    <TextWidget
                        id={this.id}
                        name={name}
                        value={value}
                        onChange={this.setRecordValue}
                        placeholder={placeHolder}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        disabled={disabled || this.state.disableField}
                        className={textFieldClassName}
                        error={error}
                        autoFocus={autoFocus}
                        showErrors={showErrors}
                        maxLength={maxLength}
                        pattern={pattern}
                    />
                    <InputGroupAddon addonType="append">
                        {addOn}
                    </InputGroupAddon>{' '}
                </InputGroup>
            );
        }
    }

    renderInputGroup() {
        let {
            name,
            className,
            field,
            hideLabel,
            containerLabelWidthSize,
            isEditorGridColumnEditor,
            showErrors
        } = this.props;

        if (this.props.hidden || this.state.hideField || getPathValues(field, 'uiHint.hidden')) {
            return <div />;
        }
        let helpString = getFieldHelpString(field, this.context.formData);
        let LabelComp = getFieldLabelComp(
            field,
            name,
            isEditorGridColumnEditor,
            containerLabelWidthSize,
        );
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        return (
            <FormGroup className={classnames(className, `d-flex flex-row list-group ${required}`)} test_id={field.attrPath}>
                {!hideLabel && LabelComp}
                <Col>
                    {!this.state.hideField && this.getInputGroupItems()}
                    {error && showErrors && <FormFeedbackWidget target={this.id} feedback={error} />}
                    {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderInputGroup()}</FormConsumer>
            </React.Fragment>
        );
    }
}

InputGroupBuilder.contextType = FormContext;

InputGroupBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false,
};

if (process.env.NODE_ENV !== 'production') {
    InputGroupBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        hideLabel: PropTypes.bool,
        containerLabelWidthSize: PropTypes.string,
        isEditorGridColumnEditor: PropTypes.bool,
        showErrors: PropTypes.bool,
        hidden: PropTypes.bool,
        addonType: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        disabled: PropTypes.bool,
        autoFocus: PropTypes.func,
        cellInfo: PropTypes.object,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        filters: PropTypes.array,
        onChange: PropTypes.func,
        /**
		 * *defaultAddOn* - default value of addOn string to be appended or prepended"
         * *defaultValue* - sets the default value of the field.
         * 
         * UiHint properties :
         * *separator* - seperates addOn and value.
         * *allowBlank* - adds an error if field is blank
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * Takes precedence over containerLabelWidthSize
         * *fieldWidthSize* - The size of the field - ranges from 1 to 12.
         * *fieldLabel* - adds a label to the field
         * *hideLabel* - hides the label
         * *showHelpString* - if true,will show custom help string/message
         * *helpstring* - set the help string/mesage
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
         * The string values available are -
         * noAllowBlank,objectName,isNumber,validNumber,validNumberRangeList,rangeList,rangedInt,isIpV4Address,isIpV4AddressMask,ipAndIntegerSubnetMaskV4orV6,
         * octectsToLong,isIpV4Netmask,isIpV6Address,isIpV6Netmask,isIpV6AddressMask,isIpAddress,inRange,ipAndIntegerSubnetMaskV4,ipAndIntegerSubnetMaskV6,
         * isIpAddressMask,ipRange,multiVtype.
         * 
         * *getAddOn* - function that returns a string which is the addOn i.e The string is either appended or prepended to the value.
         * eg: getAddOn: function (bprops) {
                return (getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn') || '');
            },
         * Association properties
         * *updateFormData* - update formData to include the addOn string to the state value.
         * eg:   updateFormData: function (values, formData, filters, key) {
                    if (values && values[0]) {
                        return { '$.@name': values[0] + '.' + getPathValue(getStoreState(), 'gpcs.MU_FQDN.msg.gpfqdn') };
                    }
                }
		 */
        field: PropTypes.shape({
            defaultAddOn: PropTypes.string,
            defaultValue: PropTypes.string,
            uiHint: PropTypes.shape({
                getAddOn: PropTypes.func,
                separator: PropTypes.string,
                allowBlank: PropTypes.bool,
                labelWidthSize: PropTypes.string,
                fieldLabel: PropTypes.string,
                hideLabel: PropTypes.string,
                hidden: PropTypes.bool,
                fieldWidthSize: PropTypes.string,
                showHelpString: PropTypes.bool,
                helpString: PropTypes.string,
                vtype: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                customValidation: PropTypes.func,
                association: PropTypes.shape({
                    availHide: PropTypes.func,
                    availDisable: PropTypes.func,
                    updateFormData: PropTypes.func,
                }),
            })
        })
    };
}
