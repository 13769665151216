import _ from 'lodash'

export const EMPTY_SCHEMA = {}

export class LogSchema {
  constructor(schema = EMPTY_SCHEMA) {
    this.schema = schema instanceof LogSchema ? schema.schema : schema
  }

  is(schema) {
    return schema === this || schema === this.schema
  }

  isEqual(schema) {
    const schemaData = schema.schema || schema
    return _.isEqual(schemaData, this.schema)
  }

  /**
   * get log type
   * @example .get('panw', 'traffic') given vendor and type in two args
   * @example .get(['panw', 'traffic']) given both as array
   * @example .get('panw.traffic') given both as path string
   * @param {string|string[]} vendor vendor string or log type path
   * @param {string} [type] log type string, omit if log type already included in vender
   * @return {object?} logType
   */
  get(vendor, type) {
    let pathes = type && vendor ? `${vendor}.${type}` : vendor
    if (!pathes) {
      return undefined
    }
    pathes = pathes.toLowerCase().split('.')
    if (pathes.length > 1) {
      pathes.splice(1, 0, 'content')
    }
    return _.get(this.schema, pathes)
  }

  getFields(vendor, type) {
    const logType = this.get(vendor, type)
    // do not filter here, unless we can cache the result array
    // so that the same vendor/type will always return the same array instance
    return logType && logType.fields
  }

  forEachLogType(fn) {
    _.each(this.schema, (sub, vendor) => {
      _.each(sub.content, (logType, type) => {
        fn(logType, type, vendor, sub)
      })
    })
  }

  forEachVendor(fn) {
    _.each(this.schema, fn)
  }

  mapVendor(fn) {
    return Object.keys(this.schema).map(vendor => fn(this.schema[vendor], vendor))
  }

  getTreeData() {
    if (this.treeData) {
      return this.treeData
    }
    this.treeData = this.mapVendor(({ content, displayName }, vendor) => {
      const children = _.map(content, (origChild) => {
        if (origChild.displayName && origChild.logType) {
          const id = `${vendor}.${origChild.logType}`
          return {
            title: origChild.displayName,
            displayName: `${displayName}/${origChild.displayName}`,
            value: id,
            key: id,
          }
        }
      })
      return {
        disabled: true,
        title: displayName,
        displayName,
        value: vendor,
        key: vendor,
        children
      }
    })
    return this.treeData
  }

  static getField(fields, name) {
    return name ? _.find(fields, { name }) : undefined
  }

  static getFieldEnum(fields, name) {
    const field = Array.isArray(fields) ? LogSchema.getField(fields, name) : fields
    if (!field || !field.enum || !Array.isArray(field.enum)) {
      return undefined
    }
    const quoted = field.type === 'enum'
    // convert
    return field.enum.map(v => {
      const name = Object.keys(v)[0]
      if (!name) {
        return null
      }
      return {
        ...v[name],
        name,
        quoted,
      }
    })
  }

  static EMPTY_SCHEMA = EMPTY_SCHEMA
  static from(schema = EMPTY_SCHEMA) {
    if (schema instanceof LogSchema) {
      return schema
    }
    return new LogSchema(schema)
  }
}
