import './hipObjects.css';
import { getPathValue, Pan } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const hipObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        }
    },
    {
        name: '$',
        childrenNames: [
            'Category'
        ],
        uiHint: {
            labelWidthSize: 3
        }
    },
    {
        name: 'Category',
        childrenNames: [
            'General',
            '$.mobile-device',
            '$.patch-management',
            '$.firewall',
            '$.anti-malware',
            '$.disk-backup',
            '$.disk-encryption',
            '$.data-loss-prevention',
            'CertificateTab',
            '$.custom-checks'
        ],
        uiHint: {
            builder: "TabBuilder",
            vertical: true,
            fastRender: true
        }
    },
    {
        name: 'General',
        childrenNames: [
            'Configuration',
            '$.host-info.criteria',
            '$.network-info.criteria'
        ]
    },
    {
        name: 'Configuration',
        childrenNames: [
            'name',
            'location',
            '$.description'
        ],
        uiHint: {
            builder: 'FieldSetBuilder'
        }
    },
    {
        name: '$.host-info.criteria',
        childrenNames: [
            '$.host-info.criteria.managed',
            '$.host-info.criteria.domain',
            '$.host-info.criteria.os',
            '$.host-info.criteria.client-version',
            '$.host-info.criteria.host-name',
            '$.host-info.criteria.host-id',
            '$.host-info.criteria.serial-number'
        ],
        uiHint: {
            fieldLabel: _T('Host Info'),
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.host-info.criteria.managed',
        pruneIfNotInSchema: true,
        uiHint: {
            useHelpStringAsDisplay: true,
            largeSelectionCount: 1,
            fieldLabel: _T('Managed')
        }
    },
    {
        name: '$.host-info.criteria.domain.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.host-info.criteria.client-version.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.host-info.criteria.host-name.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.host-info.criteria.serial-number.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.host-info.criteria.os.contains.choice',
        uiHint: {
            hideLabel: true,
            singleLineLayout: true
        }
    },
    {
        name: '$.host-info.criteria.os.choice',
        uiHint: {
            largeSelectionCount: 1,
            fieldLabel: 'OS',
            singleLineLayout: true
        }
    },
    {
        name: '$.host-info.criteria.host-id.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.network-info.criteria',
        uiHint: {
            fieldLabel: _T('Mobile Device Network Info'),
            builder: 'FieldSetBuilder'
        }
    },
    {
        name: '$.network-info.criteria.network',
        uiHint: {
            largeSelectionCount: 1,
            showHelpString: _T('This match criteria applies to mobile devices only.')
        }
    },
    {
        name: '$.network-info.criteria.network.is',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    // endregion # General
    // region # $.mobile-device
    {
        name: '$.mobile-device',
        childrenNames: ['MobileDevicesContainer'],
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true,
            showHelpString: _T('This match criteria requires integration with GlobalProtect Mobile Security Manager.')
        }
    },
    {
        name: 'MobileDevicesContainer',
        childrenNames: [
            'MobileDevices_Device',
            'MobileDevices_Settings',
            '$.mobile-device.criteria.applications'
        ],
        uiHint: {
            builder: 'TabBuilder',
            autoHeight: false,
            height: 300
        }
    },
    {
        name: 'MobileDevices_Device',
        childrenNames: [
            '$.mobile-device.criteria.serial-number',
            '$.mobile-device.criteria.model',
            '$.mobile-device.criteria.tag',
            '$.mobile-device.criteria.phone-number',
            '$.mobile-device.criteria.imei'
        ],
        uiHint: {
            fieldLabel: _T('Device')
        }
    },
    {
        name: '$.mobile-device.criteria.model.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.mobile-device.criteria.tag.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.mobile-device.criteria.phone-number.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.mobile-device.criteria.imei.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.mobile-device.criteria.last-checkin-time',
        uiHint: {
            fieldLabel: _T('Time Since Last Check-in')
        }
    },
    {
        name: 'MobileDevices_Settings',
        childrenNames: [
            '$.mobile-device.criteria.passcode-set',
            '$.mobile-device.criteria.managed',
            '$.mobile-device.criteria.jailbroken',
            '$.mobile-device.criteria.disk-encrypted',
            '$.mobile-device.criteria.last-checkin-time',
            '$.mobile-device.criteria.device-roaming'
        ],
        uiHint: {
            fieldLabel: _T('Settings')
        }
    },
    {
        name: '$.mobile-device.criteria.passcode-set',
        uiHint: {
            useHelpStringAsDisplay: true,
            largeSelectionCount: 1,
            fieldLabel: _T('Passcode')
        }
    },
    {
        name: '$.mobile-device.criteria.jailbroken',
        uiHint: {
            largeSelectionCount: 1,
            fieldLabel: _T('Rooted/Jailbroken')
        }
    },
    {
        name: '$.mobile-device.criteria.disk-encrypted',
        uiHint: {
            largeSelectionCount: 1,
            fieldLabel: _T('Disk Encryption')
        }
    },
    {
        name: '$.mobile-device.criteria.last-checkin-time',
        uiHint: {
            largeSelectionCount: 1,
            fieldLabel: _T('Time Since Last Check-in')
        }
    },
    {
        name: 'CertificateTab',
        childrenNames: ['$.certificate'],
        uiHint: {
            fieldLabel: _T('Certificate')
        }
    },
    {
        name: '$.certificate',
        childrenNames: ['$.certificate.criteria.certificate-profile', 'CertificateAttributes'],
        uiHint: {
            fieldLabel: _T('Validate Certificate'),
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.certificate.criteria.certificate-profile',
        uiHint: {
            builder: 'CompletionBuilder',
            completionConfig: {
                actions: [
                    {
                        text: 'Add Certificate Profile',
                        rbaPath: 'device.certificate-management.certificate-profile',
                        viewerProps: {
                            windowSize: 'xl',
                            title: _T('Certificate Profile')
                        },
                        viewerName: 'CertificateProfileRecordViewer'
                    }
                ]
            }
        }
    }, {
        name: 'CertificateAttributes',
        childrenNames: ['$.certificate.criteria.certificate-attributes'],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('Certificate Attributes'),
            // association: [
            //     {
            //         match: {
            //             evaluate: 'fieldEvt',
            //             field: '$.certificate.criteria.certificate-profile',
            //             event: 'change'
            //         },
            //         exec: {
            //             evaluate: function() {
            //                 var dataExtractor = Pan.base.json.path(this, '$.__component.__pdefaults.__dataExtractor', null);
            //                 var certPr = dataExtractor ? dataExtractor('$.certificate.criteria.certificate-profile') : "";
            //                 if (Ext.isEmpty(certPr)) {
            //                     this.__component.disable(true);
            //                 } else {
            //                     this.__component.enable();
            //                 }
            //             }
            //         }
            //     }
            // ]
        }
    }, {
        name: '$.certificate.criteria.certificate-attributes.entry',
        uiHint: {
            useCheckBoxSelection: true
        }
    }, {
        name: '$.certificate.criteria.certificate-attributes.entry.*.@name',
        uiHint: {
            columnConfig: {
                headerName: _T('Certificate Field')
            }
        }
    }, {
        name: '$.mobile-device.criteria.jailbroken',
        uiHint: {
            useHelpStringAsDisplay: true,
            fieldLabel: _T('Rooted/Jailbroken')
        }
    },
    {
        name: '$.mobile-device.criteria.disk-encrypted',
        uiHint: {
            useHelpStringAsDisplay: true,
            fieldLabel: _T('Disk Encryption')
        }
    },
    {
        name: 'ApplicationsCriteriaInner',
        childrenNames: ['ApplicationCriteriaInnerCriteria', 'ApplicationCriteriaInnerInclude'],
        uiHint: {
            builder: 'TabBuilder'
        }
    },
    {
        name: 'ApplicationCriteriaInnerCriteria',
        childrenNames: ['$.mobile-device.criteria.applications.has-malware'],
        uiHint: {
            fieldLabel: _T('Criteria')
        }
    },
    {
        name: 'ApplicationCriteriaInnerInclude',
        childrenNames: ['$.mobile-device.criteria.applications.has-unmanaged-app', '$.mobile-device.criteria.applications.includes'],
        uiHint: {
            fieldLabel: _T('Include')
        }
    }, {
        name: '$.mobile-device.criteria.applications.has-unmanaged-app',
        uiHint: {
            fieldLabel: _T('Has apps that are not managed'),
            largeSelectionCount: 1
        }
    },
    {
        name: '$.mobile-device.criteria.applications',
        childrenNames: ['ApplicationsCriteriaInner'],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T("Apps")
        }
    },
    {
        name: '$.mobile-device.criteria.applications.has-malware',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.mobile-device.criteria.applications.has-malware.yes.excludes.entry.*.@name',
        uiHint: {
            columnConfig: {
                hidden: true,
                hideable: false
            }
        }
    },
    {
        name: "$.mobile-device.criteria.applications.has-malware.yes.excludes.entry",
        uiHint: {
            hasGridFilter: false,
            useCheckBoxSelection: true,
            height: 120,
            fieldLabel: _T('Exclude'),
            labelAlign: 'top',
            // bbar: ['addRecordWithHiddenKeyColumnAction', 'deleteRecordAction']
        }
    },
    {
        name: '$.mobile-device.criteria.applications.includes.entry.*.@name',
        uiHint: {
            columnConfig: {
                hidden: true,
                hideable: false
            }
        }
    },
    {
        name: '$.mobile-device.criteria.applications.includes.entry',
        uiHint: {
            hasGridFilter: false,
            useCheckBoxSelection: true,
            height: 160,
        }
    },
    {
        name: 'andStub',
        saveMap: undefined,
        defaultValue: _T('and'),
        uiHint: {
            xtype: 'pan-displayfield',
            style: 'padding-bottom:5px',
            fieldLabel: ''
        }
    },
    {
        name: 'PatchMgmt_Vendor',
        childrenNames: ['$.patch-management.vendor', '$.patch-management.exclude-vendor'],
        uiHint: {
            fieldLabel: _T('Vendor')
        }
    },
    {
        name: '$.patch-management.vendor.entry',
        uiHint: {
            hasGridFilter: true,
            editorType: 'record-form',
            dialogSize: 'md',
            useColumns: [
                '$.patch-management.vendor.entry.*.@name',
                '$.patch-management.vendor.entry.*.product'
            ],
            childrenNames: [
                '$.patch-management.vendor.*.@name',
                '$.patch-management.vendor.*.product'
            ]
        }
    }, {
        name: 'PatchMgmt',
        childrenNames: ['$.patch-management.criteria', 'PatchMgmt_Vendor'],
        uiHint: {
            builder: 'TabBuilder'
        }
    },
    {
        name: '$.patch-management.criteria',
        childrenNames: [
            '$.patch-management.criteria.is-installed',
            '$.patch-management.criteria.is-enabled',
            '$.patch-management.criteria.missing-patches'
        ],
        uiHint: {
            // layout: [{
            //     '$.patch-management.criteria.is-enabled': 'col-7',
            //     '$.patch-management.criteria.is-installed': 'col-5'
            // }, {
            //     '$.patch-management.criteria.missing-patches': 'col-12'
            // }]
        }
    },
    {
        name: '$.patch-management.criteria.is-enabled',
        uiHint: {
            largeSelectionCount: 1,
            association: {
                fields: ['$.patch-management.criteria.is-installed'],
                availDisable: function (values, formData, filters, key) {
                    return (values && values[0] && values[0] === 'no' ? true : false);
                }
            }
        }
    },
    {
        name: '$.patch-management.criteria.missing-patches',
        childrenNames: [
            '$.patch-management.criteria.missing-patches.severity',
            '$.patch-management.criteria.missing-patches.check',
            '$.patch-management.criteria.missing-patches.patches'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            layout: [{
                '$.patch-management.criteria.missing-patches.severity': 'col-12'
            }, {
                '$.patch-management.criteria.missing-patches.check': 'col-12'
            }, {
                '$.patch-management.criteria.missing-patches.patches': 'col-12'
            }]
        }
    },
    {
        name: '$.patch-management.criteria.missing-patches.check',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.patch-management.criteria.missing-patches.severity.choice',
        uiHint: {
            largeSelectionCount: 1,
            singleLineLayout: true
        }
    },
    {
        name: '$.patch-management.criteria.missing-patches.patches.member',
        uiHint: {
            // height: Pan.objects.HIPObjectsViewer.gridHeight - 65
        }
    },
    {
        name: '$.patch-management',
        childrenNames: ['PatchMgmt'],
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.firewall.criteria',
        childrenNames: [
            '$.firewall.criteria.is-installed',
            '$.firewall.criteria.is-enabled'],
        uiHint: {
            // layout: [{
            //     '$.firewall.criteria.is-enabled': 'col-7',
            //     '$.firewall.criteria.is-installed': 'col-5'
            // }]
        }
    },
    {
        name: '$.firewall.criteria.is-enabled',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.firewall',
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.data-loss-prevention.criteria',
        childrenNames: [
            '$.data-loss-prevention.criteria.is-installed',
            '$.data-loss-prevention.criteria.is-enabled'],
        uiHint: {
            // layout: [{
            //     '$.data-loss-prevention.criteria.is-enabled': 'col-7',
            //     '$.data-loss-prevention.criteria.is-installed': 'col-5'
            // }]
        }
    },
    {
        name: '$.data-loss-prevention.criteria.is-enabled',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.data-loss-prevention',
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.anti-malware',
        uiHint: {
            fieldLabel: _T('Anti-Malware'),
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.anti-malware.criteria',
        childrenNames: [
            '$.anti-malware.criteria.is-installed',
            '$.anti-malware.criteria.real-time-protection',
            '$.anti-malware.criteria.virdef-version',
            '$.anti-malware.criteria.product-version',
            '$.anti-malware.criteria.last-scan-time',
        ]
    },
    {
        name: '$.anti-malware.criteria.real-time-protection',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.anti-malware.criteria.virdef-version',
        uiHint: {
            fieldLabel: _T("Virus Definition Version")
        }
    },
    {
        name: '$.anti-malware.criteria.last-scan-time.choice',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.anti-malware.criteria.product-version.choice',
        uiHint: {
            singleLineLayout: true
        }
    },
    {
        name: '$.anti-malware.criteria.virdef-version.choice',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.disk-backup.criteria.last-backup-time.choice',
        uiHint: {
            largeSelectionCount: 1
        }
    },
    {
        name: '$.custom-checks.criteria.plist.entry',
        uiHint: {
            editorType: 'record-form',
            useColumns: [
                '$.custom-checks.criteria.plist.entry.*.@name',
                '$.custom-checks.criteria.plist.entry.*.negate'
            ]
        }
    },
    {
        name: '$.custom-checks.criteria.plist.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.custom-checks.criteria.plist.entry.*.key.entry',
        uiHint: {
            editorType: 'record-form',
            useColumns: [
                '$.custom-checks.criteria.plist.entry.*.key.entry.*.@name',
                '$.custom-checks.criteria.plist.entry.*.key.entry.*.value',
                '$.custom-checks.criteria.plist.entry.*.key.entry.*.negate'
            ]
        }
    },
    {
        name: '$.custom-checks.criteria.plist.entry.*.key.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.custom-checks.criteria.registry-key.entry.*.registry-value.entry',
        uiHint: {
            editorType: 'record-form',
            useColumns: [
                '$.custom-checks.criteria.registry-key.entry.*.registry-value.entry.*.@name',
                '$.custom-checks.criteria.registry-key.entry.*.registry-value.entry.*.value-data',
                '$.custom-checks.criteria.registry-key.entry.*.registry-value.entry.*.negate'
            ]
        }
    },
    {
        name: '$.custom-checks.criteria.registry-key.entry.*.registry-value.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.custom-checks.criteria.registry-key.entry',
        uiHint: {
            editorType: 'record-form',
            useColumns: [
                '$.custom-checks.criteria.registry-key.entry.*.@name',
                '$.custom-checks.criteria.registry-key.entry.*.default-value-data',
                '$.custom-checks.criteria.registry-key.entry.*.negate'
            ]
        }
    },
    {
        name: '$.custom-checks.criteria.registry-key.entry.*.@name',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                valueGetter: params => {
                    return getPathValue(params.data, "@name");
                }
            }
        }
    },
    {
        name: '$.custom-checks.criteria.registry-key.entry.*.default-value-data',
        uiHint: {
            fieldLabel: _T('(Default) Value Data')
        }
    },
    {
        name: '$.custom-checks.criteria',
        uiHint: {
            builder: 'TabBuilder'
        }
    },
    {
        name: 'CustomChecksCriteria',
        childrenNames: ['$.custom-checks.criteria']
    },
    {
        name: '$.custom-checks',
        childrenNames: ['CustomChecksCriteria'],
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: '$.disk-backup',
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    },
    {
        name: 'DiskEnc_Vendor',
        childrenNames: ['$.disk-encryption.vendor', '$.disk-encryption.exclude-vendor'],
        uiHint: {
            fieldLabel: _T('Vendor')
        }
    },
    {
        name: 'DiskEnc',
        childrenNames: ['$.disk-encryption.criteria', 'DiskEnc_Vendor'],
        uiHint: {
            builder: 'TabBuilder'
        }
    },
    // PASTE HERE
    {
        name: '$.disk-encryption.criteria.encrypted-locations.entry',
        uiHint: {
            editorType: 'record-form',
            dialogSize: 'lg',
            useColumns: [
                '$.disk-encryption.criteria.encrypted-locations.entry.*.@name',
                '$.disk-encryption.criteria.encrypted-locations.entry.*.encryption-state'
            ]
        }
    },
    {
        name: '$.disk-encryption.criteria.encrypted-locations.entry.*.@name',
        looseMembership: true,
        uiHint: {
            columnConfig: {
                type: 'clickableColumn'
            }
        }
    },
    {
        name: '$.disk-encryption.criteria.encrypted-locations.entry.*.encryption-state',
        uiHint: {
            fieldLabel: _T("State"),
            columnConfig: {
                valueGetter: params => {
                    const stateValue = Object.keys(params.data["encryption-state"]);
                    const isValue = Object.values(params.data["encryption-state"]);
                    return stateValue + " " + isValue;
                }
            }
        }
    },
    {
        name: '$.disk-encryption.criteria.encrypted-locations.entry.*.encryption-state.is',
        proxySelectionCommonName: "State",
        uiHint: {
            columnConfig: {
                headerName: _T('Condition'),
                editor: {}
            }
        }
    },
    {
        name: '$.disk-encryption.criteria.encrypted-locations.entry.*.encryption-state.is-not',
        proxySelectionCommonName: _T("State"),
        uiHint: {
            columnConfig: {
                headerName: _T('Condition'),
                editor: {}
            }
        }
    },
    {
        name: '$.disk-encryption.criteria.is-installed',
        uiHint: {
            hideLabel: true
        }
    },
    {
        name: '$.disk-encryption',
        childrenNames: ['DiskEnc'],
        uiHint: {
            builder: 'FieldSetBuilder',
            checkboxToggle: true
        }
    }
];

const getCategoryDisplayOrder = () => {
    return ['host-info',
        'network-info',
        'mobile-device',
        'patch-management',
        'firewall',
        'anti-malware',
        'disk-backup',
        'disk-encryption',
        'data-loss-prevention',
        'certificate',
        'custom-checks'
    ];
};

const renderMobileDevices = (items, criteria) => {
    var val = "";
    var i = 0;
    var processObject = function (obj) {
        var theStr = "";
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                if (Pan.isObject(obj[attr])) {
                    if (obj[attr].entry) {
                        theStr += attr + " " + obj[attr].entry.length + " ";
                        continue;
                    }
                    theStr += attr + " " + processObject(obj[attr]);
                }
                if (Pan.isString(obj[attr])) {
                    theStr += attr + " " + Pan.htmlEncode(obj[attr]);
                }
            }
        }
        return theStr;
    };
    var cL1;
    for (cL1 in criteria) {
        if (criteria.hasOwnProperty(cL1)) {
            if (i) {
                items.push(val);
                val = '';
            }
            val += cL1 + " ";
            i++;
            if (Pan.isObject(criteria[cL1])) {
                val += processObject(criteria[cL1]);
            } else {
                // not object - i.e. boolean yes/no
                val += Pan.htmlEncode(criteria[cL1]);
            }
        }
    }
    items.push(val);
    return items;
};

const criteriaColumnDisplayLogic = (value, category) => {
    var val = "";
    var items = [];
    if (Pan.isEmpty(value[category])) {
        return [];
    }
    let criteria = value[category].criteria ? value[category].criteria : undefined;
    if (category === "mobile-device" || category === "network-info") {
        return renderMobileDevices(items, criteria);
    }
    var cL1;
    for (cL1 in criteria) {
        if (criteria.hasOwnProperty(cL1)) {
            val += cL1 + " ";
            var numCond;
            if (criteria[cL1].entry) {
                numCond = criteria[cL1].entry.length;
                // plural
                // val += '<img border="0" title="' + numCond + ' configured" src="/images/allow.png">';
            } else if (Pan.isObject(criteria[cL1])) {
                var cL2;
                var xss_cL2;
                var xss_cL3;
                for (cL2 in criteria[cL1]) {
                    xss_cL2 = Pan.htmlEncode(cL2);
                    if (Pan.isObject(criteria[cL1][cL2])) {
                        if (criteria[cL1][cL2].entry) {
                            numCond = criteria[cL1][cL2].entry.length;
                            val += " " + xss_cL2 + " " + numCond + " condition";
                            // plural
                            val += numCond > 1 ? "s" : "";
                        } else if (cL2 === "not-available") {
                            val += " " + xss_cL2;
                        }

                        var cL3;
                        var cL3Str = "";
                        var escaped_Criteria;
                        for (cL3 in criteria[cL1][cL2]) {
                            if (criteria[cL1][cL2].hasOwnProperty(cL3)) {
                                xss_cL3 = Pan.htmlEncode(cL3);
                                if (cL1 === "missing-patches") {
                                    items.push(val);
                                    val = "";
                                }
                                if (cL2 === "within" || cL2 === "not-within") {
                                    escaped_Criteria = Pan.htmlEncode(criteria[cL1][cL2][cL3]);
                                    if (cL3 === "days" || cL3 === "hours")
                                        cL3Str += xss_cL2 + " " + escaped_Criteria + " " + xss_cL3;
                                    else cL3Str += xss_cL2 + " " + xss_cL3 + " " + escaped_Criteria;
                                } else if (cL1 === "missing-patches" && cL2 === "patches") {
                                    numCond = criteria[cL1][cL2].member.length;
                                    cL3Str +=
                                        numCond +
                                        " patch" +
                                        (numCond > 1 ? "es" : "") +
                                        " configured";
                                } else {
                                    escaped_Criteria = Pan.htmlEncode(criteria[cL1][cL2][cL3]);
                                    cL3Str += xss_cL2 + " " + xss_cL3 + " " + escaped_Criteria;
                                }
                            }
                        }
                        val += cL3Str;
                    } else if (criteria[cL1][cL2] !== "") {
                        if (cL1 === "missing-patches") {
                            items.push(val);
                            val = "";
                        }
                        val += " " + xss_cL2 + " " + Pan.htmlEncode(criteria[cL1][cL2]);
                    }
                }
            } else {
                // not object - i.e. boolean yes/no
                val += Pan.htmlEncode(criteria[cL1]);
            }
        }
        items.push(val);
        val = "";
    }
    return items;
};

const vendorColumnDisplayLogic = (value, category) => {
    let val = [];
    if (Pan.isEmpty(value[category])) {
        return [];
    }
    let vendor = value[category].vendor ? value[category].vendor.entry : undefined;
    if (value[category]["exclude-vendor"] === "yes") {
        val.push(_T("Excluded below vendors"));
    }
    if (vendor) {
        for (let v = 0; v < vendor.length; v++) {
            val.push(vendor[v]["@name"] + ":");
            let product = vendor[v].product ? vendor[v].product.member : undefined;
            if (product) {
                for (let p = 0; p < product.length; p++) {
                    val.push(product[p]);
                }
            }
        }
    }
    return val;
};

const connectedColumns = (value, columnName) => {
    let items = [];
    let categoryOrder = getCategoryDisplayOrder();
    for (let c = 0; c < categoryOrder.length; c++) {
        let category = categoryOrder[c];
        if (!Pan.isEmpty(value[category])) {
            let criteria = criteriaColumnDisplayLogic(value, category);
            let vendor = vendorColumnDisplayLogic(value, category);
            items.push({
                'category': category,
                'criteria': criteria,
                'vendor': vendor,
                'maxLen': Math.max(1, criteria.length, vendor.length) // 1 -> where criteria and vendor are empty arrays
            });
        }
    }
    return createDataStructure(items, columnName);
};

const createDataStructure = (items, columnName) => {
    let data = [];
    for (let item of items) {
        let content = [];
        if (Array.isArray(item[columnName])) {
            content = item[columnName];
        } else {
            content.push(item[columnName]);
        }
        while (content.length < item.maxLen) {
            content.push(" "); // INFO: add dot or a dummy for perfect viewing in UI
        }
        data.push(content);
    }
    return data;
};

const hipObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.vsys.entry.address.entry',
    minWidth: 80
}, {
    headerName: 'Location',
    field: '@loc',
    minWidth: 150
}, {
    headerName: 'Category',
    valueGetter: (params) => connectedColumns(params.data, 'category'),
    minWidth: 100
}, {
    headerName: 'Criteria',
    valueGetter: (params) => connectedColumns(params.data, 'criteria'),
    minWidth: 200
}, {
    headerName: 'Vendor',
    valueGetter: (params) => connectedColumns(params.data, 'vendor'),
    minWidth: 150
}, {
    headerName: 'Description',
    field: 'description',
    minWidth: 150
}];

export default class HIPObjects extends DGBaseViewer {

    serviceName = 'Objects/GlobalProtectHIPObjects';
    reduxId = 'hipObjects';
    header = 'HIP Objects';
    widerForm = true;
    columns = hipObjectColumns;
    fields = hipObjectFields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.hip-objects.entry';
    rbaPath = 'objects.global-protect.hip-objects'
}