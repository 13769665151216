import { getPathValue, getStoreState } from 'ui-lib';

const _T = str => str;

const gpcsCommonColumnConfig = {
    name: {
        type: ['nameColumn', 'clickableColumn']
    },
    product: {
        headerName: _T('Product'),
        id: 'product',
        valueGetter: params => getPathValue(params.data, 'stage1.product'),
    },
    region: {
        headerName: _T('Location'),
        valueGetter: params => {
            let _val = getPathValue(params.data, 'stage1.region');
            if (getStoreState().gpcs.regionInfo) {
                let _region = getStoreState().gpcs.regionInfo.find(r => r['@value'] === _val);
                if (_region) {
                    _val = _region['@display'];
                }
            }
            return _val;
        },
        minWidth: 150
    },
    bandwith: {
        headerName: _T('Bandwidth'),
        valueGetter: params => {
            let bw = getPathValue(params.data, 'stage1.bandwidth');
            let bws = bw ? bw.replace("FWAAS-", "") : "";
            return bws;
        },
        minWidth: 150
    },
    peerIp: {
        headerName: _T('Peer IP'),
        valueGetter: params => {
            const val = getPathValue(params.data, 'stage1.authentication.pre-shared-key.peer-id');
            return val ? val.type + ':' + val.id : '';
        },
        minWidth: 200
    },
    tunnelMonitoringIp: {
        headerName: _T('Tunnel Monitoring IP'),
        valueGetter: params => getPathValue(params.data, 'stage1.tunnel-monitor.destination-ip'),
        minWidth: 200
    },
    secondaryTunnelMonitoringIp: {
        headerName: _T('Tunnel Monitoring IP'),
        valueGetter: params => getPathValue(params.data, 'stage2.tunnel-monitor.destination-ip'),
        minWidth: 200
    }
};

export default gpcsCommonColumnConfig;