import React from "react";
import { Component } from "react";
import { ExtraInfoContext } from "ui-lib";
import _ from "lodash";

export class PolicyAddressColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getAddressHTML = (addressItem, negateStyle) => {
		if (!addressItem.value || addressItem.value === 'any') {
			return addressItem.value;
		}
		if (addressItem.type === "region") {
			return (
				<div key={addressItem.value} style={{ textDecoration: negateStyle }}>
					<img src="/images/treemenu/regions.png" alt="Regions" />
					{addressItem.value}
				</div>
			);
		} else if (addressItem.type === "address-group") {
			return (
				<div key={addressItem.value} style={{ textDecoration: negateStyle }}>
					<img src="/images/treemenu/address-groups.png" alt="Address groups" />
					{addressItem.value}
				</div>
			);
		} else if (addressItem.type === "external-list") {
			return (
				<div key={addressItem.value} style={{ textDecoration: negateStyle }}>
					<img src="/images/treemenu/external-dynamic-lists.png" alt="External List" />
					{addressItem.value}
				</div>
			);
		} else {
			return (
				<div key={addressItem.value} style={{ textDecoration: negateStyle }}>
					<img src="/images/treemenu/addresses.png" alt="Address" />
					{addressItem.value}
				</div>
			);
		}
	};

	renderAddress = (data, negateStyle) => {
		let formattedAddresses = [];
		const policiesAddresses = _.get(this.context, "extraInfo.policiesAddresses", []);
		data.forEach(item => {
			let foundItem = policiesAddresses.find(element => element.value === item);
			foundItem = foundItem ? foundItem : { value: item, type: "address" };
			formattedAddresses.push(this.getAddressHTML(foundItem, negateStyle));
		});
		return formattedAddresses;
	};

	render() {
		return <div>{this.renderAddress(this.props.data, this.props.negateStyle)}</div>;
	}
}
PolicyAddressColumn.contextType = ExtraInfoContext;
