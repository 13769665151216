export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

import { getStore } from '../utils/storeRegistry';

export const showModal = (modal) => {
    const { dispatch } = getStore();
    dispatch({
        type: SHOW_MODAL,
        modal
    })
}

export const hideModal = (id) => {
    const { dispatch } = getStore();
    dispatch({
        type: HIDE_MODAL,
        id
    });
};

export const updateModal = (id, props) => {
    const { dispatch } = getStore();
    dispatch({
        type: UPDATE_MODAL,
        id,
        props
    });
};

export const startLoading = renderTo => ({
	type: SHOW_LOADING,
	renderTo
});

export const finishLoading = () => ({
	type: HIDE_LOADING
});