import React, { Component } from 'react';
import './DetailListWidget.scss';

const COUNT_PER_COLUMN = 13;

export class DetailListWidget extends Component {
    constructor(props) {
        super(props);
    }

    renderDetail() {
        let columns = [];
        let regions = this.props.mapRegionInfo[this.props.selectArea].regions;
        let columnNumber = Math.ceil(regions.length / COUNT_PER_COLUMN);
        for (var i = 0; i < columnNumber; i++) {
            columns.push([]);
        }
        let checkboxes = regions.map(region => {
            return (
                <div key={`check_box_${region.name}`}>
                    <input
                        type="checkbox"
                        id={region.name}
                        checked={this.props.data.includes(region.name)}
                        onChange={event => this.props.onDetailListCheckboxChange(event.target.checked, region.name)}
                    />
                    {` ${region['display_name']}`}
                </div>
            )
        });
        checkboxes.forEach((chk, idx) => {
            columns[Math.floor(idx / COUNT_PER_COLUMN)].push(chk);
        });
        return (
            <>
                <div className="selectArea">{this.props.selectArea}</div>
                <div className="checkbox-groups">
                    {
                        columns.map((col, idx) => {
                            return (
                                <div key={`checkbox-group-${idx}`} className="checkbox-col">
                                    {col.map(item => item)}
                                </div>
                            );
                        })
                    }
                </div>
            </>
        );
    }

    includeInGroup(_regions) {
        let notChecked = _regions.filter((region) => {
            return !this.props.data.includes(region);
        })
        return notChecked.length === 0;
    }

    renderGroup(column, idx) {
        let _title, _selectAll;
        let _regions = column.map(item => item.name);
        if (idx === 0) {
            _title = <div className="areas">Africa, Europe & Middle East</div>;
        }
        if (idx === 1) {
            _title = <div className="areas">Aisa, Australia & Japan</div>;
        }
        if (idx === 2) {
            _title = <div className="areas">South & North America</div>;
        }
        _selectAll = (
            <div className="sub-select-all" >
                <input
                    type="checkbox"
                    checked={this.includeInGroup(_regions)}
                    onChange={event => this.props.onDetailListCheckboxChange(event.target.checked, _regions)}
                />
                {`Select All(${_regions.length})`}
            </div>
        );

        let checkboxes = column.map(region => {
            return (
                <div >
                    <input
                        key={`check_box_${region.name}`}
                        type="checkbox" id={region.name}
                        checked={this.props.data.includes(region.name)}
                        onChange={event => this.props.onDetailListCheckboxChange(event.target.checked, region.name)}
                    />
                    {` ${region['display_name']}`}
                </div>
            );
        });
        return (
            <div className="checkbox-groups-colums"> 
                {_title}{_selectAll}
                <div className="checkbox-groups-item-container">{checkboxes}</div>
            </div>
        );
    }

    renderAll() {
        let columns = [[], [], []];
        Object.keys(this.props.mapRegionInfo).forEach((continent) => {
            if (continent === 'Africa' || continent === 'Europe' || continent === 'Middle East') {
                columns[0] = columns[0].concat(this.props.mapRegionInfo[continent].regions);
            } else if (continent === 'Asia' || continent === 'ANZ' || continent === 'Japan') {
                columns[1] = columns[1].concat(this.props.mapRegionInfo[continent].regions);
            } else {
                columns[2] = columns[2].concat(this.props.mapRegionInfo[continent].regions);
            }
        });
        return (
            <div className="checkbox-groups">
                {columns.map((col, idx) => this.renderGroup(col, idx))}
            </div>
        );
    }

    renderBody = () => {
        if (this.props.modalDetail) {
            return this.renderDetail();
        } else {
            return this.renderAll();
        }
    }

    render() {
        return (
            <div className="list-container">
                <div className="text-info-check">
                    <div className="operation-buttons">
                        {this.props.operationButtons}
                    </div>
                </div>
                {this.renderBody()}
            </div>
        );
    }
}
