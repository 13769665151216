import React from "react";
import PropTypes from "prop-types";
import { Input, FormText } from "reactstrap";
import './BaseInput.scss';
import Pan from "../../schema/Pan";
export class BaseInput extends React.Component {
    constructor() {
        super();
        this.input = React.createRef();
    }
    // Note: since React 15.2.0 we can't forward unknown element attributes, so we
    // exclude the "options" and "schema" ones here.
    render() {
        let props = this.props;
        if (!props.id) {
            console.log("No id for", props);
            throw new Error(`no id for props ${JSON.stringify(props)}`);
        }
        let {
            value,
            readonly,
            disabled,
            autoFocus,
            onBlur,
            onFocus,
            options,
            error,
            showErrors,
            ...inputProps
        } = props;

        if (value && Pan.isObject(value)) {
            // when textfield is inside member grid
            value = value.value || "";
        }

        inputProps.type = (options && options.inputType) || (inputProps && inputProps.type) || "text";
        const _onChange = ({ target: { value } }) => {
            const emptyValue = (options && options.emptyValue) ? options.emptyValue : "";
            return props.onChange(value === "" ? emptyValue : value);
        };

        return (
            <React.Fragment>
                <Input
                    readOnly={readonly}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    innerRef={this.input}
                    value={value == null ? "" : value}
                    {...inputProps}
                    onChange={_onChange}
                    onBlur={onBlur && (event => onBlur(inputProps.id, event.target.value))}
                    onFocus={onFocus && (event => onFocus(inputProps.id, event.target.value))}
                    invalid={showErrors && !!error}
                />
            </React.Fragment>
        );
    }

    focus = () => {
        if (this.input && this.input.current && this.input.current.focus) {
            this.input.current.focus();
        }
    }
}

BaseInput.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
    readonly: false,
    autoFocus: false,
};

if (process.env.NODE_ENV !== "production") {
    BaseInput.propTypes = {
        value: PropTypes.any,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        type:PropTypes.string,
        error:PropTypes.string,
        className:PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autoFocus: PropTypes.bool,
        showErrors:PropTypes.bool,
        onKeyDown:PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        options: PropTypes.shape({
            inputType: PropTypes.string,
            emptyValue: PropTypes.string
          }),
    };
}

