import React, { Component } from 'react';
import './ZoomWidget.scss';

export class ZoomWidget extends Component {

    onClick = (type) => {
        if (type === 1) {
            if (this.props.onClick) {
                this.props.onClick(1);
            }
        } else {
            if (this.props.onClick) {
                this.props.onClick(-1);
            }
        }
    }

    render() {
        return (
            <span className={this.props.className} >
                <div className='plusButton' onClick={() => this.onClick(1)} ></div>
                <div className='minusButton' onClick={() => this.onClick(-1)} ></div>
            </span>
        )
    }
}
