import React from 'react';
import Pan from '../../schema/Pan';
import { FormContext, FormConsumer } from '../../FormContext';
import { BaseFieldBuilder } from '../BaseBuilder';
import { FormGroup, Col, FormText } from 'reactstrap';
import { AutoSuggestWidget } from '../../widgets/AutoSuggestWidget';
import {
    getCompletionLoadConfig,
    getFieldLabelComp,
    getFieldWidthClass,
    getCompletionXpath,
    getFieldHelpString
} from '../../schema/utils';
import classnames from 'classnames';
import { getGenericAutoComplete } from '../../../services/CommonServices';

export class AutoSuggestBuilder extends BaseFieldBuilder {
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.setRecordValue = super.setRecordValue.bind(this);
        this.state = {
            loading: false,
            value: null,
        };
    }

    renderTagField = () => {
        const {
            name,
            className,
            field,
            disabled,
            onBlur,
            onFocus,
            containerLabelWidthSize,
            isEditorGridColumnEditor,
            showErrors,
            hideLabel,
        } = this.props;
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        const builderConfig = field.uiHint.builderConfig || {};
        const completionConfig = getCompletionLoadConfig(field);
        const completionServiceFunction =
            completionConfig && completionConfig.CustomCompletion
                ? completionConfig.CustomCompletion
                : getGenericAutoComplete;
        let completionXpath = getCompletionXpath(
            builderConfig.xpath || this.props.field.attrPath,
            this.props.filters,
            this.context.formData,
            builderConfig.recordConfigPath || this.context.recordConfigPath,
        );
        const { configLocation } = this.context;
        const value = this.state.value || this.getRecordValue();
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors)
            ? ''
            : errors[0] || 'Invalid field value';
        let LabelComp = getFieldLabelComp(
            field,
            name,
            isEditorGridColumnEditor,
            containerLabelWidthSize,
        );
        let fieldClassName = getFieldWidthClass(
            field,
            isEditorGridColumnEditor,
        );
        let helpString = getFieldHelpString(field, this.context.formData);
        return (
            <FormGroup className={classnames(className, `d-flex flex-row ${required}`)}>
                {!hideLabel && LabelComp}
                <Col>
                    <div className={'select-display-same-row ' + fieldClassName} style={{ padding: 0 }}>
                        <AutoSuggestWidget
                            id={this.id}
                            name={name}
                            value={value}
                            configLocation={configLocation}
                            onChange={this.setRecordValue}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            isDisabled={disabled}
                            loadOptions={completionServiceFunction}
                            error={error}
                            showErrors={showErrors}
                            completionXpath={completionXpath}
                            showNotCondition={builderConfig.showNotCondition}
                            showCondition={builderConfig.showCondition}
                        />
                    </div>
                    {!this.state.hideField && helpString && (
                        <FormText>
                            {helpString.split('\n').map((i, key) => {
                                return <div key={key}>{i}</div>;
                            })}
                        </FormText>
                    )}
                    {/* {error && showErrors && !disabled && (<FormFeedbackWidget target={this.id} feedback={error} />)} */}
                </Col>
            </FormGroup>
        );
    };
    
    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderTagField()}</FormConsumer>
            </React.Fragment>
        );
    }
}

AutoSuggestBuilder.contextType = FormContext;
