import { ViewerManager, Pan } from 'ui-lib';
import DGBaseViewer from '../../deviceGroup/DGBaseViewer';
import getDGRecordViewer from "../../deviceGroup/DGRecordViewer";

const _T = str => str;

const serviceGroupObjectFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            autoFocus: true
        },
    },
    {
        name: '$.members.member',
        uiHint: {
            fieldLabel: _T('Services'),
        },
    },
    {
        name: '$.members.member.*',
        uiHint: {
            fieldLabel: _T('Service'),
        },
    },
    {
        name: '$.tag.member',
        uiHint: {
            fieldLabel: _T('Tag')
        },
    },
    {
        name: '$',
        childrenNames: ['name', 'location', '$.members.member', '$.tag.member'],
    },
];

const serviceGroupObjectColumns = [
    {
        type: ['nameColumn', 'clickableColumn'],
        recordConfigPath: '$.config.devices.entry.vsys.entry.service-group.entry',
        minWidth: 100
    },
    {
        headerName: 'Location',
        field: '@loc',
        minWidth: 100
    },
    {
        headerName: _T('Member Count'),
        id: 'serviceMemberCount',
        valueGetter: params => {
            let members = params.data.members;
            if (members) {
                return members && members['member'].length
                    ? members['member'].length
                    : '';
            } else {
                console.log(params.data);
            }
        },
        minWidth: 50
    },
    {
        headerName: _T('Services'),
        field: 'members.member',
        minWidth: 100
    },
    {
        headerName: _T('Tags'),
        field: 'tag.member',
        minWidth: 80
    }
];

const viewConfig = {
    serviceName: 'Objects/ServiceGroups',
    reduxId: 'serviceGroupObjects',
    header: 'Service Groups',
    columns: serviceGroupObjectColumns,
    fields: serviceGroupObjectFields,
    recordConfigPath: '$.config.devices.entry.device-group.entry.service-group.entry',
    rbaPath: 'objects.service-groups',
    className: 'ServiceGroups'
}

export default class ServiceGroups extends DGBaseViewer {
    constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getDGRecordViewer);
