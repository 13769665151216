import { ajax } from "rxjs/ajax";
import { getCustomServiceURL, getLoginServiceURL, MOCK_SERVICES } from "ui-lib";
import { loginSuccess, loginFail } from "./actions";
import { getAuthHeaders, getStoreState } from "ui-lib";
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

const relogin = () => {
	let loginUrl = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL']
	//tenant from redux store
	let store = getStoreState()
	let tenantId = _.get(store, 'main.tenant.id', null)
	if (tenantId) {
		loginUrl += `?tenantId=${tenantId}`
	}
	window.location = loginUrl;
}

export function isUserLoggedIn() {
	let { SERVICE_URL } = getLoginServiceURL("api/sso/v1/isLoggedIn");

	const urlParams = new URLSearchParams(window.location.search);
	const tenantId = urlParams.get('tenantId');

	if (tenantId) {
		SERVICE_URL += `?tenantId=${tenantId}`;
	}

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		// headers: getAuthHeaders()
	});
}
export function getLoggedInUser() {
	let { SERVICE_URL } = getCustomServiceURL('api/sso/v1/user');

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	}).pipe(map(r => {
		// on redirection response is null & req url != resp url
		if (r.xhr.status === 403) {
			return relogin();
		}
		return r
	}), catchError(e => {
		if (e.xhr.status === 403) {
			return relogin()
		} else {
			return throwError(e)
		}
	}))
}

export function postLoginProcess(resp) {
	if (resp && resp.response && resp.response.isLoggedIn) {
		return loginSuccess(resp.response.tenant);
	} else {
		//navigate to login page
		return loginFail();
	}
}

export function commitActionObservable(scope) {
	let { SERVICE_URL } = getCustomServiceURL("api/config/v9.0/Operations/commitScope/" + scope);
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	});
}

export function doCommitActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL("api/config/v9.0/commit");
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	});
}

export function doCommitAndPushActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL("api/config/v9.0/commitAndPush");
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	});
}

export function doPushActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/extensions/gpcs/pushToCloud`
	);
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	});
}

export function doRevertActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL('api/config/v9.0/revert');
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	});
}

export function directoryDomainsObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/directory/v1/domains");
	return ajax({
		withCredentials: true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: {},
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	});
}