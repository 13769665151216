import React from "react";
import Pan from "../autorender/schema/Pan";

export const clickableColumn = {
    onCellClicked: params => {
        if (params.column.colDef.onClick) {
            params.column.colDef.onClick(params.data, params.rowIndex);
        }
    },
    cellRendererParams: {
        renderCell: params => <a>{params.value}</a>
    }
}

export const nameColumn = {
    headerName: 'Name',
    field: '@name',
    minWidth: 100,
    flex: 1
}

export const checkboxColumn = {
    headerName: '',
    field: '$#id',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    lockVisible: true,
    suppressMenu: true,
    maxWidth: 40,
    editable: false
}

export const dragDropCheckboxColumn = {
    ...checkboxColumn,
    field: '@name',
    headerClass: 'pan-drag-checkbox-header',
    maxWidth: 70
}

export function isClickable(column) {
    if (!column)
        return false;
    if (column.type === 'clickableColumn')
        return true;
    if (Pan.isArray(column.type) && column.type.indexOf('clickableColumn') >= 0)
        return true;

    return false;
}