
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormContext, FormConsumer } from "../../FormContext";

import { jsonPath, getPathValue, getPathValues, setPathValue } from "../../../utils/json";
import './AdminroleBuilder.scss';

export class AdminroleBuilder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            name,
            field,
            formData,
            onBlur,
            onFocus
        } = this.props;

        return (
            <div>AdminroleBuilder for {name}</div>
        );
    }

}

AdminroleBuilder.contextType = FormContext;

AdminroleBuilder.defaultProps = {
    field: {},
    required: false,
    disabled: false,
    readonly: false
};

if (process.env.NODE_ENV !== "production") {
    AdminroleBuilder.propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        field: PropTypes.object
    };
}
