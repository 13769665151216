import * as React from 'react';
import { Option } from 'rc-select';
import classNames from 'classnames';
import InputElement from './components/InputElement';
import {ClearableInputWidget as Input} from '../ClearableInputWidget';
import Select from './components/Select';
import './AutoCompleteWidget.scss'

function isSelectOptionOrSelectOptGroup(child) {
    return child && child.type && (child.type.isSelectOption || child.type.isSelectOptGroup);
}

export class AutoCompleteWidget extends React.Component {

    static Option = Option

    static defaultProps = {
        transitionName: 'slide-up',
        optionLabelProp: 'children',
        choiceTransitionName: 'zoom',
        showSearch: false,
        filterOption: false,
    };

    saveSelect = (node) => {
        this.select = node;
    };

    getInputElement = () => {
        const { children } = this.props;
        const element =
            children && React.isValidElement(children) && children.type !== Option ? (
                React.Children.only(this.props.children)
            ) : (
                <Input />
            );
        const elementProps = { ...(element).props };
        delete elementProps.children;
        return <InputElement {...elementProps}>{element}</InputElement>;
    };

    focus() {
        this.select.focus();
    }

    blur() {
        this.select.blur();
    }

    render() {
        const {
            size,
            className = '',
            notFoundContent,
            optionLabelProp,
            dataSource,
            children,
        } = this.props;
        const prefixCls = 'pan-select';

        const cls = classNames({
            [`${prefixCls}-lg`]: size === 'large',
            [`${prefixCls}-sm`]: size === 'small',
            [className]: !!className,
            [`${prefixCls}-show-search`]: true,
            [`${prefixCls}-auto-complete`]: true,
        });

        let options;
        const childArray = React.Children.toArray(children);
        if (childArray.length && isSelectOptionOrSelectOptGroup(childArray[0])) {
            options = children;
        } else {
            options = dataSource
                ? dataSource.map(item => {
                    if (React.isValidElement(item)) {
                        return item;
                    }
                    switch (typeof item) {
                        case 'string':
                            return <Option key={item}>{item}</Option>;
                        case 'object':
                            return (
                                <Option key={item.value}>
                                    {item.text}
                                </Option>
                            );
                        default:
                            throw new Error(
                                'AutoCompleteWidget[dataSource] only supports type `string[] | Object[]`.',
                            );
                    }
                })
                : [];
        }

        return (
            <Select
                {...this.props}
                className={cls}
                mode='SECRET_COMBOBOX_MODE_DO_NOT_USE'
                optionLabelProp={optionLabelProp}
                getInputElement={this.getInputElement}
                notFoundContent={notFoundContent}
                ref={this.saveSelect}
            >
                {options}
            </Select>
        );
    }
}
