import { Component } from "react";
import { deletePath, getPathValue, getRecordPathWithFilters, setPathValue } from "../../../utils/json";
import Pan from "../../schema/Pan";
import './BaseBuilder.scss';
import { validationRulesMap } from "../../../utils/validationRules";
import { deleteRecursivelyEmptyParents, INJECTED_REC_ID } from "../../widgets/util";
import _ from 'lodash';
import { associationService } from "../../AssociationService";
import { getField } from "../../schema/utils";


export class BaseBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableField: false
        }
    }

    static getDerivedStateFromProps(nextProps, _) {
        const { field } = nextProps;
        let uiHint = field ? field.uiHint : null;
        if (uiHint && uiHint.disabled === true) {
            return {
                disableField: true
            };
        }
        return null;
    }

    componentDidMount() {
        this.executeAtRender(this.props);
        this.executeAssociation(this.props, true);
        const { field, filters, cellInfo } = this.props;
        const { formData } = this.context;
        let uiHint = field ? field.uiHint : null;
        let association = uiHint ? uiHint.association : null;
        //regular association
        let associationFields = association ? (association.fields || [field.attrPath]) : null;
        if (associationFields) {
            //subscribe to association events
            let associationPaths = associationFields.map(associationField => {
                return getRecordPathWithFilters(formData, associationField, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            });
            this.subscription = associationService.listenEvents().subscribe(event => {
                let runAssociation = false;
                for (let associationPath of associationPaths) {
                    //check eventId matching with the fields associated with.
                    //If association fields empty, we listen to currentfield
                    if (event && (event.eventId === associationPath)) {
                        //execute association
                        runAssociation = true;
                        break;
                    }
                }
                if (runAssociation) {
                    this.executeAssociation(this.props);
                }
            });
        }

        //completion association
        let completionAssociationFields = uiHint && uiHint.completionConfig ? uiHint.completionConfig.associationFields : null;
        if (completionAssociationFields) {
            //subscribe to association events
            let completionAssociationPaths = completionAssociationFields.map(associationField => {
                return getRecordPathWithFilters(formData, associationField, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            });
            this.completionSubscription = associationService.listenEvents().subscribe(event => {
                let runAssociation = false;
                for (let completionAssociationPath of completionAssociationPaths) {
                    //check eventId matching with the fields associated with
                    if (event && (event.eventId === completionAssociationPath)) {
                        //execute association
                        runAssociation = true;
                        break;
                    }
                }
                if (runAssociation) {
                    this.executeCompletionAssociation();
                }
            });
        }
    }

    componentWillUnmount() {
        // unsubscribe
        this.subscription ? this.subscription.unsubscribe() : null;
        this.completionSubscription ? this.completionSubscription.unsubscribe() : null;
    }

    getValue(props) {
        const { field, filters, cellInfo } = props;
        const { formData } = this.context;
        const attrPath = field.attrPath;
        return getPathValue(formData, attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]) || '';
    }

    getRecordValue() {
        return this.getValue(this.props);
    }

    setRecordValue(value, dontSetState) {
        const { field } = this.props;
        return this.setFieldValue(value, dontSetState, field);
    }

    setFieldValue(value, dontSetState, field) {
        this.setFieldValues([{ field, value }], dontSetState)
    }

    setFieldValues(fieldToValues, dontSetState) {
        // if (!Pan.isDefined(value)) {
        //     return;
        // }
        const { onChange, addError, removeError, filters, cellInfo } = this.props;
        const { formData, errors } = this.context;
        let newFormData = Pan.clone(formData);
        _.each(fieldToValues, (item) => {
            const { field, value } = item;
            const attrPath = field.attrPath;
            if (!Pan.isDefined(attrPath)) {
                return;
            }
            if (value === null || value === undefined) {
                //delete the field
                deletePath(newFormData, attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
                //when deleting the field, delete the empty parents
                deleteRecursivelyEmptyParents(newFormData, attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            } else {
                setPathValue(newFormData, attrPath, value, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            }
            // RUN VALIDATION
            let recPath = getRecordPathWithFilters(formData, field.attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            let fieldErrors = this.executeFieldValidation(value);
            if (!Pan.isEmpty(fieldErrors)) {
                //set errors
                addError({ [recPath]: fieldErrors });
            } else if (errors[recPath]) {
                //delete if already set
                removeError(recPath);
            }
            if (!dontSetState) {
                this.setState({
                    value: value
                });
            }
            onChange({ formData: newFormData, formDataBackup: formData, fieldId: recPath });
        });
    }

    runFieldValidationAtRender(value) {
        const { field, addError, filters, cellInfo } = this.props;
        const { formData } = this.context;
        let fieldErrors = this.executeFieldValidation(value);
        if (fieldErrors) {
            let recPath = getRecordPathWithFilters(formData, field.attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
            addError({ [recPath]: fieldErrors });
        }
    }

    executeFieldValidation(value) {
        const { field } = this.props;
        let validationRules = this.getValidationRules(field, value);
        let fieldErrors = this.execValidationRules(field, value, validationRules);
        return fieldErrors;
    }

    executeValidationForField(field, value, key) {
        let validationRules = this.getValidationRules(field, value);
        let recPath = getRecordPathWithFilters(this.context.formData, field.attrPath, this.props.filters, key);
        let fieldErrors = this.execValidationRules(field, value, validationRules);
        if (fieldErrors) {
            this.props.addError({ [recPath]: fieldErrors });
        } else if (this.context.errors[recPath]) {
            // delete if already set
            this.props.removeError(recPath);
        }
    }

    getValidationRules(field, value) {
        let rules = [];

        // Always run customValidation if exist
        if (field && field.uiHint && Pan.isFunction(field.uiHint.customValidation)) {
            rules.push(field.uiHint.customValidation);
        }
        if (field && field.uiHint && field.uiHint.allowBlank === false) {
            rules.push(validationRulesMap['noAllowBlank']);
        } else if (Pan.isEmpty(value)) {
            // allowblank true and empty value. No need to validate
            return rules;
        }

        if (field && field.uiHint && field.uiHint.vtype && validationRulesMap[field.uiHint.vtype]) {
            rules.push(validationRulesMap[field.uiHint.vtype]);
        }
        if (field && field.uiHint && field.uiHint.vtype && Pan.isFunction(field.uiHint.vtype)) {
            rules.push(validationRulesMap[field.uiHint.vtype(field)]);
        }
        if (field && field.uiHint && field.uiHint.regex) {
            rules.push(validationRulesMap['regEx']);
        }
        return rules;
    }

    execValidationRules(field, value, rules) {
        if (rules && rules.length > 0) {
            let result = [];
            rules.forEach(rule => {
                let error = this.execValidationRule(field, value, rule);
                if (error) {
                    result.push(error)
                }
            });
            if (result.length > 0) {
                return result;
            }
        }
        return null;
    }

    execValidationRule(field, value, rule) {
        if (Pan.isFunction(rule)) {
            return rule(value ? (value[INJECTED_REC_ID] ? value.value || "" : value) : "", field, this.context.formData);
        }
    }

    showErrors(errors) {
        // each builder to provide implementation
    }

    getErrors() {
        const { field, filters, cellInfo } = this.props;
        const { formData, errors } = this.context;
        let recPath = getRecordPathWithFilters(formData, field.attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
        return errors ? errors[recPath] : [];
    }

    isValidated() {
        const { errors } = this.context;
        return Pan.isEmpty(errors);
    }

    removeErrors() {
        const { field, removeError, filters, cellInfo } = this.props;
        const { formData, errors } = this.context;
        let recPath = getRecordPathWithFilters(formData, field.attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
        if (errors && errors[recPath]) {
            removeError(recPath);
        }
    }

    updateFormDataInternal(updatedFields, formData, key, props) {
        const { field, onChange, filters } = props;
        let newFormData = Pan.clone(formData);
        let formDataUpdated = false;
        for (let fieldKey in updatedFields) {
            let pathValue = getPathValue(newFormData, fieldKey, filters, key);
            if (pathValue !== updatedFields[fieldKey]) {
                setPathValue(newFormData, fieldKey, updatedFields[fieldKey], filters, key);
                formDataUpdated = true;

                // update state if the current builder value changed
                if (fieldKey === field.name) {
                    this.setState({
                        value: updatedFields[fieldKey]
                    });
                }
            }
        }
        if (formDataUpdated) {
            onChange({formData: newFormData, formDataBackup: formData});
            const {fields} = this.context;
            for( let fieldKey in updatedFields ) {
                let field = getField(fields, fieldKey);
                this.executeValidationForField(field, updatedFields[fieldKey], key);
            }
        }
    }

    executeAssociation(props, firstTime) {
        /***
         * goes over the Association config and executes all the avail functions
         * 1. availHide - return true to hide field
         * 2. availDisable - return true to disable
         * **/
        const { field, filters, cellInfo } = props;
        let key = cellInfo ? cellInfo[INJECTED_REC_ID] : '';
        let { formData } = this.context;
        let uiHint = field ? field.uiHint : null;
        let association = uiHint ? uiHint.association : null;
        if (association) {
            let assFields = association.fields;
            let values = assFields ? assFields.map(assField => {
                return getPathValue(formData, assField, filters, key);
            }) : [];
            let hideField = false; //default false, to show field
            let disableField = false; //default
            if (association.availHide) {
                hideField = association.availHide(values, formData, filters, key);
                if (hideField !== this.state.hideField) {
                    this.setState({
                        hideField: hideField
                    });
                    if (hideField) {
                        //field is going to hide. Remove errors
                        this.removeErrors();
                    }
                }
            }
            if (!hideField && association.availDisable) {
                disableField = association.availDisable(values, formData, filters, key);
                if (disableField !== this.state.disableField) {
                    this.setState({
                        disableField: disableField
                    });
                    if (disableField) {
                        //field is going to disable. Remove errors
                        this.removeErrors();
                    }
                }
            }
            if (!hideField && association.updateFormData && firstTime !== true) {
                // dont run updateFormData firstTime when component is rendering. This should execute only on change
                let updatedFields = association.updateFormData(values, formData, filters, key);
                this.updateFormDataInternal(updatedFields, formData, key, props);
            }
            if (!hideField && association.updateFormDataAsync && firstTime !== true) {
                // dont run updateFormData firstTime when component is rendering. This should execute only on change
                let promise = association.updateFormDataAsync(values, formData, filters, this.context);
                promise.then(
                    (updatedFields) => {
                        this.updateFormDataInternal(updatedFields, this.context.formData, key, props);
                    },
                    (err) => {
                        store.dispatch({
                            type: SERVER_ERROR,
                            errorMessage: parseError(err),
                            showMessage: false
                        });
                    }
                );
            }
            if (!hideField && association.runValidation && firstTime !== true) {
                // dont run updateFormData firstTime when component is rendering. This should execute only on change
                let updatedFields = association.runValidation(values, formData, filters, key);
                for (let fieldKey in updatedFields) {
                    let field = getField(this.context.fields, fieldKey);
                    this.executeValidationForField(field, updatedFields[fieldKey], key);
                }
            }
        }
    }

    executeAtRender() {
        const { field } = this.props;
        let uiHint = field ? field.uiHint : null;
        let { isNewRecord } = this.context;
        let disableOnEditRecord = uiHint ? uiHint.disableOnEditRecord : null;
        if (disableOnEditRecord && !isNewRecord) {
            this.setState({
                disableField: true
            });
        }
    }

    getDefaultValue(field) {
        if (field && field.defaultValue) {
            if (Array.isArray(field.defaultValue)) {
                return field.defaultValue[0];
            } else if (typeof field.defaultValue === 'string') {
                return field.defaultValue
            }
            return "";
        }
        return "";
    }

    render() {
        return null
    }
}
