import React from 'react';
import PropTypes from 'prop-types';
import { FormContext, FormConsumer } from '../../FormContext';
import { EditorGrid } from '../../widgets/EditorGrid';
import { RecordFormEditorGrid } from '../../widgets/RecordFormEditorGrid';
import _ from 'lodash';
import { FormGroup, Col, FormText } from 'reactstrap';
import { BaseFieldBuilder } from '../BaseBuilder';
import Pan from '../../schema/Pan';
import {
    getFieldLabel,
    getFieldLabelComp,
    getField,
    getFieldWidthClass,
    getFieldHelpString,
    isFieldEndsWithMember,
    getMemberCompletionField
} from "../../schema/utils";
import './EditorGridBuilder.scss';
import classnames from "classnames";
import { getPathValue, getRecordPathWithFilters, getPathValues } from "../../../utils/json";
import { INJECTED_REC_ID, deleteRecursivelyEmptyParents } from "../../widgets/util";
import { FormFeedbackWidget } from "../../widgets";

export class EditorGridBuilder extends BaseFieldBuilder {
    constructor(props) {
        super(props);
        this.getInlineEditGridColumns = this.getInlineEditGridColumns.bind(
            this,
        );
        this.getRecordFormEditGridColumns = this.getRecordFormEditGridColumns.bind(
            this,
        );
        this.getRecordValue = super.getRecordValue.bind(this);
        this.setRecordValue = super.setRecordValue.bind(this);
        this.getEditorGrid = this.getEditorGrid.bind(this);
        this.getColumnsFromStarField = this.getColumnsFromStarField.bind(this);
        this.setDefaultValue = this.setDefaultValue.bind(this);
        this.onChange = this.onChange.bind(this);
        this.id = Pan.id();
        this.state = {
            hideField: false,
            selectWidgetValue: '',
            editingrow: -1, // do not show editor by default
            editingcolumn: '@name'
        };
    }

    componentDidMount() {
        super.componentDidMount();
        let { field } = this.props;
        if (isFieldEndsWithMember(field) && !Pan.isEmpty(field.defaultValue)) {
            let val = this.getRecordValue();
            let strVal = val ? typeof val === 'string' ? val : (Array.isArray(val) && val.length === 1) ? val[0].value : val : field.defaultValue[0];
            const memberField = getMemberCompletionField(field, this.context.fields);
            const enums = _.get(memberField, "multitypes.enum");
            if (!Pan.isEmpty(strVal) && enums && _.findIndex(enums, item => item[0] === strVal) >= 0) {
                // enum value
                this.setState({
                    displayGridMode: false,
                    selectWidgetValue: strVal,
                });
                return;
            }
            this.setState({
                displayGridMode: true,
                selectWidgetValue: '__select__',
            });
        } else {
            this.setState({
                displayGridMode: true
            })
        }
    }

    setDefaultValue(value) {
        const displayGridMode = value === '__select__';
        const selectWidgetValue = value;
        if (displayGridMode) {
            value = [];
        }
        this.setRecordValue(value);
        this.setState({ selectWidgetValue, displayGridMode });
    }

    onChange(args) {
        const {
            onChange,
            field,
            filters,
            cellInfo,
            addError,
            removeError,
        } = this.props;
        let newFormData = args ? args.formData || {} : {};
        const attrPath = field.attrPath;
        let { errors } = this.context;
        let gridData =
            getPathValue(
                newFormData,
                attrPath,
                filters,
                cellInfo && cellInfo[INJECTED_REC_ID],
            ) || [];
        let fieldErrors = this.executeFieldValidation(gridData);
        let recPath = getRecordPathWithFilters(
            newFormData,
            field.attrPath,
            filters,
            cellInfo && cellInfo[INJECTED_REC_ID],
        );
        if (fieldErrors) {
            addError({ [recPath]: fieldErrors })
        } else if (errors && !Pan.isEmpty(errors[recPath])) {
            removeError(recPath);
        }
        if (!gridData || gridData.length === 0) {
            //empty grid data, delete the entry/member nodes and its empty parent nodes
            deleteRecursivelyEmptyParents(
                newFormData,
                field.attrPath + '.*',
                this.props.filters,
            );
        }
        //publish change event
        args.fieldId = recPath;
        onChange(args);
    }

    hasDuplicates(gridData) {
        return _.some(gridData, function (item, index) {
            if (item && gridData.indexOf(item) !== index) {
                gridData.dupId = item;
                gridData.dupIndex = index;
                return true;
            }
        });
    }

    execValidationRules(field, value, rules) {
        let errors = super.execValidationRules(field, value, rules);
        let gridData = value;
        if (typeof gridData === 'string') {
            return errors;
        }
        //array duplicate validation
        let entries;
        if (field.attrPath.endsWith('.member')) {
            entries = _.groupBy(gridData, 'value');
        } else {
            entries = _.groupBy(gridData, '@name');
        }
        entries = Object.values(entries);
        _.forEach(entries, function (val, key) {
            if (Array.isArray(val) && val.length > 1) {
                errors = errors || [];
                gridData.dupId = val[0]['@name'] || val[0].value || '';
                errors.push(`Duplicate values in table: ${gridData.dupId}`);
                return false;
            }
        });
        return errors;
    }

    getErrors() {
        let gridErrors = super.getErrors();
        if (!Pan.isEmpty(gridErrors)) {
            return gridErrors;
        } else {
            //check if any child has errors
            let { field, filters, cellInfo } = this.props;
            let { formData, errors } = this.context;
            let recPath = getRecordPathWithFilters(
                formData,
                field.attrPath,
                filters,
                cellInfo && cellInfo[INJECTED_REC_ID],
            );
            for (let key in errors) {
                if (key.indexOf(recPath) === 0) {
                    //child of grid has error
                    return { [recPath]: errors[key] };
                }
            }
        }
    } 

    getInlineEditGridColumns(columnsList, fields) {
        let fieldMap = {};
        fields.forEach(field => {
            fieldMap[field.name] = field;
        });
        let { showErrors, disabled } = this.props;
        if (columnsList) {
            if (!Pan.isArray(columnsList)) {
                columnsList = [columnsList];
            }
            return columnsList.map((column, indx) => {
                let columnField = fieldMap[column] || {};
                let fieldUiHint = columnField.uiHint || {};
                if (columnField.uiHint && columnField.uiHint.hideLabel)
                    columnField.uiHint.hideLabel = true;
                let fieldAttrName = columnField['attrName'];
                fieldAttrName = fieldAttrName && fieldAttrName === '*' ? 'value' : fieldAttrName;
                let { builder, columnConfig } = fieldUiHint;
                let fieldLabel = getFieldLabel(columnField);
                columnConfig = columnConfig || {};
                let { headerName, field, valueGetter } = columnConfig;
                let col = {
                    headerName: Pan.isDefined(headerName) ? headerName  : (fieldLabel || fieldAttrName),
                    field: field || fieldAttrName, //ag-grid column field
                    cellEditor: 'editorGridCellBuilder',
                    valueSetter: () => {}, //without this ag-grid would try setting the value,
                    id: fieldAttrName,
                    editorProps: Pan.apply(
                        {
                            builder: builder || 'TextfieldBuilder',
                            field: columnField,
                            name: fieldAttrName,
                            showErrors: showErrors,
                            displayGridMode: this.state.displayGridMode,
                            disabled: disabled,
                            onChange: this.onChange,
                            addError: this.props.addError,
                            removeError: this.props.removeError,
                            isEditorGridColumnEditor: true
                        },
                        columnConfig.editor,
                    ),
                };
                if (valueGetter) {
                    col.valueGetter = valueGetter; //ag-grid column valueGetter
                }
                return col;
            });
        }
    }

    getRecordFormEditGridColumns(columnsList, fields) {
        let fieldMap = {};
        fields.forEach(field => {
            fieldMap[field.name] = field;
        });
        if (columnsList) {
            return columnsList.map(column => {
                let columnField = fieldMap[column] || {};
                let fieldUiHint = columnField.uiHint || {};
                let fieldAttrName = columnField['attrName'];
                let { columnConfig } = fieldUiHint;
                let fieldLabel = getFieldLabel(columnField);
                columnConfig = columnConfig || {};
                let { headerName, field, valueGetter, type, onClick, columns, children } = columnConfig;
                let col =  {
                    headerName: Pan.isDefined(headerName) ? headerName  : (fieldLabel || fieldAttrName),
                    field: field || fieldAttrName,
                    columns: columns ? Pan.clone(columns) : null,
                    type,
                    onClick,
                    children
                };
                if (valueGetter) {
                    col.valueGetter = valueGetter;
                }
                return col;
            });
        }
    }

    onMoveDrag = (selectedData, destIndex, gridData, elements, api, moveTop) => {
        destIndex = moveTop ? destIndex : destIndex + 1;
        const filteredData = gridData.map((item, index) => {
            return elements.indexOf(index) >= 0 ? undefined : item;
        });
        filteredData.splice(destIndex, 0, ...selectedData);
        const finalData = filteredData.filter((item) => {
            return item;
        });
        this.setRecordValue(finalData);
    }
    
    getFieldRootPath() {
        let { field } = this.props;
        let { recordConfigPath } = this.context;
        if (recordConfigPath && field) {
            let fieldPath = field.attrPath;
            if (
                fieldPath &&
                (fieldPath.indexOf('*') > 0 || fieldPath.indexOf('$') === 0)
            ) {
                fieldPath = _.replace(fieldPath, '*.', '');
                fieldPath = _.replace(fieldPath, '$', '');
            }
            return recordConfigPath + fieldPath;
        } else if (recordConfigPath) {
            return recordConfigPath;
        }
    }

    getEditorGrid(editorType, columnsList, store, errors) {
        let {
            field,
            disabled,
            isEditorGridColumnEditor,
            showErrors,
            addError,
            removeError,
        } = this.props;
        let uiHint = field && field.uiHint ? field.uiHint : {};
        let { isOrdered, showPaging, showFilter, checkboxSelection, gridActionsAvail } = uiHint;
        showPaging = Pan.isBoolean(showPaging)
            ? showPaging
            : !isEditorGridColumnEditor;
        const starFieldChild = getField(
            this.context.fields,
            field.attrPath + '.*',
        );
        showFilter = Pan.isBoolean(showFilter)
            ? showFilter
            : !(starFieldChild && starFieldChild.multitypes);
        let defaultValue = field.defaultValue;
        let minRows = store.length;
        minRows = minRows > 5 ? 5 : minRows;
        minRows = minRows < 1 ? 1 : minRows;
        let noDataText = this.props.noDataText ? this.props.noDataText : ('This table will populate as you add ' + (!Pan.isEmpty(uiHint.fieldLabel.trim()) ? uiHint.fieldLabel : 'items'));
        const suppressColsSizeToFit = getPathValue(field, 'uiHint.suppressColsSizeToFit');
        if (editorType === 'inline') {
            let columns = this.getInlineEditGridColumns(
                columnsList,
                this.context.fields,
            );
            return (
                <EditorGrid
                    gridData={store}
                    columns={columns}
                    minRows={minRows}
                    onChange={this.onChange}
                    recordConfigPath={field.attrPath}
                    filters={this.props.filters}
                    disabled={disabled}
                    isOrdered={isOrdered}
                    showPaging={showPaging}
                    showFilter={showFilter}
                    noDataText={noDataText}
                    field={field}
                    defaultValue={defaultValue}
                    selectWidgetValue={this.state.selectWidgetValue}
                    setDefaultValue={this.setDefaultValue}
                    displayGridMode={this.state.displayGridMode}
                    errors={errors}
                    showErrors={showErrors}
                    editingrow={this.state.editingrow}
                    editingcolumn={this.state.editingcolumn}
                    addError={addError}
                    removeError={removeError}
                    checkboxSelection={Pan.isBoolean(checkboxSelection) ? checkboxSelection : true}
                    suppressColsSizeToFit={suppressColsSizeToFit}
                    allowDrag={uiHint.allowDrag}
                    allowDrop={uiHint.allowDrop}    
                    onMoveDrag={this.onMoveDrag}  
                    gridActionsAvail={gridActionsAvail}
                />
            );
        } else if (editorType === 'record-form') {
            const {
                disableDefaultAddAction = false
            } = uiHint;
            const columns = this.getRecordFormEditGridColumns(columnsList, this.context.fields);
            const dialogSize = getPathValue(field, 'uiHint.dialogSize') || 'md';
            const title = getPathValue(field, 'uiHint.title') || getFieldLabel(field);
            return (
                <RecordFormEditorGrid
                    gridData={store}
                    columns={columns}
                    minRows={minRows}
                    panSchema={this.context.schema}
                    inputFields={this.context.fields}
                    recordConfigPath={field.attrPath}
                    onChange={this.onChange}
                    filters={this.props.filters}
                    disabled={disabled}
                    isOrdered={isOrdered}
                    showPaging={showPaging}
                    showFilter={showFilter}
                    noDataText={noDataText}
                    field={field}
                    title={title}
                    dialogSize={dialogSize}
                    errors={errors}
                    showErrors={showErrors}
                    checkboxSelection={Pan.isBoolean(checkboxSelection) ? checkboxSelection : true}
                    disableDefaultAddAction={disableDefaultAddAction}
                    suppressColsSizeToFit={suppressColsSizeToFit}
                    allowDrag={uiHint.allowDrag}
                    allowDrop={uiHint.allowDrop}   
                    onMoveDrag={this.onMoveDrag}
                />
            );
        } else {
            return <div>Editor Grid</div>;
        }
    }

    getColumnsFromStarField(fields, fieldPath) {
        let columns = [];
        if (!Pan.isEmpty(fields) && !Pan.isEmpty(fieldPath)) {
            fields.forEach(curr => {
                if (curr.attrPath === fieldPath) {
                    columns = curr.childrenNames || curr.attrPath;
                }
            });
        }
        return columns;
    }

    renderGrid() {
        let {
            name,
            field,
            className,
            disabled,
            showErrors,
            containerLabelWidthSize,
            isEditorGridColumnEditor,
        } = this.props;

        if (this.props.hidden || this.state.hideField || getPathValues(field, 'uiHint.hidden')) {
            return (
                <div />
            );
        }
        const recordStore = this.getRecordValue() || [];

        let LabelComp = getFieldLabelComp(
            field,
            name,
            isEditorGridColumnEditor,
            containerLabelWidthSize,
        );
        if (field.uiHint.fieldLabel === field.uiHint.fieldLabelAutoGen) {
            LabelComp = false;
        }
        let required = '';
        if (LabelComp) {
            required = field.uiHint.allowBlank === false ? 'required' : '';
        }
        let editorType = getPathValue(field, 'uiHint.editorType') || 'inline';
        let columnsList = getPathValue(field, 'uiHint.useColumns') || this.getColumnsFromStarField(this.context.fields, field.attrPath + '.*');
        let gridFieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let helpString = getFieldHelpString(field, this.context.formData);
        let fieldClassName = Pan.isEmpty(LabelComp) || !LabelComp ? 'col-sm-12' : gridFieldClassName;
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        if (columnsList) {
            let Grid = this.getEditorGrid(
                editorType,
                columnsList,
                recordStore,
                errors,
            );
            return (
                <FormGroup
                    className={classnames(
                        className,
                        `d-flex flex-row ${required} editor-grid-builder`,
                    )}
                    test_id={field.attrPath}
                >
                    {LabelComp}
                    <Col>
                        <div id={this.id} className={"no-padding " + fieldClassName}>
                            {Grid}
                        </div>
                        {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                        {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} placement={'bottom'} feedback={error} />}
                    </Col>
                </FormGroup>
            );
        } else {
            return (
                <FormGroup row>
                    <div>Editor Grid Builder</div>
                </FormGroup>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>{() => this.renderGrid()}</FormConsumer>
            </React.Fragment>
        );
    }
}

EditorGridBuilder.contextType = FormContext;

EditorGridBuilder.defaultProps = {
    filters: [],
    hidden: false,
    disabled: false,
    isEditorGridColumnEditor: false,
};

if (process.env.NODE_ENV !== 'production') {
    EditorGridBuilder.propTypes = {
        onChange: PropTypes.func,
        /**
         * (find and replace) and append are used to track table entries in the record grid array
         * append is used when field.type=="multiple"
         */
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                find: PropTypes.string,
                replace: PropTypes.string,
                append: PropTypes.string,
            }),
        ),
        disabled: PropTypes.bool,
        /**
         * *attrPath* - defines the attribute schema path eg: "$.a.b.c" 
         * *attrName* - specifies the name of the field attribute eg: "c"
         * *defaultValue* - The defaultValue array options are shown as a dropdown.
         * *uiHint*
         * *isOrdered* - if true , the grid adds move up and move down action buttons.
         * *showPaging* - enable/disable paging
         * *dialogSize* - defines the size of the dialog in the recordFormEditorGrid. Possible values - "lg","md","sm"
         * *hidden* - hides the grid
         * *fieldLabel* - adds a label to the field
         * *fieldLabelAutoGen* -  if fieldLabelAutoGen==fieldLabel , label will not be shown.
         * *allowBlank* - if false,blank values will add error
         * *editorType* - sets the type of editor grid which is one if "inline" or "record-form"
         * *useColumns* - it is an array. Pass column names to the grid eg ["$.http-header-insertion.entry.*.@name", "$.http-header-insertion.entry.*.type"]
         * *labelWidthSize* - The size of the label - ranges from 1 to 12.The size of the label column is given by the class col-*number* .
         * *fieldWidthSize* - The size of the field - ranegs from 1 to 12 . The size of the field is given by the class col-*number*
         * *customValidation* - custom function for validation
         * *vtype* - validation type can be a function or a string.The function would return a string which would be one of the values listed below.The field object is passed to the function
       
         */
        field: PropTypes.shape({
            attrPath: PropTypes.string,
            defaultValue: PropTypes.array,
            uiHint: PropTypes.shape({
                isOrdered: PropTypes.bool,
                showPaging: PropTypes.bool,
                showFilter: PropTypes.bool,
                dialogSize: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                hidden: PropTypes.bool,
                fieldLabel: PropTypes.string,
                fieldLabelAutoGen: PropTypes.string,
                allowBlank: PropTypes.bool,
                editorType: PropTypes.string,
                useColumns: PropTypes.array,
                labelWidthSize: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                fieldWidthSize: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                customValidation: PropTypes.func,
                vtype: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            }),
        }),
        showErrors: PropTypes.bool,
        addError: PropTypes.func,
        removeError: PropTypes.func,
        cellInfo: PropTypes.object,
        isEditorGridColumnEditor: PropTypes.bool,
        name: PropTypes.string,
        className: PropTypes.string,
        containerLabelWidthSize: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        hidden: PropTypes.bool,
    };
}
