
export function getEffectiveServiceMap(serviceName, serviceMap) {
    let { GET, POST, PUT, DELETE, CLONE } = serviceMap || {};
    return serviceMap = {
        GET: GET || serviceName,
        POST: POST || serviceName,
        PUT: PUT || serviceName,
        DELETE : DELETE || serviceName,
        CLONE: CLONE || serviceName
    }
}