import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { ClearableInputWidget as Input, TimeRangeSelectComponent as LogRangePicker } from 'ui-lib'
import LogTypeSelector from './LogTypeSelector'
import LogQueryInput from './LogQueryInput'
import LogQueryCompletion from './LogQueryCompletion'
import LogQueryButton from './LogQueryButton'
import { LogSchema, EMPTY_SCHEMA } from './util'
import './LogQuery.scss'

const getRange = LogRangePicker.getRange

export class LogQueryComponent extends Component {
	state = {
		fields: [],
		query: '',
		defaultQuery: '',
		logType: '',
		defaultLogType: '',
		timeRange: [],
		timePreset: undefined,
		defaultTimeRange: [],
		defaultTimePreset: undefined,
		schema: new LogSchema()
	}

	static getDerivedStateFromProps(props, state) {
		const { query, logType, timeRange, timePreset, schema } = props
		const update = {}
		if (schema !== state.schema && !state.schema.is(schema)) {
			update.schema = LogSchema.from(schema)
		}
		if (logType !== state.defaultLogType) {
			Object.assign(update, {
				logType,
				defaultLogType: logType
			})
		}
		if (update.schema || update.logType) {
			const schema = update.schema || state.schema
			const logType = update.logType || state.logType
			const fields = logType && schema && schema.getFields(logType)
			if (fields && fields !== state.fields) {
				update.fields = fields
			}
		}
		if (query !== state.defaultQuery) {
			Object.assign(update, { query, defaultQuery: query })
		}
		if (timeRange !== state.defaultTimeRange) {
			Object.assign(update, { timeRange, defaultTimeRange: timeRange })
		}
		if (timePreset !== state.defaultTimePreset) {
			Object.assign(update, { timePreset, defaultTimePreset: timePreset })
		}
		return update
	}

	appendQuery = (queryExp, andor = 'AND', triggerSearch = false) => {
		const oldQuery = this.state.query.trim()
		const query = oldQuery ? `${oldQuery} ${andor} ${queryExp.trim()}` : queryExp.trim()
		// console.error('appendQuery', oldQuery, queryExp, query)
		this.setState({ query }, () => {
			if (triggerSearch) {
				this.triggerOnSearch()
			} else if (this.queryInputRef && this.queryInputRef.onSearch) {
				this.queryInputRef.onSearch()
			}
		})
	}

	onLogTypeChange = (logType) => {
		const fields = this.state.schema.getFields(logType)
		this.setState({ logType, fields }, () => {
			this.props.onLogTypeChange(logType, fields)
			this.triggerOnSearch()
			// if (logType && this.queryInputRef) {
			//   this.queryInputRef.focus()
			// }
		})
	}

	onRangeChange = (timeRange, timePreset) => {
		this.setState({ timeRange, timePreset }, () => {
			this.props.onRangeChange(timeRange, timePreset)
			this.triggerOnSearch()
		})
	}
	// only handle preset change
	// the range value change caused by preset change
	// will be handled by onRangeChange
	onTimePresetChange = (timePreset, callback) => {
		this.setState({ timePreset }, callback)
	}

	onQueryChange = (query) => {
		this.setState({ query }, () => {
			this.props.onQueryChange(query)
		})
	}

	onQueryPressEnter = (query) => {
		const trigger = () => {
			this.props.onPressEnter(query)
			this.triggerOnSearch()
		}
		if (query !== this.state.query) {
			this.setState({ query }, trigger)
		} else {
			trigger()
		}
	}

	triggerOnSearch = () => {
		const { query, logType, timeRange: customTimeRange, timePreset, fields } = this.state
		const { maxLength } = this.props

		const timeRange = timePreset === 'custom' ? customTimeRange : getRange(timePreset).timeval()
		if (logType && timePreset && timeRange && timeRange.length && (maxLength > 0 ? query.length <= maxLength : 1)) {
			this.props.onSearch(query, { query, logType, timeRange, timePreset, fields })
		}
	}

	render() {
		const {
			commonFilters,
			className,
			style,
			// schema,
			disabled,
			addonBefore,
			addonAfter,
			addonButton,
			searchButton,
			useCompletion,
			hasTimeRange,
			hasLogType,
			onSearch,
			onPressEnter,
			onQueryChange,
			onRangeChange,
			onLogTypeChange,
			onQueryParsed,
			onValueCompletion,
			logType: _pLT, // ignored
			query: _pQ, // ignored
			timeRange: _pTR, // ignored
			timeFormat,
			maxLength,
			supportNot,
			supportParen,
			disabledDate,
			...props
		} = this.props
		const { schema, fields, logType, defaultLogType, timeRange, timePreset, defaultTimeRange, query, defaultQuery } = this.state
		const inputDisabled = disabled || !this.state.logType
		return (
			<div style={style} className={classNames(className, 'log-query-wrapper')}>
				{addonBefore}
				<Input.Group compact className="log-query-input-group">
					{hasLogType && (
						<LogTypeSelector
							ref={(comp) => (this.logTypeSelectorRef = comp)}
							value={logType}
							disabled={disabled}
							defaultValue={defaultLogType}
							onChange={this.onLogTypeChange}
							onSelect={this.onLogTypeSelect}
							schema={schema}
						/>
					)}
					{useCompletion ? (
						<LogQueryCompletion
							{...props}
							commonFilters={commonFilters}
							ref={(comp) => (this.queryInputRef = comp)}
							disabled={inputDisabled}
							logType={logType}
							fields={fields || []}
							value={query}
							timeFormat={timeFormat}
							defaultValue={defaultQuery}
							onQueryChange={this.onQueryChange}
							onQueryPressEnter={this.onQueryPressEnter}
							onQueryParsed={onQueryParsed}
							maxLength={maxLength}
							supportNot={supportNot}
							supportParen={supportParen}
							onValueCompletion={onValueCompletion}
						/>
					) : (
						<LogQueryInput
							placeholder="Query"
							{...props}
							ref={(comp) => (this.queryInputRef = comp)}
							disabled={inputDisabled}
							value={query}
							defaultValue={defaultQuery}
							onQueryChange={this.onQueryChange}
							onQueryPressEnter={this.onQueryPressEnter}
							maxLength={maxLength}
						/>
					)}
					{searchButton === true || _.isPlainObject(searchButton) ? (
						<LogQueryButton
							{...searchButton}
							disabled={inputDisabled || (hasTimeRange && _.isEmpty(timeRange)) || (maxLength > 0 && query.length > maxLength)}
							onClick={this.triggerOnSearch}
							text="&rarr;"
						/>
					) : searchButton ? (
						React.cloneElement(searchButton, {
							disabled: inputDisabled || (maxLength > 0 && query.length > maxLength),
							onClick: this.triggerOnSearch
						})
					) : null}
					{addonButton}
				</Input.Group>
				<Input.Group compact className="log-query-date-group">
					{hasTimeRange && (
						<LogRangePicker
							value={timeRange}
							preset={timePreset}
							disabled={disabled}
							format={timeFormat}
							defaultValue={defaultTimeRange}
							disabledDate={disabledDate}
							onChange={this.onRangeChange}
							onPresetChange={this.onTimePresetChange}
						/>
					)}
				</Input.Group>
				{addonAfter}
			</div>
		)
	}
}

LogQueryComponent.propTypes = {
	commonFilters: PropTypes.array,
	className: PropTypes.string,
	style: PropTypes.object,
	disabled: PropTypes.bool,
	supportNot: PropTypes.bool,
	supportParen: PropTypes.bool,
	schema: PropTypes.object,
	addonBefore: PropTypes.node,
	addonAfter: PropTypes.node,
	addonButton: PropTypes.node,
	searchButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
	useCompletion: PropTypes.bool,
	hasTimeRange: PropTypes.bool,
	hasLogType: PropTypes.bool,
	onSearch: PropTypes.func,
	onPressEnter: PropTypes.func,
	onQueryChange: PropTypes.func,
	onRangeChange: PropTypes.func,
	onLogTypeChange: PropTypes.func,
	onQueryParsed: PropTypes.func,
	onValueCompletion: PropTypes.func,
	disabledDate: PropTypes.func,
	logType: PropTypes.string,
	query: PropTypes.string,
	maxLength: PropTypes.number,
	timeRange: PropTypes.array,
	timePreset: PropTypes.string,
	timeFormat: PropTypes.string
}

LogQueryComponent.defaultProps = {
	commonFilters: [],
	className: '',
	schema: EMPTY_SCHEMA,
	disabled: false,
	supportNot: true,
	supportParen: true,
	addonBefore: null,
	addonAfter: null,
	addonButton: null,
	searchButton: null,
	useCompletion: true,
	hasTimeRange: true,
	hasLogType: true,
	onSearch: _.noop,
	onPressEnter: _.noop,
	onQueryChange: _.noop,
	onRangeChange: _.noop,
	onLogTypeChange: _.noop,
	onQueryParsed: _.noop,
	disabledDate: undefined,
	logType: '',
	query: '',
	maxLength: 2048,
	timeFormat: 'YYYY/MM/DD HH:mm:ss'
}
