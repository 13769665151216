import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { FormContext, FormConsumer } from "../../FormContext";
import Pan from "../../schema/Pan";
import { FormGroup, Col, FormText } from "reactstrap";
import {
    FormFeedbackWidget,
    AsyncSelectWidget,
    SelectWidget,
    RadioWidget
} from '../../widgets';

import {
    capitalizeLetter,
    getFieldLabelComp,
    getFieldWidthClass,
    getFieldHelpString,
    getCompletionXpath,
    isFieldEndsWithMember,
    getMemberCompletionField,
    getEnumsFromMemberCompletionField
} from "../../schema/utils";

import { getPathValue, getPathValues, getRecordPathWithFilters } from "../../../utils/json";
import { BaseFieldBuilder } from '../BaseBuilder';
import { ViewerDisplayActionBuilder } from "../ViewerDisplayActionBuilder";
import { INJECTED_REC_ID, getRBAPermission, isPermissionEnabled } from '../../widgets/util';
import { getGenericAutoComplete } from "../../../../core/services/CommonServices";
import { components } from 'react-select';

import './CompletionBuilder.scss';
const debouncePromise = require('debounce-promise');

const CREATABLE = 'Creatable';
const SELECT = 'Select';
const ASYNC_SELECT = 'AsyncSelect';

export class CompletionBuilder extends BaseFieldBuilder {
    static NAME = 'CompletionBuilder';
    constructor(props) {
        super(props);
        this.id = Pan.id();
        this.state = {
            hideField: false,
            type: SELECT,
            value: null,
            formEditorDisplay: false,
            selectKey: 0,
        };
        this.select = React.createRef();
        this.setRecordValue = this.setRecordValue.bind(this);
        this.filterDefault = this.filterDefault.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.getMenuList = this.getMenuList.bind(this);
    }

    executeCompletionAssociation() {
        //clear the current selection value
        this.setRecordValue();
        //trigger completion
        this.setState({
            selectKey: this.state.selectKey + 1
        });
    }

    getErrors() {
        let { field, filters, cellInfo } = this.props;
        if (isFieldEndsWithMember(field)) {
            let { formData, errors, fields } = this.context;
            let childField = getMemberCompletionField(field, fields);
            let recPath = getRecordPathWithFilters(formData, childField&&childField.attrPath, filters, cellInfo && cellInfo.original[INJECTED_REC_ID]);
            return (errors[recPath]);
        }
        return super.getErrors();
    }

    setRecordValue(value) {
        if (value == undefined) {
            value = '';
        }
        let { field } = this.props;
        // if (value === undefined && field.allowBlank === false && field.defaultValue && !this.props.isEditorGridColumnEditor) {
        // when clearing selection for a field with default value, select the default value (not for EditorGridColumnEditor)
        // value = field.defaultValue;
        // }
        if (field.attrPath && field.attrPath.endsWith('.member.*')) {
            //member grid. value needs to be merged back to the object with same key
            value = Pan.apply(Pan.clone(this.getRecordValue()), { value: value });
        }
        super.setRecordValue(value, false);
        this.setState({
            value: value,
        });
    }

    afterSubmit = (record) => {
        if (record && (record['@name'] || record.name)) {
            //add this record to formData 
            this.setRecordValue(record['@name'] || record.name);
        }
        this.setState({
            formEditorDisplay: false,
            selectKey: this.state.selectKey + 1,
        });
    }

    getSelectOptions(field) {
        // if it is custom completion with static options, just return them back
        let completionCustomConfigOptions = getPathValue(field, 'uiHint.completionConfig.props.options');
        if (completionCustomConfigOptions) {
            return completionCustomConfigOptions;
        }

        // use value field for Label display
        let useValueAsDisplay = getPathValue(field, 'uiHint.useValueAsDisplay');

        // enum field
        let optionsEnum = field && field.uiHint ? field.uiHint.enum : null;
        optionsEnum = completionCustomConfigOptions || optionsEnum;
        if (optionsEnum && Array.isArray(optionsEnum)) {
            //options are from enum
            if (optionsEnum && field.uiHint && field.uiHint.allowBlank && !field.defaultValue) {
                if (optionsEnum[0][0] !== '') {
                    optionsEnum.unshift(['', 'None']);
                }
            }
            return optionsEnum.map((option) => {
                return { value: option[0], label: !useValueAsDisplay ? option[1] : option[0] };
            });
        }

        let completionConfig = field && field.uiHint ? field.uiHint.completionConfig : null;
        let localCompletion = completionConfig ? completionConfig.type === 'local' : false
        let localCompletionPath = localCompletion ? completionConfig.fieldPath : "";
        let localCompletionValueField = localCompletion ? completionConfig.valueField : "";

        // local completion
        if (localCompletion && localCompletionPath) {
            // for local completion, options list comes from another field in formData
            let { formData } = this.context;
            if (formData) {
                let pathObjs = getPathValues(formData, localCompletionPath);
                if (pathObjs && pathObjs.length > 0) {
                    return pathObjs.map(obj => {
                        return {
                            value: obj[localCompletionValueField],
                            label: obj[localCompletionValueField],
                        };
                    });
                } else {
                    return [];
                }
            } else {
                return [];
            }
        }

        // custom completion
        let customCompletion = completionConfig ? completionConfig.CustomCompletion : null;
        if ((customCompletion && Pan.isFunction(customCompletion))) {
            return customCompletion;
        }

        // the generic autocompletion
        return getGenericAutoComplete;
    }

    getLoadOptions(completionFn, completionXpath, configLocation, filterFn, doDebounce = false) {
        let { formData } = this.context;
        var invokeCompletion = doDebounce ? debouncePromise(completionFn, 500, { leading: false, trailing: true}) : completionFn;
		return inputValue => { 
			return new Promise((resolve, reject) => {
				if(Pan.isFunction(completionFn)){
					invokeCompletion(inputValue, completionXpath, configLocation,Pan.clone(formData), this.props.filters).then(resp => {
						resolve(filterFn(resp, formData));
					},
					err => {
						reject(err);
					})
				}
				else{
					resolve([]);
				}
			});
		};
    }


    getCustomProps(field) {
        let completionConfig =
            field && field.uiHint ? field.uiHint.completionConfig : null;
        let customProps = completionConfig ? completionConfig.props : null;
        return customProps;
    }

    getActions(field) {
        let completionConfig =
            field && field.uiHint ? field.uiHint.completionConfig : null;
        let actions = completionConfig ? completionConfig.actions : null;
        return actions;
    }

    getSelectType(field) {
        let optionsEnum = field && field.uiHint ? field.uiHint.enum : null;
        let isMultiple =
            field && field.type ? field.type === 'multiple' : false;
        let selectType =
            field && field.uiHint && field.uiHint.completionConfig
                ? field.uiHint.completionConfig.type
                : null;
        if (
            (optionsEnum && Array.isArray(optionsEnum)) ||
            selectType === 'local'
        ) {
            return SELECT;
        } else if (field.looseMembership === true || (isMultiple && field.multitypes['enum'] && get(field, "multitypes.string.content-dependent", "no") !== "yes")) {
            return CREATABLE; // Allow creating new
        } else {
            return ASYNC_SELECT; // get data from promise.
        }
    }

    filterDefault(result) {
		let { filters, cellInfo, field,isEditorGridColumnEditor } = this.props;
		let { formData } = this.context;

		if (isEditorGridColumnEditor) {

			if (field && field.defaultValue) {
				result.filter(val => val["value"] && val["value"] !== this.props.field.defaultValue);
			}

			if (field.attrPath.endsWith("member.*")) {
				let {attrPath} = field;
				attrPath=attrPath.substring(0, attrPath.length -2);
				let recPath = getRecordPathWithFilters(formData, attrPath, filters, cellInfo && cellInfo[INJECTED_REC_ID]);
                let currSelection = getPathValue(formData, recPath);
                
				if (currSelection) {
					let selectionMap = {};
					for (const selection of currSelection) {
						const { value } = selection;
						value && (selectionMap[value] = 1);
					}
					return result.filter(val => {
                        const { value } = val;
						return !(value && selectionMap[value] && value != cellInfo.value);
					});
				}
			}
		}
		return result;
	}

    getMenuList(actions) {
        let MenuList = props => {
            return (
                <components.MenuList {...props}>
                    {props.children}
                </components.MenuList>
            );
        };
        if (actions) {
            let actionItems = actions.map((action, index) => {
                let disabled = true;
                if (
                    action.rbaPath &&
                    isPermissionEnabled(getRBAPermission(action.rbaPath))
                ) {
                    disabled = action.disabled;
                }
                let viewerProps = action.viewerProps || {};
                let configLocation = viewerProps.configLocation ? viewerProps.configLocation : this.context.configLocation;

                return (
                    <ViewerDisplayActionBuilder
                        key={index}
                        field={{
                            uiHint: {
                                viewerName: action.viewerName,
                                viewer: action.viewer,
                                viewerProps: {
                                    ...action.viewerProps,
                                    configLocation,
                                    rbaPath: action.rbaPath,
                                    afterSubmit: this.afterSubmit
                                },
                                actionText: action.text,
                                actionProps: {
                                    style: { margin: 8, marginLeft: 0 }
                                },
                                disabled: disabled
                            }
                        }}
                    />
                );
            });
            MenuList = props => {
                return (
                    <components.MenuList {...props}>
                        <div className='actionHolder'>
                            {actionItems}
                        </div>
                        {props.children}
                    </components.MenuList>
                );
            };
        }
        return MenuList;
    }

    getSelectWidget() {
        let {
            name,
            field,
            onBlur,
            onFocus,
            disabled,
            isEditorGridColumnEditor,
            autoFocus,
            showErrors,
            selectType,
        } = this.props;

        const { recordConfigPath, configLocation } = this.context;
        const customStyles = isEditorGridColumnEditor ? {
            control: (base) => ({
                ...base,
                height: 32,
                minHeight: 32,
            }),
            dropdownIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            })
        } : {};

        // duplicate Options: we need to remove duplicate options from '__select__' editorGrid
        const duplicateOptions = getEnumsFromMemberCompletionField(field);
        // need to get allowBlank from original field
        const { allowBlank } = field.uiHint;

        if (isFieldEndsWithMember(field)) {
            field = getMemberCompletionField(field, this.context.fields);
        }

        selectType = selectType ? selectType : this.getSelectType(field);
        let options = this.getSelectOptions(field);
        let customProps = this.getCustomProps(field) || {};
        let actions = this.getActions(field);
        const value = !Pan.isEmpty(this.state.value) ? this.state.value : (this.getRecordValue() || this.getDefaultValue(field));
        let errors = this.getErrors();
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        let fieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let menuList = this.getMenuList(actions);
        let placeholder = getPathValue(field, 'uiHint.placeholder');

        let uiHint = field ? field.uiHint : {};
        let {
            completionConfig,
            query,
            groupOrder,
            largeSelectionCount
        } = uiHint;

        let filterFn = completionConfig && completionConfig.filterFn ? completionConfig.filterFn : this.filterDefault;
        //selection type can be multiple or single, array type is multi select
        let multiple = completionConfig ? completionConfig.multiple : field.isCollection || field.nodetype === 'array';
        let completionXpath = getCompletionXpath(this.props.field.attrPath, this.props.filters, this.context.formData, this.context.recordConfigPath);

        let selectWidget = '';
        let selectWidgetProps = {
            id: this.id,
            name: name,
            value: value,
            onChange: this.setRecordValue,
            onBlur: onBlur,
            onFocus: onFocus,
            recordConfigPath: recordConfigPath,
            multiple: multiple,
            field: field,
            className: fieldClassName,
            styles: customStyles,
            disabled: disabled || this.state.disableField,
            error: error,
            showErrors: showErrors,
            customProps: customProps,
            MenuList: menuList,
            selectKey: this.state.selectKey,
            autoFocus: autoFocus,
            groupOrder: groupOrder,
            placeholder: placeholder,
            isClearable: allowBlank,
            query
        };
        if (selectType === SELECT) {
            largeSelectionCount = largeSelectionCount || 3;
            let isTypeLocal = completionConfig && completionConfig.type === 'local' ? true : false;
            if (options.length <= largeSelectionCount && !isEditorGridColumnEditor && !isTypeLocal) {
                const enumOptions = options.map((name) => {
                    const label = capitalizeLetter(name.label);
                    const value = name.value;
                    return { label, value };
                });
                const inline = true;
                const eOptions = {
                    enumOptions,
                    inline,
                };
                selectWidget = <RadioWidget 
                    {...selectWidgetProps} 
                    options={eOptions} />;
            } else {
                selectWidget = <SelectWidget 
                    ref={this.select}
                    options={options} 
                    {...selectWidgetProps} />;
            }
        } else if (selectType === ASYNC_SELECT || selectType === CREATABLE) {
            if (query && query === 'remote') {
                // asyncselect
                selectWidget = <AsyncSelectWidget
                    ref={this.select}
                    loadOptions={this.getLoadOptions(options, completionXpath, configLocation, filterFn, true)}
                    creatable={selectType === CREATABLE}
                    duplicateOptions={duplicateOptions}
                    {...selectWidgetProps}
                />;
            } else {
                // regular select
                selectWidget = <SelectWidget
                    ref={this.select}
                    loadOptions={this.getLoadOptions(options, completionXpath, configLocation, filterFn)}
                    creatable={selectType === CREATABLE}
                    duplicateOptions={duplicateOptions}
                    {...selectWidgetProps}
                />;
            }
        } else {
            selectWidget = <div>Error in SelectType!</div>;
        }
        return selectWidget;
    }


    focus = () => {
        if (this.select && this.select.current.focus) {
            this.select.current.focus();
        }
    }

    renderCompletion = () => {
        const {
            name,
            field,
            disabled,
            showErrors,
            hideLabel,
            containerLabelWidthSize,
            isEditorGridColumnEditor
        } = this.props;

        if (
            this.props.hidden ||
            this.state.hideField ||
            getPathValues(field, 'uiHint.hidden')
        ) {
            return <div />;
        }
        // const {path} = this.context;
        // select widget options can be in three formats
        // 1. From uiHint.compeltionConfig - enum . This can be either from schema or field uiHint. If the uiHint has enum array, display that as value, label
        // 2. From uiHint.compeltionConfig - CustomCompletion. If uiHint has CustomCompletion function that returns a Promise, use it and show the response array as value, label
        // 3. From schema path . If the uiHint is not having enum or CustomCompletion, make a query to  get the completion for schem path of this field
        let selectWidget = this.getSelectWidget();
        let required = field.uiHint && field.uiHint.allowBlank === false ? 'required' : '';
        let LabelComp = getFieldLabelComp(field, name, isEditorGridColumnEditor, containerLabelWidthSize);
        let fieldClassName = getFieldWidthClass(field, isEditorGridColumnEditor);
        let helpString = getFieldHelpString(field, this.context.formData);
        let errors = this.getErrors();
        let showViewer = field.uiHint && field.uiHint.manageViewer && field.uiHint.manageViewer.showViewer ? true : false;
        let viewerProps = showViewer && field.uiHint.manageViewer.viewerProps ? field.uiHint.manageViewer.viewerProps : null;
        viewerProps = viewerProps ? { ...viewerProps, afterSubmit: this.afterSubmit } : null;
        if (viewerProps && field.uiHint.manageViewer.viewerProps) {
            field.uiHint.manageViewer.viewerProps = { ...viewerProps };
        }
        let error = Pan.isEmpty(errors) ? null : errors[0] || 'Invalid field value';
        return (
            <FormGroup row className={`d-flex flex-row ${required}`} test_id={field.attrPath}>
                {!hideLabel && LabelComp}
                <Col>
                    <div
                        className={'select-display-same-row ' + fieldClassName}
                        style={{ padding: 0 }}
                    >
                        {selectWidget}
                        {viewerProps &&
                            <ViewerDisplayActionBuilder
                                field={{
                                    uiHint: field.uiHint.manageViewer,
                                }}
                            />
                        }
                        {!this.state.hideField && helpString && <FormText>{helpString}</FormText>}
                    </div>
                    {error && showErrors && !disabled && <FormFeedbackWidget target={this.id} feedback={error} />}
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <FormConsumer>
                    {() => this.renderCompletion()}
                </FormConsumer>
            </React.Fragment>
        );
    }
}

CompletionBuilder.contextType = FormContext;

CompletionBuilder.defaultProps = {
    field: {},
    disabled: false,
    autoFocus: false,
};

if (process.env.NODE_ENV !== 'production') {
    CompletionBuilder.propTypes = {
        name: PropTypes.string,
        field: PropTypes.shape({
            attrPath: PropTypes.string,
            defaultValue: PropTypes.string,
            looseMembership: PropTypes.bool,
            type: PropTypes.string,
            multitypes: PropTypes.object,
            uiHint: PropTypes.shape({
                association: PropTypes.shape({
                    fields: PropTypes.object,
                }),
                manageViewer: PropTypes.shape({
                    showViewer: PropTypes.bool,
                    viewerProps: PropTypes.object,
                }),
                enum: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
                query: PropTypes.string,
                useValueAsDisplay: PropTypes.bool,
                completionConfig: PropTypes.oneOfType([
                    PropTypes.shape({
                        type: PropTypes.string,
                        customCompletion: PropTypes.func,
                        fieldPath: PropTypes.string,
                        valueField: PropTypes.string,
                        props: PropTypes.shape({
                            options: PropTypes.arrayOf(
                                PropTypes.shape({
                                    label: PropTypes.string,
                                    value: PropTypes.string,
                                }),
                            ),
                        }),
                    }),
                    PropTypes.string,
                ]),
            }),
        }),
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                find: PropTypes.string,
                replace: PropTypes.string,
                append: PropTypes.string,
            }),
        ),
        isEditorGridColumnEditor: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        disabled: PropTypes.bool,
        autoFocus: PropTypes.bool,
        showErrors: PropTypes.bool,
        selectType: PropTypes.oneOf(['Creatable', 'Select', 'AsyncSelect']),
        hidden: PropTypes.bool,
    };
}