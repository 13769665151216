import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

import { setState } from "../../schema/utils";
import './FileTextReaderWidget.scss';

function processFile(file) {
    // const { name, size, type } = file;
    return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.onerror = reject;
        reader.onload = event => {
            resolve({
                text: btoa(event.target.result),
            });
        };
        reader.readAsBinaryString(file);
    });
}

function processFiles(files) {
    return Promise.all([].map.call(files, processFile));
}

function FilesInfo(props) {
    const { values } = props;
    if (values.length === 0) {
        return null;
    }
    return (
        <div className={"file-info p-0 "}>
            {values.map((value, key) => {
                return (
                    <div className={'file-text'} key={key}>
                        <strong>{value}</strong>
                    </div>
                );
            })}
        </div>
    );
}

export class FileTextReaderWidget extends Component {
    constructor(props) {
        super(props);
        const { value } = props;
        const values = Array.isArray(value) ? value : [value];
        this.state = { values };
    }


    onChange = event => {
        const { multiple, onChange } = this.props;
        processFiles(event.target.files).then(filesInfo => {
            const state = {
                values: filesInfo.map(fileInfo => fileInfo.text)
            };
            setState(this, state, () => {
                if (multiple) {
                    onChange(state.values);
                } else {
                    onChange(state.values[0]);
                }
            });
        });
    };

    render() {
        const { id, readonly, disabled, autoFocus, 
            multiple, placeholder, onBlur, onFocus, className, showErrors, error } = this.props;
        const { values } = this.state;
        return (
            <div className={className}>
                {/* <FilesInfo values={values}  /> */}
                <Input
                    id={id}
                    type="file"
                    disabled={readonly || disabled}
                    onChange={this.onChange}
                    defaultValue=""
                    autoFocus={autoFocus}
                    multiple={multiple}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={className}
                    error={error}
                    showErrors={showErrors}
                />
            </div>
        );
    }
}

FileTextReaderWidget.defaultProps = {
    autoFocus: false,
    disabled:false,
    readonly:false,
    autoFocus:false
};

if (process.env.NODE_ENV !== "production") {
    FileTextReaderWidget.propTypes = {
        multiple: PropTypes.bool,
        id:PropTypes.string,
        readonly:PropTypes.bool,
        disabled:PropTypes.bool,
        autoFocus:PropTypes.bool,
        placeholder:PropTypes.string,
        onBlur:PropTypes.func,
        onFocus:PropTypes.func,
        className:PropTypes.string,
        showErrors:PropTypes.bool,
        error:PropTypes.string,
        onChange:PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        autoFocus: PropTypes.bool,
    };
}
