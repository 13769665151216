/*!
 * PAN Management UI
 * Copyright(c) Palo Alto Networks, Inc.
 */

let idSeed = 0;
const _T = str => str;
export default class Pan {
 
    static get emptyFn() {
        let fn = function() {};
        return fn;
    }

    /**
     * Copies all the properties of config to obj.
     * @param {Object} obj The receiver of the properties
     * @param {Object} config The source of the properties
     * @param {Object} defaults A different object that will also be applied for default values
     * @return {Object} returns obj
     * @member Pan apply
     */
    static apply(o, c, defaults) {
        // no "this" reference for friendly out of scope calls
        if (defaults) {
            Pan.apply(o, defaults);
        }
        if (o && c && typeof c == 'object') {
            for (var p in c) {
                o[p] = c[p];
            }
        }
        return o;
    }

    /**
     * Copies all the properties of config to obj if they don't already exist.
     * @param {Object} obj The receiver of the properties
     * @param {Object} config The source of the properties
     * @return {Object} returns obj
     */
    static applyIf(o, c) {
        if (o) {
            for (var p in c) {
                if (!Pan.isDefined(o[p])) {
                    o[p] = c[p];
                }
            }
        }
        return o;
    }

    static clone(obj) {
        var dup = function(obj) {
            var rv = obj;
            if (rv !== undefined && rv !== null) {
                rv = obj.__clone__;
                if (!Pan.isDefined(rv)) {
                    if (Pan.isArray(obj)) {
                        obj.__clone__ = rv = obj.slice(0);
                        for (var i = 0; i < rv.length; i++) {
                            rv[i] = dup(rv[i]);
                        }
                    }
                    else if (Pan.isObject(obj) && obj.__cloneable__ !== false) {
                        rv = {};
                        for (var m in obj) {
                            if (obj.hasOwnProperty(m)) {
                                rv[m] = dup(obj[m]);
                            }
                        }
                        obj.__clone__ = rv;
                    }
                    else {
                        rv = obj;
                    }
                }
            }
            return rv;
        };
        var rv = dup(obj);
        var cleanup = function(obj) {
            if (obj !== undefined && obj !== null) {
                if (Pan.isDefined(obj.__clone__)) {
                    delete obj.__clone__;
                }
                if (Pan.isArray(obj)) {
                    for (var i = 0; i < obj.length; i++) {
                        cleanup(obj[i]);
                    }
                }
                else if (Pan.isObject(obj) && obj.__cloneable__ !== false) {
                    for (var m in obj) {
                        if (obj.hasOwnProperty(m)) {
                            cleanup(obj[m]);
                        }
                    }
                }
            }
        };
        cleanup(obj);
        return rv;
    }

    /**
     * Generates unique ids. If the element already has an id, it is unchanged
     * @param {Mixed} el (optional) The element to generate an id for
     * @param {String} prefix (optional) Id prefix (defaults "pan-gen-")
     * @return {String} The generated Id.
     */
    static id(prefix) {
        const id = (prefix || "pan-gen-") + (++idSeed);
        return id;
    }

    /**
     * Takes an object and converts it to an encoded URL. 
     * e.g. Pan.urlEncode({foo: 1, bar: 2}); would return "foo=1&bar=2".  
     * Optionally, property values can be arrays, instead of keys and the resulting string 
     * that's returned will contain a name/value pair for each array value.
     * @param {Object} o
     * @param {String} pre (optional) A prefix to add to the url encoded string
     * @return {String}
     */
    static urlEncode(o, pre) {
        var empty,
            buf = [],
            e = encodeURIComponent;

        Pan.iterate(o, function (key, item) {
            empty = Pan.isEmpty(item);
            Pan.each(empty ? key : item, function (val) {
                buf.push('&', e(key), '=', (!Pan.isEmpty(val) && (val != key || !empty)) ? (Pan.isDate(val) ? Pan.encode(val).replace(/"/g, '') : e(val)) : '');
            });
        });
        if (!pre) {
            buf.shift();
            pre = '';
        }
        return pre + buf.join('');
    }

    /**
     * Takes an encoded URL and and converts it to an object. 
     * Example: Pan.urlDecode("foo=1&bar=2"); // returns {foo: "1", bar: "2"}
     * Pan.urlDecode("foo=1&bar=2&bar=3&bar=4", false); // returns {foo: "1", bar: ["2", "3", "4"]}
     * @param {String} string
     * @param {Boolean} overwrite (optional) Items of the same name will overwrite previous values instead of creating an an array (Defaults to false).
     * @return {Object} A literal with members
     */
    static urlDecode(str, overwrite) {
        if (Pan.isEmpty(str)) {
            return {};
        }
        var obj = {},
            pairs = str.split('&'),
            d = decodeURIComponent,
            name,
            value;
        pairs.forEach((pair) => {
            pair = pair.split('=');
            name = d(pair[0]);
            value = d(pair[1]);
            obj[name] = overwrite || !obj[name] ? value :
                [].concat(obj[name]).concat(value);
        });
        return obj;
    }

    /**
     * Appends content to the query string of a URL, handling logic for whether to place
     * a question mark or ampersand.
     * @param {String} url The URL to append to.
     * @param {String} s The content to append to the URL.
     * @return (String) The resulting URL
     */
    static urlAppend(url, s) {
        if (!Pan.isEmpty(s)) {
            return url + (url.indexOf('?') === -1 ? '?' : '&') + s;
        }
        return url;
    }

    static isIterable(v) {
        //check for array or arguments
        if (Pan.isArray(v) || v.callee) {
            return true;
        }
        //check for node list type
        if (/NodeList|HTMLCollection/.test(toString.call(v))) {
            return true;
        }
        //NodeList has an item and length property
        //IXMLDOMNodeList has nextNode method, needs to be checked first.
        return ((typeof v.nextNode != 'undefined' || v.item) && Pan.isNumber(v.length));
    }

    /**
     * Iterates an array calling the supplied function.
     * @param {Array/NodeList/Mixed} array The array to be iterated. If this
     * argument is not really an array, the supplied function is called once.
     * @param {Function} fn The function to be called with each item. If the
     * supplied function returns false, iteration stops and this method returns
     * the current <code>index</code>. This function is called with
     * the following arguments:
     * <div class="mdetail-params"><ul>
     * <li><code>item</code> : <i>Mixed</i>
     * <div class="sub-desc">The item at the current <code>index</code>
     * in the passed <code>array</code></div></li>
     * <li><code>index</code> : <i>Number</i>
     * <div class="sub-desc">The current index within the array</div></li>
     * <li><code>allItems</code> : <i>Array</i>
     * <div class="sub-desc">The <code>array</code> passed as the first
     * argument to <code>Pan.each</code>.</div></li>
     * </ul></div>
     * @param {Object} scope The scope (<code>this</code> reference) in which the specified function is executed.
     * Defaults to the <code>item</code> at the current <code>index</code>
     * within the passed <code>array</code>.
     * @return See description for the fn parameter.
     */
    static each(array, fn, scope) {
        if (Pan.isEmpty(array, true)) {
            return;
        }
        if (!Pan.isIterable(array) || Pan.isPrimitive(array)) {
            array = [array];
        }
        for (var i = 0, len = array.length; i < len; i++) {
            if (fn.call(scope || array[i], array[i], i, array) === false) {
                return i;
            };
        }
    }

    /**
     * Iterates either the elements in an array, or each of the properties in an object.
     * <b>Note</b>: If you are only iterating arrays, it is better to call {@link #each}.
     * @param {Object/Array} object The object or array to be iterated
     * @param {Function} fn The function to be called for each iteration.
     * The iteration will stop if the supplied function returns false, or
     * all array elements / object properties have been covered. The signature
     * varies depending on the type of object being interated:
     * <div class="mdetail-params"><ul>
     * <li>Arrays : <tt>(Object item, Number index, Array allItems)</tt>
     * <div class="sub-desc">
     * When iterating an array, the supplied function is called with each item.</div></li>
     * <li>Objects : <tt>(String key, Object value, Object)</tt>
     * <div class="sub-desc">
     * When iterating an object, the supplied function is called with each key-value pair in
     * the object, and the iterated object</div></li>
     * </ul></div>
     * @param {Object} scope The scope (<code>this</code> reference) in which the specified function is executed. Defaults to
     * the <code>object</code> being iterated.
     */
    static iterate(obj, fn, scope) {
        if (Pan.isEmpty(obj)) {
            return;
        }
        if (Pan.isIterable(obj)) {
            Pan.each(obj, fn, scope);
            return;
        } else if (Pan.isObject(obj)) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (fn.call(scope || obj, prop, obj[prop], obj) === false) {
                        return;
                    };
                }
            }
        }
    }

    /**
     * <p>Returns true if the passed value is empty.</p>
     * <p>The value is deemed to be empty if it is<div class="mdetail-params"><ul>
     * <li>null</li>
     * <li>undefined</li>
     * <li>an empty array</li>
     * <li>a zero length string (Unless the <tt>allowBlank</tt> parameter is <tt>true</tt>)</li>
     * </ul></div>
     * @param {Mixed} value The value to test
     * @param {Boolean} allowBlank (optional) true to allow empty strings (defaults to false)
     * @return {Boolean}
     */
    static isEmpty(v, allowBlank) {
        return v === null || v === undefined || ((Pan.isArray(v) && !v.length)) || ((Pan.isObject(v) && Object.keys(v).length === 0)) || (!allowBlank ? v === '' : false);
    }

    /**
     * Returns true if the passed value is a JavaScript array, otherwise false.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isArray(v) {
        return toString.apply(v) === '[object Array]';
    }

    /**
     * Returns true if the passed object is a JavaScript date object, otherwise false.
     * @param {Object} object The object to test
     * @return {Boolean}
     */
    static isDate(v) {
        return toString.apply(v) === '[object Date]';
    }

    /**
     * Returns true if the passed value is a JavaScript Object, otherwise false.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isObject(v) {
        return !!v && Object.prototype.toString.call(v) === '[object Object]';
    }

    /**
     * Returns true if the passed value is a JavaScript 'primitive', a string, number or boolean.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isPrimitive(v) {
        return Pan.isString(v) || Pan.isNumber(v) || Pan.isBoolean(v);
    }

    /**
     * Returns true if the passed value is a JavaScript Function, otherwise false.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isFunction(v) {
        return toString.apply(v) === '[object Function]';
    }

    /**
     * Returns true if the passed value is a number. Returns false for non-finite numbers.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isNumber(v) {
        return typeof v === 'number' && isFinite(v);
    }

    /**
     * Returns true if the passed value is a string.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isString(v) {
        return typeof v === 'string';
    }

    /**
     * Returns true if the passed value is a boolean.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isBoolean(v) {
        return typeof v === 'boolean';
    }

    /**
     * Returns true if the passed value is not undefined.
     * @param {Mixed} value The value to test
     * @return {Boolean}
     */
    static isDefined(v) {
        return typeof v !== 'undefined';
    }

    /**
     * Returns object encoded for HTML
     * @param {object} value The value to encode
     * @return {String}
     */
    static htmlEncode(object) {
        if (Pan.isArray(object)) {
            Pan.each(object, function(value, index, array) {
                array[index] = Pan.htmlEncode(value);
            });
            return object;
        }
        else if (Pan.isObject(object)) {
            for (let property in object) {
                if (object.hasOwnProperty(property)) {
                    var value = object[property];
                    object[property] = Pan.htmlEncode(value);
                }
            }
            return object;
        }
        else if (Pan.isString(object)) {
            return object.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
        }
        else if (object === null || object === undefined) {
            return object;
        }
        else {
            return object;
        }
    }

    /*
        Generate GUID
    */
    static guid() {
        var result, i, j;
        result = '';
        for(j=0; j<32; j++) {
          if( j == 8 || j == 12 || j == 16 || j == 20) 
            result = result + '-';
          i = Math.floor(Math.random()*16).toString(16).toUpperCase();
          result = result + i;
        }
        return result;
      }

    static unit  = {
        second : _T('sec'),
        seconds : _T('sec'),
        minutes : _T('min'),
        minute : _T('min'),
        hours: _T('hours'),
        hour: _T('hour'),
        days: _T('days'),
        percent: "%",
        milliseconds: _T('ms'),
        mb: _T('MB'),
        kb: _T('KB'),
        pkts: _T("packets"),
        number: _T("number")
    }


}
