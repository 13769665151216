import React from "react";
import { Component } from "react";
import { ExtraInfoContext, Pan } from "ui-lib";
import _ from "lodash";

const types = ["allow", "alert", "block", "continue", "override"];

const getAllowCategories = (data, allCategories) => {
	let notAllowCategories = [];
	if (Pan.isDefined(data)) {
		types.forEach(type => {
			if (type !== "allow" && Pan.isDefined(data[type])) {
				notAllowCategories = notAllowCategories.concat(data[type]["member"]);
			}
		});
		const reducer = (accumulator, currentItem) => {
			if (!notAllowCategories.includes(currentItem.value)) {
				accumulator.push(currentItem.value);
			}
			return accumulator;
		};
		_.set(data, "allow.member", allCategories.reduce(reducer, []));
		return data;
	}
	return {};
};

const gcategoriesDisplayData = data => {
	let value = [];
	if (!Pan.isEmpty(data)) {
		types.forEach(type => (value = value.concat(categoriesDisplayStructure(data[type], type))));
	}
	return value;
};

const categoriesDisplayStructure = (category, categoryName) => {
	let value = [];
	if (!Pan.isEmpty(category)) {
		if (Array.isArray(category["member"])) {
			value.push(<div>{categoryName + "Categories (" + category.member.length + ") : "} </div>);
			category.member.forEach(item => {
				value.push(<div>{item}</div>);
			});
			return value;
		}
	}
	value.push(<div>{categoryName + "Categories (0) : "}</div>);
	return value;
};

export class DisplayCategories extends Component {
	constructor(props) {
		super(props);
		this.state = {
			len: 0
		};
	}

	renderCategories = () => {
		const allCategories = Pan.isDefined(this.context.extraInfo) && Pan.isDefined(this.context.extraInfo["URLFilteringCategories"]) ? this.context.extraInfo["URLFilteringCategories"] : [];
		const data = getAllowCategories(this.props.data, allCategories);
		return gcategoriesDisplayData(data);
	};

	render() {
		return <div>{this.renderCategories()}</div>;
	}
}
DisplayCategories.contextType = ExtraInfoContext;
