import TPLBaseViewer from '../../template/TPLBaseViewer';

const _T = str => str;

const localUsersFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name'),
            disableOnEditRecord: true
        }
    },
    {
        name: '$.phash',
        uiHint: {
            fieldLabel: _T('Password'),
            builder: 'PasswordBuilder'
        }
    },
    {
        name: '$',
        childrenNames: [
            'name',
            '$.phash'
        ],
        uiHint: {
            labelWidthSize: 5
        }
    }
];

const localUsersColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    recordConfigPath: '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.local-user-database.user.entry'
}];

export default class LocalUsers extends TPLBaseViewer {
    serviceName = 'Device/LocalUsers';
    reduxId = 'localUsers';
    header = 'Temporary Test Users';
    columns = localUsersColumns;
    fields = localUsersFields;
    rbaPath= 'device.local-user-database.users';
    recordConfigPath = '$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.local-user-database.user.entry';
    showDefaultCloneAction = false;
}