import { ViewerManager, Pan } from "ui-lib";
import TPLBaseViewer from "../../template/TPLBaseViewer";
import getTPLRecordViewer from '../../template/TPLRecordViewer';

const _T = str => str;

const samlIdPProfileFields = [
	{
		type: "string",
		name: "name",
		mapping: "@name",
		uiHint: {
			fieldLabel: _T("Name")
		}
	},
	{
		name: "$.admin-use-only"
	},
	{
		name: "$.entity-id",
		uiHint: {
			fieldLabel: _T("Identity Provider ID")
		}
	},
	{
		name: "$.certificate",
		uiHint: {
			fieldLabel: _T("Identity Provider Certificate"),
			showHelpString: _T("Import the IdP public key certificate to use to verify the authenticity of SAML responses."),
            completionConfig: {
                actions: [
                  {
                    text: "Import",
                    rbaPath: 'device.certificate-management.certificates',
                    viewerName: "CertificateImport",
                    viewerProps: {
					  windowSize: "lg",
					  title: _T('Import Certificate'),
                      labelWidthSize: 3
                    }
                  }
                ]
            }
		}
	},
	{
		name: "$.sso-url",
		uiHint: {
			fieldLabel: _T("Identity Provider SSO URL")
		}
	},
	{
		name: "$.slo-url",
		uiHint: {
			fieldLabel: _T("Identity Provider SLO URL")
		}
	},
	{
		name: "$.sso-bindings",
		uiHint: {
			fieldLabel: _T("SAML HTTP Binding for SSO Requests to IDP")
		}
	},
	{
		name: "$.slo-bindings",
		defaultValue: 'post',
		uiHint: {
			allowBlank: false,
			fieldLabel: _T("SAML HTTP Binding for SLO Requests to IDP")
		}
	},
	{
		name: "$.validate-idp-certificate",
		defaultValue: "no",
		uiHint: {
			fieldLabel: _T("Validate Identity Provider Certificate"),
			allowBlank: false, //ADI-1354 - hide this and set no by default 
			fieldRequired: true,
			hidden: true,
			// association: {
			// 	fields: ['$.certificate'],
				// updateFormData: function (values, formData, filters, key) {
				// 	// None cert is selected
				// 	if (values && values[0] === false) {
				// 		return { '$.validate-idp-certificate': "no" };
				// 	}
				// }, 
				// availDisable: function (values, formData, filters, key) {
				// 	// None cert is selected
				// 	return (values && values[0] === false);
				// }, 
            // }
		}
	},
	{
		name: "$.want-auth-requests-signed",
		defaultValue: "yes",
		uiHint: {
			fieldLabel: _T("Sign SAML Message to IDP"),
			allowBlank: false, //ADI-1354 - set this to yes by default
		}
	},
	{
		name: "$.max-clock-skew",
		uiHint: {
			fieldLabel: _T("Maximum time difference (seconds)")
		}
	},
	{
		name: "IdpConfig",
		childrenNames: [
			"$.entity-id",
			"$.certificate",
			"$.sso-url",
			"$.slo-url",
			"$.sso-bindings",
			"$.slo-bindings",
			"$.validate-idp-certificate",
			"$.want-auth-requests-signed",
			"$.max-clock-skew"
		],
		uiHint: {
			builder: "FieldSetBuilder",
			fieldLabel: _T("Identity Provider Configuration")
		}
	},
	{
		name: "$",
		childrenNames: ["name", "location", "$.admin-use-only", "IdpConfig"],
		uiHint: {
			labelWidthSize: 6
		}
	}
];

const samlIdPProfileColumns = [
	{
		type: ['nameColumn', 'clickableColumn'],
		recordConfigPath: "$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.server-profile.saml-idp.entry"
	}
];

const viewConfig = {
	widerForm: true,
	serviceName : "Device/SAMLIdPProfiles",
	reduxId : "samlIDPProfile",
	header : "SAML IDP Profile",
	columns : samlIdPProfileColumns,
	fields : samlIdPProfileFields,
	rbaPath : "device.server-profile.saml_idp",
	recordConfigPath : "$.config.devices.entry.template.entry.config.devices.entry.vsys.entry.server-profile.saml-idp.entry",
    className: 'SAMLIDPProfile'
}
export default class SAMLIDPProfile extends TPLBaseViewer {
	constructor(props) {
        super(props);
        Pan.apply(this, viewConfig);
    }
}

ViewerManager.registerViewConfig(viewConfig, getTPLRecordViewer);
