import React from 'react';
import {createPortal} from 'react-dom';
import './LoadingWidget.scss';

export class LoadingWidget extends React.Component {
  constructor(props) {
    super(props);
    const doc = window.document;
    this.container = ( this.props.renderTo && document.getElementById(this.props.renderTo) ) || doc.body;
    this.node = doc.createElement('div');
    this.node.className = "load-container";
    this.container.appendChild(this.node);
  }

  render() {
    return createPortal(
      <div className="loader">      
      </div>, 
      this.node 
    );
  }

  componentWillUnmount() {
    this.container.removeChild(this.node);
  }
}