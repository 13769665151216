import Address from '../objects/address'
import AddressGroups from '../objects/addressGroups';
import ApplicationFilters from '../objects/applicationFilters';
import ApplicationGroups from '../objects/applicationGroups';
import Applications from '../objects/applications';
//import DataFiltering from '../objects/dataFiltering';
import DosProtectionProfile from '../objects/dosProtectionProfile';
import ExternalDynamicLists from '../objects/externalDynamicLists';
import FileBlocking from '../objects/fileBlocking';
import HipObjects from '../objects/hipObjects';
import HipProfiles from '../objects/hipProfiles';
import Regions from '../objects/regions';
import ServiceGroups from '../objects/serviceGroups';
import {Services} from '../objects/services';
import Tags from '../objects/tags';
import URLFiltering from '../objects/urlFiltering';
import VulnerabilityProtection from '../objects/vulnerabilityProtection';
import WildFireAnalysis from '../objects/wildFireAnalysis';
import {AppOverridePolicies} from '../policies/appOverride';
import {AuthPolicies} from '../policies/auth';
import {DecryptionPolicies} from '../policies/decryption';
import {DosPolicies} from '../policies/dos';
import {PbfPolicies} from '../policies/pbf';
import {QoSPolicies} from '../policies/qos';
import {SecurityPolicies} from '../policies/security';
import {SecurityPostPolicies} from '../policies/securityPost';
import URLCategory from '../objects/urlCategory/URLCategory';

export const dgViews = {
    'Address': Address,
    'AddressGroups': AddressGroups,
    'ApplicationFilters': ApplicationFilters,
    'ApplicationGroups': ApplicationGroups,
    'Applications': Applications,
    //'DataFiltering': DataFiltering,
    'DosProtectionProfile': DosProtectionProfile,
    'ExternalDynamicLists': ExternalDynamicLists,
    'FileBlocking': FileBlocking,
    'HipObjects': HipObjects,
    'HipProfiles': HipProfiles,
    'Regions': Regions,
    'ServiceGroups': ServiceGroups,
    'Services': Services,
    'Tags': Tags,
    'URLFiltering': URLFiltering,
    'VulnerabilityProtection': VulnerabilityProtection,
    'WildFireAnalysis': WildFireAnalysis,
    'AppOverridePolicies': AppOverridePolicies,
    'AuthPolicies': AuthPolicies,
    'DecryptionPolicies': DecryptionPolicies,
    'DosPolicies': DosPolicies,
    'PbfPolicies': PbfPolicies,
    'QoSPolicies': QoSPolicies,
    'SecurityPolicies': SecurityPolicies,
    'SecurityPostPolicies': SecurityPostPolicies,
    'URLCategory': URLCategory
}


