import {
    parseError,
    SHOW_LOADING,
    HIDE_LOADING,
    Pan,
    SERVER_ERROR
} from 'ui-lib';

import {
    GPCS_COMMON_TPL,
    getRecordsActionObservable,
    addRecordActionObservable,
    editRecordActionObservable,
    getRecordActionObservable,
    addSimpleRecordActionObservable,
    renameConfigItem
} from 'service-lib';

import {
    generateMUCertificateObservable,
    getGlobalMapRegionInfoObservable,
    completeRegionsObservable,
    getMobileUsersFQDNActionObservable,
    getAPIKeyActionObservable,
    generateAPIKeyActionObservable,
    fetchGPaaSDetailInfoObservable,
    doMUCommitAndPushActionObservable,
    getMUJobDetailsActionObservable,
    getNotificationObservable,
    postNotificationObservable,
    getNetworkDetailDataObservable,
    getRemoteNetworkDetailsObservable,
    getSSLDecryptionObservable,
    getServiceConnectionNetworkDetailsObservable,
    getMobileUsersNetworkDetailsObservable,
    getGPCSRegionDetailsObservable,
    getBGPStatusObservable,
    getMobileUserProvisioningStatusObservable,
    checkMobileUserPortalFqdnObservable
} from './services';

import {
    mergeMap,
    map,
    concatMap,
    switchMap,
    catchError,
    takeUntil,
    debounceTime
} from 'rxjs/operators';

import { ofType } from 'redux-observable';
import { of, concat, forkJoin, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import _ from 'lodash';

export const LOAD_GPCS_ITEMS = 'LOAD_GPCS_ITEMS';
export const FETCH_GPCS_ITEM = 'FETCH_GPCS_ITEM';
export const FETCH_GPCS_COMMON_INFRA = 'FETCH_GPCS_COMMON_INFRA';
export const FETCH_MU_FQDN = 'FETCH_MU_FQDN';
export const FETCH_API_KEY = 'FETCH_API_KEY';
export const UPDATE_GPCS_REDUX_STATE = 'UPDATE_GPCS_REDUX_STATE';
export const START_GPCS_LOADING = 'START_GPCS_LOADING';
export const FINISH_GPCS_LOADING = 'FINISH_GPCS_LOADING';
export const START_WORKFLOW_LOADING = 'START_WORKFLOW_LOADING';
export const FINISH_WORKFLOW_LOADING = 'FINISH_WORKFLOW_LOADING';
export const SHOW_WORKFLOW = 'SHOW_WORKFLOW';
export const HIDE_WORKFLOW = 'HIDE_WORKFLOW';
export const GPCS_LOADING = 'GPCS_LOADING';
export const WORKFLOW_LOADING = 'WORKFLOW_LOADING';
export const HIDE_GPCS_ERROR = 'HIDE_GPCS_ERROR';
export const GET_GLOBAL_MAP_REGION_INFO = 'GET_GLOBAL_MAP_REGION_INFO';
export const GET_GLOBAL_MAP_REGION_INFO_SUCCESS = 'GET_GLOBAL_MAP_REGION_INFO_SUCCESS';
export const GET_GLOBAL_MAP_REGION_INFO_FAIL = 'GET_GLOBAL_MAP_REGION_INFO_FAIL';
export const COMMON_INFRA_SERVICE = 'workflow/commonInfra';
export const MU_FQDN_SERVICE = 'gpFQDN';
export const API_KEY_SERVICE = 'apiKey';
export const FORWARD_TRUST_SERVICE = 'Device/CertificateManagement/SSLDecrypt';
export const CERT_GEN_REDUXID = 'CERT_GEN_REDUXID';
export const MU_FQDN = 'MU_FQDN';
export const API_KEY = 'API_KEY';
export const POLLING_MU_STATUS = 'POLLING_MU_STATUS';
export const POLLING_MU_STATUS_SUCCESS = 'POLLING_MU_STATUS_SUCCESS';
export const STOP_POLLING_MU_STATUS = 'STOP_POLLING_MU_STATUS';
export const STOP_POLLING_MU_PROVISION_STATUS = 'STOP_POLLING_MU_PROVISION_STATUS';

export const ADD_PUSH_GPCS_ITEM = 'ADD_PUSH_GPCS_ITEM';
export const EDIT_PUSH_GPCS_ITEM = 'EDIT_PUSH_GPCS_ITEM';
export const DO_MU_COMMIT_PUSH = 'DO_MU_COMMIT_PUSH';
export const DO_MU_COMMIT_PUSH_SUCCESS = 'DO_MU_COMMIT_PUSH_SUCCESS';
export const FETCH_MU_JOB_DETAILS = 'FETCH_MU_JOB_DETAILS';
export const FETCH_MU_JOB_DETAILS_SUCCESS = 'FETCH_MU_JOB_DETAILS_SUCCESS';
export const GENERATE_GPCS_MU_CERTS = 'GENERATE_GPCS_MU_CERTS';
export const GENERATE_GPCS_MU_AUTH_CERT = 'GENERATE_GPCS_MU_AUTH_CERT';
export const MARK_CERT_FORWARD_UNTRUST = 'MARK_CERT_FORWARD_UNTRUST';
export const MARK_CERT_FORWARD_TRUST = 'MARK_CERT_FORWARD_TRUST';
export const FORWARD_TRUST_CERT_NAME = 'Forward-Trust-CA';
export const FORWARD_UNTRUST_CERT_NAME = 'Forward-UnTrust-CA';
export const FORWARD_TRUST_CERT_NAME_ECDSA = 'Forward-Trust-CA-ECDSA';
export const FORWARD_UNTRUST_CERT_NAME_ECDSA = 'Forward-UnTrust-CA-ECDSA';
export const SAML_SIGNING_CERT_NAME = 'SAML-Signing-Cert';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';
export const POST_NOTIFICATION = 'POST_NOTIFICATION';
export const POST_NOTIFICATION_SUCCESS = 'POST_NOTIFICATION_SUCCESS';
export const POST_NOTIFICATION_ERROR = 'POST_NOTIFICATION_ERROR';
export const GET_NETWORK_DETAIL_DATA = 'GET_NETWORK_DETAIL_DATA';
export const GET_NETWORK_DETAIL_DATA_SUCCESS = 'GET_NETWORK_DETAIL_DATA_SUCCESS';
export const GET_NETWORK_DETAIL_DATA_ERROR = 'GET_NETWORK_DETAIL_DATA_ERROR';
export const SHOW_GPCS_FORM = "SHOW_GPCS_FORM";
export const HIDE_GPCS_FORM = "HIDE_GPCS_FORM";
export const REMOTE_NETWORK_DETAILS = 'REMOTE_NETWORK_DETAILS';
export const REMOTE_NETWORK_DETAILS_SERVICE = 'gpcs/remoteNetworkDetails';
export const REMOTE_NETWORK_DETAILS_ERROR = 'REMOTE_NETWORK_DETAILS_ERROR';
export const REMOTE_NETWORK_DETAIL_DATA_SUCCESS = 'REMOTE_NETWORK_DETAIL_DATA_SUCCESS';
export const SERVICE_CONNECTION_NETWORK_DETAILS = 'SERVICE_CONNECTION_NETWORK_DETAILS';
export const SERVICE_CONNECTION_NETWORK_DETAILS_SERVICE = 'gpcs/serviceConnectionNetworkDetails';
export const SERVICE_CONNECTION_NETWORK_DETAILS_ERROR = 'SERVICE_CONNECTION_NETWORK_DETAILS_ERROR';
export const SERVICE_CONNECTION_DETAIL_DATA_SUCCESS = 'SERVICE_CONNECTION_DETAIL_DATA_SUCCESS';
export const MOBILE_USERS_NETWORK_DETAILS = 'MOBILE_USERS_NETWORK_DETAILS';
export const MOBILE_USERS_NETWORK_DETAILS_SERVICE = 'gpcs/mobileUsersNetworkDetails';
export const MOBILE_USERS_NETWORK_DETAILS_ERROR = 'MOBILE_USERS_NETWORK_DETAILS_ERROR';
export const MOBILE_USERS_NETWORK_DETAILS_DATA_SUCCESS = 'MOBILE_USERS_NETWORK_DETAILS_DATA_SUCCESS';
export const MOBILE_USERS_CHECK_PORTAL_FQDN = 'MOBILE_USERS_CHECK_PORTAL_FQDN';
export const MOBILE_USERS_CHECK_PORTAL_FQDN_SUCCESS = 'MOBILE_USERS_CHECK_PORTAL_FQDN_SUCCESS';
export const MOBILE_USERS_CHECK_PORTAL_FQDN_ERROR = 'MOBILE_USERS_CHECK_PORTAL_FQDN_ERROR';
export const GET_API_KEY = "GET_API_KEY";
export const GET_API_KEY_SUCCESS = "GET_API_KEY_SUCCESS";
export const GET_API_KEY_ERROR = "GET_API_KEY_ERROR";
export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const GENERATE_API_KEY_SUCCESS = "GENERATE_API_KEY_SUCCESS";
export const GENERATE_API_KEY_ERROR = "GENERATE_API_KEY_ERROR";
export const FETCH_GPCS_CONFIG_STATUS = "FETCH_GPCS_CONFIG_STATUS";
export const FETCH_SSL_DECRYPTION = "FETCH_SSL_DECRYPTION";
export const FETCH_SSL_DECRYPTION_FAIL = "FETCH_SSL_DECRYPTION_FAIL";
export const FETCH_SSL_DECRYPTION_SUCCESS = "FETCH_SSL_DECRYPTION_SUCCESS";
export const FETCH_GPCS_CONFIG_STATUS_SUCCESS = "FETCH_GPCS_CONFIG_STATUS_SUCCESS";
export const FETCH_GPCS_CONFIG_STATUS_FAIL = "FETCH_GPCS_CONFIG_STATUS_FAIL";

export const BGP_STATUS = 'BGP_STATUS';
export const BGP_STATUS_ERROR = 'BGP_STATUS_ERROR';
export const BGP_STATUS_DATA_SUCCESS = 'BGP_STATUS_DATA_SUCCESS';


export const POLLING_MU_PROVISIONING_STATUS = 'POLLING_MU_PROVISIONING_STATUS';
export const MU_PROVISIONING_STATUS = 'MU_PROVISIONING_STATUS';
export const MU_PROVISIONING_ERROR = 'MU_PROVISIONING_ERROR';
export const MU_PROVISIONING_SUCCESS = 'MU_PROVISIONING_SUCCESS';
export const SERVICE_INFRASTRUCTURE_REDUX_ID = 'serviceInfrastructure';


const FORWARD_TRUST_CERT_CONFIG = {
    certType: 'forward-trust-certificate',
};

const FORWARD_UNTRUST_CERT_CONFIG = {
    certType: 'forward-untrust-certificate',
};

export const startGpcsLoading = reduxStateId => ({
    type: START_GPCS_LOADING,
    reduxStateId,
});

export const finishGpcsLoading = reduxStateId => ({
    type: FINISH_GPCS_LOADING,
    reduxStateId,
});

export const showWorkflow = (reduxStateId, locationPath) => ({
    type: SHOW_WORKFLOW,
    reduxStateId,
    locationPath
});

export const hideWorkflow = (reduxStateId, locationPath) => ({
    type: HIDE_WORKFLOW,
    reduxStateId,
    locationPath
});

export const startWorkflowLoading = reduxStateId => ({
    type: START_WORKFLOW_LOADING,
    reduxStateId,
});

export const finishWorkflowLoading = reduxStateId => ({
    type: FINISH_WORKFLOW_LOADING,
    reduxStateId,
});

export const loadInReduxGpcsItems = (items, reduxStateId) => ({
    type: LOAD_GPCS_ITEMS,
    items,
    reduxStateId,
});

export const generateMUDefaultConfigCerts = (reduxStateId, location) => ({
    type: GENERATE_GPCS_MU_CERTS,
    reduxStateId,
    location,
});

export const getRemoteNetworksDetails = (reduxStateId, location) => ({
    type: REMOTE_NETWORK_DETAILS,
    serviceName: REMOTE_NETWORK_DETAILS_SERVICE,
    reduxStateId,
    location
});

export const getSSLDecryption = (reduxStateId, location) => {
    return {
        type: FETCH_SSL_DECRYPTION,
        reduxStateId,
        location
    }
}


export const getServiceConnectionNetworksDetails = (reduxStateId, location) => ({
    type: SERVICE_CONNECTION_NETWORK_DETAILS,
    serviceName: SERVICE_CONNECTION_NETWORK_DETAILS_SERVICE,
    reduxStateId,
    location
});

export const getMobileUsersNetworkDetails = (reduxStateId, location) => ({
    type: MOBILE_USERS_NETWORK_DETAILS,
    serviceName: MOBILE_USERS_NETWORK_DETAILS_SERVICE,
    reduxStateId,
    location
});

export const checkMobileUserPortalFqdn = (reduxStateId, fqdn) => ({
    type: MOBILE_USERS_CHECK_PORTAL_FQDN,
    reduxStateId,
    fqdn,
});

export const fetchGPCSStatus = (reduxId) => ({
    type: FETCH_GPCS_CONFIG_STATUS,
    identifier: reduxId
});

export const markCertAsForwardTrust = (certName, location) => {
    return {
        type: MARK_CERT_FORWARD_TRUST,
        serviceName: FORWARD_TRUST_SERVICE,
        location,
        config: {
            ...FORWARD_TRUST_CERT_CONFIG,
            "@template": _.get(location, "tpl.name", GPCS_COMMON_TPL),
            name: certName,
        }
    };
};

export const markCertAsForwardUntrust = (certName, location) => {
    return {
        type: MARK_CERT_FORWARD_UNTRUST,
        serviceName: FORWARD_TRUST_SERVICE,
        location,
        config: {
            ...FORWARD_UNTRUST_CERT_CONFIG,
            "@template": _.get(location, "tpl.name", GPCS_COMMON_TPL),
            name: certName
        }
    };
};

export const generateMUAuthCert = (serviceName, reduxStateId, location) => ({
    type: GENERATE_GPCS_MU_AUTH_CERT,
    serviceName,
    reduxStateId,
    location,
});

export const fetchGpcsItem = (
    serviceName,
    recordId,
    reduxStateId,
    location,
) => ({
    type: FETCH_GPCS_ITEM,
    serviceName,
    recordId,
    reduxStateId,
    location,
});

export const fetchServiceInfra = (reduxStateId, location) => ({
    type: FETCH_GPCS_COMMON_INFRA,
    serviceName: COMMON_INFRA_SERVICE,
    reduxStateId: SERVICE_INFRASTRUCTURE_REDUX_ID,
    location: { extension: { gpcs: { name: "gpcs" } } }
});

export const fetchGpcsCommonInfra = (serviceName, reduxStateId, location) => ({
    type: FETCH_GPCS_COMMON_INFRA,
    serviceName,
    reduxStateId,
    location,
});

export const fetchMUFQDN = location => ({
    type: FETCH_MU_FQDN,
    serviceName: MU_FQDN_SERVICE,
    reduxStateId: MU_FQDN,
    location,
});

export const fetchAPIKey = location => ({
    type: FETCH_API_KEY,
    serviceName: API_KEY_SERVICE,
    reduxStateId: API_KEY,
    location,
});

export const generateAPIKey = location => ({
    type: GENERATE_API_KEY,
    serviceName: API_KEY_SERVICE,
    reduxStateId: API_KEY,
    location,
});

export const updateGpcsReduxState = (item, reduxStateId) => ({
    type: UPDATE_GPCS_REDUX_STATE,
    item,
    reduxStateId
});

export const addPushGpcsItem = (item, serviceMap, reduxStateId, location) => ({
    type: ADD_PUSH_GPCS_ITEM,
    item,
    serviceMap,
    reduxStateId,
    location,
});

export const editPushGpcsItem = (
    newItem,
    oldItem,
    serviceMap,
    reduxStateId,
    location,
) => ({
    type: EDIT_PUSH_GPCS_ITEM,
    item: { new: newItem, old: oldItem },
    serviceMap,
    reduxStateId,
    location,
});

export const showGPCSForm = reduxStateId => ({
    type: SHOW_GPCS_FORM,
    reduxStateId
});

export const hideGPCSForm = reduxStateId => ({
    type: HIDE_GPCS_FORM,
    reduxStateId
});

export const doMUCommitAndPushSuccess = response => ({
    type: DO_MU_COMMIT_PUSH_SUCCESS,
    response,
});
export const doMUCommitAndPush = params => ({
    type: DO_MU_COMMIT_PUSH,
    params,
});
export const fetchJobsDetails = jobId => ({
    type: FETCH_MU_JOB_DETAILS,
    jobId,
});
export const fetchJobsDetailsSuccess = response => ({
    type: FETCH_MU_JOB_DETAILS_SUCCESS,
    response,
});

export const fetchBGPStatus = (payload) => ({
    type: BGP_STATUS,
    payload
})

export const fetchMapRegionInfo = () => ({
    type: GET_GLOBAL_MAP_REGION_INFO
});

export const pullingProvisionStatus = (reduxStateId, postAction) => ({
    type: MU_PROVISIONING_STATUS,
    postAction: postAction
});

export const getGlobalMapRegionInfoSuccess = resp => {
    let regionInfo = [];
    let globalMapRegionInfo = {};
    if (
        resp['regionInfo'] &&
        resp['regionInfo'].response &&
        resp['regionInfo'].response.msg
    ) {
        regionInfo = resp['regionInfo'].response.msg.completions;
    }
    if (
        resp['globalMapRegionInfo'] &&
        resp['globalMapRegionInfo'].response &&
        resp['globalMapRegionInfo'].response.msg
    ) {
        globalMapRegionInfo = resp['globalMapRegionInfo'].response.msg.result.entry;
    }

    let contients = _.chain(regionInfo)
        .map('@continent')
        .uniq()
        .value();
    let contientRegionMap = _.zipObject(
        contients,
        _.map(contients, () => ({ total: 0, regions: [] })),
    );
    for (let i = 0; i < globalMapRegionInfo.length; i++) {
        let region = regionInfo.find(
            region => region['@display'] === globalMapRegionInfo[i]['display_name'],
        );
        contientRegionMap[region['@continent']].total++;
        contientRegionMap[region['@continent']].regions.push(
            globalMapRegionInfo[i],
        );
    }
    return {
        type: GET_GLOBAL_MAP_REGION_INFO_SUCCESS,
        info: contientRegionMap,
        globalMapRegionInfo,
        regionInfo,
    };
};

export const isPollingSuccessFn = resp => {
    const xhr = resp && resp.response && resp.response.result ? resp.response.result : null;
    let statusArray = [];

    if (xhr && resp.status === 200) {
        statusArray = xhr.entry && xhr.entry.length > 0 ? xhr.entry : [];
    }

    for (let i = 0; i < statusArray.length; i++) {
        if (statusArray[i]['Status']['value'] === 'OK') {
            return false;
        }
    }

    return true;
};

export const pollingMobileUserStatusSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg && resp.response.msg.result ? resp.response.msg.result : null;
    let statusArray = [];

    if (xhr && resp.status === 200) {
        statusArray = xhr.entry && xhr.entry.length > 0 ? xhr.entry : [];
    }

    return {
        type: POLLING_MU_STATUS_SUCCESS,
        mapPageData: statusArray,
        pollingSuccess: statusArray.length > 0
    };
};

export const getAPIKeySuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    let key = '';
    if (xhr && xhr['@status'] === 'success') {
        key = xhr.result;
    }
    return {
        type: GET_API_KEY_SUCCESS,
        apiKey: {
            value: key,
            copied: false
        }
    }
};

export const generateAPIKeySuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    let key = '';
    if (xhr && xhr['@status'] === 'success') {
        key = xhr.result;
    }
    return {
        type: GENERATE_API_KEY_SUCCESS,
        apiKey: {
            value: key,
            copied: false
        }
    }
};

export const getNotificationSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    let url = '';
    if (xhr && xhr['@status'] === 'success') {
        url = xhr.result['total-count'] > 0 ? xhr.result.url : url;
    }
    return {
        type: GET_NOTIFICATION_SUCCESS,
        notification_url: url
    }
};

export const postNotificationSuccess = (resp, url) => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    if (xhr && xhr.result === 'success') {
        return {
            type: POST_NOTIFICATION_SUCCESS,
            notification_url: url,
            notification_status: 'success'
        }
    } else {
        return {
            type: POST_NOTIFICATION_ERROR,
            notification_status: 'error'
        }
    }
}

export const getNetworkDetailDataSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    if (xhr && xhr['@status'] === 'success') {
        return {
            type: GET_NETWORK_DETAIL_DATA_SUCCESS,
            service_infra_status: xhr.result.entry[0]
        }
    } else {
        return {
            type: GET_NETWORK_DETAIL_DATA_SUCCESS,
            service_infra_status: 'error'
        }
    }
}

export const generateMUDefaultConfigCertsEpic = action$ =>
    action$.pipe(
        ofType(GENERATE_GPCS_MU_CERTS),
        mergeMap(action => {
            return concat(
                ajax(
                    generateMUCertificateObservable(),
                ).pipe(
                    mergeMap(response =>
                        of(finishGpcsLoading(action.reduxStateId)),
                    ),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        });
                    })
                ),
            )
        }),
    );

export const markCertAsForwardTrustEpic = (action$, store) => {
    return action$.pipe(
        ofType(MARK_CERT_FORWARD_TRUST),
        mergeMap(action => {
            return concat(
                ajax(
                    addSimpleRecordActionObservable(
                        action.config,
                        action.serviceName,
                        action.location,
                    ),
                ).pipe(
                    mergeMap(response =>
                        of(getSSLDecryption('', action.location),
                            finishGpcsLoading(action.reduxStateId)),
                    ),
                    catchError(error => {
                        return of(
                            {
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId,
                                showMessage: false
                            },
                            finishGpcsLoading(action.reduxStateId),
                        );
                    }),
                ),
            );
        }),
    );
};

export const markCertAsForwardUntrustEpic = (action$, store) => {
    return action$.pipe(
        ofType(MARK_CERT_FORWARD_UNTRUST),
        mergeMap(action => {
            return concat(
                ajax(
                    addSimpleRecordActionObservable(
                        action.config,
                        action.serviceName,
                        action.location,
                    ),
                ).pipe(
                    mergeMap(response =>
                        of(getSSLDecryption('', action.location),
                            finishGpcsLoading(action.reduxStateId)),
                    ),
                    catchError(error => {
                        return of(
                            {
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId,
                                showMessage: false
                            },
                            finishGpcsLoading(action.reduxStateId),
                        );
                    }),
                ),
            );
        }),
    );
};

export const fetchGpcsItemEpic = (action$, store) => {
    return action$.pipe(
        ofType(FETCH_GPCS_ITEM),
        mergeMap(action => {
            return concat(
                of(startGpcsLoading(action.reduxStateId)),
                ajax(
                    getRecordActionObservable(
                        action.serviceName,
                        action.recordId,
                        action.location,
                    ),
                ).pipe(
                    mergeMap(response =>
                        of(
                            finishGpcsLoading(action.reduxStateId),
                            loadInReduxGpcsItems(response, action.reduxStateId),
                        ),
                    ),
                    catchError(error => {
                        return of(
                            {
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId,
                            },
                            finishGpcsLoading(action.reduxStateId),
                        );
                    }),
                ),
            );
        }),
    );
};

export const fetchGpcsCommonInfraEpic = (action$, store) => {
    return action$.pipe(
        ofType(FETCH_GPCS_COMMON_INFRA),
        mergeMap(action =>
            ajax(
                getRecordsActionObservable(
                    COMMON_INFRA_SERVICE,
                    action.location,
                ),
            ).pipe(
                mergeMap(response =>
                    of(
                        finishGpcsLoading(action.reduxStateId),
                        loadInReduxGpcsItems(response, action.reduxStateId),
                    ),
                ),
                catchError(error => {
                    return of(
                        {
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        },
                        finishGpcsLoading(action.reduxStateId),
                    );
                }),
            ),
        ),
    );
};

export const fetchMobileUsersFQDNEpic = action$ =>
    action$.pipe(
        ofType(FETCH_MU_FQDN),
        mergeMap(action =>
            ajax(
                getMobileUsersFQDNActionObservable(),
            ).pipe(
                mergeMap(response =>
                    of(
                        finishGpcsLoading(action.reduxStateId),
                        loadInReduxGpcsItems(response, action.reduxStateId),
                    ),
                ),
                catchError(error => {
                    return of(
                        {
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        },
                        finishGpcsLoading(action.reduxStateId),
                    );
                }),
            ),
        ),
    );

export const fetchAPIKeyEpic = action$ =>
    action$.pipe(
        ofType(FETCH_API_KEY),
        mergeMap(action =>
            ajax(
                getAPIKeyActionObservable(),
            ).pipe(
                mergeMap(response =>
                    of(
                        finishGpcsLoading(action.reduxStateId),
                        loadInReduxGpcsItems(response, action.reduxStateId),
                    ),
                ),
                catchError(error => {
                    return of(
                        {
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        },
                        finishGpcsLoading(action.reduxStateId),
                    );
                }),
            ),
        ),
    );

export const getGlobalMapRegionInfoEpic = action$ => {
    return action$.pipe(
        ofType(GET_GLOBAL_MAP_REGION_INFO),
        mergeMap(action =>
            forkJoin({
                regionInfo: ajax(completeRegionsObservable()),
                globalMapRegionInfo: ajax(getGlobalMapRegionInfoObservable()),
            }).pipe(
                mergeMap(response =>
                    of(getGlobalMapRegionInfoSuccess(response)),
                ),
                catchError(error => {
                    return of({
                        type: GET_GLOBAL_MAP_REGION_INFO_FAIL,
                    });
                }),
            ),
        ),
    );
};

export const pollingMobileUserStatusEpic = action$ => {
    return action$.pipe(
        ofType(POLLING_MU_STATUS),
        mergeMap(action =>
            timer(0, 5000)
                .pipe(concatMap(() => ajax(fetchGPaaSDetailInfoObservable())))
                .pipe(
                    mergeMap(response =>
                        of(pollingMobileUserStatusSuccess(response)),
                    ),
                )
                .pipe(takeUntil(action$.ofType(STOP_POLLING_MU_STATUS)))
        ),
    );
};

export const doMUCommitAndPushEpic = action$ => {
    return action$.pipe(
        ofType(DO_MU_COMMIT_PUSH),
        mergeMap(action =>
            ajax(doMUCommitAndPushActionObservable()).pipe(
                mergeMap(response => of(doMUCommitAndPushSuccess(response))),
                catchError(error => {
                    return of({
                        type: SERVER_ERROR,
                        errorMessage: parseError(error),
                    });
                }),
            ),
        ),
    );
};

export const addPushGpcsItemEpic = action$ =>
    action$.pipe(
        ofType(ADD_PUSH_GPCS_ITEM),
        mergeMap(action => {
            return concat(
                of(startWorkflowLoading(action.reduxStateId.reduxId)),
                ajax(
                    addRecordActionObservable(
                        action.item,
                        action.serviceMap.POST,
                        action.location,
                    ),
                ).pipe(
                    mergeMap(response => {
                        if (response.response['@status'] === 'error') {
                            return of(
                                {
                                    type: SERVER_ERROR,
                                    errorMessage:
                                        response.response['msg'] &&
                                            response.response['msg'].length > 0
                                            ? response.response['msg'].join(',')
                                            : 'Unknown error occured',
                                    reduxStateId: action.reduxStateId.reduxId,
                                },
                                finishWorkflowLoading(action.reduxStateId.reduxId),
                            );
                        } else {
                            return of(
                                doMUCommitAndPush(),
                                finishWorkflowLoading(action.reduxStateId.reduxId),
                            );
                        }
                    }),
                    catchError(error => {
                        return of(
                            {
                                type: SERVER_ERROR,
                                errorMessage: parseError(error),
                                reduxStateId: action.reduxStateId.reduxId,
                            },
                            finishWorkflowLoading(action.reduxStateId.reduxId),
                        );
                    }),
                ),
            );
        }),
    );

export const editPushGpcsItemEpic = action$ =>
    action$.pipe(
        ofType(EDIT_PUSH_GPCS_ITEM),
        mergeMap(action => {
            if (action.item.old['@name'] !== action.item.new['@name']) {
                return concat(
                    of(startWorkflowLoading(action.reduxStateId.reduxId)),
                    ajax(
                        editRecordActionObservable(
                            action.item,
                            action.serviceMap.PUT,
                            action.location,
                        ),
                    ).pipe(
                        map(
                            response =>
                                renameConfigItem(
                                    action.item,
                                    action.serviceMap,
                                    action.reduxStateId,
                                    action.location,
                                ),
                            doMUCommitAndPush(),
                        ),
                        catchError(error => {
                            return of(
                                {
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(error),
                                    reduxStateId: action.reduxStateId.reduxId,
                                },
                                finishWorkflowLoading(action.reduxStateId.reduxId,),
                            );
                        }),
                    ),
                );
            } else {
                return concat(
                    of(startWorkflowLoading(action.reduxStateId.reduxId,)),
                    ajax(
                        editRecordActionObservable(
                            action.item,
                            action.serviceMap.PUT,
                            action.location,
                        ),
                    ).pipe(
                        mergeMap(response =>
                            of(
                                doMUCommitAndPush(),
                                finishWorkflowLoading(action.reduxStateId.reduxId,),
                            ),
                        ),
                        catchError(error => {
                            return of(
                                {
                                    type: SERVER_ERROR,
                                    errorMessage: parseError(error),
                                    reduxStateId: action.reduxStateId.reduxId,
                                },
                                finishWorkflowLoading(action.reduxStateId.reduxId,),
                            );
                        }),
                    ),
                );
            }
        }),
    );

export const fetchJobDetailsEpic = action$ => {
    return action$.pipe(
        ofType(FETCH_MU_JOB_DETAILS),
        mergeMap(action => {
            return concat(
                ajax(getMUJobDetailsActionObservable(action.jobId)).pipe(
                    mergeMap(response => of(fetchJobsDetailsSuccess(response))),
                    catchError(error => {
                        return of({
                            type: SERVER_ERROR,
                            errorMessage: parseError(error),
                            reduxStateId: action.reduxStateId,
                        });
                    }),
                ),
            );
        }),
    );
};

export const getAPIKeyEpic = action$ => {
    return action$.pipe(
        ofType(GET_API_KEY),
        mergeMap(action => {
            return concat(
                ajax(getAPIKeyActionObservable()).pipe(
                    mergeMap(response => of(getAPIKeySuccess(response))),
                    catchError(error => {
                        return of({
                            type: GET_API_KEY_ERROR,
                        });
                    }),
                ),
            );
        }),
    );
};

export const generationAPIKeyEpic = action$ => {
    return action$.pipe(
        ofType(GENERATE_API_KEY),
        mergeMap(action => {
            return concat(
                ajax(generateAPIKeyActionObservable()).pipe(
                    mergeMap(response => of(generateAPIKeySuccess(response))),
                    catchError(error => {
                        return of({
                            type: GET_API_KEY_ERROR,
                        });
                    }),
                ),
            );
        }),
    );
};

export const getNotificationEpic = action$ => {
    return action$.pipe(
        ofType(GET_NOTIFICATION),
        mergeMap(action => {
            return concat(
                ajax(getNotificationObservable()).pipe(
                    mergeMap(response => of(getNotificationSuccess(response))),
                    catchError(error => {
                        return of({
                            type: GET_NOTIFICATION_ERROR,
                        });
                    }),
                ),
            );
        }),
    );
};

export const postNotificationEpic = action$ => {
    return action$.pipe(
        ofType(POST_NOTIFICATION),
        debounceTime(2000),
        mergeMap(action => {
            return concat(
                ajax(postNotificationObservable(action.payload)).pipe(
                    switchMap(response => of(postNotificationSuccess(response, action.payload))),
                    catchError(error => {
                        return of({
                            type: POST_NOTIFICATION_ERROR,
                        });
                    }),
                ),
            );
        }),
    );
};

export const getNetworkDetailDataEpic = action$ => {
    return action$.pipe(
        ofType(GET_NETWORK_DETAIL_DATA),
        mergeMap(action => {
            return concat(
                ajax(getNetworkDetailDataObservable()).pipe(
                    mergeMap(response => of(getNetworkDetailDataSuccess(response))),
                    catchError(error => {
                        return of({
                            type: GET_NETWORK_DETAIL_DATA_ERROR,
                        });
                    }),
                ),
            );
        }),
    );
};


export const getRemoteNetworkDetailsEpic = action$ => {
    return action$.pipe(
        ofType(REMOTE_NETWORK_DETAILS),
        mergeMap(action => {
            return concat(
                ajax(getRemoteNetworkDetailsObservable()).pipe(
                    mergeMap(response => of(getRemoteNetworkDetailsSuccess(response))),
                    catchError(error => {
                        return of({
                            type: REMOTE_NETWORK_DETAILS_ERROR,
                            errorMessage: parseError(error),
                        });
                    }),
                ),
            );
        }),
    );
};

export const getServiceConnectionNetworkDetailsEpic = action$ => {
    return action$.pipe(
        ofType(SERVICE_CONNECTION_NETWORK_DETAILS),
        mergeMap(action => {
            return concat(
                ajax(getServiceConnectionNetworkDetailsObservable()).pipe(
                    mergeMap(response => of(getServiceConnectionNetworkDetailsSuccess(response))),
                    catchError(error => {
                        return of({
                            type: SERVICE_CONNECTION_NETWORK_DETAILS_ERROR,
                            errorMessage: parseError(error),
                        });
                    }),
                ),
            );
        }),
    );
};

export const fetchSSLDecryptionEpic = action$ => {
    return action$.pipe(
        ofType(FETCH_SSL_DECRYPTION),
        mergeMap(action => {
            return concat(
                ajax(getSSLDecryptionObservable(action.location.tpl.name)).pipe(
                    mergeMap(response => of(getSSLDecryptionObservableSuccess(response))),
                    catchError(error => {
                        return of({
                            type: FETCH_SSL_DECRYPTION_FAIL,
                            errorMessage: parseError(error),
                        });
                    }),
                ),
            );
        })
    );
}

export const getRemoteNetworkDetailsSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    if (xhr && xhr['@status'] === 'success') {
        return {
            type: REMOTE_NETWORK_DETAIL_DATA_SUCCESS,
            remoteNetworkDetails: xhr.result.entry
        }
    } else {
        return {
            type: REMOTE_NETWORK_DETAIL_DATA_SUCCESS,
            remoteNetworkDetails: 'error'
        }
    }
}

export const getServiceConnectionNetworkDetailsSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    if (xhr && xhr['@status'] === 'success') {
        return {
            type: SERVICE_CONNECTION_DETAIL_DATA_SUCCESS,
            serviceConnectionNetworkDetails: xhr.result.entry
        }
    } else {
        return {
            type: SERVICE_CONNECTION_DETAIL_DATA_SUCCESS,
            serviceConnectionNetworkDetails: 'error'
        }
    }
}

export const getSSLDecryptionObservableSuccess = resp => {
    const xhr = resp && resp.response ? resp.response : null;
    if (xhr && xhr['@status'] === 'success') {
        return {
            type: FETCH_SSL_DECRYPTION_SUCCESS,
            sslDecryptionDetails: xhr.result['ssl-decrypt']
        }
    } else {
        return {
            type: FETCH_SSL_DECRYPTION_SUCCESS,
            sslDecryptionDetails: 'error'
        }
    }
}

export const getMobileUsersNetworkDetailsEpic = action$ => {
    return action$.pipe(
        ofType(MOBILE_USERS_NETWORK_DETAILS),
        mergeMap(action => {
            return concat(
                ajax(getMobileUsersNetworkDetailsObservable()).pipe(
                    mergeMap(response => of(getMobileUsersNetworkDetailsSuccess(response))),
                    catchError(error => {
                        return of({
                            type: MOBILE_USERS_NETWORK_DETAILS_ERROR,
                            errorMessage: parseError(error),
                        });
                    }),
                ),
            );
        }),
    );
};

export const getMobileUsersNetworkDetailsSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    if (xhr && xhr['@status'] === 'success') {
        return {
            type: MOBILE_USERS_NETWORK_DETAILS_DATA_SUCCESS,
            mobileUsersNetworkDetails: xhr.result.entry
        }
    } else {
        return {
            type: MOBILE_USERS_NETWORK_DETAILS_DATA_SUCCESS,
            mobileUsersNetworkDetails: 'error'
        }
    }
}

export const checkMobileUserPortalFqdnEpic = action$ => {
    return action$.pipe(
        ofType(MOBILE_USERS_CHECK_PORTAL_FQDN),
        mergeMap(action => {
            return concat(
                of({
                    type: SHOW_LOADING
                }),
                ajax(checkMobileUserPortalFqdnObservable(action.fqdn)).pipe(
                    mergeMap(response => {
                        return of(
                            {
                                type: HIDE_LOADING
                            },
                            checkMobileUserPortalFqdnSuccess(response)
                        )
                    }),
                    catchError(error => {
                        return of(
                            {
                                type: HIDE_LOADING
                            },
                            {
                                type: MOBILE_USERS_CHECK_PORTAL_FQDN_ERROR,
                                errorMessage: parseError(error)
                            }
                        );
                    })
                )
            );
        })
    );
}

export const checkMobileUserPortalFqdnSuccess = (resp) => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    const url = _.get(resp, 'request.url');

    let fqdn = url.indexOf('fqdn=');
    if (fqdn >= 0) {
        fqdn = url.substring(fqdn + 5, url.length);
    }
    return {
        type: MOBILE_USERS_CHECK_PORTAL_FQDN_SUCCESS,
        fqdnAvail: {
            fqdn: fqdn,
            avail: xhr.result.msg
        }
    }
}

export const fetchGPCSStatusEpic = action$ => {
    return action$.pipe(
        ofType(FETCH_GPCS_CONFIG_STATUS),
        switchMap(allRegions => ajax(completeRegionsObservable()), (action, r) => [r, action]),
        switchMap(
            ([regionResp, action]) => {
                let allRegions = [];
                if (regionResp && regionResp.response && regionResp.response.msg) {
                    allRegions = regionResp.response.msg.completions;
                    allRegions = allRegions.map(region => region["@display"]);
                }
                return ajax(getGPCSRegionDetailsObservable(action.identifier, allRegions))
            }
        ),
        mergeMap(regionDetailResponse =>
            of(fetchGPCSStatusSuccess(regionDetailResponse))
        ),
        catchError(error => {
            return of({
                type: FETCH_GPCS_CONFIG_STATUS_FAIL
            });
        })
    );
}

export const fetchGPCSStatusSuccess = resp => {
    const xhr = resp && resp.response ? resp.response : null;
    let status = {};

    if (xhr && resp.status === 200) {
        status = xhr.msg;
    }

    if (status['@status'] === 'success') {
        // let obj = {
        //     status: status.result.entry[0]['Status'].value,
        //     status_tooltip: status.result.entry[0]['Status'].tooltip,
        //     set_up_now: status.result.entry[0]['Status']['set-up-now'],
        //     config_status: status.result.entry[0]['Config Status'].value,
        //     config_status_tooltip: status.result.entry[0]['Config Status'].tooltip,
        //     connection_number: status.result.entry[0]['Service Connections']
        // }
        return {
            type: FETCH_GPCS_CONFIG_STATUS_SUCCESS,
            gpcs: status.result && status.result.entry ? status.result.entry : []
        }
    } else {
        return {
            type: FETCH_GPCS_CONFIG_STATUS_SUCCESS
        }
    }
}

export const getBGPStatusEpic = action$ => {
    return action$.pipe(
        ofType(BGP_STATUS),
        mergeMap(action => {
            return concat(
                ajax(getBGPStatusObservable(action.payload)).pipe(
                    mergeMap(response => of(getBGPStatusSuccess(response))),
                    catchError(error => {
                        return of({
                            type: BGP_STATUS_ERROR,
                            errorMessage: parseError(error),
                        });
                    }),
                ),
            );
        }),
    );
};

export const getBGPStatusSuccess = resp => {
    const xhr = resp && resp.response && resp.response.msg ? resp.response.msg : null;
    if (xhr && xhr['@status'] === 'success') {
        return {
            type: BGP_STATUS_DATA_SUCCESS,
            bgpStatus: xhr.result.entry
        }
    } else {
        return {
            type: BGP_STATUS_DATA_SUCCESS,
            bgpStatus: 'error'
        }
    }
}

export const getPollingMobileUserProvisioningStatusEpic = action$ => {
    return action$.pipe(
        ofType(POLLING_MU_PROVISIONING_STATUS),
        mergeMap(action =>
            timer(0, 10000)
                .pipe(concatMap(() => ajax(getMobileUserProvisioningStatusObservable())))
                .pipe(
                    mergeMap(response =>
                        of(getMobileUserProvisioningStatusSuccess(response, action)),
                    ),
                    catchError(error => {
                        return of({
                            type: MU_PROVISIONING_SUCCESS,
                            muProvisionStatus: 'exception'
                        });
                    }),
                )
                .pipe(takeUntil(action$.ofType(STOP_POLLING_MU_PROVISION_STATUS)))
        ),
    );
};

export const getMobileUserProvisioningStatusEpic = action$ => {
    return action$.pipe(
        ofType(MU_PROVISIONING_STATUS),
        mergeMap(action => {
            return concat(
                of({
                    type: SHOW_LOADING
                }),
                ajax(
                    getMobileUserProvisioningStatusObservable()
                ).pipe(
                    mergeMap(response =>
                        of(
                            getMobileUserProvisioningStatusSuccess(response, action),
                            {
                                type: HIDE_LOADING
                            }
                        ),
                    ),
                    catchError(error => {
                        return of(
                            {
                                type: MU_PROVISIONING_SUCCESS,
                                muProvisionStatus: 'failure'
                            },
                            {
                                type: HIDE_LOADING
                            }
                        );
                    }),
                ),
            );
        }),
    );
};

export const getMobileUserProvisioningStatusSuccess = (resp, action) => {
    const xhr = resp && resp.response;
    if( action.postAction && Pan.isFunction( action.postAction ) ) {
        action.postAction();
    }
    if (xhr && xhr.status === 'success') {
        return {
            type: STOP_POLLING_MU_PROVISION_STATUS,
            muProvisionStatus: xhr.status,
        }
    } else {
        return {
            type: MU_PROVISIONING_SUCCESS,
            muProvisionStatus: xhr.status
        }
    }
}