import React from 'react';
import ServiceInfrastructureBaseViewer from './ServiceInfrastructureBaseViewer';
import { getStoreState, jsonPath, getPathValue, showModal, hideModal,isUserRolePemission } from 'ui-lib';
import { INTERNAL_DNS_LIST_REDUX_ID} from './ServiceInfrastructureViewerWithActions';
import { SERVICE_INFRASTRUCTURE_REDUX_ID } from '../main/actions';
const _T = str => str;

const serviceInfrastructureFields = [    
    {
        name: '$.service-subnet',
        uiHint: {
            fieldLabel: _T('Infrastructure Subnet')
        }
    },
    {
        name: '$.infra-bgp-as',
        uiHint: {
            fieldLabel: _T('Infrastructure BGP AS')
        }
    },
    {
        name: '$.internal-dns-list.entry',
        uiHint: {
            fieldLabel: _T('Internal Domain List'),
            editorType: 'record-form',
            useColumns: [
                '$.internal-dns-list.entry.*.primary',
                '$.internal-dns-list.entry.*.secondary',
                '$.internal-dns-list.entry.*.domain-name'
            ]
        }
    },
    {
        name: '$.internal-dns-list.entry.*',
        childrenNames: [
            '$.internal-dns-list.entry.*.@name',
            '$.internal-dns-list.entry.*.primary',
            '$.internal-dns-list.entry.*.secondary',
            '$.internal-dns-list.entry.*.domain-name'
        ]
    },
    {
        name: '$.internal-dns-list.entry.*.domain-name.member.*',
        uiHint: {
            allowBlank: true
        }
    },
    {
        name: '$.internal-dns-list.entry.*.@name',
        uiHint: {
            allowBlank: true,
            hidden: true
        }
    },
    {
        name: '$.internal-dns-list.entry.*.primary',
        uiHint: {
            columnConfig: {
                type: 'clickableColumn',
                header: _T('Primary DNS')
            }
        }
    },
    {
        name: '$.internal-dns-list.entry.*.domain-name',
        uiHint: {
            columnConfig: {
                header: _T('Domain Names')
            }
        }
    },
    {
        name: '$.internal-dns-list.entry.*.domain-name.member',
        uiHint: {
            fieldLabel: _T('Domain Names')
        }
    },
    {
        name: '$.internal-dns-list.entry.*.secondary',
        uiHint: {
            columnConfig: {
                header: _T('Secondary DNS')
            }
        }
    },
    {
        name: '$',
        childrenNames: [
            '$.service-subnet',
            '$.infra-bgp-as',
        ],
        uiHint: {
            allowBlank: false,
            labelWidthSize: 3
        }
    }
];

const dnsListFields = [
    {
        name: '$',
        childrenNames: [
            'header',
            '$.primary',
            '$.secondary',
            '$.domain-name'
        ]
    },
    {
        name: 'header',
        uiHint: {
            builder: (field) => {
                return (field) => {
                    let headertext = _T('Add the Domain Names, Primary DNS, and Secondary DNS servers that the cloud service can use to resolve your internal domain names. DNS queries for domains contained in the Internal Domain List are sent to your local DNS servers to ensure that resources, such as LDAP servers, are available to Prisma Access.');
                    return <div className='mb-3'>{headertext}</div> 
                }
            }
        }
    },
    {
        name: '$.primary',
        uiHint: {
            fieldLabel: 'Primary DNS'
        }
    },
    {
        name: '$.secondary',
        uiHint: {
            fieldLabel: 'Secondary DNS'
        }
    },
];

const serviceInfrastructureColumns = [
    {
        headerName: _T('INFRASTRUCTURE SUBNET'),
        valueGetter: params => {
            return jsonPath(params.data, '$.service-subnet') || 'Not Configured yet';
        },
        type: 'clickableColumn'
    },
    {
        headerName: _T('INFRASTRUCTURE BGP AS'),
        valueGetter: params => {
            return jsonPath(params.data, '$.infra-bgp-as') || 'Not Configured yet';
        },
        type: 'clickableColumn'
    },
    {
        headerName: _T('TUNNEL MONITOR IP ADDRESS'),
        field: 'tunnel_monitor_ip_address',
        cellRendererParams: {
            renderCell: params => {
                let value = '';
                if (getStoreState().gpcs.service_infra_status && getStoreState().gpcs.service_infra_status !== 'error') {
                    value = getStoreState().gpcs.service_infra_status['Tunnel Monitor IP Address'];
                }
                return <div>{value}</div>
            }
        }
    },
    {
        headerName: _T('LOOPBACK IPS'),
        field: 'loopback_ips',
        cellRendererParams: {
            renderCell: params => {
                let value = '';
                if (getStoreState().gpcs.service_infra_status && getStoreState().gpcs.service_infra_status !== 'error') {
                    value = getStoreState().gpcs.service_infra_status['Loopback IPs'];
                }
                return <div>{value}</div>
            }
        }
    }
];

const internalDomainListColumns = [
    {
        headerName: _T('Primary DNS'),
        valueGetter: params => {
            return jsonPath(params.data, '$.primary') || '';
        },
        type: 'clickableColumn'
    }, {
        headerName: _T('Secondary DNS'),
        valueGetter: params => {
           return jsonPath(params.data, '$.secondary') || '';
        },
    }, {
        headerName: _T('Domain Names'),
        valueGetter: params => {
           return jsonPath(params.data, '$.domain-name.member') || '';
        },
    }
];

const serviceInfraSimpleColumns = [
    {
        headerName: _T('INFRASTRUCTURE SUBNET'),
        valueGetter: params => {
            return jsonPath(params.data, '$.service-subnet') || '';
        },
        minWidth: 200
    },
    {
        headerName: _T('INFRASTRUCTURE BGP AS'),
        valueGetter: params => {
            return jsonPath(params.data, '$.infra-bgp-as') || '';
        },
        width: 300
    }];

function prepareAddRecord(formData, addClosure) {
    if( this.props.gpcs.serviceInfrastructure ) {
        let n = getPathValue(getStoreState().gpcs.serviceInfrastructure, 'result.entry').length;
        if( n > 0) {
            let originalData = getPathValue(getStoreState().gpcs.serviceInfrastructure, 'result.entry').find(item=> item['@name'] === formData['@name']);
            if(originalData) {
                if( originalData['service-subnet'] !== formData['service-subnet']) {
                    //subnet changed
                    let modal = {
                        id:"ServiceInfrastructureSubnetChange",
                        title: _T('Service Setup'),
                        type: 'Warning',
                        style: {
                            width: '500px'
                        },
                        message:_T(`Changing the infrastructure subnet may require you to change other onboarding information as well as some on premise configurations (routing, VPN monitoring).\nDo you want to continue?`),
                        toggle: () => {
                            hideModal("ServiceInfrastructureSubnetChange");
                        },
                        actions: [{
                            text: 'Yes',
                            action: () => {
                                addClosure && addClosure();
                                hideModal("ServiceInfrastructureSubnetChange");
                            }
                        }, {
                            text: 'No',
                            action: () => {
                                hideModal("ServiceInfrastructureSubnetChange");
                            }
                        }]
                    };
                    return showModal(modal);
                }
            }
        }
    }
    addClosure();
};

function prepareAddRecord_DNSList() {
    let addClosure;
    if(arguments.length === 1) {
        addClosure = arguments[0];
    } else {
        addClosure = arguments[1];
    }
    addClosure();
}

export default class ServiceInfrastructure extends ServiceInfrastructureBaseViewer {
    header = 'Service Infrastructure';
    secondaryHeader = 'Internal Domain List'
    editorMode = 'modal';
    serviceName = 'workflow/commonInfra';
    reduxId = SERVICE_INFRASTRUCTURE_REDUX_ID;
    secondaryReduxId = INTERNAL_DNS_LIST_REDUX_ID;
    columns = serviceInfrastructureColumns;
    secondaryColumns = internalDomainListColumns;
    fields = serviceInfrastructureFields;
    secondaryFields = dnsListFields;
    showConfigureActions = isUserRolePemission();
    rbaPermission = isUserRolePemission() ? 'enable' : 'disable';
    recordConfigPath = '$.config.devices.entry.plugins.cloud_services.adelphi_gpcs_workflow.common-infra.entry';
    recordConfigPathSecondary = '$.config.devices.entry.plugins.cloud_services.adelphi_gpcs_workflow.common-infra.entry.internal-dns-list.entry';
    showDefaultAddAction = false;
    showDefaultDeleteAction = false;
    showDefaultCloneAction = false;
    showFilter = false;
    simpleColumns = serviceInfraSimpleColumns;
    checkboxSelection = false;
    idColumn = 'service-subnet';
    secondaryIdColumn = 'primary';
    prepareAddRecord = prepareAddRecord;
    prepareAddRecord_DNSList = prepareAddRecord_DNSList;
    refreshColumns = ['tunnel_monitor_ip_address', 'loopback_ips'];
}