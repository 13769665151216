import _ from "lodash";
import QueryExpression from "./QueryExpression";

export const queryParseConverter = (query, fields, fn) => {
	const queryExp = _.isString(query) ? QueryExpression.parse(query, fields) : query;
	if (!(queryExp && queryExp instanceof QueryExpression)) {
		throw new Error("query must be QueryExpression instance or string");
	}
	if (!fn || !_.isFunction(fn)) {
		throw new Error("interation fn must be given");
	}
	queryExp.forEachToken(fn);
	return queryExp;
};

export const quoteFieldName = token => {
	if (token && token.kind === "key") {
		token.quoted = "`";
	}
	return token;
};

/**
 * enforce single quote, escape single and double quotes into unicode
 * only for string type
 * also convert single `\` to `\\`
 * @param {object[]} token
 */
export const escapeStringValue = token => {
	if (token && token.kind === "value" && token.type === "string" && (token.quoted || (token.field && QueryExpression.isStringTypes(token.field.type)))) {
		token.quoted = "'"; // ATHNA-284: ' => \\u0027 and " => \\u0022
		token.value = token.value
			.replace(/\\/g, "\\\\")
			.replace(/'/g, "\\u0027")
			.replace(/"/g, "\\u0022")
			.replace(
				/[\x00-\x1f]/g,
				(
					ch // eslint-disable-line no-control-regex
				) => `\\u${`000${ch.charCodeAt(0).toString(16)}`.slice(-4)}`
			);
	}
	return token;
};

export const removeDuplicatedStars = token => {
	if (token && token.kind === "value" && QueryExpression.isStringTypes(token.type)) {
		token.value = token.value.replace(/\*+/g, "*");
	}
	return token;
};

const queryServiceConvertFn = token => {
	quoteFieldName(token);
	escapeStringValue(token);
	removeDuplicatedStars(token);
	return token;
};

export const queryServiceConverter = (query, fields) => {
	return queryParseConverter(query, fields, queryServiceConvertFn);
};
queryServiceConverter.fn = queryServiceConvertFn;
