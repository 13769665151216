import DGBaseViewer from '../../deviceGroup/DGBaseViewer';

const _T = str => str;

const DoSProtectionHelper = {
    getBlockDurationConfig: function (location) {
        return {
            name: '$.' + location + '.block.duration',
            uiHint: {
                fieldLabel: _T('Block Duration'),
                unitText: _T('s')
            }
        };
    },
    getMaximalRateConfig: function (location, sub) {
        return {
            name: '$.' + location + '.' + sub + '.maximal-rate',
            uiHint: {
                fieldLabel: _T('Max Rate'),
                unitText: _T('connections/s')
            }
        };
    },
    getMaximalRateBlockConfig: function (location, sub) {
        return {
            name: location + "_" + sub + '_max-rate',
            childrenNames: [
                '$.' + location + '.' + sub + '.maximal-rate',
                '$.' + location + '.' + sub + '.block.duration'
            ],
            uiHint: {
                builder: 'ContainerBuilder',
                fieldLabel: '',
                collapsible: false
            }
        };
    },
    getAlarmRateConfig: function (location, sub) {
        return {
            name: '$.' + location + '.' + sub + '.alarm-rate',
            uiHint: {
                fieldLabel: _T('Alarm Rate'),
                unitText: _T('connections/s')
            }
        };
    },
    getActivateRateConfig: function (location, sub) {
        return {
            name: '$.' + location + '.' + sub + '.activate-rate',
            uiHint: {
                fieldLabel: _T('Activate Rate'),
                unitText: _T('connections/s')
            }
        };
    },
    getRateConfig: function (location, sub, fb, height) {
        var cfg = {
            name: '$.' + location + '.enable',
            childrenNames: [
                '$.' + location + '.' + sub,
            ],
            uiHint: {
                builder: 'FieldSetBuilder',
                fieldLabel: fb
            }
        };
        if (height) {
            cfg.uiHint.autoHeight = false;
            cfg.uiHint.height = height;
        }
        return cfg;
    }
};

const dppFields = [
    {
        type: 'string',
        name: 'name',
        mapping: '@name',
        uiHint: {
            fieldLabel: _T('Name')
        }
    },
    DoSProtectionHelper.getAlarmRateConfig('flood.tcp-syn', 'red'),
    DoSProtectionHelper.getActivateRateConfig('flood.tcp-syn', 'red'),
    DoSProtectionHelper.getBlockDurationConfig('flood.tcp-syn.red'),
    DoSProtectionHelper.getMaximalRateConfig('flood.tcp-syn', 'red'),
    DoSProtectionHelper.getMaximalRateBlockConfig('flood.tcp-syn', 'red'),
    {
        name: '$.flood.tcp-syn.red',
        childrenNames: [
            '$.flood.tcp-syn.red.alarm-rate',
            '$.flood.tcp-syn.red.activate-rate',
            'flood.tcp-syn_red_max-rate'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            fieldLabel: _T('Random Early Drop')
        }
    },
    DoSProtectionHelper.getAlarmRateConfig('flood.tcp-syn', 'syn-cookies'),
    DoSProtectionHelper.getActivateRateConfig('flood.tcp-syn', 'syn-cookies'),
    DoSProtectionHelper.getBlockDurationConfig('flood.tcp-syn.syn-cookies'),
    DoSProtectionHelper.getMaximalRateConfig('flood.tcp-syn', 'syn-cookies'),
    DoSProtectionHelper.getMaximalRateBlockConfig('flood.tcp-syn', 'syn-cookies'),
    {
        name: '$.flood.tcp-syn.syn-cookies',
        childrenNames: [
            '$.flood.tcp-syn.syn-cookies.alarm-rate',
            '$.flood.tcp-syn.syn-cookies.activate-rate',
            'flood.tcp-syn_syn-cookies_max-rate'
        ],
        uiHint: {
            builder: 'ContainerBuilder',
            fieldLabel: _T('SYN Cookies')
        }
    }, {
        name: '$.flood.tcp-syn.choice',
        uiHint: {
            largeSelectionCount: 1,
            fieldLabel: _T('Action')
        },
        defaultValue: 'red'
    }, {
        name: '$.flood.tcp-syn.enable',
        childrenNames: [
            '$.flood.tcp-syn.choice'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('SYN Flood')
        }
    },

    DoSProtectionHelper.getBlockDurationConfig('flood.udp.red'),
    DoSProtectionHelper.getMaximalRateConfig('flood.udp', 'red'),
    DoSProtectionHelper.getMaximalRateBlockConfig('flood.udp', 'red'),
    DoSProtectionHelper.getAlarmRateConfig('flood.udp', 'red'),
    DoSProtectionHelper.getActivateRateConfig('flood.udp', 'red'),
    DoSProtectionHelper.getRateConfig('flood.udp', 'red', 'UDP Flood', 0),

    DoSProtectionHelper.getBlockDurationConfig('flood.icmp.red'),
    DoSProtectionHelper.getMaximalRateConfig('flood.icmp', 'red'),
    DoSProtectionHelper.getMaximalRateBlockConfig('flood.icmp', 'red'),
    DoSProtectionHelper.getAlarmRateConfig('flood.icmp', 'red'),
    DoSProtectionHelper.getActivateRateConfig('flood.icmp', 'red'),
    DoSProtectionHelper.getRateConfig('flood.icmp', 'red', 'ICMP Flood', 0),

    DoSProtectionHelper.getBlockDurationConfig('flood.icmpv6.red'),
    DoSProtectionHelper.getMaximalRateConfig('flood.icmpv6', 'red'),
    DoSProtectionHelper.getMaximalRateBlockConfig('flood.icmpv6', 'red'),
    DoSProtectionHelper.getAlarmRateConfig('flood.icmpv6', 'red'),
    DoSProtectionHelper.getActivateRateConfig('flood.icmpv6', 'red'),
    DoSProtectionHelper.getRateConfig('flood.icmpv6', 'red', 'ICMPv6 Flood', 0),

    DoSProtectionHelper.getBlockDurationConfig('flood.other-ip.red'),
    DoSProtectionHelper.getMaximalRateConfig('flood.other-ip', 'red'),
    DoSProtectionHelper.getMaximalRateBlockConfig('flood.other-ip', 'red'),
    DoSProtectionHelper.getAlarmRateConfig('flood.other-ip', 'red'),
    DoSProtectionHelper.getActivateRateConfig('flood.other-ip', 'red'),
    DoSProtectionHelper.getRateConfig('flood.other-ip', 'red', 'Other IP Flood', 0),
    {
        name: 'Flood',
        childrenNames: [
            '$.flood.tcp-syn.enable',
            '$.flood.udp.enable',
            '$.flood.icmp.enable',
            '$.flood.icmpv6.enable',
            '$.flood.other-ip.enable'
        ],
        uiHint: {
            builder: 'TabBuilder',
            fieldLabel: _T('Flood Protection')
        }
    },
    {
        name: '$.resource.sessions.max-concurrent-limit',
        uiHint: {
            fieldLabel: _T('Maximum Concurrent Sessions'),
            allowBlank: false
        }
    },
    {
        name: '$.resource.sessions.enabled',
        childrenNames: [
            '$.resource.sessions.max-concurrent-limit'
        ],
        uiHint: {
            builder: 'FieldSetBuilder',
            fieldLabel: _T('Sessions')
        }
    }, {
        name: 'Resource',
        childrenNames: [
            '$.resource.sessions.enabled'
        ],
        uiHint: {
            fieldLabel: _T('Resources Protection'),
            labelWidthSize: 3
        }
    }, {
        name: 'Protection',
        childrenNames: [
            'Flood',
            'Resource'
        ],
        uiHint: {
            builder: 'TabBuilder'
        }
    }, {
        name: '$',
        childrenNames: [
            'name',
            '$.description',
            'location',
            '$.type',
            'Protection'
        ]
    }
];

const dppObjectColumns = [{
    type: ['nameColumn', 'clickableColumn'],
    minWidth: 100
}, {
    headerName: 'Location',
    field: '@loc',
    minWidth: 150
}, {
    headerName: 'Type',
    field: 'type',
    minWidth: 100
}, {
    headerName: 'Flood Protection',
    children: [{
        headerName: 'SYN Flood',
        valueGetter: params => {
            let fld = params.data.flood;
            let isEnable = fld && fld['tcp-syn'] ? fld['tcp-syn'].enable : '';
            return isEnable;
        },
        minWidth: 100
    }, {
        headerName: 'UDP Flood',
        valueGetter: params => {
            let fld = params.data.flood;
            let isEnable = fld && fld.udp ? fld.udp.enable : '';
            return isEnable;
        },
        minWidth: 100
    }, {
        headerName: 'ICMP Flood',
        valueGetter: params => {
            let fld = params.data.flood;
            let isEnable = fld && fld.icmp ? fld.icmp.enable : '';
            return isEnable;
        },
        minWidth: 100
    }, {
        headerName: 'ICMPv6 Flood',
        valueGetter: params => {
            let fld = params.data.flood;
            let isEnable = fld && fld.icmpv6 ? fld.icmpv6.enable : '';
            return isEnable;
        },
        minWidth: 100
    }, {
        headerName: 'Other IP Flood',
        valueGetter: params => {
            let fld = params.data.flood;
            let isEnable = fld && fld['other-ip'] ? fld['other-ip'].enable : '';
            return isEnable;
        },
        minWidth: 100
    }]
}, {
    headerName: 'Resources Protection',
    children: [{
        headerName: 'Sessions',
        valueGetter: params => {
            let res = params.data.resource;
            let isEnable = res && res.sessions ? res.sessions.enabled : '';
            return isEnable;
        },
        minWidth: 100
    }]
}];

export default class DosProtectionProfile extends DGBaseViewer {

    serviceName = 'Objects/DoSProtectionSecurityProfiles';
    reduxId = 'dosProtectionProfileObjects';
    header = 'DoS Protection Profiles';
    columns = dppObjectColumns;
    fields = dppFields;
    recordConfigPath = '$.config.devices.entry.device-group.entry.profiles.dos-protection.entry';
    rbaPath = 'objects.security-profiles.dos-protection';
}
