import './AppBar.scss';
import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'ui-lib';
import {
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge
} from 'reactstrap';
import { store } from '../../store';
import {
    isPermissionDisabled,
    getRBAPermission,
    showModal,
    hideModal,
    JobManager,
    ErrorManager,
    getStoreState,
    getPathValue,
    isUserRolePemission
} from 'ui-lib';
import CommitManager from '../commitManager';
import { clearNewErrors } from '../main/actions';
import { TechSupportDetailsComponent } from '../TechSupport';

const ACTION_TITLE = {
    commitandpush: "Commit & Push",
    commit: "Commit",
    push: "Push",
    revert: "Revert"
};

export default class AppBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            widgetDropdownOpen: false,
            commitType: '',
            showCommitManager: false,
            newErrors: 0
        };
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    toggleWidgetMenu = () => {
        this.setState({
            widgetDropdownOpen: !this.state.widgetDropdownOpen,
        });
    }

    showJobManager = () => {
        let modal = {
            id: "JobManagerModal",
            open: true,
            title: 'Jobs',
            size: 'xl',
            type: 'Info',
            toggle: () => {
                hideModal("JobManagerModal");
            },
            modalBody: {
                component: JobManager,
                props: {
                }
            },
            actions: [
                {
                    text: "Close",
                    color: "secondary",
                    action: () => {
                        hideModal('JobManagerModal')
                    }
                }
            ]
        };
        showModal(modal);
    }

    showCommitManager = (e) => {
        let commitType = e.target.name || 'commit';
        let modal = {
            id: "CommitManagerModal",
            open: true,
            title: ACTION_TITLE[commitType],
            type: 'Warning',
            toggle: () => {
                hideModal("CommitManagerModal");
            },
            modalBody: {
                component: CommitManager,
                props: {
                    type: commitType,
                    commitBegins: false
                }
            },
            actions: []
        };
        showModal(modal);
    }

    loadErrors = () => {
        let errors = getPathValue(getStoreState(), 'main.server.errors') || [];
        return errors.reverse();
    }

    getErrorCount = () => {
        return getPathValue(getStoreState(), 'main.server.totalErrors') || undefined;
    }

    getNewErrorCount = () => {
        return getPathValue(getStoreState(), 'main.server.newErrors') || undefined;
    }

    showErrorManager = (e) => {
        let modal = {
            id: "ErrorManagerModal",
            open: true,
            title: 'Error Manager',
            size: 'xl',
            type: 'Warning',
            toggle: () => {
                hideModal("ErrorManagerModal");
                store.dispatch(clearNewErrors());
            },
            modalBody: {
                component: ErrorManager,
                props: {
                    data: this.loadErrors()
                }
            },
            actions: [
                {
                    text: "Close",
                    color: "secondary",
                    action: () => {
                        hideModal('ErrorManagerModal')
                        store.dispatch(clearNewErrors());
                    }
                }
            ]
        };
        showModal(modal);
    }

    techSupportDetails = () => {
        let modal = {
            id: "TechSupportModal",
            open: true,
            title: 'Tech Support',
            size: 'xl',
            modalBody: {
                component: TechSupportDetailsComponent,
            },
            toggle: () => {
                hideModal('TechSupportModal');
            },
            actions: [
                {
                    text: "Close",
                    color: "secondary",
                    action: () => {
                        hideModal('TechSupportModal');
                    }
                }
            ]
        };
        showModal(modal);
    }

    render() {
        const { defaultRoutes } = this.props;
        return (
            <React.Fragment>
                <div className="main-tabs">
                    {
                        <NavLink tag={RRNavLink} activeClassName="active" to={defaultRoutes['dashboard']}>
                            <span className="nav-label">Dashboard</span>
                        </NavLink>
                    }
                    {
                        <NavLink tag={RRNavLink} activeClassName="active" to={defaultRoutes['explore']}>
                            <span className="nav-label">Explore</span>
                        </NavLink>
                    }
                    {
                        !isPermissionDisabled(getRBAPermission('policies')) &&
                        <NavLink tag={RRNavLink} activeClassName="active" to={defaultRoutes['policies']}>
                            <span className="nav-label">Policies</span>
                        </NavLink>
                    }
                    {
                        !isPermissionDisabled(getRBAPermission('objects')) &&
                        <NavLink tag={RRNavLink} activeClassName="active" to={defaultRoutes['objects']}>
                            <span className="nav-label">Objects</span>
                        </NavLink>
                    }
                    <NavLink tag={RRNavLink} activeClassName="active" to={defaultRoutes['configure']}>
                        <span className="nav-label">Configure</span>
                    </NavLink>
                </div>
                <ul id="utility-bar" className="nav utility-bar">
                    <NavLink className="icon-container" onClick={this.techSupportDetails}>
                        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>Tech Support</title>
                            <desc>Created with Sketch.</desc>
                            <g id="support" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <path d="M5.4999,1.0005 C6.8789,1.0005 7.9999,2.1205 7.9999,3.4995 C7.9999,4.8795 6.8789,5.9995 5.4999,5.9995 C4.1209,5.9995 2.9999,4.8795 2.9999,3.4995 C2.9999,2.1205 4.1209,1.0005 5.4999,1.0005 M5.4999,6.9995 C7.4309,6.9995 8.9999,5.4295 8.9999,3.4995 C8.9999,1.5695 7.4309,-0.0005 5.4999,-0.0005 C3.5689,-0.0005 1.9999,1.5695 1.9999,3.4995 C1.9999,5.4295 3.5689,6.9995 5.4999,6.9995" id="Fill-1" fill="#FFFFFF"></path>
                                <path d="M12.872,6.8921 L11.665,6.5191 C11.399,6.4381 11.12,6.5851 11.04,6.8491 C10.958,7.1131 11.106,7.3931 11.37,7.4741 L12.577,7.8471 C12.626,7.8631 12.676,7.8701 12.725,7.8701 C12.938,7.8701 13.136,7.7321 13.202,7.5171 C13.284,7.2531 13.136,6.9741 12.872,6.8921" id="Fill-4" fill="#FFFFFF"></path>
                                <path d="M14.8505,7.0162 C14.3685,8.2242 13.2765,8.4612 13.2335,8.4702 C13.0695,8.5012 12.9315,8.6132 12.8675,8.7662 L10.2375,14.9712 C10.2315,14.9792 10.1985,14.9992 10.1435,14.9992 L8.0995,14.2922 C8.0305,14.2672 8.0025,14.2242 8.0015,14.2152 L10.3865,7.9302 C10.4535,7.7532 10.4115,7.5502 10.2845,7.4112 C10.2545,7.3792 9.5825,6.6222 10.0835,5.3692 C10.4155,4.5402 11.1135,4.2102 11.6875,4.0812 L11.2875,5.0822 C11.2385,5.2092 11.2415,5.3502 11.2975,5.4742 C11.3535,5.5982 11.4575,5.6942 11.5855,5.7392 L13.4835,6.4122 C13.7365,6.5022 14.0145,6.3752 14.1145,6.1262 L14.5455,5.0482 C14.8755,5.4682 15.1975,6.1512 14.8505,7.0162 L14.8505,7.0162 Z M0.9995,10.4842 C0.9995,9.6652 1.6865,8.9992 2.5305,8.9992 L8.6205,8.9992 C8.7145,8.9992 8.8075,9.0142 8.9005,9.0312 L7.3935,12.9992 L0.9995,12.9992 L0.9995,10.4842 Z M14.6445,3.7652 C14.5195,3.6772 14.3605,3.6512 14.2145,3.6962 C14.0685,3.7392 13.9505,3.8472 13.8935,3.9892 L13.3645,5.3082 L12.4105,4.9702 L12.9215,3.6862 C12.9815,3.5372 12.9645,3.3692 12.8785,3.2332 C12.7925,3.0972 12.6465,3.0112 12.4865,3.0022 C12.4765,3.0022 12.3815,2.9992 12.3715,2.9992 C11.2045,2.9992 9.7465,3.5242 9.1565,4.9972 C8.6055,6.3742 9.0585,7.3792 9.3545,7.8322 L9.2575,8.0882 C9.0485,8.0352 8.8355,7.9992 8.6205,7.9992 L2.5305,7.9992 C1.1345,7.9992 -0.0005,9.1142 -0.0005,10.4842 L-0.0005,13.4992 C-0.0005,13.7762 0.2235,13.9992 0.4995,13.9992 L7.0425,13.9992 C6.9965,14.2072 6.9985,14.4242 7.0895,14.6272 C7.2145,14.9052 7.4605,15.1252 7.7655,15.2352 L9.7315,15.9282 C9.8645,15.9762 10.0025,15.9992 10.1435,15.9992 C10.6045,15.9992 11.0055,15.7432 11.1605,15.3542 L13.6945,9.3762 C14.1955,9.2072 15.2515,8.7062 15.7785,7.3882 C16.4795,5.6412 15.3425,4.2542 14.6445,3.7652 L14.6445,3.7652 Z" id="Fill-6" fill="#FFFFFF"></path>
                            </g>
                        </svg>
                    </NavLink>
                    <NavLink onClick={this.showErrorManager}><span className="fa fa-bell-o"><Badge color='danger' className='ml-1' pill>{this.getNewErrorCount()}</Badge></span></NavLink>
                    <Dropdown className="icon-container" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle nav caret>
                            <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <title>Icon</title>
                                <desc>Created with Sketch.</desc>
                                <defs />
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.8">
                                    <g fill="#FFFFFF">
                                        <rect fillRule="nonzero" x="0" y="8" width="16" height="1" />
                                        <rect fillRule="nonzero" x="0" y="13" width="16" height="1" />
                                        <rect id="Rectangle-4" fillRule="nonzero" x="0" y="2" width="16" height="1" />
                                        <rect x="7.41176471" y="0" width="2.82352941" height="5" rx="1.41176471" />
                                        <rect x="3.82352941" y="5.64705882" width="2.82352941" height="5" rx="1.41176471" />
                                        <rect x="9" y="11" width="2.82352941" height="5" rx="1.41176471" />
                                    </g>
                                </g>
                            </svg>
                        </DropdownToggle>
                        <DropdownMenu tag="div" id="settings-menu" aria-labelledby="pan-user-tab">
                            {
                                !isPermissionDisabled(getRBAPermission('commit')) && isUserRolePemission() && 
                                <React.Fragment>
                                    <DropdownItem tag="a" name='commit' href="#" onClick={this.showCommitManager}>
                                        Commit
                                    </DropdownItem>
                                    <DropdownItem tag="a" name='push' href="#" onClick={this.showCommitManager}>
                                        Push
                                    </DropdownItem>
                                    <DropdownItem tag="a" name='commitandpush' href="#" onClick={this.showCommitManager}>
                                        Commit and Push
                                    </DropdownItem>
                                    <DropdownItem tag="a" name='revert' href="#" onClick={this.showCommitManager}>
                                        Revert
                                    </DropdownItem>
                                </React.Fragment>
                            }
                            {
                                !isPermissionDisabled(getRBAPermission('tasks')) &&
                                <DropdownItem tag="a" href="#" onClick={this.showJobManager}>
                                    Jobs
                                </DropdownItem>
                            }
                        </DropdownMenu>
                    </Dropdown>
                </ul>
            </React.Fragment>
        );
    }
}
