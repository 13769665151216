import { getCustomServiceURL, MOCK_SERVICES, getAuthHeaders } from 'ui-lib';
import {
	GPCS_COMMON_TPL,
	GPCS_COMMON_DG,
	MOBILE_USERS_DG,
	MOBILE_USERS_TPL,
	MOBILE_USERS_TPL_STACK,
	MOBILE_USERS_WORKFLOW_CONFIGURATION
} from 'service-lib';

export function generateMUCertificateObservable() {
    let { SERVICE_URL } = getCustomServiceURL(
        'api/extensions/gpcs/generateGPCSDefaultCertificates'
    );

    return {
        withCredentials: MOCK_SERVICES ? false : true,
        method: 'POST',
        responseType: 'json',
        url: SERVICE_URL,
        body: JSON.stringify({
            '@location': 'template',
            '@template': GPCS_COMMON_TPL,
        }),
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(),
        },
    };
}

export function getGlobalMapRegionInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGlobalMapRegionInfo`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function completeRegionsObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/completeRegions`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getMobileUsersFQDNActionObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/gpFQDN`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}
export function getAPIKeyActionObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/v1/apiKey`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function generateAPIKeyActionObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/generateApiKey`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function fetchGPaaSDetailInfoObservable(header) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSRegionalStat`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function doMUCommitAndPushActionObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/config/v9.0/commitAndPush");
	let params = {
		commit: {
			deviceGroups: [],
			templates: [],
			workflowConfigurations: [],
			//templateStacks:[]
		},
		push: {
			deviceGroups: [],
			templateStacks: []
		}
	};
	params.commit.deviceGroups = [GPCS_COMMON_DG, MOBILE_USERS_DG];
	params.commit.templates = [GPCS_COMMON_TPL, MOBILE_USERS_TPL];
	params.commit.workflowConfigurations = [MOBILE_USERS_WORKFLOW_CONFIGURATION];

	params.push.deviceGroups = [MOBILE_USERS_DG];
	params.push.templateStacks = [MOBILE_USERS_TPL_STACK];
	return {
		withCredentials: true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function getMUJobDetailsActionObservable(jobid) {
	let { SERVICE_URL } = getCustomServiceURL("api/jobs/v1/" + jobid);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getNotificationObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/extensions/gpcs/notification");
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function postNotificationObservable(notification_url) {
	let { SERVICE_URL } = getCustomServiceURL("api/extensions/gpcs/notification");
	let params = {
		url: notification_url
	};
	return {
		withCredentials: true,
		method: "POST",
		responseType: "json",
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			"Content-Type": "application/json",
			...getAuthHeaders()
		}
	};
}

export function getNetworkDetailDataObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/extensions/gpcs/serviceInfrastructureNetworkDetails");
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getRemoteNetworkDetailsObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/extensions/gpcs/remoteNetworkDetails");
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getSSLDecryptionObservable(tpl) {
	let { SERVICE_URL } = getCustomServiceURL(`api/config/v9.0/Device/CertificateManagement/SSLDecrypt?template=${tpl}`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getServiceConnectionNetworkDetailsObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/extensions/gpcs/serviceConnectionNetworkDetails");
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getMobileUsersNetworkDetailsObservable() {
	let { SERVICE_URL } = getCustomServiceURL("api/extensions/gpcs/mobileUsersNetworkDetails");
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getGPCSRegionDetailsObservable(identifier, locations) {
	let param = "";
	if (identifier === "remoteNetworks") {
		param = "remoteNetworkRegionDetails";
	} else if (identifier === "mobileUsers") {
		param = "mobileUserRegionDetails";
	} else {
		param = "serviceConnectionRegionDetails";
	}
	let locationStr = locations.reduce((currStr, location) => (currStr += '"' + location + '",'), "");
	locationStr = locationStr.substring(0, locationStr.length - 2) + '"';
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/${param}?regions=[${locationStr}]`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getBGPStatusObservable(site, ribout = null, ribin = null) {
	// site = 'asaini-sc2';
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/statusBGP?site=${site}&ribOutPrefix=${ribout}&ribInPrefix=${ribin}`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function getMobileUserProvisioningStatusObservable() {
	console.log("getMobileUserProvisioningStatusObservable");
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/mobileUserProvisioningStatus`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}

export function checkMobileUserPortalFqdnObservable(fqdn) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/checkPortalFQDN?fqdn=${fqdn}`);
	return {
		withCredentials: true,
		method: "GET",
		responseType: "json",
		url: SERVICE_URL,
		headers: getAuthHeaders()
	};
}
