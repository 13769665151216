import React from "react";
import { Component } from "react";
import { ExtraInfoContext } from "ui-lib";
import _ from "lodash";

export class PolicyServiceColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getServiceHTML = item => {
		if (!item.value || item.value === 'any') {
			return item.value;
		}
		if (item.type === "predefined service") {
			return (
				<div key={item.value}>
					<img src="/images/treemenu/services.png" alt="predefined service" />
					{item.value}
				</div>
			);
		} else if (item.type === "service-group") {
			return (
				<div key={item.value}>
					<img src="/images/treemenu/service-groups.png" alt="service-group" />
					{item.value}
				</div>
			);
		} else {
			return (
				<div key={item.value}>
					<img src="/images/treemenu/services.png" alt="Applications" />
					{item.value}
				</div>
			);
		}
	};

	renderService = data => {
		let formattedServices = [];
		const policiesServices = _.get(this.context, "extraInfo.policiesServices", []);
		data.forEach(item => {
			let foundItem = policiesServices.find(element => element.value === item);
			foundItem = foundItem ? foundItem : { value: item, type: "service" };
			formattedServices.push(this.getServiceHTML(foundItem));
		});
		return formattedServices;
	};

	render() {
		return <div>{this.renderService(this.props.data)}</div>;
	}
}
PolicyServiceColumn.contextType = ExtraInfoContext;
