import { ajax } from 'rxjs/ajax';
import { getPathValue } from '../utils/json';
import { getStoreState, getStore } from '../utils/storeRegistry';
import { parseAutoCompletionToOptions } from '../autorender/widgets/util';
import { getFieldXpath } from '../autorender/schema/utils';
import { getAuthHeaders } from "../../auth/lib";
import Pan from "../autorender/schema/Pan";
import { get } from "lodash";

export const SERVER_ERROR = 'SERVER_ERROR';
export const MOCK_SERVICES = false; //true -> to invoke mock services, false -> to invoke actual API services
export let LOGIN_SERVICE_BASE_URL = window.__admin_cluster_url || process.env.REACT_APP_ADMIN_CLUSTER;
export const MOCK_BASE_URL = "https://localhost:5000";
export const VSYS = "vsys1";
export const VSYS_SHARED = "shared";

export const CONFIG_BASE_URI = "/api/config/v9.0";
export const AUTO_COMPLETE_URI = "/api/config/v9.0/complete";
export const EXTENSION_BASE_URL = "/api/extensions";

export function getRedirectURL() {
	let storeState = getStoreState();
	return getPathValue(storeState || {}, "main.tenant.cluster.redirectURL") || "";
}

export function getCustomServiceURL(serviceUrl) {
	let SERVICE_BASE_URL = getRedirectURL();
	return {
		SERVICE_URL: MOCK_SERVICES ? `${MOCK_BASE_URL}/${serviceUrl}` : `${SERVICE_BASE_URL}/${serviceUrl}`
	};
}

export function getAdminServiceURL(adminUrl) {
	return {
		LOGIN_SERVICE_URL: MOCK_SERVICES ? `${MOCK_BASE_URL}/${adminUrl}` : `${LOGIN_SERVICE_BASE_URL}/${adminUrl}`
	};
}

export function getLoginServiceURL(serviceUrl) {
	return {
		SERVICE_URL: MOCK_SERVICES ? `${MOCK_BASE_URL}/${serviceUrl}` : `${LOGIN_SERVICE_BASE_URL}/${serviceUrl}`
	};
}
// parse error from server api
export function parseError(error) {
	const xhr = error && error.xhr ? error.xhr : null;
	const response = xhr && xhr.response ? xhr.response : null;
	let message = {};

	if (xhr && (xhr.status === 403)) {
		//session timed out. Navigate to login
		let loginUrl = getLoginServiceURL("api/sso/v1/login")["SERVICE_URL"];
		//tenant from redux store
		let store = getStoreState();
		let tenantId = get(store, "main.tenant.id", null);
		if (tenantId) {
			loginUrl += `?tenantId=${tenantId}`;
		}
		window.location = loginUrl;
		return;
	} else if (response && response.errorMessage) {
		message = { msg: response.errorMessage };
	} else if (response && response.error) {
		message = { msg: response.error };
	} else if (error && error.message) {
		message = { msg: error.message };
	} else {
		message = { msg: "Server Error" };
	}

	// make a copy of the response
	if (response) {
		message.extra = response.extra;
		message.errorCode = response.errorCode;
		message.errorMessage = response.errorMessage;
		message.httpStatusCode = response.httpStatusCode;
		message.trackingId = response.trackingId;
	}

	message.timestamp = new Date().toLocaleTimeString();
	message.trackingId = message.trackingId || Pan.id("tracking-gen-");
	return message;
}

export function getAutoCompletionURL(location) {
	let SERVICE_BASE_URL = getRedirectURL();
	let serviceUrl = `${SERVICE_BASE_URL}${AUTO_COMPLETE_URI}`;
	return serviceUrl;
}

export function getGenericAutoComplete(inputValue, schemadPath, location) {
	let respPromise = new Promise(function (resolve, reject) {
		ajax(autoCompletionObservable(inputValue, schemadPath, location)).toPromise().then((resp) => {
			let options = parseAutoCompletionToOptions(resp)
			resolve(options);
		}, (err) => {
			let store = getStore();
			store.dispatch({
				type: SERVER_ERROR,
				errorMessage: parseError(err),
				showMessage: false
			});
		});
	});
	return respPromise;
}

export function autoCompletionObservable(input, schemaPath, location, additional, pobj) {
	let AUTO_COMPLETION_URL = getAutoCompletionURL(location);
	let xpath = getFieldXpath(schemaPath, location);
	let body = {
		xpath: xpath
	};

	if (additional) {
		body["additional"] = JSON.stringify(additional);
	}

	if (pobj) {
		body["pobj"] = pobj;
	}

	return {
		withCredentials: MOCK_SERVICES ? false : true,
		method: "POST",
		responseType: "json",
		url: AUTO_COMPLETION_URL,
		body: body,
		headers: getAuthHeaders()
	};
}
