import React, { Component } from 'react';
import { FormGroup } from "reactstrap";
import { Pan, BaseFieldBuilder, TextWidget, FileWidget, getFieldLabelComp, FormContext, setPathValue, deletePath, showModal, hideModal, getStore, SERVER_ERROR } from "ui-lib";
import "./ApplicationIcon.scss";

const _T = str => str;
export default class ApplicationIcon extends BaseFieldBuilder{

    constructor(props){
        super(props);
        this.id = Pan.id();
        this.state = {
            value: ''
        }
        this.inputRef = React.createRef();
        this.openFileWidget = this.openFileWidget.bind(this);
        this.validateImage = this.validateImage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.previewIcon = this.previewIcon.bind(this);
        this.removeIcon = this.removeIcon.bind(this);
    }

    openFileWidget(){
        this.inputRef.current.triggerInputFile();
    }

    validateImage(str){
        if(Pan.isEmpty(str)){ return false; }
        let imageRe = new RegExp('data:image\/([a-zA-Z]*)');
        return !(str.match(imageRe)===null);
    }

    onChange(){
        return (val) => {
            const store = getStore();
            if(!this.validateImage(val)){
                store.dispatch({
                    type: SERVER_ERROR,
                    errorMessage: {
                        msg: _T('Please select a file with extension: jpeg, png, jpg, gif, tif, or tiff.')
                    },
                });
                return;
            }
            let rules = super.getValidationRules(this.props.field, val);
            let errors = super.execValidationRules(this.props.field, val, rules);
            if(errors){
                store.dispatch({
                    type: SERVER_ERROR,
                    errorMessage: {
                        msg: _T(errors)
                    },
                });
            }
            else{
                this.setState({
                    value: val
                });
                setPathValue(this.context.formData, this.props.field.name, val);
            }
        }       
    }

    previewIcon(){
        const imgBase64 = !Pan.isEmpty(this.state.value) ? this.state.value : this.getRecordValue();
        let iconImg = !Pan.isEmpty(imgBase64) ? <div className="preview-icon-content" dangerouslySetInnerHTML={{__html: '<img src="'+imgBase64+'">'}} /> : 'Upload icon to display.';
        showModal({
            id:"PreviewApplicationIconModal",
            open: true,
            size:'md',
            toggle:() => {
                hideModal("PreviewApplicationIconModal");
            },
            title: 'Preview Application Icon',
            message: iconImg,
            actions:[
                {
                    text: "OK",
                    color: "secondary",
                    action: () => {
                        hideModal('PreviewApplicationIconModal');
                    }
                },
            ]
        });
    }

    removeIcon(){
        this.setState({
            value: ''
        });
        deletePath(this.context.formData, this.props.field.name);
        this.inputRef.current.resetInputFile();
    }

    render(){
        let {
            name,
            field,
            hideLabel,
        } = this.props;
        let required = field.uiHint.allowBlank === false ? 'required' : '';
        let LabelComp = getFieldLabelComp(field, name, false, 5);
        const value = !Pan.isEmpty(this.state.value) ? this.state.value : this.getRecordValue();
        return (
            <FormGroup className={`d-flex flex-row ${required}`}>
                {!hideLabel && LabelComp}
                <div className="col-5" style={{ paddingLeft: 0 }}>
                    <TextWidget
                        id={this.id}
                        name={name}
                        value={value}
                        disabled={true}
                    />
                </div>
                <div className="col-2 action-icons" style={{ paddingRight: 0 }}>
                    <i className="fa fa-upload" title="upload" onClick={this.openFileWidget} />
                    <i className="fa fa-image" title="preview" onClick={this.previewIcon} />
                    <i className="fa fa-minus-square" title="remove" onClick={this.removeIcon} />
                    <span className="d-none">
                        <FileWidget val={value} ref={this.inputRef} multiple={false} onChange={this.onChange()} id={"file_"+this.id} />
                    </span>
                </div>
            </FormGroup>
        );
    }
}
ApplicationIcon.contextType = FormContext;