import React, { Component } from "react";
import { GridWidget } from "../../core/autorender/widgets/GridWidget";
import _ from "lodash";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import { ajax } from "rxjs/ajax";
import { getJobDetailsActionObservable } from "./services";
import { capitalizeLetter } from '../../core/autorender/schema/utils';

export const CONFIG_SERVICE = "CONFIG_SERVICE";

const DEFAULT_LINE_HEIGHT = 26;
export class JobDetails extends Component {
	constructor(props) {
		super(props);
		this.getJobDetails = this.getJobDetails.bind(this);
		this.htmlDecode = this.htmlDecode.bind(this);
		this.toggle = this.toggle.bind(this);
		this.jsonParse = this.jsonParse.bind(this);
		this.renderErrorTab = this.renderErrorTab.bind(this);
		this.renderErrorGrid = this.renderErrorGrid.bind(this);
		this.shouldRenderTabs = this.shouldRenderTabs.bind(this);
		this.state = {
			activeTab: "0",
			gridData: [],
			commonColumns: [],
			isloading: true
		};
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	componentDidMount() {
		if (this.props.jobDetails === undefined) {
			this.getJobDetails();
		} else {
			this.processResponseData(this.props.jobDetails);
		}
	}

	getJobDetails() {
		ajax(getJobDetailsActionObservable(this.props.jobid)).subscribe(res => {
			this.processResponseData(res.response);
		});
	}

	htmlDecode(input) {
		let returnValue = "";
		try {
			returnValue = _.unescape(input);
		} catch (error) {
			console.log("unescape error:", error);
		}
		try {
			var e = document.createElement("div");
			e.innerHTML = returnValue;
			returnValue = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
		} catch (error) {
			console.log("unescape error:", error);
		}
		return returnValue;
	}

	jsonParse(input) {
		let returnValue = "";
		try {
			returnValue = JSON.parse(this.htmlDecode(input));
		} catch (error) {
			console.log("parse error:", error);
		}
		return returnValue;
	}

	parseJobDetails(responseData) {
		let jsonData = this.jsonParse(responseData);
		let jobData = {};
		if (this.isOneJobBlock(jsonData)) {
			jobData[CONFIG_SERVICE] = jsonData;
		} else {
			jobData = jsonData;
		}
		return jobData;
	}

	getRowHeight(params) {
		if(params && params.data && params.data.message) {
			let arrayOfLines = params.data.message.match(/[^\r\n]+/g);
			return arrayOfLines.length > 0 ? arrayOfLines.length * DEFAULT_LINE_HEIGHT : DEFAULT_LINE_HEIGHT;
		}
		return DEFAULT_LINE_HEIGHT;
	}

	processResponseData(responseData) {
		let gridData = {};
		let commonColumns = [
			{
				headerName: "Type",
				field: "type",
				width: 100,
				maxWidth: 100
			},
			{
				headerName: "Messages",
				field: "message",
				flex:1,
				cellRendererParams: {
					renderCell: params => {
						return <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{params.value}</div>;
					}
				}
			}
		];
		if (responseData && responseData.data && responseData.data.length > 0) {
			let d = responseData.data[0].details;
			if (d) {
				let jobData = this.parseJobDetails(d);
				Object.keys(jobData).forEach(function (jobType) {
					let job = jobData[jobType];
					if (typeof job !== 'string') {
						gridData[jobType] = [];
						Object.keys(job).forEach(function (messageType) {
							let messages = job[messageType];
							if (Array.isArray(messages) && messages.length > 0) {
								messages.forEach(function (message) {
									gridData[jobType].push({
										message: message,
										type: messageType
									});
								});
							}
						});
					}
				});
			}
		}
		this.setState({
			gridData,
			commonColumns,
			isloading: false
		});
	}

	isOneJobBlock(JobDetailsObject) {
		return Object.keys(JobDetailsObject).some(e => ["info", "error", "warning"].includes(e));
	}

	renderErrorTab() {
		let { gridData, activeTab } = this.state;
		let navItems = [];
		let that = this;
		Object.keys(gridData).forEach(function (job, index) {
			navItems.push(
				<NavItem key={"key-" + index + ""}>
					<NavLink
						className={activeTab === "" + index + "" ? "active" : ""}
						onClick={() => {
							that.toggle("" + index + "");
						}}
					>
						{capitalizeLetter(job)}
					</NavLink>
				</NavItem>
			);
		});
		return navItems;
	}

	renderErrorGrid() {
		let { gridData, commonColumns, isloading } = this.state;
		let jobItems = [];
		Object.keys(gridData).forEach((job, index)=>{
			jobItems.push(
				<TabPane key={"key-" + index + ""} tabId={"" + index + ""}>
					<Row>
						<Col sm="12">
							<GridWidget
								columns={commonColumns}
								loading={isloading}
								gridData={gridData[job] || []}
								checkboxSelection={false}
								showPaging={false}
								showGridToolBar={false}
								pages={25}
								getRowHeight={this.getRowHeight}
							/>
						</Col>
					</Row>
				</TabPane>
			);
		});
		return jobItems;
	}

	renderTabs() {
		return (
			<div>
				<Nav tabs>{this.renderErrorTab()}</Nav>
				<TabContent activeTab={this.state.activeTab}>{this.renderErrorGrid()}</TabContent>
			</div>
		);
	}

	shouldRenderTabs() {
		let { gridData } = this.state;
		return Object.keys(gridData).length > 1;
	}

	render() {
		let { gridData, isloading } = this.state;
		if (isloading && Object.keys(gridData).length === 0) {
			return (
				<GridWidget
					columns={[
						{
							headerName: "Name"
						}
					]}
					loading={this.state.isloading}
					gridData={[]}
				/>
			);
		} else if (!isloading && Object.keys(gridData).length === 0) {
			return <div>Loading messages...</div>;
		} else if (this.shouldRenderTabs()) {
			return this.renderTabs();
		} else if (Object.keys(gridData).length === 1) {
			return (
				<div>
					{this.renderErrorGrid()}
				</div>
			);
		}
	}
}
