import { getHLRecordViewer } from 'service-lib';

const getDGRecordViewer = (viewerName, serviceName, recordId, reduxId, serviceMap, resultPath = "result", widerForm = false) => {
    return getHLRecordViewer(
        viewerName,
        serviceName,
        recordId,
        reduxId,
        serviceMap,
        resultPath,
        'dg',
        widerForm
    );
}
export default getDGRecordViewer;
