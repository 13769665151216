import React from 'react';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        //Update error state 
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        //log the error 
        console.log(error, info);
    }

    render() {
        if (this.state.hasError) {
            //Error
            return <h1 className="m-4">Something went wrong</h1>;
        }
        return this.props.children;
    }
}