import * as React from 'react';
import RcDropdown from 'rc-dropdown';
import classNames from 'classnames';
import warning from 'warning';
import right from '../../../../../images/icon-right.svg'

export default class Dropdown extends React.Component{

    static defaultProps = {
        mouseEnterDelay: 0.15,
        mouseLeaveDelay: 0.1,
        placement: 'bottomLeft',
    };

    getTransitionName() {
        const { placement = '', transitionName } = this.props;
        if (transitionName !== undefined) {
            return transitionName;
        }
        if (placement.indexOf('top') >= 0) {
            return 'slide-down';
        }
        return 'slide-up';
    }

    renderOverlay = (prefixCls) => {
        // rc-dropdown already can process the function of overlay, but we have check logic here.
        // So we need render the element to check and pass back to rc-dropdown.
        const { overlay } = this.props;

        let overlayNode;
        if (typeof overlay === 'function') {
            overlayNode = (overlay)();
        } else {
            overlayNode = overlay;
        }
        overlayNode = React.Children.only(overlayNode);

        const overlayProps = overlayNode.props;

        // Warning if use other mode
        warning(
            !overlayProps.mode || overlayProps.mode === 'vertical',
            'Dropdown '+
            `mode="${overlayProps.mode}" is not supported for Dropdown's Menu.`,
        );

        // menu cannot be selectable in dropdown defaultly
        // menu should be focusable in dropdown defaultly
        const { selectable = false, focusable = true } = overlayProps;

        const expandIcon = (
            <span className={`${prefixCls}-menu-submenu-arrow`}>
                <i className={`${prefixCls}-menu-submenu-arrow-icon`}><img width={'12px'} height={'12px'} alt="right" src={right}  /></i>
            </span>
        );

        const fixedModeOverlay =
            typeof overlayNode.type === 'string'
                ? overlay
                : React.cloneElement(overlayNode, {
                    mode: 'vertical',
                    selectable,
                    focusable,
                    expandIcon,
                });

        return fixedModeOverlay;
    };

    render() {
        const {
            children,
            trigger,
            disabled,
            getPopupContainer,
        } = this.props;

        const prefixCls = 'pan-dropdown';
        const child = React.Children.only(children);

        const dropdownTrigger = React.cloneElement(child, {
            className: classNames(child.props.className, `${prefixCls}-trigger`),
            disabled,
        });

        const triggerActions = disabled ? [] : trigger;
        let alignPoint;
        if (triggerActions && triggerActions.indexOf('contextMenu') !== -1) {
            alignPoint = true;
        }

        return (
            <RcDropdown
                alignPoint={alignPoint}
                {...this.props}
                prefixCls={prefixCls}
                getPopupContainer={getPopupContainer || (triggerNode => triggerNode)}
                transitionName={this.getTransitionName()}
                trigger={triggerActions}
                overlay={() => this.renderOverlay(prefixCls)}
            >
                {dropdownTrigger}
            </RcDropdown>
        );
    }
}
