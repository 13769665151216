import React, { Component } from 'react';
import { getPathValue, getStoreState, ChartWidget, CheckboxWidget, updateModal } from 'ui-lib';

import './ServiceConnections.scss';

const _T = str => str

const DEFAULT_SC_BANDWIDTH = 300;
const remoteNetworkColor = '#2e637c';
const allocatedServiceConnectionColor = '#8cb669';
const unallocatedColor = '#d3d3d3';
const DEFAULT_FREE_SC_COUNT = 3;

export default class ServiceConnectionAddChecking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalFwaas: 0,
            usedFwaas: 0,
            usedSc: 0,
            allocated: false,
            chartProps: {
                chart: {
                    type: 'pie',
                    width: 250,
                    height:250
                },
                title: {
                    text: ''
                },

                tooltip: {
                    pointFormat: '<b>{point.y:1f} Mbps</b>'
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        colors: [unallocatedColor, allocatedServiceConnectionColor, remoteNetworkColor],
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: false,
                        animation: false
                    }
                },
                series: [{
                    colorByPoint: true,
                    data: [{
                        name: 'Unallocated'
                    }, {
                        name: 'Allocated Additional Service Connections'
                    }, {
                        name: 'Remote Networks'
                    }]
                }]
            }
        };
    }

    componentDidMount() {
        this.createChart();
    }

    processLicense(license) {
        if (!license) {
            return 0;
        }
        var begin = license.indexOf("FWAAS-");
        if (begin < 0) {
            return 0;
        }
        begin += "FWAAS-".length;
        var end = license.indexOf("Mbps");
        if (end < 0 || end < begin) {
            return 0;
        }
        try {
            return Number(license.substring(begin, end));
        } catch (e) {
            return 0;
        }
    }

    createChart() {
        let _storeState = getStoreState();
        let licenseInfo = getPathValue(_storeState, "main.licenseInfo");
        let _totalFwaas = Number(licenseInfo['rn-purchased-size']);
        let totalSC = getPathValue(_storeState.gpcs.serviceConnection, 'result.entry').length;
        let _usedSc = DEFAULT_SC_BANDWIDTH * (totalSC - DEFAULT_FREE_SC_COUNT);
        let allRns = getPathValue(_storeState.gpcs.remoteNetworks, 'result.entry') || [];
        let _usedFwaas = allRns.reduce((total, cur) => {
            return this.processLicense(getPathValue(cur, 'stage1.bandwidth')) + total;
        }, 0);
        let _chartProps = { ...this.state.chartProps };
        _chartProps.series[0].data[0].y = _totalFwaas - _usedFwaas - _usedSc;
        _chartProps.series[0].data[1].y = _usedSc;
        _chartProps.series[0].data[2].y = _usedFwaas;
        this.setState({
            chartProps: _chartProps,
            totalFwaas: _totalFwaas,
            usedFwaas: _usedFwaas,
            usedSc: _usedSc
        })
    }

    updateChart(allocate) {
        let allocated = allocate > 0 ? true : false;
        let _chartProps = { ...this.state.chartProps };
        _chartProps.series[0].data[0].y -= allocate;
        _chartProps.series[0].data[1].y += allocate;
        let _usedFwaas = this.state.usedFwaas + allocate;
        this.setState({
            chartProps: _chartProps,
            usedFwaas: _usedFwaas,
            allocated: allocated
        })
    }

    updateModalAction(val) {
        let _id = this.props.modalId;
        let { modals } = getStoreState().main;
        let _modal = modals.find(item => item.modal.id === _id);
        if (_modal) {
            let _actions = _modal.modal.actions;
            _actions[0].disabled = !val;
            updateModal(_id, { actions: _actions });
        }
    }

    render() {
        const _unallocated = this.state.totalFwaas - this.state.usedSc - this.state.usedFwaas;
        const _enoughFwaas = _unallocated >= DEFAULT_SC_BANDWIDTH;
        const _bottomContent = _enoughFwaas ?
            (
                <CheckboxWidget
                    id="sc_checkbox_allocate"
                    label={_T(`Allocate ${DEFAULT_SC_BANDWIDTH} Mbps for an additional service connection`)}
                    value={this.state.allocated}
                    onChange={(val) => {
                        let allocate = val ? DEFAULT_SC_BANDWIDTH : -1 * DEFAULT_SC_BANDWIDTH;
                        this.updateChart(allocate);
                        this.updateModalAction(val);
                    }}
                />
            ) :
            (
                <div style={{ color: 'red' }}>
                    {_T(`There is not enough bandwidth for an additional service connection`)}
                </div>
            );

        return (_unallocated < 0 || isNaN(_unallocated)) ?
            (
                <div className="sc-add-checking-container">
                    <div>{_T(`After the first three service connections, each additional service connection will use ${DEFAULT_SC_BANDWIDTH} Mbps of unallocated bandwidth from your Remote Networks license`)}</div>
                    <br></br>
                    <div style={{ color: 'red' }}>
                        {_T(`There is not enough bandwidth for an additional service connection`)}
                    </div>
                </div>
            ) :
            (
                <div className="sc-add-checking-container">
                    <div>{_T(`After the first three service connections, each additional service connection will use ${DEFAULT_SC_BANDWIDTH} Mbps of unallocated bandwidth from your Remote Networks license`)}</div>
                    <br></br>
                    <div>{_T(`${this.state.totalFwaas} Mbps Licensed Bandwidth(${_unallocated} Mbps Unallocated)`)}</div>
                    <ChartWidget chartOptions={this.state.chartProps} />
                    {_bottomContent}
                    <br></br>
                    <div className="foot-container">
                        <div className="box" style={{ backgroundColor: remoteNetworkColor }}></div><div>{_T(`Remote Networks`)}</div>
                        <div className="box" style={{ backgroundColor: allocatedServiceConnectionColor }}></div><div>{_T(`Additional Service Connections`)}</div>
                        <div className="box" style={{ backgroundColor: unallocatedColor }}></div><div>{_T(`Unallocated`)}</div>
                    </div>
                </div>
            );
    }
}
